import React from 'react';
import './Loader.css'; // Ensure you have this CSS file

// Import the loader image
import loaderImage from './logo.png'; // Adjust path as needed

const Loader = () => {
  return (
    <div className="loader">
      <img src={loaderImage} alt="Loading..." />
    </div>
  );
};

export default Loader;
