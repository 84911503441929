import React from 'react';

const HeroSection = () => {
    return (
        <section className="bg-gray-900 py-24">
            <div className="py-8 px-6 mx-auto max-w-screen-xl lg:py-16">
                <div className="relative shadow-lg rounded-3xl p-10 md:p-16 mb-10 bg-gray-800 border-teal-400 border-4 border-opacity-50 transition-transform transform hover:scale-105 duration-500 ease-in-out">
                    <div className="flex justify-center mb-5">
                        <button className="font-bold text-3xl md:text-2xl text-white italic inline-flex items-center px-2 py-2 rounded-3xl bg-gray-900 shadow-md pr-6">
                            <img src="https://cdn.virtue-host.com/company/logo.png" alt="Logo" width="45" className="p-2 rounded-lg shadow-lg transform transition-all duration-500 hover:scale-110 bg-gray-900 hidden sm:block" />
                            <span id="text-content">SETUP. RUN. GAME.</span>
                        </button>
                    </div>
                    <h1 className="mb-8 text-4xl font-extrabold text-center leading-tight tracking-tight text-white md:text-6xl lg:text-7xl">
                        <em>AMD Ryzen</em> Hosting at 
                        <span className="underline whitespace-nowrap ml-5" style={{ textDecorationColor: '#2dd4bf' }}>
                            Virtue Host
                        </span>
                    </h1>
                    <p className="text-xl md:text-2xl font-bold text-center text-white my-8 px-4 lg:px-16">
                        Lightning-fast hosting for Minecraft Servers and beyond. With instant deployment and seamless gameplay, lag is a thing of the past!
                    </p>
                    <div className="flex justify-center">
                        <a href="games.php" className="bg-gray-900 border border-teal-400 text-white font-bold rounded-3xl text-center w-fit text-lg md:text-2xl px-6 py-3 md:px-8 md:py-2 shadow-lg transform transition-transform hover:scale-110 hover:bg-gray-700 hover:text-white focus:ring-4 focus:ring-green-300 dark:focus:ring-gray-800">
                            Deploy Now!
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
