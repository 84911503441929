import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDropdownToggle = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close dropdown if clicking outside of it
      if (
        dropdownRef1.current && !dropdownRef1.current.contains(event.target) &&
        dropdownRef2.current && !dropdownRef2.current.contains(event.target)
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <section className="bg-white dark:bg-gray-900 transition-colors duration-300">
      <nav className="fixed top-0 left-0 right-0 p-6 bg-gray-900 z-50">
        <div className="container mx-auto lg:flex lg:items-center lg:justify-between max-w-screen-7xl">
          <div className="flex items-center justify-between w-full lg:w-auto">
            <a href="/" className="text-white font-extrabold text-2xl flex items-center">
              <img className="h-10 mr-1" src="https://cdn.virtue-host.com/company/logo.png" alt="Virtue Host Logo" />
              Virtue Host
            </a>
            <button
              type="button"
              className="text-teal-400 lg:hidden hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-green-500 transition-transform duration-300"
              aria-label="Toggle menu"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                </svg>
              )}
            </button>
          </div>

          <div className={`lg:flex lg:items-center lg:space-x-6 mx-auto flex-col lg:flex-row ${isMobileMenuOpen ? '' : 'hidden'}`}>
            <div className="flex flex-col lg:flex-row lg:space-y-0 space-y-1 lg:space-x-6 font-bold text-lg">
              <a className="text-white hover:text-teal-400 transition-colors duration-300 transform dark:text-white dark:hover:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md" href="/">Home</a>
              <a className="text-white hover:text-teal-400 transition-colors duration-300 transform dark:text-white dark:hover:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md" href="/minecraft">Minecraft</a>
              <a className="text-white hover:text-teal-400 transition-colors duration-300 transform dark:text-gray-200 dark:hover:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md" href="/games">Game Servers</a>
              
              <div ref={dropdownRef1} className="relative group">
                <button
                  className="flex items-center text-white hover:text-teal-400 transition-colors duration-300 dark:text-gray-200 dark:hover:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md"
                  aria-expanded={openDropdown === 'dropdown1'}
                  onClick={() => handleDropdownToggle('dropdown1')}
                >
                  Other Hosting
                  <svg className="w-4 h-4 ml-1 text-teal-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div className={`absolute left-0 mt-4 p-4 bg-gray-800 border border-gray-700 rounded-2xl shadow-lg whitespace-nowrap transition-opacity duration-300 ${openDropdown === 'dropdown1' ? 'block' : 'hidden'}`}>
                  <a href="/bot-hosting" className="block px-4 py-1 text-white hover:text-teal-400 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md">Bot Hosting</a>
                </div>
              </div>
              
              <div ref={dropdownRef2} className="relative group">
                <button
                  className="flex items-center text-white hover:text-teal-400 transition-colors duration-300 dark:text-gray-200 dark:hover:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md"
                  aria-expanded={openDropdown === 'dropdown2'}
                  onClick={() => handleDropdownToggle('dropdown2')}
                >
                  Company
                  <svg className="w-4 h-4 ml-1 text-teal-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                <div className={`absolute left-0 mt-4 p-4 bg-gray-800 border border-gray-700 rounded-2xl shadow-lg whitespace-nowrap transition-opacity duration-300 ${openDropdown === 'dropdown2' ? 'block' : 'hidden'}`}>
                  <a href="https://status.virtue-host.com/" className="block px-4 py-1 text-white hover:text-teal-400 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md">Status Page</a>
                  <a href="/terms-of-service" className="block px-4 py-1 text-white hover:text-teal-400 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md">Terms of Service</a>
                  <a href="/privacy-policy" className="block px-4 py-1 text-white hover:text-teal-400 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md">Privacy Policy</a>
                  <a href="/aup" className="block px-4 py-1 text-white hover:text-teal-400 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400 rounded-md">AUP</a>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-5 lg:hidden space-y-2 pt-4 border-t border-gray-700">
              <a className="px-4 py-1.5 text-base font-semibold text-center text-white capitalize bg-gray-900 border-teal-400 border rounded-lg hover:bg-gray-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400" href="https://panel.virtue-host.com/">Game Panel</a>
              <a className="px-4 py-1.5 text-base font-semibold text-center text-white capitalize bg-gray-900 border-teal-400 border rounded-lg hover:bg-gray-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400" href="https://virtue-host.com/billing/">Client Area</a>
            </div>
          </div>

          <div className="hidden lg:flex lg:items-center space-x-2">
            <a className="px-4 py-1.5 text-base font-semibold text-center text-white capitalize bg-gray-900 border-teal-400 border rounded-3xl hover:bg-gray-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400" href="https://panel.virtue-host.com/">Game Panel</a>
            <a className="px-4 py-1.5 text-base font-semibold text-center text-white capitalize bg-gray-900 border-teal-400 border rounded-3xl hover:bg-gray-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400" href="https://virtue-host.com/billing/">Client Area</a>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Navbar;
