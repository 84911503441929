import React from 'react';

const Comparison = () => {
  return (
    <div className="bg-gray-900">
    <section className="px-6 mx-auto bg-gray-900 max-w-screen-xl py-16">
      <h1 className="text-4xl font-bold text-center capitalize lg:text-4xl text-white">
        Compare <span className="text-teal-400">Virtue Host</span> With Other Providers
      </h1>
      <p className="max-w-2xl mx-auto font-bold text-2xl mt-3 mb-10 text-center text-white">
        Experience Virtue, Bigger Bang - Less Expenses!
      </p>
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-700 md:rounded-3xl">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-800">
                  <tr>
                    <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-white">
                      Company
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-white">
                      Overall Rating
                    </th>
                    <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-white">
                      CPU
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-white">
                      RAM
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-white">
                      Storage
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-white">
                      Monthly Price
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700 bg-gray-800">
                  <tr>
                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                      <div>
                        <h2 className="font-bold text-base text-green-500">Virtue Host</h2>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="w-48 h-1.5 bg-gray-700 overflow-hidden rounded-full">
                        <div className="bg-green-500 w-full h-1.5"></div>
                      </div>
                    </td>
                    <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                      <div className="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-gray-700">
                        Ryzen 9 5950x
                      </div>
                      <h2 className="mt-2 font-bold text-md text-green-500">Single Thread Score: 3469</h2>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-green-500 font-bold text-base">16GB</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-green-500 font-bold text-base">320GB NVMe SSD</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-green-500 font-bold text-base">$32</h4>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                      <div>
                        <h2 className="font-medium text-white">Bloom Host</h2>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="w-48 h-1.5 bg-gray-700 overflow-hidden rounded-full">
                        <div className="bg-yellow-500 w-[80%] h-1.5"></div>
                      </div>
                    </td>
                    <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                      <div className="inline px-3 py-1 text-sm font-normal rounded-full text-white gap-x-2 bg-gray-700">
                        Ryzen 9 5950x
                      </div>
                      <h2 className="mt-2 text-[14px] font-thin text-white">Single Thread Score: 3469</h2>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">16GB</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">300GB NVMe SSD</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">$48</h4>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                      <div>
                        <h2 className="font-medium text-white">Sparked Host</h2>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="w-48 h-1.5 bg-gray-700 overflow-hidden rounded-full">
                        <div className="bg-orange-500 w-[50%] h-1.5"></div>
                      </div>
                    </td>
                    <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                      <div className="inline px-3 py-1 text-sm font-normal rounded-full text-white gap-x-2 bg-gray-700">
                        Ryzen 7 7700
                      </div>
                      <h2 className="mt-2 text-[14px] font-thin text-white">Single Thread Score: 4057</h2>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">16GB</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">250GB NVMe SSD</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">$68.06</h4>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                      <div>
                        <h2 className="font-medium text-white">Pebble Host</h2>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="w-48 h-1.5 bg-gray-700 overflow-hidden rounded-full">
                        <div className="bg-orange-500 w-[50%] h-1.5"></div>
                      </div>
                    </td>
                    <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                      <div className="inline px-3 py-1 text-sm font-normal rounded-full text-white gap-x-2 bg-gray-700">
                        Ryzen 7 5800x
                      </div>
                      <h2 className="mt-2 text-[14px] font-thin text-white">Single Thread Score: 3445</h2>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">16GB</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">Unlimited NVMe SSD</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">$66.50</h4>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                      <div>
                        <h2 className="font-medium text-white">Shockbyte</h2>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="w-48 h-1.5 bg-gray-700 overflow-hidden rounded-full">
                        <div className="bg-yellow-500 w-[40%] h-1.5"></div>
                      </div>
                    </td>
                    <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                      <div className="inline px-3 py-1 text-sm font-normal rounded-full text-white gap-x-2 bg-gray-700">
                        Ryzen 5 5600x
                      </div>
                      <h2 className="mt-2 text-[14px] font-thin text-white">Single Thread Score: 3402</h2>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">16GB</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">200GB NVMe SSD</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">$56.76</h4>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                      <div>
                        <h2 className="font-medium text-white">Apex Hosting</h2>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="w-48 h-1.5 bg-gray-700 overflow-hidden rounded-full">
                        <div className="bg-orange-500 w-[30%] h-1.5"></div>
                      </div>
                    </td>
                    <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                      <div className="inline px-3 py-1 text-sm font-normal rounded-full text-white gap-x-2 bg-gray-700">
                        Ryzen 9 5950x
                      </div>
                      <h2 className="mt-2 text-[14px] font-thin text-white">Single Thread Score: 3469</h2>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">16GB</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">250GB NVMe SSD</h4>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div>
                        <h4 className="text-gray-200">$69.99</h4>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Comparison;
