import React, { useState } from 'react';
import Faq from '../components/Faq';
import Panel from '../components/Panel';
import { Helmet } from 'react-helmet';
// Define packages as an object with categories
const packages = {
  budget: [
    {
      title: 'Creeper',
      description: 'Ryzen 7 5700G (2 vCores)',
      price: '$7',
      sale: true,
      salePrice: '$5.50',
      link: 'https://virtue-host.com/billing/store/essential-minecraft/4-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Creeper.webp',
      features: [
        '4 GB DDR4 RAM',
        '40 GB NVMe SSD',
        '1 GBit/s Connection',
        '1 Database',
        '3 Allocations',
        '1 Included Backup',
        'Create 0 Additional Servers'
      ]
    },
    {
      title: 'Enderman',
      description: 'Ryzen 7 5700G (2 vCores)',
      price: '$10.50',
      sale: true,
      salePrice: '$8.25',
      link: 'https://virtue-host.com/billing/store/essential-minecraft/6-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Enderman.webp',
      features: [
        '6 GB DDR4 RAM',
        '60 GB NVMe SSD',
        '1 GBit/s Connection',
        '2 Database',
        '3 Allocations',
        '1 Included Backup',
        'Create 0 Additional Servers'
      ]
    },
    {
      title: 'Skeleton',
      description: 'Ryzen 7 5700G (2.5 vCores)',
      price: '$14',
      sale: true,
      salePrice: '$11',
      link: 'https://virtue-host.com/billing/store/essential-minecraft/8-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Skeleton.webp',
      features: [
        '8 GB DDR4 RAM',
        '80 GB NVMe SSD',
        '1 GBit/s Connection',
        '2 Database',
        '3 Allocations',
        '1 Included Backup',
        'Create 1 Additional Servers'
      ]
    },
    {
      title: 'Magma',
      description: 'Ryzen 7 5700G (3 vCores)',
      price: '$21',
      sale: true,
      salePrice: '$16.50',
      link: 'https://virtue-host.com/billing/store/essential-minecraft/12-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Magma_Cube.webp',
      features: [
        '12 GB DDR4 RAM',
        '120 GB NVMe SSD',
        '1 GBit/s Connection',
        '3 Database',
        '4 Allocations',
        '1 Included Backup',
        'Create 2 Additional Servers'
      ]
    },
    {
      title: 'Zombie',
      description: 'Ryzen 7 5700G (4 vCores)',
      price: '$28',
      sale: true,
      salePrice: '$22',
      link: 'https://virtue-host.com/billing/store/essential-minecraft/16-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Zombie.webp',
      features: [
        '16 GB DDR4 RAM',
        '125 GB NVMe SSD',
        '1 GBit/s Connection',
        '3 Database',
        '5 Allocations',
        '1 Included Backup',
        'Create 3 Additional Servers'
      ]
    }
  ],
  performance: [
    {
      title: 'Creeper+',
      description: 'Ryzen 9 5950X (2 vCores)',
      price: '$8',
      link: 'https://virtue-host.com/billing/store/performance-minecraft/4-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Creeper.webp',
      features: [
        '4 GB DDR4 RAM',
        '80 GB NVMe SSD',
        '1 GBit/s Connection',
        '2 Database',
        '3 Allocations',
        '1 Included Backup',
        'Create 0 Additional Servers'
      ]
    },
    {
      title: 'Enderman+',
      description: 'Ryzen 9 5950X (2 vCores)',
      price: '$12',
      link: 'https://virtue-host.com/billing/store/performance-minecraft/6-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Enderman.webp',
      features: [
        '6 GB DDR4 RAM',
        '120 GB NVMe SSD',
        '1 GBit/s Connection',
        '2 Database',
        '3 Allocations',
        '1 Included Backup',
        'Create 0 Additional Servers'
      ]
    },
    {
      title: 'Skeleton+',
      description: 'Ryzen 9 5950X (2.5 vCores)',
      price: '$16',
      link: 'https://virtue-host.com/billing/store/performance-minecraft/8-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Skeleton.webp',
      features: [
        '8 GB DDR4 RAM',
        '160 GB NVMe SSD',
        '1 GBit/s Connection',
        '2 Database',
        '3 Allocations',
        '1 Included Backup',
        'Create 1 Additional Servers'
      ]
    },
    {
      title: 'Magma+',
      description: 'Ryzen 9 5950X (3 vCores)',
      price: '$24',
      link: 'https://virtue-host.com/billing/store/performance-minecraft/12-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Magma_Cube.webp',
      features: [
        '12 GB DDR4 RAM',
        '240 GB NVMe SSD',
        '1 GBit/s Connection',
        '3 Database',
        '4 Allocations',
        '1 Included Backup',
        'Create 2 Additional Servers'
      ]
    },
    {
      title: 'Zombie+',
      description: 'Ryzen 9 5950X (4 vCores)',
      price: '$32',
      link: 'https://virtue-host.com/billing/store/performance-minecraft/16-gb',
      image: 'https://cdn.virtue-host.com/plan-logos/Zombie.webp',
      features: [
        '16 GB DDR4 RAM',
        '320 GB NVMe SSD',
        '1 GBit/s Connection',
        '3 Database',
        '5 Allocations',
        '1 Included Backup',
        'Create 3 Additional Servers'
      ]
    }
  ],
};

const MinecraftHosting = () => {
  const [activeTab, setActiveTab] = useState('budget');

  const renderCards = () => {
    return packages[activeTab].slice(0, 6).map((pkg) => (
      <div key={pkg.title} className="bg-gray-800 border-gray-700 border-4 rounded-3xl p-6">
        <img
          src={pkg.image}
          alt={pkg.title}
          className="rounded-lg mb-4 w-auto h-20 object-cover mx-auto"
        />
        <h3 className="text-2xl font-bold text-white text-center">{pkg.title}</h3>
        <p className="text-gray-300 text-lg italic mb-4 text-center">{pkg.description}</p>

        {/* Conditionally display sale price */}
        <p className="text-center text-4xl font-bold mb-4">
          {pkg.sale ? (
            <>
              <span className="line-through text-teal-400 text-3xl">{pkg.price}</span>
              <span className="text-green-400"> {pkg.salePrice}</span>
            </>
          ) : (
            <span className="text-teal-400">{pkg.price}</span>
          )}
          <span className="text-xl text-white">/month</span>
        </p>

        <ul className="list-disc list-inside text-xl font-semibold text-white mb-4">
          {pkg.features.map((feature, idx) => (
            <li key={idx}>{feature}</li>
          ))}
        </ul>
        <a
          href={pkg.link}
          className="inline-flex items-center justify-center text-white w-full hover:bg-gray-700 border-teal-400 border hover:text-white focus:ring-4 focus:outline-none font-bold rounded-3xl text-sm px-5 py-3 bg-gray-900 focus:ring-green-800"
        >
          View Details
          <svg
            className="w-4 h-4 ml-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    ));
  };
  return (
    <>
      <section className="px-4 py-8 lg:py-20 bg-gray-900">
        <div className="grid max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse text-sm text-gray-200">
              <li className="inline-flex items-center">
                <a href="/" className="inline-flex items-center font-medium hover:text-teal-400">
                  <svg className="w-4 h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M19.707 9.293L17.707 7.293l-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414z" />
                  </svg>
                  Home
                </a>
              </li>
              <Helmet>
              <title>Minecraft | Virtue Host</title>
        <meta name="description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="keywords" content="Game Servers, Minecraft Hosting, Bot Hosting" />
        {/* Open Graph meta tags */}
        <meta property="og:image" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:secure_url" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="96" />
        <meta property="og:image:height" content="96" />
        <meta property="og:image:alt" content="Virtue Host Logo" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:src" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta name="twitter:title" content="Minecraft - Virtue Host" />
        <meta name="twitter:description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="twitter:image" content="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Link tags */}
        <link rel="canonical" href="https://virtue-host.com/" />
        <link rel="apple-touch-icon" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="manifest" href="" />
        <link rel="mask-icon" href="https://virtue-host.com/assets/images/company/logo.png" color="#55FF55" />
        <link rel="shortcut icon" href="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Microsoft-specific meta tags */}
        <meta name="msapplication-TileColor" content="#55FF55" />
        <meta name="msapplication-config" content="" />
        <meta name="theme-color" content="#55FF55" />
      </Helmet>
              <li aria-current="page">
                <div className="flex items-center">
                  <a href="/games" className="inline-flex items-center font-medium hover:text-teal-400">
                    <svg className="rtl:rotate-180 w-4 h-4 text-gray-400 mx-1 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 9l4-4-4-4" />
                    </svg>
                    <span className="text-white hover:text-teal-400">Games</span>
                  </a>
                </div>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <svg className="rtl:rotate-180 w-4 h-4 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 9l4-4-4-4" />
                  </svg>
                  <span className="text-white">Minecraft</span>
                </div>
              </li>
            </ol>
            <h1 className="text-4xl font-extrabold leading-tight mb-4 mt-10 md:text-5xl xl:text-6xl text-white">
              Minecraft Server Hosting
            </h1>
            <p className="mb-6 text-lg font-semibold lg:mb-8 lg:text-xl text-gray-300">
              Enhance your Minecraft journey with Virtue Host's hosting solutions. Experience seamless performance and expert support, crafting an unparalleled gaming adventure. 
            </p>
            <a href="https://virtue-host.com/billing/submitticket.php/" className="inline-flex items-center justify-center text-white hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none font-bold rounded-3xl text-sm px-5 py-3 bg-gray-800 border border-teal-400 focus:ring-green-800">
              Speak to Sales
              <svg className="w-4 h-4 ml-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>
          </div>
          <div className="hidden lg:flex lg:col-span-5 lg:justify-end lg:items-center">
            <img src="https://cdn.virtue-host.com/game-covers/minecraft.webp" alt="mockup" className="rounded-xl w-full max-w-md h-auto object-cover" />
          </div>
        </div>
      </section>

      <section className="bg-gray-900 py-12 lg:py-24">
        <div className="text-center mb-8">
          <h2 className="text-5xl font-bold text-teal-400 mb-4">Our Minecraft Hosting Plans</h2>
          <p className="text-2xl font-semibold text-gray-300">Choose the plan that fits your needs and start your Minecraft adventure today!</p>
        </div>
        <div className="flex justify-center mb-8">
          <div className="space-x-2">
            <button
              onClick={() => setActiveTab('budget')}
              className={`px-8 py-3 font-bold rounded-3xl ${activeTab === 'budget' ? 'bg-gray-900 border-teal-400 border text-white' : 'bg-gray-800 border border-transparent text-gray-400'}`}
            >
              Essential Plans
            </button>
            <button
              onClick={() => setActiveTab('performance')}
              className={`px-8 py-3 font-bold rounded-3xl ${activeTab === 'performance' ? 'bg-gray-900 border-teal-400 border text-white' : 'bg-gray-800 border border-transparent text-gray-400'}`}
            >
              Performance Plans
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 mx-auto gap-8 max-w-screen-xl">
          {renderCards()}
        </div>
      </section>
      <Panel />
      <Faq />
    </>
  );
};

export default MinecraftHosting;
