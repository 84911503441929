import React, { useState } from 'react';

const faqData = {
  general: [
    { question: "What is Virtue Host?", answer: "Virtue Host offers comprehensive online hosting solutions, including Game Servers, Website Hosting, Discord Bot Hosting, and more." },
    { question: "What regions does Virtue Host service?", answer: "Our services support all regions; however, for optimal performance, it's best to choose game server hardware closer to your region." },
    { question: "How does support work?", answer: "We offer top-tier support primarily through our client area, complemented by support via our Discord Ticket System." },
    { question: "Can I get a refund?", answer: "We offer our customers a 48-hour refund policy." },
    { question: "A game I want to host is not on the Virtue Host site?", answer: "If a game you wish to host is not listed on the Virtue Host site, we do not offer services for that particular game." },
    { question: "How do I contact support?", answer: "You can contact support through our Client Area or by opening a ticket in our Discord support channel." }
  ],
  minecraft: [
    { question: "Do you offer server locations in different regions?", answer: "We currently provide server locations in North America and Europe." },
    { question: "Can I switch between different Minecraft server versions?", answer: "Yes, it's accessible through our game panel." },
    { question: "Can I upgrade or downgrade my server plan?", answer: "Yes, it's accessible through our client area." },
    { question: "Can I transfer an existing Minecraft world to your hosting service?", answer: "Yes!" },
    { question: "Is there a limit on the number of players my Minecraft server can support?", answer: "There is no limit; however, you proceed at your own risk." },
    { question: "What are the benefits of using a dedicated Minecraft host?", answer: "You receive dedicated resources, isolating your environment from others to ensure maximum performance tailored to your needs." },
    { question: "What version of Minecraft do you support?", answer: "All versions!" },
    { question: "Can I install mods/plugins on my Minecraft server?", answer: "Yes, we support most Minecraft server JARs, excluding Folia. We also have 1-click installers for mods." }
  ],
  "bot-hosting": [
    { question: "What locations does Virtue Host offer for Bot Hosting?", answer: "Bot hosting hardware is located in the Europe region. However, it's important to note that bot hosting can typically be done from anywhere and accessed globally." },
    { question: "Can I host multiple Discord bots on the same server?", answer: "Yes! For all bot languages except Java." },
    { question: "How do I monitor and manage my hosted Discord bot?", answer: "Within the Virtue Host Control Panel and/or FTP." },
    { question: "What programming languages and frameworks are supported for Discord bot hosting?", answer: "We support Node.js, Python, and Java. Additionally, we offer pre-installed Discord bot software for your convenience." }
  ]
};

const Faq = () => {
  const [activeSection, setActiveSection] = useState('general');
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const handleTabClick = (section) => {
    setActiveSection(section);
    setOpenFaqIndex(null); // Optionally close all FAQs when changing section
  };

  const handleFaqClick = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  return (
    <section className="bg-gray-900">
      <div className="container px-6 pt-32 mx-auto max-w-screen-7xl">
        <h1 className="text-4xl font-bold text-center capitalize lg:text-4xl text-white">
          Frequently <span className="text-teal-400">Asked</span> Questions
        </h1>

        <div className="mt-8 xl:mt-16 lg:flex lg:-mx-12">
          {/* Table of Contents */}
          <div className="lg:mx-12">
            <h1 className="text-xl font-semibold text-white underline">FAQ Categories</h1>
            <div className="mt-4 space-y-2 lg:mt-4">
              <button
                onClick={() => handleTabClick('general')}
                className={`block text-teal-400 hover:underline font-bold ${activeSection === 'general' ? 'font-bold' : ''}`}
              >
                General
              </button>
              <button
                onClick={() => handleTabClick('minecraft')}
                className={`block text-teal-400 hover:underline font-bold ${activeSection === 'minecraft' ? 'font-bold' : ''}`}
              >
                Minecraft
              </button>
              <button
                onClick={() => handleTabClick('bot-hosting')}
                className={`block text-teal-400 hover:underline font-bold ${activeSection === 'bot-hosting' ? 'font-bold' : ''}`}
              >
                Bot Hosting
              </button>
            </div>
          </div>

          {/* FAQ Sections */}
          <div className="flex-1 mt-8 lg:mx-12 lg:mt-0">
            {Object.keys(faqData).map(section => (
              <div
                key={section}
                id={section}
                className={`faq-section ${activeSection === section ? '' : 'hidden'}`}
              >
                <h2 className="text-2xl font-semibold text-white capitalize">{section.charAt(0).toUpperCase() + section.slice(1)}</h2>
                <div className="mt-4">
                  {faqData[section].map((faq, index) => (
                    <div key={index} className="faq-item mb-6">
                      <button
                        className="faq-button flex items-center w-full text-left py-4 px-4 bg-gray-800 rounded-3xl focus:outline-none transition-colors duration-300 hover:bg-gray-700"
                        onClick={() => handleFaqClick(index)}
                      >
                        <svg
                          className={`flex-shrink-0 w-6 h-6 text-teal-400 mr-4 transition-transform duration-300 transform ${openFaqIndex === index ? 'rotate-45' : ''}`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d={openFaqIndex === index ? "M6 18L18 6M6 6l12 12" : "M20 12H4"}
                          />
                        </svg>
                        <h3 className="text-xl font-bold text-white">{faq.question}</h3>
                      </button>
                      <div className={`faq-content mt-4 ${openFaqIndex === index ? '' : 'hidden'}`}>
                        <p className="text-white font-semibold">{faq.answer}</p>
                      </div>
                      <hr className="mt-4 border-gray-700" />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
