// GamesPage.jsx
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Sample data for games
const games = [
  {
    name: "Minecraft",
    description: "Explore a vast, blocky world where you can build, survive, and craft to your heart's content. Endless adventures await in this sandbox game!",
    img: "https://cdn.virtue-host.com/game-covers/minecraft.webp",
    link: "minecraft",
    topSeller: true,
    onSale: false,
    newRelease: false
  },
  {
    name: "Counter Strike",
    description: "Tactical shooter where teamwork and strategy are key. Engage in objective-based combat with realistic weaponry and intense rounds.",
    img: "https://cdn.virtue-host.com/game-covers/csgo.webp",
    link: "counterstrike",
    topSeller: false,
    onSale: false,
    newRelease: false
  },
  {
    name: "Garry's Mod",
    description: "Tactical shooter where teamwork and strategy are key. Engage in objective-based combat with realistic weaponry and intense rounds.",
    img: "https://cdn.virtue-host.com/game-covers/gmod.webp",
    link: "garrysmod",
    topSeller: false,
    onSale: false,
    newRelease: false
  },
  {
    name: "RUST",
    description: "Survival game focused on gathering resources, building, and combat. Outwit other players and survive in a harsh, open-world environment.",
    img: "https://cdn.virtue-host.com/game-covers/rust.webp",
    link: "rust",
    topSeller: false,
    onSale: false,
    newRelease: false
  },
  {
    name: "Palworld",
    description: "Survival game with creatures and crafting. Explore, battle, and build in a vibrant world with a unique blend of gameplay elements.",
    img: "https://cdn.virtue-host.com/game-covers/Palworld.webp",
    link: "palworld",
    topSeller: false,
    onSale: false,
    newRelease: true
  },
  {
    name: "ARK: Survival Evolved",
    description: "An epic adventure in a world filled with dinosaurs and prehistoric creatures. Survive, craft, and build in a changing environment.",
    img: "https://cdn.virtue-host.com/game-covers/ArkSurvivalEvolved.webp",
    link: "survival-evolved",
    topSeller: false,
    onSale: false,
    newRelease: true
  }
];

const GamesPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredGames, setFilteredGames] = useState(games);

  useEffect(() => {
    const results = games.filter(game =>
      game.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      game.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredGames(results);
  }, [searchQuery]);

  return (
    <div className="bg-gray-900 text-white">

       {/* Meta Tags and Page Title */}
       <Helmet>
       <title>Game Servers | Virtue Host</title>
        <meta name="description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="keywords" content="Game Servers, Minecraft Hosting, Bot Hosting" />
        {/* Open Graph meta tags */}
        <meta property="og:image" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:secure_url" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="96" />
        <meta property="og:image:height" content="96" />
        <meta property="og:image:alt" content="Virtue Host Logo" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:src" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta name="twitter:title" content="Game Servers - Virtue Host" />
        <meta name="twitter:description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="twitter:image" content="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Link tags */}
        <link rel="canonical" href="https://virtue-host.com/" />
        <link rel="apple-touch-icon" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="manifest" href="" />
        <link rel="mask-icon" href="https://virtue-host.com/assets/images/company/logo.png" color="#55FF55" />
        <link rel="shortcut icon" href="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Microsoft-specific meta tags */}
        <meta name="msapplication-TileColor" content="#55FF55" />
        <meta name="msapplication-config" content="" />
        <meta name="theme-color" content="#55FF55" />
      </Helmet>
      {/* Breadcrumb Navigation */}
      <section className="bg-gray-900 pt-10 mt-5">
        <div className="max-w-screen-xl px-4 py-10 mx-auto lg:py-20 flex items-center justify-between">
          <ol className="inline-flex items-center space-x-2">
            <li className="inline-flex items-center">
              <a href="/" className="inline-flex items-center text-sm font-medium text-white hover:text-blue-500">
                <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                </svg>
                Home
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <svg className="w-4 h-4 text-white mx-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                </svg>
                <span className="ml-1 text-sm font-medium text-white">Games</span>
              </div>
            </li>
          </ol>
          <form className="flex items-center w-1/2 lg:w-fit mb-3 lg:mb-0">
            <label htmlFor="search" className="sr-only">Search</label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg className="w-6 h-6 text-teal-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                </svg>
              </div>
              <input
                type="text"
                id="search"
                className="border text-sm rounded-3xl focus:ring-green-500 focus:border-green-500 w-full pl-10 p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 text-white"
                placeholder="Search Games"
                required
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </form>
        </div>
      </section>

      {/* Game Cards */}
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 text-white mb-20">
          {filteredGames.map(game => (
            <div
              key={game.name}
              className="relative bg-gray-800 border-4 border-gray-700 rounded-3xl shadow-lg overflow-hidden flex flex-col transition-transform transform hover:scale-105 hover:shadow-2xl duration-300"
            >
              {game.topSeller && (
                <div className="absolute top-0 right-0 bg-green-600 text-white text-md font-bold px-3 py-2 shadow-md border-2 rounded-l-md border-gray-700">
                  Top Seller
                </div>
              )}
              {game.onSale && (
                <div className="absolute top-2 left-2 bg-orange-500 text-white text-md font-bold px-3 py-2 shadow-md border-2 rounded-l-md border-gray-700">
                  On Sale
                </div>
              )}
              {game.newRelease && (
                <div className="absolute top-0 right-0 bg-gray-900 text-white text-md font-bold px-3 py-2 shadow-md border-2 rounded-l-md border-gray-700">
                  New Release
                </div>
              )}
              <img src={game.img} alt={game.name} className="w-full h-56 object-cover" />
              <div className="p-4 flex-grow bg-gray-850">
                <h3 className="text-2xl font-extrabold mb-2">{game.name}</h3>
                <p className="text-md font-semibold text-gray-300">{game.description}</p>
              </div>
              <div className="p-4 bg-gray-800 border-t border-gray-700">
                <a href={game.link} className="text-teal-400 hover:text-white font-bold transition-colors duration-300">
                  View Server Plans
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GamesPage;
