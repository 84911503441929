const Reviews = () => {
  return (
    <section className="bg-gray-900">
      <div className="container px-6 py-16 mx-auto max-w-screen-xl">
        <h1 className="text-4xl font-bold text-center capitalize lg:text-4xl text-white">
          What our <span className="text-teal-400">customers</span> are saying
        </h1>
        <p className="max-w-2xl mx-auto font-bold text-2xl mt-6 mb-10 text-center text-white">
          Our clients love our services! Hear their thoughts.
        </p>
        <div className="grid grid-cols-1 gap-8 mx-auto mt-8 lg:grid-cols-3 xl:mt-10 max-w-screen-xl">
          <div className="p-6 rounded-3xl bg-gray-800 border-teal-400 border md:p-8 flex flex-col h-full">
          <div className="flex items-center mt-6 mb-10 left-0 top-0">
              <img className="object-cover rounded-full w-14 h-14" src="https://cdn.virtue-host.com/company/creeper-face.webp" alt="Customer face" />
              <div className="mx-4">
                <h1 className="font-semibold text-green-500">D_rip</h1>
                <span className="text-sm font-semibold text-gray-300">Satisfied Customer</span>
              </div>
            </div>
            <p className="leading-loose text-base font-bold text-white text-center flex-1">
              "Switched from Shockbyte and I gotta say it is well worth it for the price and specs. Also having no hidden fees satisfies me."
            </p>
          </div>
          <div className="p-6 rounded-3xl bg-gray-800 border-teal-400 border md:p-8 flex flex-col h-full">
          <div className="flex items-center mt-6 mb-10 left-0 top-0">
              <img className="object-cover rounded-full w-14 h-14" src="https://cdn.virtue-host.com/company/creeper-face.webp" alt="Customer face" />
              <div className="mx-4">
                <h1 className="font-semibold text-green-500">RaydenSpawn</h1>
                <span className="text-sm font-semibold text-gray-300">Happy Client</span>
              </div>
            </div>
            <p className="leading-loose text-base font-bold text-white text-center flex-1">
              "This server provider actually has customization and support that actually helps when able to. If you wanted to, you can pay 1$ extra per 10gb of storage per month. The help I received was quick and the performance is significantly better compared to other hosts."
            </p>
          </div>
          <div className="p-6 rounded-3xl bg-gray-800 border-teal-400 border md:p-8 flex flex-col h-full">
          <div className="flex items-center mt-6 mb-10 left-0 top-0">
              <img className="object-cover rounded-full w-14 h-14" src="https://cdn.virtue-host.com/company/creeper-face.webp" alt="Customer face" />
              <div className="mx-4">
                <h1 className="font-semibold text-green-500">EllasRipley</h1>
                <span className="text-sm font-semibold text-gray-300">Long-time Customer</span>
              </div>
            </div>
            <p className="leading-loose text-base font-bold text-white text-center flex-1">
              "I've owned many servers over the years and hosted with various companies. Virtue Host stands out for its reliable performance at a great price. No throttling and exceptional staff support!"
            </p>

          </div>
        </div>
        <div className="flex justify-center items-center mt-10">
          <a
            href="https://www.trustpilot.com/review/virtue-host.com"
            className="inline-flex items-center bg-[#72b043] border border-transparent text-white text-lg font-semibold py-3 px-6 rounded-3xl shadow-md transition-transform transform hover:scale-105"
          >
            <img src="https://cdn.virtue-host.com/company/square_app_icon.webp" alt="Trustpilot Logo" className="w-8 h-8 mr-2" />
            Leave a Review on Trustpilot
          </a>
        </div>
        <div className="mt-5 flex justify-center items-center">
          <div className="flex items-center">
            <svg className="w-6 h-6 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg className="w-6 h-6 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg className="w-6 h-6 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg className="w-6 h-6 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg className="w-6 h-6 text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          </div>
        </div>
          <h2 className="text-base mt-3 text-center font-bold tracking-tight text-white">
            4/5 Rating
          </h2>
        </div>
    </section>
  );
};

export default Reviews;
