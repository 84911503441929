import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import GamesPage from './pages/GamesPage';
import AupPage from './pages/AupPage';
import PartnersPage from './pages/PartnersPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import BotHostingPage from './pages/BotHostingPage';
import TermsOfService from './pages/TermsOfService';
import MinecraftPage from './pages/MinecraftPage';
import CounterStrikePage from './pages/CounterStrikePage';
import Loader from './components/Loader';
import PalworldPage from './pages/PalworldPage';
import GarrysModPage from './pages/GarrysModPage';
import RustPage from './pages/RustPage';
import SurvivalEvolvedPage from './pages/SurvivalEvolved';
import NotFound from './pages/NotFound';

// Custom hook to manage loader visibility
const usePageLoading = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500); // Adjust duration as needed
    };

    handleRouteChange(); // Start loading when the component mounts

    return () => {
      setLoading(false); // Ensure loading is hidden on unmount
    };
  }, [location]);

  return loading;
};

const PageWrapper = ({ children }) => {
  const loading = usePageLoading();

  return (
    <>
      {loading && <Loader />}
      {children}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Navbar />
      <main>
        <PageWrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/aup" element={<AupPage />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/bot-hosting" element={<BotHostingPage />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/minecraft" element={<MinecraftPage />} />
            <Route path="/counterstrike" element={<CounterStrikePage />} />
            <Route path="/garrysmod" element={<GarrysModPage />} />
            <Route path="/palworld" element={<PalworldPage />} />
            <Route path="/rust" element={<RustPage />} />
            <Route path="/survival-evolved" element={<SurvivalEvolvedPage />} />
            {/* Add more routes as needed */}
            <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
          </Routes>
        </PageWrapper>
      </main>
      <Footer />
    </Router>
  );
};


export default App;
