import React from 'react';
import { Helmet } from 'react-helmet';

const AupPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto py-10 px-4 sm:py-16 sm:px-6 max-w-screen-xl">
        <h1 className="text-3xl font-extrabold mt-5 pt-10">Acceptable Use Policy</h1>
       {/* Meta Tags and Page Title */}
       <Helmet>
        <title>Acceptable Usage Policy | Virtue Host</title>
        <meta name="description" content="Affordable and reliable bot hosting services. Perfect for Discord, Telegram, and other chatbots." />
        <meta name="keywords" content="Game Servers, Minecraft Hosting, Bot Hosting" />
        {/* Open Graph meta tags */}
        <meta property="og:image" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:secure_url" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="96" />
        <meta property="og:image:height" content="96" />
        <meta property="og:image:alt" content="Virtue Host Logo" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:src" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta name="twitter:title" content="AUP - Virtue Host" />
        <meta name="twitter:description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="twitter:image" content="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Link tags */}
        <link rel="canonical" href="https://virtue-host.com/" />
        <link rel="apple-touch-icon" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="manifest" href="" />
        <link rel="mask-icon" href="https://virtue-host.com/assets/images/company/logo.png" color="#55FF55" />
        <link rel="shortcut icon" href="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Microsoft-specific meta tags */}
        <meta name="msapplication-TileColor" content="#55FF55" />
        <meta name="msapplication-config" content="" />
        <meta name="theme-color" content="#55FF55" />
      </Helmet>
        <section className="mt-8">
          <p className="mb-2">
            Our Acceptable Use Policy (AUP) is designed to ensure the security, reliability, and integrity of Virtue Host's services. All users are required to adhere to this policy to maintain a safe and lawful environment for everyone.
          </p>
          <ul className="list-disc list-inside mt-4">
            <li>Prohibited activities include any action that disrupts, damages, or unauthorized access to our services or systems.</li>
            <li>We may terminate or suspend services for violations of this policy. Notice and reasons will be provided where possible, and refunds will be considered on a case-by-case basis.</li>
            <li>This AUP may be updated periodically. Users are responsible for reviewing and complying with any changes.</li>
            <li>Users must comply with all applicable laws and regulations when using our services.</li>
            <li>Activities that interfere with the operation of our services or the services of others are prohibited.</li>
            <li>Access to our services is restricted to individuals aged 13 and above, with parental consent required for those under 18.</li>
            <li>Examples of prohibited activities include, but are not limited to:</li>
            <ul className="list-disc list-inside mt-2 ml-4">
              <li>Sending unsolicited or unauthorized commercial communications (spam).</li>
              <li>Hosting, distributing, or linking to illegal, harmful, or offensive content.</li>
              <li>Transmitting malicious software, viruses, or other harmful code.</li>
              <li>Attempting unauthorized access to any network, system, account, or data.</li>
              <li>Conducting activities that disrupt or interfere with our services or networks.</li>
              <li>Violating the security or integrity of any system or network.</li>
            </ul>
            <li>Users are responsible for ensuring compliance with this policy and all applicable laws and regulations.</li>
            <li>Violations of this AUP may result in immediate termination or suspension of services, at our sole discretion.</li>
            <li>We reserve the right to cooperate with legal authorities and third parties in investigating alleged violations, including disclosing user identities.</li>
            <li>Users must ensure their usage of server resources (CPU, RAM, bandwidth) does not adversely affect other users.</li>
            <li>Users must not host or distribute any illegal, harmful, infringing, abusive, or objectionable content.</li>
            <li>Unauthorized probing, scanning, or testing of system vulnerabilities is prohibited.</li>
            <li>Users must comply with data privacy laws and not collect or store personal data without proper consent.</li>
            <li>Engaging in fraudulent activities or misrepresentation is prohibited.</li>
            <li>We may monitor user activity to ensure compliance with this AUP.</li>
            <li>Specific service-related rules included, but not limited to:</li>
            <ul className="list-disc list-inside mt-2 ml-4">
              <li>Minecraft server hosting: No unauthorized mods or plugins causing server harm.</li>
              <li>Game server hosting: No exploiting server mechanics for unfair advantages.</li>
              <li>Website hosting: No hosting malicious content or phishing activities.</li>
              <li>Bot hosting: No bots for spamming, data scraping, or unauthorized purposes.</li>
              <li>Domain services: No use for illegal activities, including phishing or distributing malware.</li>
            </ul>
          </ul>
        </section>

        <h2 className="text-2xl font-semibold mt-8">Objective of the AUP</h2>
        <p className="mb-2">
          This Acceptable Use Policy (AUP) outlines the standards and expectations for using Virtue Host's services responsibly. It aims to ensure that our services remain secure, reliable, and efficient for all users, while also adhering to legal and regulatory obligations.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Misuse of Network</h2>
        <p className="mb-2">
          Misuse of our network includes any actions that disrupt or negatively impact other users or our services. Examples include excessive use of bandwidth, unauthorized access, DDoS attacks, spamming, and port scanning. Such activities can degrade network performance and threaten security.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Harmful Activities</h2>
        <p className="mb-2">
          Harmful activities encompass any deliberate actions intended to damage, disrupt, or interfere with Virtue Host's services or the services provided to other users. This includes the introduction of malware, launching attacks against our infrastructure, or engaging in any behavior that seeks to compromise our systems or users.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Security of the Network</h2>
        <p className="mb-2">
          Users must not jeopardize the security of our network. Prohibited activities include unauthorized access, probing, scanning, or testing for vulnerabilities, as well as introducing malicious software or code. Users are encouraged to report any vulnerabilities they discover immediately, without exploiting them.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Monitoring and Compliance</h2>
        <p className="mb-2">
          We reserve the right to monitor user activities to ensure compliance with this AUP. Monitoring may involve logging traffic, inspecting communications, and analyzing usage patterns. These measures help us identify and prevent unauthorized activities, safeguarding the integrity of our network.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Policy on Restrictions</h2>
        <p className="mb-2">
          We have the authority to restrict or terminate services for users who violate this AUP, engage in illegal activities, or pose a threat to our network. Such users may be permanently barred from our platform to maintain a secure and dependable environment for all.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Cooperation with Law Enforcement</h2>
        <p className="mb-2">
          We will fully cooperate with law enforcement authorities in investigating and prosecuting illegal activities. This may involve sharing user information and activity logs with authorities as required by law, in order to protect our users and fulfill legal obligations.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Reporting Violations</h2>
        <p className="mb-2">
          If you believe your rights have been violated or that you have been unjustly accused of a violation, please reach out to us at <a href="mailto:abuse@virtue-host.com" className="text-blue-600 hover:underline">abuse@virtue-host.com</a>. We will review your complaint and take appropriate action. Our commitment is to resolve disputes fairly, with thorough investigations and transparent communication.
        </p>
      </div>
    </div>
  );
}

export default AupPage;
