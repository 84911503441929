import React from 'react';

const Technologies = () => {
  return (
    <div className="bg-gray-800 py-24 sm:py-16">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-4">
        <h1 className="text-4xl font-bold text-center capitalize lg:text-4xl mb-10 text-white">
          Utilizing the<span className="text-teal-400"> Latest</span> Technologies
        </h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            <a className="flex items-center justify-center transition-transform transform hover:scale-110">
              <img 
                src="https://cdn.virtue-host.com/company/pterodactyl_logo_transparent.webp" 
                alt="Pterodactyl" 
                className="h-20 w-auto transition-transform duration-300 ease-in-out" 
              />
            </a>
            <a className="flex items-center justify-center transition-transform transform hover:scale-110">
              <img 
                src="https://cdn.virtue-host.com/company/whmcs-logo.webp" 
                alt="WHMCS" 
                className="h-20 w-auto transition-transform duration-300 ease-in-out" 
              />
            </a>
            <a className="flex items-center justify-center transition-transform transform hover:scale-110">
              <img 
                src="https://cdn.virtue-host.com/company/2560px-Stripe_Logo%2C_revised_2016.svg.webp" 
                alt="Stripe" 
                className="h-20 w-auto transition-transform duration-300 ease-in-out" 
              />
            </a>
            <a className="flex items-center justify-center transition-transform transform hover:scale-110">
              <img 
                src="https://cdn.virtue-host.com/company/Cloudflare_Logo.svg.webp" 
                alt="Cloudflare" 
                className="h-20 w-auto transition-transform duration-300 ease-in-out" 
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
