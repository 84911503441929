import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center text-white">
      <Helmet>
        <title>404 Not Found | Virtue Host</title>
        <meta name="description" content="Page not found - Virtue Host" />
      </Helmet>
      <div className="text-center p-6 bg-gray-800 rounded-3xl shadow-lg">
        <div className="mb-4">
          <img
            src="https://cdn.virtue-host.com/company/logo.png"
            alt="Virtue Host Logo"
            className="w-18 h-auto mx-auto mb-4"
          />
          <h1 className="text-7xl font-extrabold text-teal-500">404</h1>
          <p className="text-3xl font-semibold mt-4">Oops! Page Not Found</p>
        </div>
        <p className="text-lg mb-6">It looks like the page you’re looking for doesn’t exist. It may have been moved or deleted.</p>
        <Link to="/" className="inline-block px-6 py-3 bg-gray-900 text-white font-semibold rounded-3xl border-teal-400 border hover:bg-teal-700 transition duration-300 ease-in-out">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
