import React from "react";

const MapContent = () => {
    return (

<svg id="OBJECTS" className="img-fluidbg-gray-900 max-w-screen-xl mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 662.2 246.92">
    <defs>
        <style>
            {`
            .cls-1 {
                fill: #1F2937;
            }

            .blue {
                fill: #0e9f6e;
            }

            .pulse {
                animation: pulse 2.5s infinite;
                opacity: 1;
            }

            @keyframes pulse {
                0% {
                    stroke-width: 1;
                    stroke: rgb(14, 159, 110) ;
                }

                100% {
                    stroke-width: 2;
                    stroke: rgb(14, 159, 110) ;
                }
            }

            @keyframes pulse2 {
                0% {
                    transform: scale(0.5);
                    opacity: 0.3
                }
            }
                `}
        </style>
    </defs>
    <path class="cls-1" d="M225,251.29c0,.63.75,1.09,1.68,1.05s1.67-.59,1.67-1.21-.75-1.1-1.67-1.06S225,250.67,225,251.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.63,251.57c0,.63.75,1.09,1.68,1S223,252,223,251.4s-.74-1.1-1.67-1.05S219.64,250.94,219.63,251.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M214.31,251.86c0,.62.74,1.09,1.67,1s1.67-.6,1.67-1.23-.74-1.09-1.66-1S214.31,251.23,214.31,251.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.35,253.81c0,.63.72,1.09,1.64,1s1.69-.62,1.7-1.25-.72-1.1-1.64-1S182.36,253.18,182.35,253.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177,254.17c0,.63.72,1.09,1.65,1s1.68-.63,1.7-1.26-.72-1.09-1.64-1S177,253.54,177,254.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.7,254.55c0,.63.71,1.09,1.64,1s1.68-.63,1.7-1.26-.7-1.09-1.63-1S171.72,253.92,171.7,254.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.38,254.94c0,.63.7,1.08,1.63,1s1.69-.64,1.71-1.27-.7-1.1-1.62-1S166.4,254.3,166.38,254.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.67,248c0,.63.73,1.11,1.66,1.06s1.67-.6,1.68-1.24-.72-1.12-1.65-1.07S219.68,247.36,219.67,248Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M214.35,248.28c0,.64.73,1.11,1.65,1.06s1.68-.6,1.69-1.24S217,247,216.05,247,214.36,247.64,214.35,248.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M209,248.57c0,.64.73,1.12,1.65,1.06s1.68-.6,1.69-1.24-.72-1.12-1.64-1.07S209.05,247.93,209,248.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.47,250.21c0,.64.7,1.11,1.62,1s1.69-.63,1.72-1.27-.7-1.12-1.62-1.06S182.5,249.56,182.47,250.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.16,250.57c0,.64.7,1.11,1.62,1s1.69-.63,1.72-1.27-.69-1.12-1.61-1.06S177.19,249.92,177.16,250.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.86,250.94c0,.64.69,1.11,1.61,1s1.69-.63,1.72-1.28-.69-1.12-1.6-1S171.89,250.29,171.86,250.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.55,251.32c0,.64.69,1.11,1.61,1s1.69-.64,1.72-1.28-.68-1.12-1.59-1.06S166.59,250.67,166.55,251.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.65,246.54c0,.65.68,1.13,1.6,1.07s1.69-.64,1.73-1.29-.68-1.14-1.59-1.08S182.69,245.88,182.65,246.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.36,246.89c0,.66.68,1.13,1.6,1.07s1.69-.64,1.73-1.29-.67-1.14-1.59-1.08A1.75,1.75,0,0,0,177.36,246.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.08,247.26c-.05.65.66,1.13,1.58,1.07s1.7-.65,1.74-1.3-.67-1.14-1.58-1.08S172.12,246.6,172.08,247.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.79,247.63c0,.66.66,1.14,1.58,1.07s1.7-.65,1.74-1.3-.66-1.15-1.57-1.08A1.77,1.77,0,0,0,166.79,247.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M661.91,254.76a2.7,2.7,0,0,0,2.07,1.48c.88.1,1.38-.4,1.11-1.12a2.71,2.71,0,0,0-2.08-1.48C662.14,253.54,661.65,254,661.91,254.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M656.85,254.17a2.6,2.6,0,0,0,2,1.48c.88.1,1.39-.4,1.13-1.11a2.65,2.65,0,0,0-2.06-1.48C657.09,253,656.59,253.46,656.85,254.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M651.77,253.6a2.58,2.58,0,0,0,2,1.47c.88.1,1.4-.4,1.15-1.11a2.62,2.62,0,0,0-2.05-1.47C652,252.39,651.53,252.89,651.77,253.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M646.69,253a2.52,2.52,0,0,0,2,1.46c.89.1,1.41-.4,1.17-1.11a2.54,2.54,0,0,0-2-1.46C647,251.83,646.45,252.33,646.69,253Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M641.6,252.49a2.49,2.49,0,0,0,2,1.45c.88.1,1.42-.4,1.19-1.11a2.54,2.54,0,0,0-2-1.46C641.9,251.28,641.37,251.78,641.6,252.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M214.6,240.91c0,.67.69,1.16,1.61,1.11s1.68-.62,1.71-1.28-.69-1.17-1.6-1.12S214.63,240.25,214.6,240.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M188.17,242.46c0,.66.67,1.15,1.58,1.1a1.74,1.74,0,0,0,1.74-1.31c0-.67-.66-1.16-1.58-1.11A1.75,1.75,0,0,0,188.17,242.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.9,242.8c-.05.67.66,1.15,1.57,1.09a1.74,1.74,0,0,0,1.74-1.31c0-.66-.65-1.16-1.57-1.1A1.75,1.75,0,0,0,182.9,242.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.63,243.15c-.06.67.65,1.16,1.56,1.09a1.75,1.75,0,0,0,1.75-1.31c.05-.67-.65-1.16-1.56-1.1A1.76,1.76,0,0,0,177.63,243.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.36,243.51c-.06.67.64,1.16,1.56,1.09a1.78,1.78,0,0,0,1.75-1.32c.05-.66-.64-1.16-1.56-1.1A1.79,1.79,0,0,0,172.36,243.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.09,243.88c-.05.67.64,1.16,1.56,1.09a1.78,1.78,0,0,0,1.75-1.32c.06-.67-.63-1.16-1.54-1.1A1.8,1.8,0,0,0,167.09,243.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M665.39,251.26a2.73,2.73,0,0,0,2.08,1.49c.87.11,1.34-.4,1-1.13a2.79,2.79,0,0,0-2.09-1.5C665.57,250,665.1,250.53,665.39,251.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M660.39,250.68a2.67,2.67,0,0,0,2.07,1.49c.87.1,1.35-.4,1.07-1.13a2.75,2.75,0,0,0-2.08-1.49C660.59,249.45,660.11,250,660.39,250.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M655.38,250.11a2.63,2.63,0,0,0,2,1.49c.88.1,1.37-.41,1.1-1.13a2.71,2.71,0,0,0-2.07-1.49C655.6,248.88,655.11,249.39,655.38,250.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M650.36,249.56a2.59,2.59,0,0,0,2,1.47c.88.1,1.37-.4,1.11-1.12a2.65,2.65,0,0,0-2-1.49C650.6,248.33,650.1,248.84,650.36,249.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M645.33,249a2.58,2.58,0,0,0,2,1.47c.87.09,1.38-.41,1.13-1.13a2.56,2.56,0,0,0-2-1.47C645.59,247.78,645.09,248.29,645.33,249Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M640.29,248.46a2.53,2.53,0,0,0,2,1.47c.88.09,1.4-.41,1.16-1.13a2.58,2.58,0,0,0-2-1.47C640.57,247.24,640.06,247.75,640.29,248.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.24,247.93a2.49,2.49,0,0,0,2,1.46c.88.09,1.41-.41,1.17-1.13a2.49,2.49,0,0,0-2-1.46C635.53,246.71,635,247.22,635.24,247.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.72,238.33c-.06.68.65,1.18,1.56,1.13a1.75,1.75,0,0,0,1.74-1.33c0-.67-.65-1.18-1.56-1.13A1.76,1.76,0,0,0,193.72,238.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M188.46,238.66c-.06.68.64,1.18,1.55,1.12a1.76,1.76,0,0,0,1.75-1.33c.05-.67-.64-1.18-1.55-1.12A1.75,1.75,0,0,0,188.46,238.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.2,239c-.06.68.64,1.17,1.55,1.11a1.76,1.76,0,0,0,1.75-1.33c.06-.67-.63-1.18-1.54-1.12A1.79,1.79,0,0,0,183.2,239Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178,239.34c-.06.68.63,1.18,1.54,1.12a1.8,1.8,0,0,0,1.76-1.33c.06-.68-.63-1.19-1.54-1.13A1.8,1.8,0,0,0,178,239.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.7,239.7c-.06.68.62,1.18,1.53,1.12a1.82,1.82,0,0,0,1.77-1.34c.06-.68-.62-1.19-1.53-1.12A1.82,1.82,0,0,0,172.7,239.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.46,240.07c-.07.68.61,1.17,1.52,1.11a1.82,1.82,0,0,0,1.77-1.34c.07-.68-.61-1.18-1.52-1.12A1.82,1.82,0,0,0,167.46,240.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M668.67,247.71a2.85,2.85,0,0,0,2.11,1.51c.85.1,1.3-.42,1-1.15a2.84,2.84,0,0,0-2.1-1.52C668.81,246.45,668.37,247,668.67,247.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M663.75,247.13a2.74,2.74,0,0,0,2.08,1.51c.86.1,1.32-.41,1-1.15a2.82,2.82,0,0,0-2.09-1.51C663.9,245.89,663.45,246.4,663.75,247.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M658.8,246.57a2.75,2.75,0,0,0,2.08,1.5c.86.1,1.32-.41,1-1.15a2.75,2.75,0,0,0-2.08-1.5C659,245.33,658.52,245.84,658.8,246.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M653.85,246a2.71,2.71,0,0,0,2.06,1.49c.86.09,1.34-.42,1.05-1.15a2.67,2.67,0,0,0-2.06-1.49C654,244.77,653.58,245.29,653.85,246Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M648.89,245.47a2.61,2.61,0,0,0,2,1.48c.87.1,1.35-.41,1.08-1.14a2.67,2.67,0,0,0-2-1.49C649.1,244.23,648.62,244.74,648.89,245.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M643.91,244.93a2.6,2.6,0,0,0,2,1.48c.87.09,1.36-.42,1.1-1.14a2.64,2.64,0,0,0-2-1.49C644.14,243.69,643.65,244.21,643.91,244.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M638.92,244.4a2.54,2.54,0,0,0,2,1.47c.87.09,1.37-.41,1.12-1.14a2.6,2.6,0,0,0-2-1.48C639.17,243.16,638.67,243.68,638.92,244.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M633.92,243.88a2.5,2.5,0,0,0,2,1.46c.87.09,1.38-.42,1.13-1.14a2.51,2.51,0,0,0-2-1.47C634.19,242.64,633.68,243.15,633.92,243.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M593.54,240a2.23,2.23,0,0,0,1.92,1.42c.88.08,1.46-.43,1.27-1.14a2.24,2.24,0,0,0-1.92-1.43C593.93,238.77,593.37,239.29,593.54,240Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M199.28,234.16c-.06.69.64,1.2,1.55,1.15a1.77,1.77,0,0,0,1.75-1.34c0-.69-.64-1.2-1.55-1.15A1.76,1.76,0,0,0,199.28,234.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M194,234.48c-.06.68.63,1.19,1.54,1.14a1.77,1.77,0,0,0,1.76-1.34c.06-.69-.63-1.21-1.54-1.15A1.78,1.78,0,0,0,194,234.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M188.79,234.8c-.06.69.63,1.2,1.54,1.14a1.78,1.78,0,0,0,1.75-1.34c.07-.69-.62-1.21-1.52-1.15A1.81,1.81,0,0,0,188.79,234.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.56,235.13c-.07.69.61,1.2,1.52,1.14a1.81,1.81,0,0,0,1.77-1.35c.06-.68-.62-1.2-1.52-1.14A1.82,1.82,0,0,0,183.56,235.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178.33,235.48c-.07.69.61,1.19,1.51,1.13a1.81,1.81,0,0,0,1.77-1.35c.07-.69-.6-1.2-1.51-1.14A1.83,1.83,0,0,0,178.33,235.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.1,235.83c-.07.69.6,1.2,1.51,1.14a1.83,1.83,0,0,0,1.77-1.36c.08-.69-.59-1.2-1.5-1.14A1.85,1.85,0,0,0,173.1,235.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.88,236.2c-.08.69.6,1.19,1.5,1.13a1.85,1.85,0,0,0,1.78-1.36c.08-.69-.59-1.2-1.49-1.14A1.87,1.87,0,0,0,167.88,236.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M666.92,243.54a2.83,2.83,0,0,0,2.1,1.51c.85.1,1.27-.42,1-1.16a2.87,2.87,0,0,0-2.1-1.52C667,242.27,666.61,242.8,666.92,243.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M662.05,243a2.79,2.79,0,0,0,2.09,1.51c.84.1,1.28-.42,1-1.16a2.84,2.84,0,0,0-2.09-1.52C662.18,241.72,661.74,242.24,662.05,243Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M657.16,242.43a2.77,2.77,0,0,0,2.08,1.5c.85.1,1.3-.42,1-1.16a2.78,2.78,0,0,0-2.07-1.51C657.31,241.17,656.87,241.69,657.16,242.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M652.27,241.88a2.66,2.66,0,0,0,2.06,1.5c.85.1,1.31-.42,1-1.16a2.71,2.71,0,0,0-2.06-1.5C652.43,240.62,652,241.15,652.27,241.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M647.35,241.34a2.66,2.66,0,0,0,2,1.5c.86.09,1.32-.42,1-1.16a2.68,2.68,0,0,0-2.05-1.5C647.54,240.09,647.08,240.61,647.35,241.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M642.43,240.82a2.59,2.59,0,0,0,2,1.48c.86.1,1.34-.42,1.06-1.15a2.63,2.63,0,0,0-2-1.5C642.63,239.56,642.16,240.08,642.43,240.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M637.49,240.29a2.57,2.57,0,0,0,2,1.49c.86.09,1.35-.43,1.08-1.16a2.59,2.59,0,0,0-2-1.49C637.71,239,637.23,239.56,637.49,240.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M632.54,239.78a2.53,2.53,0,0,0,2,1.48c.86.09,1.36-.43,1.1-1.16a2.56,2.56,0,0,0-2-1.48C632.78,238.53,632.29,239.05,632.54,239.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M627.58,239.27a2.48,2.48,0,0,0,2,1.48c.86.08,1.36-.43,1.12-1.16a2.53,2.53,0,0,0-2-1.48C627.83,238,627.34,238.55,627.58,239.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M622.6,238.78a2.43,2.43,0,0,0,2,1.46c.87.09,1.38-.43,1.14-1.15a2.47,2.47,0,0,0-2-1.47C622.87,237.53,622.37,238.05,622.6,238.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M612.62,237.81a2.35,2.35,0,0,0,2,1.45c.87.09,1.4-.43,1.18-1.15a2.4,2.4,0,0,0-2-1.46C612.92,236.56,612.4,237.08,612.62,237.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M607.6,237.33a2.35,2.35,0,0,0,2,1.45c.87.09,1.41-.43,1.19-1.15a2.36,2.36,0,0,0-2-1.46C607.92,236.09,607.4,236.61,607.6,237.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M602.58,236.87a2.35,2.35,0,0,0,1.95,1.45c.87.08,1.42-.44,1.21-1.16a2.34,2.34,0,0,0-2-1.45C602.92,235.63,602.38,236.15,602.58,236.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M597.55,236.41a2.28,2.28,0,0,0,1.94,1.44c.87.08,1.42-.43,1.22-1.15a2.31,2.31,0,0,0-1.94-1.45C597.9,235.17,597.36,235.69,597.55,236.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M592.51,236a2.26,2.26,0,0,0,1.92,1.43c.88.08,1.44-.44,1.25-1.15a2.29,2.29,0,0,0-1.94-1.45C592.87,234.73,592.32,235.25,592.51,236Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.86,230c-.06.69.62,1.21,1.53,1.16a1.77,1.77,0,0,0,1.76-1.35c.06-.7-.63-1.22-1.53-1.17A1.78,1.78,0,0,0,204.86,230Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M199.63,230.25c-.07.7.62,1.21,1.53,1.16a1.78,1.78,0,0,0,1.75-1.35c.07-.7-.61-1.23-1.52-1.17A1.8,1.8,0,0,0,199.63,230.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M194.41,230.56c-.08.7.61,1.21,1.51,1.16a1.8,1.8,0,0,0,1.77-1.36c.06-.7-.61-1.22-1.52-1.17A1.81,1.81,0,0,0,194.41,230.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.19,230.88c-.08.7.6,1.22,1.51,1.16a1.81,1.81,0,0,0,1.76-1.36c.08-.7-.6-1.22-1.5-1.17A1.83,1.83,0,0,0,189.19,230.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M184,231.21c-.08.7.6,1.22,1.5,1.16a1.84,1.84,0,0,0,1.78-1.37c.07-.7-.6-1.22-1.5-1.16A1.85,1.85,0,0,0,184,231.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178.77,231.55c-.09.7.58,1.22,1.49,1.16a1.85,1.85,0,0,0,1.78-1.37c.08-.7-.59-1.22-1.49-1.16A1.85,1.85,0,0,0,178.77,231.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.56,231.91c-.08.69.58,1.21,1.49,1.15a1.86,1.86,0,0,0,1.78-1.38c.08-.7-.57-1.22-1.48-1.16A1.89,1.89,0,0,0,173.56,231.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M665.11,239.34a2.91,2.91,0,0,0,2.1,1.52c.84.1,1.25-.43.92-1.18a2.89,2.89,0,0,0-2.1-1.52C665.2,238.06,664.79,238.59,665.11,239.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M660.3,238.79a2.82,2.82,0,0,0,2.08,1.52c.84.09,1.27-.43.94-1.18a2.83,2.83,0,0,0-2.09-1.52C660.4,237.51,660,238,660.3,238.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M655.47,238.25a2.78,2.78,0,0,0,2.07,1.51c.84.09,1.27-.43,1-1.18a2.79,2.79,0,0,0-2.07-1.51C655.59,237,655.16,237.5,655.47,238.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M650.62,237.71a2.74,2.74,0,0,0,2.06,1.51c.85.09,1.29-.43,1-1.17a2.76,2.76,0,0,0-2.07-1.52C650.76,236.44,650.33,237,650.62,237.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M645.76,237.18a2.71,2.71,0,0,0,2,1.51c.85.09,1.3-.44,1-1.18a2.72,2.72,0,0,0-2.06-1.5C645.92,235.92,645.47,236.44,645.76,237.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M640.89,236.66a2.63,2.63,0,0,0,2,1.5c.85.09,1.31-.43,1-1.17a2.67,2.67,0,0,0-2-1.5C641.06,235.4,640.61,235.93,640.89,236.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M636,236.15a2.58,2.58,0,0,0,2,1.49c.86.09,1.32-.43,1.05-1.17a2.59,2.59,0,0,0-2-1.49C636.19,234.89,635.73,235.41,636,236.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M631.1,235.65a2.54,2.54,0,0,0,2,1.48c.85.09,1.33-.43,1.07-1.17a2.62,2.62,0,0,0-2-1.49C631.31,234.38,630.83,234.91,631.1,235.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M626.18,235.15a2.53,2.53,0,0,0,2,1.48c.86.09,1.35-.44,1.09-1.17a2.57,2.57,0,0,0-2-1.49C626.41,233.89,625.93,234.41,626.18,235.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M621.25,234.66a2.48,2.48,0,0,0,2,1.48c.86.08,1.36-.44,1.11-1.17a2.52,2.52,0,0,0-2-1.49C621.5,233.4,621,233.93,621.25,234.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.31,234.18a2.42,2.42,0,0,0,2,1.47c.86.08,1.37-.44,1.12-1.17a2.46,2.46,0,0,0-2-1.48C616.57,232.92,616.07,233.45,616.31,234.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M611.35,233.7a2.41,2.41,0,0,0,2,1.47c.86.08,1.37-.44,1.14-1.17a2.45,2.45,0,0,0-2-1.47C611.63,232.44,611.12,233,611.35,233.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M606.38,233.24a2.37,2.37,0,0,0,2,1.46c.86.08,1.38-.44,1.16-1.17a2.41,2.41,0,0,0-2-1.47C606.68,232,606.16,232.51,606.38,233.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M601.4,232.78a2.36,2.36,0,0,0,2,1.46c.87.08,1.39-.45,1.17-1.17a2.37,2.37,0,0,0-2-1.47C601.71,231.52,601.19,232.05,601.4,232.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M596.41,232.33a2.34,2.34,0,0,0,2,1.45c.87.08,1.4-.44,1.19-1.17a2.34,2.34,0,0,0-1.95-1.46C596.74,231.07,596.2,231.6,596.41,232.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M591.41,231.89a2.29,2.29,0,0,0,1.93,1.45c.88.07,1.42-.45,1.21-1.18a2.29,2.29,0,0,0-1.94-1.45C591.75,230.63,591.21,231.16,591.41,231.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M379.05,221.76a1.58,1.58,0,0,0,1.7,1.29c.92,0,1.64-.55,1.62-1.26a1.59,1.59,0,0,0-1.71-1.29A1.43,1.43,0,0,0,379.05,221.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210.46,225.68c-.07.71.61,1.24,1.52,1.19a1.77,1.77,0,0,0,1.75-1.36c.07-.71-.61-1.25-1.51-1.2A1.79,1.79,0,0,0,210.46,225.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M205.24,226c-.07.71.61,1.24,1.51,1.19a1.8,1.8,0,0,0,1.77-1.37c.07-.71-.61-1.24-1.51-1.19A1.82,1.82,0,0,0,205.24,226Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200,226.27c-.07.71.6,1.24,1.51,1.19a1.81,1.81,0,0,0,1.76-1.38c.08-.7-.59-1.24-1.49-1.19A1.85,1.85,0,0,0,200,226.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M194.83,226.58c-.08.71.59,1.24,1.49,1.18a1.82,1.82,0,0,0,1.78-1.37c.07-.71-.59-1.24-1.49-1.19A1.86,1.86,0,0,0,194.83,226.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.63,226.9c-.08.71.59,1.24,1.49,1.18a1.83,1.83,0,0,0,1.78-1.38c.08-.71-.58-1.24-1.48-1.18A1.87,1.87,0,0,0,189.63,226.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M184.44,227.23c-.09.71.58,1.24,1.48,1.18A1.86,1.86,0,0,0,187.7,227c.09-.71-.57-1.24-1.47-1.19A1.89,1.89,0,0,0,184.44,227.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.26,227.57c-.09.71.56,1.24,1.46,1.18a1.87,1.87,0,0,0,1.79-1.39c.09-.71-.56-1.24-1.46-1.18A1.89,1.89,0,0,0,179.26,227.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.08,227.92c-.09.71.56,1.23,1.46,1.17a1.88,1.88,0,0,0,1.79-1.39c.1-.71-.55-1.24-1.45-1.18A1.92,1.92,0,0,0,174.08,227.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M663.25,235.1a2.91,2.91,0,0,0,2.1,1.53c.83.1,1.23-.43.89-1.18a2.94,2.94,0,0,0-2.11-1.54C663.32,233.82,662.92,234.35,663.25,235.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M658.49,234.56a2.84,2.84,0,0,0,2.09,1.53c.83.09,1.24-.44.9-1.19a2.88,2.88,0,0,0-2.09-1.53C658.57,233.28,658.17,233.81,658.49,234.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M653.71,234a2.81,2.81,0,0,0,2.08,1.52c.83.09,1.25-.44.93-1.18a2.84,2.84,0,0,0-2.08-1.53C653.81,232.75,653.4,233.28,653.71,234Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M648.92,233.51A2.75,2.75,0,0,0,651,235c.84.09,1.26-.44.95-1.18a2.76,2.76,0,0,0-2.06-1.52C649,232.23,648.62,232.76,648.92,233.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M644.11,233a2.72,2.72,0,0,0,2,1.51c.84.09,1.28-.44,1-1.19a2.74,2.74,0,0,0-2-1.51C644.25,231.71,643.81,232.24,644.11,233Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M639.29,232.48a2.66,2.66,0,0,0,2,1.5c.84.09,1.28-.44,1-1.18a2.67,2.67,0,0,0-2.05-1.51C639.44,231.2,639,231.73,639.29,232.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M634.45,232a2.61,2.61,0,0,0,2,1.5c.84.09,1.29-.44,1-1.18a2.66,2.66,0,0,0-2-1.51C634.62,230.69,634.17,231.23,634.45,232Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M629.59,231.47a2.59,2.59,0,0,0,2,1.5c.85.09,1.31-.44,1-1.19a2.63,2.63,0,0,0-2-1.5C629.78,230.2,629.32,230.73,629.59,231.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M624.72,231a2.53,2.53,0,0,0,2,1.49c.85.09,1.32-.44,1-1.18a2.59,2.59,0,0,0-2-1.5C624.93,229.71,624.46,230.24,624.72,231Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M619.84,230.5a2.51,2.51,0,0,0,2,1.49c.85.08,1.33-.45,1.07-1.19a2.55,2.55,0,0,0-2-1.49C620.06,229.23,619.58,229.76,619.84,230.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M614.94,230a2.47,2.47,0,0,0,2,1.49c.85.08,1.34-.45,1.09-1.19a2.54,2.54,0,0,0-2-1.49C615.18,228.75,614.69,229.29,614.94,230Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M610,229.56a2.47,2.47,0,0,0,2,1.48c.85.08,1.35-.45,1.1-1.19a2.44,2.44,0,0,0-2-1.48C610.28,228.29,609.79,228.82,610,229.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M605.1,229.1a2.41,2.41,0,0,0,2,1.47c.86.08,1.36-.45,1.13-1.18a2.44,2.44,0,0,0-2-1.48C605.37,227.83,604.87,228.36,605.1,229.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M600.16,228.65a2.39,2.39,0,0,0,2,1.47c.86.07,1.38-.46,1.15-1.19a2.42,2.42,0,0,0-2-1.48C600.44,227.38,599.94,227.91,600.16,228.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M595.21,228.2a2.35,2.35,0,0,0,1.95,1.47c.86.07,1.39-.46,1.16-1.19a2.36,2.36,0,0,0-2-1.47C595.51,226.93,595,227.47,595.21,228.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M590.25,227.77a2.33,2.33,0,0,0,1.94,1.46c.86.07,1.39-.46,1.18-1.19a2.37,2.37,0,0,0-2-1.47C590.56,226.5,590,227,590.25,227.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M585.27,227.34a2.3,2.3,0,0,0,1.93,1.46c.87.07,1.41-.46,1.2-1.19a2.32,2.32,0,0,0-1.94-1.47C585.59,226.07,585.06,226.61,585.27,227.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.69,217.92a1.63,1.63,0,0,0,1.72,1.31c.91,0,1.62-.55,1.59-1.27a1.64,1.64,0,0,0-1.72-1.32A1.4,1.4,0,0,0,394.69,217.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.43,217.85a1.62,1.62,0,0,0,1.72,1.31c.91,0,1.62-.55,1.59-1.27a1.62,1.62,0,0,0-1.72-1.32C390.11,216.56,389.4,217.13,389.43,217.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384.17,217.79a1.6,1.6,0,0,0,1.71,1.31c.91,0,1.63-.56,1.6-1.28a1.61,1.61,0,0,0-1.71-1.31C384.86,216.5,384.14,217.07,384.17,217.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.91,217.74a1.59,1.59,0,0,0,1.7,1.31,1.43,1.43,0,0,0,1.61-1.28,1.6,1.6,0,0,0-1.71-1.31A1.42,1.42,0,0,0,378.91,217.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.64,217.7a1.59,1.59,0,0,0,1.7,1.31,1.43,1.43,0,0,0,1.61-1.28,1.58,1.58,0,0,0-1.7-1.31A1.44,1.44,0,0,0,373.64,217.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368.38,217.68a1.57,1.57,0,0,0,1.69,1.3c.91,0,1.64-.57,1.61-1.29a1.57,1.57,0,0,0-1.69-1.3A1.44,1.44,0,0,0,368.38,217.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M216.06,221.38c-.07.72.61,1.26,1.51,1.21a1.79,1.79,0,0,0,1.76-1.38c.07-.72-.6-1.26-1.5-1.22A1.81,1.81,0,0,0,216.06,221.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210.86,221.66c-.07.71.6,1.25,1.5,1.2a1.79,1.79,0,0,0,1.77-1.38c.07-.71-.6-1.26-1.5-1.21A1.82,1.82,0,0,0,210.86,221.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M205.67,222c-.08.71.59,1.25,1.49,1.2a1.82,1.82,0,0,0,1.77-1.39c.08-.71-.58-1.26-1.48-1.21A1.84,1.84,0,0,0,205.67,222Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200.49,222.25c-.09.71.57,1.25,1.48,1.2a1.84,1.84,0,0,0,1.77-1.39c.08-.72-.58-1.26-1.47-1.21A1.84,1.84,0,0,0,200.49,222.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.31,222.55c-.09.72.57,1.26,1.47,1.2a1.84,1.84,0,0,0,1.78-1.39c.08-.72-.57-1.26-1.47-1.21A1.87,1.87,0,0,0,195.31,222.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.13,222.87c-.09.72.56,1.25,1.46,1.2a1.87,1.87,0,0,0,1.79-1.4c.09-.72-.56-1.26-1.45-1.2A1.89,1.89,0,0,0,190.13,222.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185,223.2c-.1.72.55,1.25,1.45,1.19a1.87,1.87,0,0,0,1.79-1.4c.09-.71-.55-1.25-1.45-1.2A1.89,1.89,0,0,0,185,223.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.81,223.54c-.1.72.54,1.25,1.44,1.19a1.91,1.91,0,0,0,1.8-1.41c.1-.71-.54-1.25-1.44-1.19A1.91,1.91,0,0,0,179.81,223.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.66,223.88c-.11.72.53,1.25,1.43,1.19a1.92,1.92,0,0,0,1.8-1.4c.11-.72-.53-1.26-1.42-1.2A1.94,1.94,0,0,0,174.66,223.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.51,224.24c-.11.72.53,1.25,1.42,1.19a1.93,1.93,0,0,0,1.81-1.41c.11-.72-.52-1.26-1.41-1.2A2,2,0,0,0,169.51,224.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M661.34,230.84a2.92,2.92,0,0,0,2.1,1.54c.82.09,1.2-.44.85-1.2a2.93,2.93,0,0,0-2.1-1.54C661.38,229.55,661,230.09,661.34,230.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M651.91,229.79a2.81,2.81,0,0,0,2.07,1.52c.83.1,1.23-.44.9-1.19a2.86,2.86,0,0,0-2.08-1.54C652,228.49,651.58,229,651.91,229.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M647.16,229.27a2.79,2.79,0,0,0,2.07,1.52c.83.09,1.24-.44.91-1.2a2.79,2.79,0,0,0-2.06-1.52C647.26,228,646.85,228.52,647.16,229.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M642.4,228.76a2.76,2.76,0,0,0,2.06,1.52c.83.09,1.25-.45.94-1.2a2.77,2.77,0,0,0-2.06-1.52C642.52,227.47,642.1,228,642.4,228.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M637.63,228.25a2.71,2.71,0,0,0,2,1.52c.83.09,1.26-.45,1-1.2a2.73,2.73,0,0,0-2-1.52C637.76,227,637.33,227.5,637.63,228.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M632.84,227.75a2.66,2.66,0,0,0,2,1.52c.83.08,1.27-.45,1-1.2a2.71,2.71,0,0,0-2-1.52C633,226.47,632.55,227,632.84,227.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M628,227.26a2.62,2.62,0,0,0,2,1.51c.84.08,1.29-.45,1-1.2a2.66,2.66,0,0,0-2-1.51C628.19,226,627.75,226.52,628,227.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M623.2,226.78a2.58,2.58,0,0,0,2,1.5c.84.09,1.29-.45,1-1.2a2.59,2.59,0,0,0-2-1.5C623.38,225.49,622.93,226,623.2,226.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M618.36,226.3a2.54,2.54,0,0,0,2,1.5c.84.08,1.31-.45,1-1.2a2.57,2.57,0,0,0-2-1.5C618.56,225,618.1,225.56,618.36,226.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.51,225.83a2.51,2.51,0,0,0,2,1.5c.85.08,1.32-.46,1.06-1.2a2.52,2.52,0,0,0-2-1.5C613.72,224.55,613.25,225.09,613.51,225.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M608.64,225.37a2.47,2.47,0,0,0,2,1.49c.84.08,1.32-.45,1.07-1.2a2.52,2.52,0,0,0-2-1.49C608.87,224.09,608.39,224.63,608.64,225.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603.76,224.92a2.43,2.43,0,0,0,2,1.48c.85.08,1.34-.45,1.1-1.2a2.49,2.49,0,0,0-2-1.49C604,223.63,603.51,224.18,603.76,224.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M598.86,224.47a2.4,2.4,0,0,0,2,1.48c.85.08,1.35-.46,1.11-1.2a2.43,2.43,0,0,0-2-1.48C599.12,223.19,598.62,223.73,598.86,224.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M594,224a2.39,2.39,0,0,0,2,1.48c.85.08,1.36-.46,1.12-1.2a2.38,2.38,0,0,0-2-1.48C594.22,222.75,593.72,223.29,594,224Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M589,223.6a2.34,2.34,0,0,0,2,1.47c.86.08,1.37-.46,1.15-1.2a2.4,2.4,0,0,0-2-1.48C589.31,222.32,588.8,222.86,589,223.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M584.08,223.18a2.35,2.35,0,0,0,1.95,1.47c.86.07,1.38-.47,1.16-1.21a2.34,2.34,0,0,0-2-1.47C584.38,221.9,583.87,222.44,584.08,223.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M579.13,222.76a2.29,2.29,0,0,0,1.94,1.47c.86.07,1.39-.47,1.17-1.21a2.3,2.3,0,0,0-1.94-1.47C579.45,221.48,578.92,222,579.13,222.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.48,213.84a1.64,1.64,0,0,0,1.72,1.33c.91,0,1.62-.56,1.57-1.29a1.64,1.64,0,0,0-1.71-1.34A1.41,1.41,0,0,0,394.48,213.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.24,213.77A1.63,1.63,0,0,0,391,215.1c.91,0,1.62-.56,1.58-1.29a1.62,1.62,0,0,0-1.71-1.34A1.41,1.41,0,0,0,389.24,213.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384,213.71a1.6,1.6,0,0,0,1.71,1.33c.91,0,1.62-.57,1.58-1.29a1.61,1.61,0,0,0-1.7-1.34A1.42,1.42,0,0,0,384,213.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.75,213.66a1.6,1.6,0,0,0,1.7,1.33,1.42,1.42,0,0,0,1.6-1.3,1.62,1.62,0,0,0-1.7-1.33A1.43,1.43,0,0,0,378.75,213.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.51,213.63A1.58,1.58,0,0,0,375.2,215a1.42,1.42,0,0,0,1.6-1.3,1.6,1.6,0,0,0-1.69-1.33A1.44,1.44,0,0,0,373.51,213.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M216.49,217.3c-.08.73.59,1.27,1.49,1.23a1.82,1.82,0,0,0,1.77-1.4c.07-.72-.59-1.28-1.49-1.23A1.84,1.84,0,0,0,216.49,217.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M211.32,217.58c-.09.73.58,1.27,1.47,1.22a1.83,1.83,0,0,0,1.78-1.4c.08-.72-.58-1.27-1.47-1.23A1.84,1.84,0,0,0,211.32,217.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.15,217.87c-.09.72.57,1.27,1.47,1.22a1.84,1.84,0,0,0,1.77-1.4c.09-.73-.56-1.28-1.46-1.23A1.86,1.86,0,0,0,206.15,217.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201,218.17c-.1.72.56,1.27,1.45,1.21a1.86,1.86,0,0,0,1.79-1.4c.09-.73-.56-1.28-1.45-1.23A1.88,1.88,0,0,0,201,218.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.83,218.47c-.1.73.55,1.27,1.45,1.22a1.9,1.9,0,0,0,1.79-1.41c.09-.73-.55-1.28-1.44-1.22A1.89,1.89,0,0,0,195.83,218.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.68,218.79c-.1.73.55,1.27,1.44,1.21a1.89,1.89,0,0,0,1.8-1.41c.1-.73-.54-1.27-1.44-1.22A1.92,1.92,0,0,0,190.68,218.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.54,219.12c-.1.72.54,1.26,1.43,1.21a1.91,1.91,0,0,0,1.8-1.42c.1-.73-.53-1.27-1.42-1.22A1.94,1.94,0,0,0,185.54,219.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M180.41,219.45c-.11.73.52,1.27,1.42,1.21a1.91,1.91,0,0,0,1.8-1.42c.11-.73-.52-1.27-1.41-1.21A1.93,1.93,0,0,0,180.41,219.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.29,219.8c-.12.72.51,1.26,1.4,1.2a1.93,1.93,0,0,0,1.81-1.42c.12-.73-.51-1.27-1.39-1.21A2,2,0,0,0,175.29,219.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170.17,220.15c-.12.73.5,1.26,1.39,1.2a2,2,0,0,0,1.82-1.42c.12-.73-.5-1.27-1.38-1.21A2,2,0,0,0,170.17,220.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M650.05,225.51a2.85,2.85,0,0,0,2.07,1.54c.82.09,1.21-.45.87-1.21a2.9,2.9,0,0,0-2.08-1.54C650.1,224.21,649.71,224.75,650.05,225.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M645.35,225a2.82,2.82,0,0,0,2.07,1.53c.82.09,1.21-.45.88-1.21a2.79,2.79,0,0,0-2.07-1.53C645.42,223.7,645,224.24,645.35,225Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M640.64,224.5A2.79,2.79,0,0,0,642.7,226c.82.09,1.23-.45.9-1.21a2.78,2.78,0,0,0-2.06-1.53C640.73,223.19,640.33,223.74,640.64,224.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.91,224a2.76,2.76,0,0,0,2.05,1.52c.82.09,1.24-.45.93-1.21a2.78,2.78,0,0,0-2-1.53C636,222.7,635.61,223.24,635.91,224Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M631.17,223.51a2.68,2.68,0,0,0,2,1.51c.83.09,1.26-.45.95-1.21a2.73,2.73,0,0,0-2-1.52C631.29,222.21,630.87,222.75,631.17,223.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M626.41,223a2.66,2.66,0,0,0,2,1.52c.83.08,1.27-.46,1-1.22a2.68,2.68,0,0,0-2-1.51C626.54,221.72,626.11,222.27,626.41,223Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M621.63,222.54a2.59,2.59,0,0,0,2,1.51c.84.09,1.28-.46,1-1.21a2.64,2.64,0,0,0-2-1.51C621.78,221.24,621.34,221.79,621.63,222.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.83,222.07a2.59,2.59,0,0,0,2,1.51c.84.08,1.29-.46,1-1.21a2.64,2.64,0,0,0-2-1.52C617,220.77,616.56,221.32,616.83,222.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M612,221.61a2.54,2.54,0,0,0,2,1.5c.84.08,1.3-.46,1-1.21a2.56,2.56,0,0,0-2-1.51C612.21,220.31,611.75,220.86,612,221.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M607.19,221.15a2.51,2.51,0,0,0,2,1.5c.83.08,1.3-.46,1-1.21a2.55,2.55,0,0,0-2-1.51C607.4,219.85,606.93,220.4,607.19,221.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M602.35,220.7a2.48,2.48,0,0,0,2,1.5c.85.07,1.32-.47,1.06-1.22a2.48,2.48,0,0,0-2-1.5C602.57,219.4,602.1,220,602.35,220.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M597.49,220.26a2.45,2.45,0,0,0,2,1.49c.84.08,1.33-.47,1.08-1.22a2.47,2.47,0,0,0-2-1.49C597.73,219,597.25,219.51,597.49,220.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M592.62,219.82a2.4,2.4,0,0,0,2,1.49c.85.08,1.34-.47,1.09-1.21a2.43,2.43,0,0,0-2-1.5C592.87,218.53,592.38,219.08,592.62,219.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.74,219.4a2.37,2.37,0,0,0,2,1.48c.85.08,1.36-.47,1.12-1.22a2.44,2.44,0,0,0-2-1.49C588,218.1,587.5,218.65,587.74,219.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M582.84,219a2.34,2.34,0,0,0,1.95,1.48c.85.07,1.36-.47,1.13-1.22a2.39,2.39,0,0,0-2-1.49C583.11,217.68,582.61,218.23,582.84,219Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M548.16,216.27a2.19,2.19,0,0,0,1.9,1.46c.87.06,1.42-.49,1.24-1.23a2.21,2.21,0,0,0-1.9-1.47C548.53,215,548,215.53,548.16,216.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.47,209.78a1.67,1.67,0,0,0,1.72,1.36c.91,0,1.61-.57,1.56-1.31a1.68,1.68,0,0,0-1.73-1.36A1.38,1.38,0,0,0,399.47,209.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.26,209.7a1.64,1.64,0,0,0,1.71,1.36c.91,0,1.61-.57,1.56-1.31a1.65,1.65,0,0,0-1.71-1.36A1.38,1.38,0,0,0,394.26,209.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389,209.64a1.65,1.65,0,0,0,1.71,1.35,1.39,1.39,0,0,0,1.57-1.31,1.63,1.63,0,0,0-1.71-1.36A1.41,1.41,0,0,0,389,209.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.81,209.58a1.62,1.62,0,0,0,1.71,1.35,1.4,1.4,0,0,0,1.57-1.32,1.62,1.62,0,0,0-1.7-1.35A1.42,1.42,0,0,0,383.81,209.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.59,209.53a1.6,1.6,0,0,0,1.69,1.35,1.43,1.43,0,0,0,1.59-1.32,1.63,1.63,0,0,0-1.7-1.35A1.42,1.42,0,0,0,378.59,209.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.36,209.5a1.59,1.59,0,0,0,1.69,1.34,1.44,1.44,0,0,0,1.59-1.32,1.6,1.6,0,0,0-1.69-1.35A1.44,1.44,0,0,0,373.36,209.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368.13,209.47a1.58,1.58,0,0,0,1.68,1.34,1.45,1.45,0,0,0,1.6-1.32,1.57,1.57,0,0,0-1.68-1.34A1.44,1.44,0,0,0,368.13,209.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.9,209.46a1.56,1.56,0,0,0,1.68,1.33,1.45,1.45,0,0,0,1.6-1.32,1.56,1.56,0,0,0-1.68-1.34A1.46,1.46,0,0,0,362.9,209.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M222.13,212.91c-.09.73.57,1.29,1.47,1.24a1.81,1.81,0,0,0,1.77-1.41c.08-.73-.58-1.29-1.47-1.25A1.82,1.82,0,0,0,222.13,212.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M217,213.17c-.09.74.57,1.29,1.46,1.25a1.84,1.84,0,0,0,1.78-1.42c.08-.73-.57-1.29-1.46-1.24A1.84,1.84,0,0,0,217,213.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M211.82,213.45c-.1.74.56,1.29,1.45,1.24a1.85,1.85,0,0,0,1.78-1.41c.09-.74-.56-1.3-1.45-1.25A1.86,1.86,0,0,0,211.82,213.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.67,213.74c-.09.73.55,1.28,1.45,1.23a1.86,1.86,0,0,0,1.78-1.41c.1-.74-.55-1.3-1.44-1.25A1.88,1.88,0,0,0,206.67,213.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201.54,214c-.11.74.54,1.29,1.43,1.24a1.89,1.89,0,0,0,1.79-1.42c.1-.74-.54-1.29-1.43-1.24A1.89,1.89,0,0,0,201.54,214Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M196.41,214.34c-.11.74.53,1.29,1.42,1.23a1.91,1.91,0,0,0,1.8-1.42c.1-.74-.53-1.29-1.42-1.24A1.91,1.91,0,0,0,196.41,214.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.29,214.66c-.12.73.52,1.28,1.41,1.22a1.91,1.91,0,0,0,1.8-1.42c.11-.74-.52-1.29-1.41-1.24A1.94,1.94,0,0,0,191.29,214.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M186.17,215c-.11.74.51,1.28,1.4,1.23a1.94,1.94,0,0,0,1.81-1.43c.12-.74-.51-1.29-1.39-1.24A2,2,0,0,0,186.17,215Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.07,215.32c-.12.73.5,1.28,1.39,1.22a2,2,0,0,0,1.81-1.44c.12-.73-.5-1.28-1.38-1.23A2,2,0,0,0,181.07,215.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176,215.66c-.12.73.49,1.28,1.38,1.22a2,2,0,0,0,1.82-1.44c.12-.73-.49-1.28-1.37-1.22A2,2,0,0,0,176,215.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170.88,216c-.13.74.48,1.28,1.37,1.22a2,2,0,0,0,1.83-1.44c.12-.74-.48-1.29-1.36-1.23A2,2,0,0,0,170.88,216Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M652.76,221.72a3,3,0,0,0,2.09,1.55c.8.08,1.17-.46.81-1.23a3,3,0,0,0-2.09-1.55C652.77,220.41,652.41,221,652.76,221.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M648.14,221.21a2.88,2.88,0,0,0,2.07,1.54c.81.09,1.18-.46.83-1.22A2.89,2.89,0,0,0,649,220C648.16,219.89,647.79,220.44,648.14,221.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M643.49,220.7a2.82,2.82,0,0,0,2.07,1.54c.81.09,1.19-.46.85-1.22a2.86,2.86,0,0,0-2.07-1.54C643.54,219.39,643.16,219.94,643.49,220.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M638.83,220.2a2.77,2.77,0,0,0,2.06,1.54c.81.08,1.2-.46.87-1.22A2.8,2.8,0,0,0,639.7,219C638.89,218.89,638.5,219.44,638.83,220.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M634.15,219.71a2.73,2.73,0,0,0,2,1.53c.82.09,1.22-.46.9-1.22a2.8,2.8,0,0,0-2.05-1.54C634.23,218.4,633.83,219,634.15,219.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M629.45,219.22a2.69,2.69,0,0,0,2,1.53c.81.09,1.22-.46.91-1.22a2.74,2.74,0,0,0-2-1.53C629.54,217.91,629.14,218.46,629.45,219.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M624.73,218.74a2.7,2.7,0,0,0,2,1.53c.82.08,1.24-.47.93-1.23a2.68,2.68,0,0,0-2-1.52C624.84,217.43,624.43,218,624.73,218.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M620,218.27a2.65,2.65,0,0,0,2,1.52c.82.08,1.25-.46,1-1.22a2.68,2.68,0,0,0-2-1.53C620.12,217,619.7,217.51,620,218.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M615.24,217.8a2.62,2.62,0,0,0,2,1.52c.82.08,1.26-.47,1-1.22a2.63,2.63,0,0,0-2-1.52A.84.84,0,0,0,615.24,217.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M610.47,217.34a2.59,2.59,0,0,0,2,1.52c.83.08,1.27-.47,1-1.23a2.59,2.59,0,0,0-2-1.52C610.64,216,610.2,216.59,610.47,217.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M605.69,216.89a2.53,2.53,0,0,0,2,1.51c.83.08,1.28-.47,1-1.23a2.6,2.6,0,0,0-2-1.51C605.87,215.58,605.42,216.14,605.69,216.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M600.89,216.45a2.51,2.51,0,0,0,2,1.5c.83.08,1.29-.47,1-1.23a2.56,2.56,0,0,0-2-1.51C601.08,215.14,600.63,215.69,600.89,216.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M596.07,216a2.49,2.49,0,0,0,2,1.5c.84.08,1.31-.47,1.05-1.23a2.49,2.49,0,0,0-2-1.5C596.28,214.7,595.82,215.25,596.07,216Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M591.24,215.58a2.46,2.46,0,0,0,2,1.5c.84.07,1.32-.48,1.07-1.23a2.48,2.48,0,0,0-2-1.51C591.47,214.27,591,214.82,591.24,215.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M586.39,215.15a2.41,2.41,0,0,0,2,1.5c.84.07,1.33-.48,1.08-1.23a2.42,2.42,0,0,0-2-1.5C586.63,213.85,586.15,214.4,586.39,215.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.11,212.05a2.19,2.19,0,0,0,1.9,1.47c.87.06,1.41-.5,1.21-1.25a2.2,2.2,0,0,0-1.9-1.47C547.46,210.74,546.92,211.3,547.11,212.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.29,206.27a1.77,1.77,0,0,0,1.76,1.39c.9,0,1.57-.56,1.49-1.3a1.77,1.77,0,0,0-1.76-1.4C430.88,204.94,430.22,205.52,430.29,206.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.21,205.6a1.66,1.66,0,0,0,1.72,1.37,1.37,1.37,0,0,0,1.54-1.32,1.7,1.7,0,0,0-1.73-1.38A1.37,1.37,0,0,0,399.21,205.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394,205.52a1.64,1.64,0,0,0,1.71,1.37,1.38,1.38,0,0,0,1.55-1.32,1.65,1.65,0,0,0-1.71-1.38A1.39,1.39,0,0,0,394,205.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.81,205.46a1.64,1.64,0,0,0,1.71,1.36,1.4,1.4,0,0,0,1.56-1.32,1.65,1.65,0,0,0-1.71-1.37A1.39,1.39,0,0,0,388.81,205.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.61,205.4a1.63,1.63,0,0,0,1.7,1.36,1.4,1.4,0,0,0,1.57-1.33,1.64,1.64,0,0,0-1.71-1.36A1.4,1.4,0,0,0,383.61,205.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.41,205.35a1.6,1.6,0,0,0,1.69,1.36,1.41,1.41,0,0,0,1.57-1.33A1.6,1.6,0,0,0,380,204,1.41,1.41,0,0,0,378.41,205.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.2,205.32a1.6,1.6,0,0,0,1.69,1.35,1.43,1.43,0,0,0,1.58-1.33,1.6,1.6,0,0,0-1.69-1.36A1.43,1.43,0,0,0,373.2,205.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368,205.29a1.58,1.58,0,0,0,1.68,1.35,1.43,1.43,0,0,0,1.59-1.33,1.59,1.59,0,0,0-1.68-1.36A1.46,1.46,0,0,0,368,205.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.78,205.28a1.58,1.58,0,0,0,1.68,1.35,1.45,1.45,0,0,0,1.59-1.34,1.58,1.58,0,0,0-1.67-1.36A1.47,1.47,0,0,0,362.78,205.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M227.76,208.47c-.09.74.57,1.31,1.46,1.26a1.81,1.81,0,0,0,1.77-1.42c.08-.74-.57-1.31-1.46-1.27A1.84,1.84,0,0,0,227.76,208.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M222.62,208.73c-.09.74.56,1.3,1.45,1.26a1.85,1.85,0,0,0,1.78-1.42c.08-.75-.57-1.32-1.46-1.27A1.85,1.85,0,0,0,222.62,208.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M217.49,209c-.1.74.55,1.3,1.44,1.25a1.84,1.84,0,0,0,1.78-1.42c.09-.75-.55-1.31-1.44-1.27A1.86,1.86,0,0,0,217.49,209Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212.36,209.27c-.1.75.54,1.31,1.43,1.26a1.88,1.88,0,0,0,1.79-1.43c.1-.75-.54-1.31-1.43-1.26A1.88,1.88,0,0,0,212.36,209.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M207.24,209.56c-.1.74.54,1.3,1.42,1.25a1.91,1.91,0,0,0,1.8-1.43c.1-.74-.54-1.31-1.42-1.26A1.91,1.91,0,0,0,207.24,209.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M202.13,209.86c-.11.74.53,1.3,1.41,1.25a1.92,1.92,0,0,0,1.8-1.44c.11-.74-.52-1.31-1.41-1.26A1.93,1.93,0,0,0,202.13,209.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197,210.16c-.11.74.51,1.3,1.4,1.25a1.93,1.93,0,0,0,1.8-1.44c.12-.75-.51-1.31-1.39-1.25A2,2,0,0,0,197,210.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.94,210.48c-.12.74.5,1.29,1.38,1.24a2,2,0,0,0,1.82-1.44c.11-.75-.51-1.31-1.39-1.25A1.94,1.94,0,0,0,191.94,210.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M186.85,210.8c-.12.74.49,1.3,1.38,1.24A2,2,0,0,0,190,210.6c.13-.75-.49-1.31-1.37-1.25A2,2,0,0,0,186.85,210.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.77,211.13c-.12.75.49,1.3,1.37,1.24a2,2,0,0,0,1.82-1.45c.13-.74-.48-1.3-1.36-1.24A2,2,0,0,0,181.77,211.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176.71,211.48c-.14.74.47,1.29,1.35,1.23a2,2,0,0,0,1.83-1.45c.13-.74-.47-1.3-1.35-1.24A2,2,0,0,0,176.71,211.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.65,211.83c-.14.74.46,1.29,1.34,1.23a2,2,0,0,0,1.83-1.45c.14-.75-.46-1.31-1.33-1.24A2,2,0,0,0,171.65,211.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M641.58,216.38a2.88,2.88,0,0,0,2.07,1.54c.8.09,1.17-.46.82-1.23a2.91,2.91,0,0,0-2.08-1.55C641.6,215.05,641.23,215.61,641.58,216.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M637,215.88a2.8,2.8,0,0,0,2.06,1.54c.81.09,1.18-.46.84-1.23a2.82,2.82,0,0,0-2.06-1.54C637,214.56,636.63,215.12,637,215.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M632.32,215.39a2.78,2.78,0,0,0,2.06,1.54c.8.09,1.19-.46.86-1.23a2.8,2.8,0,0,0-2.06-1.54C632.38,214.07,632,214.63,632.32,215.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M627.67,214.91a2.76,2.76,0,0,0,2,1.54c.81.08,1.21-.47.89-1.24a2.83,2.83,0,0,0-2.05-1.54C627.74,213.59,627.35,214.15,627.67,214.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M623,214.44a2.71,2.71,0,0,0,2,1.53c.81.08,1.22-.47.9-1.24a2.73,2.73,0,0,0-2-1.53C623.09,213.12,622.69,213.67,623,214.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M618.31,214a2.68,2.68,0,0,0,2,1.53c.82.08,1.23-.48.92-1.24a2.68,2.68,0,0,0-2-1.53C618.41,212.65,618,213.2,618.31,214Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.6,213.5a2.61,2.61,0,0,0,2,1.53.84.84,0,0,0,.94-1.24,2.68,2.68,0,0,0-2-1.53A.84.84,0,0,0,613.6,213.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M608.87,213.05a2.62,2.62,0,0,0,2,1.52.85.85,0,0,0,1-1.24,2.62,2.62,0,0,0-2-1.52A.85.85,0,0,0,608.87,213.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M604.13,212.6a2.57,2.57,0,0,0,2,1.52c.83.08,1.27-.48,1-1.24a2.6,2.6,0,0,0-2-1.52A.86.86,0,0,0,604.13,212.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M599.37,212.16a2.51,2.51,0,0,0,2,1.51c.83.08,1.28-.47,1-1.24a2.57,2.57,0,0,0-2-1.52A.88.88,0,0,0,599.37,212.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M594.59,211.72a2.51,2.51,0,0,0,2,1.51c.83.08,1.29-.47,1-1.24a2.54,2.54,0,0,0-2-1.51C594.78,210.4,594.33,211,594.59,211.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M550.92,208.15a2.26,2.26,0,0,0,1.92,1.48c.86.06,1.38-.5,1.17-1.25a2.26,2.26,0,0,0-1.92-1.49C551.24,206.82,550.72,207.39,550.92,208.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M546,207.79a2.22,2.22,0,0,0,1.91,1.48c.86.06,1.39-.5,1.18-1.26a2.24,2.24,0,0,0-1.91-1.48C546.33,206.47,545.8,207,546,207.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.07,207.44a2.18,2.18,0,0,0,1.9,1.48c.86.06,1.4-.5,1.2-1.26a2.22,2.22,0,0,0-1.91-1.48C541.4,206.12,540.87,206.68,541.07,207.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435,202.18a1.78,1.78,0,0,0,1.76,1.41c.9,0,1.55-.56,1.46-1.31a1.79,1.79,0,0,0-1.77-1.42A1.27,1.27,0,0,0,435,202.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.86,202a1.76,1.76,0,0,0,1.76,1.41c.89,0,1.55-.56,1.47-1.32a1.8,1.8,0,0,0-1.76-1.41A1.29,1.29,0,0,0,429.86,202Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.09,201.45a1.67,1.67,0,0,0,1.72,1.39,1.34,1.34,0,0,0,1.52-1.33,1.69,1.69,0,0,0-1.73-1.39A1.34,1.34,0,0,0,404.09,201.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.92,201.37a1.66,1.66,0,0,0,1.72,1.38,1.34,1.34,0,0,0,1.52-1.33,1.67,1.67,0,0,0-1.72-1.39A1.37,1.37,0,0,0,398.92,201.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.75,201.29a1.65,1.65,0,0,0,1.71,1.38,1.37,1.37,0,0,0,1.54-1.33,1.68,1.68,0,0,0-1.72-1.39A1.38,1.38,0,0,0,393.75,201.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.57,201.23a1.66,1.66,0,0,0,1.71,1.38,1.38,1.38,0,0,0,1.54-1.34,1.64,1.64,0,0,0-1.7-1.39A1.4,1.4,0,0,0,388.57,201.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.4,201.17a1.62,1.62,0,0,0,1.69,1.38,1.4,1.4,0,0,0,1.56-1.35,1.64,1.64,0,0,0-1.7-1.38A1.4,1.4,0,0,0,383.4,201.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.22,201.13a1.61,1.61,0,0,0,1.69,1.37,1.4,1.4,0,0,0,1.56-1.35,1.63,1.63,0,0,0-1.69-1.38A1.42,1.42,0,0,0,378.22,201.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373,201.09a1.61,1.61,0,0,0,1.69,1.37,1.43,1.43,0,0,0,1.57-1.35,1.6,1.6,0,0,0-1.69-1.37A1.43,1.43,0,0,0,373,201.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.85,201.07a1.57,1.57,0,0,0,1.68,1.36,1.43,1.43,0,0,0,1.57-1.35,1.58,1.58,0,0,0-1.67-1.37A1.44,1.44,0,0,0,367.85,201.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.66,201.05a1.57,1.57,0,0,0,1.67,1.36,1.45,1.45,0,0,0,1.59-1.35,1.58,1.58,0,0,0-1.67-1.37A1.46,1.46,0,0,0,362.66,201.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M238.51,203.76c-.09.75.57,1.33,1.46,1.29a1.81,1.81,0,0,0,1.76-1.43,1.25,1.25,0,0,0-1.46-1.29A1.81,1.81,0,0,0,238.51,203.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M233.38,204c-.09.75.57,1.32,1.46,1.28a1.81,1.81,0,0,0,1.76-1.43c.09-.75-.56-1.33-1.45-1.29A1.84,1.84,0,0,0,233.38,204Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M228.26,204.25c-.09.75.56,1.32,1.45,1.28a1.85,1.85,0,0,0,1.77-1.44c.09-.75-.56-1.32-1.44-1.28A1.86,1.86,0,0,0,228.26,204.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M223.15,204.51c-.1.75.55,1.32,1.43,1.27a1.85,1.85,0,0,0,1.78-1.44c.1-.75-.54-1.32-1.43-1.28A1.89,1.89,0,0,0,223.15,204.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M218.05,204.77c-.11.75.53,1.32,1.42,1.28a1.89,1.89,0,0,0,1.78-1.45c.1-.75-.53-1.32-1.42-1.27A1.87,1.87,0,0,0,218.05,204.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M213,205.05c-.11.75.52,1.32,1.41,1.27a1.9,1.9,0,0,0,1.79-1.44c.1-.76-.53-1.33-1.41-1.28A1.91,1.91,0,0,0,213,205.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M207.86,205.34c-.12.75.51,1.31,1.4,1.26a1.9,1.9,0,0,0,1.79-1.44c.11-.76-.51-1.33-1.39-1.28A1.92,1.92,0,0,0,207.86,205.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M202.77,205.63c-.11.75.51,1.32,1.39,1.27a1.94,1.94,0,0,0,1.81-1.45c.11-.76-.51-1.33-1.39-1.27A1.94,1.94,0,0,0,202.77,205.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197.7,205.94c-.12.75.49,1.31,1.38,1.26a2,2,0,0,0,1.81-1.46c.12-.75-.5-1.32-1.38-1.26A2,2,0,0,0,197.7,205.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M192.64,206.25c-.13.75.48,1.31,1.36,1.26a2,2,0,0,0,1.82-1.46c.12-.75-.49-1.32-1.36-1.26A2,2,0,0,0,192.64,206.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187.58,206.57c-.13.76.47,1.32,1.35,1.26a2,2,0,0,0,1.82-1.46c.13-.75-.47-1.32-1.34-1.26A2,2,0,0,0,187.58,206.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.53,206.91c-.14.75.46,1.31,1.34,1.25a2,2,0,0,0,1.83-1.46c.14-.75-.46-1.32-1.33-1.26A2,2,0,0,0,182.53,206.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.49,207.25c-.14.75.46,1.31,1.33,1.25a2,2,0,0,0,1.84-1.47c.14-.75-.45-1.31-1.32-1.25A2.06,2.06,0,0,0,177.49,207.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.45,208c-.16.75.43,1.31,1.3,1.24a2.07,2.07,0,0,0,1.85-1.47c.15-.75-.43-1.31-1.3-1.25A2.09,2.09,0,0,0,167.45,208Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M639.61,212a2.9,2.9,0,0,0,2.07,1.56c.8.08,1.15-.47.79-1.25a2.88,2.88,0,0,0-2.07-1.55C639.61,210.69,639.26,211.25,639.61,212Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635,211.53a2.87,2.87,0,0,0,2.06,1.55c.8.09,1.16-.47.82-1.24a2.88,2.88,0,0,0-2.07-1.55C635.06,210.2,634.7,210.76,635,211.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M625.84,210.57a2.77,2.77,0,0,0,2,1.54c.8.09,1.18-.47.85-1.24a2.82,2.82,0,0,0-2-1.55C625.89,209.24,625.51,209.8,625.84,210.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M621.21,210.1a2.73,2.73,0,0,0,2,1.54c.81.08,1.2-.48.87-1.25a2.75,2.75,0,0,0-2-1.54C621.28,208.77,620.89,209.33,621.21,210.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.56,209.63a2.72,2.72,0,0,0,2,1.54c.81.08,1.21-.48.9-1.25a2.73,2.73,0,0,0-2-1.54C616.65,208.3,616.25,208.86,616.56,209.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M611.9,209.17a2.67,2.67,0,0,0,2,1.54c.81.08,1.22-.48.91-1.25a2.7,2.7,0,0,0-2-1.54C612,207.84,611.59,208.4,611.9,209.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M607.21,208.72a2.64,2.64,0,0,0,2,1.53c.81.08,1.23-.48.93-1.25a2.67,2.67,0,0,0-2-1.53C607.33,207.39,606.92,208,607.21,208.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M602.51,208.27a2.61,2.61,0,0,0,2,1.53.85.85,0,0,0,1-1.25,2.66,2.66,0,0,0-2-1.53A.85.85,0,0,0,602.51,208.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M583.54,206.56a2.47,2.47,0,0,0,2,1.52c.83.07,1.29-.49,1-1.26a2.47,2.47,0,0,0-2-1.52A.89.89,0,0,0,583.54,206.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M569.14,205.35a2.37,2.37,0,0,0,2,1.51c.84.07,1.32-.5,1.07-1.26a2.37,2.37,0,0,0-1.95-1.51C569.37,204,568.9,204.59,569.14,205.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.31,205a2.35,2.35,0,0,0,1.94,1.51c.84.06,1.33-.5,1.09-1.26a2.36,2.36,0,0,0-2-1.51C564.56,203.63,564.07,204.2,564.31,205Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M559.46,204.58a2.32,2.32,0,0,0,1.94,1.5c.84.07,1.34-.49,1.11-1.26a2.36,2.36,0,0,0-2-1.5C559.72,203.25,559.23,203.82,559.46,204.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M554.6,204.21a2.33,2.33,0,0,0,1.93,1.5c.85.06,1.35-.5,1.12-1.27a2.33,2.33,0,0,0-1.93-1.5C554.88,202.88,554.38,203.45,554.6,204.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.73,203.85a2.26,2.26,0,0,0,1.92,1.49c.85.06,1.36-.5,1.14-1.27a2.3,2.3,0,0,0-1.93-1.49C550,202.51,549.51,203.08,549.73,203.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.51,197.9a1.79,1.79,0,0,0,1.76,1.42,1.26,1.26,0,0,0,1.45-1.32,1.83,1.83,0,0,0-1.77-1.43A1.26,1.26,0,0,0,434.51,197.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.4,197.76a1.78,1.78,0,0,0,1.76,1.42,1.28,1.28,0,0,0,1.45-1.33,1.81,1.81,0,0,0-1.76-1.43A1.28,1.28,0,0,0,429.4,197.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.89,197.27a1.73,1.73,0,0,0,1.74,1.41,1.32,1.32,0,0,0,1.49-1.34,1.73,1.73,0,0,0-1.74-1.41A1.33,1.33,0,0,0,408.89,197.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.76,197.18a1.7,1.7,0,0,0,1.72,1.4,1.33,1.33,0,0,0,1.5-1.34,1.7,1.7,0,0,0-1.72-1.41A1.34,1.34,0,0,0,403.76,197.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.61,197.09a1.69,1.69,0,0,0,1.72,1.4,1.34,1.34,0,0,0,1.51-1.34,1.68,1.68,0,0,0-1.71-1.41A1.37,1.37,0,0,0,398.61,197.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.47,197a1.65,1.65,0,0,0,1.71,1.39,1.36,1.36,0,0,0,1.52-1.35,1.68,1.68,0,0,0-1.71-1.4A1.38,1.38,0,0,0,393.47,197Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.32,197a1.67,1.67,0,0,0,1.7,1.4,1.38,1.38,0,0,0,1.53-1.36,1.66,1.66,0,0,0-1.7-1.4A1.39,1.39,0,0,0,388.32,197Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.17,196.9a1.63,1.63,0,0,0,1.69,1.39,1.39,1.39,0,0,0,1.54-1.36,1.63,1.63,0,0,0-1.69-1.39A1.4,1.4,0,0,0,383.17,196.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378,196.85a1.63,1.63,0,0,0,1.69,1.39,1.4,1.4,0,0,0,1.55-1.36,1.62,1.62,0,0,0-1.69-1.39A1.41,1.41,0,0,0,378,196.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.86,196.82a1.59,1.59,0,0,0,1.68,1.38,1.42,1.42,0,0,0,1.56-1.36,1.62,1.62,0,0,0-1.69-1.39A1.42,1.42,0,0,0,372.86,196.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.7,196.79a1.58,1.58,0,0,0,1.67,1.38,1.43,1.43,0,0,0,1.57-1.36,1.61,1.61,0,0,0-1.68-1.39A1.44,1.44,0,0,0,367.7,196.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.54,196.78a1.57,1.57,0,0,0,1.66,1.38,1.45,1.45,0,0,0,1.58-1.37,1.58,1.58,0,0,0-1.67-1.38A1.44,1.44,0,0,0,362.54,196.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.37,196.78a1.56,1.56,0,0,0,1.66,1.37,1.46,1.46,0,0,0,1.59-1.37A1.58,1.58,0,0,0,359,195.4,1.48,1.48,0,0,0,357.37,196.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M239,199.5c-.09.76.56,1.33,1.44,1.3a1.82,1.82,0,0,0,1.76-1.45,1.24,1.24,0,0,0-1.44-1.3A1.83,1.83,0,0,0,239,199.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M233.9,199.74c-.1.75.55,1.33,1.43,1.29a1.84,1.84,0,0,0,1.77-1.45,1.24,1.24,0,0,0-1.43-1.3A1.86,1.86,0,0,0,233.9,199.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M228.81,200a1.21,1.21,0,0,0,1.42,1.29,1.85,1.85,0,0,0,1.77-1.44c.1-.76-.53-1.34-1.42-1.3A1.85,1.85,0,0,0,228.81,200Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M223.72,200.24c-.1.76.53,1.33,1.41,1.29a1.89,1.89,0,0,0,1.79-1.45,1.21,1.21,0,0,0-1.41-1.3A1.91,1.91,0,0,0,223.72,200.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M218.65,200.51c-.11.76.51,1.33,1.4,1.28a1.87,1.87,0,0,0,1.78-1.45c.11-.76-.51-1.34-1.39-1.29A1.9,1.9,0,0,0,218.65,200.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M213.58,200.78c-.12.76.5,1.34,1.38,1.29a1.91,1.91,0,0,0,1.8-1.46c.11-.76-.51-1.34-1.39-1.29A1.93,1.93,0,0,0,213.58,200.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M208.51,201.07c-.12.76.5,1.33,1.38,1.28a1.92,1.92,0,0,0,1.8-1.46c.12-.76-.49-1.34-1.37-1.29A2,2,0,0,0,208.51,201.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M203.46,201.37c-.12.75.49,1.32,1.37,1.27a1.94,1.94,0,0,0,1.8-1.46c.13-.76-.48-1.33-1.36-1.28A2,2,0,0,0,203.46,201.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M198.42,201.67c-.13.76.47,1.33,1.35,1.27a1.94,1.94,0,0,0,1.81-1.46c.13-.76-.47-1.34-1.34-1.28A2,2,0,0,0,198.42,201.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.38,202c-.13.76.47,1.33,1.34,1.28a2,2,0,0,0,1.82-1.47c.14-.76-.46-1.34-1.33-1.28A2,2,0,0,0,193.38,202Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M188.36,202.31c-.15.76.45,1.32,1.32,1.27a2,2,0,0,0,1.83-1.48c.14-.76-.45-1.33-1.32-1.27A2,2,0,0,0,188.36,202.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.34,202.64c-.15.76.44,1.32,1.31,1.26a2,2,0,0,0,1.84-1.47c.14-.76-.44-1.33-1.31-1.27A2,2,0,0,0,183.34,202.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178.33,203c-.15.76.43,1.32,1.3,1.26a2.06,2.06,0,0,0,1.85-1.48c.14-.75-.44-1.32-1.3-1.26A2.06,2.06,0,0,0,178.33,203Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.34,203.33c-.16.76.41,1.32,1.28,1.26a2.06,2.06,0,0,0,1.85-1.48c.16-.76-.42-1.33-1.28-1.27A2.07,2.07,0,0,0,173.34,203.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.35,203.69c-.16.76.41,1.32,1.27,1.26a2.11,2.11,0,0,0,1.86-1.49c.16-.76-.41-1.32-1.27-1.26A2.11,2.11,0,0,0,168.35,203.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M163.37,204.06c-.17.76.4,1.32,1.26,1.25a2.12,2.12,0,0,0,1.87-1.49c.16-.75-.4-1.32-1.26-1.26A2.15,2.15,0,0,0,163.37,204.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M633.08,207.16a2.87,2.87,0,0,0,2.06,1.55c.79.09,1.14-.47.78-1.25a2.94,2.94,0,0,0-2.07-1.56C633.07,205.82,632.72,206.38,633.08,207.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M624,206.2a2.79,2.79,0,0,0,2,1.55c.79.08,1.16-.48.82-1.25a2.85,2.85,0,0,0-2.05-1.56C624,204.86,623.63,205.43,624,206.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M619.38,205.73a2.74,2.74,0,0,0,2,1.55c.79.08,1.17-.48.84-1.25a2.81,2.81,0,0,0-2-1.56C619.42,204.39,619.05,205,619.38,205.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M614.77,205.27a2.73,2.73,0,0,0,2,1.54c.81.08,1.19-.48.87-1.25a2.76,2.76,0,0,0-2-1.55C614.83,203.93,614.45,204.49,614.77,205.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M610.15,204.81a2.66,2.66,0,0,0,2,1.54c.81.08,1.2-.48.88-1.25a2.73,2.73,0,0,0-2-1.55C610.23,203.47,609.83,204,610.15,204.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M582,202.21a2.51,2.51,0,0,0,2,1.53.88.88,0,0,0,1-1.27,2.54,2.54,0,0,0-2-1.53A.89.89,0,0,0,582,202.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M563,200.62a2.38,2.38,0,0,0,1.95,1.52.93.93,0,0,0,1.06-1.28,2.4,2.4,0,0,0-2-1.51A.92.92,0,0,0,563,200.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M558.14,200.24a2.38,2.38,0,0,0,1.94,1.52c.84.06,1.32-.51,1.08-1.28a2.37,2.37,0,0,0-1.95-1.51A.93.93,0,0,0,558.14,200.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M553.32,199.87a2.32,2.32,0,0,0,1.93,1.51c.84.07,1.33-.5,1.1-1.27a2.38,2.38,0,0,0-1.94-1.52C553.57,198.53,553.08,199.1,553.32,199.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M548.48,199.51a2.32,2.32,0,0,0,1.93,1.51c.84.06,1.34-.51,1.11-1.28a2.33,2.33,0,0,0-1.94-1.51A1,1,0,0,0,548.48,199.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.63,199.16a2.25,2.25,0,0,0,1.92,1.5c.84.06,1.35-.51,1.12-1.28a2.29,2.29,0,0,0-1.92-1.51A1,1,0,0,0,543.63,199.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.88,198.47a2.24,2.24,0,0,0,1.91,1.5c.85.06,1.37-.52,1.16-1.29a2.27,2.27,0,0,0-1.92-1.5A1,1,0,0,0,533.88,198.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434,193.58a1.81,1.81,0,0,0,1.77,1.44,1.25,1.25,0,0,0,1.42-1.34,1.82,1.82,0,0,0-1.77-1.44A1.25,1.25,0,0,0,434,193.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.91,193.44a1.77,1.77,0,0,0,1.76,1.43,1.26,1.26,0,0,0,1.43-1.34,1.8,1.8,0,0,0-1.76-1.44A1.27,1.27,0,0,0,428.91,193.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.52,193a1.71,1.71,0,0,0,1.73,1.42,1.32,1.32,0,0,0,1.47-1.36A1.73,1.73,0,0,0,410,191.6,1.32,1.32,0,0,0,408.52,193Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.41,192.86a1.7,1.7,0,0,0,1.72,1.42,1.34,1.34,0,0,0,1.49-1.36,1.72,1.72,0,0,0-1.73-1.42A1.33,1.33,0,0,0,403.41,192.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.29,192.78a1.69,1.69,0,0,0,1.72,1.41,1.33,1.33,0,0,0,1.49-1.36,1.7,1.7,0,0,0-1.71-1.42A1.36,1.36,0,0,0,398.29,192.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.17,192.7a1.68,1.68,0,0,0,1.71,1.41,1.36,1.36,0,0,0,1.51-1.36,1.69,1.69,0,0,0-1.71-1.42A1.38,1.38,0,0,0,393.17,192.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.05,192.64a1.66,1.66,0,0,0,1.7,1.4,1.38,1.38,0,0,0,1.52-1.36,1.66,1.66,0,0,0-1.7-1.41A1.38,1.38,0,0,0,388.05,192.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.93,192.58a1.64,1.64,0,0,0,1.69,1.41,1.4,1.4,0,0,0,1.53-1.37,1.65,1.65,0,0,0-1.69-1.41A1.39,1.39,0,0,0,382.93,192.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.8,192.54a1.63,1.63,0,0,0,1.68,1.4,1.41,1.41,0,0,0,1.54-1.37,1.63,1.63,0,0,0-1.68-1.41A1.41,1.41,0,0,0,377.8,192.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.67,192.5a1.61,1.61,0,0,0,1.68,1.4,1.41,1.41,0,0,0,1.54-1.38,1.61,1.61,0,0,0-1.67-1.4A1.43,1.43,0,0,0,372.67,192.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.54,192.48a1.58,1.58,0,0,0,1.66,1.39,1.43,1.43,0,0,0,1.56-1.38,1.59,1.59,0,0,0-1.66-1.39A1.43,1.43,0,0,0,367.54,192.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.4,192.47a1.59,1.59,0,0,0,1.66,1.39,1.45,1.45,0,0,0,1.57-1.39,1.59,1.59,0,0,0-1.66-1.39A1.46,1.46,0,0,0,362.4,192.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.27,192.46a1.56,1.56,0,0,0,1.65,1.39,1.45,1.45,0,0,0,1.57-1.39,1.57,1.57,0,0,0-1.65-1.39A1.47,1.47,0,0,0,357.27,192.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M239.52,195.19A1.23,1.23,0,0,0,241,196.5a1.83,1.83,0,0,0,1.76-1.45,1.24,1.24,0,0,0-1.42-1.32A1.85,1.85,0,0,0,239.52,195.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M234.45,195.43a1.22,1.22,0,0,0,1.41,1.31,1.85,1.85,0,0,0,1.77-1.46,1.21,1.21,0,0,0-1.41-1.31A1.86,1.86,0,0,0,234.45,195.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M229.39,195.68c-.11.76.52,1.34,1.4,1.3a1.86,1.86,0,0,0,1.78-1.46,1.21,1.21,0,0,0-1.4-1.31A1.88,1.88,0,0,0,229.39,195.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M224.33,195.93c-.11.77.51,1.35,1.39,1.31a1.9,1.9,0,0,0,1.79-1.47,1.2,1.2,0,0,0-1.39-1.31A1.93,1.93,0,0,0,224.33,195.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.29,196.2a1.17,1.17,0,0,0,1.37,1.3,1.9,1.9,0,0,0,1.79-1.47,1.17,1.17,0,0,0-1.37-1.3A1.93,1.93,0,0,0,219.29,196.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M214.25,196.48c-.13.76.49,1.34,1.36,1.29a1.91,1.91,0,0,0,1.8-1.47,1.17,1.17,0,0,0-1.36-1.3A1.94,1.94,0,0,0,214.25,196.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M209.21,196.76c-.12.77.48,1.35,1.36,1.3a1.94,1.94,0,0,0,1.8-1.48c.13-.76-.48-1.34-1.35-1.29A2,2,0,0,0,209.21,196.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.19,197.06c-.13.76.47,1.34,1.34,1.29a2,2,0,0,0,1.82-1.48,1.14,1.14,0,0,0-1.34-1.29A2,2,0,0,0,204.19,197.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M199.18,197.36c-.14.77.45,1.34,1.33,1.29a2,2,0,0,0,1.82-1.48c.13-.76-.46-1.34-1.33-1.29A2,2,0,0,0,199.18,197.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M194.17,197.68c-.14.76.45,1.34,1.32,1.28a2,2,0,0,0,1.83-1.48c.14-.77-.45-1.35-1.32-1.29A2,2,0,0,0,194.17,197.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.18,198c-.15.76.43,1.34,1.3,1.28a2,2,0,0,0,1.83-1.48c.15-.77-.43-1.35-1.29-1.29A2,2,0,0,0,189.18,198Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M184.19,198.33c-.15.77.43,1.34,1.29,1.28a2,2,0,0,0,1.84-1.49c.15-.76-.42-1.34-1.28-1.28A2.09,2.09,0,0,0,184.19,198.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.22,198.67c-.16.77.41,1.34,1.27,1.28a2.08,2.08,0,0,0,1.85-1.49c.16-.77-.41-1.34-1.27-1.28A2.09,2.09,0,0,0,179.22,198.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.25,199c-.16.77.4,1.34,1.27,1.27a2.08,2.08,0,0,0,1.85-1.49c.16-.76-.4-1.34-1.26-1.28A2.13,2.13,0,0,0,174.25,199Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.3,199.38c-.17.77.39,1.33,1.25,1.27a2.11,2.11,0,0,0,1.86-1.5c.17-.76-.39-1.33-1.24-1.27A2.13,2.13,0,0,0,169.3,199.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M164.36,199.75c-.18.76.37,1.33,1.23,1.26a2.13,2.13,0,0,0,1.87-1.49c.18-.77-.37-1.34-1.23-1.28A2.17,2.17,0,0,0,164.36,199.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.43,200.12c-.19.77.36,1.33,1.22,1.27a2.18,2.18,0,0,0,1.87-1.5c.18-.77-.36-1.34-1.21-1.27A2.17,2.17,0,0,0,159.43,200.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M662,206.26a3.2,3.2,0,0,0,2.12,1.58c.75.09,1-.47.6-1.26a3.24,3.24,0,0,0-2.12-1.58C661.84,204.91,661.57,205.47,662,206.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M648.86,204.73a3.1,3.1,0,0,0,2.1,1.57c.77.09,1.06-.47.67-1.26a3.09,3.09,0,0,0-2.1-1.57C648.77,203.38,648.47,203.94,648.86,204.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.54,203.24a3,3,0,0,0,2.08,1.57c.77.08,1.1-.48.73-1.26a3,3,0,0,0-2.08-1.57C635.5,201.9,635.17,202.46,635.54,203.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M631.06,202.76a2.89,2.89,0,0,0,2.07,1.56c.78.08,1.11-.48.75-1.26a3,3,0,0,0-2.07-1.57C631,201.41,630.7,202,631.06,202.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M580.47,197.83a2.52,2.52,0,0,0,2,1.54.87.87,0,0,0,1-1.28,2.54,2.54,0,0,0-2-1.53A.87.87,0,0,0,580.47,197.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M542.36,194.79a2.32,2.32,0,0,0,1.93,1.51,1,1,0,0,0,1.1-1.29,2.32,2.32,0,0,0-1.93-1.52A1,1,0,0,0,542.36,194.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M16.92,143c-.55.8-.51,1.36.09,1.25a4.22,4.22,0,0,0,2.09-1.64c.55-.8.5-1.36-.1-1.25A4.24,4.24,0,0,0,16.92,143Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M13.48,143.61c-.56.8-.52,1.35.07,1.24a4.16,4.16,0,0,0,2.09-1.64c.55-.8.52-1.36-.08-1.25A4.27,4.27,0,0,0,13.48,143.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M10.06,144.25c-.56.79-.53,1.35.06,1.24a4.26,4.26,0,0,0,2.09-1.64c.55-.8.52-1.36-.07-1.25A4.25,4.25,0,0,0,10.06,144.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M30.45,136.55c-.54.8-.48,1.36.13,1.26a4.09,4.09,0,0,0,2.08-1.65c.54-.8.48-1.36-.13-1.25A4,4,0,0,0,30.45,136.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M27,137.19c-.55.8-.5,1.36.11,1.25a4.09,4.09,0,0,0,2.08-1.65c.54-.8.49-1.36-.11-1.25A4.09,4.09,0,0,0,27,137.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M23.49,137.82c-.55.8-.51,1.36.09,1.25a4.09,4.09,0,0,0,2.08-1.65c.55-.8.51-1.36-.09-1.25A4.17,4.17,0,0,0,23.49,137.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M20,138.46c-.55.8-.52,1.36.08,1.24a4.14,4.14,0,0,0,2.08-1.64c.55-.8.51-1.36-.08-1.25A4.17,4.17,0,0,0,20,138.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M33.51,132c-.54.8-.49,1.36.12,1.25a4.09,4.09,0,0,0,2.08-1.65c.54-.8.48-1.36-.12-1.25A4.17,4.17,0,0,0,33.51,132Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M30,132.66c-.54.8-.5,1.36.1,1.25a4.09,4.09,0,0,0,2.08-1.65c.54-.8.5-1.36-.1-1.25A4.23,4.23,0,0,0,30,132.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M26.59,133.3c-.55.8-.51,1.36.09,1.25a4.17,4.17,0,0,0,2.08-1.65c.54-.8.5-1.36-.09-1.25A4.23,4.23,0,0,0,26.59,133.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M23.16,133.94c-.55.8-.52,1.36.08,1.25a4.19,4.19,0,0,0,2.07-1.65c.55-.8.52-1.35-.07-1.24A4.24,4.24,0,0,0,23.16,133.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M19.76,134.59c-.56.8-.53,1.35.06,1.24a4.25,4.25,0,0,0,2.08-1.65c.55-.79.52-1.35-.07-1.24A4.24,4.24,0,0,0,19.76,134.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M13,135.89c-.57.79-.55,1.35,0,1.23a4.31,4.31,0,0,0,2.07-1.64c.56-.8.54-1.35,0-1.24A4.26,4.26,0,0,0,13,135.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M22.91,130.08c-.56.8-.54,1.35.05,1.24A4.19,4.19,0,0,0,25,129.67c.55-.8.53-1.35-.06-1.24A4.22,4.22,0,0,0,22.91,130.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.44,189.23a1.81,1.81,0,0,0,1.77,1.44,1.23,1.23,0,0,0,1.4-1.35,1.83,1.83,0,0,0-1.76-1.45A1.25,1.25,0,0,0,433.44,189.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.39,189.08a1.8,1.8,0,0,0,1.76,1.45,1.25,1.25,0,0,0,1.41-1.36,1.81,1.81,0,0,0-1.76-1.45A1.27,1.27,0,0,0,428.39,189.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.27,188.82a1.76,1.76,0,0,0,1.74,1.44,1.29,1.29,0,0,0,1.44-1.36,1.78,1.78,0,0,0-1.75-1.44A1.28,1.28,0,0,0,418.27,188.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.2,188.71a1.74,1.74,0,0,0,1.73,1.43,1.3,1.3,0,0,0,1.45-1.36,1.76,1.76,0,0,0-1.74-1.44A1.3,1.3,0,0,0,413.2,188.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.12,188.6a1.71,1.71,0,0,0,1.73,1.43,1.31,1.31,0,0,0,1.46-1.36,1.74,1.74,0,0,0-1.73-1.44A1.32,1.32,0,0,0,408.12,188.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403,188.51a1.72,1.72,0,0,0,1.72,1.43,1.33,1.33,0,0,0,1.47-1.37,1.73,1.73,0,0,0-1.72-1.44A1.33,1.33,0,0,0,403,188.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398,188.42a1.7,1.7,0,0,0,1.71,1.43,1.35,1.35,0,0,0,1.49-1.38,1.7,1.7,0,0,0-1.71-1.42A1.35,1.35,0,0,0,398,188.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.87,188.35a1.67,1.67,0,0,0,1.7,1.42,1.35,1.35,0,0,0,1.49-1.38,1.68,1.68,0,0,0-1.7-1.42A1.35,1.35,0,0,0,392.87,188.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.77,188.28a1.66,1.66,0,0,0,1.7,1.42,1.36,1.36,0,0,0,1.5-1.38,1.66,1.66,0,0,0-1.69-1.42A1.38,1.38,0,0,0,387.77,188.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.68,188.23a1.64,1.64,0,0,0,1.68,1.41,1.38,1.38,0,0,0,1.52-1.38,1.66,1.66,0,0,0-1.69-1.42A1.39,1.39,0,0,0,382.68,188.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.58,188.18a1.63,1.63,0,0,0,1.68,1.42,1.4,1.4,0,0,0,1.52-1.39,1.62,1.62,0,0,0-1.68-1.41A1.4,1.4,0,0,0,377.58,188.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.48,188.15a1.61,1.61,0,0,0,1.67,1.41,1.42,1.42,0,0,0,1.53-1.39,1.62,1.62,0,0,0-1.67-1.41A1.41,1.41,0,0,0,372.48,188.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.37,188.13a1.59,1.59,0,0,0,1.66,1.4,1.43,1.43,0,0,0,1.55-1.39,1.61,1.61,0,0,0-1.66-1.41A1.44,1.44,0,0,0,367.37,188.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.27,188.11a1.56,1.56,0,0,0,1.65,1.4,1.43,1.43,0,0,0,1.55-1.39,1.58,1.58,0,0,0-1.65-1.41A1.45,1.45,0,0,0,362.27,188.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.16,188.11a1.56,1.56,0,0,0,1.64,1.4,1.47,1.47,0,0,0,1.57-1.4,1.57,1.57,0,0,0-1.64-1.4A1.47,1.47,0,0,0,357.16,188.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M240.08,190.85a1.21,1.21,0,0,0,1.4,1.32,1.86,1.86,0,0,0,1.77-1.47,1.23,1.23,0,0,0-1.41-1.33A1.86,1.86,0,0,0,240.08,190.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M235,191.08a1.2,1.2,0,0,0,1.39,1.32,1.86,1.86,0,0,0,1.77-1.47,1.2,1.2,0,0,0-1.39-1.32A1.88,1.88,0,0,0,235,191.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M230,191.33a1.19,1.19,0,0,0,1.38,1.32,1.9,1.9,0,0,0,1.78-1.48,1.2,1.2,0,0,0-1.38-1.32A1.91,1.91,0,0,0,230,191.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M225,191.59a1.18,1.18,0,0,0,1.37,1.31,1.91,1.91,0,0,0,1.79-1.47,1.19,1.19,0,0,0-1.37-1.32A1.92,1.92,0,0,0,225,191.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M220,191.86a1.17,1.17,0,0,0,1.36,1.31,1.93,1.93,0,0,0,1.79-1.48,1.17,1.17,0,0,0-1.35-1.32A2,2,0,0,0,220,191.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M215,192.13a1.15,1.15,0,0,0,1.34,1.31,2,2,0,0,0,1.8-1.48,1.15,1.15,0,0,0-1.34-1.31A1.93,1.93,0,0,0,215,192.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210,192.42c-.13.77.47,1.35,1.34,1.3a2,2,0,0,0,1.8-1.48,1.14,1.14,0,0,0-1.32-1.31A2,2,0,0,0,210,192.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M205,192.71c-.14.78.45,1.36,1.32,1.31a2,2,0,0,0,1.82-1.49,1.14,1.14,0,0,0-1.32-1.31A2,2,0,0,0,205,192.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200,193c-.14.77.44,1.35,1.31,1.3a2,2,0,0,0,1.82-1.49,1.12,1.12,0,0,0-1.3-1.31A2.05,2.05,0,0,0,200,193Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195,193.33c-.15.77.43,1.35,1.29,1.3a2,2,0,0,0,1.83-1.5c.15-.77-.43-1.35-1.29-1.3A2.05,2.05,0,0,0,195,193.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.05,193.66c-.16.77.41,1.34,1.27,1.29a2.06,2.06,0,0,0,1.84-1.5c.16-.77-.41-1.35-1.27-1.3A2.06,2.06,0,0,0,190.05,193.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.09,194c-.16.77.41,1.35,1.27,1.29a2.08,2.08,0,0,0,1.84-1.5c.16-.77-.4-1.35-1.26-1.3A2.12,2.12,0,0,0,185.09,194Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M180.15,194.33c-.17.77.39,1.34,1.25,1.29a2.1,2.1,0,0,0,1.85-1.51,1.06,1.06,0,0,0-1.24-1.29A2.12,2.12,0,0,0,180.15,194.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.22,194.68c-.18.77.38,1.34,1.24,1.28a2.14,2.14,0,0,0,1.86-1.5c.17-.77-.38-1.35-1.24-1.29A2.15,2.15,0,0,0,175.22,194.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170.3,195c-.18.77.37,1.34,1.22,1.28a2.16,2.16,0,0,0,1.87-1.51,1,1,0,0,0-1.22-1.29A2.17,2.17,0,0,0,170.3,195Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M165.39,195.4c-.19.77.36,1.35,1.21,1.28a2.16,2.16,0,0,0,1.87-1.51,1,1,0,0,0-1.2-1.28A2.19,2.19,0,0,0,165.39,195.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M160.49,195.78c-.19.77.35,1.34,1.2,1.27a2.19,2.19,0,0,0,1.88-1.51,1,1,0,0,0-1.19-1.28A2.24,2.24,0,0,0,160.49,195.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.61,196.16c-.2.77.33,1.34,1.18,1.28a2.24,2.24,0,0,0,1.89-1.52,1,1,0,0,0-1.18-1.28A2.25,2.25,0,0,0,155.61,196.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M651,200.8a3.18,3.18,0,0,0,2.1,1.58c.76.09,1-.48.62-1.26a3.17,3.17,0,0,0-2.1-1.59C650.85,199.44,650.57,200,651,200.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M642.25,199.8a3.08,3.08,0,0,0,2.09,1.57c.76.09,1.06-.48.66-1.26a3.08,3.08,0,0,0-2.08-1.58C642.16,198.44,641.86,199,642.25,199.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M637.86,199.3a3,3,0,0,0,2.08,1.58c.76.08,1.07-.48.68-1.27a3,3,0,0,0-2.08-1.57C637.78,198,637.47,198.52,637.86,199.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M633.44,198.82a3,3,0,0,0,2.07,1.57c.77.08,1.09-.48.71-1.27a3,3,0,0,0-2.08-1.57C633.38,197.46,633.06,198,633.44,198.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M629,198.33a2.92,2.92,0,0,0,2.06,1.57c.78.09,1.1-.48.73-1.26a3,3,0,0,0-2.07-1.58C629,197,628.63,197.55,629,198.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M624.54,197.86a2.93,2.93,0,0,0,2.06,1.57c.78.08,1.11-.49.74-1.27a2.9,2.9,0,0,0-2.06-1.57C624.51,196.5,624.18,197.07,624.54,197.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M620.06,197.39a2.86,2.86,0,0,0,2.05,1.56c.78.08,1.12-.48.76-1.27a2.89,2.89,0,0,0-2-1.57C620.05,196,619.7,196.6,620.06,197.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M588.14,194.26a2.62,2.62,0,0,0,2,1.55c.8.07,1.21-.5.9-1.28a2.65,2.65,0,0,0-2-1.55C588.24,192.9,587.84,193.48,588.14,194.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.8,192.23a2.46,2.46,0,0,0,2,1.54.89.89,0,0,0,1-1.29,2.5,2.5,0,0,0-2-1.54A.89.89,0,0,0,564.8,192.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M560.08,191.85a2.45,2.45,0,0,0,2,1.53.9.9,0,0,0,1-1.29,2.47,2.47,0,0,0-2-1.54A.91.91,0,0,0,560.08,191.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M437.89,185a1.85,1.85,0,0,0,1.77,1.46,1.21,1.21,0,0,0,1.37-1.36,1.85,1.85,0,0,0-1.77-1.46A1.22,1.22,0,0,0,437.89,185Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.75,184.32a1.74,1.74,0,0,0,1.73,1.44,1.28,1.28,0,0,0,1.43-1.37,1.76,1.76,0,0,0-1.73-1.45A1.3,1.3,0,0,0,412.75,184.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.7,184.21a1.74,1.74,0,0,0,1.73,1.44,1.3,1.3,0,0,0,1.44-1.37,1.75,1.75,0,0,0-1.72-1.45A1.31,1.31,0,0,0,407.7,184.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402.65,184.12a1.73,1.73,0,0,0,1.72,1.44,1.33,1.33,0,0,0,1.46-1.39,1.75,1.75,0,0,0-1.72-1.44A1.34,1.34,0,0,0,402.65,184.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397.6,184a1.71,1.71,0,0,0,1.71,1.44,1.34,1.34,0,0,0,1.46-1.39,1.7,1.7,0,0,0-1.7-1.44A1.34,1.34,0,0,0,397.6,184Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.54,184a1.68,1.68,0,0,0,1.7,1.43,1.35,1.35,0,0,0,1.48-1.39,1.69,1.69,0,0,0-1.7-1.43A1.35,1.35,0,0,0,392.54,184Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.48,183.89a1.66,1.66,0,0,0,1.69,1.43,1.36,1.36,0,0,0,1.49-1.39A1.68,1.68,0,0,0,389,182.5,1.37,1.37,0,0,0,387.48,183.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.41,183.84a1.64,1.64,0,0,0,1.68,1.42,1.38,1.38,0,0,0,1.51-1.39,1.67,1.67,0,0,0-1.69-1.43A1.39,1.39,0,0,0,382.41,183.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.35,183.8a1.63,1.63,0,0,0,1.67,1.42,1.4,1.4,0,0,0,1.51-1.4,1.65,1.65,0,0,0-1.67-1.43A1.4,1.4,0,0,0,377.35,183.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.27,183.76a1.62,1.62,0,0,0,1.67,1.42,1.41,1.41,0,0,0,1.52-1.4,1.62,1.62,0,0,0-1.66-1.42A1.42,1.42,0,0,0,372.27,183.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.2,183.74a1.58,1.58,0,0,0,1.65,1.41,1.42,1.42,0,0,0,1.54-1.4,1.61,1.61,0,0,0-1.66-1.42A1.44,1.44,0,0,0,367.2,183.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.13,183.72a1.57,1.57,0,0,0,1.64,1.42,1.44,1.44,0,0,0,1.54-1.41,1.59,1.59,0,0,0-1.64-1.42A1.45,1.45,0,0,0,362.13,183.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M245.68,186.23a1.23,1.23,0,0,0,1.4,1.34,1.84,1.84,0,0,0,1.75-1.47,1.23,1.23,0,0,0-1.39-1.35A1.87,1.87,0,0,0,245.68,186.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M240.67,186.46a1.21,1.21,0,0,0,1.38,1.34,1.86,1.86,0,0,0,1.76-1.48,1.21,1.21,0,0,0-1.38-1.34A1.88,1.88,0,0,0,240.67,186.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M235.66,186.7A1.19,1.19,0,0,0,237,188a1.88,1.88,0,0,0,1.77-1.48,1.19,1.19,0,0,0-1.37-1.33A1.89,1.89,0,0,0,235.66,186.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M230.66,187a1.18,1.18,0,0,0,1.36,1.33,1.91,1.91,0,0,0,1.78-1.49,1.19,1.19,0,0,0-1.36-1.33A1.92,1.92,0,0,0,230.66,187Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M225.66,187.21a1.16,1.16,0,0,0,1.35,1.32,1.92,1.92,0,0,0,1.79-1.48,1.17,1.17,0,0,0-1.35-1.33A1.94,1.94,0,0,0,225.66,187.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M220.68,187.48A1.15,1.15,0,0,0,222,188.8a2,2,0,0,0,1.8-1.49,1.16,1.16,0,0,0-1.33-1.33A2,2,0,0,0,220.68,187.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M215.7,187.75a1.14,1.14,0,0,0,1.32,1.32,2,2,0,0,0,1.81-1.49,1.14,1.14,0,0,0-1.32-1.32A2,2,0,0,0,215.7,187.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210.74,188a1.12,1.12,0,0,0,1.3,1.32,2,2,0,0,0,1.81-1.5,1.13,1.13,0,0,0-1.3-1.32A2,2,0,0,0,210.74,188Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M205.78,188.34a1.11,1.11,0,0,0,1.29,1.31,2,2,0,0,0,1.82-1.5,1.12,1.12,0,0,0-1.29-1.32A2,2,0,0,0,205.78,188.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200.83,188.64c-.16.78.42,1.36,1.28,1.31a2,2,0,0,0,1.82-1.5,1.11,1.11,0,0,0-1.27-1.32A2.05,2.05,0,0,0,200.83,188.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.89,189a1.09,1.09,0,0,0,1.26,1.31,2.06,2.06,0,0,0,1.84-1.5,1.09,1.09,0,0,0-1.27-1.31A2.06,2.06,0,0,0,195.89,189Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191,189.28a1.07,1.07,0,0,0,1.25,1.3,2.07,2.07,0,0,0,1.84-1.51,1.08,1.08,0,0,0-1.25-1.3A2.07,2.07,0,0,0,191,189.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M186,189.61a1.06,1.06,0,0,0,1.24,1.3,2.11,2.11,0,0,0,1.85-1.51,1.08,1.08,0,0,0-1.24-1.31A2.12,2.12,0,0,0,186,189.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.13,190c-.18.78.37,1.36,1.23,1.3a2.12,2.12,0,0,0,1.85-1.52,1.05,1.05,0,0,0-1.22-1.3A2.14,2.14,0,0,0,181.13,190Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176.23,190.3a1,1,0,0,0,1.21,1.29,2.15,2.15,0,0,0,1.87-1.51,1,1,0,0,0-1.21-1.3A2.17,2.17,0,0,0,176.23,190.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.35,190.66a1,1,0,0,0,1.19,1.29,2.16,2.16,0,0,0,1.87-1.52,1,1,0,0,0-1.19-1.3A2.2,2.2,0,0,0,171.35,190.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.47,191a1,1,0,0,0,1.18,1.29,2.18,2.18,0,0,0,1.88-1.52,1,1,0,0,0-1.17-1.29A2.22,2.22,0,0,0,166.47,191Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.61,191.4c-.2.78.32,1.35,1.17,1.29a2.22,2.22,0,0,0,1.88-1.53,1,1,0,0,0-1.16-1.29A2.24,2.24,0,0,0,161.61,191.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M156.76,191.78a1,1,0,0,0,1.15,1.29,2.26,2.26,0,0,0,1.9-1.53,1,1,0,0,0-1.15-1.29A2.29,2.29,0,0,0,156.76,191.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M648.66,196.35a3.18,3.18,0,0,0,2.1,1.58c.75.09,1-.48.6-1.27a3.21,3.21,0,0,0-2.11-1.59C648.51,195,648.25,195.56,648.66,196.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.67,194.86a3.05,3.05,0,0,0,2.08,1.58c.76.08,1-.49.65-1.28a3.08,3.08,0,0,0-2.08-1.58C635.57,193.5,635.28,194.07,635.67,194.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M631.29,194.37a3,3,0,0,0,2.07,1.58c.77.08,1.07-.49.68-1.27a3.07,3.07,0,0,0-2.07-1.59C631.21,193,630.91,193.58,631.29,194.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M626.89,193.89a3,3,0,0,0,2.07,1.58c.77.08,1.08-.49.7-1.28a3,3,0,0,0-2.07-1.58C626.83,192.53,626.52,193.1,626.89,193.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M622.47,193.42a3,3,0,0,0,2.06,1.57c.78.08,1.1-.49.72-1.28a2.94,2.94,0,0,0-2.06-1.57C622.43,192.05,622.11,192.63,622.47,193.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M618,193a2.93,2.93,0,0,0,2.06,1.57c.77.08,1.1-.49.74-1.28a3,3,0,0,0-2.06-1.58C618,191.58,617.67,192.16,618,193Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M567.91,188.19a2.54,2.54,0,0,0,2,1.55c.81.07,1.23-.51.94-1.3a2.54,2.54,0,0,0-2-1.55C568.05,186.82,567.63,187.41,567.91,188.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M563.25,187.8a2.48,2.48,0,0,0,2,1.54.88.88,0,0,0,1-1.29,2.53,2.53,0,0,0-2-1.55C563.4,186.43,563,187,563.25,187.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M553.87,187a2.44,2.44,0,0,0,2,1.54.9.9,0,0,0,1-1.3,2.47,2.47,0,0,0-2-1.55A.92.92,0,0,0,553.87,187Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M544.43,186.31a2.4,2.4,0,0,0,1.94,1.54.93.93,0,0,0,1-1.31,2.4,2.4,0,0,0-1.94-1.54A.94.94,0,0,0,544.43,186.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.29,180a1.79,1.79,0,0,0,1.73,1.46,1.28,1.28,0,0,0,1.41-1.39,1.8,1.8,0,0,0-1.74-1.46A1.27,1.27,0,0,0,417.29,180Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.28,179.89a1.76,1.76,0,0,0,1.73,1.46,1.29,1.29,0,0,0,1.42-1.39,1.79,1.79,0,0,0-1.73-1.46A1.3,1.3,0,0,0,412.28,179.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.27,179.79a1.74,1.74,0,0,0,1.72,1.45,1.3,1.3,0,0,0,1.43-1.39,1.76,1.76,0,0,0-1.72-1.45A1.3,1.3,0,0,0,407.27,179.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402.25,179.69a1.71,1.71,0,0,0,1.71,1.45,1.31,1.31,0,0,0,1.44-1.39,1.72,1.72,0,0,0-1.71-1.45A1.32,1.32,0,0,0,402.25,179.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397.23,179.61a1.7,1.7,0,0,0,1.7,1.44,1.32,1.32,0,0,0,1.45-1.39,1.7,1.7,0,0,0-1.7-1.45A1.33,1.33,0,0,0,397.23,179.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.2,179.53a1.69,1.69,0,0,0,1.69,1.45,1.35,1.35,0,0,0,1.47-1.4,1.69,1.69,0,0,0-1.69-1.45A1.36,1.36,0,0,0,392.2,179.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.17,179.47a1.67,1.67,0,0,0,1.69,1.44,1.36,1.36,0,0,0,1.47-1.4,1.68,1.68,0,0,0-1.68-1.45A1.37,1.37,0,0,0,387.17,179.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.14,179.42a1.65,1.65,0,0,0,1.67,1.43,1.37,1.37,0,0,0,1.49-1.4,1.65,1.65,0,0,0-1.67-1.44A1.38,1.38,0,0,0,382.14,179.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.1,179.37a1.63,1.63,0,0,0,1.67,1.43,1.39,1.39,0,0,0,1.5-1.4A1.65,1.65,0,0,0,378.6,178,1.4,1.4,0,0,0,377.1,179.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.06,179.34a1.62,1.62,0,0,0,1.66,1.43,1.42,1.42,0,0,0,1.51-1.41,1.62,1.62,0,0,0-1.66-1.44A1.42,1.42,0,0,0,372.06,179.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367,179.31a1.59,1.59,0,0,0,1.65,1.43,1.42,1.42,0,0,0,1.52-1.41,1.6,1.6,0,0,0-1.65-1.43A1.42,1.42,0,0,0,367,179.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362,179.3a1.56,1.56,0,0,0,1.63,1.42,1.44,1.44,0,0,0,1.54-1.41,1.59,1.59,0,0,0-1.64-1.43A1.45,1.45,0,0,0,362,179.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M251.26,181.6a1.23,1.23,0,0,0,1.39,1.35,1.83,1.83,0,0,0,1.74-1.48,1.23,1.23,0,0,0-1.38-1.36A1.85,1.85,0,0,0,251.26,181.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M246.27,181.82a1.21,1.21,0,0,0,1.38,1.35,1.86,1.86,0,0,0,1.75-1.49,1.22,1.22,0,0,0-1.37-1.35A1.87,1.87,0,0,0,246.27,181.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M241.29,182.05a1.19,1.19,0,0,0,1.36,1.34,1.86,1.86,0,0,0,1.76-1.49,1.2,1.2,0,0,0-1.36-1.34A1.88,1.88,0,0,0,241.29,182.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M236.31,182.29a1.19,1.19,0,0,0,1.35,1.34,1.89,1.89,0,0,0,1.77-1.49,1.18,1.18,0,0,0-1.34-1.35A1.92,1.92,0,0,0,236.31,182.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M231.34,182.54a1.18,1.18,0,0,0,1.34,1.34,1.93,1.93,0,0,0,1.78-1.5,1.17,1.17,0,0,0-1.33-1.34A1.94,1.94,0,0,0,231.34,182.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M226.38,182.8a1.16,1.16,0,0,0,1.33,1.33,1.94,1.94,0,0,0,1.79-1.5,1.17,1.17,0,0,0-1.33-1.34A2,2,0,0,0,226.38,182.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M221.43,183.06a1.15,1.15,0,0,0,1.31,1.34,2,2,0,0,0,1.8-1.5,1.15,1.15,0,0,0-1.31-1.34A2,2,0,0,0,221.43,183.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M216.49,183.34a1.13,1.13,0,0,0,1.3,1.33,2,2,0,0,0,1.8-1.5,1.14,1.14,0,0,0-1.29-1.34A2,2,0,0,0,216.49,183.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M211.55,183.63a1.12,1.12,0,0,0,1.29,1.32,2,2,0,0,0,1.81-1.5,1.12,1.12,0,0,0-1.28-1.33A2,2,0,0,0,211.55,183.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.63,183.92a1.1,1.1,0,0,0,1.27,1.33,2,2,0,0,0,1.82-1.51,1.11,1.11,0,0,0-1.27-1.33A2,2,0,0,0,206.63,183.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201.71,184.23a1.1,1.1,0,0,0,1.26,1.32A2,2,0,0,0,204.8,184a1.1,1.1,0,0,0-1.26-1.33A2.08,2.08,0,0,0,201.71,184.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M196.81,184.54a1.07,1.07,0,0,0,1.24,1.32,2.07,2.07,0,0,0,1.84-1.51,1.09,1.09,0,0,0-1.24-1.33A2.09,2.09,0,0,0,196.81,184.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.91,184.87a1.06,1.06,0,0,0,1.23,1.31,2.11,2.11,0,0,0,1.85-1.52,1.07,1.07,0,0,0-1.23-1.31A2.1,2.1,0,0,0,191.91,184.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187,185.2a1,1,0,0,0,1.21,1.31,2.12,2.12,0,0,0,1.85-1.52,1.06,1.06,0,0,0-1.21-1.32A2.15,2.15,0,0,0,187,185.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.15,185.54a1,1,0,0,0,1.2,1.31,2.14,2.14,0,0,0,1.86-1.53A1,1,0,0,0,184,184,2.18,2.18,0,0,0,182.15,185.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.29,185.89a1,1,0,0,0,1.19,1.3,2.15,2.15,0,0,0,1.86-1.52,1,1,0,0,0-1.18-1.31A2.19,2.19,0,0,0,177.29,185.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.44,186.25a1,1,0,0,0,1.17,1.3,2.22,2.22,0,0,0,1.88-1.53,1,1,0,0,0-1.17-1.3A2.22,2.22,0,0,0,172.44,186.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.6,186.61a1,1,0,0,0,1.16,1.3,2.2,2.2,0,0,0,1.88-1.53,1,1,0,0,0-1.15-1.3A2.24,2.24,0,0,0,167.6,186.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M162.77,187a1,1,0,0,0,1.14,1.3,2.24,2.24,0,0,0,1.89-1.54,1,1,0,0,0-1.13-1.3A2.29,2.29,0,0,0,162.77,187Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M158,187.37a1,1,0,0,0,1.13,1.3,2.28,2.28,0,0,0,1.89-1.54,1,1,0,0,0-1.12-1.3A2.31,2.31,0,0,0,158,187.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M646.3,191.88a3.21,3.21,0,0,0,2.1,1.59c.74.08,1-.49.57-1.28a3.24,3.24,0,0,0-2.1-1.59C646.14,190.51,645.88,191.08,646.3,191.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M620.37,189a2.93,2.93,0,0,0,2.06,1.58c.76.08,1.07-.49.69-1.28a3,3,0,0,0-2.06-1.58C620.3,187.58,620,188.16,620.37,189Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616,188.48a2.94,2.94,0,0,0,2,1.58c.77.08,1.09-.49.71-1.28a2.91,2.91,0,0,0-2.05-1.58C615.91,187.11,615.6,187.69,616,188.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M602.65,187.11a2.79,2.79,0,0,0,2,1.57c.78.08,1.12-.5.77-1.29a2.85,2.85,0,0,0-2-1.57C602.64,185.74,602.3,186.32,602.65,187.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M598.17,186.67a2.78,2.78,0,0,0,2,1.57c.78.07,1.13-.5.78-1.3a2.8,2.8,0,0,0-2-1.57C598.18,185.29,597.83,185.88,598.17,186.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M584.61,185.37a2.68,2.68,0,0,0,2,1.56c.79.08,1.17-.5.84-1.29a2.67,2.67,0,0,0-2-1.57C584.67,184,584.29,184.58,584.61,185.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M575.48,184.54a2.63,2.63,0,0,0,2,1.56c.79.07,1.19-.51.88-1.3a2.66,2.66,0,0,0-2-1.57C575.57,183.16,575.17,183.75,575.48,184.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.79,175.44a1.77,1.77,0,0,0,1.73,1.46,1.28,1.28,0,0,0,1.4-1.39,1.79,1.79,0,0,0-1.73-1.47A1.28,1.28,0,0,0,411.79,175.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.81,175.33a1.75,1.75,0,0,0,1.72,1.46,1.28,1.28,0,0,0,1.41-1.39,1.76,1.76,0,0,0-1.71-1.47A1.3,1.3,0,0,0,406.81,175.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.83,175.24a1.74,1.74,0,0,0,1.71,1.46,1.31,1.31,0,0,0,1.42-1.4,1.75,1.75,0,0,0-1.71-1.47A1.32,1.32,0,0,0,401.83,175.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.84,175.15a1.72,1.72,0,0,0,1.7,1.46,1.32,1.32,0,0,0,1.44-1.4,1.72,1.72,0,0,0-1.7-1.46A1.32,1.32,0,0,0,396.84,175.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.85,175.08a1.69,1.69,0,0,0,1.69,1.45,1.35,1.35,0,0,0,1.45-1.41,1.7,1.7,0,0,0-1.69-1.45A1.35,1.35,0,0,0,391.85,175.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.85,175a1.67,1.67,0,0,0,1.68,1.45,1.35,1.35,0,0,0,1.46-1.41,1.67,1.67,0,0,0-1.68-1.45A1.36,1.36,0,0,0,386.85,175Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.85,175a1.66,1.66,0,0,0,1.67,1.45A1.37,1.37,0,0,0,385,175a1.65,1.65,0,0,0-1.66-1.45A1.39,1.39,0,0,0,381.85,175Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.85,174.92a1.64,1.64,0,0,0,1.66,1.44,1.38,1.38,0,0,0,1.48-1.42,1.63,1.63,0,0,0-1.65-1.44A1.4,1.4,0,0,0,376.85,174.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.85,174.88a1.6,1.6,0,0,0,1.64,1.44,1.4,1.4,0,0,0,1.5-1.42,1.62,1.62,0,0,0-1.65-1.44A1.4,1.4,0,0,0,371.85,174.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.84,174.86a1.59,1.59,0,0,0,1.63,1.44,1.43,1.43,0,0,0,1.51-1.43,1.6,1.6,0,0,0-1.63-1.44A1.43,1.43,0,0,0,366.84,174.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.83,174.85a1.56,1.56,0,0,0,1.62,1.43,1.42,1.42,0,0,0,1.52-1.43,1.58,1.58,0,0,0-1.62-1.43A1.44,1.44,0,0,0,361.83,174.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M246.88,177.37a1.21,1.21,0,0,0,1.36,1.36,1.88,1.88,0,0,0,1.76-1.49,1.22,1.22,0,0,0-1.36-1.37A1.9,1.9,0,0,0,246.88,177.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M241.94,177.61a1.18,1.18,0,0,0,1.34,1.35,1.88,1.88,0,0,0,1.76-1.5,1.19,1.19,0,0,0-1.34-1.36A1.9,1.9,0,0,0,241.94,177.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M237,177.84a1.18,1.18,0,0,0,1.33,1.36,1.92,1.92,0,0,0,1.77-1.51,1.18,1.18,0,0,0-1.33-1.35A1.91,1.91,0,0,0,237,177.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M232.06,178.09a1.16,1.16,0,0,0,1.32,1.35,1.93,1.93,0,0,0,1.78-1.5,1.16,1.16,0,0,0-1.32-1.35A2,2,0,0,0,232.06,178.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M227.14,178.35a1.15,1.15,0,0,0,1.3,1.35,2,2,0,0,0,1.79-1.51,1.15,1.15,0,0,0-1.3-1.35A2,2,0,0,0,227.14,178.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M222.22,178.62a1.13,1.13,0,0,0,1.29,1.34,2,2,0,0,0,1.8-1.51,1.14,1.14,0,0,0-1.29-1.34A2,2,0,0,0,222.22,178.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M217.31,178.9a1.12,1.12,0,0,0,1.28,1.34,2,2,0,0,0,1.8-1.52,1.12,1.12,0,0,0-1.27-1.34A2,2,0,0,0,217.31,178.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212.41,179.19a1.1,1.1,0,0,0,1.26,1.33,2,2,0,0,0,1.82-1.51,1.11,1.11,0,0,0-1.26-1.34A2,2,0,0,0,212.41,179.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M207.52,179.48a1.09,1.09,0,0,0,1.25,1.33,2,2,0,0,0,1.82-1.51,1.1,1.1,0,0,0-1.25-1.34A2.05,2.05,0,0,0,207.52,179.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M202.64,179.79a1.08,1.08,0,0,0,1.23,1.33,2.08,2.08,0,0,0,1.83-1.52,1.08,1.08,0,0,0-1.23-1.34A2.1,2.1,0,0,0,202.64,179.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197.77,180.1a1.07,1.07,0,0,0,1.22,1.33,2.1,2.1,0,0,0,1.84-1.53,1.07,1.07,0,0,0-1.22-1.32A2.09,2.09,0,0,0,197.77,180.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M192.91,180.43a1.05,1.05,0,0,0,1.2,1.32,2.13,2.13,0,0,0,1.85-1.53,1,1,0,0,0-1.2-1.32A2.12,2.12,0,0,0,192.91,180.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M188.06,180.76a1,1,0,0,0,1.19,1.32,2.14,2.14,0,0,0,1.85-1.53,1,1,0,0,0-1.18-1.32A2.15,2.15,0,0,0,188.06,180.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.22,181.1a1,1,0,0,0,1.18,1.32,2.17,2.17,0,0,0,1.86-1.54,1,1,0,0,0-1.17-1.32A2.19,2.19,0,0,0,183.22,181.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178.39,181.45a1,1,0,0,0,1.16,1.31,2.18,2.18,0,0,0,1.87-1.53,1,1,0,0,0-1.15-1.32A2.21,2.21,0,0,0,178.39,181.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.58,181.81a1,1,0,0,0,1.14,1.31,2.21,2.21,0,0,0,1.88-1.54,1,1,0,0,0-1.14-1.31A2.22,2.22,0,0,0,173.58,181.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.78,182.18a1,1,0,0,0,1.13,1.3,2.24,2.24,0,0,0,1.88-1.54,1,1,0,0,0-1.13-1.31A2.26,2.26,0,0,0,168.78,182.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M164,182.55a1,1,0,0,0,1.12,1.31,2.27,2.27,0,0,0,1.89-1.55,1,1,0,0,0-1.11-1.3A2.27,2.27,0,0,0,164,182.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.21,182.94a1,1,0,0,0,1.1,1.3,2.3,2.3,0,0,0,1.9-1.55,1,1,0,0,0-1.1-1.3A2.3,2.3,0,0,0,159.21,182.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M154.44,183.33a1,1,0,0,0,1.09,1.3,2.31,2.31,0,0,0,1.9-1.55,1,1,0,0,0-1.08-1.3A2.32,2.32,0,0,0,154.44,183.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M605.08,183.08a2.88,2.88,0,0,0,2,1.58c.77.08,1.09-.5.72-1.29a2.91,2.91,0,0,0-2-1.59C605,181.7,604.71,182.29,605.08,183.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.28,181.31a2.74,2.74,0,0,0,2,1.58c.78.07,1.14-.51.8-1.31a2.76,2.76,0,0,0-2-1.57C587.3,179.94,586.94,180.52,587.28,181.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M582.78,180.89a2.71,2.71,0,0,0,2,1.57c.78.07,1.15-.51.82-1.31a2.78,2.78,0,0,0-2-1.57C582.81,179.51,582.45,180.09,582.78,180.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M578.26,180.47a2.69,2.69,0,0,0,2,1.57c.79.07,1.17-.51.84-1.31a2.72,2.72,0,0,0-2-1.57C578.31,179.09,577.94,179.68,578.26,180.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M573.73,180.06a2.63,2.63,0,0,0,2,1.56c.79.08,1.17-.51.86-1.3a2.67,2.67,0,0,0-2-1.57C573.79,178.68,573.41,179.26,573.73,180.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M569.17,179.65a2.62,2.62,0,0,0,2,1.57c.8.07,1.19-.52.87-1.31a2.62,2.62,0,0,0-2-1.57C569.25,178.27,568.86,178.86,569.17,179.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.6,179.25a2.59,2.59,0,0,0,2,1.57c.79.07,1.19-.52.88-1.32a2.6,2.6,0,0,0-2-1.56C564.7,177.87,564.3,178.46,564.6,179.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M560,178.86a2.54,2.54,0,0,0,2,1.56c.8.07,1.21-.52.91-1.31a2.56,2.56,0,0,0-2-1.56C560.13,177.48,559.72,178.07,560,178.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555.41,178.48a2.53,2.53,0,0,0,2,1.56c.8.06,1.22-.52.92-1.32a2.55,2.55,0,0,0-2-1.56C555.54,177.09,555.12,177.69,555.41,178.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M550.79,178.11a2.51,2.51,0,0,0,2,1.55c.81.06,1.23-.53.94-1.32a2.52,2.52,0,0,0-2-1.56C550.93,176.72,550.51,177.31,550.79,178.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.5,177.38a2.47,2.47,0,0,0,2,1.55.91.91,0,0,0,1-1.33,2.45,2.45,0,0,0-1.95-1.55C541.67,176,541.23,176.58,541.5,177.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.29,171a1.77,1.77,0,0,0,1.72,1.48,1.27,1.27,0,0,0,1.38-1.41,1.79,1.79,0,0,0-1.72-1.47A1.27,1.27,0,0,0,411.29,171Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.34,170.85a1.75,1.75,0,0,0,1.71,1.47,1.29,1.29,0,0,0,1.4-1.41,1.77,1.77,0,0,0-1.71-1.47A1.29,1.29,0,0,0,406.34,170.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.39,170.76a1.73,1.73,0,0,0,1.7,1.46,1.29,1.29,0,0,0,1.41-1.41,1.74,1.74,0,0,0-1.7-1.47A1.32,1.32,0,0,0,401.39,170.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.44,170.67a1.7,1.7,0,0,0,1.69,1.46,1.31,1.31,0,0,0,1.42-1.41,1.71,1.71,0,0,0-1.69-1.46A1.32,1.32,0,0,0,396.44,170.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.48,170.6a1.71,1.71,0,0,0,1.68,1.46,1.33,1.33,0,0,0,1.44-1.42,1.7,1.7,0,0,0-1.68-1.46A1.34,1.34,0,0,0,391.48,170.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.52,170.53a1.67,1.67,0,0,0,1.67,1.46,1.35,1.35,0,0,0,1.45-1.42,1.68,1.68,0,0,0-1.67-1.46A1.36,1.36,0,0,0,386.52,170.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.56,170.48a1.65,1.65,0,0,0,1.66,1.45,1.36,1.36,0,0,0,1.46-1.42,1.67,1.67,0,0,0-1.66-1.46A1.37,1.37,0,0,0,381.56,170.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.59,170.44a1.64,1.64,0,0,0,1.65,1.45,1.39,1.39,0,0,0,1.47-1.43,1.64,1.64,0,0,0-1.65-1.45A1.38,1.38,0,0,0,376.59,170.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.62,170.4a1.61,1.61,0,0,0,1.64,1.45,1.39,1.39,0,0,0,1.48-1.43A1.63,1.63,0,0,0,373.1,169,1.4,1.4,0,0,0,371.62,170.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.65,170.38a1.58,1.58,0,0,0,1.62,1.44,1.41,1.41,0,0,0,1.5-1.43,1.62,1.62,0,0,0-1.63-1.45A1.42,1.42,0,0,0,366.65,170.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.67,170.37a1.58,1.58,0,0,0,1.62,1.44,1.44,1.44,0,0,0,1.51-1.44,1.59,1.59,0,0,0-1.62-1.44A1.44,1.44,0,0,0,361.67,170.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.69,170.36a1.56,1.56,0,0,0,1.61,1.44,1.44,1.44,0,0,0,1.52-1.44,1.57,1.57,0,0,0-1.61-1.44A1.46,1.46,0,0,0,356.69,170.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M247.53,172.9a1.19,1.19,0,0,0,1.34,1.37,1.88,1.88,0,0,0,1.75-1.51,1.2,1.2,0,0,0-1.34-1.37A1.9,1.9,0,0,0,247.53,172.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M242.62,173.13a1.17,1.17,0,0,0,1.32,1.36,1.89,1.89,0,0,0,1.76-1.5,1.18,1.18,0,0,0-1.32-1.37A1.91,1.91,0,0,0,242.62,173.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M237.71,173.37a1.16,1.16,0,0,0,1.31,1.36,1.91,1.91,0,0,0,1.77-1.51,1.17,1.17,0,0,0-1.31-1.36A1.93,1.93,0,0,0,237.71,173.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M232.81,173.62a1.16,1.16,0,0,0,1.3,1.36,1.94,1.94,0,0,0,1.78-1.52,1.15,1.15,0,0,0-1.3-1.35A2,2,0,0,0,232.81,173.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M227.92,173.88a1.14,1.14,0,0,0,1.29,1.35,1.94,1.94,0,0,0,1.78-1.51,1.14,1.14,0,0,0-1.28-1.36A2,2,0,0,0,227.92,173.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M223,174.15a1.12,1.12,0,0,0,1.27,1.35,2,2,0,0,0,1.8-1.52,1.13,1.13,0,0,0-1.27-1.35A2,2,0,0,0,223,174.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M218.17,174.43a1.11,1.11,0,0,0,1.25,1.35,2,2,0,0,0,1.81-1.53A1.12,1.12,0,0,0,220,172.9,2,2,0,0,0,218.17,174.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M213.3,174.72a1.1,1.1,0,0,0,1.24,1.34,2,2,0,0,0,1.82-1.52,1.1,1.1,0,0,0-1.24-1.35A2.05,2.05,0,0,0,213.3,174.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M208.45,175a1.07,1.07,0,0,0,1.22,1.34,2.07,2.07,0,0,0,1.83-1.52,1.09,1.09,0,0,0-1.23-1.35A2.08,2.08,0,0,0,208.45,175Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M203.6,175.32a1.07,1.07,0,0,0,1.21,1.34,2.1,2.1,0,0,0,1.84-1.53,1.07,1.07,0,0,0-1.21-1.34A2.11,2.11,0,0,0,203.6,175.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M198.77,175.64A1.05,1.05,0,0,0,200,177a2.1,2.1,0,0,0,1.84-1.53,1.05,1.05,0,0,0-1.19-1.34A2.11,2.11,0,0,0,198.77,175.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.94,176a1,1,0,0,0,1.19,1.33,2.13,2.13,0,0,0,1.84-1.54,1,1,0,0,0-1.18-1.33A2.17,2.17,0,0,0,193.94,176Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.13,176.29a1,1,0,0,0,1.17,1.33,2.17,2.17,0,0,0,1.85-1.54,1,1,0,0,0-1.16-1.33A2.18,2.18,0,0,0,189.13,176.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M184.33,176.63a1,1,0,0,0,1.15,1.33,2.18,2.18,0,0,0,1.86-1.54,1,1,0,0,0-1.14-1.33A2.21,2.21,0,0,0,184.33,176.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.54,177a1,1,0,0,0,1.13,1.33,2.22,2.22,0,0,0,1.88-1.55,1,1,0,0,0-1.14-1.32A2.21,2.21,0,0,0,179.54,177Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.76,177.34a1,1,0,0,0,1.12,1.32,2.24,2.24,0,0,0,1.88-1.54,1,1,0,0,0-1.12-1.33A2.27,2.27,0,0,0,174.76,177.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170,177.71A1,1,0,0,0,171.1,179a2.29,2.29,0,0,0,1.89-1.55,1,1,0,0,0-1.11-1.32A2.28,2.28,0,0,0,170,177.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M165.24,178.09a1,1,0,0,0,1.09,1.31,2.28,2.28,0,0,0,1.89-1.55,1,1,0,0,0-1.08-1.32A2.34,2.34,0,0,0,165.24,178.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M160.5,178.47a1,1,0,0,0,1.07,1.31,2.29,2.29,0,0,0,1.9-1.55,1,1,0,0,0-1.07-1.31A2.32,2.32,0,0,0,160.5,178.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M589.8,177.25a2.83,2.83,0,0,0,2,1.58c.77.07,1.1-.51.75-1.31a2.82,2.82,0,0,0-2-1.58C589.79,175.86,589.45,176.45,589.8,177.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M576.42,176a2.7,2.7,0,0,0,2,1.57c.78.08,1.14-.51.81-1.31a2.77,2.77,0,0,0-2-1.58C576.45,174.58,576.09,175.17,576.42,176Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M571.93,175.56a2.68,2.68,0,0,0,2,1.57c.79.07,1.16-.52.83-1.32a2.71,2.71,0,0,0-2-1.57C572,174.17,571.6,174.76,571.93,175.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M567.41,175.15a2.66,2.66,0,0,0,2,1.57c.79.07,1.17-.52.85-1.32a2.69,2.69,0,0,0-2-1.57C567.47,173.76,567.1,174.35,567.41,175.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M562.88,174.75a2.62,2.62,0,0,0,2,1.57c.78.07,1.17-.52.86-1.32a2.64,2.64,0,0,0-2-1.57C563,173.36,562.57,174,562.88,174.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M558.33,174.36a2.59,2.59,0,0,0,2,1.57c.79.07,1.19-.53.88-1.32a2.61,2.61,0,0,0-2-1.57C558.42,173,558,173.56,558.33,174.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.18,173.6a2.51,2.51,0,0,0,2,1.56c.8.07,1.21-.52.91-1.32a2.55,2.55,0,0,0-2-1.57C549.3,172.21,548.9,172.8,549.18,173.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M544.58,173.23a2.5,2.5,0,0,0,2,1.56c.8.07,1.22-.53.93-1.33a2.52,2.52,0,0,0-2-1.56C544.72,171.84,544.3,172.44,544.58,173.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540,172.87a2.46,2.46,0,0,0,1.95,1.56c.8.06,1.23-.53,1-1.33a2.47,2.47,0,0,0-2-1.56C540.12,171.48,539.7,172.08,540,172.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M410.76,166.45a1.78,1.78,0,0,0,1.72,1.48,1.26,1.26,0,0,0,1.36-1.41,1.8,1.8,0,0,0-1.71-1.49A1.27,1.27,0,0,0,410.76,166.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M405.86,166.34a1.75,1.75,0,0,0,1.7,1.48,1.27,1.27,0,0,0,1.38-1.41,1.76,1.76,0,0,0-1.71-1.48A1.27,1.27,0,0,0,405.86,166.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.94,166.25a1.73,1.73,0,0,0,1.7,1.47,1.29,1.29,0,0,0,1.39-1.41,1.74,1.74,0,0,0-1.69-1.48A1.3,1.3,0,0,0,400.94,166.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396,166.16a1.72,1.72,0,0,0,1.68,1.48,1.32,1.32,0,0,0,1.41-1.42,1.73,1.73,0,0,0-1.69-1.48A1.31,1.31,0,0,0,396,166.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.11,166.09a1.69,1.69,0,0,0,1.67,1.47,1.34,1.34,0,0,0,1.42-1.43,1.71,1.71,0,0,0-1.68-1.47A1.33,1.33,0,0,0,391.11,166.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.18,166a1.68,1.68,0,0,0,1.66,1.46,1.35,1.35,0,0,0,1.44-1.43,1.69,1.69,0,0,0-1.67-1.46A1.35,1.35,0,0,0,386.18,166Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.25,166a1.66,1.66,0,0,0,1.65,1.46,1.36,1.36,0,0,0,1.45-1.43,1.66,1.66,0,0,0-1.65-1.46A1.36,1.36,0,0,0,381.25,166Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.32,165.93a1.63,1.63,0,0,0,1.64,1.46,1.39,1.39,0,0,0,1.46-1.44,1.65,1.65,0,0,0-1.64-1.46A1.39,1.39,0,0,0,376.32,165.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.39,165.9a1.61,1.61,0,0,0,1.63,1.45,1.4,1.4,0,0,0,1.47-1.44,1.62,1.62,0,0,0-1.63-1.45A1.39,1.39,0,0,0,371.39,165.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.45,165.87a1.59,1.59,0,0,0,1.62,1.45,1.4,1.4,0,0,0,1.48-1.43,1.6,1.6,0,0,0-1.62-1.46A1.42,1.42,0,0,0,366.45,165.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.51,165.86a1.58,1.58,0,0,0,1.61,1.45,1.42,1.42,0,0,0,1.49-1.44,1.59,1.59,0,0,0-1.6-1.46A1.44,1.44,0,0,0,361.51,165.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.57,165.86a1.55,1.55,0,0,0,1.6,1.44,1.43,1.43,0,0,0,1.5-1.44,1.56,1.56,0,0,0-1.59-1.45A1.45,1.45,0,0,0,356.57,165.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.63,165.87a1.53,1.53,0,0,0,1.58,1.44,1.46,1.46,0,0,0,1.52-1.45,1.55,1.55,0,0,0-1.58-1.45A1.48,1.48,0,0,0,351.63,165.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M228.74,169.39a1.13,1.13,0,0,0,1.26,1.36,2,2,0,0,0,1.79-1.53,1.13,1.13,0,0,0-1.26-1.36A2,2,0,0,0,228.74,169.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M223.89,169.66a1.11,1.11,0,0,0,1.25,1.35,2,2,0,0,0,1.8-1.52,1.12,1.12,0,0,0-1.25-1.36A2,2,0,0,0,223.89,169.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.06,169.93a1.1,1.1,0,0,0,1.23,1.36,2,2,0,0,0,1.8-1.53,1.1,1.1,0,0,0-1.23-1.36A2.05,2.05,0,0,0,219.06,169.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M214.23,170.22a1.08,1.08,0,0,0,1.22,1.35,2,2,0,0,0,1.81-1.53,1.08,1.08,0,0,0-1.21-1.35A2.08,2.08,0,0,0,214.23,170.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M209.41,170.52a1.08,1.08,0,0,0,1.21,1.35,2.08,2.08,0,0,0,1.82-1.54,1.08,1.08,0,0,0-1.2-1.35A2.1,2.1,0,0,0,209.41,170.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.6,170.83a1.06,1.06,0,0,0,1.19,1.34,2.09,2.09,0,0,0,1.83-1.54,1.05,1.05,0,0,0-1.18-1.34A2.12,2.12,0,0,0,204.6,170.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M199.81,171.14a1,1,0,0,0,1.17,1.34,2.12,2.12,0,0,0,1.84-1.54,1,1,0,0,0-1.17-1.34A2.13,2.13,0,0,0,199.81,171.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195,171.47a1,1,0,0,0,1.16,1.34,2.14,2.14,0,0,0,1.84-1.55,1,1,0,0,0-1.15-1.34A2.17,2.17,0,0,0,195,171.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.24,171.8a1,1,0,0,0,1.14,1.34,2.17,2.17,0,0,0,1.86-1.55,1,1,0,0,0-1.14-1.34A2.21,2.21,0,0,0,190.24,171.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.48,172.14a1,1,0,0,0,1.12,1.34,2.22,2.22,0,0,0,1.87-1.55,1,1,0,0,0-1.13-1.34A2.22,2.22,0,0,0,185.48,172.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M180.72,172.49a1,1,0,0,0,1.11,1.33,2.23,2.23,0,0,0,1.88-1.55,1,1,0,0,0-1.11-1.33A2.27,2.27,0,0,0,180.72,172.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176,172.85a1,1,0,0,0,1.1,1.33,2.27,2.27,0,0,0,1.88-1.55,1,1,0,0,0-1.09-1.33A2.28,2.28,0,0,0,176,172.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.25,173.22a1,1,0,0,0,1.08,1.33,2.3,2.3,0,0,0,1.89-1.56,1,1,0,0,0-1.08-1.33A2.34,2.34,0,0,0,171.25,173.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.54,173.6a1,1,0,0,0,1.06,1.32,2.32,2.32,0,0,0,1.9-1.56,1,1,0,0,0-1.06-1.32A2.31,2.31,0,0,0,166.54,173.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.83,174a1,1,0,0,0,1,1.32,2.33,2.33,0,0,0,1.91-1.56,1,1,0,0,0-1-1.32A2.38,2.38,0,0,0,161.83,174Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574.54,171.45a2.73,2.73,0,0,0,2,1.58c.77.07,1.12-.52.78-1.32a2.75,2.75,0,0,0-2-1.58C574.56,170.06,574.21,170.65,574.54,171.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M570.08,171a2.7,2.7,0,0,0,2,1.57c.78.08,1.14-.51.81-1.31a2.76,2.76,0,0,0-2-1.59C570.11,169.64,569.76,170.24,570.08,171Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M565.61,170.63a2.67,2.67,0,0,0,2,1.58c.78.07,1.15-.52.82-1.32a2.68,2.68,0,0,0-2-1.58C565.65,169.24,565.29,169.83,565.61,170.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M561.12,170.23a2.64,2.64,0,0,0,2,1.58c.78.07,1.16-.53.84-1.33a2.63,2.63,0,0,0-2-1.57C561.17,168.84,560.8,169.43,561.12,170.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M556.61,169.84a2.57,2.57,0,0,0,2,1.57c.79.07,1.17-.52.86-1.32a2.62,2.62,0,0,0-2-1.58C556.68,168.44,556.3,169,556.61,169.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543,168.71a2.55,2.55,0,0,0,2,1.57c.8.06,1.2-.54.91-1.34a2.52,2.52,0,0,0-2-1.56C543.09,167.31,542.69,167.91,543,168.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M538.4,168.35a2.47,2.47,0,0,0,2,1.56c.8.07,1.21-.53.92-1.33a2.5,2.5,0,0,0-1.95-1.57C538.53,167,538.12,167.55,538.4,168.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.81,168a2.44,2.44,0,0,0,1.94,1.56c.8.06,1.22-.54.94-1.34a2.47,2.47,0,0,0-1.94-1.56C534,166.59,533.53,167.2,533.81,168Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.08,162a1.81,1.81,0,0,0,1.72,1.49,1.24,1.24,0,0,0,1.34-1.41,1.84,1.84,0,0,0-1.72-1.5A1.25,1.25,0,0,0,415.08,162Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M410.22,161.92a1.79,1.79,0,0,0,1.71,1.49,1.26,1.26,0,0,0,1.35-1.42,1.79,1.79,0,0,0-1.71-1.49A1.26,1.26,0,0,0,410.22,161.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M405.35,161.81a1.76,1.76,0,0,0,1.7,1.49,1.26,1.26,0,0,0,1.36-1.42,1.77,1.77,0,0,0-1.7-1.49A1.27,1.27,0,0,0,405.35,161.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.48,161.72a1.74,1.74,0,0,0,1.68,1.48,1.28,1.28,0,0,0,1.38-1.42,1.75,1.75,0,0,0-1.69-1.49A1.29,1.29,0,0,0,400.48,161.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395.6,161.63a1.7,1.7,0,0,0,1.67,1.48,1.29,1.29,0,0,0,1.39-1.42A1.73,1.73,0,0,0,397,160.2,1.31,1.31,0,0,0,395.6,161.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.72,161.56a1.69,1.69,0,0,0,1.66,1.47,1.3,1.3,0,0,0,1.4-1.42,1.69,1.69,0,0,0-1.66-1.48A1.31,1.31,0,0,0,390.72,161.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.83,161.5a1.67,1.67,0,0,0,1.65,1.47,1.34,1.34,0,0,0,1.42-1.44,1.69,1.69,0,0,0-1.66-1.47A1.34,1.34,0,0,0,385.83,161.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.94,161.44a1.64,1.64,0,0,0,1.64,1.47,1.35,1.35,0,0,0,1.43-1.44,1.67,1.67,0,0,0-1.64-1.47A1.36,1.36,0,0,0,380.94,161.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376,161.4a1.62,1.62,0,0,0,1.63,1.46,1.37,1.37,0,0,0,1.45-1.44,1.64,1.64,0,0,0-1.63-1.46A1.38,1.38,0,0,0,376,161.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.15,161.37a1.61,1.61,0,0,0,1.62,1.46,1.38,1.38,0,0,0,1.45-1.44,1.62,1.62,0,0,0-1.62-1.47A1.39,1.39,0,0,0,371.15,161.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.25,161.34a1.58,1.58,0,0,0,1.61,1.46,1.4,1.4,0,0,0,1.47-1.44,1.62,1.62,0,0,0-1.61-1.47A1.41,1.41,0,0,0,366.25,161.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.35,161.33a1.57,1.57,0,0,0,1.59,1.46,1.43,1.43,0,0,0,1.49-1.45,1.58,1.58,0,0,0-1.6-1.46A1.43,1.43,0,0,0,361.35,161.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.44,161.33a1.56,1.56,0,0,0,1.59,1.45,1.44,1.44,0,0,0,1.49-1.45,1.56,1.56,0,0,0-1.58-1.46A1.46,1.46,0,0,0,356.44,161.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.54,161.34a1.53,1.53,0,0,0,1.57,1.45,1.47,1.47,0,0,0,1.51-1.46,1.54,1.54,0,0,0-1.57-1.45A1.46,1.46,0,0,0,351.54,161.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.63,161.36a1.51,1.51,0,0,0,1.56,1.44,1.46,1.46,0,0,0,1.52-1.46,1.52,1.52,0,0,0-1.56-1.45A1.5,1.5,0,0,0,346.63,161.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M224.78,165.14A1.1,1.1,0,0,0,226,166.5,2,2,0,0,0,227.8,165a1.11,1.11,0,0,0-1.22-1.37A2,2,0,0,0,224.78,165.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M220,165.42a1.09,1.09,0,0,0,1.21,1.36,2,2,0,0,0,1.8-1.54,1.08,1.08,0,0,0-1.2-1.36A2.07,2.07,0,0,0,220,165.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M215.19,165.71a1.07,1.07,0,0,0,1.2,1.35,2.06,2.06,0,0,0,1.81-1.54,1.08,1.08,0,0,0-1.19-1.36A2.09,2.09,0,0,0,215.19,165.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210.41,166a1.06,1.06,0,0,0,1.18,1.36,2.08,2.08,0,0,0,1.82-1.54,1.07,1.07,0,0,0-1.18-1.36A2.11,2.11,0,0,0,210.41,166Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M205.64,166.31a1,1,0,0,0,1.17,1.35,2.12,2.12,0,0,0,1.83-1.54,1.06,1.06,0,0,0-1.17-1.36A2.15,2.15,0,0,0,205.64,166.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200.88,166.63A1,1,0,0,0,202,168a2.16,2.16,0,0,0,1.84-1.55,1,1,0,0,0-1.15-1.35A2.15,2.15,0,0,0,200.88,166.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M196.13,167a1,1,0,0,0,1.13,1.35,2.17,2.17,0,0,0,1.85-1.55A1,1,0,0,0,198,165.4,2.19,2.19,0,0,0,196.13,167Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.39,167.29a1,1,0,0,0,1.12,1.34,2.22,2.22,0,0,0,1.86-1.55,1,1,0,0,0-1.12-1.35A2.23,2.23,0,0,0,191.39,167.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M186.66,167.63a1,1,0,0,0,1.11,1.34,2.23,2.23,0,0,0,1.86-1.56,1,1,0,0,0-1.1-1.34A2.25,2.25,0,0,0,186.66,167.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182,168a1,1,0,0,0,1.09,1.34,2.25,2.25,0,0,0,1.87-1.56,1,1,0,0,0-1.09-1.34A2.27,2.27,0,0,0,182,168Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.24,168.34a1,1,0,0,0,1.08,1.34,2.3,2.3,0,0,0,1.88-1.56,1,1,0,0,0-1.07-1.34A2.31,2.31,0,0,0,177.24,168.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.55,168.71a1,1,0,0,0,1.06,1.33,2.31,2.31,0,0,0,1.89-1.56,1,1,0,0,0-1.05-1.33A2.33,2.33,0,0,0,172.55,168.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.87,169.09a1,1,0,0,0,1,1.33,2.33,2.33,0,0,0,1.9-1.57,1,1,0,0,0-1-1.33A2.39,2.39,0,0,0,167.87,169.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M585.77,168.19a2.83,2.83,0,0,0,2,1.59c.76.08,1.07-.51.71-1.31a2.89,2.89,0,0,0-2-1.59C585.72,166.8,585.4,167.39,585.77,168.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M572.62,166.91a2.74,2.74,0,0,0,2,1.59c.76.07,1.1-.52.76-1.32a2.78,2.78,0,0,0-2-1.59C572.62,165.52,572.28,166.11,572.62,166.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M568.2,166.5a2.73,2.73,0,0,0,2,1.58c.77.08,1.12-.52.78-1.32a2.74,2.74,0,0,0-2-1.59C568.21,165.1,567.87,165.7,568.2,166.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.32,164.17a2.54,2.54,0,0,0,2,1.57c.79.07,1.19-.53.89-1.34a2.56,2.56,0,0,0-2-1.57C541.42,162.77,541,163.37,541.32,164.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.79,163.81a2.49,2.49,0,0,0,2,1.57c.79.06,1.19-.54.9-1.34a2.51,2.51,0,0,0-1.95-1.57C536.9,162.4,536.5,163,536.79,163.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419.31,157.61a1.83,1.83,0,0,0,1.72,1.5,1.22,1.22,0,0,0,1.3-1.42,1.84,1.84,0,0,0-1.72-1.5A1.21,1.21,0,0,0,419.31,157.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.49,157.48a1.8,1.8,0,0,0,1.71,1.5,1.23,1.23,0,0,0,1.32-1.42,1.82,1.82,0,0,0-1.72-1.5A1.23,1.23,0,0,0,414.49,157.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.66,157.37a1.79,1.79,0,0,0,1.7,1.49,1.24,1.24,0,0,0,1.33-1.42,1.79,1.79,0,0,0-1.7-1.5A1.25,1.25,0,0,0,409.66,157.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.83,157.26a1.76,1.76,0,0,0,1.69,1.49,1.26,1.26,0,0,0,1.35-1.42,1.78,1.78,0,0,0-1.69-1.5A1.28,1.28,0,0,0,404.83,157.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400,157.17a1.73,1.73,0,0,0,1.68,1.49,1.28,1.28,0,0,0,1.36-1.43,1.76,1.76,0,0,0-1.68-1.49A1.28,1.28,0,0,0,400,157.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395.16,157.08a1.72,1.72,0,0,0,1.67,1.49,1.3,1.3,0,0,0,1.37-1.43,1.73,1.73,0,0,0-1.67-1.49A1.29,1.29,0,0,0,395.16,157.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.31,157a1.7,1.7,0,0,0,1.66,1.48,1.32,1.32,0,0,0,1.39-1.43,1.71,1.71,0,0,0-1.66-1.49A1.32,1.32,0,0,0,390.31,157Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.47,157a1.67,1.67,0,0,0,1.64,1.47,1.32,1.32,0,0,0,1.4-1.43,1.7,1.7,0,0,0-1.64-1.49A1.34,1.34,0,0,0,385.47,157Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.61,156.89a1.66,1.66,0,0,0,1.64,1.48,1.35,1.35,0,0,0,1.41-1.44,1.66,1.66,0,0,0-1.63-1.48A1.36,1.36,0,0,0,380.61,156.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M375.76,156.85a1.63,1.63,0,0,0,1.62,1.47,1.37,1.37,0,0,0,1.43-1.44,1.65,1.65,0,0,0-1.62-1.48A1.37,1.37,0,0,0,375.76,156.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.9,156.82a1.6,1.6,0,0,0,1.61,1.46,1.37,1.37,0,0,0,1.44-1.44,1.61,1.61,0,0,0-1.61-1.47A1.38,1.38,0,0,0,370.9,156.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366,156.79a1.59,1.59,0,0,0,1.6,1.47,1.4,1.4,0,0,0,1.45-1.45,1.59,1.59,0,0,0-1.59-1.47A1.41,1.41,0,0,0,366,156.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.18,156.78a1.56,1.56,0,0,0,1.58,1.46,1.41,1.41,0,0,0,1.47-1.45,1.58,1.58,0,0,0-1.58-1.47A1.43,1.43,0,0,0,361.18,156.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.31,156.78a1.56,1.56,0,0,0,1.58,1.46,1.44,1.44,0,0,0,1.48-1.46,1.55,1.55,0,0,0-1.57-1.46A1.45,1.45,0,0,0,356.31,156.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.45,156.79a1.52,1.52,0,0,0,1.56,1.45,1.44,1.44,0,0,0,1.49-1.46,1.54,1.54,0,0,0-1.56-1.46A1.46,1.46,0,0,0,351.45,156.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M216.18,161.17a1.07,1.07,0,0,0,1.18,1.36,2.09,2.09,0,0,0,1.81-1.54,1.07,1.07,0,0,0-1.17-1.37A2.12,2.12,0,0,0,216.18,161.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M211.44,161.47a1.06,1.06,0,0,0,1.16,1.36,2.11,2.11,0,0,0,1.82-1.55,1.06,1.06,0,0,0-1.16-1.36A2.13,2.13,0,0,0,211.44,161.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.71,161.78a1,1,0,0,0,1.14,1.35,2.12,2.12,0,0,0,1.83-1.55,1,1,0,0,0-1.14-1.36A2.15,2.15,0,0,0,206.71,161.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M202,162.09a1,1,0,0,0,1.13,1.36,2.15,2.15,0,0,0,1.83-1.56,1,1,0,0,0-1.12-1.35A2.17,2.17,0,0,0,202,162.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197.28,162.42a1,1,0,0,0,1.11,1.35,2.2,2.2,0,0,0,1.85-1.56,1,1,0,0,0-1.11-1.35A2.2,2.2,0,0,0,197.28,162.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M192.58,162.76a1,1,0,0,0,1.09,1.34,2.21,2.21,0,0,0,1.86-1.56,1,1,0,0,0-1.09-1.35A2.22,2.22,0,0,0,192.58,162.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187.89,163.1a1,1,0,0,0,1.08,1.34,2.23,2.23,0,0,0,1.86-1.56,1,1,0,0,0-1.07-1.35A2.28,2.28,0,0,0,187.89,163.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.21,163.45a1,1,0,0,0,1.06,1.34,2.27,2.27,0,0,0,1.88-1.56,1,1,0,0,0-1.06-1.35A2.32,2.32,0,0,0,183.21,163.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178.55,163.81a1,1,0,0,0,1,1.34,2.28,2.28,0,0,0,1.88-1.57,1,1,0,0,0-1-1.34A2.32,2.32,0,0,0,178.55,163.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.89,164.18a1,1,0,0,0,1,1.34,2.33,2.33,0,0,0,1.89-1.57,1,1,0,0,0-1-1.34A2.36,2.36,0,0,0,173.89,164.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.25,164.56a.94.94,0,0,0,1,1.33,2.37,2.37,0,0,0,1.9-1.57.94.94,0,0,0-1-1.33A2.37,2.37,0,0,0,169.25,164.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M164.63,165a.93.93,0,0,0,1,1.33,2.38,2.38,0,0,0,1.91-1.58c.25-.8-.19-1.4-1-1.33A2.4,2.4,0,0,0,164.63,165Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.41,165.74c-.27.81.17,1.4,1,1.33a2.44,2.44,0,0,0,1.92-1.58c.26-.8-.17-1.4-1-1.33A2.47,2.47,0,0,0,155.41,165.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M642.05,170.33a3.42,3.42,0,0,0,2.1,1.61c.7.09.89-.48.42-1.29a3.46,3.46,0,0,0-2.1-1.61C641.78,169,641.59,169.53,642.05,170.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M638,169.82a3.35,3.35,0,0,0,2.1,1.61c.7.09.89-.49.43-1.29a3.37,3.37,0,0,0-2.1-1.61C637.77,168.44,637.57,169,638,169.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M634,169.31a3.35,3.35,0,0,0,2.09,1.62c.71.08.91-.49.46-1.3a3.39,3.39,0,0,0-2.09-1.61C633.74,167.93,633.53,168.51,634,169.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M609.22,166.38a3.12,3.12,0,0,0,2.06,1.61c.73.08,1-.51.57-1.31a3.17,3.17,0,0,0-2.06-1.61C609.06,165,608.81,165.58,609.22,166.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M583.69,163.65a2.89,2.89,0,0,0,2,1.59c.75.08,1-.51.68-1.32a3,3,0,0,0-2-1.6C583.62,162.25,583.32,162.84,583.69,163.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M579.37,163.21a2.85,2.85,0,0,0,2,1.6c.75.07,1.06-.52.69-1.33a2.85,2.85,0,0,0-2-1.59C579.31,161.81,579,162.41,579.37,163.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M570.66,162.36a2.78,2.78,0,0,0,2,1.59c.76.08,1.09-.52.73-1.32a2.81,2.81,0,0,0-2-1.6C570.64,161,570.31,161.56,570.66,162.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.64,159.62a2.54,2.54,0,0,0,2,1.57c.78.07,1.17-.53.86-1.34a2.55,2.55,0,0,0-2-1.58C539.72,158.21,539.34,158.81,539.64,159.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.14,159.25a2.52,2.52,0,0,0,2,1.58c.78.06,1.18-.54.88-1.35a2.54,2.54,0,0,0-2-1.57C535.24,157.84,534.84,158.45,535.14,159.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530.63,158.9a2.47,2.47,0,0,0,1.94,1.57c.78.06,1.18-.54.89-1.35a2.51,2.51,0,0,0-1.94-1.57C530.74,157.49,530.34,158.09,530.63,158.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.19,153.32a1.87,1.87,0,0,0,1.74,1.51,1.16,1.16,0,0,0,1.25-1.41,1.91,1.91,0,0,0-1.74-1.52A1.18,1.18,0,0,0,428.19,153.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.42,153.18a1.87,1.87,0,0,0,1.73,1.51,1.19,1.19,0,0,0,1.27-1.42,1.86,1.86,0,0,0-1.73-1.51A1.2,1.2,0,0,0,423.42,153.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.65,153a1.85,1.85,0,0,0,1.72,1.51,1.21,1.21,0,0,0,1.28-1.42,1.84,1.84,0,0,0-1.72-1.51A1.21,1.21,0,0,0,418.65,153Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.87,152.92a1.81,1.81,0,0,0,1.71,1.5,1.23,1.23,0,0,0,1.3-1.43,1.83,1.83,0,0,0-1.71-1.5A1.23,1.23,0,0,0,413.87,152.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.09,152.8a1.78,1.78,0,0,0,1.69,1.5,1.23,1.23,0,0,0,1.31-1.43,1.78,1.78,0,0,0-1.69-1.5A1.24,1.24,0,0,0,409.09,152.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.3,152.7a1.75,1.75,0,0,0,1.68,1.49,1.25,1.25,0,0,0,1.33-1.43,1.78,1.78,0,0,0-1.68-1.5A1.27,1.27,0,0,0,404.3,152.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.5,152.6a1.75,1.75,0,0,0,1.67,1.5,1.28,1.28,0,0,0,1.35-1.44,1.74,1.74,0,0,0-1.67-1.49A1.28,1.28,0,0,0,399.5,152.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.71,152.52a1.72,1.72,0,0,0,1.65,1.49,1.29,1.29,0,0,0,1.36-1.44,1.73,1.73,0,0,0-1.66-1.49A1.28,1.28,0,0,0,394.71,152.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.9,152.44a1.69,1.69,0,0,0,1.65,1.49,1.3,1.3,0,0,0,1.37-1.44,1.71,1.71,0,0,0-1.65-1.49A1.31,1.31,0,0,0,389.9,152.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.09,152.38a1.67,1.67,0,0,0,1.64,1.48,1.31,1.31,0,0,0,1.38-1.44,1.68,1.68,0,0,0-1.63-1.49A1.34,1.34,0,0,0,385.09,152.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.28,152.33a1.65,1.65,0,0,0,1.62,1.48,1.34,1.34,0,0,0,1.4-1.45,1.65,1.65,0,0,0-1.62-1.48A1.35,1.35,0,0,0,380.28,152.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M375.47,152.28a1.63,1.63,0,0,0,1.61,1.48,1.35,1.35,0,0,0,1.41-1.45,1.64,1.64,0,0,0-1.61-1.48A1.35,1.35,0,0,0,375.47,152.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.65,152.25a1.6,1.6,0,0,0,1.6,1.47,1.37,1.37,0,0,0,1.43-1.45,1.63,1.63,0,0,0-1.6-1.48A1.38,1.38,0,0,0,370.65,152.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M365.83,152.23a1.59,1.59,0,0,0,1.59,1.47,1.4,1.4,0,0,0,1.44-1.46,1.6,1.6,0,0,0-1.59-1.47A1.4,1.4,0,0,0,365.83,152.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361,152.22a1.55,1.55,0,0,0,1.57,1.46,1.42,1.42,0,0,0,1.46-1.46,1.59,1.59,0,0,0-1.58-1.47A1.42,1.42,0,0,0,361,152.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.18,152.21a1.56,1.56,0,0,0,1.56,1.47,1.44,1.44,0,0,0,1.47-1.47,1.56,1.56,0,0,0-1.56-1.46A1.45,1.45,0,0,0,356.18,152.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.36,152.22a1.52,1.52,0,0,0,1.55,1.46,1.44,1.44,0,0,0,1.48-1.46,1.54,1.54,0,0,0-1.55-1.47A1.46,1.46,0,0,0,351.36,152.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.53,152.24a1.51,1.51,0,0,0,1.54,1.46,1.46,1.46,0,0,0,1.49-1.47,1.51,1.51,0,0,0-1.53-1.46A1.47,1.47,0,0,0,346.53,152.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212.51,156.92a1,1,0,0,0,1.13,1.36,2.13,2.13,0,0,0,1.82-1.55,1,1,0,0,0-1.13-1.37A2.13,2.13,0,0,0,212.51,156.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M207.81,157.22a1,1,0,0,0,1.12,1.37,2.15,2.15,0,0,0,1.83-1.56,1,1,0,0,0-1.12-1.36A2.16,2.16,0,0,0,207.81,157.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M203.13,157.54a1,1,0,0,0,1.11,1.36,2.16,2.16,0,0,0,1.83-1.56A1,1,0,0,0,205,156,2.22,2.22,0,0,0,203.13,157.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M198.46,157.87a1,1,0,0,0,1.09,1.36,2.2,2.2,0,0,0,1.84-1.57,1,1,0,0,0-1.08-1.35A2.2,2.2,0,0,0,198.46,157.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.8,158.21a1,1,0,0,0,1.07,1.35,2.22,2.22,0,0,0,1.85-1.57,1,1,0,0,0-1.06-1.35A2.25,2.25,0,0,0,193.8,158.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.15,158.55a1,1,0,0,0,1,1.35,2.26,2.26,0,0,0,1.87-1.57A1,1,0,0,0,191,157,2.26,2.26,0,0,0,189.15,158.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M184.51,158.9a1,1,0,0,0,1,1.35,2.28,2.28,0,0,0,1.87-1.57,1,1,0,0,0-1-1.35A2.3,2.3,0,0,0,184.51,158.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.88,159.27a1,1,0,0,0,1,1.34,2.33,2.33,0,0,0,1.88-1.57,1,1,0,0,0-1-1.35A2.34,2.34,0,0,0,179.88,159.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.27,159.64a.94.94,0,0,0,1,1.34,2.37,2.37,0,0,0,1.89-1.58,1,1,0,0,0-1-1.34A2.37,2.37,0,0,0,175.27,159.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170.67,160c-.26.81.19,1.41,1,1.34a2.37,2.37,0,0,0,1.9-1.57c.25-.81-.19-1.41-1-1.34A2.39,2.39,0,0,0,170.67,160Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M157,161.2c-.28.81.14,1.4.94,1.33a2.47,2.47,0,0,0,1.92-1.58c.27-.81-.15-1.41-.94-1.34A2.5,2.5,0,0,0,157,161.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M147.87,162c-.29.81.11,1.4.9,1.33a2.55,2.55,0,0,0,1.94-1.59c.29-.81-.12-1.4-.9-1.33A2.56,2.56,0,0,0,147.87,162Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M647.32,166.82a3.51,3.51,0,0,0,2.11,1.62c.68.09.84-.48.36-1.29a3.58,3.58,0,0,0-2.11-1.62C647,165.44,646.84,166,647.32,166.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M643.38,166.3a3.49,3.49,0,0,0,2.11,1.62c.68.09.85-.49.37-1.29a3.54,3.54,0,0,0-2.1-1.62C643.08,164.92,642.91,165.5,643.38,166.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M639.42,165.79a3.48,3.48,0,0,0,2.1,1.62c.69.08.87-.49.39-1.3a3.49,3.49,0,0,0-2.09-1.62C639.13,164.4,639,165,639.42,165.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.43,165.28a3.43,3.43,0,0,0,2.1,1.61c.69.09.88-.49.41-1.29a3.4,3.4,0,0,0-2.09-1.62C635.16,163.89,635,164.47,635.43,165.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M631.42,164.77a3.36,3.36,0,0,0,2.09,1.61c.7.09.9-.49.44-1.29a3.4,3.4,0,0,0-2.09-1.62C631.16,163.38,631,164,631.42,164.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M627.39,164.27a3.35,3.35,0,0,0,2.08,1.61c.71.09.91-.49.46-1.3a3.36,3.36,0,0,0-2.09-1.61C627.14,162.88,626.94,163.46,627.39,164.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M619.25,163.28a3.3,3.3,0,0,0,2.08,1.61c.71.08.93-.5.49-1.31a3.3,3.3,0,0,0-2.08-1.61C619,161.89,618.82,162.47,619.25,163.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M598.53,160.89a3,3,0,0,0,2.05,1.61c.73.08,1-.51.58-1.32a3.12,3.12,0,0,0-2-1.61C598.39,159.49,598.12,160.08,598.53,160.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M594.32,160.43a3,3,0,0,0,2,1.6c.74.08,1-.51.61-1.31a3.07,3.07,0,0,0-2.05-1.61C594.19,159,593.92,159.62,594.32,160.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M590.09,160a3,3,0,0,0,2,1.6c.74.08,1-.51.62-1.32a3,3,0,0,0-2-1.6C590,158.58,589.7,159.17,590.09,160Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M585.84,159.53a3,3,0,0,0,2,1.6c.74.08,1-.51.64-1.32a3,3,0,0,0-2-1.6C585.75,158.13,585.46,158.72,585.84,159.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M581.58,159.09a2.93,2.93,0,0,0,2,1.6c.75.07,1-.52.66-1.33a2.94,2.94,0,0,0-2-1.6C581.49,157.69,581.2,158.28,581.58,159.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537.91,155.05a2.6,2.6,0,0,0,2,1.58c.77.06,1.15-.54.83-1.35a2.58,2.58,0,0,0-2-1.58C538,153.63,537.6,154.24,537.91,155.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.45,154.69a2.58,2.58,0,0,0,1.95,1.57c.78.07,1.16-.54.86-1.35a2.59,2.59,0,0,0-2-1.58C533.53,153.27,533.15,153.88,533.45,154.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427.44,148.74a1.89,1.89,0,0,0,1.73,1.52,1.16,1.16,0,0,0,1.23-1.42,1.9,1.9,0,0,0-1.73-1.52A1.16,1.16,0,0,0,427.44,148.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422.71,148.6a1.86,1.86,0,0,0,1.72,1.51,1.18,1.18,0,0,0,1.25-1.42,1.88,1.88,0,0,0-1.72-1.52A1.19,1.19,0,0,0,422.71,148.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418,148.46a1.84,1.84,0,0,0,1.71,1.51,1.19,1.19,0,0,0,1.26-1.42,1.84,1.84,0,0,0-1.71-1.51A1.19,1.19,0,0,0,418,148.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.24,148.34a1.82,1.82,0,0,0,1.7,1.5,1.2,1.2,0,0,0,1.28-1.42,1.82,1.82,0,0,0-1.7-1.51A1.22,1.22,0,0,0,413.24,148.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.5,148.22a1.8,1.8,0,0,0,1.69,1.51,1.23,1.23,0,0,0,1.29-1.44,1.79,1.79,0,0,0-1.69-1.5A1.23,1.23,0,0,0,408.5,148.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.75,148.12a1.77,1.77,0,0,0,1.67,1.5,1.25,1.25,0,0,0,1.32-1.44,1.79,1.79,0,0,0-1.68-1.5A1.25,1.25,0,0,0,403.75,148.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399,148a1.74,1.74,0,0,0,1.66,1.5,1.25,1.25,0,0,0,1.32-1.44,1.75,1.75,0,0,0-1.66-1.5A1.27,1.27,0,0,0,399,148Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.24,147.94a1.71,1.71,0,0,0,1.65,1.49,1.27,1.27,0,0,0,1.34-1.44,1.72,1.72,0,0,0-1.65-1.49A1.28,1.28,0,0,0,394.24,147.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.48,147.86a1.7,1.7,0,0,0,1.63,1.5,1.31,1.31,0,0,0,1.36-1.45,1.7,1.7,0,0,0-1.64-1.49A1.3,1.3,0,0,0,389.48,147.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384.71,147.8a1.68,1.68,0,0,0,1.63,1.49,1.32,1.32,0,0,0,1.37-1.45,1.7,1.7,0,0,0-1.63-1.49A1.32,1.32,0,0,0,384.71,147.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M379.94,147.75a1.64,1.64,0,0,0,1.61,1.48,1.34,1.34,0,0,0,1.39-1.45,1.67,1.67,0,0,0-1.62-1.49A1.34,1.34,0,0,0,379.94,147.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M375.17,147.7a1.61,1.61,0,0,0,1.6,1.48,1.35,1.35,0,0,0,1.4-1.45,1.63,1.63,0,0,0-1.6-1.48A1.35,1.35,0,0,0,375.17,147.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.39,147.67a1.62,1.62,0,0,0,1.59,1.48,1.37,1.37,0,0,0,1.41-1.46,1.62,1.62,0,0,0-1.59-1.48A1.38,1.38,0,0,0,370.39,147.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M365.61,147.65a1.58,1.58,0,0,0,1.58,1.47,1.38,1.38,0,0,0,1.42-1.46,1.58,1.58,0,0,0-1.57-1.47A1.39,1.39,0,0,0,365.61,147.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.83,147.64a1.56,1.56,0,0,0,1.56,1.47,1.4,1.4,0,0,0,1.44-1.47,1.56,1.56,0,0,0-1.56-1.47A1.42,1.42,0,0,0,360.83,147.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.05,147.63a1.54,1.54,0,0,0,1.55,1.47,1.42,1.42,0,0,0,1.45-1.47,1.55,1.55,0,0,0-1.55-1.47A1.44,1.44,0,0,0,356.05,147.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.26,147.64a1.53,1.53,0,0,0,1.54,1.47,1.45,1.45,0,0,0,1.47-1.47,1.53,1.53,0,0,0-1.54-1.47A1.46,1.46,0,0,0,351.26,147.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.48,147.66a1.49,1.49,0,0,0,1.52,1.46,1.46,1.46,0,0,0,1.49-1.47,1.51,1.51,0,0,0-1.53-1.46A1.46,1.46,0,0,0,346.48,147.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.69,147.69a1.51,1.51,0,1,0,1.5-1.48A1.49,1.49,0,0,0,341.69,147.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M327.34,147.84a1.42,1.42,0,0,0,1.47,1.45,1.54,1.54,0,0,0,1.53-1.49,1.42,1.42,0,0,0-1.47-1.45A1.56,1.56,0,0,0,327.34,147.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M322.55,147.91a1.41,1.41,0,0,0,1.46,1.45,1.57,1.57,0,0,0,1.55-1.49,1.42,1.42,0,0,0-1.46-1.45A1.58,1.58,0,0,0,322.55,147.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M313,148.09a1.37,1.37,0,0,0,1.43,1.44A1.62,1.62,0,0,0,316,148a1.37,1.37,0,0,0-1.43-1.44A1.62,1.62,0,0,0,313,148.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M199.67,153.3a1,1,0,0,0,1.07,1.36,2.2,2.2,0,0,0,1.84-1.56,1,1,0,0,0-1.06-1.36A2.24,2.24,0,0,0,199.67,153.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.05,153.64a1,1,0,0,0,1,1.36,2.24,2.24,0,0,0,1.85-1.57,1,1,0,0,0-1-1.36A2.28,2.28,0,0,0,195.05,153.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.44,154a1,1,0,0,0,1,1.35,2.28,2.28,0,0,0,1.86-1.57,1,1,0,0,0-1-1.36A2.31,2.31,0,0,0,190.44,154Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.84,154.34a1,1,0,0,0,1,1.35,2.3,2.3,0,0,0,1.87-1.57,1,1,0,0,0-1-1.36A2.34,2.34,0,0,0,185.84,154.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.26,154.7a1,1,0,0,0,1,1.35,2.34,2.34,0,0,0,1.88-1.58,1,1,0,0,0-1-1.35A2.36,2.36,0,0,0,181.26,154.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176.68,155.07c-.25.81.19,1.41,1,1.35a2.38,2.38,0,0,0,1.89-1.58c.24-.81-.19-1.41-1-1.35A2.42,2.42,0,0,0,176.68,155.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.12,155.45c-.26.81.17,1.41,1,1.35a2.42,2.42,0,0,0,1.9-1.59c.25-.8-.18-1.41-1-1.34A2.43,2.43,0,0,0,172.12,155.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M149.52,157.48c-.3.8.1,1.4.88,1.32a2.54,2.54,0,0,0,1.94-1.59c.29-.81-.11-1.4-.88-1.33A2.55,2.55,0,0,0,149.52,157.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M640.69,161.76a3.54,3.54,0,0,0,2.1,1.62c.68.09.84-.49.36-1.3a3.59,3.59,0,0,0-2.1-1.62C640.37,160.37,640.21,161,640.69,161.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M636.76,161.24a3.48,3.48,0,0,0,2.1,1.62c.68.09.85-.49.37-1.3a3.52,3.52,0,0,0-2.09-1.62C636.46,159.85,636.29,160.43,636.76,161.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M628.83,160.22a3.38,3.38,0,0,0,2.09,1.61c.69.09.88-.49.41-1.3a3.39,3.39,0,0,0-2.08-1.61C628.56,158.83,628.37,159.41,628.83,160.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M592.06,155.86a3.05,3.05,0,0,0,2,1.61c.73.08,1-.51.58-1.32a3.08,3.08,0,0,0-2-1.61C591.91,154.46,591.66,155.05,592.06,155.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.87,155.41a3,3,0,0,0,2,1.6c.73.08,1-.51.59-1.32a3,3,0,0,0-2-1.6C587.74,154,587.47,154.6,587.87,155.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M579.43,154.52a3,3,0,0,0,2,1.6c.73.07,1-.52.63-1.33a3,3,0,0,0-2-1.6C579.33,153.11,579.05,153.71,579.43,154.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M575.18,154.08a2.9,2.9,0,0,0,2,1.6c.74.08,1-.52.65-1.33a2.92,2.92,0,0,0-2-1.6C575.1,152.67,574.81,153.27,575.18,154.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.31,151.61a2.73,2.73,0,0,0,2,1.59c.76.06,1.1-.54.76-1.35a2.73,2.73,0,0,0-2-1.59C549.32,150.19,549,150.8,549.31,151.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M544.94,151.22a2.68,2.68,0,0,0,2,1.59c.77.06,1.11-.54.78-1.35a2.7,2.7,0,0,0-2-1.59C545,149.8,544.62,150.41,544.94,151.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540.56,150.84a2.65,2.65,0,0,0,2,1.59c.76.06,1.12-.54.79-1.35a2.65,2.65,0,0,0-2-1.59C540.59,149.42,540.24,150,540.56,150.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531.74,150.11a2.56,2.56,0,0,0,1.94,1.58c.77.06,1.14-.55.83-1.36a2.57,2.57,0,0,0-2-1.58C531.8,148.69,531.43,149.29,531.74,150.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.87,147.49a2.32,2.32,0,0,0,1.88,1.57c.79.05,1.23-.57,1-1.38a2.36,2.36,0,0,0-1.88-1.57C496.06,146.06,495.63,146.68,495.87,147.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M426.66,144.15a1.88,1.88,0,0,0,1.73,1.52,1.15,1.15,0,0,0,1.21-1.42,1.9,1.9,0,0,0-1.72-1.52A1.16,1.16,0,0,0,426.66,144.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422,144a1.86,1.86,0,0,0,1.71,1.51,1.16,1.16,0,0,0,1.23-1.42,1.87,1.87,0,0,0-1.71-1.52A1.18,1.18,0,0,0,422,144Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.29,143.87a1.86,1.86,0,0,0,1.7,1.52,1.19,1.19,0,0,0,1.25-1.43,1.85,1.85,0,0,0-1.7-1.52A1.2,1.2,0,0,0,417.29,143.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.6,143.75a1.81,1.81,0,0,0,1.69,1.51,1.21,1.21,0,0,0,1.26-1.44,1.83,1.83,0,0,0-1.69-1.51A1.21,1.21,0,0,0,412.6,143.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.9,143.63a1.78,1.78,0,0,0,1.67,1.51,1.22,1.22,0,0,0,1.28-1.44,1.8,1.8,0,0,0-1.68-1.51A1.23,1.23,0,0,0,407.9,143.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.19,143.53a1.77,1.77,0,0,0,1.67,1.5,1.24,1.24,0,0,0,1.29-1.44,1.77,1.77,0,0,0-1.67-1.5A1.24,1.24,0,0,0,403.19,143.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.48,143.43a1.72,1.72,0,0,0,1.65,1.5,1.25,1.25,0,0,0,1.31-1.44,1.75,1.75,0,0,0-1.65-1.5A1.26,1.26,0,0,0,398.48,143.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.76,143.35a1.71,1.71,0,0,0,1.64,1.49,1.27,1.27,0,0,0,1.33-1.44,1.74,1.74,0,0,0-1.64-1.5A1.29,1.29,0,0,0,393.76,143.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389,143.27a1.7,1.7,0,0,0,1.63,1.5,1.3,1.3,0,0,0,1.34-1.45,1.71,1.71,0,0,0-1.63-1.5A1.31,1.31,0,0,0,389,143.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384.32,143.21a1.67,1.67,0,0,0,1.61,1.49,1.31,1.31,0,0,0,1.36-1.45,1.69,1.69,0,0,0-1.62-1.49A1.31,1.31,0,0,0,384.32,143.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M379.59,143.16a1.64,1.64,0,0,0,1.6,1.48,1.32,1.32,0,0,0,1.37-1.45,1.66,1.66,0,0,0-1.6-1.49A1.33,1.33,0,0,0,379.59,143.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M374.86,143.11a1.63,1.63,0,0,0,1.59,1.49,1.35,1.35,0,0,0,1.38-1.46,1.64,1.64,0,0,0-1.59-1.49A1.36,1.36,0,0,0,374.86,143.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.13,143.08a1.59,1.59,0,0,0,1.57,1.48,1.36,1.36,0,0,0,1.4-1.46,1.61,1.61,0,0,0-1.58-1.48A1.36,1.36,0,0,0,370.13,143.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M365.39,143.06a1.59,1.59,0,0,0,1.56,1.48,1.39,1.39,0,0,0,1.42-1.47,1.6,1.6,0,0,0-1.57-1.48A1.39,1.39,0,0,0,365.39,143.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.65,143.05a1.56,1.56,0,0,0,1.55,1.47,1.4,1.4,0,0,0,1.43-1.47,1.56,1.56,0,0,0-1.55-1.47A1.4,1.4,0,0,0,360.65,143.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.91,143a1.53,1.53,0,0,0,1.54,1.47,1.42,1.42,0,0,0,1.44-1.47,1.55,1.55,0,0,0-1.54-1.47A1.43,1.43,0,0,0,355.91,143Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.17,143.05a1.52,1.52,0,0,0,1.52,1.47,1.44,1.44,0,0,0,1.46-1.47,1.51,1.51,0,0,0-1.52-1.47A1.45,1.45,0,0,0,351.17,143.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.43,143.07a1.5,1.5,0,0,0,1.51,1.47,1.47,1.47,0,0,0,1.47-1.48,1.51,1.51,0,0,0-1.51-1.47A1.47,1.47,0,0,0,346.43,143.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.69,143.1a1.49,1.49,0,1,0,1.48-1.48A1.47,1.47,0,0,0,341.69,143.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M336.94,143.14a1.46,1.46,0,0,0,1.49,1.46,1.5,1.5,0,0,0,1.49-1.48,1.45,1.45,0,0,0-1.48-1.46A1.51,1.51,0,0,0,336.94,143.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.2,143.19a1.43,1.43,0,0,0,1.47,1.46,1.53,1.53,0,0,0,1.51-1.49,1.45,1.45,0,0,0-1.47-1.46A1.53,1.53,0,0,0,332.2,143.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M327.46,143.25a1.4,1.4,0,0,0,1.45,1.45,1.54,1.54,0,0,0,1.53-1.49,1.43,1.43,0,0,0-1.46-1.45A1.55,1.55,0,0,0,327.46,143.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M322.71,143.32a1.39,1.39,0,0,0,1.45,1.45,1.56,1.56,0,0,0,1.53-1.49,1.4,1.4,0,0,0-1.44-1.45A1.57,1.57,0,0,0,322.71,143.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M318,143.41a1.38,1.38,0,0,0,1.43,1.44,1.58,1.58,0,0,0,1.55-1.5,1.38,1.38,0,0,0-1.43-1.44A1.6,1.6,0,0,0,318,143.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M313.23,143.5a1.36,1.36,0,0,0,1.42,1.44,1.61,1.61,0,0,0,1.56-1.5A1.36,1.36,0,0,0,314.8,142,1.62,1.62,0,0,0,313.23,143.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M308.5,143.6a1.33,1.33,0,0,0,1.4,1.44,1.62,1.62,0,0,0,1.57-1.5,1.34,1.34,0,0,0-1.4-1.44A1.63,1.63,0,0,0,308.5,143.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M303.76,143.71a1.32,1.32,0,0,0,1.39,1.44,1.67,1.67,0,0,0,1.59-1.51,1.33,1.33,0,0,0-1.39-1.43A1.66,1.66,0,0,0,303.76,143.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.66,152.49c-.3.81.09,1.4.87,1.33a2.53,2.53,0,0,0,1.93-1.59c.29-.81-.1-1.41-.87-1.34A2.55,2.55,0,0,0,155.66,152.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M151.2,152.91c-.3.81.09,1.4.86,1.33a2.58,2.58,0,0,0,1.94-1.6c.3-.81-.09-1.4-.86-1.33A2.6,2.6,0,0,0,151.2,152.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M146.76,153.34c-.3.81.07,1.4.84,1.33a2.6,2.6,0,0,0,2-1.6c.3-.81-.07-1.41-.84-1.33A2.63,2.63,0,0,0,146.76,153.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M142.34,153.77c-.32.81,0,1.41.82,1.33a2.65,2.65,0,0,0,2-1.6c.31-.81-.06-1.4-.82-1.33A2.69,2.69,0,0,0,142.34,153.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M137.93,154.22c-.32.81,0,1.4.81,1.32a2.68,2.68,0,0,0,2-1.6c.32-.81,0-1.4-.8-1.33A2.73,2.73,0,0,0,137.93,154.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M133.54,154.67c-.33.81,0,1.4.79,1.32a2.74,2.74,0,0,0,2-1.6c.32-.81,0-1.41-.79-1.33A2.73,2.73,0,0,0,133.54,154.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M638,157.2a3.5,3.5,0,0,0,2.1,1.62c.67.09.82-.48.34-1.29a3.55,3.55,0,0,0-2.1-1.62C637.64,155.81,637.49,156.39,638,157.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M634.08,156.68a3.45,3.45,0,0,0,2.09,1.62c.68.09.84-.49.36-1.29a3.54,3.54,0,0,0-2.1-1.63C633.76,155.29,633.6,155.87,634.08,156.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M630.16,156.17a3.46,3.46,0,0,0,2.09,1.62c.68.09.85-.5.37-1.3a3.45,3.45,0,0,0-2.09-1.62C629.85,154.78,629.69,155.36,630.16,156.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M626.22,155.66a3.45,3.45,0,0,0,2.08,1.62c.69.08.86-.5.4-1.3a3.47,3.47,0,0,0-2.09-1.63C625.93,154.26,625.75,154.85,626.22,155.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M593.9,151.75a3.09,3.09,0,0,0,2.05,1.61c.71.08,1-.51.54-1.32a3.17,3.17,0,0,0-2-1.61C593.73,150.35,593.49,150.94,593.9,151.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M585.61,150.83a3,3,0,0,0,2,1.61c.72.08,1-.51.57-1.32a3,3,0,0,0-2-1.61C585.47,149.43,585.21,150,585.61,150.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M577.25,149.94a2.94,2.94,0,0,0,2,1.6c.73.08,1-.51.61-1.33a3,3,0,0,0-2-1.6C577.13,148.53,576.86,149.13,577.25,149.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M573,149.5a2.9,2.9,0,0,0,2,1.6c.73.08,1-.52.63-1.33a3,3,0,0,0-2-1.6C572.94,148.09,572.66,148.69,573,149.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.4,147a2.73,2.73,0,0,0,2,1.59c.75.07,1.08-.53.74-1.34a2.78,2.78,0,0,0-2-1.6C547.39,145.6,547.06,146.21,547.4,147Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.07,146.63a2.69,2.69,0,0,0,2,1.59c.76.07,1.09-.53.75-1.35a2.7,2.7,0,0,0-2-1.59C543.07,145.22,542.74,145.82,543.07,146.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M538.72,146.25a2.66,2.66,0,0,0,2,1.59c.75.07,1.1-.54.77-1.35a2.68,2.68,0,0,0-2-1.59C538.74,144.83,538.4,145.44,538.72,146.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530,145.52a2.6,2.6,0,0,0,1.95,1.58c.76.06,1.12-.54.81-1.36a2.6,2.6,0,0,0-2-1.58C530,144.09,529.67,144.7,530,145.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M490,142.61a2.3,2.3,0,0,0,1.86,1.56c.79.05,1.22-.57,1-1.38a2.31,2.31,0,0,0-1.87-1.56C490.13,141.18,489.7,141.8,490,142.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.44,142.33a2.27,2.27,0,0,0,1.85,1.56c.79.05,1.23-.57,1-1.38a2.24,2.24,0,0,0-1.85-1.56C485.64,140.9,485.2,141.52,485.44,142.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435.13,139.88a2,2,0,0,0,1.74,1.52A1.11,1.11,0,0,0,438,140a2,2,0,0,0-1.73-1.53A1.13,1.13,0,0,0,435.13,139.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.51,139.71a1.91,1.91,0,0,0,1.72,1.52,1.12,1.12,0,0,0,1.18-1.42,1.91,1.91,0,0,0-1.72-1.52A1.13,1.13,0,0,0,430.51,139.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M425.88,139.55a1.89,1.89,0,0,0,1.71,1.53,1.16,1.16,0,0,0,1.2-1.43,1.91,1.91,0,0,0-1.72-1.52A1.14,1.14,0,0,0,425.88,139.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M421.24,139.41a1.87,1.87,0,0,0,1.7,1.52,1.16,1.16,0,0,0,1.21-1.43,1.87,1.87,0,0,0-1.7-1.52A1.16,1.16,0,0,0,421.24,139.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.59,139.27a1.84,1.84,0,0,0,1.69,1.52,1.18,1.18,0,0,0,1.23-1.43,1.85,1.85,0,0,0-1.69-1.52A1.18,1.18,0,0,0,416.59,139.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.94,139.15a1.82,1.82,0,0,0,1.68,1.51,1.19,1.19,0,0,0,1.24-1.43,1.83,1.83,0,0,0-1.68-1.52A1.2,1.2,0,0,0,411.94,139.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.28,139a1.79,1.79,0,0,0,1.67,1.51,1.21,1.21,0,0,0,1.26-1.44,1.82,1.82,0,0,0-1.67-1.51A1.22,1.22,0,0,0,407.28,139Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402.62,138.93a1.75,1.75,0,0,0,1.65,1.5,1.23,1.23,0,0,0,1.28-1.44,1.77,1.77,0,0,0-1.66-1.5A1.23,1.23,0,0,0,402.62,138.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398,138.83a1.74,1.74,0,0,0,1.64,1.51,1.25,1.25,0,0,0,1.29-1.45,1.74,1.74,0,0,0-1.64-1.5A1.25,1.25,0,0,0,398,138.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.28,138.75a1.71,1.71,0,0,0,1.63,1.5,1.27,1.27,0,0,0,1.3-1.45,1.72,1.72,0,0,0-1.63-1.5A1.27,1.27,0,0,0,393.28,138.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.6,138.67a1.69,1.69,0,0,0,1.62,1.5,1.29,1.29,0,0,0,1.32-1.45,1.71,1.71,0,0,0-1.62-1.5A1.29,1.29,0,0,0,388.6,138.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.92,138.61a1.67,1.67,0,0,0,1.6,1.49,1.3,1.3,0,0,0,1.34-1.45,1.69,1.69,0,0,0-1.6-1.5A1.31,1.31,0,0,0,383.92,138.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M379.24,138.56a1.64,1.64,0,0,0,1.59,1.49,1.32,1.32,0,0,0,1.35-1.46,1.65,1.65,0,0,0-1.59-1.49A1.32,1.32,0,0,0,379.24,138.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M374.55,138.51a1.62,1.62,0,0,0,1.58,1.49,1.33,1.33,0,0,0,1.36-1.46,1.63,1.63,0,0,0-1.57-1.49A1.34,1.34,0,0,0,374.55,138.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M369.86,138.48a1.6,1.6,0,0,0,1.56,1.48,1.34,1.34,0,0,0,1.38-1.46,1.6,1.6,0,0,0-1.56-1.48A1.35,1.35,0,0,0,369.86,138.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M365.17,138.46a1.58,1.58,0,0,0,1.55,1.48,1.37,1.37,0,0,0,1.39-1.47,1.58,1.58,0,0,0-1.55-1.48A1.38,1.38,0,0,0,365.17,138.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.47,138.45a1.55,1.55,0,0,0,1.54,1.47,1.39,1.39,0,0,0,1.41-1.47,1.58,1.58,0,0,0-1.54-1.48A1.41,1.41,0,0,0,360.47,138.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.78,138.44a1.52,1.52,0,0,0,1.52,1.48,1.42,1.42,0,0,0,1.43-1.48A1.55,1.55,0,0,0,357.2,137,1.41,1.41,0,0,0,355.78,138.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.08,138.45a1.49,1.49,0,0,0,1.51,1.47,1.43,1.43,0,0,0,1.44-1.47,1.51,1.51,0,0,0-1.51-1.47A1.43,1.43,0,0,0,351.08,138.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.38,138.47a1.49,1.49,0,0,0,1.49,1.47,1.46,1.46,0,0,0,1.46-1.48,1.5,1.5,0,0,0-1.5-1.47A1.46,1.46,0,0,0,346.38,138.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.68,138.5a1.48,1.48,0,1,0,1.47-1.48A1.47,1.47,0,0,0,341.68,138.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337,138.54a1.44,1.44,0,0,0,1.47,1.46,1.48,1.48,0,0,0,1.48-1.48,1.45,1.45,0,0,0-1.47-1.46A1.5,1.5,0,0,0,337,138.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.28,138.59a1.42,1.42,0,0,0,1.45,1.46,1.52,1.52,0,0,0,1.5-1.49,1.43,1.43,0,0,0-1.45-1.46A1.53,1.53,0,0,0,332.28,138.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M327.58,138.65a1.41,1.41,0,0,0,1.44,1.46,1.54,1.54,0,0,0,1.51-1.49,1.41,1.41,0,0,0-1.44-1.46A1.55,1.55,0,0,0,327.58,138.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M322.88,138.73a1.39,1.39,0,0,0,1.43,1.45,1.56,1.56,0,0,0,1.52-1.5,1.39,1.39,0,0,0-1.42-1.45A1.57,1.57,0,0,0,322.88,138.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M318.18,138.81a1.37,1.37,0,0,0,1.42,1.44,1.57,1.57,0,0,0,1.53-1.49,1.36,1.36,0,0,0-1.41-1.45A1.59,1.59,0,0,0,318.18,138.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M313.49,138.9a1.34,1.34,0,0,0,1.4,1.44,1.6,1.6,0,0,0,1.55-1.5,1.35,1.35,0,0,0-1.4-1.44A1.6,1.6,0,0,0,313.49,138.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M308.79,139a1.33,1.33,0,0,0,1.39,1.44,1.62,1.62,0,0,0,1.56-1.5,1.33,1.33,0,0,0-1.38-1.44A1.64,1.64,0,0,0,308.79,139Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M304.1,139.12a1.31,1.31,0,0,0,1.37,1.43,1.65,1.65,0,0,0,1.58-1.51,1.31,1.31,0,0,0-1.37-1.43A1.67,1.67,0,0,0,304.1,139.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M299.41,139.24a1.29,1.29,0,0,0,1.36,1.43,1.68,1.68,0,0,0,1.59-1.51,1.29,1.29,0,0,0-1.35-1.43A1.69,1.69,0,0,0,299.41,139.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M294.73,139.37a1.27,1.27,0,0,0,1.34,1.43,1.69,1.69,0,0,0,1.6-1.51,1.28,1.28,0,0,0-1.34-1.43A1.71,1.71,0,0,0,294.73,139.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M271.36,140.2a1.19,1.19,0,0,0,1.26,1.41,1.83,1.83,0,0,0,1.67-1.53,1.2,1.2,0,0,0-1.26-1.42A1.84,1.84,0,0,0,271.36,140.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M252.75,141a1.12,1.12,0,0,0,1.2,1.4,1.9,1.9,0,0,0,1.71-1.54,1.12,1.12,0,0,0-1.19-1.4A2,2,0,0,0,252.75,141Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.77,147.49c-.3.81.09,1.41.86,1.34a2.5,2.5,0,0,0,1.92-1.59c.29-.81-.09-1.41-.86-1.34A2.53,2.53,0,0,0,161.77,147.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M152.92,148.33c-.3.81.07,1.41.84,1.33a2.57,2.57,0,0,0,1.93-1.6c.31-.81-.06-1.41-.83-1.33A2.62,2.62,0,0,0,152.92,148.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M148.53,148.76c-.32.81.05,1.41.81,1.33a2.61,2.61,0,0,0,1.95-1.6c.31-.81-.06-1.41-.82-1.33A2.62,2.62,0,0,0,148.53,148.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M144.14,149.2c-.32.81,0,1.4.8,1.33a2.67,2.67,0,0,0,1.95-1.61c.32-.81,0-1.4-.79-1.33A2.7,2.7,0,0,0,144.14,149.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M139.77,149.64c-.33.81,0,1.41.78,1.33a2.7,2.7,0,0,0,2-1.61c.33-.81,0-1.4-.78-1.32A2.7,2.7,0,0,0,139.77,149.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M135.42,150.1c-.34.81,0,1.4.76,1.32a2.73,2.73,0,0,0,2-1.61c.33-.81,0-1.4-.76-1.32A2.75,2.75,0,0,0,135.42,150.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M131.08,150.56c-.34.81,0,1.4.75,1.32a2.75,2.75,0,0,0,2-1.61c.34-.81,0-1.41-.74-1.33A2.8,2.8,0,0,0,131.08,150.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M126.76,151c-.35.81,0,1.4.73,1.32a2.78,2.78,0,0,0,2-1.61c.35-.81,0-1.4-.73-1.32A2.82,2.82,0,0,0,126.76,151Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M122.46,151.5c-.36.81,0,1.4.71,1.31a2.83,2.83,0,0,0,2-1.61c.35-.81,0-1.4-.71-1.32A2.85,2.85,0,0,0,122.46,151.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.17,152c-.37.81,0,1.39.69,1.31a2.85,2.85,0,0,0,2-1.61c.36-.81,0-1.4-.69-1.32A2.91,2.91,0,0,0,118.17,152Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M113.9,152.47c-.37.81-.07,1.39.67,1.31a2.92,2.92,0,0,0,2-1.62c.36-.81.06-1.4-.68-1.31A2.92,2.92,0,0,0,113.9,152.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M657.9,155.85a3.75,3.75,0,0,0,2.1,1.63c.65.1.74-.48.22-1.28a3.85,3.85,0,0,0-2.11-1.63C657.47,154.48,657.38,155.05,657.9,155.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M650.44,154.77a3.78,3.78,0,0,0,2.1,1.63c.65.09.76-.49.25-1.29a3.69,3.69,0,0,0-2.1-1.63C650,153.39,649.93,154,650.44,154.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M595.62,147.64a3.18,3.18,0,0,0,2,1.62c.7.08.93-.51.5-1.32a3.19,3.19,0,0,0-2.06-1.62C595.41,146.24,595.19,146.83,595.62,147.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M591.54,147.17a3.17,3.17,0,0,0,2,1.62c.71.08.95-.51.52-1.32a3.15,3.15,0,0,0-2.05-1.62C591.35,145.77,591.12,146.36,591.54,147.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.44,146.71a3.12,3.12,0,0,0,2,1.61c.72.08,1-.51.54-1.32a3.16,3.16,0,0,0-2-1.62C587.27,145.3,587,145.9,587.44,146.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M570.86,144.91a3,3,0,0,0,2,1.61c.73.08,1-.52.61-1.33a3,3,0,0,0-2-1.61C570.75,143.5,570.48,144.1,570.86,144.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.46,142.43a2.75,2.75,0,0,0,2,1.59c.74.07,1.06-.53.71-1.34a2.76,2.76,0,0,0-2-1.6C545.43,141,545.12,141.61,545.46,142.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.17,142a2.72,2.72,0,0,0,2,1.59c.74.07,1.07-.54.73-1.35a2.73,2.73,0,0,0-2-1.59C541.16,140.62,540.83,141.23,541.17,142Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.86,141.66a2.68,2.68,0,0,0,2,1.59c.75.06,1.09-.54.75-1.35a2.71,2.71,0,0,0-2-1.6C536.86,140.24,536.53,140.85,536.86,141.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M532.54,141.29a2.62,2.62,0,0,0,2,1.58c.75.07,1.1-.54.77-1.35a2.66,2.66,0,0,0-2-1.59C532.56,139.86,532.21,140.47,532.54,141.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M528.2,140.92a2.64,2.64,0,0,0,1.94,1.59c.76.06,1.11-.55.79-1.36a2.65,2.65,0,0,0-1.95-1.59C528.23,139.5,527.88,140.11,528.2,140.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M488.54,138a2.3,2.3,0,0,0,1.86,1.56c.78.05,1.2-.57.94-1.38a2.33,2.33,0,0,0-1.86-1.56C488.71,136.58,488.29,137.2,488.54,138Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M484.07,137.73a2.27,2.27,0,0,0,1.85,1.56c.78.05,1.21-.57,1-1.39a2.3,2.3,0,0,0-1.85-1.56C484.25,136.29,483.83,136.92,484.07,137.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M479.59,137.46a2.25,2.25,0,0,0,1.84,1.56c.78,0,1.22-.58,1-1.39a2.25,2.25,0,0,0-1.84-1.56C479.79,136,479.35,136.65,479.59,137.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M420.47,134.8a1.87,1.87,0,0,0,1.7,1.52,1.15,1.15,0,0,0,1.19-1.43,1.89,1.89,0,0,0-1.69-1.52A1.17,1.17,0,0,0,420.47,134.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.87,134.67a1.86,1.86,0,0,0,1.68,1.52,1.17,1.17,0,0,0,1.21-1.44,1.86,1.86,0,0,0-1.68-1.52A1.18,1.18,0,0,0,415.87,134.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.26,134.54a1.82,1.82,0,0,0,1.67,1.52,1.19,1.19,0,0,0,1.23-1.44,1.83,1.83,0,0,0-1.67-1.51A1.19,1.19,0,0,0,411.26,134.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.65,134.43a1.79,1.79,0,0,0,1.66,1.51,1.2,1.2,0,0,0,1.24-1.44,1.8,1.8,0,0,0-1.66-1.51A1.2,1.2,0,0,0,406.65,134.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402,134.32a1.77,1.77,0,0,0,1.65,1.51,1.22,1.22,0,0,0,1.25-1.44,1.77,1.77,0,0,0-1.64-1.51A1.23,1.23,0,0,0,402,134.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397.41,134.23a1.74,1.74,0,0,0,1.63,1.5,1.22,1.22,0,0,0,1.27-1.44,1.75,1.75,0,0,0-1.63-1.51A1.24,1.24,0,0,0,397.41,134.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.78,134.14a1.7,1.7,0,0,0,1.62,1.5,1.25,1.25,0,0,0,1.29-1.44,1.74,1.74,0,0,0-1.62-1.51A1.26,1.26,0,0,0,392.78,134.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.15,134.07a1.7,1.7,0,0,0,1.6,1.5,1.28,1.28,0,0,0,1.31-1.46,1.7,1.7,0,0,0-1.61-1.49A1.27,1.27,0,0,0,388.15,134.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.51,134a1.67,1.67,0,0,0,1.6,1.49,1.3,1.3,0,0,0,1.32-1.46,1.68,1.68,0,0,0-1.6-1.49A1.3,1.3,0,0,0,383.51,134Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.87,134a1.64,1.64,0,0,0,1.58,1.49,1.31,1.31,0,0,0,1.34-1.46,1.65,1.65,0,0,0-1.58-1.49A1.32,1.32,0,0,0,378.87,134Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M374.23,133.91a1.63,1.63,0,0,0,1.57,1.49,1.34,1.34,0,0,0,1.35-1.47,1.62,1.62,0,0,0-1.57-1.48A1.33,1.33,0,0,0,374.23,133.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M369.59,133.88a1.58,1.58,0,0,0,1.55,1.48,1.33,1.33,0,0,0,1.36-1.46,1.6,1.6,0,0,0-1.55-1.49A1.35,1.35,0,0,0,369.59,133.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364.94,133.85a1.56,1.56,0,0,0,1.54,1.48,1.37,1.37,0,0,0,1.38-1.46,1.59,1.59,0,0,0-1.54-1.49A1.38,1.38,0,0,0,364.94,133.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.29,133.84a1.54,1.54,0,0,0,1.52,1.48,1.39,1.39,0,0,0,1.4-1.47,1.55,1.55,0,0,0-1.53-1.48A1.39,1.39,0,0,0,360.29,133.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.64,133.84a1.52,1.52,0,0,0,1.51,1.47,1.4,1.4,0,0,0,1.41-1.47,1.54,1.54,0,0,0-1.51-1.48A1.41,1.41,0,0,0,355.64,133.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351,133.85a1.51,1.51,0,0,0,1.5,1.47,1.43,1.43,0,0,0,1.43-1.48,1.53,1.53,0,0,0-1.5-1.47A1.43,1.43,0,0,0,351,133.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.33,133.87a1.49,1.49,0,0,0,1.48,1.47,1.45,1.45,0,0,0,1.44-1.48,1.48,1.48,0,0,0-1.48-1.47A1.45,1.45,0,0,0,346.33,133.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,133.9a1.46,1.46,0,0,0,1.47,1.46,1.48,1.48,0,0,0,0-3A1.49,1.49,0,0,0,341.67,133.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337,133.94a1.43,1.43,0,0,0,1.45,1.46,1.49,1.49,0,0,0,1.47-1.49,1.45,1.45,0,0,0-1.45-1.46A1.5,1.5,0,0,0,337,133.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.36,134a1.43,1.43,0,0,0,1.44,1.46,1.52,1.52,0,0,0,1.49-1.49,1.43,1.43,0,0,0-1.44-1.46A1.52,1.52,0,0,0,332.36,134Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M327.71,134.05a1.4,1.4,0,0,0,1.42,1.46,1.54,1.54,0,0,0,1.5-1.5,1.4,1.4,0,0,0-1.42-1.45A1.54,1.54,0,0,0,327.71,134.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M323.05,134.12a1.37,1.37,0,0,0,1.41,1.45,1.56,1.56,0,0,0,1.52-1.49,1.4,1.4,0,0,0-1.41-1.46A1.58,1.58,0,0,0,323.05,134.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M318.4,134.2a1.36,1.36,0,0,0,1.4,1.45,1.56,1.56,0,0,0,1.52-1.5,1.37,1.37,0,0,0-1.39-1.45A1.6,1.6,0,0,0,318.4,134.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M313.75,134.3a1.33,1.33,0,0,0,1.38,1.44,1.6,1.6,0,0,0,1.54-1.5,1.35,1.35,0,0,0-1.38-1.45A1.62,1.62,0,0,0,313.75,134.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M309.1,134.4a1.33,1.33,0,0,0,1.37,1.44,1.62,1.62,0,0,0,1.55-1.51,1.32,1.32,0,0,0-1.36-1.44A1.64,1.64,0,0,0,309.1,134.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M304.45,134.51A1.31,1.31,0,0,0,305.8,136a1.64,1.64,0,0,0,1.57-1.51A1.31,1.31,0,0,0,306,133,1.67,1.67,0,0,0,304.45,134.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M299.81,134.64a1.28,1.28,0,0,0,1.33,1.43,1.68,1.68,0,0,0,1.59-1.51,1.3,1.3,0,0,0-1.34-1.44A1.68,1.68,0,0,0,299.81,134.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M295.17,134.77a1.26,1.26,0,0,0,1.32,1.43,1.68,1.68,0,0,0,1.59-1.51,1.27,1.27,0,0,0-1.32-1.44A1.72,1.72,0,0,0,295.17,134.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M262.79,136A1.14,1.14,0,0,0,264,137.4a1.86,1.86,0,0,0,1.68-1.54,1.15,1.15,0,0,0-1.21-1.41A1.9,1.9,0,0,0,262.79,136Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M258.18,136.21a1.13,1.13,0,0,0,1.2,1.4,1.9,1.9,0,0,0,1.7-1.54,1.14,1.14,0,0,0-1.2-1.4A1.93,1.93,0,0,0,258.18,136.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190,140.59c-.26.81.17,1.42,1,1.36a2.35,2.35,0,0,0,1.86-1.59c.25-.81-.17-1.42-.95-1.36A2.36,2.36,0,0,0,190,140.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.12,141.33c-.27.81.14,1.41.92,1.35a2.44,2.44,0,0,0,1.88-1.59c.26-.81-.14-1.42-.91-1.35A2.42,2.42,0,0,0,181.12,141.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176.68,141.71c-.27.81.13,1.42.9,1.35a2.45,2.45,0,0,0,1.89-1.59c.27-.81-.13-1.42-.89-1.35A2.46,2.46,0,0,0,176.68,141.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.26,142.1c-.29.81.11,1.41.88,1.35a2.48,2.48,0,0,0,1.9-1.6c.28-.81-.12-1.41-.88-1.35A2.5,2.5,0,0,0,172.26,142.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M163.44,142.91c-.3.81.08,1.41.85,1.34a2.54,2.54,0,0,0,1.91-1.6c.3-.81-.08-1.41-.84-1.34A2.55,2.55,0,0,0,163.44,142.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M150.32,144.18c-.32.81,0,1.4.79,1.33a2.66,2.66,0,0,0,1.95-1.61c.31-.81,0-1.41-.8-1.33A2.66,2.66,0,0,0,150.32,144.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M146,144.61c-.33.82,0,1.41.77,1.33a2.65,2.65,0,0,0,1.95-1.6c.32-.81,0-1.41-.77-1.33A2.67,2.67,0,0,0,146,144.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M128.75,146.44c-.36.82,0,1.41.71,1.32a2.82,2.82,0,0,0,2-1.61c.35-.81,0-1.4-.71-1.32A2.82,2.82,0,0,0,128.75,146.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M124.48,146.92c-.36.81,0,1.4.69,1.32a2.89,2.89,0,0,0,2-1.62c.36-.81,0-1.4-.69-1.32A2.93,2.93,0,0,0,124.48,146.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M120.23,147.4c-.36.81-.06,1.4.67,1.32a2.91,2.91,0,0,0,2-1.62c.36-.81.07-1.4-.67-1.32A3,3,0,0,0,120.23,147.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M116,147.89c-.37.81-.08,1.4.66,1.31a2.92,2.92,0,0,0,2-1.62c.37-.81.08-1.39-.65-1.31A3,3,0,0,0,116,147.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M665.86,153a4,4,0,0,0,2.1,1.63c.63.09.69-.48.15-1.28a4,4,0,0,0-2.1-1.63C665.39,151.59,665.32,152.17,665.86,153Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M662.25,152.41a3.87,3.87,0,0,0,2.1,1.63c.63.1.7-.48.17-1.28a4,4,0,0,0-2.11-1.63C661.79,151,661.71,151.61,662.25,152.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M658.61,151.86a3.91,3.91,0,0,0,2.1,1.63c.64.09.72-.48.19-1.28a3.84,3.84,0,0,0-2.11-1.63C658.16,150.48,658.08,151.05,658.61,151.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M655,151.31a3.85,3.85,0,0,0,2.1,1.63c.64.09.73-.48.2-1.29a3.91,3.91,0,0,0-2.1-1.63C654.52,149.93,654.42,150.5,655,151.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M651.26,150.76a3.85,3.85,0,0,0,2.1,1.63c.64.09.74-.48.22-1.29a3.85,3.85,0,0,0-2.1-1.63C650.84,149.38,650.74,150,651.26,150.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M647.55,150.22a3.71,3.71,0,0,0,2.1,1.62c.64.1.75-.48.23-1.28a3.69,3.69,0,0,0-2.1-1.63C647.14,148.83,647,149.41,647.55,150.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M643.81,149.68a3.71,3.71,0,0,0,2.1,1.62c.65.1.76-.48.25-1.29a3.79,3.79,0,0,0-2.09-1.63C643.42,148.29,643.3,148.87,643.81,149.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M628.63,147.55a3.56,3.56,0,0,0,2.09,1.63c.67.09.81-.49.32-1.3a3.6,3.6,0,0,0-2.09-1.63C628.29,146.16,628.15,146.75,628.63,147.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M624.78,147a3.51,3.51,0,0,0,2.08,1.63c.68.09.83-.49.34-1.3a3.51,3.51,0,0,0-2.08-1.63C624.45,145.64,624.3,146.23,624.78,147Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M617,146a3.44,3.44,0,0,0,2.08,1.62c.67.09.84-.49.37-1.3a3.45,3.45,0,0,0-2.08-1.63C616.71,144.61,616.54,145.2,617,146Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.09,145.5a3.41,3.41,0,0,0,2.07,1.63c.69.08.86-.5.4-1.31a3.4,3.4,0,0,0-2.08-1.62C612.81,144.11,612.63,144.69,613.09,145.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M593.19,143.06a3.23,3.23,0,0,0,2,1.62c.7.08.91-.51.48-1.32a3.25,3.25,0,0,0-2.05-1.62C593,141.66,592.76,142.25,593.19,143.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M589.15,142.59a3.14,3.14,0,0,0,2,1.61c.7.08.93-.51.5-1.32a3.23,3.23,0,0,0-2.05-1.62C589,141.18,588.72,141.77,589.15,142.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M576.91,141.21a3.06,3.06,0,0,0,2,1.61c.71.08,1-.52.55-1.33a3.08,3.08,0,0,0-2-1.61C576.75,139.8,576.5,140.4,576.91,141.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M568.66,140.32a3,3,0,0,0,2,1.61c.72.08,1-.52.59-1.33a3,3,0,0,0-2-1.61C568.53,138.91,568.27,139.51,568.66,140.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.24,137.44a2.73,2.73,0,0,0,2,1.6c.74.06,1.06-.54.71-1.36a2.75,2.75,0,0,0-2-1.59C539.21,136,538.89,136.63,539.24,137.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535,137.06a2.68,2.68,0,0,0,1.95,1.59c.75.07,1.07-.54.73-1.35a2.71,2.71,0,0,0-2-1.6C535,135.64,534.63,136.25,535,137.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530.69,136.69a2.67,2.67,0,0,0,1.94,1.59c.75.06,1.08-.55.75-1.36a2.68,2.68,0,0,0-2-1.59C530.69,135.26,530.36,135.87,530.69,136.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M526.39,136.32a2.64,2.64,0,0,0,1.94,1.59c.75.06,1.09-.55.76-1.36a2.63,2.63,0,0,0-1.94-1.59C526.41,134.9,526.07,135.51,526.39,136.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M522.08,136a2.56,2.56,0,0,0,1.93,1.59c.75.06,1.1-.55.78-1.36a2.6,2.6,0,0,0-1.93-1.59C522.11,134.54,521.76,135.15,522.08,136Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M517.75,135.61a2.55,2.55,0,0,0,1.92,1.59c.76.06,1.11-.55.8-1.37a2.57,2.57,0,0,0-1.92-1.58C517.8,134.19,517.44,134.8,517.75,135.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.1,133.41A2.33,2.33,0,0,0,489,135c.77.05,1.18-.57.92-1.38A2.34,2.34,0,0,0,488,132C487.26,132,486.85,132.59,487.1,133.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M482.68,133.13a2.27,2.27,0,0,0,1.84,1.56c.77.05,1.19-.58.94-1.39a2.31,2.31,0,0,0-1.85-1.56C482.85,131.69,482.43,132.31,482.68,133.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.33,130.66a1.94,1.94,0,0,0,1.72,1.53,1.09,1.09,0,0,0,1.12-1.42,2,2,0,0,0-1.72-1.53A1.1,1.1,0,0,0,433.33,130.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.79,130.5A1.92,1.92,0,0,0,430.5,132a1.11,1.11,0,0,0,1.14-1.42,1.94,1.94,0,0,0-1.71-1.53A1.12,1.12,0,0,0,428.79,130.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M424.25,130.34a1.89,1.89,0,0,0,1.69,1.53,1.13,1.13,0,0,0,1.16-1.43,1.91,1.91,0,0,0-1.7-1.53A1.13,1.13,0,0,0,424.25,130.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419.7,130.2a1.88,1.88,0,0,0,1.68,1.52,1.15,1.15,0,0,0,1.18-1.43,1.88,1.88,0,0,0-1.69-1.52A1.14,1.14,0,0,0,419.7,130.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.14,130.06a1.83,1.83,0,0,0,1.67,1.52,1.16,1.16,0,0,0,1.19-1.44,1.84,1.84,0,0,0-1.67-1.51A1.16,1.16,0,0,0,415.14,130.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M410.58,129.94a1.81,1.81,0,0,0,1.66,1.51,1.17,1.17,0,0,0,1.2-1.44,1.82,1.82,0,0,0-1.66-1.51A1.18,1.18,0,0,0,410.58,129.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406,129.82a1.78,1.78,0,0,0,1.65,1.51,1.19,1.19,0,0,0,1.22-1.44,1.8,1.8,0,0,0-1.65-1.51A1.2,1.2,0,0,0,406,129.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.44,129.72a1.75,1.75,0,0,0,1.63,1.5,1.21,1.21,0,0,0,1.24-1.44,1.77,1.77,0,0,0-1.63-1.51A1.22,1.22,0,0,0,401.44,129.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.86,129.62a1.72,1.72,0,0,0,1.62,1.5,1.22,1.22,0,0,0,1.25-1.44,1.75,1.75,0,0,0-1.62-1.51A1.24,1.24,0,0,0,396.86,129.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.28,129.54a1.72,1.72,0,0,0,1.6,1.5,1.25,1.25,0,0,0,1.27-1.45,1.7,1.7,0,0,0-1.6-1.5A1.24,1.24,0,0,0,392.28,129.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.69,129.46a1.67,1.67,0,0,0,1.59,1.5,1.26,1.26,0,0,0,1.29-1.45A1.69,1.69,0,0,0,389,128,1.27,1.27,0,0,0,387.69,129.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.1,129.4a1.65,1.65,0,0,0,1.58,1.49,1.28,1.28,0,0,0,1.3-1.45,1.67,1.67,0,0,0-1.58-1.5A1.29,1.29,0,0,0,383.1,129.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.5,129.34a1.65,1.65,0,0,0,1.57,1.5,1.31,1.31,0,0,0,1.32-1.46,1.65,1.65,0,0,0-1.57-1.5A1.32,1.32,0,0,0,378.5,129.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.91,129.3a1.6,1.6,0,0,0,1.55,1.49,1.31,1.31,0,0,0,1.33-1.46,1.6,1.6,0,0,0-1.55-1.49A1.32,1.32,0,0,0,373.91,129.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M369.31,129.27a1.58,1.58,0,0,0,1.54,1.48,1.34,1.34,0,0,0,1.35-1.46,1.61,1.61,0,0,0-1.54-1.49A1.35,1.35,0,0,0,369.31,129.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364.71,129.25a1.57,1.57,0,0,0,1.52,1.48,1.37,1.37,0,0,0,1.37-1.47,1.57,1.57,0,0,0-1.53-1.48A1.35,1.35,0,0,0,364.71,129.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.1,129.23a1.54,1.54,0,0,0,1.51,1.48,1.37,1.37,0,0,0,1.38-1.47,1.55,1.55,0,0,0-1.51-1.48A1.39,1.39,0,0,0,360.1,129.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.49,129.23a1.53,1.53,0,0,0,1.5,1.48,1.4,1.4,0,0,0,1.4-1.48,1.53,1.53,0,0,0-1.5-1.47A1.41,1.41,0,0,0,355.49,129.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.89,129.24a1.48,1.48,0,0,0,1.48,1.47,1.42,1.42,0,0,0,1.41-1.48,1.51,1.51,0,0,0-1.48-1.47A1.42,1.42,0,0,0,350.89,129.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.28,129.26a1.47,1.47,0,0,0,1.47,1.47,1.43,1.43,0,0,0,1.42-1.48,1.47,1.47,0,0,0-1.46-1.47A1.45,1.45,0,0,0,346.28,129.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,129.29a1.45,1.45,0,1,0,1.44-1.49A1.48,1.48,0,0,0,341.67,129.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.06,129.33a1.43,1.43,0,0,0,1.44,1.46A1.49,1.49,0,0,0,340,129.3a1.44,1.44,0,0,0-1.44-1.46A1.5,1.5,0,0,0,337.06,129.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.45,129.38a1.4,1.4,0,0,0,1.42,1.46,1.51,1.51,0,0,0,1.48-1.49,1.42,1.42,0,0,0-1.43-1.46A1.52,1.52,0,0,0,332.45,129.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M327.84,129.44a1.4,1.4,0,0,0,1.41,1.46,1.54,1.54,0,0,0,1.49-1.5,1.41,1.41,0,0,0-1.41-1.45A1.54,1.54,0,0,0,327.84,129.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M323.23,129.52a1.38,1.38,0,0,0,1.4,1.45,1.57,1.57,0,0,0,1.5-1.5,1.37,1.37,0,0,0-1.4-1.45A1.57,1.57,0,0,0,323.23,129.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M318.63,129.6a1.35,1.35,0,0,0,1.38,1.45,1.58,1.58,0,0,0,1.51-1.51,1.35,1.35,0,0,0-1.38-1.44A1.58,1.58,0,0,0,318.63,129.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M314,129.69a1.33,1.33,0,0,0,1.36,1.45,1.6,1.6,0,0,0,1.53-1.51,1.34,1.34,0,0,0-1.36-1.45A1.62,1.62,0,0,0,314,129.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M309.42,129.79a1.31,1.31,0,0,0,1.34,1.45,1.63,1.63,0,0,0,1.55-1.51,1.32,1.32,0,0,0-1.35-1.45A1.63,1.63,0,0,0,309.42,129.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M304.81,129.91a1.3,1.3,0,0,0,1.34,1.44,1.64,1.64,0,0,0,1.55-1.52,1.3,1.3,0,0,0-1.33-1.44A1.67,1.67,0,0,0,304.81,129.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M300.22,130a1.27,1.27,0,0,0,1.31,1.44A1.67,1.67,0,0,0,303.1,130a1.27,1.27,0,0,0-1.31-1.43A1.67,1.67,0,0,0,300.22,130Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M295.62,130.16a1.26,1.26,0,0,0,1.3,1.44,1.72,1.72,0,0,0,1.59-1.52,1.26,1.26,0,0,0-1.31-1.43A1.7,1.7,0,0,0,295.62,130.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200.38,135.29c-.25.81.18,1.42,1,1.36a2.29,2.29,0,0,0,1.83-1.58c.24-.81-.18-1.42-.95-1.37A2.31,2.31,0,0,0,200.38,135.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.93,135.63c-.25.82.17,1.43.95,1.37a2.31,2.31,0,0,0,1.84-1.59c.25-.81-.17-1.42-.94-1.36A2.36,2.36,0,0,0,195.93,135.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.67,136.73c-.28.81.12,1.42.89,1.35a2.43,2.43,0,0,0,1.88-1.59c.27-.81-.13-1.42-.89-1.35A2.42,2.42,0,0,0,182.67,136.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178.27,137.11c-.29.82.11,1.42.87,1.35a2.43,2.43,0,0,0,1.89-1.59c.28-.81-.11-1.42-.87-1.35A2.49,2.49,0,0,0,178.27,137.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.51,137.91c-.3.81.08,1.41.84,1.34a2.51,2.51,0,0,0,1.9-1.6c.29-.81-.08-1.41-.83-1.34A2.52,2.52,0,0,0,169.51,137.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M160.8,138.73c-.31.81,0,1.41.8,1.34a2.6,2.6,0,0,0,1.93-1.6c.3-.82-.05-1.42-.81-1.34A2.6,2.6,0,0,0,160.8,138.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M143.55,140.47c-.34.81,0,1.41.74,1.33a2.74,2.74,0,0,0,2-1.61c.34-.81,0-1.41-.73-1.33A2.77,2.77,0,0,0,143.55,140.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M130.77,141.86c-.36.81-.05,1.4.69,1.32a2.85,2.85,0,0,0,2-1.62c.35-.81.05-1.4-.69-1.32A2.87,2.87,0,0,0,130.77,141.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M126.54,142.34c-.36.81-.06,1.4.67,1.31a2.87,2.87,0,0,0,2-1.61c.36-.81.06-1.41-.67-1.32A2.91,2.91,0,0,0,126.54,142.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M122.33,142.82c-.37.81-.08,1.4.65,1.32a3,3,0,0,0,2-1.62c.37-.82.08-1.41-.65-1.32A2.94,2.94,0,0,0,122.33,142.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.14,143.31c-.38.81-.1,1.4.63,1.31a2.94,2.94,0,0,0,2-1.62c.38-.81.1-1.4-.63-1.31A3,3,0,0,0,118.14,143.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M105.66,144.82c-.4.81-.14,1.4.58,1.31a3.08,3.08,0,0,0,2-1.63c.39-.81.14-1.39-.58-1.31A3.11,3.11,0,0,0,105.66,144.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M659.23,147.87a3.91,3.91,0,0,0,2.1,1.63c.62.1.69-.48.15-1.28a4,4,0,0,0-2.1-1.63C658.76,146.49,658.69,147.06,659.23,147.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M655.62,147.31a3.82,3.82,0,0,0,2.1,1.63c.63.1.7-.48.17-1.28a4,4,0,0,0-2.1-1.63C655.16,145.93,655.09,146.51,655.62,147.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M652,146.76a3.91,3.91,0,0,0,2.1,1.63c.63.09.72-.48.19-1.29a3.91,3.91,0,0,0-2.1-1.63C651.54,145.38,651.46,146,652,146.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M648.32,146.21a3.85,3.85,0,0,0,2.1,1.63c.64.09.73-.48.2-1.29a3.86,3.86,0,0,0-2.09-1.63C647.9,144.83,647.8,145.4,648.32,146.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M644.64,145.66a3.72,3.72,0,0,0,2.1,1.63c.64.09.74-.48.22-1.29a3.85,3.85,0,0,0-2.1-1.63C644.23,144.28,644.13,144.85,644.64,145.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M640.93,145.12a3.78,3.78,0,0,0,2.1,1.63c.64.09.75-.49.24-1.29a3.75,3.75,0,0,0-2.1-1.63C640.53,143.73,640.42,144.31,640.93,145.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M637.2,144.58a3.78,3.78,0,0,0,2.1,1.63c.65.09.76-.49.25-1.29a3.67,3.67,0,0,0-2.09-1.63C636.81,143.19,636.7,143.77,637.2,144.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M633.45,144a3.64,3.64,0,0,0,2.09,1.63c.65.09.77-.49.27-1.29a3.64,3.64,0,0,0-2.09-1.63C633.07,142.65,633,143.23,633.45,144Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M629.68,143.51a3.57,3.57,0,0,0,2.08,1.63c.66.09.79-.49.29-1.3a3.7,3.7,0,0,0-2.09-1.63C629.31,142.12,629.18,142.7,629.68,143.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M614.35,141.43a3.45,3.45,0,0,0,2.08,1.63c.67.09.83-.5.35-1.31a3.45,3.45,0,0,0-2.07-1.62C614,140,613.88,140.62,614.35,141.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M586.73,138a3.22,3.22,0,0,0,2,1.62c.7.08.91-.51.48-1.32a3.2,3.2,0,0,0-2-1.62C586.51,136.6,586.3,137.19,586.73,138Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.86,134a2.87,2.87,0,0,0,2,1.61c.72.07,1-.54.63-1.35a2.86,2.86,0,0,0-2-1.6C549.78,132.62,549.49,133.22,549.86,134Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.68,133.63a2.84,2.84,0,0,0,2,1.6c.73.07,1-.53.65-1.35a2.84,2.84,0,0,0-2-1.6C545.61,132.21,545.32,132.82,545.68,133.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537.28,132.84a2.74,2.74,0,0,0,2,1.6c.74.06,1-.54.69-1.36a2.75,2.75,0,0,0-2-1.59C537.23,131.42,536.93,132,537.28,132.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.05,132.46a2.68,2.68,0,0,0,2,1.59c.74.07,1-.54.7-1.35a2.71,2.71,0,0,0-2-1.6C533,131,532.71,131.64,533.05,132.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M528.81,132.08a2.63,2.63,0,0,0,1.94,1.59c.74.07,1.06-.54.72-1.35a2.66,2.66,0,0,0-1.94-1.59C528.79,130.66,528.47,131.27,528.81,132.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M524.55,131.72a2.66,2.66,0,0,0,1.93,1.59c.74.06,1.08-.55.74-1.36a2.63,2.63,0,0,0-1.93-1.59C524.55,130.29,524.22,130.9,524.55,131.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520.28,131.36A2.63,2.63,0,0,0,522.2,133c.75.06,1.09-.55.76-1.37A2.61,2.61,0,0,0,521,130C520.3,129.94,520,130.55,520.28,131.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M516,131a2.57,2.57,0,0,0,1.92,1.58c.75.06,1.09-.55.78-1.36a2.58,2.58,0,0,0-1.92-1.59C516,129.58,515.68,130.2,516,131Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M494.37,129.39a2.43,2.43,0,0,0,1.87,1.57c.76,0,1.15-.57.87-1.38a2.39,2.39,0,0,0-1.87-1.57C494.49,128,494.1,128.57,494.37,129.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M490,129.09a2.39,2.39,0,0,0,1.86,1.57c.76.05,1.16-.57.88-1.39a2.36,2.36,0,0,0-1.86-1.56C490.14,127.65,489.75,128.27,490,129.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.64,128.8a2.33,2.33,0,0,0,1.85,1.56c.76,0,1.17-.57.9-1.38a2.35,2.35,0,0,0-1.85-1.57C485.78,127.36,485.38,128,485.64,128.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.26,128.52a2.29,2.29,0,0,0,1.84,1.56c.76,0,1.17-.57.91-1.39a2.3,2.3,0,0,0-1.84-1.56C481.42,127.08,481,127.7,481.26,128.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.86,128.25a2.29,2.29,0,0,0,1.83,1.56c.77,0,1.19-.58.94-1.39a2.26,2.26,0,0,0-1.83-1.56C477,126.81,476.62,127.43,476.86,128.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M441.36,126.42A2,2,0,0,0,443.1,128a1.05,1.05,0,0,0,1.07-1.41,2,2,0,0,0-1.74-1.54A1.07,1.07,0,0,0,441.36,126.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M436.88,126.23a2,2,0,0,0,1.73,1.53,1.07,1.07,0,0,0,1.09-1.41,2,2,0,0,0-1.73-1.54A1.08,1.08,0,0,0,436.88,126.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M432.4,126.06a2,2,0,0,0,1.71,1.53,1.1,1.1,0,0,0,1.11-1.43,2,2,0,0,0-1.72-1.53A1.1,1.1,0,0,0,432.4,126.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427.91,125.89a1.94,1.94,0,0,0,1.7,1.53,1.11,1.11,0,0,0,1.12-1.43,1.92,1.92,0,0,0-1.7-1.52A1.1,1.1,0,0,0,427.91,125.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.41,125.73a1.9,1.9,0,0,0,1.69,1.53,1.13,1.13,0,0,0,1.14-1.43,1.91,1.91,0,0,0-1.69-1.52A1.12,1.12,0,0,0,423.41,125.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.91,125.59a1.88,1.88,0,0,0,1.67,1.52,1.14,1.14,0,0,0,1.16-1.43,1.87,1.87,0,0,0-1.68-1.52A1.13,1.13,0,0,0,418.91,125.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.4,125.45a1.82,1.82,0,0,0,1.66,1.52,1.15,1.15,0,0,0,1.17-1.43,1.84,1.84,0,0,0-1.66-1.52A1.15,1.15,0,0,0,414.4,125.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.88,125.33a1.8,1.8,0,0,0,1.65,1.51,1.17,1.17,0,0,0,1.19-1.43,1.82,1.82,0,0,0-1.65-1.52A1.17,1.17,0,0,0,409.88,125.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M405.36,125.21a1.77,1.77,0,0,0,1.63,1.51,1.18,1.18,0,0,0,1.21-1.44,1.8,1.8,0,0,0-1.64-1.51A1.19,1.19,0,0,0,405.36,125.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.83,125.11a1.75,1.75,0,0,0,1.62,1.5,1.19,1.19,0,0,0,1.22-1.44,1.77,1.77,0,0,0-1.62-1.51A1.21,1.21,0,0,0,400.83,125.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.3,125a1.74,1.74,0,0,0,1.61,1.51,1.21,1.21,0,0,0,1.23-1.45,1.73,1.73,0,0,0-1.6-1.5A1.22,1.22,0,0,0,396.3,125Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.76,124.93a1.72,1.72,0,0,0,1.6,1.5,1.24,1.24,0,0,0,1.25-1.45,1.71,1.71,0,0,0-1.59-1.5A1.24,1.24,0,0,0,391.76,124.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.22,124.85a1.67,1.67,0,0,0,1.58,1.5,1.26,1.26,0,0,0,1.27-1.45,1.68,1.68,0,0,0-1.58-1.5A1.27,1.27,0,0,0,387.22,124.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.68,124.79a1.64,1.64,0,0,0,1.56,1.49,1.26,1.26,0,0,0,1.29-1.45,1.66,1.66,0,0,0-1.57-1.5A1.28,1.28,0,0,0,382.68,124.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.13,124.74a1.63,1.63,0,0,0,1.55,1.49,1.3,1.3,0,0,0,1.31-1.46,1.64,1.64,0,0,0-1.56-1.49A1.3,1.3,0,0,0,378.13,124.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.58,124.69a1.61,1.61,0,0,0,1.54,1.49,1.32,1.32,0,0,0,1.32-1.46,1.62,1.62,0,0,0-1.54-1.49A1.32,1.32,0,0,0,373.58,124.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M369,124.66a1.58,1.58,0,0,0,1.52,1.49,1.35,1.35,0,0,0,1.34-1.47,1.61,1.61,0,0,0-1.53-1.49A1.34,1.34,0,0,0,369,124.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364.47,124.64a1.56,1.56,0,0,0,1.51,1.48,1.35,1.35,0,0,0,1.35-1.47,1.56,1.56,0,0,0-1.51-1.48A1.35,1.35,0,0,0,364.47,124.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.91,124.63a1.53,1.53,0,0,0,1.5,1.47,1.36,1.36,0,0,0,1.36-1.47,1.55,1.55,0,0,0-1.49-1.48A1.39,1.39,0,0,0,359.91,124.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.35,124.62a1.52,1.52,0,0,0,1.48,1.48,1.4,1.4,0,0,0,1.39-1.48,1.53,1.53,0,0,0-1.49-1.47A1.4,1.4,0,0,0,355.35,124.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.79,124.63a1.49,1.49,0,0,0,1.47,1.48,1.43,1.43,0,0,0,1.4-1.48,1.51,1.51,0,0,0-1.47-1.48A1.44,1.44,0,0,0,350.79,124.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.23,124.65a1.46,1.46,0,0,0,1.45,1.47,1.42,1.42,0,0,0,1.41-1.48,1.47,1.47,0,0,0-1.45-1.47A1.44,1.44,0,0,0,346.23,124.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,124.68a1.43,1.43,0,1,0,1.42-1.48A1.45,1.45,0,0,0,341.67,124.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.1,124.72a1.44,1.44,0,0,0,1.43,1.47A1.48,1.48,0,0,0,340,124.7a1.43,1.43,0,0,0-1.42-1.46A1.48,1.48,0,0,0,337.1,124.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.54,124.78a1.39,1.39,0,0,0,1.41,1.45,1.5,1.5,0,0,0,1.46-1.49,1.41,1.41,0,0,0-1.41-1.46A1.52,1.52,0,0,0,332.54,124.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M328,124.84a1.38,1.38,0,0,0,1.39,1.45,1.52,1.52,0,0,0,1.47-1.49,1.39,1.39,0,0,0-1.39-1.46A1.53,1.53,0,0,0,328,124.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M323.42,124.91a1.36,1.36,0,0,0,1.37,1.45,1.54,1.54,0,0,0,1.49-1.5,1.37,1.37,0,0,0-1.38-1.45A1.55,1.55,0,0,0,323.42,124.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M318.86,125a1.33,1.33,0,0,0,1.36,1.45,1.57,1.57,0,0,0,1.5-1.5,1.34,1.34,0,0,0-1.36-1.45A1.57,1.57,0,0,0,318.86,125Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M314.3,125.08a1.32,1.32,0,0,0,1.34,1.45,1.59,1.59,0,0,0,1.52-1.51,1.33,1.33,0,0,0-1.35-1.44A1.6,1.6,0,0,0,314.3,125.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M309.74,125.19a1.3,1.3,0,0,0,1.33,1.44,1.62,1.62,0,0,0,1.53-1.51,1.31,1.31,0,0,0-1.33-1.44A1.63,1.63,0,0,0,309.74,125.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M305.18,125.3a1.29,1.29,0,0,0,1.32,1.44,1.64,1.64,0,0,0,1.54-1.51,1.28,1.28,0,0,0-1.31-1.44A1.66,1.66,0,0,0,305.18,125.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M300.63,125.42a1.27,1.27,0,0,0,1.3,1.44,1.67,1.67,0,0,0,1.56-1.51,1.27,1.27,0,0,0-1.3-1.44A1.68,1.68,0,0,0,300.63,125.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M296.08,125.56a1.25,1.25,0,0,0,1.28,1.43,1.69,1.69,0,0,0,1.58-1.52,1.26,1.26,0,0,0-1.29-1.43A1.7,1.7,0,0,0,296.08,125.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M250.81,127.46a1.07,1.07,0,0,0,1.12,1.4,2,2,0,0,0,1.7-1.55,1.08,1.08,0,0,0-1.12-1.4A2,2,0,0,0,250.81,127.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M246.31,127.71a1.06,1.06,0,0,0,1.11,1.39,2,2,0,0,0,1.71-1.55,1.07,1.07,0,0,0-1.1-1.4A2,2,0,0,0,246.31,127.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.2,133.31c-.3.81.07,1.41.82,1.34a2.55,2.55,0,0,0,1.9-1.59c.3-.82-.07-1.42-.81-1.35A2.57,2.57,0,0,0,171.2,133.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.88,133.72c-.31.81.05,1.41.8,1.34a2.57,2.57,0,0,0,1.91-1.6c.31-.81-.05-1.42-.79-1.34A2.58,2.58,0,0,0,166.88,133.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M162.58,134.13c-.32.82,0,1.42.78,1.34a2.6,2.6,0,0,0,1.92-1.6c.31-.81,0-1.41-.78-1.34A2.6,2.6,0,0,0,162.58,134.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M132.83,137.27c-.37.81-.07,1.4.66,1.32a2.84,2.84,0,0,0,2-1.61c.37-.82.07-1.41-.66-1.33A2.88,2.88,0,0,0,132.83,137.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M128.64,137.75c-.38.81-.09,1.4.64,1.32a2.93,2.93,0,0,0,2-1.62c.36-.81.08-1.4-.65-1.32A2.91,2.91,0,0,0,128.64,137.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M124.46,138.23c-.38.82-.1,1.41.63,1.32a2.91,2.91,0,0,0,2-1.62c.38-.81.1-1.4-.62-1.32A3,3,0,0,0,124.46,138.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M120.31,138.73c-.39.81-.12,1.4.61,1.31a3,3,0,0,0,2-1.62c.39-.81.11-1.4-.6-1.32A3,3,0,0,0,120.31,138.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M116.17,139.22c-.4.82-.13,1.4.59,1.32a3,3,0,0,0,2-1.63c.39-.81.13-1.4-.59-1.31A3,3,0,0,0,116.17,139.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M112,139.73c-.4.81-.14,1.4.58,1.31a3,3,0,0,0,2-1.63c.4-.81.14-1.4-.57-1.31A3.08,3.08,0,0,0,112,139.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M656.2,143.32a3.91,3.91,0,0,0,2.09,1.64c.62.09.68-.48.14-1.28a3.94,3.94,0,0,0-2.09-1.64C655.72,142,655.66,142.52,656.2,143.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M649,142.21a3.91,3.91,0,0,0,2.1,1.63c.63.09.7-.48.17-1.28a3.87,3.87,0,0,0-2.09-1.64C648.56,140.83,648.48,141.4,649,142.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M645.37,141.65a3.79,3.79,0,0,0,2.1,1.63c.63.1.71-.48.19-1.28a3.82,3.82,0,0,0-2.1-1.63C644.94,140.27,644.85,140.85,645.37,141.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M641.72,141.1a3.74,3.74,0,0,0,2.09,1.63c.64.1.73-.48.21-1.28a3.8,3.8,0,0,0-2.09-1.63C641.29,139.72,641.2,140.3,641.72,141.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M638,140.56a3.79,3.79,0,0,0,2.09,1.63c.64.09.74-.48.22-1.29a3.71,3.71,0,0,0-2.09-1.63C637.63,139.17,637.53,139.75,638,140.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M634.34,140a3.74,3.74,0,0,0,2.09,1.64c.64.09.75-.49.24-1.3a3.83,3.83,0,0,0-2.09-1.63C633.94,138.63,633.83,139.21,634.34,140Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M630.62,139.48a3.71,3.71,0,0,0,2.08,1.63c.65.09.76-.49.26-1.3a3.76,3.76,0,0,0-2.09-1.63C630.23,138.09,630.11,138.67,630.62,139.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M626.87,138.94a3.63,3.63,0,0,0,2.08,1.63c.66.1.78-.48.28-1.29a3.67,3.67,0,0,0-2.09-1.63C626.5,137.55,626.37,138.14,626.87,138.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M623.1,138.41a3.6,3.6,0,0,0,2.08,1.63c.66.09.79-.49.29-1.29a3.63,3.63,0,0,0-2.08-1.63C622.74,137,622.61,137.61,623.1,138.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M619.31,137.89a3.57,3.57,0,0,0,2.08,1.63c.66.09.8-.49.31-1.3a3.63,3.63,0,0,0-2.08-1.63C619,136.5,618.83,137.08,619.31,137.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M615.5,137.37a3.51,3.51,0,0,0,2.08,1.63c.66.09.8-.49.32-1.3a3.58,3.58,0,0,0-2.07-1.63C615.17,136,615,136.56,615.5,137.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M607.82,136.35a3.43,3.43,0,0,0,2.06,1.62c.68.09.84-.49.36-1.3a3.46,3.46,0,0,0-2.07-1.63C607.51,135,607.35,135.54,607.82,136.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M576.29,132.48a3.13,3.13,0,0,0,2,1.61c.7.08.92-.51.49-1.32a3.17,3.17,0,0,0-2-1.62C576.09,131.07,575.87,131.67,576.29,132.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M572.27,132a3.08,3.08,0,0,0,2,1.62c.7.08.93-.52.51-1.33a3.1,3.1,0,0,0-2-1.61C572.08,130.62,571.85,131.21,572.27,132Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M568.22,131.57a3.05,3.05,0,0,0,2,1.62c.7.07.94-.52.53-1.33a3.1,3.1,0,0,0-2-1.62C568.05,130.17,567.82,130.76,568.22,131.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.16,131.13a3,3,0,0,0,2,1.61c.71.08,1-.52.55-1.33a3.07,3.07,0,0,0-2-1.61C564,129.72,563.76,130.32,564.16,131.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551.88,129.85a2.91,2.91,0,0,0,2,1.6c.72.08,1-.53.6-1.34a2.93,2.93,0,0,0-2-1.6C551.77,128.44,551.5,129,551.88,129.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.76,129.44a2.86,2.86,0,0,0,2,1.6c.72.07,1-.53.61-1.35a2.88,2.88,0,0,0-2-1.6C547.66,128,547.38,128.62,547.76,129.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.62,129a2.82,2.82,0,0,0,2,1.6c.72.07,1-.53.63-1.35a2.86,2.86,0,0,0-2-1.6C543.53,127.61,543.25,128.22,543.62,129Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.46,128.63a2.81,2.81,0,0,0,2,1.6c.73.07,1-.54.65-1.35a2.81,2.81,0,0,0-2-1.6C539.39,127.21,539.1,127.82,539.46,128.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.29,128.24a2.77,2.77,0,0,0,2,1.6c.73.06,1-.54.67-1.36a2.79,2.79,0,0,0-2-1.59C535.23,126.82,534.93,127.43,535.29,128.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531.1,127.86a2.74,2.74,0,0,0,1.95,1.59c.73.07,1-.54.68-1.35a2.73,2.73,0,0,0-2-1.6C531.06,126.44,530.75,127,531.1,127.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M526.9,127.48a2.66,2.66,0,0,0,1.94,1.59c.73.07,1-.54.7-1.35a2.66,2.66,0,0,0-1.94-1.59C526.87,126.06,526.56,126.67,526.9,127.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M522.68,127.12a2.66,2.66,0,0,0,1.93,1.58c.74.07,1.06-.54.72-1.36a2.64,2.64,0,0,0-1.93-1.58C522.67,125.69,522.35,126.3,522.68,127.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M518.45,126.76a2.62,2.62,0,0,0,1.92,1.58c.74.06,1.07-.54.74-1.36a2.66,2.66,0,0,0-1.92-1.59C518.46,125.33,518.13,125.94,518.45,126.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M514.21,126.41a2.58,2.58,0,0,0,1.91,1.58c.74.06,1.08-.55.76-1.36A2.6,2.6,0,0,0,515,125C514.23,125,513.89,125.59,514.21,126.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510,126.06a2.55,2.55,0,0,0,1.91,1.59c.74,0,1.09-.56.77-1.37a2.56,2.56,0,0,0-1.9-1.58C510,124.64,509.64,125.25,510,126.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M505.69,125.73a2.48,2.48,0,0,0,1.89,1.58c.74.06,1.1-.56.79-1.37a2.55,2.55,0,0,0-1.9-1.58C505.74,124.3,505.38,124.92,505.69,125.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M497.11,125.09a2.44,2.44,0,0,0,1.87,1.57c.75.06,1.12-.56.83-1.37a2.45,2.45,0,0,0-1.88-1.58C497.19,123.66,496.82,124.28,497.11,125.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M492.8,124.78a2.39,2.39,0,0,0,1.87,1.57c.75.06,1.13-.56.84-1.38a2.43,2.43,0,0,0-1.86-1.57C492.9,123.35,492.53,124,492.8,124.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M488.49,124.48a2.35,2.35,0,0,0,1.85,1.57c.75,0,1.14-.57.86-1.38a2.4,2.4,0,0,0-1.85-1.57C488.6,123.05,488.22,123.67,488.49,124.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M484.16,124.19a2.32,2.32,0,0,0,1.84,1.57c.76,0,1.15-.57.88-1.38a2.35,2.35,0,0,0-1.84-1.57C484.29,122.76,483.9,123.38,484.16,124.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M479.82,123.91a2.31,2.31,0,0,0,1.83,1.57c.76,0,1.16-.58.9-1.39a2.31,2.31,0,0,0-1.84-1.56C480,122.48,479.56,123.1,479.82,123.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M475.47,123.64a2.26,2.26,0,0,0,1.82,1.56c.76,0,1.17-.57.91-1.39a2.29,2.29,0,0,0-1.82-1.56C475.63,122.21,475.22,122.83,475.47,123.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M471.11,123.38a2.25,2.25,0,0,0,1.81,1.56c.76,0,1.18-.58.93-1.4A2.25,2.25,0,0,0,472,122C471.28,121.94,470.87,122.57,471.11,123.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M444.75,122a2,2,0,0,0,1.74,1.53,1,1,0,0,0,1-1.41,2,2,0,0,0-1.74-1.53C445,120.56,444.56,121.19,444.75,122Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M440.33,121.81a2,2,0,0,0,1.72,1.54,1.06,1.06,0,0,0,1.06-1.42,2,2,0,0,0-1.73-1.53A1,1,0,0,0,440.33,121.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435.9,121.63a2,2,0,0,0,1.71,1.53,1.07,1.07,0,0,0,1.07-1.42,2,2,0,0,0-1.72-1.53A1.06,1.06,0,0,0,435.9,121.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M431.46,121.45a2,2,0,0,0,1.7,1.53,1.08,1.08,0,0,0,1.09-1.42,2,2,0,0,0-1.71-1.53A1.08,1.08,0,0,0,431.46,121.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427,121.28a1.93,1.93,0,0,0,1.69,1.53,1.1,1.1,0,0,0,1.11-1.42,2,2,0,0,0-1.7-1.53A1.1,1.1,0,0,0,427,121.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422.56,121.13a1.9,1.9,0,0,0,1.68,1.52,1.11,1.11,0,0,0,1.12-1.42,1.92,1.92,0,0,0-1.68-1.53A1.12,1.12,0,0,0,422.56,121.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.1,121a1.86,1.86,0,0,0,1.67,1.52,1.12,1.12,0,0,0,1.13-1.43,1.88,1.88,0,0,0-1.66-1.52A1.14,1.14,0,0,0,418.1,121Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.64,120.85a1.82,1.82,0,0,0,1.65,1.51,1.13,1.13,0,0,0,1.15-1.43,1.86,1.86,0,0,0-1.65-1.52A1.15,1.15,0,0,0,413.64,120.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.17,120.72a1.8,1.8,0,0,0,1.64,1.51A1.16,1.16,0,0,0,412,120.8a1.83,1.83,0,0,0-1.64-1.51A1.16,1.16,0,0,0,409.17,120.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.69,120.61a1.79,1.79,0,0,0,1.63,1.51,1.17,1.17,0,0,0,1.18-1.44,1.78,1.78,0,0,0-1.62-1.51A1.19,1.19,0,0,0,404.69,120.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.21,120.5a1.75,1.75,0,0,0,1.61,1.51,1.2,1.2,0,0,0,1.21-1.44,1.77,1.77,0,0,0-1.61-1.51A1.2,1.2,0,0,0,400.21,120.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395.73,120.41a1.73,1.73,0,0,0,1.6,1.5,1.21,1.21,0,0,0,1.22-1.44A1.75,1.75,0,0,0,397,119,1.22,1.22,0,0,0,395.73,120.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.24,120.32a1.69,1.69,0,0,0,1.58,1.5,1.23,1.23,0,0,0,1.24-1.45,1.72,1.72,0,0,0-1.58-1.5A1.24,1.24,0,0,0,391.24,120.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.75,120.25a1.66,1.66,0,0,0,1.56,1.49,1.24,1.24,0,0,0,1.26-1.45A1.69,1.69,0,0,0,388,118.8,1.24,1.24,0,0,0,386.75,120.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.25,120.18a1.64,1.64,0,0,0,1.55,1.5,1.26,1.26,0,0,0,1.27-1.46,1.66,1.66,0,0,0-1.55-1.49A1.27,1.27,0,0,0,382.25,120.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.75,120.13a1.62,1.62,0,0,0,1.54,1.49,1.29,1.29,0,0,0,1.29-1.46,1.65,1.65,0,0,0-1.55-1.49A1.29,1.29,0,0,0,377.75,120.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.25,120.09a1.58,1.58,0,0,0,1.52,1.48,1.29,1.29,0,0,0,1.3-1.46,1.6,1.6,0,0,0-1.52-1.48A1.3,1.3,0,0,0,373.25,120.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368.74,120.06a1.58,1.58,0,0,0,1.51,1.48,1.32,1.32,0,0,0,1.32-1.47,1.59,1.59,0,0,0-1.51-1.48A1.33,1.33,0,0,0,368.74,120.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364.23,120a1.54,1.54,0,0,0,1.5,1.48,1.33,1.33,0,0,0,1.33-1.46,1.56,1.56,0,0,0-1.5-1.48A1.34,1.34,0,0,0,364.23,120Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.72,120a1.52,1.52,0,0,0,1.48,1.48,1.36,1.36,0,0,0,1.35-1.47,1.53,1.53,0,0,0-1.48-1.48A1.37,1.37,0,0,0,359.72,120Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.21,120a1.5,1.5,0,0,0,1.47,1.47A1.38,1.38,0,0,0,358,120a1.51,1.51,0,0,0-1.47-1.47A1.38,1.38,0,0,0,355.21,120Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.7,120a1.47,1.47,0,0,0,1.45,1.47,1.41,1.41,0,0,0,1.38-1.48,1.49,1.49,0,0,0-1.45-1.47A1.41,1.41,0,0,0,350.7,120Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.18,120.05a1.47,1.47,0,0,0,1.44,1.47A1.44,1.44,0,0,0,349,120a1.47,1.47,0,0,0-1.44-1.47A1.43,1.43,0,0,0,346.18,120.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,120.08a1.42,1.42,0,0,0,1.42,1.46,1.48,1.48,0,0,0,0-3A1.46,1.46,0,0,0,341.67,120.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.15,120.12a1.41,1.41,0,0,0,1.41,1.46,1.48,1.48,0,0,0,1.43-1.49,1.43,1.43,0,0,0-1.41-1.46A1.49,1.49,0,0,0,337.15,120.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.63,120.17a1.41,1.41,0,0,0,1.4,1.46,1.5,1.5,0,0,0,1.44-1.49,1.4,1.4,0,0,0-1.39-1.46A1.51,1.51,0,0,0,332.63,120.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M328.12,120.23a1.37,1.37,0,0,0,1.38,1.46,1.52,1.52,0,0,0,1.46-1.5,1.39,1.39,0,0,0-1.38-1.45A1.53,1.53,0,0,0,328.12,120.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M323.61,120.3a1.34,1.34,0,0,0,1.36,1.45,1.54,1.54,0,0,0,1.47-1.49,1.35,1.35,0,0,0-1.36-1.45A1.54,1.54,0,0,0,323.61,120.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M319.09,120.39a1.33,1.33,0,0,0,1.35,1.44,1.56,1.56,0,0,0,1.49-1.5,1.34,1.34,0,0,0-1.35-1.44A1.56,1.56,0,0,0,319.09,120.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M314.58,120.48a1.31,1.31,0,0,0,1.33,1.44,1.57,1.57,0,0,0,1.5-1.5,1.31,1.31,0,0,0-1.33-1.44A1.59,1.59,0,0,0,314.58,120.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M310.07,120.58a1.29,1.29,0,0,0,1.31,1.44,1.6,1.6,0,0,0,1.52-1.5,1.29,1.29,0,0,0-1.31-1.44A1.62,1.62,0,0,0,310.07,120.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M305.56,120.7a1.27,1.27,0,0,0,1.3,1.43,1.63,1.63,0,0,0,1.53-1.51,1.28,1.28,0,0,0-1.3-1.43A1.65,1.65,0,0,0,305.56,120.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M301.06,120.82a1.24,1.24,0,0,0,1.28,1.43,1.65,1.65,0,0,0,1.54-1.51,1.25,1.25,0,0,0-1.28-1.43A1.66,1.66,0,0,0,301.06,120.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M296.55,121a1.24,1.24,0,0,0,1.27,1.44,1.71,1.71,0,0,0,1.56-1.52,1.24,1.24,0,0,0-1.27-1.43A1.7,1.7,0,0,0,296.55,121Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212,125.41c-.24.82.19,1.43,1,1.37a2.26,2.26,0,0,0,1.81-1.57c.23-.81-.2-1.43-1-1.37A2.26,2.26,0,0,0,212,125.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M207.57,125.74c-.25.82.17,1.43.93,1.37a2.26,2.26,0,0,0,1.82-1.57c.24-.82-.18-1.43-.93-1.37A2.29,2.29,0,0,0,207.57,125.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M203.2,126.08c-.25.82.16,1.43.92,1.37a2.33,2.33,0,0,0,1.83-1.58c.24-.81-.16-1.43-.92-1.37A2.36,2.36,0,0,0,203.2,126.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M198.84,126.43c-.26.82.14,1.43.9,1.37a2.37,2.37,0,0,0,1.84-1.59c.26-.81-.14-1.42-.9-1.36A2.39,2.39,0,0,0,198.84,126.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.92,128.71c-.3.81.05,1.42.8,1.35a2.56,2.56,0,0,0,1.9-1.6c.3-.81-.06-1.42-.8-1.35A2.59,2.59,0,0,0,172.92,128.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.65,129.12c-.32.81,0,1.41.77,1.34a2.57,2.57,0,0,0,1.91-1.6c.31-.81,0-1.41-.78-1.34A2.61,2.61,0,0,0,168.65,129.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M164.38,129.54c-.32.81,0,1.41.76,1.34a2.63,2.63,0,0,0,1.92-1.61c.31-.81,0-1.41-.76-1.34A2.67,2.67,0,0,0,164.38,129.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M134.91,132.68c-.37.81-.09,1.4.64,1.32a2.85,2.85,0,0,0,2-1.62c.37-.81.08-1.4-.64-1.32A2.9,2.9,0,0,0,134.91,132.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M130.76,133.16c-.38.81-.1,1.4.62,1.32a2.91,2.91,0,0,0,2-1.62c.37-.81.1-1.4-.62-1.32A2.91,2.91,0,0,0,130.76,133.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M126.62,133.65c-.38.81-.11,1.4.61,1.31a3,3,0,0,0,2-1.62c.38-.81.11-1.4-.61-1.32A3,3,0,0,0,126.62,133.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M122.5,134.14c-.39.81-.13,1.4.59,1.31a3,3,0,0,0,2-1.62c.38-.81.12-1.4-.59-1.32A3.05,3.05,0,0,0,122.5,134.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.4,134.64c-.4.81-.14,1.4.57,1.31a3,3,0,0,0,2-1.63c.4-.81.14-1.39-.57-1.31A3.07,3.07,0,0,0,118.4,134.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M114.31,135.15c-.4.81-.15,1.39.56,1.3a3,3,0,0,0,2-1.62c.4-.81.15-1.4-.56-1.31A3.11,3.11,0,0,0,114.31,135.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M110.24,135.66c-.41.81-.16,1.39.54,1.31a3.13,3.13,0,0,0,2-1.63c.4-.82.16-1.4-.54-1.31A3.18,3.18,0,0,0,110.24,135.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M102.16,136.7c-.42.81-.2,1.4.5,1.31a3.22,3.22,0,0,0,2-1.64c.42-.81.2-1.39-.5-1.3A3.19,3.19,0,0,0,102.16,136.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M646,137.65a3.91,3.91,0,0,0,2.09,1.64c.62.09.7-.48.16-1.28a3.89,3.89,0,0,0-2.08-1.64C645.56,136.27,645.48,136.85,646,137.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M642.41,137.1a3.86,3.86,0,0,0,2.09,1.63c.62.1.7-.48.17-1.28a3.85,3.85,0,0,0-2.08-1.64C642,135.72,641.88,136.29,642.41,137.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M638.78,136.54a3.84,3.84,0,0,0,2.09,1.64c.63.09.71-.48.19-1.29a3.82,3.82,0,0,0-2.09-1.63C638.35,135.16,638.26,135.74,638.78,136.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.13,136a3.81,3.81,0,0,0,2.08,1.63c.64.09.73-.48.21-1.29a3.75,3.75,0,0,0-2.08-1.63C634.71,134.61,634.61,135.19,635.13,136Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M631.46,135.45a3.69,3.69,0,0,0,2.08,1.63c.64.1.74-.48.23-1.29a3.78,3.78,0,0,0-2.09-1.63C631.05,134.07,630.94,134.64,631.46,135.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M627.76,134.91a3.69,3.69,0,0,0,2.08,1.63c.65.1.75-.48.24-1.29a3.69,3.69,0,0,0-2.08-1.63C627.36,133.52,627.25,134.1,627.76,134.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M624.05,134.37a3.7,3.7,0,0,0,2.07,1.64c.65.09.77-.49.26-1.3a3.63,3.63,0,0,0-2.08-1.63C623.66,133,623.54,133.57,624.05,134.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M620.31,133.84a3.58,3.58,0,0,0,2.07,1.63c.65.1.78-.49.28-1.29a3.63,3.63,0,0,0-2.08-1.63C619.94,132.45,619.81,133,620.31,133.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.55,133.32a3.62,3.62,0,0,0,2.07,1.63c.66.09.79-.49.29-1.3a3.58,3.58,0,0,0-2.07-1.63C616.19,131.93,616.06,132.51,616.55,133.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M612.77,132.8a3.54,3.54,0,0,0,2.07,1.62c.66.09.79-.49.31-1.3a3.58,3.58,0,0,0-2.07-1.63C612.42,131.4,612.28,132,612.77,132.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M609,132.28a3.49,3.49,0,0,0,2.07,1.63c.66.09.8-.5.32-1.31a3.55,3.55,0,0,0-2.07-1.63C608.63,130.89,608.49,131.47,609,132.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M601.31,131.26a3.45,3.45,0,0,0,2.06,1.63c.67.09.83-.5.35-1.31a3.45,3.45,0,0,0-2.06-1.63C601,129.87,600.84,130.45,601.31,131.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M561.88,126.54a3.06,3.06,0,0,0,2,1.61c.7.07.94-.52.53-1.33a3,3,0,0,0-2-1.61C561.71,125.13,561.47,125.73,561.88,126.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M557.84,126.1a3,3,0,0,0,2,1.61c.7.08.94-.52.54-1.33a3,3,0,0,0-2-1.61C557.68,124.69,557.44,125.29,557.84,126.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M553.78,125.67a3,3,0,0,0,2,1.61c.71.07,1-.52.56-1.34a3,3,0,0,0-2-1.6C553.64,124.26,553.39,124.86,553.78,125.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.71,125.25a3,3,0,0,0,2,1.61c.7.07,1-.53.57-1.34a3,3,0,0,0-2-1.61C549.59,123.84,549.33,124.44,549.71,125.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.63,124.84a2.88,2.88,0,0,0,2,1.6c.71.07,1-.53.59-1.34a2.88,2.88,0,0,0-2-1.6C545.51,123.42,545.25,124,545.63,124.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.52,124.43a2.88,2.88,0,0,0,2,1.6c.72.07,1-.53.61-1.34a2.84,2.84,0,0,0-2-1.6C541.43,123,541.15,123.62,541.52,124.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537.41,124a2.78,2.78,0,0,0,2,1.6c.71.07,1-.53.62-1.35a2.79,2.79,0,0,0-2-1.59C537.32,122.62,537,123.22,537.41,124Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.27,123.64a2.78,2.78,0,0,0,2,1.6c.71.06,1-.54.64-1.35a2.79,2.79,0,0,0-2-1.6C533.21,122.22,532.92,122.83,533.27,123.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529.13,123.26a2.74,2.74,0,0,0,1.94,1.59c.72.07,1-.54.66-1.35a2.72,2.72,0,0,0-1.94-1.59C529.07,121.84,528.78,122.45,529.13,123.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525,122.88a2.66,2.66,0,0,0,1.93,1.59c.73.07,1-.54.68-1.35a2.68,2.68,0,0,0-1.93-1.59C524.93,121.46,524.62,122.07,525,122.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520.79,122.52a2.66,2.66,0,0,0,1.93,1.58c.72.07,1-.54.7-1.35a2.66,2.66,0,0,0-1.93-1.59C520.77,121.09,520.46,121.7,520.79,122.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M516.61,122.16a2.59,2.59,0,0,0,1.91,1.58c.73.06,1.05-.55.72-1.36a2.66,2.66,0,0,0-1.92-1.58C516.6,120.73,516.28,121.34,516.61,122.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M512.4,121.81a2.59,2.59,0,0,0,1.91,1.58c.73.06,1.06-.55.73-1.36a2.59,2.59,0,0,0-1.9-1.59C512.41,120.38,512.08,121,512.4,121.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M508.19,121.46a2.53,2.53,0,0,0,1.9,1.58c.73.06,1.07-.55.75-1.36a2.56,2.56,0,0,0-1.9-1.58C508.21,120,507.88,120.65,508.19,121.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M504,121.13a2.53,2.53,0,0,0,1.89,1.58c.74.05,1.08-.56.77-1.37a2.55,2.55,0,0,0-1.89-1.58C504,119.7,503.66,120.32,504,121.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499.73,120.81a2.46,2.46,0,0,0,1.87,1.57c.74.06,1.09-.56.79-1.37a2.52,2.52,0,0,0-1.88-1.58C499.78,119.38,499.43,120,499.73,120.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.47,120.49a2.46,2.46,0,0,0,1.87,1.57c.74,0,1.1-.56.81-1.37a2.48,2.48,0,0,0-1.87-1.58C495.54,119.06,495.18,119.68,495.47,120.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.21,120.18a2.43,2.43,0,0,0,1.86,1.57c.74,0,1.11-.56.82-1.38A2.45,2.45,0,0,0,492,118.8C491.3,118.75,490.93,119.37,491.21,120.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M486.94,119.88a2.35,2.35,0,0,0,1.85,1.57c.74,0,1.12-.57.83-1.38a2.38,2.38,0,0,0-1.84-1.57C487,118.45,486.66,119.07,486.94,119.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M482.65,119.59a2.34,2.34,0,0,0,1.84,1.57c.75,0,1.13-.57.86-1.39a2.37,2.37,0,0,0-1.84-1.56C482.77,118.16,482.39,118.78,482.65,119.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.36,119.31a2.3,2.3,0,0,0,1.82,1.56c.75,0,1.14-.57.88-1.38a2.31,2.31,0,0,0-1.83-1.56C478.49,117.88,478.1,118.5,478.36,119.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.05,119a2.3,2.3,0,0,0,1.82,1.56c.75.05,1.15-.58.89-1.39a2.3,2.3,0,0,0-1.81-1.56C474.2,117.61,473.8,118.23,474.05,119Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469.74,118.78a2.22,2.22,0,0,0,1.8,1.55c.75,0,1.16-.57.91-1.39a2.25,2.25,0,0,0-1.8-1.55C469.9,117.34,469.5,118,469.74,118.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.65,117.41a2,2,0,0,0,1.73,1.53c.76,0,1.22-.59,1-1.41a2.05,2.05,0,0,0-1.73-1.53C443.91,116,443.46,116.59,443.65,117.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M439.28,117.21a2,2,0,0,0,1.71,1.53,1,1,0,0,0,1-1.41,2,2,0,0,0-1.72-1.53A1,1,0,0,0,439.28,117.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.89,117a2,2,0,0,0,1.71,1.53,1.05,1.05,0,0,0,1-1.41,2,2,0,0,0-1.71-1.53A1.05,1.05,0,0,0,434.89,117Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.5,116.85a2,2,0,0,0,1.69,1.53,1.07,1.07,0,0,0,1.07-1.42,2,2,0,0,0-1.69-1.53A1.08,1.08,0,0,0,430.5,116.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M426.1,116.68a1.93,1.93,0,0,0,1.68,1.53,1.09,1.09,0,0,0,1.09-1.42,1.94,1.94,0,0,0-1.68-1.53A1.09,1.09,0,0,0,426.1,116.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M421.7,116.53a1.89,1.89,0,0,0,1.66,1.52,1.11,1.11,0,0,0,1.11-1.43,1.92,1.92,0,0,0-1.67-1.52A1.11,1.11,0,0,0,421.7,116.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.29,116.38a1.84,1.84,0,0,0,1.65,1.52,1.12,1.12,0,0,0,1.12-1.43A1.86,1.86,0,0,0,418.4,115,1.11,1.11,0,0,0,417.29,116.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.87,116.25a1.83,1.83,0,0,0,1.64,1.51,1.13,1.13,0,0,0,1.13-1.43,1.83,1.83,0,0,0-1.64-1.51A1.13,1.13,0,0,0,412.87,116.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.45,116.12a1.8,1.8,0,0,0,1.62,1.51,1.15,1.15,0,0,0,1.16-1.43,1.83,1.83,0,0,0-1.63-1.51A1.15,1.15,0,0,0,408.45,116.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404,116a1.77,1.77,0,0,0,1.61,1.5,1.16,1.16,0,0,0,1.17-1.43,1.79,1.79,0,0,0-1.61-1.51A1.17,1.17,0,0,0,404,116Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.59,115.9a1.75,1.75,0,0,0,1.6,1.51,1.18,1.18,0,0,0,1.18-1.44,1.76,1.76,0,0,0-1.6-1.51A1.19,1.19,0,0,0,399.59,115.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395.15,115.81a1.72,1.72,0,0,0,1.58,1.5,1.2,1.2,0,0,0,1.21-1.44,1.74,1.74,0,0,0-1.59-1.51A1.21,1.21,0,0,0,395.15,115.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.71,115.72a1.69,1.69,0,0,0,1.57,1.5,1.22,1.22,0,0,0,1.22-1.45,1.69,1.69,0,0,0-1.57-1.49A1.21,1.21,0,0,0,390.71,115.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.26,115.65a1.68,1.68,0,0,0,1.56,1.49,1.24,1.24,0,0,0,1.24-1.45,1.68,1.68,0,0,0-1.56-1.49A1.25,1.25,0,0,0,386.26,115.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.81,115.58a1.65,1.65,0,0,0,1.55,1.5,1.26,1.26,0,0,0,1.25-1.46,1.65,1.65,0,0,0-1.54-1.49A1.27,1.27,0,0,0,381.81,115.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.36,115.53a1.62,1.62,0,0,0,1.53,1.49,1.27,1.27,0,0,0,1.27-1.46,1.62,1.62,0,0,0-1.53-1.48A1.28,1.28,0,0,0,377.36,115.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.91,115.49a1.58,1.58,0,0,0,1.51,1.48,1.29,1.29,0,0,0,1.29-1.46,1.61,1.61,0,0,0-1.52-1.48A1.3,1.3,0,0,0,372.91,115.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368.45,115.46a1.57,1.57,0,0,0,1.5,1.48,1.32,1.32,0,0,0,1.3-1.46,1.59,1.59,0,0,0-1.5-1.49A1.33,1.33,0,0,0,368.45,115.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364,115.43a1.54,1.54,0,0,0,1.48,1.48,1.33,1.33,0,0,0,1.32-1.46,1.54,1.54,0,0,0-1.48-1.48A1.34,1.34,0,0,0,364,115.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.53,115.42A1.52,1.52,0,0,0,361,116.9a1.36,1.36,0,0,0,1.33-1.47,1.54,1.54,0,0,0-1.47-1.48A1.36,1.36,0,0,0,359.53,115.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.06,115.42a1.5,1.5,0,0,0,1.46,1.47,1.38,1.38,0,0,0,1.35-1.47,1.5,1.5,0,0,0-1.46-1.47A1.39,1.39,0,0,0,355.06,115.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.6,115.43A1.48,1.48,0,0,0,352,116.9a1.39,1.39,0,0,0,1.36-1.48A1.48,1.48,0,0,0,352,114,1.41,1.41,0,0,0,350.6,115.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.13,115.45a1.45,1.45,0,0,0,1.43,1.46,1.42,1.42,0,0,0,1.38-1.47,1.46,1.46,0,0,0-1.43-1.47A1.43,1.43,0,0,0,346.13,115.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,115.48a1.42,1.42,0,0,0,1.4,1.46,1.47,1.47,0,1,0-1.4-1.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.2,115.52a1.4,1.4,0,0,0,1.39,1.46,1.46,1.46,0,0,0,1.41-1.49,1.4,1.4,0,0,0-1.39-1.45A1.46,1.46,0,0,0,337.2,115.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.73,115.57a1.39,1.39,0,0,0,1.38,1.46,1.49,1.49,0,0,0,1.43-1.49,1.4,1.4,0,0,0-1.38-1.46A1.5,1.5,0,0,0,332.73,115.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M328.26,115.63a1.36,1.36,0,0,0,1.37,1.45,1.5,1.5,0,0,0,1.44-1.49,1.37,1.37,0,0,0-1.36-1.45A1.53,1.53,0,0,0,328.26,115.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M323.8,115.71a1.33,1.33,0,0,0,1.34,1.44,1.53,1.53,0,0,0,1.46-1.49,1.35,1.35,0,0,0-1.34-1.45A1.54,1.54,0,0,0,323.8,115.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M319.33,115.79a1.32,1.32,0,0,0,1.33,1.44,1.56,1.56,0,0,0,1.48-1.5,1.33,1.33,0,0,0-1.33-1.44A1.57,1.57,0,0,0,319.33,115.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M314.87,115.88a1.3,1.3,0,0,0,1.31,1.44,1.57,1.57,0,0,0,1.49-1.5,1.3,1.3,0,0,0-1.31-1.44A1.59,1.59,0,0,0,314.87,115.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M310.41,116a1.28,1.28,0,0,0,1.29,1.44,1.61,1.61,0,0,0,1.51-1.5,1.29,1.29,0,0,0-1.3-1.44A1.61,1.61,0,0,0,310.41,116Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M306,116.1a1.26,1.26,0,0,0,1.28,1.43,1.64,1.64,0,0,0,1.52-1.51,1.27,1.27,0,0,0-1.28-1.43A1.64,1.64,0,0,0,306,116.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M301.49,116.22a1.24,1.24,0,0,0,1.26,1.43,1.65,1.65,0,0,0,1.54-1.51,1.25,1.25,0,0,0-1.27-1.43A1.67,1.67,0,0,0,301.49,116.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M297,116.36a1.22,1.22,0,0,0,1.25,1.42,1.68,1.68,0,0,0,1.55-1.51,1.24,1.24,0,0,0-1.25-1.43A1.7,1.7,0,0,0,297,116.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M270.37,117.38a1.13,1.13,0,0,0,1.15,1.41,1.86,1.86,0,0,0,1.64-1.54,1.13,1.13,0,0,0-1.15-1.41A1.87,1.87,0,0,0,270.37,117.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M265.94,117.58a1.11,1.11,0,0,0,1.14,1.41,1.9,1.9,0,0,0,1.65-1.54,1.12,1.12,0,0,0-1.14-1.41A1.92,1.92,0,0,0,265.94,117.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M257.1,118a1.08,1.08,0,0,0,1.1,1.4,2,2,0,0,0,1.68-1.54,1.08,1.08,0,0,0-1.1-1.4A2,2,0,0,0,257.1,118Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M252.69,118.26a1.07,1.07,0,0,0,1.09,1.4,2,2,0,0,0,1.68-1.55,1.06,1.06,0,0,0-1.08-1.4A2,2,0,0,0,252.69,118.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M217.65,120.49c-.23.82.19,1.43.95,1.38a2.24,2.24,0,0,0,1.79-1.57c.23-.82-.2-1.43-.95-1.38A2.27,2.27,0,0,0,217.65,120.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M213.31,120.81c-.24.82.17,1.43.93,1.37a2.25,2.25,0,0,0,1.8-1.57c.23-.81-.18-1.43-.93-1.37A2.28,2.28,0,0,0,213.31,120.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M209,121.15c-.25.81.15,1.42.91,1.36a2.29,2.29,0,0,0,1.81-1.57c.24-.82-.17-1.43-.91-1.37A2.3,2.3,0,0,0,209,121.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.65,121.49c-.26.81.14,1.42.9,1.36a2.35,2.35,0,0,0,1.82-1.58c.25-.81-.15-1.42-.9-1.36A2.34,2.34,0,0,0,204.65,121.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170.44,124.53c-.32.81,0,1.41.75,1.34a2.59,2.59,0,0,0,1.9-1.6c.32-.82,0-1.42-.75-1.35A2.62,2.62,0,0,0,170.44,124.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.21,124.94c-.33.82,0,1.42.74,1.34a2.62,2.62,0,0,0,1.91-1.6c.32-.81,0-1.41-.74-1.34A2.66,2.66,0,0,0,166.21,124.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M145.29,127.15c-.36.82-.07,1.41.65,1.33a2.85,2.85,0,0,0,2-1.61c.35-.82.06-1.41-.66-1.33A2.85,2.85,0,0,0,145.29,127.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M141.14,127.62c-.36.81-.08,1.4.64,1.32a2.88,2.88,0,0,0,2-1.61c.36-.82.07-1.41-.64-1.33A2.9,2.9,0,0,0,141.14,127.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M137,128.09c-.38.81-.1,1.4.62,1.32a2.87,2.87,0,0,0,2-1.62c.37-.81.09-1.4-.62-1.32A2.92,2.92,0,0,0,137,128.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M132.9,128.57c-.38.81-.11,1.4.61,1.32a3,3,0,0,0,2-1.62c.37-.81.1-1.4-.61-1.32A3,3,0,0,0,132.9,128.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M128.81,129.06c-.39.81-.13,1.4.58,1.31a3,3,0,0,0,2-1.62c.38-.81.12-1.4-.59-1.31A3,3,0,0,0,128.81,129.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M124.73,129.55c-.4.81-.15,1.4.56,1.32a3.05,3.05,0,0,0,2-1.63c.39-.81.13-1.4-.57-1.31A3,3,0,0,0,124.73,129.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M120.66,130.05c-.4.81-.15,1.4.55,1.31a3,3,0,0,0,2-1.62c.4-.81.15-1.4-.55-1.31A3.05,3.05,0,0,0,120.66,130.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M116.61,130.56c-.41.81-.17,1.4.54,1.31a3.1,3.1,0,0,0,2-1.63c.41-.81.17-1.4-.53-1.31A3.14,3.14,0,0,0,116.61,130.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M112.58,131.08c-.42.81-.18,1.39.52,1.3a3.14,3.14,0,0,0,2-1.63c.41-.81.18-1.39-.52-1.3A3.16,3.16,0,0,0,112.58,131.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M108.56,131.6c-.42.81-.19,1.39.5,1.3a3.15,3.15,0,0,0,2-1.63c.42-.81.2-1.4-.5-1.31A3.28,3.28,0,0,0,108.56,131.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M104.57,132.12c-.43.81-.22,1.4.48,1.3a3.2,3.2,0,0,0,2-1.63c.43-.81.21-1.39-.48-1.3A3.2,3.2,0,0,0,104.57,132.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M646.57,133.67a4,4,0,0,0,2.08,1.63c.62.1.68-.47.14-1.28a4,4,0,0,0-2.09-1.63C646.09,132.29,646,132.86,646.57,133.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M643,133.1a3.89,3.89,0,0,0,2.08,1.64c.62.09.69-.48.15-1.28a3.89,3.89,0,0,0-2.08-1.64C642.54,131.72,642.48,132.3,643,133.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M639.43,132.54a3.89,3.89,0,0,0,2.08,1.64c.63.09.7-.48.17-1.29a3.91,3.91,0,0,0-2.08-1.63C639,131.16,638.9,131.74,639.43,132.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.83,132a3.84,3.84,0,0,0,2.08,1.63c.63.1.71-.48.18-1.28A3.85,3.85,0,0,0,636,130.7C635.39,130.61,635.3,131.18,635.83,132Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M632.2,131.44a3.84,3.84,0,0,0,2.08,1.63c.64.09.72-.48.2-1.29a3.8,3.8,0,0,0-2.08-1.63C631.77,130.05,631.68,130.63,632.2,131.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M617.49,129.27a3.63,3.63,0,0,0,2.08,1.63c.64.1.76-.48.26-1.29a3.66,3.66,0,0,0-2.08-1.63C617.11,127.88,617,128.46,617.49,129.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.77,128.74a3.58,3.58,0,0,0,2.07,1.63c.64.1.77-.49.27-1.29a3.61,3.61,0,0,0-2.07-1.63C613.39,127.35,613.27,127.94,613.77,128.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M610,128.22a3.53,3.53,0,0,0,2.06,1.63c.66.09.79-.49.29-1.3a3.56,3.56,0,0,0-2.06-1.63C609.66,126.83,609.53,127.41,610,128.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M606.25,127.7a3.5,3.5,0,0,0,2.06,1.63c.66.09.79-.49.31-1.3a3.55,3.55,0,0,0-2.07-1.63C605.9,126.31,605.76,126.89,606.25,127.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M602.46,127.19a3.5,3.5,0,0,0,2.06,1.63c.66.09.8-.5.32-1.31a3.45,3.45,0,0,0-2.06-1.62C602.13,125.8,602,126.38,602.46,127.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M598.65,126.68a3.45,3.45,0,0,0,2.06,1.63c.66.09.81-.5.33-1.31a3.4,3.4,0,0,0-2-1.62C598.33,125.29,598.18,125.87,598.65,126.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M559.57,122a3.06,3.06,0,0,0,2,1.61c.69.07.92-.52.51-1.33a3.07,3.07,0,0,0-2-1.61C559.39,120.54,559.16,121.14,559.57,122Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555.57,121.51a3,3,0,0,0,2,1.61c.7.07.93-.52.52-1.33a3,3,0,0,0-2-1.61C555.4,120.1,555.17,120.7,555.57,121.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551.55,121.08a3,3,0,0,0,2,1.61c.7.07.94-.53.54-1.34a3,3,0,0,0-2-1.6C551.4,119.67,551.15,120.27,551.55,121.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.52,120.66a2.92,2.92,0,0,0,2,1.6c.7.08,1-.52.55-1.34a3,3,0,0,0-2-1.6C547.38,119.25,547.13,119.85,547.52,120.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.47,120.25a2.93,2.93,0,0,0,2,1.6c.71.07,1-.53.58-1.34a2.89,2.89,0,0,0-2-1.6C543.34,118.83,543.09,119.43,543.47,120.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.41,119.84a2.85,2.85,0,0,0,2,1.6c.71.07,1-.53.59-1.35a2.87,2.87,0,0,0-2-1.59C539.3,118.43,539,119,539.41,119.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.33,119.44a2.79,2.79,0,0,0,1.95,1.59c.71.07,1-.53.61-1.34a2.85,2.85,0,0,0-1.95-1.6C535.23,118,535,118.63,535.33,119.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531.24,119.05a2.78,2.78,0,0,0,1.94,1.59c.72.07,1-.53.63-1.35a2.82,2.82,0,0,0-1.95-1.59C531.16,117.63,530.88,118.24,531.24,119.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M527.13,118.66a2.71,2.71,0,0,0,1.94,1.59c.71.07,1-.53.64-1.35a2.77,2.77,0,0,0-1.93-1.59C527.07,117.25,526.78,117.85,527.13,118.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M523,118.29a2.73,2.73,0,0,0,1.93,1.59c.72.06,1-.54.66-1.36a2.69,2.69,0,0,0-1.93-1.58C523,116.87,522.67,117.48,523,118.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M518.88,117.92a2.7,2.7,0,0,0,1.92,1.59c.72.06,1-.55.68-1.36a2.69,2.69,0,0,0-1.92-1.58C518.84,116.5,518.54,117.11,518.88,117.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M514.73,117.56a2.64,2.64,0,0,0,1.91,1.58c.73.07,1-.54.7-1.35a2.66,2.66,0,0,0-1.91-1.59C514.71,116.14,514.4,116.75,514.73,117.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510.58,117.21a2.58,2.58,0,0,0,1.9,1.58c.72.06,1-.55.71-1.36a2.61,2.61,0,0,0-1.9-1.58C510.57,115.79,510.25,116.4,510.58,117.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M506.4,116.87a2.58,2.58,0,0,0,1.89,1.58c.73.06,1.06-.56.74-1.37a2.56,2.56,0,0,0-1.89-1.57C506.41,115.45,506.09,116.06,506.4,116.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M502.22,116.53a2.51,2.51,0,0,0,1.88,1.58c.73.06,1.07-.55.75-1.37a2.54,2.54,0,0,0-1.88-1.57C502.25,115.11,501.91,115.72,502.22,116.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M498,116.21a2.46,2.46,0,0,0,1.87,1.57c.73.06,1.07-.55.76-1.37a2.51,2.51,0,0,0-1.87-1.57C498.07,114.78,497.72,115.4,498,116.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M493.82,115.89a2.41,2.41,0,0,0,1.86,1.57c.73.06,1.08-.56.78-1.37a2.48,2.48,0,0,0-1.86-1.57C493.87,114.47,493.52,115.08,493.82,115.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.6,115.59a2.4,2.4,0,0,0,1.85,1.56c.73.06,1.09-.56.8-1.37a2.43,2.43,0,0,0-1.85-1.57C489.67,114.16,489.31,114.77,489.6,115.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.37,115.29a2.37,2.37,0,0,0,1.84,1.56c.74.05,1.1-.56.82-1.38a2.4,2.4,0,0,0-1.84-1.56C485.46,113.86,485.09,114.48,485.37,115.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.13,115a2.34,2.34,0,0,0,1.83,1.56c.74,0,1.11-.57.84-1.38a2.37,2.37,0,0,0-1.83-1.56C481.24,113.57,480.86,114.19,481.13,115Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.88,114.72a2.32,2.32,0,0,0,1.82,1.56c.74,0,1.12-.58.85-1.39a2.33,2.33,0,0,0-1.82-1.56C477,113.29,476.62,113.91,476.88,114.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.62,114.45a2.28,2.28,0,0,0,1.81,1.55c.74,0,1.13-.57.87-1.38a2.29,2.29,0,0,0-1.81-1.56C472.76,113,472.37,113.63,472.62,114.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M468.35,114.18a2.25,2.25,0,0,0,1.79,1.56c.75,0,1.15-.58.89-1.39a2.24,2.24,0,0,0-1.79-1.55C468.5,112.75,468.1,113.37,468.35,114.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M464.07,113.93a2.21,2.21,0,0,0,1.78,1.55c.75,0,1.15-.58.91-1.39a2.22,2.22,0,0,0-1.78-1.55C464.24,112.5,463.83,113.12,464.07,113.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M459.78,113.69a2.16,2.16,0,0,0,1.77,1.54c.75,0,1.16-.58.93-1.39a2.21,2.21,0,0,0-1.77-1.55C460,112.25,459.55,112.88,459.78,113.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M438.21,112.62a2,2,0,0,0,1.71,1.53,1,1,0,0,0,1-1.41,2,2,0,0,0-1.71-1.53C438.48,111.17,438,111.8,438.21,112.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.87,112.43a2,2,0,0,0,1.7,1.53,1,1,0,0,0,1-1.41,2,2,0,0,0-1.7-1.53A1.05,1.05,0,0,0,433.87,112.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.53,112.26a2,2,0,0,0,1.68,1.52,1.06,1.06,0,0,0,1.05-1.42,2,2,0,0,0-1.68-1.52A1.07,1.07,0,0,0,429.53,112.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M425.18,112.09a1.92,1.92,0,0,0,1.67,1.52,1.07,1.07,0,0,0,1.06-1.42,1.93,1.93,0,0,0-1.67-1.52A1.08,1.08,0,0,0,425.18,112.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M420.82,111.94a1.88,1.88,0,0,0,1.66,1.51,1.09,1.09,0,0,0,1.08-1.42,1.91,1.91,0,0,0-1.66-1.52A1.1,1.1,0,0,0,420.82,111.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.46,111.79a1.85,1.85,0,0,0,1.64,1.51,1.1,1.1,0,0,0,1.1-1.42,1.86,1.86,0,0,0-1.64-1.51A1.11,1.11,0,0,0,416.46,111.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.09,111.65a1.84,1.84,0,0,0,1.63,1.52,1.13,1.13,0,0,0,1.12-1.43,1.85,1.85,0,0,0-1.63-1.51A1.12,1.12,0,0,0,412.09,111.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.72,111.53a1.8,1.8,0,0,0,1.61,1.51,1.14,1.14,0,0,0,1.13-1.43,1.8,1.8,0,0,0-1.61-1.51A1.14,1.14,0,0,0,407.72,111.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.34,111.41a1.76,1.76,0,0,0,1.6,1.51,1.15,1.15,0,0,0,1.15-1.43,1.79,1.79,0,0,0-1.6-1.51A1.16,1.16,0,0,0,403.34,111.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399,111.31a1.76,1.76,0,0,0,1.59,1.5,1.17,1.17,0,0,0,1.17-1.44,1.76,1.76,0,0,0-1.59-1.5A1.19,1.19,0,0,0,399,111.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.56,111.22a1.71,1.71,0,0,0,1.58,1.49,1.19,1.19,0,0,0,1.18-1.44,1.71,1.71,0,0,0-1.57-1.49A1.19,1.19,0,0,0,394.56,111.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.17,111.13a1.7,1.7,0,0,0,1.56,1.5,1.22,1.22,0,0,0,1.2-1.45,1.69,1.69,0,0,0-1.56-1.49A1.21,1.21,0,0,0,390.17,111.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.78,111.06a1.65,1.65,0,0,0,1.54,1.49,1.24,1.24,0,0,0,1.22-1.45,1.68,1.68,0,0,0-1.55-1.49A1.23,1.23,0,0,0,385.78,111.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.38,111a1.63,1.63,0,0,0,1.52,1.49,1.25,1.25,0,0,0,1.24-1.45,1.66,1.66,0,0,0-1.53-1.49A1.25,1.25,0,0,0,381.38,111Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377,110.94a1.62,1.62,0,0,0,1.52,1.49,1.28,1.28,0,0,0,1.25-1.46,1.63,1.63,0,0,0-1.52-1.48A1.27,1.27,0,0,0,377,110.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.57,110.9a1.58,1.58,0,0,0,1.49,1.48,1.28,1.28,0,0,0,1.27-1.46,1.59,1.59,0,0,0-1.5-1.48A1.28,1.28,0,0,0,372.57,110.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368.16,110.86a1.54,1.54,0,0,0,1.48,1.48,1.3,1.3,0,0,0,1.29-1.46,1.57,1.57,0,0,0-1.49-1.47A1.3,1.3,0,0,0,368.16,110.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M363.75,110.84a1.54,1.54,0,0,0,1.46,1.48,1.33,1.33,0,0,0,1.31-1.46,1.54,1.54,0,0,0-1.47-1.48A1.33,1.33,0,0,0,363.75,110.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.33,110.83a1.52,1.52,0,0,0,1.46,1.47,1.33,1.33,0,0,0,1.31-1.46,1.52,1.52,0,0,0-1.45-1.47A1.35,1.35,0,0,0,359.33,110.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M354.92,110.83a1.49,1.49,0,0,0,1.44,1.47,1.37,1.37,0,0,0,1.33-1.47,1.5,1.5,0,0,0-1.44-1.47A1.37,1.37,0,0,0,354.92,110.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.5,110.84a1.46,1.46,0,0,0,1.42,1.46,1.39,1.39,0,0,0,1.36-1.47,1.48,1.48,0,0,0-1.43-1.46A1.4,1.4,0,0,0,350.5,110.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346.08,110.86a1.45,1.45,0,0,0,1.41,1.46,1.41,1.41,0,0,0,1.37-1.47,1.46,1.46,0,0,0-1.41-1.47A1.44,1.44,0,0,0,346.08,110.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,110.89a1.39,1.39,0,1,0,1.38-1.48A1.45,1.45,0,0,0,341.67,110.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.25,110.93a1.41,1.41,0,0,0,1.37,1.46,1.46,1.46,0,0,0,1.4-1.49,1.39,1.39,0,0,0-1.37-1.45A1.46,1.46,0,0,0,337.25,110.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.83,111a1.37,1.37,0,0,0,1.36,1.45,1.49,1.49,0,0,0,1.42-1.48,1.38,1.38,0,0,0-1.36-1.45A1.48,1.48,0,0,0,332.83,111Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M328.41,111a1.36,1.36,0,0,0,1.35,1.45,1.5,1.5,0,0,0,1.43-1.49,1.36,1.36,0,0,0-1.35-1.45A1.52,1.52,0,0,0,328.41,111Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M324,111.11a1.33,1.33,0,0,0,1.32,1.45,1.53,1.53,0,0,0,1.45-1.49,1.35,1.35,0,0,0-1.33-1.45A1.53,1.53,0,0,0,324,111.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M319.58,111.2a1.31,1.31,0,0,0,1.31,1.44,1.55,1.55,0,0,0,1.46-1.5A1.32,1.32,0,0,0,321,109.7,1.57,1.57,0,0,0,319.58,111.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M315.16,111.29a1.3,1.3,0,0,0,1.3,1.44,1.59,1.59,0,0,0,1.48-1.5,1.31,1.31,0,0,0-1.3-1.44A1.6,1.6,0,0,0,315.16,111.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M310.75,111.39a1.28,1.28,0,0,0,1.28,1.44,1.6,1.6,0,0,0,1.49-1.5,1.28,1.28,0,0,0-1.28-1.44A1.61,1.61,0,0,0,310.75,111.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M306.34,111.51a1.25,1.25,0,0,0,1.26,1.43,1.63,1.63,0,0,0,1.51-1.51,1.26,1.26,0,0,0-1.26-1.43A1.65,1.65,0,0,0,306.34,111.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M301.93,111.63a1.24,1.24,0,0,0,1.25,1.43,1.66,1.66,0,0,0,1.52-1.51,1.25,1.25,0,0,0-1.25-1.43A1.67,1.67,0,0,0,301.93,111.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219,115.9c-.24.81.18,1.43.93,1.37a2.21,2.21,0,0,0,1.77-1.57c.24-.81-.18-1.42-.92-1.37A2.27,2.27,0,0,0,219,115.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M214.69,116.22c-.25.81.16,1.43.91,1.37a2.27,2.27,0,0,0,1.79-1.57c.24-.81-.17-1.43-.91-1.37A2.29,2.29,0,0,0,214.69,116.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.12,116.89c-.26.81.13,1.43.88,1.37a2.35,2.35,0,0,0,1.81-1.58c.26-.81-.14-1.42-.87-1.36A2.35,2.35,0,0,0,206.12,116.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.07,120.35c-.33.81,0,1.41.72,1.34a2.65,2.65,0,0,0,1.9-1.6c.33-.81,0-1.41-.72-1.34A2.66,2.66,0,0,0,168.07,120.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M163.9,120.78c-.34.81,0,1.41.7,1.33a2.68,2.68,0,0,0,1.91-1.6c.34-.81,0-1.41-.7-1.34A2.72,2.72,0,0,0,163.9,120.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.74,121.21c-.35.82,0,1.41.68,1.34a2.74,2.74,0,0,0,1.93-1.61c.34-.81,0-1.41-.69-1.33A2.73,2.73,0,0,0,159.74,121.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.59,121.66c-.35.81-.05,1.4.67,1.33a2.8,2.8,0,0,0,1.94-1.61c.34-.81,0-1.41-.67-1.33A2.77,2.77,0,0,0,155.59,121.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M151.46,122.11c-.36.81-.07,1.4.65,1.32a2.81,2.81,0,0,0,1.95-1.61c.35-.81.06-1.4-.65-1.32A2.81,2.81,0,0,0,151.46,122.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M147.34,122.56c-.36.82-.08,1.41.64,1.33a2.83,2.83,0,0,0,2-1.61c.36-.82.08-1.41-.63-1.33A2.89,2.89,0,0,0,147.34,122.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M143.24,123c-.37.81-.1,1.4.62,1.32a2.89,2.89,0,0,0,2-1.61c.37-.81.09-1.41-.62-1.33A2.93,2.93,0,0,0,143.24,123Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M139.15,123.5c-.38.82-.11,1.41.6,1.32a2.92,2.92,0,0,0,2-1.61c.37-.81.11-1.41-.6-1.32A2.92,2.92,0,0,0,139.15,123.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M135.08,124c-.39.82-.13,1.41.58,1.32a3,3,0,0,0,2-1.62c.38-.81.12-1.4-.59-1.32A3,3,0,0,0,135.08,124Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M131,124.47c-.4.81-.14,1.4.56,1.32a3,3,0,0,0,2-1.62c.39-.82.13-1.4-.57-1.32A3,3,0,0,0,131,124.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M127,125c-.4.81-.15,1.4.55,1.31a3,3,0,0,0,2-1.62c.4-.82.15-1.4-.55-1.32A3.1,3.1,0,0,0,127,125Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M123,125.47c-.41.81-.17,1.4.53,1.31a3.08,3.08,0,0,0,2-1.63c.41-.81.17-1.39-.53-1.31A3.1,3.1,0,0,0,123,125.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.93,126c-.41.81-.18,1.39.52,1.31a3.12,3.12,0,0,0,2-1.63c.41-.81.18-1.4-.51-1.31A3.16,3.16,0,0,0,118.93,126Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M114.94,126.49c-.42.81-.2,1.4.5,1.31a3.18,3.18,0,0,0,2-1.63c.41-.81.19-1.4-.5-1.31A3.18,3.18,0,0,0,114.94,126.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M111,127c-.43.81-.21,1.39.48,1.3a3.22,3.22,0,0,0,2-1.63c.42-.81.2-1.4-.49-1.31A3.26,3.26,0,0,0,111,127Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M107,127.54c-.43.81-.22,1.4.47,1.3a3.22,3.22,0,0,0,2-1.63c.43-.81.22-1.39-.46-1.3A3.27,3.27,0,0,0,107,127.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M103.05,128.08c-.44.81-.24,1.39.45,1.3a3.34,3.34,0,0,0,2-1.64c.43-.81.23-1.39-.45-1.3A3.34,3.34,0,0,0,103.05,128.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M99.12,128.62c-.44.81-.25,1.39.43,1.3a3.33,3.33,0,0,0,2-1.64c.44-.81.24-1.39-.44-1.3A3.4,3.4,0,0,0,99.12,128.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M95.21,129.17c-.45.81-.26,1.38.42,1.29a3.34,3.34,0,0,0,2-1.64c.45-.81.26-1.39-.41-1.29A3.35,3.35,0,0,0,95.21,129.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M636.44,128a3.87,3.87,0,0,0,2.08,1.63c.62.1.69-.47.15-1.28a3.87,3.87,0,0,0-2.08-1.63C636,126.61,635.91,127.18,636.44,128Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M632.86,127.43a3.82,3.82,0,0,0,2.08,1.63c.62.1.7-.47.17-1.28a3.87,3.87,0,0,0-2.08-1.63C632.41,126.05,632.34,126.62,632.86,127.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M614.66,124.71a3.76,3.76,0,0,0,2.07,1.63c.64.09.75-.49.25-1.3a3.67,3.67,0,0,0-2.07-1.63C614.27,123.32,614.16,123.9,614.66,124.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M611,124.17A3.59,3.59,0,0,0,613,125.8c.64.1.76-.48.26-1.29a3.62,3.62,0,0,0-2.06-1.63C610.59,122.79,610.47,123.37,611,124.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M607.25,123.65a3.56,3.56,0,0,0,2.06,1.63c.65.09.77-.49.27-1.3a3.56,3.56,0,0,0-2.06-1.63C606.88,122.26,606.76,122.84,607.25,123.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603.51,123.13a3.5,3.5,0,0,0,2.06,1.63c.65.09.78-.5.29-1.3a3.56,3.56,0,0,0-2.06-1.63C603.15,121.74,603,122.32,603.51,123.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M599.75,122.62a3.53,3.53,0,0,0,2,1.62c.66.09.8-.49.31-1.3a3.52,3.52,0,0,0-2.05-1.63C599.41,121.22,599.27,121.81,599.75,122.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M596,122.11a3.5,3.5,0,0,0,2,1.62c.66.09.81-.5.33-1.3a3.52,3.52,0,0,0-2.05-1.63C595.64,120.71,595.5,121.3,596,122.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M576.82,119.65a3.25,3.25,0,0,0,2,1.62c.68.08.86-.51.41-1.32a3.27,3.27,0,0,0-2-1.61C576.55,118.25,576.37,118.84,576.82,119.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M557.24,117.36a3.09,3.09,0,0,0,2,1.61c.69.08.91-.52.49-1.33a3.11,3.11,0,0,0-2-1.61C557,116,556.83,116.55,557.24,117.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M553.28,116.93a3,3,0,0,0,2,1.6c.69.08.92-.52.51-1.33a3.06,3.06,0,0,0-2-1.61C553.1,115.52,552.87,116.11,553.28,116.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.3,116.5a3,3,0,0,0,2,1.6c.69.07.92-.52.52-1.34a3,3,0,0,0-2-1.6C549.13,115.09,548.9,115.68,549.3,116.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.3,116.07a3,3,0,0,0,2,1.6c.7.08.94-.52.54-1.33a3,3,0,0,0-2-1.6C545.15,114.66,544.91,115.26,545.3,116.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.29,115.66a3,3,0,0,0,2,1.6c.7.07,1-.53.55-1.34a2.93,2.93,0,0,0-2-1.6C541.16,114.25,540.91,114.85,541.29,115.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537.27,115.25a2.89,2.89,0,0,0,2,1.6c.7.07.95-.53.57-1.34a2.91,2.91,0,0,0-2-1.6C537.15,113.84,536.89,114.44,537.27,115.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.23,114.85a2.83,2.83,0,0,0,1.95,1.59c.7.07,1-.53.59-1.34a2.86,2.86,0,0,0-1.95-1.59C533.12,113.44,532.86,114,533.23,114.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529.18,114.46a2.8,2.8,0,0,0,1.94,1.59c.71.07,1-.53.61-1.34a2.81,2.81,0,0,0-1.94-1.6C529.09,113.05,528.82,113.65,529.18,114.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525.12,114.08a2.71,2.71,0,0,0,1.93,1.58c.7.07,1-.53.62-1.34a2.76,2.76,0,0,0-1.93-1.59C525,112.66,524.76,113.27,525.12,114.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M521,113.7a2.73,2.73,0,0,0,1.92,1.59c.71.06,1-.54.64-1.35a2.71,2.71,0,0,0-1.92-1.59C521,112.28,520.69,112.89,521,113.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M517,113.33a2.7,2.7,0,0,0,1.91,1.59c.71.06,1-.55.66-1.36a2.7,2.7,0,0,0-1.91-1.58C516.9,111.92,516.6,112.52,517,113.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M512.84,113a2.64,2.64,0,0,0,1.91,1.58c.71.07,1-.54.67-1.35a2.65,2.65,0,0,0-1.9-1.58C512.81,111.56,512.51,112.16,512.84,113Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M508.73,112.62a2.6,2.6,0,0,0,1.89,1.58c.72.06,1-.55.69-1.36a2.64,2.64,0,0,0-1.89-1.58C508.71,111.2,508.4,111.81,508.73,112.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M504.6,112.28a2.57,2.57,0,0,0,1.88,1.58c.72.06,1-.55.71-1.36a2.56,2.56,0,0,0-1.88-1.58C504.6,110.86,504.28,111.47,504.6,112.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M500.46,112a2.52,2.52,0,0,0,1.87,1.57c.72.06,1.05-.55.73-1.36a2.57,2.57,0,0,0-1.87-1.58C500.47,110.53,500.15,111.14,500.46,112Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M496.31,111.62a2.47,2.47,0,0,0,1.86,1.57c.72.06,1.06-.55.75-1.36a2.5,2.5,0,0,0-1.87-1.57C496.33,110.2,496,110.81,496.31,111.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M492.14,111.31a2.45,2.45,0,0,0,1.85,1.56c.73.06,1.07-.56.77-1.37a2.46,2.46,0,0,0-1.85-1.56C492.19,109.88,491.85,110.5,492.14,111.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M488,111a2.4,2.4,0,0,0,1.84,1.56c.73.06,1.08-.56.78-1.37a2.42,2.42,0,0,0-1.84-1.56C488,109.57,487.68,110.19,488,111Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483.78,110.7a2.4,2.4,0,0,0,1.83,1.56c.73,0,1.09-.56.8-1.37a2.37,2.37,0,0,0-1.83-1.56C483.86,109.28,483.5,109.89,483.78,110.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M479.59,110.41a2.35,2.35,0,0,0,1.82,1.56c.73,0,1.1-.57.82-1.38a2.38,2.38,0,0,0-1.82-1.56C479.68,109,479.32,109.6,479.59,110.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M475.38,110.13a2.33,2.33,0,0,0,1.81,1.56c.73,0,1.11-.57.84-1.38a2.35,2.35,0,0,0-1.81-1.56C475.49,108.7,475.12,109.32,475.38,110.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M471.17,109.86a2.26,2.26,0,0,0,1.8,1.55c.73,0,1.11-.57.85-1.38a2.29,2.29,0,0,0-1.8-1.55C471.29,108.43,470.91,109.05,471.17,109.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M466.94,109.6a2.25,2.25,0,0,0,1.79,1.55c.73,0,1.12-.58.87-1.39a2.28,2.28,0,0,0-1.79-1.55C467.08,108.17,466.7,108.79,466.94,109.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M462.71,109.35a2.2,2.2,0,0,0,1.77,1.54c.74,0,1.14-.58.89-1.39A2.21,2.21,0,0,0,463.6,108C462.87,107.91,462.47,108.54,462.71,109.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.47,109.1a2.19,2.19,0,0,0,1.76,1.55c.74,0,1.14-.58.9-1.4a2.16,2.16,0,0,0-1.76-1.54C458.64,107.67,458.24,108.29,458.47,109.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M454.22,108.87a2.14,2.14,0,0,0,1.75,1.54c.74,0,1.15-.58.92-1.39a2.16,2.16,0,0,0-1.75-1.54C454.41,107.44,454,108.06,454.22,108.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450,108.65a2.09,2.09,0,0,0,1.73,1.53c.75,0,1.17-.58.94-1.39a2.12,2.12,0,0,0-1.73-1.54C450.16,107.21,449.74,107.84,450,108.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M445.69,108.43a2.09,2.09,0,0,0,1.72,1.54c.75,0,1.18-.59,1-1.4a2.09,2.09,0,0,0-1.72-1.54C445.91,107,445.48,107.62,445.69,108.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M441.42,108.23a2,2,0,0,0,1.71,1.53c.74,0,1.18-.59,1-1.41a2.07,2.07,0,0,0-1.71-1.53C441.65,106.79,441.22,107.42,441.42,108.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M437.13,108a2,2,0,0,0,1.7,1.53c.75,0,1.19-.6,1-1.41a2,2,0,0,0-1.69-1.52C437.39,106.59,436.94,107.22,437.13,108Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M424.25,107.51A1.9,1.9,0,0,0,425.9,109a1.07,1.07,0,0,0,1.05-1.42,1.93,1.93,0,0,0-1.66-1.52A1.07,1.07,0,0,0,424.25,107.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419.94,107.35a1.88,1.88,0,0,0,1.64,1.52,1.08,1.08,0,0,0,1.06-1.42,1.88,1.88,0,0,0-1.64-1.51A1.08,1.08,0,0,0,419.94,107.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.62,107.21a1.86,1.86,0,0,0,1.63,1.51,1.09,1.09,0,0,0,1.08-1.42,1.85,1.85,0,0,0-1.63-1.51A1.1,1.1,0,0,0,415.62,107.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.3,107.07a1.83,1.83,0,0,0,1.62,1.51,1.11,1.11,0,0,0,1.1-1.42,1.84,1.84,0,0,0-1.62-1.51A1.11,1.11,0,0,0,411.3,107.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407,107a1.78,1.78,0,0,0,1.6,1.5,1.12,1.12,0,0,0,1.11-1.42,1.8,1.8,0,0,0-1.6-1.51A1.13,1.13,0,0,0,407,107Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402.65,106.83a1.76,1.76,0,0,0,1.58,1.5,1.15,1.15,0,0,0,1.14-1.43,1.78,1.78,0,0,0-1.59-1.5A1.14,1.14,0,0,0,402.65,106.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.31,106.73a1.75,1.75,0,0,0,1.57,1.5,1.17,1.17,0,0,0,1.15-1.44,1.73,1.73,0,0,0-1.57-1.49A1.17,1.17,0,0,0,398.31,106.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394,106.63a1.72,1.72,0,0,0,1.56,1.5,1.19,1.19,0,0,0,1.17-1.44,1.71,1.71,0,0,0-1.56-1.49A1.19,1.19,0,0,0,394,106.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.63,106.55a1.67,1.67,0,0,0,1.54,1.49,1.2,1.2,0,0,0,1.19-1.44,1.71,1.71,0,0,0-1.55-1.49A1.2,1.2,0,0,0,389.63,106.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.28,106.48a1.65,1.65,0,0,0,1.53,1.48,1.21,1.21,0,0,0,1.2-1.44,1.67,1.67,0,0,0-1.53-1.49A1.23,1.23,0,0,0,385.28,106.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.93,106.41a1.64,1.64,0,0,0,1.51,1.49,1.24,1.24,0,0,0,1.22-1.45,1.63,1.63,0,0,0-1.51-1.48A1.24,1.24,0,0,0,380.93,106.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.58,106.36a1.6,1.6,0,0,0,1.5,1.48,1.25,1.25,0,0,0,1.23-1.45,1.62,1.62,0,0,0-1.5-1.48A1.26,1.26,0,0,0,376.58,106.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.22,106.32a1.58,1.58,0,0,0,1.48,1.48,1.29,1.29,0,0,0,1.26-1.46,1.58,1.58,0,0,0-1.49-1.47A1.28,1.28,0,0,0,372.22,106.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.86,106.29a1.56,1.56,0,0,0,1.47,1.47,1.3,1.3,0,0,0,1.27-1.46,1.56,1.56,0,0,0-1.47-1.47A1.31,1.31,0,0,0,367.86,106.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M363.5,106.26a1.52,1.52,0,0,0,1.45,1.47,1.31,1.31,0,0,0,1.29-1.45,1.55,1.55,0,0,0-1.46-1.48A1.32,1.32,0,0,0,363.5,106.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.14,106.25a1.49,1.49,0,0,0,1.43,1.47,1.34,1.34,0,0,0,1.31-1.46,1.51,1.51,0,0,0-1.44-1.47A1.33,1.33,0,0,0,359.14,106.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M354.77,106.25a1.48,1.48,0,0,0,1.42,1.47,1.37,1.37,0,0,0,1.32-1.47,1.48,1.48,0,0,0-1.42-1.46A1.37,1.37,0,0,0,354.77,106.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.4,106.26a1.46,1.46,0,0,0,1.41,1.46,1.39,1.39,0,0,0,1.34-1.47,1.47,1.47,0,0,0-1.41-1.46A1.4,1.4,0,0,0,350.4,106.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346,106.28a1.44,1.44,0,0,0,1.39,1.46,1.41,1.41,0,0,0,1.35-1.47,1.44,1.44,0,0,0-1.39-1.46A1.4,1.4,0,0,0,346,106.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,106.31a1.37,1.37,0,1,0,2.74,0,1.42,1.42,0,0,0-1.37-1.46A1.44,1.44,0,0,0,341.67,106.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.3,106.35a1.38,1.38,0,0,0,1.36,1.45,1.45,1.45,0,0,0,1.38-1.48,1.38,1.38,0,0,0-1.35-1.45A1.46,1.46,0,0,0,337.3,106.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M332.93,106.4a1.37,1.37,0,0,0,1.35,1.45,1.48,1.48,0,0,0,1.4-1.48,1.38,1.38,0,0,0-1.35-1.45A1.48,1.48,0,0,0,332.93,106.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M328.56,106.46a1.35,1.35,0,0,0,1.33,1.45,1.5,1.5,0,0,0,1.42-1.49A1.35,1.35,0,0,0,330,105,1.51,1.51,0,0,0,328.56,106.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M324.2,106.53a1.33,1.33,0,0,0,1.31,1.45,1.52,1.52,0,0,0,1.43-1.49,1.32,1.32,0,0,0-1.31-1.44A1.52,1.52,0,0,0,324.2,106.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M319.83,106.62a1.31,1.31,0,0,0,1.3,1.44,1.55,1.55,0,0,0,1.44-1.5,1.3,1.3,0,0,0-1.29-1.43A1.55,1.55,0,0,0,319.83,106.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M315.46,106.71a1.28,1.28,0,0,0,1.28,1.43,1.57,1.57,0,0,0,1.47-1.49,1.29,1.29,0,0,0-1.28-1.43A1.58,1.58,0,0,0,315.46,106.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M311.1,106.81a1.26,1.26,0,0,0,1.26,1.43,1.58,1.58,0,0,0,1.48-1.49,1.28,1.28,0,0,0-1.26-1.44A1.61,1.61,0,0,0,311.1,106.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M306.74,106.93a1.23,1.23,0,0,0,1.24,1.42,1.63,1.63,0,0,0,1.5-1.5,1.25,1.25,0,0,0-1.25-1.43A1.65,1.65,0,0,0,306.74,106.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.09,115.35c-.33.81,0,1.41.71,1.34a2.64,2.64,0,0,0,1.89-1.6c.32-.81,0-1.41-.71-1.34A2.63,2.63,0,0,0,174.09,115.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.94,115.77c-.33.81,0,1.4.7,1.33a2.63,2.63,0,0,0,1.9-1.6c.33-.81,0-1.41-.69-1.33A2.7,2.7,0,0,0,169.94,115.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M165.82,116.19c-.35.81,0,1.41.68,1.34a2.73,2.73,0,0,0,1.91-1.61c.33-.81,0-1.4-.68-1.33A2.72,2.72,0,0,0,165.82,116.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.7,116.63c-.35.81,0,1.4.66,1.33a2.73,2.73,0,0,0,1.92-1.61c.35-.81.05-1.4-.66-1.33A2.79,2.79,0,0,0,161.7,116.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M157.59,117.07c-.35.81-.06,1.41.65,1.33a2.78,2.78,0,0,0,1.93-1.61c.35-.81.06-1.4-.65-1.33A2.84,2.84,0,0,0,157.59,117.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M153.5,117.52c-.36.81-.08,1.41.63,1.33a2.82,2.82,0,0,0,1.94-1.61c.36-.81.08-1.41-.63-1.33A2.88,2.88,0,0,0,153.5,117.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M149.43,118c-.37.81-.1,1.4.61,1.32a2.87,2.87,0,0,0,2-1.61c.36-.81.09-1.4-.62-1.32A2.88,2.88,0,0,0,149.43,118Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M145.36,118.45c-.38.81-.11,1.4.6,1.32a2.89,2.89,0,0,0,1.95-1.62c.38-.81.11-1.4-.59-1.32A2.92,2.92,0,0,0,145.36,118.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M141.31,118.92c-.38.81-.12,1.4.58,1.32a2.92,2.92,0,0,0,2-1.62c.38-.81.12-1.4-.58-1.32A3,3,0,0,0,141.31,118.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M137.28,119.4c-.4.81-.14,1.4.56,1.32a3,3,0,0,0,2-1.62c.38-.81.13-1.4-.56-1.32A3,3,0,0,0,137.28,119.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M133.25,119.89c-.39.81-.15,1.4.55,1.31a3,3,0,0,0,2-1.62c.39-.81.15-1.4-.55-1.31A3,3,0,0,0,133.25,119.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M129.25,120.39c-.41.81-.17,1.39.53,1.31a3.09,3.09,0,0,0,2-1.63c.4-.81.16-1.39-.53-1.31A3.09,3.09,0,0,0,129.25,120.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M125.26,120.89c-.42.81-.19,1.39.51,1.31a3.13,3.13,0,0,0,2-1.63c.41-.81.18-1.39-.51-1.31A3.13,3.13,0,0,0,125.26,120.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M121.28,121.4c-.42.81-.2,1.39.5,1.3a3.08,3.08,0,0,0,2-1.62c.42-.81.2-1.4-.49-1.31A3.17,3.17,0,0,0,121.28,121.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M117.32,121.92c-.42.8-.21,1.39.48,1.3a3.14,3.14,0,0,0,2-1.63c.42-.81.21-1.39-.48-1.3A3.19,3.19,0,0,0,117.32,121.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M113.37,122.44c-.43.81-.22,1.39.47,1.3a3.23,3.23,0,0,0,2-1.63c.43-.81.22-1.39-.46-1.3A3.25,3.25,0,0,0,113.37,122.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M109.45,123c-.44.81-.24,1.39.44,1.3a3.28,3.28,0,0,0,2-1.64c.43-.81.23-1.39-.44-1.29A3.25,3.25,0,0,0,109.45,123Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M105.54,123.5c-.45.81-.25,1.39.43,1.3a3.27,3.27,0,0,0,2-1.63c.44-.81.25-1.39-.43-1.3A3.27,3.27,0,0,0,105.54,123.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M101.64,124.05c-.45.8-.26,1.38.42,1.29a3.36,3.36,0,0,0,2-1.64c.44-.8.25-1.38-.42-1.29A3.36,3.36,0,0,0,101.64,124.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M97.77,124.59c-.46.81-.28,1.39.39,1.3a3.39,3.39,0,0,0,2-1.64c.45-.81.27-1.39-.4-1.29A3.38,3.38,0,0,0,97.77,124.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M611.82,120.14a3.65,3.65,0,0,0,2.06,1.63c.64.09.74-.49.24-1.29a3.7,3.7,0,0,0-2.07-1.63C611.42,118.75,611.32,119.33,611.82,120.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M608.15,119.61a3.66,3.66,0,0,0,2.06,1.63c.64.09.75-.49.25-1.3a3.73,3.73,0,0,0-2.06-1.63C607.76,118.22,607.65,118.8,608.15,119.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M604.46,119.08a3.56,3.56,0,0,0,2.06,1.63c.64.09.76-.49.26-1.3a3.66,3.66,0,0,0-2.06-1.63C604.08,117.69,604,118.27,604.46,119.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M600.75,118.56a3.57,3.57,0,0,0,2,1.63c.65.09.77-.5.28-1.3a3.57,3.57,0,0,0-2.05-1.63C600.39,117.17,600.26,117.75,600.75,118.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M597,118a3.54,3.54,0,0,0,2.05,1.63c.65.09.78-.49.3-1.3a3.57,3.57,0,0,0-2-1.63C596.67,116.65,596.54,117.24,597,118Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M593.28,117.53a3.5,3.5,0,0,0,2,1.63c.66.09.79-.5.31-1.31a3.55,3.55,0,0,0-2-1.62C592.94,116.14,592.8,116.73,593.28,117.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M589.51,117a3.43,3.43,0,0,0,2,1.62c.65.09.8-.5.32-1.3a3.5,3.5,0,0,0-2-1.63C589.19,115.64,589,116.22,589.51,117Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M585.73,116.53a3.39,3.39,0,0,0,2,1.62c.66.09.81-.5.34-1.31a3.46,3.46,0,0,0-2-1.62C585.42,115.14,585.27,115.72,585.73,116.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M581.94,116a3.38,3.38,0,0,0,2,1.62c.66.08.82-.5.35-1.31a3.38,3.38,0,0,0-2-1.62C581.63,114.65,581.47,115.23,581.94,116Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M578.12,115.56a3.33,3.33,0,0,0,2,1.61c.66.09.83-.5.37-1.31a3.38,3.38,0,0,0-2-1.62C577.83,114.16,577.67,114.75,578.12,115.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574.29,115.08a3.25,3.25,0,0,0,2,1.61c.67.08.84-.51.39-1.31a3.33,3.33,0,0,0-2-1.62C574,113.68,573.84,114.27,574.29,115.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M554.89,112.78a3.11,3.11,0,0,0,2,1.61c.68.08.89-.52.47-1.33a3.06,3.06,0,0,0-2-1.6C554.68,111.38,554.47,112,554.89,112.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551,112.35a3.05,3.05,0,0,0,2,1.6c.69.07.91-.52.49-1.33a3,3,0,0,0-2-1.6C550.77,110.94,550.55,111.54,551,112.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547,111.92a3,3,0,0,0,2,1.6c.68.07.91-.53.5-1.33a3,3,0,0,0-2-1.6C546.84,110.51,546.62,111.11,547,111.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.07,111.49a2.93,2.93,0,0,0,2,1.6c.69.07.93-.52.52-1.33a3,3,0,0,0-2-1.6C542.9,110.09,542.67,110.68,543.07,111.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.1,111.08a2.9,2.9,0,0,0,1.95,1.59c.7.08.94-.52.54-1.33a3,3,0,0,0-2-1.6C539,109.67,538.71,110.27,539.1,111.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.11,110.67a2.9,2.9,0,0,0,1.95,1.59c.7.08,1-.52.56-1.33a2.91,2.91,0,0,0-2-1.6C535,109.26,534.73,109.86,535.11,110.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531.12,110.27a2.82,2.82,0,0,0,1.94,1.59c.69.07,1-.53.57-1.34a2.86,2.86,0,0,0-1.94-1.59C531,108.86,530.74,109.46,531.12,110.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M527.11,109.88a2.79,2.79,0,0,0,1.93,1.59c.7.07,1-.54.59-1.34a2.84,2.84,0,0,0-1.94-1.59C527,108.47,526.74,109.07,527.11,109.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M523.08,109.5a2.73,2.73,0,0,0,1.92,1.58c.71.07,1-.53.61-1.34a2.81,2.81,0,0,0-1.92-1.59C523,108.09,522.72,108.69,523.08,109.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M519,109.12A2.71,2.71,0,0,0,521,110.7c.7.07,1-.53.62-1.34a2.78,2.78,0,0,0-1.91-1.59C519,107.71,518.69,108.31,519,109.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M515,108.75a2.68,2.68,0,0,0,1.91,1.58c.7.07,1-.54.64-1.35a2.75,2.75,0,0,0-1.91-1.58C514.94,107.34,514.65,108,515,108.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510.93,108.4a2.66,2.66,0,0,0,1.9,1.57c.71.06,1-.54.66-1.35a2.71,2.71,0,0,0-1.9-1.58C510.89,107,510.59,107.59,510.93,108.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M506.86,108a2.6,2.6,0,0,0,1.88,1.58c.71.06,1-.55.68-1.36a2.64,2.64,0,0,0-1.89-1.57C506.83,106.63,506.53,107.24,506.86,108Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M502.77,107.7a2.57,2.57,0,0,0,1.88,1.57c.71.06,1-.54.69-1.35a2.57,2.57,0,0,0-1.87-1.57C502.76,106.29,502.45,106.89,502.77,107.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M498.68,107.37a2.53,2.53,0,0,0,1.86,1.57c.72.06,1-.55.71-1.36a2.56,2.56,0,0,0-1.86-1.57C498.68,106,498.36,106.56,498.68,107.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M494.57,107.05a2.5,2.5,0,0,0,1.85,1.56c.72.06,1-.55.73-1.36a2.51,2.51,0,0,0-1.85-1.57C494.59,105.63,494.26,106.24,494.57,107.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M490.45,106.73a2.43,2.43,0,0,0,1.84,1.56c.72.06,1-.55.75-1.36a2.5,2.5,0,0,0-1.85-1.57C490.48,105.31,490.15,105.92,490.45,106.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M486.32,106.42a2.4,2.4,0,0,0,1.83,1.56c.72.06,1.06-.56.77-1.37a2.47,2.47,0,0,0-1.84-1.56C486.37,105,486,105.61,486.32,106.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M482.18,106.12a2.39,2.39,0,0,0,1.82,1.56c.72,0,1.07-.56.78-1.37a2.41,2.41,0,0,0-1.82-1.56C482.24,104.7,481.9,105.32,482.18,106.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478,105.84a2.37,2.37,0,0,0,1.81,1.55c.72,0,1.08-.57.8-1.37a2.38,2.38,0,0,0-1.81-1.56C478.11,104.41,477.75,105,478,105.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M473.87,105.56a2.32,2.32,0,0,0,1.8,1.55c.72,0,1.09-.57.81-1.38a2.32,2.32,0,0,0-1.79-1.55C474,104.13,473.6,104.75,473.87,105.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469.7,105.29a2.27,2.27,0,0,0,1.79,1.54c.73,0,1.1-.57.83-1.38a2.28,2.28,0,0,0-1.79-1.54C469.81,103.86,469.44,104.48,469.7,105.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.52,105a2.27,2.27,0,0,0,1.78,1.55c.73,0,1.11-.57.85-1.38a2.27,2.27,0,0,0-1.78-1.55C465.65,103.6,465.27,104.22,465.52,105Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M461.34,104.77a2.19,2.19,0,0,0,1.76,1.54c.73,0,1.12-.57.87-1.38a2.21,2.21,0,0,0-1.77-1.54C461.48,103.35,461.09,104,461.34,104.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M457.14,104.53a2.17,2.17,0,0,0,1.75,1.54c.73,0,1.13-.58.89-1.39a2.2,2.2,0,0,0-1.75-1.54C457.3,103.1,456.91,103.72,457.14,104.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M452.94,104.3a2.13,2.13,0,0,0,1.73,1.53c.74,0,1.14-.58.91-1.39a2.14,2.14,0,0,0-1.74-1.53C453.11,102.87,452.71,103.49,452.94,104.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M448.73,104.07a2.1,2.1,0,0,0,1.72,1.54c.73,0,1.15-.59.92-1.4a2.13,2.13,0,0,0-1.72-1.53C448.92,102.64,448.51,103.27,448.73,104.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M444.51,103.86a2.06,2.06,0,0,0,1.71,1.53c.73,0,1.15-.59.94-1.4a2.09,2.09,0,0,0-1.72-1.52C444.71,102.43,444.3,103.05,444.51,103.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M440.28,103.66a2,2,0,0,0,1.7,1.52c.73,0,1.16-.59.95-1.4a2,2,0,0,0-1.69-1.52C440.5,102.22,440.08,102.85,440.28,103.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M436,103.46a2,2,0,0,0,1.69,1.52c.74,0,1.17-.59,1-1.4a2,2,0,0,0-1.68-1.52C436.29,102,435.85,102.65,436,103.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M431.8,103.28a2,2,0,0,0,1.68,1.52c.73,0,1.18-.6,1-1.41a2,2,0,0,0-1.68-1.52C432.06,101.84,431.62,102.47,431.8,103.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427.56,103.1a1.93,1.93,0,0,0,1.65,1.52,1,1,0,0,0,1-1.41,1.94,1.94,0,0,0-1.66-1.51A1,1,0,0,0,427.56,103.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419,102.78a1.87,1.87,0,0,0,1.63,1.51,1.07,1.07,0,0,0,1.05-1.41,1.9,1.9,0,0,0-1.63-1.51A1.07,1.07,0,0,0,419,102.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.78,102.64a1.85,1.85,0,0,0,1.61,1.51,1.09,1.09,0,0,0,1.07-1.42,1.87,1.87,0,0,0-1.62-1.51A1.09,1.09,0,0,0,414.78,102.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M410.5,102.5a1.84,1.84,0,0,0,1.61,1.51,1.11,1.11,0,0,0,1.08-1.42,1.83,1.83,0,0,0-1.61-1.5A1.1,1.1,0,0,0,410.5,102.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.23,102.38a1.78,1.78,0,0,0,1.59,1.5,1.13,1.13,0,0,0,1.1-1.42,1.81,1.81,0,0,0-1.59-1.5A1.12,1.12,0,0,0,406.23,102.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402,102.27a1.76,1.76,0,0,0,1.57,1.49,1.13,1.13,0,0,0,1.12-1.42,1.78,1.78,0,0,0-1.58-1.5A1.14,1.14,0,0,0,402,102.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397.66,102.16a1.73,1.73,0,0,0,1.56,1.49,1.14,1.14,0,0,0,1.13-1.42,1.75,1.75,0,0,0-1.56-1.5A1.16,1.16,0,0,0,397.66,102.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.37,102.07a1.72,1.72,0,0,0,1.55,1.49,1.17,1.17,0,0,0,1.15-1.43,1.72,1.72,0,0,0-1.55-1.49A1.17,1.17,0,0,0,393.37,102.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.08,102a1.67,1.67,0,0,0,1.53,1.49,1.19,1.19,0,0,0,1.17-1.44,1.69,1.69,0,0,0-1.53-1.48A1.19,1.19,0,0,0,389.08,102Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384.78,101.91a1.64,1.64,0,0,0,1.52,1.48,1.2,1.2,0,0,0,1.18-1.44,1.66,1.66,0,0,0-1.51-1.48A1.21,1.21,0,0,0,384.78,101.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.48,101.85a1.63,1.63,0,0,0,1.5,1.48,1.22,1.22,0,0,0,1.2-1.44,1.63,1.63,0,0,0-1.5-1.48A1.23,1.23,0,0,0,380.48,101.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.18,101.79a1.59,1.59,0,0,0,1.48,1.48,1.24,1.24,0,0,0,1.22-1.44,1.61,1.61,0,0,0-1.48-1.48A1.25,1.25,0,0,0,376.18,101.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.87,101.75a1.56,1.56,0,0,0,1.47,1.47,1.27,1.27,0,0,0,1.24-1.44,1.59,1.59,0,0,0-1.47-1.48A1.28,1.28,0,0,0,371.87,101.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.56,101.72a1.55,1.55,0,0,0,1.46,1.47,1.29,1.29,0,0,0,1.25-1.45,1.55,1.55,0,0,0-1.46-1.47A1.29,1.29,0,0,0,367.56,101.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M363.25,101.7a1.51,1.51,0,0,0,1.44,1.46,1.3,1.3,0,0,0,1.27-1.45,1.54,1.54,0,0,0-1.44-1.47A1.32,1.32,0,0,0,363.25,101.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M358.94,101.69a1.49,1.49,0,0,0,1.42,1.46,1.33,1.33,0,0,0,1.29-1.46,1.5,1.5,0,0,0-1.43-1.46A1.33,1.33,0,0,0,358.94,101.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M354.62,101.68a1.47,1.47,0,0,0,1.41,1.46,1.34,1.34,0,0,0,1.3-1.46,1.47,1.47,0,0,0-1.4-1.45A1.35,1.35,0,0,0,354.62,101.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.31,101.69a1.44,1.44,0,0,0,1.39,1.46,1.37,1.37,0,0,0,1.32-1.46,1.46,1.46,0,0,0-1.39-1.46A1.37,1.37,0,0,0,350.31,101.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346,101.71a1.43,1.43,0,0,0,1.38,1.46,1.39,1.39,0,0,0,1.33-1.47,1.42,1.42,0,0,0-1.37-1.45A1.39,1.39,0,0,0,346,101.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.67,101.74a1.4,1.4,0,0,0,1.36,1.45,1.46,1.46,0,0,0,0-2.92A1.43,1.43,0,0,0,341.67,101.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.36,101.78a1.37,1.37,0,0,0,1.34,1.45,1.45,1.45,0,0,0,1.37-1.47,1.39,1.39,0,0,0-1.35-1.45A1.45,1.45,0,0,0,337.36,101.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M333,101.84a1.35,1.35,0,0,0,1.32,1.44,1.46,1.46,0,0,0,1.39-1.48,1.36,1.36,0,0,0-1.33-1.44A1.47,1.47,0,0,0,333,101.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M328.72,101.9a1.34,1.34,0,0,0,1.31,1.44,1.49,1.49,0,0,0,1.4-1.48,1.34,1.34,0,0,0-1.31-1.44A1.5,1.5,0,0,0,328.72,101.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M324.4,102a1.33,1.33,0,0,0,1.3,1.44,1.51,1.51,0,0,0,1.41-1.49,1.31,1.31,0,0,0-1.29-1.43A1.51,1.51,0,0,0,324.4,102Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M320.09,102.05a1.28,1.28,0,0,0,1.27,1.43A1.53,1.53,0,0,0,322.8,102a1.31,1.31,0,0,0-1.28-1.44A1.54,1.54,0,0,0,320.09,102.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M315.77,102.14a1.27,1.27,0,0,0,1.26,1.43,1.56,1.56,0,0,0,1.45-1.49,1.28,1.28,0,0,0-1.26-1.43A1.59,1.59,0,0,0,315.77,102.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M311.46,102.25a1.24,1.24,0,0,0,1.24,1.42,1.58,1.58,0,0,0,1.47-1.49,1.27,1.27,0,0,0-1.25-1.43A1.6,1.6,0,0,0,311.46,102.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M307.14,102.36a1.23,1.23,0,0,0,1.23,1.42,1.61,1.61,0,0,0,1.48-1.49,1.24,1.24,0,0,0-1.23-1.43A1.64,1.64,0,0,0,307.14,102.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M180.05,110.36c-.32.81,0,1.41.72,1.34a2.59,2.59,0,0,0,1.87-1.59c.32-.81,0-1.41-.71-1.34A2.64,2.64,0,0,0,180.05,110.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.94,110.77c-.33.81,0,1.41.7,1.34a2.62,2.62,0,0,0,1.88-1.6c.33-.81,0-1.4-.69-1.33A2.65,2.65,0,0,0,175.94,110.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.84,111.19c-.33.81,0,1.4.68,1.33a2.67,2.67,0,0,0,1.9-1.59c.33-.81,0-1.41-.68-1.34A2.72,2.72,0,0,0,171.84,111.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.76,111.62c-.35.8,0,1.4.66,1.33a2.72,2.72,0,0,0,1.9-1.6c.34-.81.05-1.41-.66-1.33A2.72,2.72,0,0,0,167.76,111.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M163.68,112.05c-.35.81-.06,1.4.65,1.33a2.76,2.76,0,0,0,1.91-1.6c.35-.81.06-1.41-.64-1.33A2.75,2.75,0,0,0,163.68,112.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.62,112.49c-.36.81-.08,1.41.63,1.33a2.83,2.83,0,0,0,1.92-1.61c.35-.8.07-1.4-.63-1.32A2.81,2.81,0,0,0,159.62,112.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.57,112.94c-.37.81-.1,1.41.61,1.33a2.84,2.84,0,0,0,1.93-1.61c.36-.81.09-1.4-.61-1.32A2.85,2.85,0,0,0,155.57,112.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M151.53,113.4c-.38.81-.11,1.4.59,1.32a2.86,2.86,0,0,0,1.94-1.61c.37-.8.11-1.4-.59-1.32A2.88,2.88,0,0,0,151.53,113.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M147.5,113.87c-.38.81-.12,1.4.58,1.32a2.94,2.94,0,0,0,1.95-1.61c.38-.81.12-1.4-.58-1.32A3,3,0,0,0,147.5,113.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M143.49,114.34c-.39.81-.13,1.4.56,1.32a3,3,0,0,0,2-1.61c.38-.81.13-1.4-.56-1.32A3,3,0,0,0,143.49,114.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M139.49,114.83c-.39.8-.15,1.39.55,1.31a3,3,0,0,0,2-1.62c.39-.81.15-1.39-.54-1.31A3,3,0,0,0,139.49,114.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M135.51,115.31c-.4.81-.16,1.4.53,1.31a3,3,0,0,0,2-1.61c.4-.81.16-1.4-.53-1.31A3.06,3.06,0,0,0,135.51,115.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M131.54,115.81c-.41.81-.18,1.39.51,1.31a3.07,3.07,0,0,0,2-1.62c.41-.81.18-1.4-.51-1.31A3.12,3.12,0,0,0,131.54,115.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M127.59,116.31c-.42.81-.2,1.4.49,1.31a3.11,3.11,0,0,0,2-1.62c.41-.81.19-1.4-.5-1.31A3.12,3.12,0,0,0,127.59,116.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M123.65,116.82c-.42.81-.21,1.4.48,1.31a3.18,3.18,0,0,0,2-1.63c.42-.81.2-1.39-.48-1.3A3.19,3.19,0,0,0,123.65,116.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M119.72,117.34c-.42.81-.22,1.39.46,1.3a3.22,3.22,0,0,0,2-1.62c.42-.81.21-1.4-.47-1.31A3.24,3.24,0,0,0,119.72,117.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M115.81,117.86c-.43.81-.23,1.39.45,1.3a3.25,3.25,0,0,0,2-1.62c.43-.81.23-1.39-.45-1.3A3.3,3.3,0,0,0,115.81,117.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M111.92,118.4c-.44.8-.25,1.38.43,1.29a3.23,3.23,0,0,0,2-1.63c.44-.81.25-1.39-.43-1.3A3.34,3.34,0,0,0,111.92,118.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M108,118.93c-.44.81-.26,1.39.42,1.3a3.35,3.35,0,0,0,2-1.64c.44-.8.25-1.38-.42-1.29A3.33,3.33,0,0,0,108,118.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M104.18,119.48c-.45.8-.27,1.38.4,1.29a3.4,3.4,0,0,0,2-1.64c.44-.8.27-1.38-.4-1.29A3.4,3.4,0,0,0,104.18,119.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M100.34,120c-.46.81-.29,1.39.38,1.29a3.34,3.34,0,0,0,2-1.63c.45-.81.28-1.39-.38-1.29A3.47,3.47,0,0,0,100.34,120Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M96.51,120.58c-.46.81-.3,1.38.37,1.29a3.43,3.43,0,0,0,2-1.64c.46-.81.3-1.38-.36-1.29A3.5,3.5,0,0,0,96.51,120.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M92.7,121.14c-.47.81-.31,1.38.35,1.29a3.44,3.44,0,0,0,2-1.64c.47-.81.31-1.38-.35-1.29A3.53,3.53,0,0,0,92.7,121.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M612.58,116.12a3.83,3.83,0,0,0,2.07,1.63c.63.09.72-.48.2-1.29a3.72,3.72,0,0,0-2.06-1.63C612.16,114.74,612.07,115.31,612.58,116.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M609,115.58a3.68,3.68,0,0,0,2.06,1.63c.63.09.73-.49.22-1.29a3.68,3.68,0,0,0-2.06-1.63C608.55,114.2,608.45,114.77,609,115.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M605.32,115.05a3.66,3.66,0,0,0,2,1.62c.64.1.74-.48.24-1.29a3.73,3.73,0,0,0-2.06-1.63C604.92,113.66,604.82,114.24,605.32,115.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M601.66,114.52a3.59,3.59,0,0,0,2.05,1.62c.64.1.75-.48.25-1.29a3.66,3.66,0,0,0-2.06-1.63C601.27,113.13,601.16,113.71,601.66,114.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M594.28,113.48a3.52,3.52,0,0,0,2.05,1.62c.64.09.76-.49.27-1.3a3.58,3.58,0,0,0-2-1.62C593.92,112.09,593.79,112.67,594.28,113.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M586.83,112.46a3.44,3.44,0,0,0,2,1.62c.65.09.79-.49.31-1.3a3.48,3.48,0,0,0-2-1.62C586.5,111.07,586.36,111.66,586.83,112.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M583.09,112a3.37,3.37,0,0,0,2,1.62c.65.09.8-.5.32-1.3a3.43,3.43,0,0,0-2-1.62C582.76,110.57,582.62,111.16,583.09,112Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M575.54,111a3.34,3.34,0,0,0,2,1.61c.66.09.82-.5.36-1.31a3.36,3.36,0,0,0-2-1.61C575.24,109.59,575.08,110.18,575.54,111Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M567.93,110a3.27,3.27,0,0,0,2,1.62c.66.08.84-.51.39-1.32a3.26,3.26,0,0,0-2-1.61C567.66,108.64,567.48,109.23,567.93,110Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.1,109.57a3.22,3.22,0,0,0,2,1.61c.67.08.85-.51.41-1.32a3.26,3.26,0,0,0-2-1.61C563.84,108.17,563.66,108.76,564.1,109.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M556.4,108.66a3.09,3.09,0,0,0,2,1.6c.67.08.86-.51.43-1.32a3.14,3.14,0,0,0-2-1.6C556.17,107.26,556,107.85,556.4,108.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M552.52,108.21a3.12,3.12,0,0,0,2,1.61c.68.07.88-.52.46-1.33a3.14,3.14,0,0,0-2-1.6C552.3,106.81,552.1,107.41,552.52,108.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M548.63,107.78a3.11,3.11,0,0,0,2,1.6c.68.07.89-.52.47-1.33a3.1,3.1,0,0,0-2-1.6C548.43,106.38,548.22,107,548.63,107.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M544.73,107.35a3,3,0,0,0,2,1.59c.68.08.89-.52.48-1.32a3,3,0,0,0-2-1.6C544.54,106,544.32,106.54,544.73,107.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540.81,106.92a3,3,0,0,0,2,1.6c.68.07.91-.52.5-1.33a3,3,0,0,0-1.95-1.6C540.64,105.52,540.41,106.12,540.81,106.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.88,106.51a2.94,2.94,0,0,0,2,1.59c.69.07.92-.52.52-1.33a2.92,2.92,0,0,0-2-1.59C536.72,105.11,536.49,105.7,536.88,106.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M532.94,106.1a2.86,2.86,0,0,0,1.94,1.59c.69.07.93-.53.54-1.33a2.9,2.9,0,0,0-1.95-1.59C532.79,104.7,532.55,105.29,532.94,106.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529,105.7a2.87,2.87,0,0,0,1.93,1.59c.69.07.94-.53.56-1.34a2.89,2.89,0,0,0-1.94-1.58C528.85,104.3,528.6,104.9,529,105.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525,105.31a2.81,2.81,0,0,0,1.93,1.59c.69.06.94-.54.57-1.34a2.85,2.85,0,0,0-1.93-1.59C524.89,103.91,524.64,104.51,525,105.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M521,104.93a2.76,2.76,0,0,0,1.91,1.58c.7.07,1-.53.59-1.34a2.77,2.77,0,0,0-1.92-1.58C520.93,103.52,520.66,104.12,521,104.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M517,104.55a2.72,2.72,0,0,0,1.91,1.58c.7.07,1-.53.6-1.34a2.72,2.72,0,0,0-1.9-1.58C517,103.15,516.68,103.75,517,104.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M513,104.19a2.69,2.69,0,0,0,1.9,1.57c.7.07,1-.54.62-1.34a2.69,2.69,0,0,0-1.89-1.58C513,102.78,512.68,103.38,513,104.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M509,103.83a2.62,2.62,0,0,0,1.88,1.57c.7.06,1-.54.64-1.35a2.68,2.68,0,0,0-1.89-1.57C509,102.42,508.66,103,509,103.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M505,103.48a2.62,2.62,0,0,0,1.88,1.57c.7.06,1-.55.66-1.35a2.62,2.62,0,0,0-1.88-1.57C504.93,102.07,504.64,102.67,505,103.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M500.93,103.14a2.56,2.56,0,0,0,1.87,1.56c.7.06,1-.54.67-1.35a2.59,2.59,0,0,0-1.86-1.57C500.91,101.73,500.61,102.33,500.93,103.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M496.88,102.8a2.54,2.54,0,0,0,1.86,1.57c.7.06,1-.55.69-1.36a2.58,2.58,0,0,0-1.86-1.56C496.87,101.39,496.56,102,496.88,102.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M492.82,102.48a2.46,2.46,0,0,0,1.84,1.56c.71.06,1-.55.71-1.36a2.54,2.54,0,0,0-1.85-1.56C492.82,101.07,492.5,101.67,492.82,102.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M488.74,102.17a2.45,2.45,0,0,0,1.84,1.55c.7.06,1-.55.72-1.36a2.47,2.47,0,0,0-1.83-1.55C488.76,100.75,488.44,101.36,488.74,102.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M484.66,101.86a2.39,2.39,0,0,0,1.82,1.55c.71.06,1-.55.74-1.36a2.42,2.42,0,0,0-1.82-1.55C484.69,100.44,484.36,101.05,484.66,101.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M480.56,101.56a2.38,2.38,0,0,0,1.81,1.55c.72.06,1.06-.56.76-1.36a2.38,2.38,0,0,0-1.81-1.55C480.61,100.15,480.27,100.76,480.56,101.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.46,101.27a2.34,2.34,0,0,0,1.8,1.55c.71.05,1.06-.56.78-1.37a2.39,2.39,0,0,0-1.8-1.54C476.53,99.86,476.18,100.47,476.46,101.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.34,101a2.31,2.31,0,0,0,1.79,1.55c.72,0,1.08-.57.8-1.37a2.34,2.34,0,0,0-1.79-1.55C472.43,99.58,472.07,100.19,472.34,101Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M468.22,100.73a2.29,2.29,0,0,0,1.78,1.54c.71,0,1.08-.57.81-1.38A2.3,2.3,0,0,0,469,99.35C468.32,99.31,468,99.92,468.22,100.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M464.09,100.46a2.21,2.21,0,0,0,1.76,1.54c.72,0,1.1-.57.84-1.37a2.27,2.27,0,0,0-1.77-1.54C464.21,99,463.83,99.66,464.09,100.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M460,100.21a2.2,2.2,0,0,0,1.75,1.54c.72,0,1.1-.58.85-1.38a2.23,2.23,0,0,0-1.75-1.54C460.08,98.79,459.7,99.41,460,100.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455.8,100a2.16,2.16,0,0,0,1.74,1.53c.72,0,1.11-.57.87-1.38a2.21,2.21,0,0,0-1.74-1.53C456,98.55,455.56,99.17,455.8,100Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M451.64,99.74a2.15,2.15,0,0,0,1.73,1.53c.72,0,1.12-.58.89-1.39a2.18,2.18,0,0,0-1.73-1.53C451.81,98.31,451.41,98.93,451.64,99.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M447.48,99.52a2.09,2.09,0,0,0,1.71,1.52c.73,0,1.13-.58.91-1.38a2.13,2.13,0,0,0-1.72-1.53C447.66,98.09,447.26,98.71,447.48,99.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.31,99.3a2.06,2.06,0,0,0,1.7,1.53c.73,0,1.14-.59.92-1.39a2.09,2.09,0,0,0-1.7-1.53C443.51,97.88,443.1,98.5,443.31,99.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M439.13,99.1a2,2,0,0,0,1.69,1.52c.72,0,1.14-.59.93-1.39a2,2,0,0,0-1.68-1.52C439.35,97.67,438.92,98.3,439.13,99.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.94,98.91a2,2,0,0,0,1.68,1.51c.73,0,1.15-.59.95-1.39a2,2,0,0,0-1.67-1.52C435.18,97.48,434.75,98.1,434.94,98.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.75,98.72a2,2,0,0,0,1.66,1.52c.73,0,1.17-.6,1-1.4a2,2,0,0,0-1.66-1.52C431,97.29,430.56,97.92,430.75,98.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M426.55,98.55a2,2,0,0,0,1.65,1.51c.73,0,1.17-.6,1-1.4a1.94,1.94,0,0,0-1.65-1.51C426.82,97.12,426.37,97.74,426.55,98.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422.35,98.38A1.9,1.9,0,0,0,424,99.89a1,1,0,0,0,1-1.4A1.93,1.93,0,0,0,423.36,97,1,1,0,0,0,422.35,98.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.14,98.23a1.86,1.86,0,0,0,1.62,1.5,1,1,0,0,0,1-1.4,1.88,1.88,0,0,0-1.62-1.51A1.07,1.07,0,0,0,418.14,98.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.92,98.09a1.85,1.85,0,0,0,1.61,1.5,1.07,1.07,0,0,0,1-1.41,1.85,1.85,0,0,0-1.61-1.5A1.08,1.08,0,0,0,413.92,98.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.7,98a1.8,1.8,0,0,0,1.59,1.5A1.09,1.09,0,0,0,412.35,98a1.82,1.82,0,0,0-1.59-1.5A1.09,1.09,0,0,0,409.7,98Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M405.47,97.83a1.79,1.79,0,0,0,1.58,1.49,1.11,1.11,0,0,0,1.08-1.42,1.8,1.8,0,0,0-1.58-1.49A1.11,1.11,0,0,0,405.47,97.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.24,97.71a1.75,1.75,0,0,0,1.56,1.49,1.12,1.12,0,0,0,1.1-1.42,1.77,1.77,0,0,0-1.56-1.49A1.14,1.14,0,0,0,401.24,97.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397,97.61a1.73,1.73,0,0,0,1.54,1.49,1.15,1.15,0,0,0,1.12-1.43,1.74,1.74,0,0,0-1.55-1.48A1.14,1.14,0,0,0,397,97.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.77,97.52A1.69,1.69,0,0,0,394.3,99a1.16,1.16,0,0,0,1.13-1.43,1.71,1.71,0,0,0-1.53-1.48A1.17,1.17,0,0,0,392.77,97.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.52,97.43A1.66,1.66,0,0,0,390,98.91a1.17,1.17,0,0,0,1.15-1.43A1.68,1.68,0,0,0,389.67,96,1.19,1.19,0,0,0,388.52,97.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380,97.3a1.61,1.61,0,0,0,1.48,1.47,1.22,1.22,0,0,0,1.19-1.43,1.62,1.62,0,0,0-1.49-1.48A1.22,1.22,0,0,0,380,97.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M375.77,97.24a1.58,1.58,0,0,0,1.48,1.47,1.23,1.23,0,0,0,1.2-1.43A1.6,1.6,0,0,0,377,95.81,1.24,1.24,0,0,0,375.77,97.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.52,97.2A1.56,1.56,0,0,0,373,98.67a1.25,1.25,0,0,0,1.22-1.44,1.57,1.57,0,0,0-1.45-1.47A1.26,1.26,0,0,0,371.52,97.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.26,97.17a1.53,1.53,0,0,0,1.44,1.46,1.27,1.27,0,0,0,1.24-1.44,1.54,1.54,0,0,0-1.44-1.46A1.28,1.28,0,0,0,367.26,97.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M354.48,97.14a1.45,1.45,0,0,0,1.39,1.45,1.33,1.33,0,0,0,1.28-1.45,1.46,1.46,0,0,0-1.39-1.46A1.34,1.34,0,0,0,354.48,97.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M350.21,97.15a1.44,1.44,0,0,0,1.38,1.45,1.37,1.37,0,0,0,1.3-1.46,1.45,1.45,0,0,0-1.38-1.45A1.36,1.36,0,0,0,350.21,97.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M346,97.17a1.39,1.39,0,0,0,1.35,1.44,1.37,1.37,0,0,0,1.32-1.46,1.42,1.42,0,0,0-1.36-1.45A1.39,1.39,0,0,0,346,97.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.68,97.2A1.39,1.39,0,0,0,343,98.64a1.46,1.46,0,1,0-1.34-1.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.41,97.24a1.38,1.38,0,0,0,1.33,1.44,1.43,1.43,0,0,0,1.35-1.47,1.37,1.37,0,0,0-1.32-1.44A1.44,1.44,0,0,0,337.41,97.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M333.14,97.29a1.35,1.35,0,0,0,1.32,1.43,1.43,1.43,0,0,0,1.36-1.47,1.34,1.34,0,0,0-1.31-1.43A1.46,1.46,0,0,0,333.14,97.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M328.88,97.35a1.32,1.32,0,0,0,1.29,1.43,1.47,1.47,0,0,0,1.39-1.47,1.33,1.33,0,0,0-1.3-1.44A1.49,1.49,0,0,0,328.88,97.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M324.61,97.42a1.3,1.3,0,0,0,1.28,1.43,1.5,1.5,0,0,0,1.4-1.48A1.31,1.31,0,0,0,326,95.94,1.53,1.53,0,0,0,324.61,97.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M320.35,97.5a1.28,1.28,0,0,0,1.26,1.43A1.54,1.54,0,0,0,323,97.45,1.3,1.3,0,0,0,321.76,96,1.54,1.54,0,0,0,320.35,97.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M316.08,97.59A1.26,1.26,0,0,0,317.33,99a1.56,1.56,0,0,0,1.43-1.49,1.26,1.26,0,0,0-1.24-1.42A1.57,1.57,0,0,0,316.08,97.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M311.82,97.7a1.23,1.23,0,0,0,1.23,1.42,1.59,1.59,0,0,0,1.45-1.49,1.25,1.25,0,0,0-1.23-1.42A1.58,1.58,0,0,0,311.82,97.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.89,105.79c-.33.81,0,1.41.69,1.34a2.61,2.61,0,0,0,1.87-1.59c.32-.81,0-1.4-.69-1.33A2.63,2.63,0,0,0,181.89,105.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.82,106.2c-.33.81,0,1.41.68,1.34a2.68,2.68,0,0,0,1.88-1.59c.32-.81,0-1.41-.68-1.34A2.7,2.7,0,0,0,177.82,106.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.76,106.62c-.34.81,0,1.4.66,1.33a2.65,2.65,0,0,0,1.89-1.59c.34-.81,0-1.4-.66-1.33A2.73,2.73,0,0,0,173.76,106.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.72,107.05c-.35.81-.06,1.4.64,1.33a2.72,2.72,0,0,0,1.9-1.6c.34-.81.05-1.4-.64-1.33A2.76,2.76,0,0,0,169.72,107.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M165.68,107.48c-.35.81-.07,1.4.63,1.33a2.78,2.78,0,0,0,1.91-1.6c.35-.81.07-1.4-.63-1.32A2.78,2.78,0,0,0,165.68,107.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.66,107.93c-.36.8-.09,1.39.61,1.32a2.84,2.84,0,0,0,1.92-1.6c.35-.81.08-1.4-.61-1.32A2.79,2.79,0,0,0,161.66,107.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M157.65,108.38c-.37.8-.1,1.39.59,1.32a2.86,2.86,0,0,0,1.93-1.61c.36-.8.1-1.4-.59-1.32A2.86,2.86,0,0,0,157.65,108.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M153.65,108.83c-.38.81-.12,1.4.58,1.32a2.89,2.89,0,0,0,1.93-1.6c.37-.81.12-1.4-.57-1.32A2.93,2.93,0,0,0,153.65,108.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M149.67,109.3c-.39.81-.14,1.4.56,1.32a3,3,0,0,0,1.94-1.61c.38-.81.13-1.4-.56-1.32A2.92,2.92,0,0,0,149.67,109.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M145.69,109.78c-.39.8-.14,1.39.55,1.31a3,3,0,0,0,1.95-1.61c.38-.81.14-1.4-.54-1.32A3,3,0,0,0,145.69,109.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M141.73,110.26c-.39.8-.16,1.39.53,1.31a3,3,0,0,0,2-1.62c.39-.8.16-1.39-.53-1.31A3.06,3.06,0,0,0,141.73,110.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M137.79,110.75c-.41.8-.18,1.39.51,1.3a3,3,0,0,0,2-1.61c.4-.81.17-1.39-.51-1.31A3.06,3.06,0,0,0,137.79,110.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M133.86,111.24c-.42.81-.19,1.39.49,1.31a3.08,3.08,0,0,0,2-1.62c.41-.81.19-1.39-.49-1.31A3.13,3.13,0,0,0,133.86,111.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M129.94,111.75c-.42.8-.21,1.39.48,1.3a3.14,3.14,0,0,0,2-1.62c.41-.81.2-1.39-.48-1.3A3.11,3.11,0,0,0,129.94,111.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M126,112.26c-.43.8-.22,1.39.46,1.3a3.2,3.2,0,0,0,2-1.63c.41-.8.21-1.38-.47-1.3A3.18,3.18,0,0,0,126,112.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M122.15,112.77c-.43.81-.24,1.39.44,1.3a3.2,3.2,0,0,0,2-1.62c.42-.81.22-1.39-.45-1.3A3.24,3.24,0,0,0,122.15,112.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.27,113.3c-.43.81-.24,1.39.43,1.29a3.26,3.26,0,0,0,2-1.62c.44-.81.24-1.39-.42-1.3A3.28,3.28,0,0,0,118.27,113.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M114.41,113.83c-.44.81-.25,1.39.42,1.29a3.28,3.28,0,0,0,2-1.63c.43-.8.25-1.38-.42-1.29A3.34,3.34,0,0,0,114.41,113.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M110.57,114.37c-.45.8-.27,1.38.4,1.29a3.28,3.28,0,0,0,2-1.63c.45-.81.27-1.39-.39-1.29A3.33,3.33,0,0,0,110.57,114.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M106.74,114.91c-.45.81-.28,1.38.39,1.29a3.35,3.35,0,0,0,2-1.63c.45-.81.28-1.39-.39-1.29A3.38,3.38,0,0,0,106.74,114.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M102.93,115.46c-.46.81-.29,1.38.37,1.29a3.41,3.41,0,0,0,2-1.64c.46-.8.3-1.38-.36-1.28A3.4,3.4,0,0,0,102.93,115.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M99.14,116c-.47.8-.31,1.38.35,1.28a3.43,3.43,0,0,0,2-1.63c.46-.81.3-1.38-.35-1.29A3.45,3.45,0,0,0,99.14,116Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M95.36,116.58c-.47.81-.32,1.38.34,1.28a3.49,3.49,0,0,0,2-1.63c.47-.81.32-1.39-.33-1.29A3.5,3.5,0,0,0,95.36,116.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M620.37,113.22a3.91,3.91,0,0,0,2.06,1.63c.62.1.68-.48.15-1.28a3.82,3.82,0,0,0-2.06-1.63C619.91,111.84,619.84,112.41,620.37,113.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.84,108.41a3.48,3.48,0,0,0,2,1.62c.64.09.76-.49.28-1.3a3.54,3.54,0,0,0-2-1.62C587.48,107,587.36,107.6,587.84,108.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M561.61,105a3.22,3.22,0,0,0,2,1.6c.66.08.84-.5.39-1.31a3.23,3.23,0,0,0-2-1.6C561.34,103.62,561.16,104.2,561.61,105Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M550.14,103.65a3.05,3.05,0,0,0,2,1.6c.67.08.87-.51.44-1.32a3.11,3.11,0,0,0-2-1.59C549.91,102.26,549.71,102.85,550.14,103.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M546.29,103.22a3,3,0,0,0,2,1.59c.68.08.88-.51.46-1.32a3.09,3.09,0,0,0-2-1.59C546.07,101.82,545.87,102.41,546.29,103.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M542.42,102.79a3,3,0,0,0,2,1.59c.67.07.88-.52.47-1.32a3,3,0,0,0-2-1.6C542.22,101.39,542,102,542.42,102.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M538.54,102.37a3,3,0,0,0,2,1.59c.68.07.9-.52.49-1.33a3,3,0,0,0-2-1.59C538.36,101,538.14,101.56,538.54,102.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M534.65,102a2.94,2.94,0,0,0,1.94,1.59c.68.07.91-.52.51-1.33a3,3,0,0,0-2-1.59C534.48,100.55,534.26,101.15,534.65,102Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530.75,101.55a2.9,2.9,0,0,0,1.93,1.58c.68.07.91-.53.52-1.33a2.89,2.89,0,0,0-1.94-1.58C530.59,100.15,530.36,100.74,530.75,101.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M526.83,101.15a2.86,2.86,0,0,0,1.93,1.58c.68.07.92-.53.53-1.33a2.89,2.89,0,0,0-1.93-1.59C526.69,99.75,526.45,100.34,526.83,101.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M522.9,100.76a2.82,2.82,0,0,0,1.92,1.58c.68.06.93-.53.55-1.34a2.86,2.86,0,0,0-1.92-1.58C522.77,99.35,522.52,100,522.9,100.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M519,100.37a2.76,2.76,0,0,0,1.91,1.58c.68.07.94-.53.56-1.34a2.77,2.77,0,0,0-1.9-1.57C518.85,99,518.59,99.57,519,100.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M515,100a2.73,2.73,0,0,0,1.9,1.57c.69.07,1-.53.59-1.34a2.79,2.79,0,0,0-1.9-1.57C514.91,98.6,514.64,99.2,515,100Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M511,99.63a2.67,2.67,0,0,0,1.89,1.57c.69.07,1-.53.6-1.34a2.74,2.74,0,0,0-1.89-1.57C511,98.23,510.69,98.83,511,99.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M507.06,99.28a2.67,2.67,0,0,0,1.88,1.56c.69.07,1-.54.62-1.34a2.68,2.68,0,0,0-1.88-1.57C507,97.87,506.72,98.47,507.06,99.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M503.07,98.93a2.63,2.63,0,0,0,1.87,1.56c.7.06,1-.54.64-1.34a2.63,2.63,0,0,0-1.87-1.57C503,97.52,502.74,98.12,503.07,98.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499.08,98.59a2.57,2.57,0,0,0,1.85,1.56c.7.06,1-.55.66-1.35a2.62,2.62,0,0,0-1.86-1.56C499,97.18,498.75,97.78,499.08,98.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.07,98.25a2.5,2.5,0,0,0,1.84,1.56c.7.06,1-.54.68-1.35a2.59,2.59,0,0,0-1.85-1.56C495.05,96.85,494.74,97.45,495.07,98.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.05,97.93a2.51,2.51,0,0,0,1.83,1.56c.7.05,1-.55.69-1.36a2.51,2.51,0,0,0-1.83-1.55C491,96.52,490.73,97.13,491.05,97.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487,97.62a2.44,2.44,0,0,0,1.82,1.55c.7,0,1-.55.71-1.36a2.49,2.49,0,0,0-1.83-1.55C487,96.21,486.71,96.81,487,97.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483,97.31a2.41,2.41,0,0,0,1.81,1.55c.7,0,1-.55.73-1.36A2.48,2.48,0,0,0,483.7,96C483,95.9,482.68,96.51,483,97.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.93,97a2.37,2.37,0,0,0,1.8,1.54c.71,0,1-.56.74-1.36a2.39,2.39,0,0,0-1.8-1.54C479,95.6,478.64,96.21,478.93,97Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.87,96.73a2.34,2.34,0,0,0,1.79,1.54c.71,0,1-.56.76-1.36a2.34,2.34,0,0,0-1.79-1.54C474.93,95.32,474.59,95.93,474.87,96.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M470.8,96.45A2.33,2.33,0,0,0,472.58,98c.71.05,1.06-.56.78-1.37a2.35,2.35,0,0,0-1.78-1.54C470.88,95,470.53,95.65,470.8,96.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M466.73,96.18a2.27,2.27,0,0,0,1.76,1.54c.71,0,1.07-.57.8-1.37a2.3,2.3,0,0,0-1.77-1.54C466.82,94.77,466.46,95.38,466.73,96.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M462.64,95.92a2.26,2.26,0,0,0,1.75,1.54c.72,0,1.08-.57.82-1.38a2.26,2.26,0,0,0-1.76-1.53C462.75,94.51,462.38,95.12,462.64,95.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.55,95.67a2.21,2.21,0,0,0,1.74,1.53c.71,0,1.08-.57.83-1.37a2.19,2.19,0,0,0-1.74-1.53C458.67,94.25,458.3,94.87,458.55,95.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M454.45,95.43A2.17,2.17,0,0,0,456.18,97c.71,0,1.09-.58.84-1.38a2.2,2.2,0,0,0-1.72-1.53C454.59,94,454.21,94.63,454.45,95.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450.34,95.2a2.13,2.13,0,0,0,1.71,1.52c.72,0,1.11-.58.87-1.38a2.16,2.16,0,0,0-1.72-1.52C450.5,93.78,450.11,94.4,450.34,95.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M446.22,95a2.12,2.12,0,0,0,1.7,1.52c.72,0,1.12-.58.89-1.38a2.09,2.09,0,0,0-1.7-1.52C446.4,93.56,446,94.18,446.22,95Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M442.1,94.76a2,2,0,0,0,1.69,1.52c.72,0,1.12-.58.9-1.38A2.09,2.09,0,0,0,443,93.38C442.29,93.34,441.89,94,442.1,94.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M438,94.56a2,2,0,0,0,1.67,1.52c.72,0,1.14-.59.92-1.39a2,2,0,0,0-1.67-1.51C438.18,93.14,437.76,93.76,438,94.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.83,94.37a2,2,0,0,0,1.66,1.51c.72,0,1.14-.59.94-1.39A2,2,0,0,0,434.77,93C434.06,93,433.64,93.57,433.83,94.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.69,94.19a2,2,0,0,0,1.65,1.5c.72,0,1.15-.59,1-1.39a2,2,0,0,0-1.65-1.51C429.93,92.76,429.5,93.39,429.69,94.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M425.54,94a1.94,1.94,0,0,0,1.63,1.51c.73,0,1.16-.6,1-1.4a2,2,0,0,0-1.64-1.5C425.8,92.59,425.36,93.21,425.54,94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M421.38,93.85a1.92,1.92,0,0,0,1.62,1.5c.73,0,1.17-.6,1-1.4a1.93,1.93,0,0,0-1.62-1.5C421.66,92.42,421.21,93.05,421.38,93.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.22,93.7a1.86,1.86,0,0,0,1.61,1.49,1,1,0,0,0,1-1.4,1.89,1.89,0,0,0-1.61-1.5A1.06,1.06,0,0,0,417.22,93.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.06,93.55a1.84,1.84,0,0,0,1.59,1.5,1.07,1.07,0,0,0,1-1.41,1.85,1.85,0,0,0-1.6-1.49A1.06,1.06,0,0,0,413.06,93.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.89,93.42a1.8,1.8,0,0,0,1.57,1.49,1.08,1.08,0,0,0,1.05-1.41A1.82,1.82,0,0,0,409.93,92,1.08,1.08,0,0,0,408.89,93.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.71,93.3a1.78,1.78,0,0,0,1.56,1.48,1.09,1.09,0,0,0,1.06-1.41,1.78,1.78,0,0,0-1.56-1.48A1.1,1.1,0,0,0,404.71,93.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.53,93.18a1.76,1.76,0,0,0,1.55,1.49,1.12,1.12,0,0,0,1.08-1.42,1.77,1.77,0,0,0-1.55-1.48A1.11,1.11,0,0,0,400.53,93.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.35,93.08a1.71,1.71,0,0,0,1.53,1.48A1.12,1.12,0,0,0,399,93.14a1.72,1.72,0,0,0-1.53-1.48A1.14,1.14,0,0,0,396.35,93.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.9,92.64a1.41,1.41,0,0,0,1.34,1.44,1.38,1.38,0,0,0,1.31-1.46,1.42,1.42,0,0,0-1.35-1.44A1.39,1.39,0,0,0,345.9,92.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.68,92.67A1.33,1.33,0,1,0,343,91.21,1.41,1.41,0,0,0,341.68,92.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.47,92.71a1.35,1.35,0,0,0,1.31,1.43,1.43,1.43,0,0,0,1.34-1.46,1.36,1.36,0,0,0-1.31-1.43A1.42,1.42,0,0,0,337.47,92.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M333.25,92.76a1.33,1.33,0,0,0,1.3,1.43,1.45,1.45,0,0,0,1.35-1.47,1.33,1.33,0,0,0-1.29-1.43A1.46,1.46,0,0,0,333.25,92.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M329,92.82a1.31,1.31,0,0,0,1.28,1.43,1.48,1.48,0,0,0,1.37-1.47,1.32,1.32,0,0,0-1.28-1.43A1.48,1.48,0,0,0,329,92.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M324.82,92.89a1.29,1.29,0,0,0,1.26,1.42,1.49,1.49,0,0,0,1.39-1.47,1.29,1.29,0,0,0-1.26-1.42A1.5,1.5,0,0,0,324.82,92.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M320.61,93a1.26,1.26,0,0,0,1.24,1.42,1.51,1.51,0,0,0,1.41-1.47A1.27,1.27,0,0,0,322,91.5,1.53,1.53,0,0,0,320.61,93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M316.4,93.06a1.24,1.24,0,0,0,1.22,1.42A1.54,1.54,0,0,0,319,93a1.24,1.24,0,0,0-1.22-1.41A1.53,1.53,0,0,0,316.4,93.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.74,101.24c-.33.8,0,1.4.68,1.33a2.64,2.64,0,0,0,1.86-1.58c.32-.81,0-1.4-.68-1.33A2.64,2.64,0,0,0,183.74,101.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.72,101.65c-.34.8-.05,1.4.66,1.33a2.63,2.63,0,0,0,1.86-1.59c.34-.8,0-1.4-.65-1.32A2.67,2.67,0,0,0,179.72,101.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.7,102.07c-.34.8,0,1.4.64,1.32a2.67,2.67,0,0,0,1.88-1.59c.34-.8.06-1.39-.64-1.32A2.71,2.71,0,0,0,175.7,102.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.7,102.49c-.35.81-.07,1.4.62,1.33a2.73,2.73,0,0,0,1.89-1.6c.35-.8.07-1.39-.62-1.32A2.78,2.78,0,0,0,171.7,102.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.71,102.93c-.36.8-.09,1.39.6,1.32a2.76,2.76,0,0,0,1.9-1.6c.36-.8.08-1.39-.61-1.32A2.79,2.79,0,0,0,167.71,102.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M163.72,103.37c-.36.8-.1,1.39.59,1.32a2.8,2.8,0,0,0,1.91-1.6c.36-.8.1-1.39-.59-1.32A2.86,2.86,0,0,0,163.72,103.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.75,103.82c-.37.8-.11,1.39.58,1.32a2.88,2.88,0,0,0,1.92-1.6c.36-.81.11-1.4-.58-1.32A2.89,2.89,0,0,0,159.75,103.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.79,104.28c-.38.8-.13,1.39.56,1.31a2.92,2.92,0,0,0,1.93-1.6c.38-.81.13-1.39-.56-1.31A2.93,2.93,0,0,0,155.79,104.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M151.85,104.74c-.39.81-.15,1.4.54,1.31a3,3,0,0,0,1.94-1.6c.38-.81.14-1.39-.55-1.31A3,3,0,0,0,151.85,104.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M147.91,105.22c-.39.8-.15,1.39.53,1.31a3,3,0,0,0,1.94-1.61c.39-.81.16-1.39-.52-1.31A3,3,0,0,0,147.91,105.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M144,105.7c-.4.8-.17,1.39.51,1.31a3,3,0,0,0,1.95-1.61c.4-.81.17-1.4-.5-1.31A3,3,0,0,0,144,105.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M140.09,106.19c-.41.8-.19,1.39.49,1.3a3.07,3.07,0,0,0,2-1.61c.4-.81.18-1.39-.49-1.3A3,3,0,0,0,140.09,106.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M136.19,106.68c-.41.81-.2,1.39.48,1.31a3.09,3.09,0,0,0,2-1.62c.41-.8.2-1.39-.47-1.3A3.14,3.14,0,0,0,136.19,106.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M132.31,107.19c-.42.8-.22,1.38.46,1.3a3.2,3.2,0,0,0,2-1.62c.41-.81.21-1.39-.46-1.3A3.17,3.17,0,0,0,132.31,107.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M128.44,107.7c-.43.8-.23,1.38.45,1.3a3.2,3.2,0,0,0,2-1.62c.42-.81.22-1.39-.44-1.3A3.21,3.21,0,0,0,128.44,107.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M124.59,108.21c-.44.81-.24,1.39.43,1.3a3.24,3.24,0,0,0,2-1.62c.43-.81.23-1.39-.43-1.3A3.24,3.24,0,0,0,124.59,108.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M120.75,108.74c-.44.8-.26,1.38.41,1.29a3.28,3.28,0,0,0,2-1.62c.43-.81.25-1.39-.41-1.29A3.25,3.25,0,0,0,120.75,108.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M116.93,109.27c-.45.81-.27,1.38.39,1.29a3.33,3.33,0,0,0,2-1.62c.44-.81.26-1.39-.4-1.3A3.36,3.36,0,0,0,116.93,109.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M113.12,109.81c-.46.8-.29,1.38.38,1.29a3.37,3.37,0,0,0,2-1.63c.44-.81.27-1.38-.38-1.29A3.34,3.34,0,0,0,113.12,109.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M109.32,110.35c-.46.81-.29,1.38.37,1.29a3.34,3.34,0,0,0,2-1.63c.46-.81.29-1.38-.36-1.29A3.45,3.45,0,0,0,109.32,110.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M105.54,110.9c-.46.81-.3,1.38.35,1.29a3.43,3.43,0,0,0,2-1.63c.46-.81.31-1.38-.35-1.29A3.51,3.51,0,0,0,105.54,110.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M101.78,111.46c-.47.81-.32,1.38.34,1.28a3.41,3.41,0,0,0,2-1.63c.47-.81.32-1.38-.33-1.28A3.49,3.49,0,0,0,101.78,111.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M37.08,122.39c-.55.8-.51,1.36.09,1.25A4.13,4.13,0,0,0,39.23,122c.55-.8.51-1.36-.08-1.25A4.19,4.19,0,0,0,37.08,122.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M599.62,105.94a3.63,3.63,0,0,0,2,1.62c.63.1.72-.48.21-1.28a3.71,3.71,0,0,0-2-1.63C599.2,104.56,599.11,105.14,599.62,105.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M588.75,104.37a3.61,3.61,0,0,0,2,1.62c.63.09.74-.49.25-1.29a3.57,3.57,0,0,0-2-1.62C588.37,103,588.26,103.57,588.75,104.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M562.86,100.93a3.27,3.27,0,0,0,2,1.6c.66.08.82-.51.36-1.31a3.29,3.29,0,0,0-2-1.6C562.57,99.54,562.41,100.12,562.86,100.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M559.1,100.46a3.22,3.22,0,0,0,2,1.6c.65.08.82-.5.37-1.31a3.27,3.27,0,0,0-2-1.6C558.82,99.07,558.65,99.66,559.1,100.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555.32,100a3.19,3.19,0,0,0,2,1.6c.66.08.83-.51.39-1.31a3.27,3.27,0,0,0-2-1.6C555.06,98.61,554.89,99.2,555.32,100Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551.54,99.55a3.16,3.16,0,0,0,2,1.6c.66.08.84-.51.41-1.32a3.19,3.19,0,0,0-2-1.59C551.29,98.16,551.11,98.75,551.54,99.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.74,99.11a3.1,3.1,0,0,0,2,1.59c.66.08.85-.51.42-1.31a3.13,3.13,0,0,0-2-1.6C547.5,97.72,547.31,98.3,547.74,99.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.92,98.67a3.07,3.07,0,0,0,2,1.59c.67.08.86-.51.44-1.32a3.1,3.1,0,0,0-2-1.59C543.7,97.28,543.51,97.87,543.92,98.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540.1,98.24a3,3,0,0,0,1.95,1.59c.67.07.87-.52.45-1.32a3.07,3.07,0,0,0-2-1.59C539.89,96.85,539.68,97.44,540.1,98.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.26,97.82a3,3,0,0,0,1.94,1.59c.67.07.88-.52.47-1.32a3,3,0,0,0-1.94-1.59C536.06,96.43,535.85,97,536.26,97.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M532.4,97.41A3,3,0,0,0,534.34,99c.67.07.89-.52.49-1.32a3,3,0,0,0-1.94-1.58C532.22,96,532,96.61,532.4,97.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M528.54,97a2.9,2.9,0,0,0,1.93,1.58c.67.07.89-.52.5-1.32A2.91,2.91,0,0,0,529,95.68C528.37,95.61,528.15,96.2,528.54,97Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M524.66,96.61a2.89,2.89,0,0,0,1.92,1.57c.68.07.91-.52.52-1.33a2.89,2.89,0,0,0-1.92-1.57C524.51,95.21,524.28,95.8,524.66,96.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520.77,96.22a2.83,2.83,0,0,0,1.91,1.57c.68.07.92-.53.54-1.33a2.82,2.82,0,0,0-1.91-1.57C520.64,94.82,520.4,95.41,520.77,96.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M516.87,95.83a2.78,2.78,0,0,0,1.9,1.58c.68.06.93-.54.55-1.34a2.8,2.8,0,0,0-1.89-1.57C516.75,94.44,516.5,95,516.87,95.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M513,95.46A2.76,2.76,0,0,0,514.85,97c.68.06.94-.53.57-1.34a2.74,2.74,0,0,0-1.89-1.56C512.85,94.06,512.6,94.66,513,95.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M509,95.1a2.67,2.67,0,0,0,1.88,1.56c.68.06.95-.53.58-1.34a2.7,2.7,0,0,0-1.88-1.56C509,93.7,508.68,94.3,509,95.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M505.1,94.74A2.67,2.67,0,0,0,507,96.3c.69.06,1-.54.61-1.34a2.69,2.69,0,0,0-1.87-1.56C505,93.34,504.76,93.94,505.1,94.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M501.16,94.39A2.62,2.62,0,0,0,503,96c.69.06,1-.54.62-1.34a2.66,2.66,0,0,0-1.86-1.56C501.1,93,500.82,93.59,501.16,94.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M497.21,94.05a2.57,2.57,0,0,0,1.84,1.56c.69.06,1-.54.64-1.35a2.59,2.59,0,0,0-1.85-1.55C497.16,92.65,496.87,93.25,497.21,94.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M493.24,93.72a2.52,2.52,0,0,0,1.84,1.55c.69.06,1-.54.65-1.34a2.54,2.54,0,0,0-1.84-1.55C493.21,92.32,492.92,92.92,493.24,93.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.26,93.4A2.53,2.53,0,0,0,491.09,95c.69,0,1-.55.67-1.35a2.53,2.53,0,0,0-1.82-1.55C489.25,92,489,92.6,489.26,93.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.28,93.09a2.45,2.45,0,0,0,1.81,1.54c.7.06,1-.55.69-1.35A2.45,2.45,0,0,0,486,91.74C485.28,91.68,485,92.29,485.28,93.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.29,92.78a2.44,2.44,0,0,0,1.8,1.55c.69,0,1-.56.71-1.36A2.45,2.45,0,0,0,482,91.43C481.3,91.38,481,92,481.29,92.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M477.28,92.49A2.39,2.39,0,0,0,479.07,94c.7,0,1-.56.73-1.36A2.41,2.41,0,0,0,478,91.13C477.31,91.08,477,91.69,477.28,92.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M473.27,92.2a2.35,2.35,0,0,0,1.78,1.54c.7.05,1-.56.74-1.36A2.38,2.38,0,0,0,474,90.84C473.32,90.8,473,91.4,473.27,92.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469.25,91.92A2.32,2.32,0,0,0,471,93.46c.7.05,1-.56.76-1.36A2.36,2.36,0,0,0,470,90.56C469.31,90.52,469,91.13,469.25,91.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.22,91.66A2.3,2.3,0,0,0,467,93.19c.71,0,1-.57.78-1.37A2.29,2.29,0,0,0,466,90.29C465.3,90.25,465,90.86,465.22,91.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M461.18,91.4a2.22,2.22,0,0,0,1.74,1.52c.71,0,1.06-.56.8-1.36A2.28,2.28,0,0,0,462,90C461.28,90,460.92,90.6,461.18,91.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M457.14,91.15a2.18,2.18,0,0,0,1.73,1.52c.7,0,1.06-.57.81-1.37A2.23,2.23,0,0,0,458,89.78C457.25,89.74,456.89,90.35,457.14,91.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M453.08,90.91a2.17,2.17,0,0,0,1.72,1.52c.71,0,1.08-.57.83-1.37a2.18,2.18,0,0,0-1.72-1.52C453.21,89.5,452.84,90.11,453.08,90.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M449,90.68a2.14,2.14,0,0,0,1.71,1.52c.7,0,1.08-.58.84-1.38a2.13,2.13,0,0,0-1.7-1.51C449.17,89.27,448.79,89.88,449,90.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M445,90.46A2.1,2.1,0,0,0,446.65,92c.71,0,1.09-.58.86-1.38a2.12,2.12,0,0,0-1.69-1.51C445.12,89,444.73,89.66,445,90.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M440.88,90.25a2.08,2.08,0,0,0,1.68,1.51c.71,0,1.1-.58.88-1.38a2.05,2.05,0,0,0-1.68-1.51C441.06,88.83,440.67,89.45,440.88,90.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M436.8,90a2,2,0,0,0,1.66,1.51c.71,0,1.12-.58.9-1.38a2,2,0,0,0-1.66-1.51C437,88.63,436.59,89.25,436.8,90Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M432.71,89.85a2,2,0,0,0,1.65,1.51c.71,0,1.13-.59.92-1.39a2,2,0,0,0-1.65-1.5C432.93,88.44,432.51,89.06,432.71,89.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.62,89.67a2,2,0,0,0,1.63,1.5c.72,0,1.14-.59.94-1.39a2,2,0,0,0-1.63-1.5C428.85,88.25,428.43,88.87,428.62,89.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M424.52,89.5A1.93,1.93,0,0,0,426.14,91c.71,0,1.14-.59,1-1.38a2,2,0,0,0-1.63-1.5C424.77,88.08,424.34,88.7,424.52,89.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M420.41,89.34A1.92,1.92,0,0,0,422,90.83c.72,0,1.15-.6,1-1.39a1.89,1.89,0,0,0-1.6-1.5C420.68,87.92,420.24,88.54,420.41,89.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.3,89.18a1.86,1.86,0,0,0,1.6,1.49,1,1,0,0,0,1-1.39,1.87,1.87,0,0,0-1.6-1.49C416.58,87.76,416.14,88.39,416.3,89.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.19,89a1.83,1.83,0,0,0,1.58,1.49,1.06,1.06,0,0,0,1-1.4,1.84,1.84,0,0,0-1.57-1.49A1.06,1.06,0,0,0,412.19,89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.07,88.91a1.79,1.79,0,0,0,1.56,1.48,1.07,1.07,0,0,0,1-1.4,1.81,1.81,0,0,0-1.57-1.48A1.07,1.07,0,0,0,408.07,88.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.94,88.78a1.77,1.77,0,0,0,1.55,1.48,1.07,1.07,0,0,0,1-1.4A1.77,1.77,0,0,0,405,87.38,1.09,1.09,0,0,0,403.94,88.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.81,88.67a1.75,1.75,0,0,0,1.54,1.48,1.1,1.1,0,0,0,1.06-1.41,1.76,1.76,0,0,0-1.54-1.47A1.11,1.11,0,0,0,399.81,88.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395.68,88.57A1.72,1.72,0,0,0,397.2,90a1.12,1.12,0,0,0,1.08-1.41,1.74,1.74,0,0,0-1.52-1.47A1.12,1.12,0,0,0,395.68,88.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.54,88.48A1.69,1.69,0,0,0,393.05,90a1.14,1.14,0,0,0,1.09-1.42,1.7,1.7,0,0,0-1.5-1.47A1.15,1.15,0,0,0,391.54,88.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.26,88.32a1.61,1.61,0,0,0,1.47,1.46,1.16,1.16,0,0,0,1.13-1.41,1.65,1.65,0,0,0-1.47-1.47A1.18,1.18,0,0,0,383.26,88.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M375,88.21a1.57,1.57,0,0,0,1.44,1.45,1.21,1.21,0,0,0,1.17-1.42,1.58,1.58,0,0,0-1.44-1.46A1.22,1.22,0,0,0,375,88.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.86,88.13a1.38,1.38,0,0,0,1.32,1.43,1.36,1.36,0,0,0,1.29-1.44,1.4,1.4,0,0,0-1.33-1.44A1.36,1.36,0,0,0,345.86,88.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.69,88.16A1.38,1.38,0,0,0,343,89.59a1.39,1.39,0,0,0,1.31-1.45A1.37,1.37,0,0,0,343,86.71,1.39,1.39,0,0,0,341.69,88.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.53,88.2a1.35,1.35,0,0,0,1.29,1.43,1.41,1.41,0,0,0,1.32-1.46,1.34,1.34,0,0,0-1.29-1.42A1.4,1.4,0,0,0,337.53,88.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M333.37,88.25a1.32,1.32,0,0,0,1.27,1.42A1.43,1.43,0,0,0,336,88.22a1.33,1.33,0,0,0-1.28-1.43A1.44,1.44,0,0,0,333.37,88.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M329.2,88.31a1.3,1.3,0,0,0,1.26,1.42,1.46,1.46,0,0,0,1.36-1.46,1.31,1.31,0,0,0-1.26-1.42A1.47,1.47,0,0,0,329.2,88.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201.64,95.16c-.3.8,0,1.4.72,1.33a2.46,2.46,0,0,0,1.81-1.56c.29-.8,0-1.4-.73-1.34A2.5,2.5,0,0,0,201.64,95.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197.62,95.53c-.31.8,0,1.4.7,1.33a2.48,2.48,0,0,0,1.82-1.56c.3-.81,0-1.4-.71-1.34A2.53,2.53,0,0,0,197.62,95.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.61,95.91c-.32.8,0,1.4.69,1.33a2.51,2.51,0,0,0,1.82-1.57c.31-.8,0-1.39-.69-1.33A2.55,2.55,0,0,0,193.61,95.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.6,96.3c-.32.8,0,1.4.68,1.33a2.59,2.59,0,0,0,1.84-1.57c.32-.8,0-1.4-.68-1.33A2.61,2.61,0,0,0,189.6,96.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.61,96.7c-.33.8,0,1.4.66,1.33a2.64,2.64,0,0,0,1.85-1.58c.32-.8,0-1.39-.66-1.32A2.63,2.63,0,0,0,185.61,96.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.63,97.11c-.34.8,0,1.39.64,1.32a2.63,2.63,0,0,0,1.86-1.58c.33-.8.05-1.39-.64-1.32A2.67,2.67,0,0,0,181.63,97.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.66,97.53c-.35.8-.07,1.39.62,1.32a2.68,2.68,0,0,0,1.87-1.59c.34-.8.06-1.39-.62-1.31A2.69,2.69,0,0,0,177.66,97.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.69,98c-.35.8-.08,1.39.61,1.32a2.71,2.71,0,0,0,1.88-1.59c.35-.8.08-1.39-.6-1.31A2.76,2.76,0,0,0,173.69,98Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.74,98.39c-.36.8-.09,1.39.59,1.31a2.75,2.75,0,0,0,1.89-1.59c.36-.8.1-1.39-.59-1.31A2.77,2.77,0,0,0,169.74,98.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M165.8,98.83c-.37.8-.11,1.39.57,1.31a2.85,2.85,0,0,0,1.91-1.59c.36-.8.1-1.39-.58-1.31A2.8,2.8,0,0,0,165.8,98.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.87,99.28c-.37.8-.13,1.39.56,1.31a2.88,2.88,0,0,0,1.91-1.6c.37-.8.12-1.39-.56-1.31A2.88,2.88,0,0,0,161.87,99.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M158,99.73c-.38.81-.14,1.39.54,1.31a2.87,2.87,0,0,0,1.92-1.6c.38-.8.14-1.38-.54-1.3A3,3,0,0,0,158,99.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M154.05,100.2c-.39.8-.16,1.39.52,1.31a3,3,0,0,0,1.93-1.61c.38-.8.15-1.38-.52-1.3A3,3,0,0,0,154.05,100.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M150.15,100.67c-.4.8-.17,1.39.51,1.31a3,3,0,0,0,1.94-1.61c.39-.8.16-1.38-.51-1.3A3,3,0,0,0,150.15,100.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M146.27,101.15c-.41.8-.19,1.39.49,1.3a3,3,0,0,0,2-1.6c.4-.8.18-1.39-.49-1.3A3,3,0,0,0,146.27,101.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M142.4,101.64c-.41.8-.2,1.39.47,1.3a3,3,0,0,0,2-1.61c.4-.8.19-1.38-.48-1.3A3.11,3.11,0,0,0,142.4,101.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M138.54,102.14c-.42.8-.21,1.38.46,1.29a3.06,3.06,0,0,0,2-1.61c.41-.8.21-1.38-.46-1.29A3.11,3.11,0,0,0,138.54,102.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M134.7,102.64c-.43.8-.23,1.38.44,1.3a3.15,3.15,0,0,0,2-1.62c.42-.8.22-1.38-.44-1.29A3.13,3.13,0,0,0,134.7,102.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M130.86,103.15c-.43.8-.23,1.38.43,1.29a3.21,3.21,0,0,0,2-1.61c.43-.81.23-1.39-.43-1.3A3.28,3.28,0,0,0,130.86,103.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M127.05,103.67c-.44.8-.26,1.38.41,1.29a3.25,3.25,0,0,0,2-1.62c.44-.8.25-1.38-.41-1.29A3.22,3.22,0,0,0,127.05,103.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M123.24,104.19c-.44.8-.26,1.38.4,1.29a3.29,3.29,0,0,0,2-1.62c.44-.8.26-1.38-.4-1.29A3.35,3.35,0,0,0,123.24,104.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M119.45,104.72c-.45.81-.28,1.38.38,1.29a3.31,3.31,0,0,0,2-1.62c.45-.81.28-1.38-.38-1.29A3.36,3.36,0,0,0,119.45,104.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M115.68,105.26c-.46.8-.29,1.38.36,1.29a3.41,3.41,0,0,0,2-1.63c.45-.8.28-1.38-.37-1.28A3.36,3.36,0,0,0,115.68,105.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M111.92,105.8c-.46.81-.31,1.38.35,1.29a3.43,3.43,0,0,0,2-1.63c.45-.8.3-1.38-.35-1.28A3.41,3.41,0,0,0,111.92,105.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M108.17,106.35c-.47.81-.32,1.38.33,1.29a3.45,3.45,0,0,0,2-1.63c.46-.81.31-1.38-.33-1.28A3.45,3.45,0,0,0,108.17,106.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M104.44,106.91c-.47.81-.33,1.38.32,1.28a3.44,3.44,0,0,0,2-1.63c.47-.8.32-1.38-.32-1.28A3.54,3.54,0,0,0,104.44,106.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M100.72,107.48c-.47.8-.34,1.37.31,1.27a3.5,3.5,0,0,0,2-1.63c.48-.8.34-1.37-.3-1.28A3.57,3.57,0,0,0,100.72,107.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M97,108.05c-.48.8-.35,1.37.29,1.27a3.53,3.53,0,0,0,2-1.63c.48-.81.35-1.38-.29-1.28A3.6,3.6,0,0,0,97,108.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M40.18,117.87c-.54.8-.51,1.36.08,1.25a4.13,4.13,0,0,0,2.06-1.65c.55-.8.52-1.36-.07-1.25A4.25,4.25,0,0,0,40.18,117.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603.85,102.48a3.79,3.79,0,0,0,2,1.62c.62.1.69-.48.17-1.28A3.79,3.79,0,0,0,604,101.2C603.41,101.1,603.34,101.68,603.85,102.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M600.31,101.94a3.72,3.72,0,0,0,2.05,1.62c.61.09.69-.48.18-1.28a3.78,3.78,0,0,0-2-1.63C599.88,100.56,599.8,101.13,600.31,101.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M586,99.83a3.59,3.59,0,0,0,2,1.62c.63.08.74-.49.24-1.3a3.63,3.63,0,0,0-2-1.61C585.59,98.45,585.48,99,586,99.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M571.39,97.82a3.37,3.37,0,0,0,2,1.61c.64.09.77-.5.29-1.3a3.4,3.4,0,0,0-2-1.6C571.05,96.44,570.92,97,571.39,97.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M560.3,96.39a3.27,3.27,0,0,0,2,1.6c.65.08.8-.5.34-1.31a3.28,3.28,0,0,0-2-1.59C560,95,559.85,95.59,560.3,96.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M556.58,95.92a3.2,3.2,0,0,0,2,1.6c.65.08.81-.5.36-1.3a3.24,3.24,0,0,0-2-1.6C556.29,94.54,556.13,95.12,556.58,95.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M552.84,95.47a3.19,3.19,0,0,0,2,1.59c.65.08.81-.51.37-1.31a3.24,3.24,0,0,0-2-1.59C552.57,94.08,552.4,94.67,552.84,95.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.09,95a3.15,3.15,0,0,0,2,1.59c.65.08.82-.51.39-1.31a3.2,3.2,0,0,0-2-1.59C548.83,93.63,548.65,94.22,549.09,95Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.32,94.58a3.1,3.1,0,0,0,2,1.58c.65.08.83-.51.4-1.31a3.1,3.1,0,0,0-2-1.58C545.08,93.19,544.9,93.78,545.32,94.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.55,94.14a3.09,3.09,0,0,0,2,1.59c.66.07.85-.52.42-1.32A3.06,3.06,0,0,0,542,92.83C541.32,92.75,541.13,93.34,541.55,94.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537.76,93.71a3,3,0,0,0,1.94,1.59c.67.07.86-.52.44-1.32a3,3,0,0,0-1.94-1.58C537.54,92.32,537.35,92.91,537.76,93.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M534,93.29a3,3,0,0,0,1.94,1.58c.66.08.86-.51.45-1.31A3,3,0,0,0,534.41,92C533.75,91.9,533.55,92.49,534,93.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530.15,92.88a3,3,0,0,0,1.92,1.58c.67.07.88-.52.47-1.32a3,3,0,0,0-1.93-1.58C530,91.49,529.75,92.08,530.15,92.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M526.32,92.48a2.89,2.89,0,0,0,1.92,1.57c.67.07.88-.52.48-1.32a2.89,2.89,0,0,0-1.91-1.57C526.14,91.09,525.93,91.68,526.32,92.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M522.48,92.08a2.87,2.87,0,0,0,1.91,1.57c.67.07.9-.52.5-1.32a2.88,2.88,0,0,0-1.9-1.57C522.32,90.69,522.1,91.28,522.48,92.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M518.64,91.69a2.79,2.79,0,0,0,1.9,1.57c.67.07.9-.53.51-1.33a2.85,2.85,0,0,0-1.9-1.56C518.49,90.3,518.26,90.89,518.64,91.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M514.78,91.31a2.78,2.78,0,0,0,1.89,1.57c.67.06.91-.53.53-1.33A2.78,2.78,0,0,0,515.31,90C514.64,89.92,514.4,90.51,514.78,91.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510.91,90.94a2.7,2.7,0,0,0,1.88,1.56c.67.07.92-.53.55-1.33a2.77,2.77,0,0,0-1.88-1.56C510.79,89.55,510.54,90.14,510.91,90.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M507,90.58a2.72,2.72,0,0,0,1.87,1.56c.67.06.93-.54.56-1.34a2.72,2.72,0,0,0-1.87-1.55C506.92,89.18,506.67,89.78,507,90.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M503.13,90.22A2.68,2.68,0,0,0,505,91.78c.68.06.94-.54.59-1.34a2.68,2.68,0,0,0-1.86-1.55C503.05,88.83,502.79,89.42,503.13,90.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499.23,89.88a2.65,2.65,0,0,0,1.85,1.55c.68.06.95-.54.61-1.34a2.65,2.65,0,0,0-1.85-1.55C499.16,88.48,498.89,89.08,499.23,89.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.32,89.54a2.62,2.62,0,0,0,1.84,1.55c.68.06,1-.54.62-1.34a2.6,2.6,0,0,0-1.84-1.55C495.27,88.14,495,88.74,495.32,89.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.4,89.21a2.51,2.51,0,0,0,1.83,1.54c.68.06,1-.54.64-1.34A2.59,2.59,0,0,0,492,87.87C491.36,87.81,491.08,88.41,491.4,89.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.47,88.89a2.5,2.5,0,0,0,1.82,1.54c.68.06,1-.55.65-1.34a2.5,2.5,0,0,0-1.81-1.54C487.45,87.49,487.15,88.09,487.47,88.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483.53,88.58a2.49,2.49,0,0,0,1.81,1.54c.68,0,1-.55.67-1.35a2.5,2.5,0,0,0-1.81-1.54C483.52,87.18,483.22,87.78,483.53,88.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M479.58,88.27a2.42,2.42,0,0,0,1.8,1.54c.68,0,1-.55.68-1.35a2.43,2.43,0,0,0-1.79-1.53C479.59,86.88,479.28,87.48,479.58,88.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M475.63,88a2.38,2.38,0,0,0,1.78,1.53c.68,0,1-.55.7-1.35a2.4,2.4,0,0,0-1.78-1.53C475.65,86.58,475.33,87.18,475.63,88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M471.66,87.69a2.33,2.33,0,0,0,1.77,1.53c.69,0,1-.55.72-1.35a2.37,2.37,0,0,0-1.77-1.53C471.7,86.3,471.37,86.9,471.66,87.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M467.69,87.42A2.31,2.31,0,0,0,469.44,89c.69,0,1-.56.74-1.36a2.31,2.31,0,0,0-1.75-1.52C467.74,86,467.41,86.62,467.69,87.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M463.7,87.15a2.28,2.28,0,0,0,1.75,1.53c.69,0,1-.57.76-1.36a2.28,2.28,0,0,0-1.75-1.52C463.77,85.75,463.43,86.36,463.7,87.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M459.71,86.9a2.23,2.23,0,0,0,1.73,1.51c.7,0,1.05-.56.78-1.35a2.24,2.24,0,0,0-1.73-1.52C459.8,85.49,459.45,86.1,459.71,86.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455.72,86.65a2.19,2.19,0,0,0,1.71,1.51c.7,0,1.06-.56.8-1.36a2.21,2.21,0,0,0-1.72-1.51C455.82,85.25,455.46,85.85,455.72,86.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M451.71,86.41a2.16,2.16,0,0,0,1.71,1.51c.69,0,1.06-.57.81-1.36a2.17,2.17,0,0,0-1.71-1.51C451.83,85,451.47,85.62,451.71,86.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M447.7,86.18a2.12,2.12,0,0,0,1.69,1.51c.7,0,1.07-.57.83-1.37a2.12,2.12,0,0,0-1.69-1.5C447.84,84.78,447.46,85.39,447.7,86.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.68,86a2.11,2.11,0,0,0,1.68,1.51c.7,0,1.08-.58.85-1.37a2.12,2.12,0,0,0-1.68-1.51C443.83,84.56,443.45,85.17,443.68,86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M439.65,85.75a2.06,2.06,0,0,0,1.67,1.5c.7,0,1.09-.58.86-1.37a2.06,2.06,0,0,0-1.66-1.5C439.83,84.34,439.44,85,439.65,85.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435.62,85.55a2,2,0,0,0,1.65,1.5c.71,0,1.1-.58.89-1.38a2,2,0,0,0-1.65-1.49C435.81,84.14,435.41,84.76,435.62,85.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M431.59,85.36a2,2,0,0,0,1.63,1.49c.7,0,1.11-.58.9-1.37a2,2,0,0,0-1.63-1.5C431.79,84,431.39,84.57,431.59,85.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427.54,85.18a2,2,0,0,0,1.62,1.49c.71,0,1.12-.59.92-1.38a2,2,0,0,0-1.62-1.49C427.76,83.77,427.35,84.39,427.54,85.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.49,85a1.92,1.92,0,0,0,1.61,1.49c.71,0,1.12-.59.94-1.39a1.93,1.93,0,0,0-1.61-1.48C423.73,83.6,423.31,84.21,423.49,85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419.44,84.85A1.87,1.87,0,0,0,421,86.33c.71,0,1.13-.59,1-1.38a1.88,1.88,0,0,0-1.59-1.49C419.69,83.43,419.26,84.05,419.44,84.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.38,84.69A1.85,1.85,0,0,0,417,86.18c.7,0,1.14-.6,1-1.39a1.86,1.86,0,0,0-1.58-1.48C415.65,83.28,415.21,83.9,415.38,84.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.31,84.55A1.83,1.83,0,0,0,412.88,86a1,1,0,0,0,1-1.39,1.86,1.86,0,0,0-1.57-1.48C411.6,83.14,411.16,83.76,411.31,84.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.24,84.42a1.79,1.79,0,0,0,1.55,1.47c.71,0,1.16-.6,1-1.39A1.8,1.8,0,0,0,408.25,83,1.07,1.07,0,0,0,407.24,84.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.17,84.3a1.76,1.76,0,0,0,1.53,1.47,1.08,1.08,0,0,0,1-1.4,1.77,1.77,0,0,0-1.53-1.47A1.08,1.08,0,0,0,403.17,84.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.09,84.19a1.72,1.72,0,0,0,1.52,1.46,1.08,1.08,0,0,0,1-1.4,1.72,1.72,0,0,0-1.51-1.46A1.09,1.09,0,0,0,399.09,84.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395,84.08a1.71,1.71,0,0,0,1.5,1.47,1.1,1.1,0,0,0,1.06-1.4,1.7,1.7,0,0,0-1.5-1.47A1.12,1.12,0,0,0,395,84.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.92,84a1.67,1.67,0,0,0,1.49,1.46,1.12,1.12,0,0,0,1.08-1.4A1.67,1.67,0,0,0,392,82.59,1.13,1.13,0,0,0,390.92,84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.84,83.91a1.64,1.64,0,0,0,1.47,1.46A1.14,1.14,0,0,0,389.4,84a1.65,1.65,0,0,0-1.47-1.46A1.15,1.15,0,0,0,386.84,83.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.74,83.84a1.61,1.61,0,0,0,1.46,1.45,1.16,1.16,0,0,0,1.11-1.41,1.61,1.61,0,0,0-1.45-1.45A1.17,1.17,0,0,0,382.74,83.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.45,83.68a1.53,1.53,0,0,0,1.41,1.45A1.23,1.23,0,0,0,373,83.71a1.55,1.55,0,0,0-1.41-1.45A1.23,1.23,0,0,0,370.45,83.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.35,83.65a1.49,1.49,0,0,0,1.39,1.44,1.23,1.23,0,0,0,1.18-1.42,1.51,1.51,0,0,0-1.39-1.44A1.24,1.24,0,0,0,366.35,83.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.92,83.63a1.41,1.41,0,0,0,1.33,1.43,1.33,1.33,0,0,0,1.25-1.44,1.41,1.41,0,0,0-1.32-1.43A1.35,1.35,0,0,0,349.92,83.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M325.26,83.9a1.27,1.27,0,0,0,1.22,1.41,1.47,1.47,0,0,0,1.36-1.46,1.26,1.26,0,0,0-1.23-1.4A1.48,1.48,0,0,0,325.26,83.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M321.15,84a1.25,1.25,0,0,0,1.21,1.41,1.5,1.5,0,0,0,1.37-1.46,1.26,1.26,0,0,0-1.21-1.41A1.51,1.51,0,0,0,321.15,84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M317,84.07a1.21,1.21,0,0,0,1.19,1.4A1.51,1.51,0,0,0,319.62,84a1.22,1.22,0,0,0-1.19-1.4A1.54,1.54,0,0,0,317,84.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M312.93,84.17a1.2,1.2,0,0,0,1.18,1.4,1.55,1.55,0,0,0,1.4-1.46,1.21,1.21,0,0,0-1.17-1.4A1.56,1.56,0,0,0,312.93,84.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.4,91.4c-.32.79,0,1.39.67,1.32a2.55,2.55,0,0,0,1.82-1.56c.32-.8,0-1.39-.67-1.33A2.58,2.58,0,0,0,195.4,91.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.44,91.78c-.32.8,0,1.39.66,1.33a2.58,2.58,0,0,0,1.83-1.57c.32-.8,0-1.39-.66-1.32A2.62,2.62,0,0,0,191.44,91.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187.5,92.18c-.34.8-.05,1.39.63,1.32A2.59,2.59,0,0,0,190,91.93c.33-.8.05-1.39-.63-1.32A2.61,2.61,0,0,0,187.5,92.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.56,92.59c-.35.8-.07,1.39.62,1.32A2.68,2.68,0,0,0,186,92.33c.34-.79.06-1.38-.62-1.31A2.66,2.66,0,0,0,183.56,92.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.63,93c-.35.8-.08,1.39.6,1.32a2.69,2.69,0,0,0,1.86-1.58c.35-.8.08-1.38-.6-1.31A2.72,2.72,0,0,0,179.63,93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.71,93.43c-.36.8-.1,1.38.58,1.31a2.74,2.74,0,0,0,1.88-1.58c.35-.8.09-1.38-.59-1.31A2.73,2.73,0,0,0,175.71,93.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M171.8,93.86c-.37.8-.11,1.39.57,1.31a2.79,2.79,0,0,0,1.88-1.58c.36-.8.1-1.39-.57-1.31A2.76,2.76,0,0,0,171.8,93.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.9,94.3c-.38.8-.13,1.38.55,1.31A2.82,2.82,0,0,0,170.34,94c.37-.8.12-1.38-.55-1.31A2.86,2.86,0,0,0,167.9,94.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M164,94.75c-.38.8-.14,1.38.54,1.3a2.88,2.88,0,0,0,1.9-1.58c.37-.8.13-1.39-.54-1.31A2.9,2.9,0,0,0,164,94.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M160.13,95.2c-.39.8-.15,1.39.52,1.31a2.89,2.89,0,0,0,1.91-1.59c.38-.8.15-1.39-.52-1.31A2.91,2.91,0,0,0,160.13,95.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M156.26,95.67c-.39.8-.17,1.38.51,1.3a3,3,0,0,0,1.92-1.59c.39-.8.16-1.39-.51-1.31A3,3,0,0,0,156.26,95.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M152.4,96.14c-.4.8-.18,1.38.49,1.3a3,3,0,0,0,1.93-1.6c.4-.8.18-1.38-.49-1.3A3,3,0,0,0,152.4,96.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M148.56,96.62c-.41.8-.2,1.38.47,1.3a3.05,3.05,0,0,0,1.94-1.6c.4-.8.19-1.38-.47-1.3A3,3,0,0,0,148.56,96.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M144.73,97.11c-.42.8-.21,1.38.46,1.29a3.07,3.07,0,0,0,1.94-1.6c.41-.8.21-1.38-.46-1.3A3.12,3.12,0,0,0,144.73,97.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M140.91,97.6c-.42.8-.23,1.38.44,1.3a3.13,3.13,0,0,0,2-1.61c.42-.8.22-1.38-.44-1.29A3.11,3.11,0,0,0,140.91,97.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M137.1,98.1c-.43.8-.24,1.38.43,1.3a3.17,3.17,0,0,0,2-1.61c.42-.8.23-1.38-.43-1.29A3.15,3.15,0,0,0,137.1,98.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M133.3,98.61c-.43.8-.25,1.38.41,1.29a3.23,3.23,0,0,0,2-1.61c.42-.8.24-1.38-.42-1.29A3.27,3.27,0,0,0,133.3,98.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M129.52,99.13c-.44.8-.26,1.38.4,1.29a3.23,3.23,0,0,0,2-1.62c.44-.8.26-1.37-.39-1.28A3.28,3.28,0,0,0,129.52,99.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M125.75,99.65c-.44.8-.27,1.38.38,1.29a3.29,3.29,0,0,0,2-1.62c.44-.8.27-1.37-.38-1.28A3.3,3.3,0,0,0,125.75,99.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M122,100.18c-.45.81-.28,1.38.37,1.29a3.32,3.32,0,0,0,2-1.62c.45-.8.29-1.38-.36-1.28A3.37,3.37,0,0,0,122,100.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.25,100.72c-.45.8-.3,1.38.35,1.28a3.36,3.36,0,0,0,2-1.62c.45-.8.3-1.37-.35-1.28A3.39,3.39,0,0,0,118.25,100.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M114.52,101.27c-.46.8-.31,1.37.34,1.28a3.42,3.42,0,0,0,2-1.63c.46-.8.31-1.37-.33-1.28A3.46,3.46,0,0,0,114.52,101.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M110.81,101.82c-.47.8-.32,1.37.32,1.27a3.41,3.41,0,0,0,2-1.62c.47-.8.32-1.37-.32-1.28A3.49,3.49,0,0,0,110.81,101.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M107.11,102.37c-.47.81-.34,1.38.31,1.28a3.53,3.53,0,0,0,2-1.63c.47-.8.34-1.37-.3-1.27A3.48,3.48,0,0,0,107.11,102.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M103.43,102.94c-.48.8-.35,1.37.29,1.27a3.57,3.57,0,0,0,2-1.63c.47-.8.34-1.37-.29-1.27A3.54,3.54,0,0,0,103.43,102.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M99.76,103.51c-.49.8-.37,1.37.27,1.27a3.59,3.59,0,0,0,2-1.63c.48-.8.35-1.37-.28-1.27A3.52,3.52,0,0,0,99.76,103.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M50.1,112.06c-.55.8-.5,1.36.09,1.25a4.15,4.15,0,0,0,2.06-1.65c.54-.8.5-1.36-.09-1.25A4.09,4.09,0,0,0,50.1,112.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M43.29,113.36c-.55.8-.52,1.35.07,1.24A4.18,4.18,0,0,0,45.42,113c.55-.8.52-1.36-.07-1.24A4.18,4.18,0,0,0,43.29,113.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M33.23,115.33c-.56.8-.55,1.35,0,1.24a4.35,4.35,0,0,0,2.06-1.66c.56-.79.54-1.35,0-1.23A4.2,4.2,0,0,0,33.23,115.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M607.92,99.05a3.84,3.84,0,0,0,2.05,1.62c.6.1.66-.47.13-1.27a3.93,3.93,0,0,0-2.05-1.63C607.45,97.68,607.39,98.25,607.92,99.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M572.36,93.79a3.42,3.42,0,0,0,2,1.6c.63.09.75-.49.26-1.29a3.46,3.46,0,0,0-2-1.6C572,92.41,571.88,93,572.36,93.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M568.72,93.3a3.4,3.4,0,0,0,2,1.6c.64.09.76-.49.29-1.29a3.44,3.44,0,0,0-2-1.6C568.38,91.92,568.25,92.5,568.72,93.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M565.07,92.81a3.37,3.37,0,0,0,2,1.6c.63.09.77-.49.3-1.29a3.43,3.43,0,0,0-2-1.6C564.74,91.44,564.61,92,565.07,92.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M561.41,92.34a3.31,3.31,0,0,0,2,1.59c.64.09.78-.49.31-1.29a3.33,3.33,0,0,0-2-1.6C561.09,91,561,91.54,561.41,92.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M557.73,91.87a3.31,3.31,0,0,0,2,1.59c.64.08.78-.5.32-1.3a3.29,3.29,0,0,0-2-1.59C557.43,90.49,557.28,91.07,557.73,91.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M554,91.4a3.28,3.28,0,0,0,2,1.6c.64.08.8-.51.34-1.31a3.31,3.31,0,0,0-2-1.59C553.75,90,553.6,90.61,554,91.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M550.34,91a3.22,3.22,0,0,0,2,1.59c.65.08.81-.51.36-1.31a3.26,3.26,0,0,0-2-1.58C550.06,89.57,549.9,90.15,550.34,91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M546.63,90.5a3.15,3.15,0,0,0,2,1.59c.65.07.81-.51.37-1.31a3.15,3.15,0,0,0-2-1.58C546.36,89.12,546.19,89.7,546.63,90.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M542.9,90.06a3.09,3.09,0,0,0,2,1.58c.65.08.83-.51.39-1.3a3.16,3.16,0,0,0-2-1.59C542.65,88.68,542.47,89.26,542.9,90.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.16,89.63a3.13,3.13,0,0,0,2,1.58c.65.07.83-.52.4-1.31a3.1,3.1,0,0,0-1.94-1.58C538.92,88.24,538.74,88.83,539.16,89.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.41,89.2a3.06,3.06,0,0,0,1.94,1.58c.65.07.84-.52.42-1.31a3.08,3.08,0,0,0-1.94-1.58C535.18,87.82,535,88.4,535.41,89.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531.65,88.78a3,3,0,0,0,1.93,1.58c.65.07.85-.52.43-1.32a3,3,0,0,0-1.92-1.57C531.44,87.4,531.24,88,531.65,88.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M527.87,88.37a3,3,0,0,0,1.92,1.57c.66.07.86-.52.46-1.31a3,3,0,0,0-1.92-1.57C527.68,87,527.47,87.58,527.87,88.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M524.09,88A2.92,2.92,0,0,0,526,89.54c.66.07.87-.52.47-1.32a2.94,2.94,0,0,0-1.91-1.57C523.9,86.59,523.69,87.17,524.09,88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520.29,87.57a2.88,2.88,0,0,0,1.9,1.57c.66.07.88-.52.49-1.32a2.91,2.91,0,0,0-1.9-1.56C520.12,86.19,519.91,86.78,520.29,87.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M516.49,87.19a2.83,2.83,0,0,0,1.89,1.56c.66.07.88-.52.5-1.32A2.85,2.85,0,0,0,517,85.87C516.33,85.8,516.11,86.39,516.49,87.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M512.67,86.81a2.8,2.8,0,0,0,1.88,1.56c.66.06.9-.53.52-1.32a2.81,2.81,0,0,0-1.88-1.56C512.53,85.42,512.3,86,512.67,86.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M508.84,86.44A2.74,2.74,0,0,0,510.71,88c.67.07.91-.52.54-1.32a2.78,2.78,0,0,0-1.87-1.55C508.71,85.05,508.47,85.65,508.84,86.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M505,86.08a2.7,2.7,0,0,0,1.86,1.55c.67.06.92-.53.55-1.33a2.73,2.73,0,0,0-1.86-1.55C504.89,84.69,504.64,85.28,505,86.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M501.15,85.73A2.67,2.67,0,0,0,503,87.27c.67.06.93-.53.57-1.32a2.65,2.65,0,0,0-1.85-1.55C501.06,84.34,500.8,84.93,501.15,85.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M497.3,85.38a2.62,2.62,0,0,0,1.83,1.55c.68.06.94-.54.59-1.33a2.63,2.63,0,0,0-1.84-1.55C497.22,84,497,84.59,497.3,85.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M493.43,85a2.56,2.56,0,0,0,1.83,1.55c.67,0,.94-.54.6-1.34A2.61,2.61,0,0,0,494,83.71C493.36,83.66,493.09,84.25,493.43,85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.55,84.72a2.56,2.56,0,0,0,1.82,1.54c.67,0,.95-.55.62-1.34a2.54,2.54,0,0,0-1.82-1.53C489.5,83.33,489.23,83.92,489.55,84.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.67,84.4a2.49,2.49,0,0,0,1.8,1.53c.68.06,1-.54.64-1.33a2.54,2.54,0,0,0-1.81-1.54C485.63,83,485.35,83.61,485.67,84.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.77,84.09a2.46,2.46,0,0,0,1.8,1.53c.67,0,1-.55.65-1.34a2.5,2.5,0,0,0-1.79-1.53C481.75,82.7,481.46,83.3,481.77,84.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M477.87,83.79a2.43,2.43,0,0,0,1.78,1.53c.68.05,1-.55.67-1.34a2.41,2.41,0,0,0-1.78-1.53C477.87,82.4,477.57,83,477.87,83.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474,83.5A2.37,2.37,0,0,0,475.73,85c.68,0,1-.55.69-1.34a2.44,2.44,0,0,0-1.77-1.53C474,82.11,473.66,82.7,474,83.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M470,83.21a2.34,2.34,0,0,0,1.76,1.52c.68,0,1-.55.7-1.34a2.36,2.36,0,0,0-1.75-1.52C470.07,81.82,469.75,82.42,470,83.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M466.11,82.94a2.31,2.31,0,0,0,1.75,1.52c.68,0,1-.56.72-1.35a2.32,2.32,0,0,0-1.74-1.52C466.16,81.55,465.83,82.15,466.11,82.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M462.18,82.67a2.27,2.27,0,0,0,1.73,1.52c.68,0,1-.56.74-1.35a2.27,2.27,0,0,0-1.73-1.51C462.24,81.28,461.91,81.88,462.18,82.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.24,82.42A2.23,2.23,0,0,0,460,83.93c.69,0,1-.56.76-1.35A2.23,2.23,0,0,0,459,81.07C458.31,81,458,81.63,458.24,82.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M454.29,82.17a2.18,2.18,0,0,0,1.7,1.51c.69,0,1-.57.78-1.35a2.2,2.2,0,0,0-1.71-1.51C454.38,80.78,454,81.38,454.29,82.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450.33,81.93A2.15,2.15,0,0,0,452,83.44c.69,0,1.05-.57.8-1.36a2.18,2.18,0,0,0-1.7-1.5C450.44,80.54,450.08,81.15,450.33,81.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M446.37,81.71a2.1,2.1,0,0,0,1.67,1.5c.7,0,1.06-.57.82-1.36a2.15,2.15,0,0,0-1.68-1.5C446.49,80.31,446.13,80.92,446.37,81.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M442.4,81.49a2.07,2.07,0,0,0,1.66,1.5c.69,0,1.06-.58.83-1.37a2.1,2.1,0,0,0-1.66-1.49C442.54,80.09,442.17,80.7,442.4,81.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M438.42,81.28a2,2,0,0,0,1.65,1.49c.69,0,1.07-.57.85-1.36a2.06,2.06,0,0,0-1.65-1.49C438.58,79.88,438.2,80.49,438.42,81.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.44,81.08a2,2,0,0,0,1.64,1.49c.69,0,1.08-.58.86-1.36a2,2,0,0,0-1.64-1.49C434.62,79.68,434.23,80.29,434.44,81.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.45,80.89a2,2,0,0,0,1.62,1.49c.7,0,1.09-.58.89-1.37a2,2,0,0,0-1.63-1.48C430.65,79.49,430.25,80.1,430.45,80.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M426.46,80.71a2,2,0,0,0,1.61,1.49c.69,0,1.09-.59.9-1.38a2,2,0,0,0-1.61-1.48C426.67,79.31,426.27,79.92,426.46,80.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422.46,80.54A1.91,1.91,0,0,0,424.05,82c.7,0,1.11-.58.92-1.37a1.92,1.92,0,0,0-1.59-1.48C422.69,79.14,422.28,79.75,422.46,80.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.45,80.38A1.9,1.9,0,0,0,420,81.86c.7,0,1.12-.59.94-1.38A1.87,1.87,0,0,0,419.39,79C418.7,79,418.28,79.59,418.45,80.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.45,80.23A1.82,1.82,0,0,0,416,81.7c.7,0,1.13-.59.95-1.38a1.86,1.86,0,0,0-1.56-1.47C414.71,78.83,414.28,79.44,414.45,80.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M410.43,80.09A1.82,1.82,0,0,0,412,81.56a1,1,0,0,0,1-1.38,1.81,1.81,0,0,0-1.55-1.47C410.71,78.69,410.28,79.3,410.43,80.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.41,80A1.77,1.77,0,0,0,408,81.42c.7,0,1.14-.59,1-1.38a1.77,1.77,0,0,0-1.54-1.46C406.71,78.55,406.27,79.17,406.41,80Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402.39,79.84a1.75,1.75,0,0,0,1.52,1.46,1.07,1.07,0,0,0,1-1.39,1.78,1.78,0,0,0-1.52-1.46A1.07,1.07,0,0,0,402.39,79.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.37,79.73a1.71,1.71,0,0,0,1.5,1.46,1.07,1.07,0,0,0,1-1.39,1.71,1.71,0,0,0-1.5-1.46A1.08,1.08,0,0,0,398.37,79.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.34,79.63a1.67,1.67,0,0,0,1.48,1.45,1.1,1.1,0,0,0,1.05-1.39,1.7,1.7,0,0,0-1.49-1.46A1.1,1.1,0,0,0,394.34,79.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.3,79.53A1.67,1.67,0,0,0,391.77,81a1.12,1.12,0,0,0,1.07-1.4,1.67,1.67,0,0,0-1.48-1.45A1.12,1.12,0,0,0,390.3,79.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.27,79.45a1.62,1.62,0,0,0,1.45,1.45,1.12,1.12,0,0,0,1.08-1.4,1.63,1.63,0,0,0-1.46-1.44A1.12,1.12,0,0,0,386.27,79.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.23,79.38a1.6,1.6,0,0,0,1.44,1.45,1.15,1.15,0,0,0,1.09-1.41A1.61,1.61,0,0,0,383.32,78,1.15,1.15,0,0,0,382.23,79.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.18,79.32a1.56,1.56,0,0,0,1.43,1.44,1.17,1.17,0,0,0,1.11-1.4,1.57,1.57,0,0,0-1.42-1.44A1.17,1.17,0,0,0,378.18,79.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M374.14,79.27a1.54,1.54,0,0,0,1.41,1.44,1.19,1.19,0,0,0,1.13-1.41,1.55,1.55,0,0,0-1.41-1.44A1.19,1.19,0,0,0,374.14,79.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.09,79.23a1.51,1.51,0,0,0,1.39,1.43,1.2,1.2,0,0,0,1.15-1.41,1.52,1.52,0,0,0-1.39-1.43A1.21,1.21,0,0,0,370.09,79.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366,79.2a1.49,1.49,0,0,0,1.38,1.43,1.23,1.23,0,0,0,1.16-1.41,1.48,1.48,0,0,0-1.37-1.43A1.23,1.23,0,0,0,366,79.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.94,79.17a1.43,1.43,0,0,0,1.34,1.42,1.27,1.27,0,0,0,1.2-1.42,1.44,1.44,0,0,0-1.34-1.42A1.27,1.27,0,0,0,357.94,79.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M353.88,79.16a1.42,1.42,0,0,0,1.33,1.43,1.3,1.3,0,0,0,1.22-1.43,1.43,1.43,0,0,0-1.33-1.42A1.3,1.3,0,0,0,353.88,79.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M329.54,79.37a1.27,1.27,0,0,0,1.22,1.41,1.45,1.45,0,0,0,1.33-1.45,1.29,1.29,0,0,0-1.23-1.4A1.44,1.44,0,0,0,329.54,79.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M325.48,79.44a1.24,1.24,0,0,0,1.21,1.4A1.45,1.45,0,0,0,328,79.4a1.26,1.26,0,0,0-1.21-1.4A1.47,1.47,0,0,0,325.48,79.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M321.42,79.52a1.23,1.23,0,0,0,1.19,1.4A1.48,1.48,0,0,0,324,79.47a1.23,1.23,0,0,0-1.19-1.39A1.49,1.49,0,0,0,321.42,79.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M317.37,79.61a1.21,1.21,0,0,0,1.17,1.4,1.51,1.51,0,0,0,1.37-1.45,1.22,1.22,0,0,0-1.17-1.4A1.52,1.52,0,0,0,317.37,79.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M313.31,79.72a1.19,1.19,0,0,0,1.16,1.39,1.55,1.55,0,0,0,1.39-1.46,1.2,1.2,0,0,0-1.16-1.39A1.56,1.56,0,0,0,313.31,79.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M216.9,85.09c-.28.8,0,1.39.74,1.33a2.33,2.33,0,0,0,1.74-1.54c.28-.79,0-1.38-.74-1.32A2.33,2.33,0,0,0,216.9,85.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212.94,85.44c-.28.79,0,1.38.73,1.32a2.39,2.39,0,0,0,1.76-1.54c.28-.79,0-1.38-.73-1.32A2.37,2.37,0,0,0,212.94,85.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M209,85.79c-.3.79,0,1.38.71,1.32a2.42,2.42,0,0,0,1.77-1.54c.29-.8,0-1.39-.71-1.33A2.45,2.45,0,0,0,209,85.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M205.06,86.15c-.3.79,0,1.38.69,1.32a2.45,2.45,0,0,0,1.78-1.55c.3-.79,0-1.38-.68-1.32A2.49,2.49,0,0,0,205.06,86.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201.13,86.52c-.31.79,0,1.38.67,1.32a2.51,2.51,0,0,0,1.8-1.55c.31-.8,0-1.39-.67-1.32A2.53,2.53,0,0,0,201.13,86.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197.21,86.9c-.32.79,0,1.38.65,1.32a2.54,2.54,0,0,0,1.81-1.56c.32-.79,0-1.38-.65-1.32A2.58,2.58,0,0,0,197.21,86.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.3,87.29c-.33.79-.05,1.38.63,1.31A2.57,2.57,0,0,0,195.75,87c.33-.79,0-1.38-.63-1.31A2.59,2.59,0,0,0,193.3,87.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.39,87.68c-.34.8-.06,1.38.62,1.32a2.62,2.62,0,0,0,1.83-1.57c.33-.79.06-1.38-.62-1.31A2.66,2.66,0,0,0,189.39,87.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.5,88.09c-.35.79-.08,1.38.6,1.31a2.65,2.65,0,0,0,1.84-1.57c.34-.79.07-1.38-.6-1.31A2.65,2.65,0,0,0,185.5,88.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.61,88.5c-.35.8-.09,1.38.59,1.31a2.73,2.73,0,0,0,1.85-1.57c.34-.79.08-1.38-.59-1.31A2.73,2.73,0,0,0,181.61,88.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.73,88.92c-.36.8-.1,1.38.57,1.31a2.72,2.72,0,0,0,1.86-1.57c.36-.8.1-1.38-.57-1.31A2.76,2.76,0,0,0,177.73,88.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.86,89.35c-.37.8-.12,1.38.56,1.31a2.79,2.79,0,0,0,1.87-1.58c.36-.79.11-1.38-.55-1.3A2.81,2.81,0,0,0,173.86,89.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170,89.79c-.37.8-.13,1.38.54,1.3a2.82,2.82,0,0,0,1.89-1.58c.36-.79.12-1.37-.54-1.3A2.86,2.86,0,0,0,170,89.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.15,90.24c-.38.79-.14,1.38.53,1.3A2.89,2.89,0,0,0,168.57,90c.38-.8.14-1.38-.52-1.3A2.87,2.87,0,0,0,166.15,90.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M162.32,90.69c-.39.8-.17,1.38.5,1.3a2.93,2.93,0,0,0,1.91-1.59c.38-.79.15-1.37-.51-1.29A2.9,2.9,0,0,0,162.32,90.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M158.49,91.15c-.4.8-.18,1.38.49,1.3a3,3,0,0,0,1.91-1.59c.39-.79.17-1.37-.49-1.29A3,3,0,0,0,158.49,91.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M154.67,91.62c-.4.8-.19,1.38.47,1.3a3,3,0,0,0,1.93-1.59c.39-.8.18-1.38-.48-1.3A3.06,3.06,0,0,0,154.67,91.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M150.86,92.1c-.41.8-.2,1.38.46,1.3a3.06,3.06,0,0,0,1.93-1.6c.41-.8.2-1.37-.45-1.29A3,3,0,0,0,150.86,92.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M147.07,92.59c-.42.8-.22,1.37.44,1.29a3.07,3.07,0,0,0,1.94-1.6c.41-.8.21-1.37-.44-1.29A3.12,3.12,0,0,0,147.07,92.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M143.29,93.08c-.43.8-.24,1.38.42,1.29a3.11,3.11,0,0,0,1.95-1.6c.42-.8.23-1.37-.43-1.29A3.15,3.15,0,0,0,143.29,93.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M139.51,93.58c-.42.8-.24,1.38.42,1.29a3.2,3.2,0,0,0,1.95-1.6c.43-.8.24-1.38-.41-1.29A3.21,3.21,0,0,0,139.51,93.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M135.75,94.09c-.43.8-.25,1.38.4,1.29a3.23,3.23,0,0,0,2-1.61c.44-.8.26-1.37-.39-1.28A3.21,3.21,0,0,0,135.75,94.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M132,94.61c-.44.79-.27,1.37.38,1.28a3.27,3.27,0,0,0,2-1.61c.44-.8.27-1.37-.38-1.28A3.27,3.27,0,0,0,132,94.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M128.27,95.13c-.45.8-.28,1.37.37,1.28a3.31,3.31,0,0,0,2-1.61c.44-.8.28-1.37-.37-1.28A3.34,3.34,0,0,0,128.27,95.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M124.55,95.66c-.45.8-.3,1.37.35,1.28a3.35,3.35,0,0,0,2-1.62c.45-.79.29-1.37-.35-1.28A3.38,3.38,0,0,0,124.55,95.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M120.84,96.19c-.46.8-.31,1.38.34,1.28a3.38,3.38,0,0,0,2-1.61c.45-.8.3-1.37-.34-1.28A3.42,3.42,0,0,0,120.84,96.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M117.15,96.74c-.47.8-.33,1.37.32,1.28a3.49,3.49,0,0,0,2-1.62c.46-.8.31-1.37-.33-1.28A3.4,3.4,0,0,0,117.15,96.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M113.46,97.29c-.47.8-.33,1.37.31,1.27a3.42,3.42,0,0,0,2-1.62c.47-.8.33-1.37-.3-1.27A3.47,3.47,0,0,0,113.46,97.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M109.79,97.85c-.47.79-.34,1.37.3,1.27a3.59,3.59,0,0,0,2-1.63c.47-.8.34-1.36-.3-1.27A3.59,3.59,0,0,0,109.79,97.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M106.14,98.41c-.48.8-.36,1.37.28,1.27a3.56,3.56,0,0,0,2-1.63c.48-.8.36-1.37-.28-1.27A3.59,3.59,0,0,0,106.14,98.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M102.5,99c-.49.8-.37,1.37.26,1.27a3.57,3.57,0,0,0,2-1.63c.49-.8.37-1.37-.26-1.27A3.64,3.64,0,0,0,102.5,99Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M98.87,99.55c-.49.8-.38,1.37.25,1.27a3.66,3.66,0,0,0,2-1.63c.49-.8.37-1.37-.25-1.27A3.69,3.69,0,0,0,98.87,99.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M95.26,100.13c-.49.81-.39,1.37.24,1.27a3.66,3.66,0,0,0,2-1.63c.49-.8.39-1.37-.24-1.27A3.72,3.72,0,0,0,95.26,100.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M91.67,100.72c-.5.8-.41,1.37.22,1.27a3.71,3.71,0,0,0,2-1.64c.5-.8.4-1.36-.22-1.26A3.72,3.72,0,0,0,91.67,100.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M53.18,107.54c-.55.8-.51,1.36.08,1.25a4.23,4.23,0,0,0,2.06-1.65c.54-.8.5-1.36-.09-1.25A4.12,4.12,0,0,0,53.18,107.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M49.78,108.19c-.55.8-.52,1.35.07,1.24a4.18,4.18,0,0,0,2.06-1.65c.55-.8.51-1.35-.08-1.24A4.2,4.2,0,0,0,49.78,108.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M46.4,108.84c-.55.8-.52,1.36.07,1.24a4.2,4.2,0,0,0,2.05-1.65c.55-.8.52-1.35-.06-1.24A4.18,4.18,0,0,0,46.4,108.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M43,109.5c-.55.8-.53,1.35.06,1.24a4.32,4.32,0,0,0,2-1.65c.55-.8.53-1.36,0-1.24A4.26,4.26,0,0,0,43,109.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M39.7,110.16c-.55.8-.54,1.35,0,1.24a4.35,4.35,0,0,0,2.06-1.66c.55-.79.53-1.35,0-1.23A4.29,4.29,0,0,0,39.7,110.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M36.38,110.82c-.56.8-.54,1.35,0,1.24a4.38,4.38,0,0,0,2.06-1.65c.55-.8.54-1.35,0-1.24A4.41,4.41,0,0,0,36.38,110.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M33.08,111.49c-.56.8-.55,1.35,0,1.23a4.29,4.29,0,0,0,2-1.65c.56-.8.55-1.35,0-1.23A4.29,4.29,0,0,0,33.08,111.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M615.23,96.21a4,4,0,0,0,2,1.63c.59.1.63-.47.09-1.27a4,4,0,0,0-2-1.62C614.73,94.85,614.69,95.41,615.23,96.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M583.93,91.3a3.65,3.65,0,0,0,2,1.61c.62.09.71-.48.2-1.28a3.65,3.65,0,0,0-2-1.61C583.52,89.93,583.43,90.5,583.93,91.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M580.38,90.79a3.64,3.64,0,0,0,2,1.6c.61.09.71-.48.21-1.28a3.63,3.63,0,0,0-2-1.61C580,89.41,579.88,90,580.38,90.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M576.82,90.28a3.59,3.59,0,0,0,2,1.6c.62.09.72-.49.23-1.28a3.63,3.63,0,0,0-2-1.61C576.43,88.9,576.33,89.48,576.82,90.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M573.24,89.77a3.5,3.5,0,0,0,2,1.61c.62.08.73-.49.24-1.29a3.54,3.54,0,0,0-2-1.6C572.86,88.4,572.75,89,573.24,89.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M569.65,89.28a3.51,3.51,0,0,0,2,1.6c.62.08.74-.49.26-1.29a3.51,3.51,0,0,0-2-1.6C569.28,87.91,569.17,88.48,569.65,89.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M566,88.79a3.41,3.41,0,0,0,2,1.59c.63.09.75-.49.27-1.28a3.44,3.44,0,0,0-2-1.6C565.69,87.42,565.57,88,566,88.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M562.43,88.31a3.4,3.4,0,0,0,2,1.59c.63.08.75-.5.28-1.29a3.36,3.36,0,0,0-2-1.59C562.09,86.93,562,87.51,562.43,88.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M558.8,87.83a3.32,3.32,0,0,0,2,1.59c.63.08.77-.5.3-1.29a3.37,3.37,0,0,0-2-1.59C558.47,86.46,558.34,87,558.8,87.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555.16,87.36a3.27,3.27,0,0,0,2,1.59c.63.08.78-.5.32-1.29a3.35,3.35,0,0,0-2-1.59C554.84,86,554.7,86.57,555.16,87.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551.5,86.9a3.25,3.25,0,0,0,2,1.59c.64.08.78-.5.33-1.3a3.32,3.32,0,0,0-2-1.58C551.2,85.53,551.05,86.11,551.5,86.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.84,86.45a3.22,3.22,0,0,0,2,1.58c.63.08.79-.5.34-1.3a3.27,3.27,0,0,0-2-1.58C547.55,85.07,547.39,85.65,547.84,86.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M544.16,86a3.18,3.18,0,0,0,2,1.58c.64.08.8-.5.36-1.3a3.23,3.23,0,0,0-2-1.58C543.88,84.63,543.72,85.21,544.16,86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540.47,85.56a3.13,3.13,0,0,0,1.94,1.58c.64.07.81-.51.38-1.3a3.17,3.17,0,0,0-1.95-1.58C540.2,84.19,540,84.77,540.47,85.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.77,85.13a3.07,3.07,0,0,0,1.93,1.57c.65.08.82-.51.39-1.3a3.09,3.09,0,0,0-1.93-1.57C536.52,83.76,536.34,84.34,536.77,85.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.05,84.71A3.08,3.08,0,0,0,535,86.28c.65.07.83-.51.41-1.31a3.13,3.13,0,0,0-1.93-1.57C532.82,83.33,532.64,83.91,533.05,84.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529.33,84.29a3,3,0,0,0,1.92,1.57c.65.07.83-.52.42-1.31A3,3,0,0,0,529.75,83C529.11,82.91,528.92,83.5,529.33,84.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525.59,83.88a3,3,0,0,0,1.91,1.57c.65.07.85-.52.44-1.31A3,3,0,0,0,526,82.58C525.39,82.51,525.19,83.09,525.59,83.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M521.85,83.48a2.93,2.93,0,0,0,1.9,1.56c.65.07.85-.52.45-1.31a2.93,2.93,0,0,0-1.9-1.56C521.66,82.11,521.45,82.69,521.85,83.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M518.09,83.09A2.9,2.9,0,0,0,520,84.65c.66.06.87-.52.47-1.31a2.87,2.87,0,0,0-1.89-1.56C517.91,81.71,517.7,82.3,518.09,83.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M514.33,82.71a2.82,2.82,0,0,0,1.88,1.55c.65.07.87-.52.48-1.31a2.83,2.83,0,0,0-1.88-1.56C514.16,81.33,513.94,81.92,514.33,82.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510.55,82.33a2.81,2.81,0,0,0,1.87,1.55c.66.06.88-.52.5-1.32A2.76,2.76,0,0,0,511.05,81C510.4,81,510.18,81.54,510.55,82.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M506.76,82a2.79,2.79,0,0,0,1.86,1.55c.66.06.9-.53.52-1.32a2.73,2.73,0,0,0-1.86-1.54C506.63,80.58,506.4,81.17,506.76,82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M503,81.6a2.74,2.74,0,0,0,1.85,1.55c.66.06.9-.53.53-1.32a2.7,2.7,0,0,0-1.84-1.54C502.85,80.22,502.61,80.81,503,81.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499.16,81.25A2.68,2.68,0,0,0,501,82.79c.67.06.91-.53.56-1.32a2.7,2.7,0,0,0-1.84-1.54C499.06,79.87,498.81,80.46,499.16,81.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.35,80.91a2.63,2.63,0,0,0,1.83,1.54c.66,0,.92-.54.57-1.33a2.63,2.63,0,0,0-1.83-1.53C495.26,79.53,495,80.12,495.35,80.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.53,80.58a2.59,2.59,0,0,0,1.81,1.53c.67.06.93-.54.59-1.33a2.62,2.62,0,0,0-1.82-1.53C491.45,79.2,491.19,79.79,491.53,80.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.7,80.25a2.51,2.51,0,0,0,1.8,1.53c.67.06.94-.54.6-1.33a2.51,2.51,0,0,0-1.8-1.52C487.64,78.87,487.37,79.46,487.7,80.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483.86,79.93a2.5,2.5,0,0,0,1.79,1.53c.67.05.94-.54.62-1.33a2.5,2.5,0,0,0-1.79-1.52C483.81,78.55,483.53,79.15,483.86,79.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M480,79.63a2.48,2.48,0,0,0,1.78,1.52c.67,0,.95-.54.63-1.33a2.43,2.43,0,0,0-1.78-1.52C480,78.24,479.69,78.84,480,79.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.15,79.33a2.44,2.44,0,0,0,1.77,1.52c.67.05,1-.55.65-1.34A2.43,2.43,0,0,0,476.8,78C476.14,78,475.84,78.54,476.15,79.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.28,79A2.4,2.4,0,0,0,474,80.55c.67,0,1-.55.67-1.33A2.39,2.39,0,0,0,473,77.7C472.29,77.65,472,78.25,472.28,79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M468.41,78.76a2.35,2.35,0,0,0,1.75,1.51c.67,0,1-.55.69-1.34a2.38,2.38,0,0,0-1.75-1.51C468.43,77.37,468.12,78,468.41,78.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M464.53,78.48A2.29,2.29,0,0,0,466.26,80c.68.05,1-.55.71-1.34a2.31,2.31,0,0,0-1.73-1.5C464.57,77.1,464.25,77.7,464.53,78.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M460.65,78.22a2.26,2.26,0,0,0,1.71,1.51c.68,0,1-.56.73-1.34a2.29,2.29,0,0,0-1.72-1.51C460.7,76.84,460.37,77.44,460.65,78.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M456.75,78a2.24,2.24,0,0,0,1.71,1.5c.67,0,1-.56.74-1.34a2.22,2.22,0,0,0-1.71-1.5C456.82,76.58,456.49,77.18,456.75,78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M452.85,77.72a2.16,2.16,0,0,0,1.69,1.5c.68,0,1-.56.76-1.34a2.19,2.19,0,0,0-1.69-1.5C452.94,76.34,452.6,76.94,452.85,77.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M448.94,77.49A2.16,2.16,0,0,0,450.62,79c.68,0,1-.56.78-1.35a2.18,2.18,0,0,0-1.68-1.49C449,76.1,448.7,76.7,448.94,77.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M445,77.26a2.09,2.09,0,0,0,1.66,1.49c.68,0,1-.56.8-1.35a2.14,2.14,0,0,0-1.67-1.49C445.15,75.88,444.79,76.48,445,77.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M441.11,77.05a2,2,0,0,0,1.65,1.48c.68,0,1-.57.81-1.35a2.06,2.06,0,0,0-1.65-1.48C441.24,75.66,440.88,76.26,441.11,77.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M437.18,76.84a2,2,0,0,0,1.64,1.48c.68,0,1.05-.57.83-1.35A2,2,0,0,0,438,75.49C437.33,75.45,437,76.06,437.18,76.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.25,76.64a2,2,0,0,0,1.62,1.48c.69,0,1.07-.57.85-1.36a2,2,0,0,0-1.62-1.47C433.42,75.25,433,75.86,433.25,76.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.31,76.45a2,2,0,0,0,1.61,1.48c.69,0,1.07-.58.86-1.36a2,2,0,0,0-1.6-1.47C429.5,75.06,429.11,75.67,429.31,76.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M425.37,76.27A1.92,1.92,0,0,0,427,77.75c.69,0,1.08-.58.89-1.36a1.94,1.94,0,0,0-1.6-1.47C425.57,74.88,425.18,75.49,425.37,76.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M421.42,76.11A1.9,1.9,0,0,0,423,77.58c.69,0,1.09-.59.9-1.37a1.92,1.92,0,0,0-1.58-1.47C421.64,74.72,421.24,75.32,421.42,76.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.47,76A1.84,1.84,0,0,0,419,77.41c.69,0,1.1-.58.92-1.36a1.87,1.87,0,0,0-1.56-1.47C417.7,74.56,417.29,75.17,417.47,76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.51,75.8a1.83,1.83,0,0,0,1.55,1.46c.69,0,1.11-.59.94-1.37a1.86,1.86,0,0,0-1.55-1.46C413.76,74.4,413.34,75,413.51,75.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.55,75.66a1.8,1.8,0,0,0,1.53,1.46c.69,0,1.12-.59,1-1.38a1.81,1.81,0,0,0-1.54-1.45C409.82,74.26,409.39,74.88,409.55,75.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M405.58,75.53A1.76,1.76,0,0,0,407.1,77c.69,0,1.13-.59,1-1.37a1.76,1.76,0,0,0-1.52-1.45C405.87,74.13,405.43,74.75,405.58,75.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.61,75.41a1.73,1.73,0,0,0,1.5,1.45,1,1,0,0,0,1-1.38A1.73,1.73,0,0,0,402.6,74,1.06,1.06,0,0,0,401.61,75.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397.64,75.3a1.69,1.69,0,0,0,1.48,1.45,1.06,1.06,0,0,0,1-1.38,1.71,1.71,0,0,0-1.48-1.45A1.07,1.07,0,0,0,397.64,75.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.66,75.2a1.66,1.66,0,0,0,1.47,1.44,1.09,1.09,0,0,0,1-1.38,1.68,1.68,0,0,0-1.47-1.44A1.09,1.09,0,0,0,393.66,75.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.68,75.11a1.64,1.64,0,0,0,1.45,1.44,1.1,1.1,0,0,0,1.05-1.39,1.66,1.66,0,0,0-1.46-1.44A1.11,1.11,0,0,0,389.68,75.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.69,75a1.62,1.62,0,0,0,1.44,1.44,1.13,1.13,0,0,0,1.07-1.39,1.63,1.63,0,0,0-1.44-1.44A1.13,1.13,0,0,0,385.69,75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.71,75a1.58,1.58,0,0,0,1.42,1.43A1.14,1.14,0,0,0,384.21,75a1.59,1.59,0,0,0-1.42-1.43A1.13,1.13,0,0,0,381.71,75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.72,74.9a1.55,1.55,0,0,0,1.4,1.43,1.16,1.16,0,0,0,1.1-1.4,1.57,1.57,0,0,0-1.41-1.43A1.16,1.16,0,0,0,377.72,74.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.72,74.84a1.54,1.54,0,0,0,1.4,1.43,1.17,1.17,0,0,0,1.11-1.39,1.54,1.54,0,0,0-1.39-1.43A1.18,1.18,0,0,0,373.72,74.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M369.73,74.8a1.51,1.51,0,0,0,1.37,1.43,1.2,1.2,0,0,0,1.14-1.4,1.51,1.51,0,0,0-1.38-1.42A1.19,1.19,0,0,0,369.73,74.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M365.73,74.77a1.49,1.49,0,0,0,1.36,1.43,1.22,1.22,0,0,0,1.15-1.41,1.49,1.49,0,0,0-1.36-1.42A1.22,1.22,0,0,0,365.73,74.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.73,74.74a1.43,1.43,0,0,0,1.33,1.42,1.26,1.26,0,0,0,1.19-1.41,1.45,1.45,0,0,0-1.33-1.42A1.27,1.27,0,0,0,357.73,74.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.73,74.75A1.38,1.38,0,0,0,351,76.16a1.3,1.3,0,0,0,1.22-1.42,1.37,1.37,0,0,0-1.29-1.4A1.3,1.3,0,0,0,349.73,74.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.73,74.77A1.35,1.35,0,0,0,347,76.18a1.33,1.33,0,0,0,1.24-1.42A1.36,1.36,0,0,0,347,73.35,1.33,1.33,0,0,0,345.73,74.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.72,74.84a1.3,1.3,0,0,0,1.24,1.4,1.37,1.37,0,0,0,1.27-1.43A1.3,1.3,0,0,0,339,73.42,1.37,1.37,0,0,0,337.72,74.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M329.71,75a1.26,1.26,0,0,0,1.21,1.39,1.42,1.42,0,0,0,1.3-1.43A1.26,1.26,0,0,0,331,73.52,1.42,1.42,0,0,0,329.71,75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M325.7,75a1.24,1.24,0,0,0,1.19,1.39A1.46,1.46,0,0,0,328.22,75,1.23,1.23,0,0,0,327,73.59,1.46,1.46,0,0,0,325.7,75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M230.25,79.66c-.26.79.08,1.38.77,1.33a2.21,2.21,0,0,0,1.69-1.52c.26-.79-.09-1.38-.77-1.33A2.24,2.24,0,0,0,230.25,79.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M226.32,80c-.27.78.07,1.38.76,1.32a2.24,2.24,0,0,0,1.7-1.52c.26-.79-.07-1.38-.75-1.33A2.29,2.29,0,0,0,226.32,80Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M218.49,80.63c-.28.79,0,1.38.72,1.32A2.35,2.35,0,0,0,221,80.42c.27-.79,0-1.38-.73-1.32A2.35,2.35,0,0,0,218.49,80.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.79,81.68c-.31.79,0,1.38.67,1.32a2.5,2.5,0,0,0,1.78-1.55c.3-.78,0-1.37-.68-1.31A2.47,2.47,0,0,0,206.79,81.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M202.91,82.05c-.32.79,0,1.37.65,1.31a2.5,2.5,0,0,0,1.79-1.54c.3-.79,0-1.38-.66-1.32A2.52,2.52,0,0,0,202.91,82.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M199,82.42c-.32.79,0,1.38.64,1.32a2.53,2.53,0,0,0,1.79-1.55c.32-.79,0-1.38-.63-1.31A2.55,2.55,0,0,0,199,82.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.16,82.81c-.33.79,0,1.38.62,1.31a2.6,2.6,0,0,0,1.81-1.55c.32-.79,0-1.38-.62-1.31A2.6,2.6,0,0,0,195.16,82.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.3,83.2c-.34.79-.07,1.38.6,1.31A2.59,2.59,0,0,0,193.72,83c.34-.79.07-1.38-.6-1.31A2.63,2.63,0,0,0,191.3,83.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187.45,83.61c-.35.79-.09,1.37.58,1.3a2.66,2.66,0,0,0,1.83-1.56c.35-.79.08-1.37-.58-1.3A2.66,2.66,0,0,0,187.45,83.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.6,84c-.35.79-.1,1.37.57,1.3A2.71,2.71,0,0,0,186,83.76c.34-.79.09-1.37-.58-1.3A2.74,2.74,0,0,0,183.6,84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179.77,84.44c-.37.79-.12,1.37.55,1.3a2.73,2.73,0,0,0,1.85-1.57c.36-.79.11-1.37-.55-1.3A2.79,2.79,0,0,0,179.77,84.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.94,84.87c-.37.79-.13,1.37.54,1.29a2.77,2.77,0,0,0,1.86-1.56c.36-.8.12-1.38-.54-1.3A2.78,2.78,0,0,0,175.94,84.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.12,85.3c-.37.79-.14,1.38.52,1.3A2.84,2.84,0,0,0,174.52,85c.37-.8.14-1.38-.52-1.3A2.88,2.88,0,0,0,172.12,85.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.31,85.75c-.38.79-.15,1.37.51,1.29a2.86,2.86,0,0,0,1.88-1.57c.38-.8.16-1.38-.5-1.3A2.91,2.91,0,0,0,168.31,85.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M164.52,86.2c-.4.79-.18,1.37.49,1.29a2.91,2.91,0,0,0,1.89-1.58c.38-.79.17-1.37-.49-1.29A2.94,2.94,0,0,0,164.52,86.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M160.73,86.66c-.4.79-.19,1.37.47,1.29a3,3,0,0,0,1.91-1.58c.39-.79.17-1.37-.48-1.29A3,3,0,0,0,160.73,86.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M157,87.13c-.41.79-.2,1.37.46,1.29a3,3,0,0,0,1.91-1.59c.4-.79.19-1.37-.46-1.29A3,3,0,0,0,157,87.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M153.18,87.6c-.41.8-.21,1.37.44,1.29a3,3,0,0,0,1.93-1.59c.4-.79.2-1.37-.45-1.28A3.06,3.06,0,0,0,153.18,87.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M149.42,88.09c-.41.79-.22,1.37.43,1.28a3.06,3.06,0,0,0,1.93-1.59c.41-.79.22-1.37-.43-1.28A3.11,3.11,0,0,0,149.42,88.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M145.68,88.58c-.43.79-.24,1.37.41,1.28A3.13,3.13,0,0,0,148,88.27c.42-.8.23-1.37-.41-1.28A3.1,3.1,0,0,0,145.68,88.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M141.94,89.08c-.43.79-.25,1.37.4,1.28a3.19,3.19,0,0,0,1.95-1.6c.42-.79.25-1.36-.4-1.28A3.19,3.19,0,0,0,141.94,89.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M138.22,89.58c-.44.8-.27,1.37.38,1.29a3.27,3.27,0,0,0,2-1.6c.43-.8.26-1.37-.39-1.28A3.22,3.22,0,0,0,138.22,89.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M134.51,90.1c-.45.79-.28,1.37.36,1.28a3.27,3.27,0,0,0,2-1.61c.43-.79.27-1.36-.37-1.27A3.26,3.26,0,0,0,134.51,90.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M130.81,90.62c-.45.8-.3,1.37.35,1.28a3.32,3.32,0,0,0,2-1.61c.44-.79.29-1.37-.35-1.28A3.29,3.29,0,0,0,130.81,90.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M127.12,91.15c-.46.79-.31,1.37.33,1.27a3.33,3.33,0,0,0,2-1.6c.45-.8.3-1.37-.33-1.28A3.34,3.34,0,0,0,127.12,91.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M123.44,91.68c-.46.8-.32,1.37.32,1.28a3.46,3.46,0,0,0,2-1.61c.46-.8.31-1.37-.32-1.28A3.46,3.46,0,0,0,123.44,91.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M119.78,92.22c-.47.8-.33,1.37.31,1.28a3.5,3.5,0,0,0,2-1.62c.46-.79.32-1.36-.31-1.27A3.49,3.49,0,0,0,119.78,92.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M116.13,92.77c-.48.8-.34,1.37.29,1.27a3.43,3.43,0,0,0,2-1.61c.47-.8.34-1.37-.3-1.27A3.48,3.48,0,0,0,116.13,92.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M112.49,93.33c-.48.8-.35,1.37.28,1.27a3.52,3.52,0,0,0,2-1.62c.48-.8.35-1.37-.28-1.27A3.55,3.55,0,0,0,112.49,93.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M108.87,93.89c-.49.8-.37,1.37.26,1.27a3.57,3.57,0,0,0,2-1.62c.48-.8.36-1.37-.27-1.27A3.58,3.58,0,0,0,108.87,93.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M105.25,94.46c-.48.8-.37,1.36.26,1.27a3.62,3.62,0,0,0,2-1.63c.49-.8.37-1.36-.25-1.26A3.62,3.62,0,0,0,105.25,94.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M101.66,95c-.5.8-.39,1.37.24,1.27a3.62,3.62,0,0,0,2-1.63c.49-.79.39-1.36-.23-1.26A3.66,3.66,0,0,0,101.66,95Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M98.07,95.62c-.49.79-.39,1.36.23,1.26a3.67,3.67,0,0,0,2-1.63c.5-.8.4-1.36-.22-1.26A3.72,3.72,0,0,0,98.07,95.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M94.51,96.2c-.51.8-.41,1.37.21,1.26a3.72,3.72,0,0,0,2-1.63c.49-.79.4-1.36-.22-1.26A3.74,3.74,0,0,0,94.51,96.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M91,96.8c-.51.79-.42,1.36.2,1.26a3.79,3.79,0,0,0,2-1.64c.51-.8.42-1.36-.2-1.26A3.86,3.86,0,0,0,91,96.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M66.5,101.1c-.54.8-.49,1.36.11,1.25a4,4,0,0,0,2-1.64c.53-.8.48-1.36-.12-1.25A4.09,4.09,0,0,0,66.5,101.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M63.07,101.74c-.54.8-.5,1.36.1,1.25a4.12,4.12,0,0,0,2-1.65c.54-.8.49-1.36-.1-1.25A4.08,4.08,0,0,0,63.07,101.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M59.65,102.38c-.54.8-.5,1.36.09,1.25a4.13,4.13,0,0,0,2-1.65c.55-.8.5-1.36-.09-1.25A4.16,4.16,0,0,0,59.65,102.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M56.26,103c-.55.79-.51,1.35.08,1.24a4.16,4.16,0,0,0,2-1.65c.54-.8.51-1.35-.08-1.24A4.12,4.12,0,0,0,56.26,103Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M52.88,103.68c-.55.79-.52,1.35.07,1.24a4.26,4.26,0,0,0,2-1.65c.54-.8.51-1.36-.07-1.24A4.16,4.16,0,0,0,52.88,103.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M49.52,104.33c-.56.8-.53,1.35,0,1.24a4.18,4.18,0,0,0,2.06-1.65c.55-.8.52-1.35-.06-1.24A4.2,4.2,0,0,0,49.52,104.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M46.17,105c-.55.8-.53,1.35,0,1.24a4.29,4.29,0,0,0,2.05-1.66c.55-.79.53-1.35-.05-1.23A4.24,4.24,0,0,0,46.17,105Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M42.85,105.65c-.56.8-.54,1.35,0,1.24a4.32,4.32,0,0,0,2-1.65c.56-.8.54-1.36,0-1.24A4.23,4.23,0,0,0,42.85,105.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M39.54,106.32c-.56.79-.55,1.35,0,1.23a4.23,4.23,0,0,0,2-1.65c.56-.8.55-1.35,0-1.23A4.24,4.24,0,0,0,39.54,106.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M33,107.66c-.56.8-.56,1.35,0,1.23a4.38,4.38,0,0,0,2-1.65c.56-.8.56-1.35,0-1.23A4.39,4.39,0,0,0,33,107.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M615.55,92.27a4.09,4.09,0,0,0,2.05,1.63c.59.1.62-.47.07-1.27a4.08,4.08,0,0,0-2-1.62C615,90.91,615,91.47,615.55,92.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M608.78,91.13a3.93,3.93,0,0,0,2,1.62c.59.1.63-.47.09-1.26a4,4,0,0,0-2-1.63C608.28,89.77,608.24,90.33,608.78,91.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M605.37,90.57a3.89,3.89,0,0,0,2,1.62c.59.1.64-.47.1-1.27a4,4,0,0,0-2-1.62C604.88,89.2,604.83,89.77,605.37,90.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M601.94,90a3.89,3.89,0,0,0,2,1.62c.59.1.64-.47.11-1.27a3.91,3.91,0,0,0-2-1.62C601.46,88.65,601.41,89.21,601.94,90Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M577.57,86.28a3.63,3.63,0,0,0,2,1.61c.62.08.71-.49.21-1.28a3.6,3.6,0,0,0-2-1.6C577.17,84.92,577.08,85.49,577.57,86.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574,85.78a3.54,3.54,0,0,0,2,1.59c.62.09.72-.48.22-1.28a3.57,3.57,0,0,0-2-1.59C573.64,84.41,573.55,85,574,85.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M570.49,85.28a3.55,3.55,0,0,0,2,1.59c.62.09.72-.49.23-1.28a3.57,3.57,0,0,0-2-1.6C570.11,83.91,570,84.48,570.49,85.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M566.93,84.78a3.45,3.45,0,0,0,2,1.59c.62.09.73-.48.25-1.28a3.52,3.52,0,0,0-2-1.59C566.56,83.41,566.45,84,566.93,84.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M563.36,84.29a3.36,3.36,0,0,0,2,1.59c.62.09.73-.49.25-1.28a3.48,3.48,0,0,0-2-1.59C563,82.93,562.88,83.5,563.36,84.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M559.78,83.81a3.32,3.32,0,0,0,2,1.59c.62.08.74-.49.27-1.28a3.4,3.4,0,0,0-2-1.59C559.43,82.45,559.31,83,559.78,83.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M556.18,83.34a3.41,3.41,0,0,0,2,1.59c.63.08.76-.5.29-1.29a3.37,3.37,0,0,0-2-1.58C555.84,82,555.72,82.55,556.18,83.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M552.57,82.88a3.32,3.32,0,0,0,2,1.58c.63.08.76-.5.3-1.29a3.32,3.32,0,0,0-2-1.58C552.25,81.51,552.11,82.08,552.57,82.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549,82.42a3.25,3.25,0,0,0,2,1.57c.63.08.77-.49.32-1.29a3.31,3.31,0,0,0-2-1.57C548.64,81.05,548.5,81.63,549,82.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.32,82a3.24,3.24,0,0,0,1.95,1.57c.63.08.78-.5.33-1.29a3.26,3.26,0,0,0-2-1.58C545,80.6,544.88,81.17,545.32,82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.68,81.52a3.16,3.16,0,0,0,1.94,1.57c.64.08.79-.5.35-1.29a3.24,3.24,0,0,0-2-1.57C541.39,80.15,541.24,80.73,541.68,81.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M538,81.09A3.08,3.08,0,0,0,540,82.65c.64.08.8-.5.36-1.29a3.18,3.18,0,0,0-1.93-1.57C537.76,79.72,537.59,80.29,538,81.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M534.36,80.66a3.08,3.08,0,0,0,1.93,1.56c.64.08.8-.51.37-1.3a3.12,3.12,0,0,0-1.92-1.56C534.1,79.29,533.94,79.87,534.36,80.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530.69,80.23a3,3,0,0,0,1.91,1.57c.64.07.82-.51.4-1.3a3.08,3.08,0,0,0-1.92-1.56C530.44,78.87,530.27,79.45,530.69,80.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M527,79.82a3,3,0,0,0,1.91,1.56c.64.07.82-.51.41-1.3a3,3,0,0,0-1.91-1.56C526.77,78.45,526.59,79,527,79.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M523.31,79.42A3,3,0,0,0,525.2,81c.65.07.84-.51.43-1.3a3,3,0,0,0-1.9-1.55C523.09,78.05,522.9,78.63,523.31,79.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M519.6,79a2.9,2.9,0,0,0,1.89,1.55c.65.07.84-.52.44-1.3A2.92,2.92,0,0,0,520,77.72C519.4,77.65,519.2,78.23,519.6,79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M515.88,78.63a2.91,2.91,0,0,0,1.88,1.55c.65.06.86-.52.46-1.31a2.86,2.86,0,0,0-1.88-1.54C515.7,77.26,515.49,77.84,515.88,78.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M512.16,78.25A2.81,2.81,0,0,0,514,79.79c.65.07.86-.52.47-1.3a2.85,2.85,0,0,0-1.87-1.55C512,76.88,511.78,77.46,512.16,78.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M508.43,77.87a2.73,2.73,0,0,0,1.85,1.54c.66.07.87-.52.49-1.3a2.77,2.77,0,0,0-1.86-1.54C508.27,76.5,508.05,77.09,508.43,77.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M504.68,77.51a2.79,2.79,0,0,0,1.85,1.54c.65.06.88-.53.5-1.31a2.74,2.74,0,0,0-1.84-1.54C504.54,76.14,504.31,76.72,504.68,77.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M500.93,77.15a2.7,2.7,0,0,0,1.84,1.54c.65.06.88-.53.52-1.32a2.73,2.73,0,0,0-1.84-1.53C500.8,75.78,500.57,76.37,500.93,77.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M497.17,76.8A2.63,2.63,0,0,0,499,78.33c.66.06.9-.52.54-1.31a2.69,2.69,0,0,0-1.83-1.53C497.05,75.43,496.81,76,497.17,76.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M493.4,76.46A2.6,2.6,0,0,0,495.21,78c.66.06.91-.53.56-1.31A2.65,2.65,0,0,0,494,75.15C493.3,75.09,493.05,75.68,493.4,76.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.62,76.13a2.57,2.57,0,0,0,1.8,1.53c.66,0,.92-.54.57-1.32a2.57,2.57,0,0,0-1.8-1.52C489.54,74.76,489.28,75.35,489.62,76.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.83,75.81a2.54,2.54,0,0,0,1.79,1.52c.66.06.93-.54.59-1.32a2.58,2.58,0,0,0-1.79-1.52C485.76,74.44,485.5,75,485.83,75.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M482,75.5A2.46,2.46,0,0,0,483.82,77c.66.06.93-.54.6-1.32a2.51,2.51,0,0,0-1.78-1.51C482,74.12,481.71,74.71,482,75.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.23,75.19A2.46,2.46,0,0,0,480,76.7c.66.06.94-.54.62-1.32a2.46,2.46,0,0,0-1.77-1.51C478.2,73.82,477.92,74.41,478.23,75.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.42,74.89a2.39,2.39,0,0,0,1.76,1.51c.66,0,.95-.54.64-1.32a2.45,2.45,0,0,0-1.76-1.51C474.4,73.52,474.12,74.11,474.42,74.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M470.6,74.61a2.37,2.37,0,0,0,1.75,1.5c.66,0,1-.54.65-1.32a2.37,2.37,0,0,0-1.74-1.51C470.6,73.23,470.31,73.83,470.6,74.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M466.78,74.33a2.33,2.33,0,0,0,1.73,1.5c.67,0,1-.55.67-1.33a2.34,2.34,0,0,0-1.73-1.5C466.79,73,466.49,73.55,466.78,74.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M463,74.06a2.28,2.28,0,0,0,1.71,1.5c.67,0,1-.55.69-1.33a2.3,2.3,0,0,0-1.71-1.5C463,72.68,462.66,73.28,463,74.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M459.11,73.8a2.22,2.22,0,0,0,1.7,1.49c.67,0,1-.55.71-1.33a2.25,2.25,0,0,0-1.71-1.49C459.15,72.42,458.83,73,459.11,73.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455.26,73.55A2.21,2.21,0,0,0,457,75c.67,0,1-.56.73-1.34a2.24,2.24,0,0,0-1.7-1.49C455.32,72.17,455,72.77,455.26,73.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M451.41,73.3a2.16,2.16,0,0,0,1.67,1.49c.68,0,1-.56.75-1.33A2.2,2.2,0,0,0,452.15,72C451.48,71.93,451.15,72.53,451.41,73.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M447.55,73.07a2.12,2.12,0,0,0,1.66,1.48c.68,0,1-.56.76-1.33a2.13,2.13,0,0,0-1.66-1.48C447.64,71.69,447.3,72.29,447.55,73.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.68,72.85a2.11,2.11,0,0,0,1.65,1.48c.68,0,1-.56.78-1.34a2.11,2.11,0,0,0-1.65-1.48C443.79,71.47,443.45,72.07,443.68,72.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M439.81,72.63a2.07,2.07,0,0,0,1.64,1.48c.67,0,1-.57.79-1.34a2.07,2.07,0,0,0-1.63-1.48C439.94,71.26,439.58,71.86,439.81,72.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435.94,72.43a2,2,0,0,0,1.62,1.47c.67,0,1-.57.81-1.34a2,2,0,0,0-1.62-1.47C436.08,71.05,435.72,71.65,435.94,72.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M432.05,72.23a2,2,0,0,0,1.61,1.47c.68,0,1-.57.83-1.35a2,2,0,0,0-1.61-1.46C432.21,70.85,431.84,71.46,432.05,72.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.17,72.05a2,2,0,0,0,1.59,1.46c.68,0,1.06-.57.85-1.35A2,2,0,0,0,429,70.7C428.34,70.67,428,71.27,428.17,72.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M424.27,71.87a1.92,1.92,0,0,0,1.58,1.46c.68,0,1.07-.57.87-1.35a1.93,1.93,0,0,0-1.58-1.46C424.47,70.49,424.08,71.09,424.27,71.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M420.38,71.7a1.87,1.87,0,0,0,1.56,1.46c.68,0,1.08-.58.89-1.35a1.92,1.92,0,0,0-1.57-1.46C420.59,70.32,420.19,70.93,420.38,71.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.48,71.54A1.84,1.84,0,0,0,418,73c.68,0,1.09-.58.91-1.36a1.89,1.89,0,0,0-1.55-1.45C416.7,70.16,416.3,70.77,416.48,71.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.57,71.4a1.83,1.83,0,0,0,1.53,1.45c.68,0,1.1-.59.92-1.36A1.83,1.83,0,0,0,413.49,70C412.82,70,412.4,70.62,412.57,71.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.66,71.26a1.81,1.81,0,0,0,1.52,1.45c.68,0,1.1-.59.94-1.37a1.81,1.81,0,0,0-1.52-1.44C408.92,69.88,408.5,70.48,408.66,71.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.74,71.13a1.74,1.74,0,0,0,1.51,1.44c.68,0,1.11-.59.95-1.36a1.74,1.74,0,0,0-1.5-1.44C405,69.75,404.6,70.36,404.74,71.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.83,71a1.7,1.7,0,0,0,1.48,1.44c.69,0,1.12-.59,1-1.37a1.73,1.73,0,0,0-1.49-1.43C401.12,69.63,400.69,70.24,400.83,71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.9,70.9a1.69,1.69,0,0,0,1.48,1.44,1.06,1.06,0,0,0,1-1.37,1.71,1.71,0,0,0-1.47-1.44A1.07,1.07,0,0,0,396.9,70.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393,70.8a1.66,1.66,0,0,0,1.45,1.44,1.07,1.07,0,0,0,1-1.38A1.66,1.66,0,0,0,394,69.43,1.08,1.08,0,0,0,393,70.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.05,70.71a1.62,1.62,0,0,0,1.44,1.43,1.08,1.08,0,0,0,1-1.37,1.64,1.64,0,0,0-1.44-1.43A1.09,1.09,0,0,0,389.05,70.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.12,70.63a1.59,1.59,0,0,0,1.42,1.43,1.11,1.11,0,0,0,1-1.38,1.61,1.61,0,0,0-1.43-1.42A1.11,1.11,0,0,0,385.12,70.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.18,70.56A1.59,1.59,0,0,0,382.59,72a1.13,1.13,0,0,0,1.07-1.38,1.58,1.58,0,0,0-1.41-1.42A1.12,1.12,0,0,0,381.18,70.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.25,70.5a1.53,1.53,0,0,0,1.39,1.42,1.14,1.14,0,0,0,1.08-1.38,1.56,1.56,0,0,0-1.39-1.42A1.14,1.14,0,0,0,377.25,70.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.31,70.45a1.51,1.51,0,0,0,1.37,1.42,1.16,1.16,0,0,0,1.1-1.39,1.52,1.52,0,0,0-1.37-1.41A1.16,1.16,0,0,0,373.31,70.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M369.37,70.41a1.49,1.49,0,0,0,1.36,1.42,1.19,1.19,0,0,0,1.11-1.39A1.49,1.49,0,0,0,370.48,69,1.17,1.17,0,0,0,369.37,70.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M365.42,70.38a1.46,1.46,0,0,0,1.35,1.41,1.2,1.2,0,0,0,1.13-1.39A1.48,1.48,0,0,0,366.56,69,1.22,1.22,0,0,0,365.42,70.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.48,70.36a1.43,1.43,0,0,0,1.32,1.41,1.23,1.23,0,0,0,1.16-1.4,1.45,1.45,0,0,0-1.33-1.4A1.23,1.23,0,0,0,361.48,70.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.53,70.35a1.41,1.41,0,0,0,1.31,1.41,1.25,1.25,0,0,0,1.17-1.4A1.41,1.41,0,0,0,358.7,69,1.25,1.25,0,0,0,357.53,70.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M353.58,70.35a1.39,1.39,0,0,0,1.3,1.4,1.26,1.26,0,0,0,1.18-1.4,1.39,1.39,0,0,0-1.29-1.4A1.28,1.28,0,0,0,353.58,70.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.63,70.36a1.36,1.36,0,0,0,1.28,1.4,1.28,1.28,0,0,0,1.2-1.41A1.36,1.36,0,0,0,350.84,69,1.3,1.3,0,0,0,349.63,70.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.68,70.38a1.35,1.35,0,0,0,1.26,1.4,1.41,1.41,0,1,0-1.26-1.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.73,70.41A1.32,1.32,0,0,0,343,71.8a1.41,1.41,0,0,0,0-2.8A1.36,1.36,0,0,0,341.73,70.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.78,70.45A1.3,1.3,0,0,0,339,71.84a1.35,1.35,0,0,0,1.25-1.42A1.29,1.29,0,0,0,339,69,1.37,1.37,0,0,0,337.78,70.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M333.83,70.5A1.28,1.28,0,0,0,335,71.89a1.38,1.38,0,0,0,1.27-1.42,1.27,1.27,0,0,0-1.2-1.39A1.4,1.4,0,0,0,333.83,70.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M329.88,70.56a1.25,1.25,0,0,0,1.19,1.38,1.41,1.41,0,0,0,1.29-1.42,1.25,1.25,0,0,0-1.19-1.38A1.42,1.42,0,0,0,329.88,70.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M325.93,70.63A1.23,1.23,0,0,0,327.1,72a1.43,1.43,0,0,0,1.31-1.43,1.22,1.22,0,0,0-1.17-1.37A1.44,1.44,0,0,0,325.93,70.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M231.7,75.23c-.26.78.08,1.38.76,1.32A2.2,2.2,0,0,0,234.14,75c.25-.78-.08-1.37-.75-1.31A2.23,2.23,0,0,0,231.7,75.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M220.09,76.19c-.29.79,0,1.37.7,1.32A2.34,2.34,0,0,0,222.52,76c.27-.79,0-1.37-.71-1.31A2.31,2.31,0,0,0,220.09,76.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M216.23,76.53c-.29.79,0,1.37.69,1.31a2.36,2.36,0,0,0,1.73-1.52c.29-.78,0-1.37-.69-1.31A2.39,2.39,0,0,0,216.23,76.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212.38,76.88c-.3.78,0,1.37.67,1.31a2.43,2.43,0,0,0,1.75-1.53c.29-.78,0-1.37-.68-1.31A2.44,2.44,0,0,0,212.38,76.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M208.53,77.24c-.31.78,0,1.37.65,1.3A2.42,2.42,0,0,0,210.94,77c.31-.78,0-1.36-.65-1.3A2.46,2.46,0,0,0,208.53,77.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.69,77.6c-.32.79,0,1.37.64,1.31a2.49,2.49,0,0,0,1.77-1.54c.31-.78,0-1.37-.64-1.3A2.51,2.51,0,0,0,204.69,77.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200.86,78c-.33.78-.05,1.36.62,1.3a2.53,2.53,0,0,0,1.78-1.54c.32-.78.05-1.37-.62-1.3A2.52,2.52,0,0,0,200.86,78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197,78.36c-.33.78-.06,1.37.61,1.3a2.58,2.58,0,0,0,1.79-1.54c.33-.79.06-1.37-.6-1.3A2.58,2.58,0,0,0,197,78.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.22,78.75c-.34.79-.08,1.37.58,1.3a2.6,2.6,0,0,0,1.81-1.55c.34-.78.07-1.36-.58-1.29A2.6,2.6,0,0,0,193.22,78.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.41,79.15c-.35.79-.1,1.37.57,1.3a2.68,2.68,0,0,0,1.82-1.55c.34-.79.09-1.37-.57-1.3A2.65,2.65,0,0,0,189.41,79.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.81,80c-.36.78-.12,1.36.54,1.29a2.72,2.72,0,0,0,1.84-1.56c.36-.78.12-1.36-.54-1.29A2.76,2.76,0,0,0,181.81,80Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178,80.4c-.37.79-.14,1.37.52,1.29a2.75,2.75,0,0,0,1.85-1.56c.37-.78.13-1.36-.52-1.29A2.82,2.82,0,0,0,178,80.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.25,80.83c-.38.79-.15,1.37.51,1.3a2.85,2.85,0,0,0,1.86-1.57c.37-.79.15-1.36-.5-1.29A2.88,2.88,0,0,0,174.25,80.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170.48,81.28c-.38.79-.16,1.36.49,1.29A2.88,2.88,0,0,0,172.85,81c.38-.79.16-1.37-.49-1.29A2.93,2.93,0,0,0,170.48,81.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.72,81.73c-.39.79-.17,1.36.48,1.28a2.88,2.88,0,0,0,1.88-1.57c.39-.79.18-1.36-.47-1.28A3,3,0,0,0,166.72,81.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M163,82.18c-.4.79-.2,1.37.45,1.29a2.94,2.94,0,0,0,1.9-1.57c.39-.79.19-1.37-.46-1.29A3,3,0,0,0,163,82.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.24,82.65c-.41.79-.21,1.37.44,1.28a3,3,0,0,0,1.9-1.57c.41-.79.21-1.37-.44-1.29A3.06,3.06,0,0,0,159.24,82.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.51,83.12c-.42.79-.22,1.37.43,1.29a3.1,3.1,0,0,0,1.91-1.58c.41-.79.21-1.37-.43-1.29A3.1,3.1,0,0,0,155.51,83.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M151.79,83.61c-.42.79-.24,1.36.41,1.28a3.11,3.11,0,0,0,1.92-1.59c.42-.79.23-1.36-.41-1.28A3.12,3.12,0,0,0,151.79,83.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M148.08,84.1c-.43.79-.25,1.36.4,1.27a3.12,3.12,0,0,0,1.93-1.58c.42-.79.24-1.37-.4-1.28A3.11,3.11,0,0,0,148.08,84.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M144.38,84.59c-.43.79-.26,1.37.38,1.28a3.19,3.19,0,0,0,1.94-1.59c.43-.79.26-1.36-.38-1.28A3.22,3.22,0,0,0,144.38,84.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M140.69,85.1c-.44.79-.27,1.36.37,1.27A3.26,3.26,0,0,0,143,84.78c.43-.79.27-1.36-.37-1.28A3.24,3.24,0,0,0,140.69,85.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M137,85.61c-.44.79-.28,1.36.36,1.27a3.26,3.26,0,0,0,1.95-1.59c.44-.8.29-1.37-.35-1.28A3.32,3.32,0,0,0,137,85.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M133.35,86.13c-.45.79-.3,1.36.34,1.27a3.35,3.35,0,0,0,2-1.6c.45-.79.29-1.36-.34-1.27A3.31,3.31,0,0,0,133.35,86.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M129.69,86.65c-.45.8-.31,1.37.33,1.27a3.33,3.33,0,0,0,2-1.6c.45-.79.31-1.36-.33-1.27A3.4,3.4,0,0,0,129.69,86.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M126.05,87.19c-.46.79-.32,1.36.31,1.27a3.47,3.47,0,0,0,2-1.61c.46-.79.32-1.36-.31-1.27A3.4,3.4,0,0,0,126.05,87.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M122.42,87.73c-.47.79-.34,1.36.3,1.26a3.44,3.44,0,0,0,2-1.6c.46-.8.33-1.37-.3-1.27A3.45,3.45,0,0,0,122.42,87.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.8,88.27c-.47.8-.35,1.37.28,1.27a3.48,3.48,0,0,0,2-1.61c.47-.8.35-1.36-.28-1.27A3.55,3.55,0,0,0,118.8,88.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M115.19,88.83c-.48.79-.36,1.36.27,1.26a3.53,3.53,0,0,0,2-1.61c.47-.8.35-1.36-.27-1.27A3.58,3.58,0,0,0,115.19,88.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M111.6,89.39c-.48.79-.37,1.36.25,1.26a3.62,3.62,0,0,0,2-1.61c.48-.8.36-1.37-.26-1.27A3.58,3.58,0,0,0,111.6,89.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M108,90c-.49.8-.38,1.37.24,1.27a3.67,3.67,0,0,0,2-1.62c.48-.8.37-1.36-.24-1.26A3.61,3.61,0,0,0,108,90Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M104.45,90.53c-.5.79-.39,1.36.23,1.26a3.67,3.67,0,0,0,2-1.62c.49-.8.39-1.36-.23-1.26A3.66,3.66,0,0,0,104.45,90.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M100.89,91.11c-.5.79-.4,1.36.22,1.26a3.7,3.7,0,0,0,2-1.63c.49-.79.4-1.36-.22-1.26A3.78,3.78,0,0,0,100.89,91.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M97.35,91.69c-.5.8-.41,1.36.2,1.26a3.78,3.78,0,0,0,2-1.62c.5-.8.41-1.36-.21-1.26A3.8,3.8,0,0,0,97.35,91.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M93.82,92.29c-.51.79-.42,1.36.19,1.25a3.76,3.76,0,0,0,2-1.63c.5-.79.42-1.35-.19-1.25A3.79,3.79,0,0,0,93.82,92.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M90.31,92.88c-.51.8-.44,1.36.18,1.26a3.87,3.87,0,0,0,2-1.63c.51-.8.43-1.36-.18-1.26A3.89,3.89,0,0,0,90.31,92.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M86.81,93.49c-.52.8-.45,1.36.16,1.25a3.84,3.84,0,0,0,2-1.63c.51-.8.44-1.36-.17-1.25A3.86,3.86,0,0,0,86.81,93.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M83.32,94.1c-.52.79-.45,1.36.15,1.25a3.92,3.92,0,0,0,2-1.64c.52-.79.45-1.35-.15-1.25A4,4,0,0,0,83.32,94.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M79.85,94.71c-.52.8-.46,1.36.14,1.25a3.93,3.93,0,0,0,2-1.63c.52-.8.46-1.36-.14-1.25A4,4,0,0,0,79.85,94.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M76.4,95.33c-.53.8-.48,1.36.12,1.25a4,4,0,0,0,2-1.64c.53-.79.47-1.35-.12-1.24A4,4,0,0,0,76.4,95.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M73,96c-.53.8-.48,1.36.12,1.25a4,4,0,0,0,2-1.64c.53-.8.48-1.36-.11-1.25A4,4,0,0,0,73,96Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M69.53,96.59c-.54.8-.49,1.36.1,1.25a4.08,4.08,0,0,0,2.05-1.65c.53-.79.48-1.35-.11-1.24A4,4,0,0,0,69.53,96.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M66.12,97.23c-.54.8-.5,1.35.09,1.24a4.09,4.09,0,0,0,2.05-1.64c.54-.8.49-1.36-.1-1.25A4.1,4.1,0,0,0,66.12,97.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M62.72,97.87c-.54.8-.5,1.35.08,1.24a4.14,4.14,0,0,0,2-1.64c.54-.8.51-1.36-.08-1.25A4.21,4.21,0,0,0,62.72,97.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M59.34,98.52c-.54.79-.51,1.35.08,1.24a4.2,4.2,0,0,0,2-1.65c.55-.8.51-1.35-.07-1.24A4.2,4.2,0,0,0,59.34,98.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M56,99.17c-.55.79-.52,1.35.06,1.24a4.27,4.27,0,0,0,2.05-1.65c.55-.8.52-1.36-.06-1.24A4.2,4.2,0,0,0,56,99.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M52.63,99.82c-.55.8-.53,1.35.06,1.24a4.18,4.18,0,0,0,2-1.65c.55-.8.53-1.35,0-1.24A4.3,4.3,0,0,0,52.63,99.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M49.3,100.48c-.55.8-.53,1.35.05,1.24a4.35,4.35,0,0,0,2-1.65c.56-.8.54-1.35,0-1.24A4.3,4.3,0,0,0,49.3,100.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M46,101.15c-.55.79-.54,1.35,0,1.23a4.26,4.26,0,0,0,2-1.65c.56-.8.54-1.35,0-1.24A4.33,4.33,0,0,0,46,101.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M42.7,101.81c-.56.8-.55,1.35,0,1.24a4.31,4.31,0,0,0,2-1.66c.56-.79.55-1.34,0-1.23A4.35,4.35,0,0,0,42.7,101.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M39.42,102.49c-.56.79-.56,1.34,0,1.23a4.31,4.31,0,0,0,2-1.66c.56-.79.56-1.34,0-1.23A4.36,4.36,0,0,0,39.42,102.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M602.35,86.07a3.94,3.94,0,0,0,2,1.61c.59.1.63-.47.1-1.26a4,4,0,0,0-2-1.62C601.86,84.7,601.81,85.27,602.35,86.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M598.94,85.51a3.82,3.82,0,0,0,2,1.61c.59.1.64-.47.1-1.26a3.91,3.91,0,0,0-2-1.62C598.46,84.15,598.41,84.71,598.94,85.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M595.53,85a3.76,3.76,0,0,0,2,1.61c.59.1.64-.47.11-1.26a3.86,3.86,0,0,0-2-1.62C595.06,83.6,595,84.17,595.53,85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574.76,81.8a3.61,3.61,0,0,0,2,1.59c.61.09.7-.48.19-1.27a3.63,3.63,0,0,0-2-1.6C574.35,80.44,574.26,81,574.76,81.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M571.25,81.29a3.63,3.63,0,0,0,2,1.6c.61.08.7-.49.21-1.28a3.57,3.57,0,0,0-2-1.59C570.85,79.93,570.76,80.5,571.25,81.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M567.74,80.79a3.48,3.48,0,0,0,2,1.59c.61.09.71-.48.22-1.27a3.52,3.52,0,0,0-2-1.59C567.35,79.43,567.25,80,567.74,80.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.21,80.3a3.51,3.51,0,0,0,2,1.59c.62.08.72-.49.24-1.28a3.5,3.5,0,0,0-2-1.58C563.83,78.94,563.73,79.51,564.21,80.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M560.67,79.82a3.39,3.39,0,0,0,2,1.58c.61.09.72-.49.24-1.28a3.43,3.43,0,0,0-2-1.58C560.3,78.46,560.19,79,560.67,79.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M557.12,79.34a3.37,3.37,0,0,0,2,1.58c.62.08.73-.49.26-1.28a3.43,3.43,0,0,0-2-1.58C556.76,78,556.65,78.55,557.12,79.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M553.55,78.87a3.37,3.37,0,0,0,2,1.58c.62.08.74-.49.27-1.28a3.36,3.36,0,0,0-2-1.58C553.21,77.51,553.09,78.08,553.55,78.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M550,78.41a3.31,3.31,0,0,0,2,1.57c.62.08.75-.49.29-1.28a3.34,3.34,0,0,0-2-1.57C549.65,77.05,549.52,77.62,550,78.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M546.39,78a3.29,3.29,0,0,0,2,1.57c.63.08.77-.49.31-1.28a3.29,3.29,0,0,0-1.95-1.57C546.08,76.59,545.94,77.17,546.39,78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M542.8,77.5a3.25,3.25,0,0,0,1.94,1.57c.63.08.77-.5.32-1.28a3.25,3.25,0,0,0-1.94-1.57C542.5,76.14,542.35,76.72,542.8,77.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.19,77.06a3.2,3.2,0,0,0,1.94,1.57c.63.07.77-.5.33-1.29a3.15,3.15,0,0,0-1.93-1.56C538.9,75.7,538.75,76.28,539.19,77.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.58,76.63a3.08,3.08,0,0,0,1.92,1.56c.63.08.79-.5.35-1.29a3.19,3.19,0,0,0-1.93-1.56C535.3,75.27,535.14,75.84,535.58,76.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M532,76.2a3.06,3.06,0,0,0,1.92,1.56c.63.07.79-.5.36-1.29a3.1,3.1,0,0,0-1.92-1.55C531.69,74.84,531.52,75.42,532,76.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M528.31,75.79a3.06,3.06,0,0,0,1.91,1.55c.63.07.8-.51.38-1.29a3.05,3.05,0,0,0-1.91-1.55C528.06,74.42,527.89,75,528.31,75.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M524.67,75.38a3,3,0,0,0,1.9,1.55c.63.07.81-.51.39-1.3a3,3,0,0,0-1.9-1.54C524.43,74,524.25,74.59,524.67,75.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M521,75a3,3,0,0,0,1.89,1.55c.64.07.82-.51.41-1.29a3,3,0,0,0-1.89-1.55C520.79,73.61,520.6,74.19,521,75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M517.34,74.58a2.94,2.94,0,0,0,1.88,1.54c.64.07.83-.51.43-1.29a2.91,2.91,0,0,0-1.88-1.54C517.14,73.22,517,73.8,517.34,74.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M513.67,74.19a2.88,2.88,0,0,0,1.87,1.54c.64.07.84-.51.44-1.3a2.86,2.86,0,0,0-1.87-1.53C513.48,72.83,513.28,73.41,513.67,74.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510,73.81a2.8,2.8,0,0,0,1.85,1.54c.65.07.85-.52.46-1.3a2.82,2.82,0,0,0-1.86-1.53C509.81,72.45,509.6,73,510,73.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M506.29,73.44A2.8,2.8,0,0,0,508.14,75c.65.06.86-.52.47-1.3a2.76,2.76,0,0,0-1.84-1.53C506.13,72.08,505.92,72.66,506.29,73.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M502.59,73.08a2.73,2.73,0,0,0,1.84,1.53c.64.06.86-.52.49-1.3a2.8,2.8,0,0,0-1.84-1.53C502.44,71.72,502.22,72.3,502.59,73.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M498.88,72.73a2.68,2.68,0,0,0,1.83,1.52c.65.06.87-.52.5-1.3a2.68,2.68,0,0,0-1.82-1.52C498.75,71.37,498.52,72,498.88,72.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.16,72.38A2.66,2.66,0,0,0,497,73.9c.65.06.88-.52.52-1.3a2.67,2.67,0,0,0-1.81-1.52C495,71,494.81,71.6,495.16,72.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.44,72.05a2.58,2.58,0,0,0,1.8,1.51c.65.06.89-.52.54-1.3A2.63,2.63,0,0,0,492,70.74C491.33,70.68,491.09,71.27,491.44,72.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.7,71.72a2.57,2.57,0,0,0,1.79,1.51c.65.06.9-.53.56-1.31a2.6,2.6,0,0,0-1.79-1.51C487.61,70.36,487.36,70.94,487.7,71.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M484,71.4a2.53,2.53,0,0,0,1.78,1.51c.65,0,.91-.53.57-1.31a2.53,2.53,0,0,0-1.78-1.51C483.89,70,483.63,70.62,484,71.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M480.21,71.09a2.47,2.47,0,0,0,1.77,1.5c.65.06.92-.53.59-1.31a2.48,2.48,0,0,0-1.77-1.5C480.15,69.72,479.89,70.31,480.21,71.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.45,70.78a2.47,2.47,0,0,0,1.76,1.51c.65,0,.92-.54.6-1.32a2.48,2.48,0,0,0-1.75-1.5C476.41,69.42,476.14,70,476.45,70.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.69,70.49a2.39,2.39,0,0,0,1.74,1.5c.66.05.94-.54.62-1.32a2.41,2.41,0,0,0-1.74-1.49C472.66,69.13,472.38,69.72,472.69,70.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M468.92,70.21a2.36,2.36,0,0,0,1.73,1.49c.65,0,.94-.54.64-1.32a2.4,2.4,0,0,0-1.73-1.49C468.91,68.84,468.62,69.43,468.92,70.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.14,69.93a2.31,2.31,0,0,0,1.72,1.49c.65,0,.95-.54.65-1.32a2.33,2.33,0,0,0-1.72-1.49C465.14,68.57,464.85,69.16,465.14,69.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M461.35,69.66a2.29,2.29,0,0,0,1.71,1.49c.66,0,1-.55.67-1.32a2.29,2.29,0,0,0-1.7-1.48C461.37,68.3,461.07,68.89,461.35,69.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M457.56,69.41a2.27,2.27,0,0,0,1.69,1.48c.66,0,1-.55.69-1.32a2.23,2.23,0,0,0-1.69-1.48C457.6,68,457.29,68.63,457.56,69.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M453.76,69.16a2.24,2.24,0,0,0,1.68,1.48c.66,0,1-.55.71-1.33a2.2,2.2,0,0,0-1.68-1.47C453.82,67.79,453.5,68.38,453.76,69.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450,68.92a2.16,2.16,0,0,0,1.66,1.47c.67,0,1-.55.73-1.32a2.17,2.17,0,0,0-1.66-1.48C450,67.55,449.7,68.14,450,68.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M446.15,68.69a2.14,2.14,0,0,0,1.65,1.47c.66,0,1-.56.74-1.33a2.14,2.14,0,0,0-1.65-1.47C446.24,67.32,445.9,67.91,446.15,68.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M442.33,68.47A2.07,2.07,0,0,0,444,69.93c.67,0,1-.55.76-1.33a2.1,2.1,0,0,0-1.63-1.46C442.44,67.1,442.1,67.69,442.33,68.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M438.51,68.25a2.07,2.07,0,0,0,1.63,1.47c.66,0,1-.56.77-1.33a2.06,2.06,0,0,0-1.62-1.47C438.63,66.89,438.28,67.48,438.51,68.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.69,68.05a2,2,0,0,0,1.6,1.46c.67,0,1-.56.8-1.33a2,2,0,0,0-1.61-1.46C434.82,66.68,434.47,67.28,434.69,68.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.85,67.86a2,2,0,0,0,1.6,1.45c.67,0,1-.56.81-1.33a2,2,0,0,0-1.59-1.46C431,66.49,430.64,67.09,430.85,67.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427,67.67a2,2,0,0,0,1.58,1.46c.67,0,1-.57.83-1.34a2,2,0,0,0-1.58-1.45C427.19,66.3,426.82,66.9,427,67.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.18,67.5A1.9,1.9,0,0,0,424.74,69c.67,0,1.05-.57.85-1.34A1.92,1.92,0,0,0,424,66.16C423.36,66.13,423,66.73,423.18,67.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419.33,67.33a1.89,1.89,0,0,0,1.55,1.45c.67,0,1.06-.57.87-1.34A1.9,1.9,0,0,0,420.2,66C419.54,66,419.15,66.56,419.33,67.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.48,67.18A1.84,1.84,0,0,0,417,68.62c.68,0,1.07-.58.89-1.35a1.85,1.85,0,0,0-1.53-1.44C415.7,65.81,415.3,66.41,415.48,67.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.63,67a1.79,1.79,0,0,0,1.51,1.44c.68,0,1.08-.58.91-1.35a1.82,1.82,0,0,0-1.52-1.43C411.86,65.66,411.46,66.26,411.63,67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.77,66.89a1.76,1.76,0,0,0,1.5,1.44c.67,0,1.08-.58.92-1.35a1.77,1.77,0,0,0-1.5-1.43C408,65.52,407.61,66.13,407.77,66.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.91,66.77a1.72,1.72,0,0,0,1.48,1.43c.68,0,1.1-.59.94-1.35a1.74,1.74,0,0,0-1.49-1.43C404.18,65.4,403.76,66,403.91,66.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400,66.65a1.71,1.71,0,0,0,1.47,1.43c.68,0,1.1-.59,1-1.36A1.72,1.72,0,0,0,401,65.3C400.33,65.28,399.9,65.88,400,66.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.17,66.54A1.68,1.68,0,0,0,397.63,68a1,1,0,0,0,1-1.36,1.68,1.68,0,0,0-1.46-1.42C396.48,65.17,396,65.77,396.17,66.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.3,66.44a1.65,1.65,0,0,0,1.44,1.43,1.07,1.07,0,0,0,1-1.37,1.65,1.65,0,0,0-1.44-1.41A1.05,1.05,0,0,0,392.3,66.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.42,66.36a1.61,1.61,0,0,0,1.43,1.41,1.07,1.07,0,0,0,1-1.36A1.64,1.64,0,0,0,389.43,65,1.09,1.09,0,0,0,388.42,66.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384.54,66.28A1.59,1.59,0,0,0,386,67.69a1.09,1.09,0,0,0,1-1.36,1.61,1.61,0,0,0-1.41-1.42A1.1,1.1,0,0,0,384.54,66.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.66,66.21a1.56,1.56,0,0,0,1.39,1.41,1.11,1.11,0,0,0,1.05-1.37,1.56,1.56,0,0,0-1.39-1.41A1.12,1.12,0,0,0,380.66,66.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.78,66.15a1.53,1.53,0,0,0,1.37,1.41,1.14,1.14,0,0,0,1.07-1.38,1.54,1.54,0,0,0-1.38-1.4A1.13,1.13,0,0,0,376.78,66.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.89,66.1a1.49,1.49,0,0,0,1.36,1.4,1.14,1.14,0,0,0,1.08-1.37,1.51,1.51,0,0,0-1.36-1.4A1.15,1.15,0,0,0,372.89,66.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M369,66.06a1.48,1.48,0,0,0,1.35,1.4,1.17,1.17,0,0,0,1.1-1.38,1.5,1.5,0,0,0-1.35-1.4A1.18,1.18,0,0,0,369,66.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M365.11,66a1.46,1.46,0,0,0,1.33,1.4,1.2,1.2,0,0,0,1.12-1.38,1.47,1.47,0,0,0-1.33-1.4A1.2,1.2,0,0,0,365.11,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.22,66a1.43,1.43,0,0,0,1.31,1.4A1.22,1.22,0,0,0,363.67,66a1.42,1.42,0,0,0-1.31-1.39A1.22,1.22,0,0,0,361.22,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.33,66a1.38,1.38,0,0,0,1.29,1.39A1.23,1.23,0,0,0,359.77,66a1.4,1.4,0,0,0-1.29-1.38A1.22,1.22,0,0,0,357.33,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M353.44,66a1.36,1.36,0,0,0,1.27,1.39A1.25,1.25,0,0,0,355.88,66a1.37,1.37,0,0,0-1.27-1.39A1.25,1.25,0,0,0,353.44,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.54,66a1.35,1.35,0,0,0,1.26,1.39A1.29,1.29,0,0,0,352,66a1.36,1.36,0,0,0-1.26-1.38A1.28,1.28,0,0,0,349.54,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.64,66a1.32,1.32,0,0,0,1.25,1.38,1.29,1.29,0,0,0,1.2-1.39,1.32,1.32,0,0,0-1.24-1.38A1.31,1.31,0,0,0,345.64,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.75,66.06A1.3,1.3,0,0,0,343,67.44a1.32,1.32,0,0,0,1.22-1.4A1.3,1.3,0,0,0,343,64.66,1.33,1.33,0,0,0,341.75,66.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.85,66.1a1.28,1.28,0,0,0,1.21,1.37,1.34,1.34,0,0,0,1.24-1.4,1.28,1.28,0,0,0-1.21-1.37A1.35,1.35,0,0,0,337.85,66.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M334,66.15a1.25,1.25,0,0,0,1.19,1.37,1.38,1.38,0,0,0,1.26-1.41,1.27,1.27,0,0,0-1.19-1.37A1.39,1.39,0,0,0,334,66.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M330.05,66.2a1.24,1.24,0,0,0,1.18,1.38,1.39,1.39,0,0,0,1.27-1.41,1.23,1.23,0,0,0-1.17-1.37A1.41,1.41,0,0,0,330.05,66.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M326.16,66.27a1.21,1.21,0,0,0,1.15,1.37,1.41,1.41,0,0,0,1.29-1.41,1.21,1.21,0,0,0-1.15-1.37A1.44,1.44,0,0,0,326.16,66.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M322.26,66.35a1.2,1.2,0,0,0,1.14,1.37,1.46,1.46,0,0,0,1.31-1.42,1.2,1.2,0,0,0-1.14-1.36A1.45,1.45,0,0,0,322.26,66.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M221.7,71.79c-.29.78,0,1.36.68,1.3a2.3,2.3,0,0,0,1.71-1.51c.28-.77,0-1.36-.68-1.3A2.33,2.33,0,0,0,221.7,71.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M217.88,72.12c-.29.78,0,1.37.67,1.31a2.37,2.37,0,0,0,1.73-1.52c.28-.77,0-1.36-.67-1.3A2.41,2.41,0,0,0,217.88,72.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M214.08,72.47c-.31.78,0,1.36.65,1.3a2.42,2.42,0,0,0,1.74-1.52c.29-.78,0-1.36-.66-1.3A2.43,2.43,0,0,0,214.08,72.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210.28,72.82c-.31.78,0,1.36.63,1.3a2.42,2.42,0,0,0,1.75-1.52c.31-.78,0-1.36-.63-1.3A2.45,2.45,0,0,0,210.28,72.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.48,73.18c-.32.78,0,1.36.62,1.3A2.49,2.49,0,0,0,208.86,73c.32-.78,0-1.36-.62-1.3A2.52,2.52,0,0,0,206.48,73.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M202.7,73.56c-.33.77-.06,1.36.6,1.29a2.51,2.51,0,0,0,1.77-1.53c.32-.78.05-1.36-.6-1.29A2.51,2.51,0,0,0,202.7,73.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M198.91,73.94c-.33.78-.07,1.35.59,1.29a2.58,2.58,0,0,0,1.79-1.53c.33-.78.06-1.36-.59-1.3A2.64,2.64,0,0,0,198.91,73.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M195.14,74.32c-.34.78-.08,1.36.57,1.3a2.63,2.63,0,0,0,1.8-1.54c.34-.78.08-1.36-.57-1.29A2.64,2.64,0,0,0,195.14,74.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.38,74.72c-.35.78-.11,1.36.55,1.29a2.65,2.65,0,0,0,1.81-1.54c.34-.78.09-1.36-.56-1.29A2.65,2.65,0,0,0,191.38,74.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187.62,75.13c-.36.78-.12,1.36.54,1.28A2.7,2.7,0,0,0,190,74.87c.35-.78.11-1.36-.54-1.29A2.69,2.69,0,0,0,187.62,75.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M180.12,76c-.37.79-.14,1.36.51,1.29a2.79,2.79,0,0,0,1.84-1.55c.37-.79.14-1.36-.5-1.29A2.83,2.83,0,0,0,180.12,76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176.39,76.39c-.38.79-.16,1.36.49,1.29a2.85,2.85,0,0,0,1.85-1.56c.38-.78.16-1.35-.49-1.28A2.85,2.85,0,0,0,176.39,76.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.66,76.83c-.38.79-.17,1.36.48,1.28A2.83,2.83,0,0,0,175,76.55c.38-.78.17-1.35-.47-1.28A2.92,2.92,0,0,0,172.66,76.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.94,77.28c-.39.78-.18,1.36.46,1.28A2.94,2.94,0,0,0,171.28,77c.39-.79.18-1.36-.46-1.28A3,3,0,0,0,168.94,77.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M165.23,77.73c-.4.79-.2,1.36.45,1.28a3,3,0,0,0,1.88-1.56c.4-.79.2-1.36-.44-1.28A3,3,0,0,0,165.23,77.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.53,78.2c-.4.78-.21,1.35.43,1.27a3,3,0,0,0,1.9-1.57c.4-.78.21-1.35-.43-1.27A3,3,0,0,0,161.53,78.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M157.84,78.67c-.41.78-.23,1.36.42,1.27a3,3,0,0,0,1.9-1.57c.41-.79.22-1.36-.41-1.27A3.07,3.07,0,0,0,157.84,78.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M154.16,79.15c-.42.78-.24,1.35.4,1.27a3.06,3.06,0,0,0,1.91-1.58c.42-.78.24-1.35-.4-1.27A3.11,3.11,0,0,0,154.16,79.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M150.49,79.63c-.43.79-.26,1.36.38,1.28a3.17,3.17,0,0,0,1.92-1.58c.43-.79.26-1.36-.38-1.28A3.19,3.19,0,0,0,150.49,79.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M146.83,80.13c-.44.78-.27,1.35.36,1.27a3.22,3.22,0,0,0,1.94-1.59c.42-.78.26-1.35-.37-1.27A3.2,3.2,0,0,0,146.83,80.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M143.17,80.63c-.44.79-.28,1.36.36,1.27a3.27,3.27,0,0,0,1.94-1.59c.43-.79.27-1.35-.36-1.27A3.27,3.27,0,0,0,143.17,80.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M139.53,81.14c-.44.79-.29,1.35.34,1.27a3.32,3.32,0,0,0,1.95-1.59c.44-.79.29-1.36-.34-1.27A3.31,3.31,0,0,0,139.53,81.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M135.9,81.65c-.45.79-.31,1.36.33,1.27a3.31,3.31,0,0,0,2-1.59c.45-.79.3-1.36-.32-1.27A3.33,3.33,0,0,0,135.9,81.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M132.28,82.18c-.46.79-.32,1.35.31,1.26a3.33,3.33,0,0,0,2-1.59c.46-.79.32-1.36-.31-1.27A3.41,3.41,0,0,0,132.28,82.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M128.67,82.71c-.46.79-.33,1.35.3,1.26a3.43,3.43,0,0,0,2-1.6c.46-.79.32-1.35-.3-1.26A3.43,3.43,0,0,0,128.67,82.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M125.07,83.24c-.47.8-.34,1.36.28,1.27a3.48,3.48,0,0,0,2-1.6c.47-.79.34-1.36-.28-1.27A3.48,3.48,0,0,0,125.07,83.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M121.48,83.79c-.47.79-.35,1.36.27,1.26a3.44,3.44,0,0,0,2-1.6c.48-.8.36-1.36-.26-1.26A3.52,3.52,0,0,0,121.48,83.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M117.91,84.34c-.48.79-.37,1.36.25,1.26a3.5,3.5,0,0,0,2-1.61c.48-.79.36-1.35-.25-1.26A3.58,3.58,0,0,0,117.91,84.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M114.34,84.9c-.48.79-.38,1.36.24,1.26a3.6,3.6,0,0,0,2-1.61c.48-.79.37-1.36-.24-1.26A3.63,3.63,0,0,0,114.34,84.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M110.79,85.46c-.49.8-.39,1.36.23,1.26a3.63,3.63,0,0,0,2-1.61c.49-.79.38-1.36-.23-1.26A3.67,3.67,0,0,0,110.79,85.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M107.25,86c-.5.79-.4,1.35.22,1.25a3.67,3.67,0,0,0,2-1.61c.49-.8.4-1.36-.21-1.26A3.66,3.66,0,0,0,107.25,86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M103.72,86.61c-.5.8-.41,1.36.2,1.26a3.73,3.73,0,0,0,2-1.62c.49-.79.4-1.36-.21-1.26A3.74,3.74,0,0,0,103.72,86.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M100.21,87.2c-.51.79-.42,1.35.19,1.25a3.74,3.74,0,0,0,2-1.62c.5-.79.42-1.35-.19-1.25A3.7,3.7,0,0,0,100.21,87.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M96.7,87.79c-.51.79-.43,1.35.18,1.25a3.76,3.76,0,0,0,2-1.62c.51-.8.43-1.36-.18-1.26A3.86,3.86,0,0,0,96.7,87.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M93.21,88.38c-.51.8-.44,1.36.17,1.26a3.87,3.87,0,0,0,2-1.63c.52-.8.44-1.36-.16-1.25A3.85,3.85,0,0,0,93.21,88.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M89.74,89c-.52.79-.45,1.35.15,1.25a3.92,3.92,0,0,0,2-1.63c.52-.8.45-1.36-.15-1.25A3.83,3.83,0,0,0,89.74,89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M86.28,89.6c-.53.79-.46,1.35.14,1.25a3.94,3.94,0,0,0,2-1.64c.52-.79.45-1.35-.14-1.25A3.94,3.94,0,0,0,86.28,89.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M82.83,90.21c-.53.8-.47,1.35.13,1.25a3.94,3.94,0,0,0,2-1.64c.53-.79.47-1.35-.13-1.24A3.86,3.86,0,0,0,82.83,90.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M79.39,90.83c-.53.8-.48,1.35.12,1.25a4,4,0,0,0,2-1.64c.53-.8.47-1.35-.12-1.25A4,4,0,0,0,79.39,90.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M76,91.46c-.53.79-.49,1.35.11,1.24a4,4,0,0,0,2-1.64c.53-.79.48-1.35-.11-1.24A4,4,0,0,0,76,91.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M72.56,92.09c-.53.79-.49,1.35.1,1.24a4,4,0,0,0,2-1.64c.54-.8.49-1.35-.1-1.24A4.07,4.07,0,0,0,72.56,92.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M69.17,92.72c-.54.8-.5,1.35.09,1.24a4.07,4.07,0,0,0,2-1.64c.54-.79.5-1.35-.09-1.24A4.17,4.17,0,0,0,69.17,92.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M65.8,93.36c-.55.8-.52,1.36.07,1.24a4.07,4.07,0,0,0,2-1.64c.55-.8.51-1.35-.07-1.24A4.13,4.13,0,0,0,65.8,93.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M62.43,94c-.54.8-.52,1.35.07,1.24a4.14,4.14,0,0,0,2-1.65c.55-.79.52-1.35-.06-1.24A4.21,4.21,0,0,0,62.43,94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M59.09,94.66c-.55.8-.53,1.35,0,1.24a4.2,4.2,0,0,0,2-1.65c.54-.8.52-1.35-.06-1.24A4.24,4.24,0,0,0,59.09,94.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M55.75,95.32c-.55.79-.53,1.35,0,1.23a4.24,4.24,0,0,0,2.05-1.65c.55-.79.52-1.35-.05-1.23A4.24,4.24,0,0,0,55.75,95.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M52.44,96c-.56.79-.54,1.35,0,1.23a4.23,4.23,0,0,0,2-1.65c.55-.8.53-1.35,0-1.23A4.23,4.23,0,0,0,52.44,96Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M49.14,96.64c-.56.8-.55,1.35,0,1.23a4.27,4.27,0,0,0,2-1.65c.55-.79.54-1.35,0-1.23A4.39,4.39,0,0,0,49.14,96.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M45.86,97.31c-.56.8-.56,1.35,0,1.23a4.29,4.29,0,0,0,2.05-1.65c.55-.8.54-1.35,0-1.23A4.23,4.23,0,0,0,45.86,97.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616,84.42a4.21,4.21,0,0,0,2,1.63c.58.1.59-.46,0-1.26a4.17,4.17,0,0,0-2-1.63C615.5,83.06,615.48,83.63,616,84.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M602.69,82.13a4,4,0,0,0,2,1.62c.59.09.62-.47.08-1.27a4,4,0,0,0-2-1.61C602.19,80.77,602.16,81.34,602.69,82.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M599.33,81.57a3.93,3.93,0,0,0,2,1.62c.59.09.63-.47.09-1.27a4,4,0,0,0-2-1.61C598.83,80.21,598.79,80.78,599.33,81.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M595.94,81a3.94,3.94,0,0,0,2,1.61c.59.1.63-.47.1-1.26a3.83,3.83,0,0,0-2-1.61C595.46,79.66,595.41,80.23,595.94,81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M561.48,75.84a3.45,3.45,0,0,0,2,1.58c.61.09.71-.48.22-1.27a3.52,3.52,0,0,0-2-1.58C561.1,74.49,561,75.06,561.48,75.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M558,75.36a3.43,3.43,0,0,0,2,1.58c.61.08.72-.49.24-1.28a3.45,3.45,0,0,0-2-1.57C557.6,74,557.5,74.58,558,75.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M554.45,74.89a3.39,3.39,0,0,0,2,1.57c.61.08.72-.49.25-1.27a3.41,3.41,0,0,0-2-1.57C554.09,73.53,554,74.1,554.45,74.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M550.92,74.42a3.37,3.37,0,0,0,2,1.57c.61.08.73-.49.26-1.27a3.35,3.35,0,0,0-1.95-1.57C550.58,73.07,550.46,73.64,550.92,74.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.38,74a3.32,3.32,0,0,0,2,1.57c.62.08.74-.49.28-1.28a3.37,3.37,0,0,0-2-1.56C547.05,72.61,546.92,73.18,547.38,74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.83,73.51a3.28,3.28,0,0,0,1.94,1.56c.62.08.75-.49.29-1.28a3.27,3.27,0,0,0-1.94-1.56C543.51,72.16,543.38,72.73,543.83,73.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540.27,73.07a3.18,3.18,0,0,0,1.94,1.55c.61.08.75-.49.3-1.27a3.24,3.24,0,0,0-1.93-1.56C540,71.71,539.83,72.28,540.27,73.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.7,72.63a3.17,3.17,0,0,0,1.93,1.55c.62.08.76-.49.32-1.28A3.23,3.23,0,0,0,537,71.35C536.41,71.28,536.26,71.85,536.7,72.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.12,72.2A3.12,3.12,0,0,0,535,73.75c.62.08.77-.5.33-1.28a3.13,3.13,0,0,0-1.91-1.55C532.84,70.85,532.69,71.42,533.12,72.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529.53,71.78a3.06,3.06,0,0,0,1.91,1.54c.63.08.78-.5.35-1.28a3.09,3.09,0,0,0-1.91-1.54C529.26,70.42,529.11,71,529.53,71.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525.94,71.36a3.08,3.08,0,0,0,1.89,1.55c.63.07.79-.51.37-1.29a3.07,3.07,0,0,0-1.9-1.54C525.68,70,525.52,70.58,525.94,71.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M522.33,71a3,3,0,0,0,1.89,1.54c.62.07.79-.51.37-1.29a3,3,0,0,0-1.88-1.54C522.08,69.6,521.91,70.18,522.33,71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M518.71,70.56a3,3,0,0,0,1.88,1.53c.63.07.8-.5.39-1.28a3,3,0,0,0-1.87-1.54C518.48,69.2,518.3,69.78,518.71,70.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M515.09,70.17A2.9,2.9,0,0,0,517,71.7c.63.07.82-.51.41-1.29a2.92,2.92,0,0,0-1.86-1.53C514.87,68.81,514.69,69.39,515.09,70.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M511.45,69.78a2.84,2.84,0,0,0,1.86,1.53c.63.07.82-.51.42-1.29a2.86,2.86,0,0,0-1.85-1.52C511.25,68.43,511.06,69,511.45,69.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M507.81,69.41a2.77,2.77,0,0,0,1.85,1.52c.63.07.83-.51.44-1.29a2.87,2.87,0,0,0-1.85-1.52C507.62,68.06,507.42,68.63,507.81,69.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M504.16,69A2.74,2.74,0,0,0,506,70.56c.64.07.84-.51.46-1.29a2.82,2.82,0,0,0-1.83-1.52C504,67.69,503.78,68.27,504.16,69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M500.5,68.68a2.7,2.7,0,0,0,1.82,1.52c.64.06.85-.52.48-1.29A2.79,2.79,0,0,0,501,67.39C500.34,67.33,500.13,67.91,500.5,68.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M496.83,68.33a2.69,2.69,0,0,0,1.81,1.52c.64.06.86-.52.49-1.3A2.7,2.7,0,0,0,497.32,67C496.69,67,496.47,67.56,496.83,68.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M493.16,68A2.62,2.62,0,0,0,495,69.5c.64.06.87-.52.5-1.29a2.64,2.64,0,0,0-1.8-1.51C493,66.64,492.8,67.22,493.16,68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.47,67.66a2.63,2.63,0,0,0,1.79,1.51c.65,0,.88-.53.53-1.3A2.64,2.64,0,0,0,490,66.36C489.36,66.31,489.13,66.89,489.47,67.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.78,67.33a2.57,2.57,0,0,0,1.78,1.51c.65,0,.89-.53.54-1.3a2.56,2.56,0,0,0-1.78-1.5C485.69,66,485.44,66.56,485.78,67.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M482.09,67a2.53,2.53,0,0,0,1.76,1.5c.65,0,.9-.53.56-1.3a2.52,2.52,0,0,0-1.77-1.5C482,65.67,481.75,66.25,482.09,67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.38,66.71a2.49,2.49,0,0,0,1.76,1.5c.64.05.9-.54.57-1.31A2.49,2.49,0,0,0,479,65.41C478.31,65.36,478.06,65.94,478.38,66.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.67,66.41a2.41,2.41,0,0,0,1.74,1.49c.65,0,.91-.53.59-1.3a2.45,2.45,0,0,0-1.74-1.49C474.62,65.06,474.35,65.64,474.67,66.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M471,66.12a2.4,2.4,0,0,0,1.73,1.49c.65,0,.92-.54.61-1.31a2.42,2.42,0,0,0-1.73-1.48C470.92,64.77,470.64,65.35,471,66.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M467.23,65.84a2.34,2.34,0,0,0,1.71,1.48c.65.05.93-.53.63-1.3a2.37,2.37,0,0,0-1.72-1.48C467.21,64.49,466.93,65.07,467.23,65.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M463.49,65.57a2.34,2.34,0,0,0,1.71,1.48c.65,0,.94-.54.64-1.31a2.34,2.34,0,0,0-1.7-1.48C463.49,64.21,463.2,64.8,463.49,65.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M459.76,65.3a2.28,2.28,0,0,0,1.69,1.48c.65,0,.94-.54.66-1.31A2.28,2.28,0,0,0,460.42,64C459.77,64,459.48,64.54,459.76,65.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M456,65.05a2.23,2.23,0,0,0,1.68,1.47c.65,0,1-.54.68-1.31a2.23,2.23,0,0,0-1.68-1.47C456,63.7,455.74,64.28,456,65.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M452.26,64.8a2.19,2.19,0,0,0,1.67,1.47c.65,0,1-.55.69-1.31A2.21,2.21,0,0,0,453,63.49C452.31,63.45,452,64,452.26,64.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M448.51,64.57A2.16,2.16,0,0,0,450.16,66c.65,0,1-.55.71-1.32a2.17,2.17,0,0,0-1.65-1.46C448.57,63.21,448.25,63.8,448.51,64.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M444.75,64.34a2.13,2.13,0,0,0,1.63,1.46c.66,0,1-.55.73-1.32A2.11,2.11,0,0,0,445.48,63C444.83,63,444.5,63.57,444.75,64.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M441,64.12a2.1,2.1,0,0,0,1.62,1.46c.66,0,1-.56.75-1.32a2.1,2.1,0,0,0-1.62-1.46C441.08,62.77,440.74,63.35,441,64.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M437.21,63.91a2,2,0,0,0,1.61,1.45c.66,0,1-.55.76-1.32A2.06,2.06,0,0,0,438,62.59C437.32,62.56,437,63.14,437.21,63.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.43,63.71a2,2,0,0,0,1.6,1.45c.66,0,1-.56.78-1.33a2,2,0,0,0-1.6-1.44C433.56,62.35,433.21,62.94,433.43,63.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.65,63.52A2,2,0,0,0,431.23,65c.66,0,1-.56.8-1.32a2,2,0,0,0-1.58-1.44C429.8,62.16,429.44,62.75,429.65,63.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M425.87,63.34a1.93,1.93,0,0,0,1.56,1.44c.66,0,1-.57.82-1.33A2,2,0,0,0,426.68,62C426,62,425.66,62.57,425.87,63.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422.08,63.16a1.89,1.89,0,0,0,1.55,1.44c.66,0,1-.57.83-1.33a1.91,1.91,0,0,0-1.55-1.43C422.26,61.81,421.88,62.4,422.08,63.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.28,63a1.85,1.85,0,0,0,1.54,1.43c.66,0,1-.56.85-1.33a1.89,1.89,0,0,0-1.54-1.43C418.48,61.64,418.1,62.24,418.28,63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.48,62.85A1.84,1.84,0,0,0,416,64.28c.66,0,1.05-.57.87-1.34a1.82,1.82,0,0,0-1.52-1.42C414.7,61.49,414.31,62.08,414.48,62.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M410.68,62.7a1.81,1.81,0,0,0,1.5,1.43c.67,0,1.06-.58.89-1.34a1.8,1.8,0,0,0-1.5-1.42C410.91,61.34,410.51,61.94,410.68,62.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.87,62.57A1.76,1.76,0,0,0,408.36,64c.67,0,1.07-.58.91-1.34a1.78,1.78,0,0,0-1.49-1.42C407.12,61.21,406.72,61.81,406.87,62.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.06,62.44a1.73,1.73,0,0,0,1.48,1.42c.66,0,1.07-.58.92-1.34A1.75,1.75,0,0,0,404,61.1C403.33,61.08,402.92,61.68,403.06,62.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.25,62.33a1.69,1.69,0,0,0,1.46,1.41c.66,0,1.08-.58.94-1.34A1.71,1.71,0,0,0,400.19,61C399.53,61,399.11,61.57,399.25,62.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395.43,62.22a1.67,1.67,0,0,0,1.44,1.41c.67,0,1.1-.58,1-1.34a1.65,1.65,0,0,0-1.44-1.41C395.73,60.86,395.3,61.46,395.43,62.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.61,62.12A1.63,1.63,0,0,0,393,63.53a1,1,0,0,0,1-1.35,1.62,1.62,0,0,0-1.42-1.4A1,1,0,0,0,391.61,62.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.79,62a1.59,1.59,0,0,0,1.41,1.4,1.05,1.05,0,0,0,1-1.35,1.6,1.6,0,0,0-1.4-1.4A1.07,1.07,0,0,0,387.79,62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384,62a1.56,1.56,0,0,0,1.39,1.4,1.08,1.08,0,0,0,1-1.35,1.58,1.58,0,0,0-1.39-1.4A1.07,1.07,0,0,0,384,62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.14,61.89a1.54,1.54,0,0,0,1.37,1.4,1.1,1.1,0,0,0,1-1.36,1.54,1.54,0,0,0-1.37-1.39A1.1,1.1,0,0,0,380.14,61.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.31,61.83a1.52,1.52,0,0,0,1.36,1.4,1.11,1.11,0,0,0,1-1.36,1.52,1.52,0,0,0-1.35-1.39A1.11,1.11,0,0,0,376.31,61.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.48,61.78a1.49,1.49,0,0,0,1.34,1.4,1.14,1.14,0,0,0,1.06-1.37,1.5,1.5,0,0,0-1.34-1.39A1.14,1.14,0,0,0,372.48,61.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368.64,61.74A1.46,1.46,0,0,0,370,63.13a1.15,1.15,0,0,0,1.08-1.36,1.47,1.47,0,0,0-1.33-1.39A1.17,1.17,0,0,0,368.64,61.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364.81,61.72a1.42,1.42,0,0,0,1.3,1.38,1.17,1.17,0,0,0,1.1-1.37,1.44,1.44,0,0,0-1.3-1.38A1.18,1.18,0,0,0,364.81,61.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361,61.7a1.4,1.4,0,0,0,1.29,1.38,1.2,1.2,0,0,0,1.12-1.37,1.41,1.41,0,0,0-1.29-1.38A1.2,1.2,0,0,0,361,61.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M357.13,61.69a1.38,1.38,0,0,0,1.27,1.38,1.23,1.23,0,0,0,1.14-1.38,1.38,1.38,0,0,0-1.28-1.37A1.21,1.21,0,0,0,357.13,61.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M353.29,61.69a1.35,1.35,0,0,0,1.26,1.37,1.24,1.24,0,0,0,1.15-1.38,1.37,1.37,0,0,0-1.26-1.37A1.25,1.25,0,0,0,353.29,61.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.45,61.7a1.33,1.33,0,0,0,1.24,1.37,1.26,1.26,0,0,0,1.17-1.38,1.34,1.34,0,0,0-1.24-1.37A1.26,1.26,0,0,0,349.45,61.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.6,61.71a1.31,1.31,0,0,0,1.23,1.37A1.28,1.28,0,0,0,348,61.7a1.33,1.33,0,0,0-1.23-1.37A1.31,1.31,0,0,0,345.6,61.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.76,61.74A1.21,1.21,0,1,0,343,60.36,1.31,1.31,0,0,0,341.76,61.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M337.92,61.78a1.27,1.27,0,0,0,1.19,1.37,1.33,1.33,0,0,0,1.22-1.39,1.26,1.26,0,0,0-1.19-1.36A1.33,1.33,0,0,0,337.92,61.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M334.07,61.83a1.24,1.24,0,0,0,1.18,1.36,1.36,1.36,0,0,0,1.24-1.39,1.25,1.25,0,0,0-1.18-1.36A1.37,1.37,0,0,0,334.07,61.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M322.54,62a1.17,1.17,0,0,0,1.12,1.35A1.46,1.46,0,0,0,325,62a1.18,1.18,0,0,0-1.13-1.35A1.44,1.44,0,0,0,322.54,62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M223.31,67.41c-.29.78,0,1.36.67,1.3a2.33,2.33,0,0,0,1.7-1.5c.28-.77,0-1.35-.67-1.29A2.31,2.31,0,0,0,223.31,67.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.54,67.75c-.29.77,0,1.35.66,1.29a2.37,2.37,0,0,0,1.71-1.5c.29-.77,0-1.35-.66-1.29A2.37,2.37,0,0,0,219.54,67.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M215.78,68.09c-.3.77,0,1.35.64,1.29a2.38,2.38,0,0,0,1.72-1.51c.3-.77,0-1.34-.63-1.29A2.45,2.45,0,0,0,215.78,68.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212,68.44c-.31.77,0,1.35.62,1.29a2.46,2.46,0,0,0,1.74-1.51c.3-.77,0-1.35-.63-1.29A2.47,2.47,0,0,0,212,68.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M208.28,68.8c-.32.77-.05,1.35.6,1.28a2.46,2.46,0,0,0,1.75-1.51c.32-.77.05-1.35-.6-1.28A2.47,2.47,0,0,0,208.28,68.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.54,69.16c-.33.78-.07,1.36.59,1.29a2.5,2.5,0,0,0,1.76-1.52c.32-.77.06-1.34-.59-1.28A2.53,2.53,0,0,0,204.54,69.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M200.8,69.54c-.33.78-.08,1.35.57,1.29a2.59,2.59,0,0,0,1.78-1.53c.33-.77.07-1.34-.57-1.28A2.62,2.62,0,0,0,200.8,69.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue pulse" d="M197.07,69.93c-.34.77-.09,1.35.56,1.28a2.59,2.59,0,0,0,1.78-1.53c.34-.77.09-1.34-.55-1.28A2.66,2.66,0,0,0,197.07,69.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M193.35,70.32c-.35.78-.11,1.35.54,1.28a2.66,2.66,0,0,0,1.8-1.53c.34-.77.1-1.35-.54-1.28A2.68,2.68,0,0,0,193.35,70.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M189.64,70.72c-.36.78-.13,1.35.52,1.28A2.7,2.7,0,0,0,192,70.47c.35-.78.12-1.35-.53-1.28A2.68,2.68,0,0,0,189.64,70.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M160.19,74.23c-.42.79-.24,1.35.39,1.27a3,3,0,0,0,1.9-1.56c.41-.78.23-1.35-.4-1.27A3.05,3.05,0,0,0,160.19,74.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M156.54,74.71c-.42.78-.25,1.35.39,1.27a3.15,3.15,0,0,0,1.9-1.57c.42-.78.24-1.35-.39-1.27A3.17,3.17,0,0,0,156.54,74.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M152.91,75.19c-.43.78-.27,1.35.37,1.27a3.19,3.19,0,0,0,1.91-1.57c.42-.79.26-1.35-.37-1.27A3.16,3.16,0,0,0,152.91,75.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M149.28,75.68c-.43.79-.28,1.35.35,1.27a3.18,3.18,0,0,0,1.93-1.58c.43-.78.27-1.35-.36-1.26A3.23,3.23,0,0,0,149.28,75.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M145.66,76.18c-.44.78-.29,1.35.34,1.26a3.19,3.19,0,0,0,1.93-1.57c.44-.79.29-1.35-.34-1.27A3.3,3.3,0,0,0,145.66,76.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M142.06,76.69c-.45.78-.31,1.35.32,1.26a3.29,3.29,0,0,0,1.94-1.58c.44-.79.3-1.35-.33-1.26A3.23,3.23,0,0,0,142.06,76.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M138.46,77.2c-.45.78-.31,1.35.31,1.26a3.33,3.33,0,0,0,1.95-1.58c.45-.79.31-1.35-.31-1.27A3.31,3.31,0,0,0,138.46,77.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M134.87,77.72c-.46.79-.32,1.35.3,1.26a3.34,3.34,0,0,0,2-1.59c.46-.79.32-1.35-.29-1.26A3.39,3.39,0,0,0,134.87,77.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M131.29,78.25c-.46.78-.33,1.35.29,1.26a3.45,3.45,0,0,0,2-1.6c.46-.78.33-1.35-.28-1.25A3.43,3.43,0,0,0,131.29,78.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M127.73,78.78c-.47.79-.35,1.35.27,1.26a3.46,3.46,0,0,0,2-1.6c.46-.78.34-1.35-.27-1.25A3.43,3.43,0,0,0,127.73,78.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M124.17,79.32c-.48.79-.36,1.35.26,1.26a3.46,3.46,0,0,0,2-1.6c.48-.79.36-1.35-.25-1.25A3.48,3.48,0,0,0,124.17,79.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M120.63,79.87c-.49.79-.38,1.35.24,1.26a3.59,3.59,0,0,0,2-1.6c.48-.79.37-1.35-.24-1.26A3.55,3.55,0,0,0,120.63,79.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M117.09,80.43c-.49.79-.38,1.35.23,1.25a3.56,3.56,0,0,0,2-1.6c.48-.79.38-1.35-.23-1.26A3.65,3.65,0,0,0,117.09,80.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M113.57,81c-.49.79-.4,1.35.22,1.25a3.65,3.65,0,0,0,2-1.61c.49-.78.39-1.35-.22-1.25A3.65,3.65,0,0,0,113.57,81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M110.06,81.56c-.5.79-.41,1.35.2,1.25a3.67,3.67,0,0,0,2-1.61c.49-.79.4-1.35-.21-1.25A3.68,3.68,0,0,0,110.06,81.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M106.56,82.13c-.51.79-.42,1.35.19,1.25a3.7,3.7,0,0,0,2-1.61c.5-.79.41-1.35-.19-1.25A3.7,3.7,0,0,0,106.56,82.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M103.07,82.71c-.51.8-.43,1.36.18,1.26a3.86,3.86,0,0,0,2-1.62c.5-.79.42-1.35-.19-1.25A3.74,3.74,0,0,0,103.07,82.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M99.59,83.3c-.51.79-.44,1.35.17,1.25a3.81,3.81,0,0,0,2-1.62c.51-.79.43-1.35-.17-1.25A3.86,3.86,0,0,0,99.59,83.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M96.12,83.9c-.51.79-.44,1.35.16,1.25a3.86,3.86,0,0,0,2-1.63c.51-.79.44-1.35-.16-1.24A3.82,3.82,0,0,0,96.12,83.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M92.67,84.5c-.52.79-.45,1.35.15,1.24a3.87,3.87,0,0,0,2-1.62c.52-.79.45-1.35-.15-1.25A3.93,3.93,0,0,0,92.67,84.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M89.23,85.1c-.52.8-.46,1.36.14,1.25a3.93,3.93,0,0,0,2-1.63c.52-.79.46-1.35-.13-1.24A3.92,3.92,0,0,0,89.23,85.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M85.81,85.72c-.53.79-.48,1.35.12,1.24a4,4,0,0,0,2-1.63c.52-.79.47-1.35-.13-1.24A3.88,3.88,0,0,0,85.81,85.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M82.39,86.33c-.53.8-.48,1.36.11,1.25a4,4,0,0,0,2-1.63c.53-.8.48-1.35-.11-1.25A4,4,0,0,0,82.39,86.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M79,87c-.53.79-.49,1.35.1,1.24a4.1,4.1,0,0,0,2-1.63c.53-.8.48-1.35-.11-1.25A4.09,4.09,0,0,0,79,87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M75.6,87.59c-.53.79-.49,1.35.09,1.24a4.07,4.07,0,0,0,2-1.64c.54-.79.5-1.35-.09-1.24A4.07,4.07,0,0,0,75.6,87.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M72.23,88.22c-.54.8-.51,1.35.08,1.24a4.07,4.07,0,0,0,2-1.64c.54-.79.5-1.35-.08-1.24A4.13,4.13,0,0,0,72.23,88.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M68.87,88.86c-.54.8-.51,1.35.07,1.24a4.11,4.11,0,0,0,2-1.64c.54-.79.51-1.35-.07-1.24A4.21,4.21,0,0,0,68.87,88.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M65.53,89.51c-.55.79-.53,1.35.06,1.24a4.24,4.24,0,0,0,2-1.65c.54-.79.51-1.35-.07-1.23A4.14,4.14,0,0,0,65.53,89.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M62.19,90.16c-.55.8-.52,1.35.05,1.24a4.2,4.2,0,0,0,2-1.65c.55-.8.53-1.35,0-1.24A4.28,4.28,0,0,0,62.19,90.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M58.88,90.81c-.56.8-.54,1.35,0,1.24a4.23,4.23,0,0,0,2-1.65c.55-.79.53-1.35,0-1.23A4.21,4.21,0,0,0,58.88,90.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M638.84,84.74a4.3,4.3,0,0,0,2,1.64c.55.11.54-.45,0-1.24a4.36,4.36,0,0,0-2-1.64C638.25,83.39,638.27,84,638.84,84.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M635.64,84.12a4.33,4.33,0,0,0,2,1.64c.55.11.54-.45,0-1.25a4.43,4.43,0,0,0-2-1.64C635.06,82.77,635.07,83.33,635.64,84.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M629.21,82.9a4.24,4.24,0,0,0,2,1.63c.56.11.55-.45,0-1.25a4.3,4.3,0,0,0-2-1.63C628.64,81.54,628.65,82.1,629.21,82.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M622.73,81.69a4.15,4.15,0,0,0,2,1.63c.56.11.57-.45,0-1.25a4.24,4.24,0,0,0-2-1.63C622.17,80.34,622.17,80.9,622.73,81.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.2,80.51a4.11,4.11,0,0,0,2,1.62c.57.11.58-.45,0-1.25a4.13,4.13,0,0,0-2-1.62C615.65,79.15,615.64,79.72,616.2,80.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M612.91,79.93a4.14,4.14,0,0,0,2,1.62c.57.1.58-.46,0-1.26a4.18,4.18,0,0,0-2-1.62C612.38,78.57,612.36,79.13,612.91,79.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M609.61,79.35a4.1,4.1,0,0,0,2,1.62c.57.1.59-.46,0-1.26a4.12,4.12,0,0,0-2-1.62C609.08,78,609.07,78.55,609.61,79.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M606.3,78.78a4.07,4.07,0,0,0,2,1.61c.57.1.59-.46,0-1.26a4.09,4.09,0,0,0-2-1.61C605.78,77.42,605.76,78,606.3,78.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603,78.21a4,4,0,0,0,2,1.61c.58.1.61-.46.06-1.26A4,4,0,0,0,603,77C602.47,76.85,602.44,77.42,603,78.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M599.65,77.65a4,4,0,0,0,2,1.61c.58.1.61-.47.07-1.26a4,4,0,0,0-2-1.61C599.14,76.29,599.11,76.86,599.65,77.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M589.57,76a3.84,3.84,0,0,0,2,1.6c.59.1.63-.47.1-1.26a3.86,3.86,0,0,0-2-1.6C589.09,74.65,589.05,75.21,589.57,76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M582.8,74.93a3.79,3.79,0,0,0,2,1.6c.59.09.64-.47.12-1.26a3.81,3.81,0,0,0-2-1.6C582.34,73.58,582.28,74.15,582.8,74.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M562.22,71.89a3.51,3.51,0,0,0,2,1.57c.61.09.69-.48.2-1.26a3.57,3.57,0,0,0-2-1.58C561.82,70.54,561.73,71.11,562.22,71.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M558.75,71.41a3.53,3.53,0,0,0,2,1.57c.6.08.7-.49.21-1.27a3.47,3.47,0,0,0-2-1.57C558.36,70.06,558.27,70.62,558.75,71.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555.27,70.93a3.49,3.49,0,0,0,2,1.57c.6.08.7-.49.22-1.27a3.43,3.43,0,0,0-2-1.57C554.9,69.58,554.8,70.15,555.27,70.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551.79,70.46a3.37,3.37,0,0,0,2,1.56c.61.08.72-.48.24-1.27a3.43,3.43,0,0,0-2-1.56C551.42,69.11,551.32,69.68,551.79,70.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M548.29,70a3.37,3.37,0,0,0,2,1.56c.61.08.72-.49.25-1.27a3.37,3.37,0,0,0-2-1.56C547.94,68.65,547.83,69.22,548.29,70Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M544.78,69.54a3.32,3.32,0,0,0,1.94,1.56c.61.08.73-.49.27-1.27a3.35,3.35,0,0,0-1.94-1.56C544.44,68.19,544.32,68.76,544.78,69.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.27,69.09a3.28,3.28,0,0,0,1.93,1.56c.61.07.74-.5.28-1.28a3.28,3.28,0,0,0-1.93-1.55C540.94,67.75,540.82,68.31,541.27,69.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537.74,68.65a3.19,3.19,0,0,0,1.93,1.55c.61.08.74-.49.29-1.27A3.24,3.24,0,0,0,538,67.38C537.43,67.31,537.3,67.88,537.74,68.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M534.21,68.22a3.2,3.2,0,0,0,1.91,1.55c.62.07.76-.5.31-1.28A3.17,3.17,0,0,0,534.52,67C533.91,66.87,533.77,67.44,534.21,68.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530.67,67.8a3.14,3.14,0,0,0,1.9,1.54c.62.07.76-.5.32-1.28a3.16,3.16,0,0,0-1.9-1.54C530.38,66.45,530.23,67,530.67,67.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M527.11,67.38a3.09,3.09,0,0,0,1.9,1.53c.62.08.77-.49.34-1.27a3.13,3.13,0,0,0-1.9-1.54C526.84,66,526.69,66.6,527.11,67.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M523.55,67a3.07,3.07,0,0,0,1.89,1.53c.62.07.78-.5.35-1.28a3.06,3.06,0,0,0-1.88-1.53C523.29,65.62,523.13,66.19,523.55,67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520,66.57a3,3,0,0,0,1.87,1.52c.62.07.79-.5.37-1.27a3,3,0,0,0-1.88-1.53C519.74,65.22,519.57,65.79,520,66.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M516.41,66.17a3,3,0,0,0,1.86,1.53c.63.06.8-.51.39-1.28a3,3,0,0,0-1.87-1.53C516.17,64.83,516,65.4,516.41,66.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M512.82,65.78a2.9,2.9,0,0,0,1.86,1.53c.62.06.8-.51.4-1.28a2.89,2.89,0,0,0-1.86-1.52C512.6,64.44,512.42,65,512.82,65.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M509.23,65.41a2.83,2.83,0,0,0,1.84,1.51c.63.07.81-.51.42-1.28a2.87,2.87,0,0,0-1.85-1.51C509,64.06,508.84,64.63,509.23,65.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M505.63,65a2.8,2.8,0,0,0,1.83,1.51c.63.06.82-.51.43-1.28a2.82,2.82,0,0,0-1.83-1.51C505.43,63.69,505.24,64.26,505.63,65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M502,64.67a2.76,2.76,0,0,0,1.82,1.51c.63.07.83-.51.45-1.28a2.82,2.82,0,0,0-1.83-1.51C501.84,63.33,501.64,63.9,502,64.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M498.4,64.32a2.7,2.7,0,0,0,1.81,1.5c.63.07.84-.51.46-1.28a2.7,2.7,0,0,0-1.81-1.5C498.24,63,498,63.55,498.4,64.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M494.78,64a2.67,2.67,0,0,0,1.8,1.5c.63.06.84-.51.47-1.28a2.69,2.69,0,0,0-1.8-1.5C494.63,62.63,494.41,63.2,494.78,64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.14,63.64a2.66,2.66,0,0,0,1.79,1.49c.64.06.86-.52.5-1.28a2.67,2.67,0,0,0-1.79-1.5C491,62.29,490.79,62.87,491.14,63.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.51,63.31a2.59,2.59,0,0,0,1.77,1.49c.64.06.87-.52.51-1.29A2.6,2.6,0,0,0,488,62C487.39,62,487.16,62.54,487.51,63.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483.86,63a2.53,2.53,0,0,0,1.77,1.48c.63.06.87-.52.52-1.28a2.57,2.57,0,0,0-1.76-1.49C483.76,61.64,483.52,62.22,483.86,63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M480.21,62.67A2.52,2.52,0,0,0,482,64.16c.64,0,.88-.53.54-1.29a2.5,2.5,0,0,0-1.75-1.48C480.12,61.33,479.88,61.91,480.21,62.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.55,62.37a2.45,2.45,0,0,0,1.74,1.48c.64,0,.89-.53.56-1.29a2.47,2.47,0,0,0-1.74-1.48C476.47,61,476.22,61.6,476.55,62.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.88,62.07a2.42,2.42,0,0,0,1.73,1.48c.64.05.9-.53.58-1.29a2.45,2.45,0,0,0-1.73-1.48C472.82,60.73,472.57,61.31,472.88,62.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469.21,61.79a2.39,2.39,0,0,0,1.72,1.47c.64.05.9-.53.59-1.29a2.37,2.37,0,0,0-1.72-1.47C469.17,60.45,468.9,61,469.21,61.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.53,61.51A2.35,2.35,0,0,0,467.24,63c.64.05.91-.53.6-1.3a2.31,2.31,0,0,0-1.7-1.46C465.51,60.17,465.23,60.75,465.53,61.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M461.85,61.24a2.32,2.32,0,0,0,1.69,1.47c.64,0,.92-.54.62-1.3A2.29,2.29,0,0,0,462.47,60C461.84,59.9,461.56,60.48,461.85,61.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.16,61a2.24,2.24,0,0,0,1.68,1.46c.64.05.93-.54.64-1.3a2.29,2.29,0,0,0-1.68-1.46C458.16,59.64,457.88,60.22,458.16,61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M454.46,60.73a2.25,2.25,0,0,0,1.67,1.46c.64,0,.94-.54.66-1.31a2.23,2.23,0,0,0-1.67-1.45C454.49,59.39,454.19,60,454.46,60.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450.76,60.49a2.19,2.19,0,0,0,1.65,1.45c.65,0,.95-.54.68-1.3a2.19,2.19,0,0,0-1.65-1.45C450.8,59.14,450.5,59.72,450.76,60.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M447.06,60.25a2.13,2.13,0,0,0,1.63,1.45c.65,0,1-.54.7-1.3A2.18,2.18,0,0,0,447.75,59C447.11,58.91,446.8,59.49,447.06,60.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.34,60A2.1,2.1,0,0,0,445,61.47c.64,0,1-.54.71-1.3a2.13,2.13,0,0,0-1.62-1.45C443.42,58.69,443.1,59.27,443.34,60Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M439.63,59.81a2.05,2.05,0,0,0,1.6,1.44c.65,0,1-.55.73-1.3a2.05,2.05,0,0,0-1.6-1.44C439.72,58.47,439.39,59.05,439.63,59.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435.91,59.6A2,2,0,0,0,437.5,61c.65,0,1-.55.74-1.31a2,2,0,0,0-1.59-1.43C436,58.26,435.68,58.85,435.91,59.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M432.18,59.41a2,2,0,0,0,1.58,1.43c.65,0,1-.55.76-1.31a2,2,0,0,0-1.58-1.43C432.3,58.06,432,58.65,432.18,59.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.45,59.22A1.94,1.94,0,0,0,430,60.65c.65,0,1-.56.78-1.31a2,2,0,0,0-1.56-1.43C428.59,57.88,428.24,58.46,428.45,59.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M424.72,59a1.9,1.9,0,0,0,1.54,1.43c.65,0,1-.56.8-1.32a1.91,1.91,0,0,0-1.55-1.42C424.87,57.7,424.51,58.28,424.72,59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M421,58.87a1.87,1.87,0,0,0,1.53,1.42c.65,0,1-.56.82-1.32a1.92,1.92,0,0,0-1.54-1.42C421.15,57.53,420.78,58.11,421,58.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.23,58.71a1.87,1.87,0,0,0,1.52,1.42c.65,0,1-.57.83-1.32a1.86,1.86,0,0,0-1.51-1.42C417.42,57.36,417.05,58,417.23,58.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.49,58.56A1.79,1.79,0,0,0,415,60c.65,0,1-.56.85-1.32a1.82,1.82,0,0,0-1.5-1.41C413.69,57.21,413.31,57.8,413.49,58.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.73,58.41a1.8,1.8,0,0,0,1.49,1.42c.66,0,1-.57.87-1.33a1.8,1.8,0,0,0-1.49-1.41C410,57.07,409.57,57.66,409.73,58.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406,58.28a1.74,1.74,0,0,0,1.47,1.41c.66,0,1.05-.57.89-1.33a1.75,1.75,0,0,0-1.47-1.4C406.22,56.94,405.82,57.53,406,58.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402.22,58.16a1.71,1.71,0,0,0,1.46,1.4c.65,0,1.06-.57.9-1.33a1.71,1.71,0,0,0-1.45-1.4C402.48,56.81,402.07,57.4,402.22,58.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.46,58a1.69,1.69,0,0,0,1.44,1.41c.66,0,1.07-.58.92-1.34a1.68,1.68,0,0,0-1.43-1.39C398.74,56.7,398.32,57.29,398.46,58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.7,57.94a1.66,1.66,0,0,0,1.42,1.4c.66,0,1.08-.58.94-1.34a1.65,1.65,0,0,0-1.42-1.39C395,56.59,394.57,57.19,394.7,57.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.93,57.84a1.62,1.62,0,0,0,1.41,1.4c.66,0,1.08-.58,1-1.34a1.64,1.64,0,0,0-1.41-1.39C391.24,56.49,390.81,57.09,390.93,57.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.16,57.76a1.59,1.59,0,0,0,1.39,1.39,1.05,1.05,0,0,0,1-1.34,1.61,1.61,0,0,0-1.39-1.39A1.06,1.06,0,0,0,387.16,57.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M383.39,57.68a1.56,1.56,0,0,0,1.37,1.39,1.07,1.07,0,0,0,1-1.34,1.59,1.59,0,0,0-1.38-1.39A1.07,1.07,0,0,0,383.39,57.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M379.61,57.62A1.54,1.54,0,0,0,381,59a1.09,1.09,0,0,0,1-1.34,1.55,1.55,0,0,0-1.36-1.39A1.1,1.1,0,0,0,379.61,57.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M375.84,57.56a1.5,1.5,0,0,0,1.34,1.38,1.1,1.1,0,0,0,1-1.35,1.51,1.51,0,0,0-1.34-1.38A1.12,1.12,0,0,0,375.84,57.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M372.06,57.51a1.48,1.48,0,0,0,1.32,1.38,1.12,1.12,0,0,0,1.05-1.35,1.49,1.49,0,0,0-1.32-1.38A1.14,1.14,0,0,0,372.06,57.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M368.28,57.47a1.45,1.45,0,0,0,1.31,1.38,1.14,1.14,0,0,0,1.06-1.36,1.45,1.45,0,0,0-1.31-1.37A1.14,1.14,0,0,0,368.28,57.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364.5,57.44a1.41,1.41,0,0,0,1.29,1.37,1.15,1.15,0,0,0,1.08-1.35,1.43,1.43,0,0,0-1.29-1.37A1.16,1.16,0,0,0,364.5,57.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.71,57.42A1.39,1.39,0,0,0,362,58.79a1.18,1.18,0,0,0,1.1-1.36,1.39,1.39,0,0,0-1.28-1.36A1.19,1.19,0,0,0,360.71,57.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.93,57.41a1.36,1.36,0,0,0,1.26,1.37,1.19,1.19,0,0,0,1.11-1.36,1.37,1.37,0,0,0-1.25-1.36A1.2,1.2,0,0,0,356.93,57.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M353.14,57.41a1.35,1.35,0,0,0,1.24,1.37,1.23,1.23,0,0,0,1.14-1.37,1.35,1.35,0,0,0-1.24-1.36A1.23,1.23,0,0,0,353.14,57.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.35,57.42a1.31,1.31,0,0,0,1.23,1.36,1.23,1.23,0,0,0,1.15-1.36,1.32,1.32,0,0,0-1.22-1.36A1.25,1.25,0,0,0,349.35,57.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.56,57.44a1.3,1.3,0,0,0,1.21,1.36,1.26,1.26,0,0,0,1.17-1.37,1.29,1.29,0,0,0-1.2-1.35A1.27,1.27,0,0,0,345.56,57.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.78,57.47A1.28,1.28,0,0,0,343,58.83a1.31,1.31,0,0,0,1.19-1.38,1.2,1.2,0,1,0-2.38,0Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M338,57.51a1.24,1.24,0,0,0,1.17,1.35,1.32,1.32,0,0,0,1.21-1.37,1.25,1.25,0,0,0-1.18-1.35A1.32,1.32,0,0,0,338,57.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M334.2,57.56a1.22,1.22,0,0,0,1.15,1.35,1.35,1.35,0,0,0,1.23-1.38,1.24,1.24,0,0,0-1.16-1.35A1.35,1.35,0,0,0,334.2,57.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M330.41,57.62A1.19,1.19,0,0,0,331.55,59a1.37,1.37,0,0,0,1.24-1.38,1.21,1.21,0,0,0-1.14-1.34A1.36,1.36,0,0,0,330.41,57.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M326.62,57.68A1.19,1.19,0,0,0,327.74,59,1.41,1.41,0,0,0,329,57.64a1.19,1.19,0,0,0-1.12-1.34A1.39,1.39,0,0,0,326.62,57.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M322.83,57.76a1.16,1.16,0,0,0,1.1,1.34,1.43,1.43,0,0,0,1.28-1.39,1.16,1.16,0,0,0-1.11-1.33A1.42,1.42,0,0,0,322.83,57.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M315.25,57.94a1.13,1.13,0,0,0,1.07,1.34,1.48,1.48,0,0,0,1.31-1.4,1.13,1.13,0,0,0-1.07-1.33A1.48,1.48,0,0,0,315.25,57.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M221.21,63.4c-.3.77,0,1.34.64,1.29a2.37,2.37,0,0,0,1.69-1.49c.29-.77,0-1.34-.64-1.28A2.33,2.33,0,0,0,221.21,63.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M217.49,63.74c-.3.77,0,1.34.63,1.28a2.41,2.41,0,0,0,1.71-1.49c.29-.77,0-1.34-.63-1.28A2.43,2.43,0,0,0,217.49,63.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M213.79,64.09c-.32.76,0,1.34.6,1.28a2.4,2.4,0,0,0,1.72-1.5c.31-.77,0-1.34-.6-1.28A2.44,2.44,0,0,0,213.79,64.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210.08,64.44c-.32.77-.05,1.34.59,1.28a2.47,2.47,0,0,0,1.74-1.5c.31-.77.05-1.34-.59-1.28A2.51,2.51,0,0,0,210.08,64.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.39,64.81c-.33.76-.07,1.34.57,1.27a2.52,2.52,0,0,0,1.75-1.5c.32-.77.06-1.34-.58-1.28A2.54,2.54,0,0,0,206.39,64.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M202.7,65.18c-.34.77-.09,1.34.55,1.27A2.55,2.55,0,0,0,205,64.94c.33-.76.08-1.33-.55-1.27A2.57,2.57,0,0,0,202.7,65.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M199,65.56c-.34.77-.1,1.34.54,1.27a2.56,2.56,0,0,0,1.77-1.51c.34-.77.1-1.34-.54-1.27A2.6,2.6,0,0,0,199,65.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M195.33,66c-.35.77-.12,1.34.53,1.27a2.64,2.64,0,0,0,1.78-1.52c.35-.77.11-1.33-.52-1.27A2.67,2.67,0,0,0,195.33,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.66,66.35c-.36.77-.13,1.34.51,1.27A2.67,2.67,0,0,0,194,66.1c.36-.78.13-1.34-.5-1.27A2.71,2.71,0,0,0,191.66,66.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M162.53,69.83c-.41.77-.24,1.34.39,1.26a3,3,0,0,0,1.88-1.56c.41-.77.24-1.34-.38-1.26A3.08,3.08,0,0,0,162.53,69.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M158.93,70.3c-.43.77-.26,1.34.37,1.26A3.08,3.08,0,0,0,161.19,70c.42-.78.25-1.34-.37-1.26A3.1,3.1,0,0,0,158.93,70.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.33,70.78c-.43.77-.27,1.34.36,1.26a3.2,3.2,0,0,0,1.9-1.57c.42-.77.26-1.34-.36-1.25A3.15,3.15,0,0,0,155.33,70.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M151.74,71.26c-.44.78-.28,1.35.34,1.26A3.17,3.17,0,0,0,154,71c.43-.78.28-1.35-.34-1.26A3.16,3.16,0,0,0,151.74,71.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M148.16,71.76c-.44.78-.3,1.34.33,1.25a3.23,3.23,0,0,0,1.92-1.57c.43-.78.29-1.34-.33-1.25A3.19,3.19,0,0,0,148.16,71.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M144.59,72.26c-.45.78-.31,1.34.31,1.25a3.24,3.24,0,0,0,1.93-1.57c.44-.78.3-1.34-.32-1.25A3.25,3.25,0,0,0,144.59,72.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M141,72.77c-.45.78-.32,1.34.3,1.25a3.32,3.32,0,0,0,1.94-1.57c.45-.79.32-1.35-.3-1.26A3.33,3.33,0,0,0,141,72.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M137.47,73.28c-.46.79-.33,1.35.29,1.26A3.4,3.4,0,0,0,139.7,73c.46-.78.33-1.35-.29-1.26A3.4,3.4,0,0,0,137.47,73.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M133.92,73.81c-.46.78-.34,1.34.28,1.25a3.42,3.42,0,0,0,2-1.58c.46-.79.34-1.35-.27-1.26A3.42,3.42,0,0,0,133.92,73.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M130.39,74.34c-.47.78-.36,1.34.26,1.25a3.45,3.45,0,0,0,2-1.59c.47-.78.35-1.34-.26-1.25A3.45,3.45,0,0,0,130.39,74.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M126.86,74.87c-.47.79-.36,1.35.25,1.26a3.58,3.58,0,0,0,2-1.59c.47-.79.36-1.35-.25-1.25A3.52,3.52,0,0,0,126.86,74.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M123.35,75.42c-.48.79-.38,1.35.23,1.25a3.56,3.56,0,0,0,2-1.59c.47-.79.37-1.35-.24-1.25A3.53,3.53,0,0,0,123.35,75.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M119.84,76c-.48.79-.38,1.35.22,1.25a3.58,3.58,0,0,0,2-1.6c.48-.78.38-1.34-.23-1.25A3.69,3.69,0,0,0,119.84,76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M116.35,76.53c-.49.79-.4,1.35.21,1.25a3.66,3.66,0,0,0,2-1.6c.49-.79.4-1.35-.2-1.25A3.63,3.63,0,0,0,116.35,76.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M112.87,77.1c-.5.78-.41,1.34.19,1.24a3.58,3.58,0,0,0,2-1.6c.5-.79.41-1.35-.19-1.25A3.68,3.68,0,0,0,112.87,77.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M109.39,77.67c-.5.79-.42,1.35.19,1.25a3.72,3.72,0,0,0,2-1.61c.5-.79.42-1.35-.18-1.25A3.77,3.77,0,0,0,109.39,77.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M105.93,78.25c-.51.79-.43,1.34.17,1.24a3.74,3.74,0,0,0,2-1.61c.51-.79.43-1.34-.17-1.24A3.77,3.77,0,0,0,105.93,78.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M102.48,78.83c-.51.79-.44,1.35.16,1.25a3.81,3.81,0,0,0,2-1.62c.5-.79.43-1.34-.16-1.24A3.79,3.79,0,0,0,102.48,78.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M99,79.42c-.52.79-.45,1.35.15,1.25a3.9,3.9,0,0,0,2-1.62c.51-.79.45-1.35-.15-1.24A3.86,3.86,0,0,0,99,79.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M95.61,80c-.52.79-.46,1.35.14,1.24a3.85,3.85,0,0,0,2-1.62c.52-.79.46-1.34-.13-1.24A3.87,3.87,0,0,0,95.61,80Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M92.19,80.62c-.52.8-.46,1.35.13,1.25a4,4,0,0,0,2-1.63c.52-.79.46-1.34-.13-1.24A4,4,0,0,0,92.19,80.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M88.79,81.23c-.53.8-.48,1.35.11,1.25a4,4,0,0,0,2-1.63c.52-.79.47-1.35-.12-1.24A3.94,3.94,0,0,0,88.79,81.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M85.4,81.85c-.54.79-.49,1.35.1,1.24a4,4,0,0,0,2-1.63c.53-.79.48-1.35-.11-1.24A4,4,0,0,0,85.4,81.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M82,82.47c-.54.79-.5,1.35.09,1.24a4,4,0,0,0,2-1.63c.53-.79.49-1.35-.1-1.24A4.06,4.06,0,0,0,82,82.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M78.65,83.1c-.54.79-.5,1.35.08,1.24a4,4,0,0,0,2-1.64c.54-.79.5-1.34-.08-1.23A4.07,4.07,0,0,0,78.65,83.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M75.29,83.73c-.54.8-.51,1.35.08,1.24a4.09,4.09,0,0,0,2-1.64c.54-.79.5-1.34-.08-1.23A4.12,4.12,0,0,0,75.29,83.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M72,84.37c-.54.79-.52,1.35.06,1.24a4.17,4.17,0,0,0,2-1.64c.54-.8.51-1.35-.07-1.24A4.24,4.24,0,0,0,72,84.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M65.3,85.66c-.55.8-.53,1.35,0,1.24a4.18,4.18,0,0,0,2-1.65c.54-.79.52-1.34,0-1.23A4.3,4.3,0,0,0,65.3,85.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M632.43,79.62a4.28,4.28,0,0,0,2,1.63c.55.11.54-.45,0-1.24a4.3,4.3,0,0,0-2-1.64C631.85,78.26,631.86,78.82,632.43,79.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M629.23,79a4.39,4.39,0,0,0,2,1.63c.56.11.55-.45,0-1.25a4.36,4.36,0,0,0-2-1.64C628.65,77.65,628.66,78.21,629.23,79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M626,78.4a4.35,4.35,0,0,0,2,1.63c.56.11.55-.46,0-1.25a4.24,4.24,0,0,0-2-1.63C625.45,77,625.45,77.6,626,78.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M622.8,77.8a4.21,4.21,0,0,0,2,1.62c.56.11.56-.45,0-1.25a4.3,4.3,0,0,0-2-1.63C622.23,76.44,622.23,77,622.8,77.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M619.56,77.2a4.27,4.27,0,0,0,2,1.63c.56.1.56-.46,0-1.26a4.27,4.27,0,0,0-2-1.62C619,75.84,619,76.4,619.56,77.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.32,76.61a4.21,4.21,0,0,0,2,1.62c.56.1.57-.46,0-1.25a4.24,4.24,0,0,0-2-1.63C615.76,75.25,615.76,75.81,616.32,76.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.06,76a4.17,4.17,0,0,0,2,1.62c.57.1.57-.46,0-1.25a4.15,4.15,0,0,0-2-1.62C612.51,74.67,612.5,75.23,613.06,76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M609.79,75.44a4.12,4.12,0,0,0,2,1.62c.57.1.58-.46,0-1.26a4.18,4.18,0,0,0-2-1.61C609.25,74.09,609.24,74.65,609.79,75.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M606.51,74.87a4.09,4.09,0,0,0,2,1.61c.57.1.58-.46,0-1.25a4.09,4.09,0,0,0-2-1.62C606,73.51,606,74.07,606.51,74.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603.22,74.3a4,4,0,0,0,2,1.61c.58.1.6-.46,0-1.26a4.09,4.09,0,0,0-2-1.61C602.69,72.94,602.67,73.51,603.22,74.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M599.92,73.73a4,4,0,0,0,2,1.61c.57.1.6-.46,0-1.25a4,4,0,0,0-2-1.61C599.4,72.38,599.37,72.94,599.92,73.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M596.6,73.18a3.92,3.92,0,0,0,2,1.6c.58.1.61-.46.06-1.25a4,4,0,0,0-2-1.61C596.09,71.83,596.06,72.39,596.6,73.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M593.28,72.63a3.91,3.91,0,0,0,2,1.6c.58.09.61-.47.08-1.26a4,4,0,0,0-2-1.6C592.77,71.27,592.74,71.84,593.28,72.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M589.94,72.08a3.86,3.86,0,0,0,2,1.6c.58.09.62-.47.09-1.26a3.89,3.89,0,0,0-2-1.59C589.45,70.73,589.41,71.29,589.94,72.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M586.59,71.54a3.91,3.91,0,0,0,2,1.6c.58.09.63-.47.1-1.26a3.84,3.84,0,0,0-2-1.59C586.11,70.19,586.07,70.76,586.59,71.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M583.24,71a3.78,3.78,0,0,0,2,1.59c.59.09.63-.47.11-1.26a3.89,3.89,0,0,0-2-1.59C582.76,69.66,582.71,70.22,583.24,71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M579.87,70.48a3.75,3.75,0,0,0,2,1.59c.59.09.64-.47.12-1.26a3.84,3.84,0,0,0-2-1.58C579.4,69.14,579.35,69.7,579.87,70.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M576.49,70a3.71,3.71,0,0,0,2,1.59c.58.09.64-.47.12-1.26a3.7,3.7,0,0,0-2-1.58C576,68.62,576,69.18,576.49,70Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M573.1,69.45a3.67,3.67,0,0,0,2,1.58c.59.09.66-.47.14-1.26a3.72,3.72,0,0,0-2-1.58C572.66,68.11,572.6,68.67,573.1,69.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M569.71,69a3.6,3.6,0,0,0,2,1.57c.59.09.66-.47.15-1.26a3.63,3.63,0,0,0-2-1.57C569.27,67.6,569.2,68.16,569.71,69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M566.3,68.45a3.61,3.61,0,0,0,2,1.57c.59.09.66-.48.16-1.26a3.6,3.6,0,0,0-2-1.57C565.87,67.1,565.8,67.66,566.3,68.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M562.88,68a3.58,3.58,0,0,0,2,1.57c.6.09.68-.48.18-1.26a3.62,3.62,0,0,0-2-1.57C562.47,66.61,562.39,67.17,562.88,68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M559.46,67.47a3.44,3.44,0,0,0,2,1.56c.6.09.68-.48.19-1.26a3.49,3.49,0,0,0-2-1.56C559.05,66.12,559,66.69,559.46,67.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M556,67a3.44,3.44,0,0,0,2,1.56c.6.08.69-.48.2-1.26a3.51,3.51,0,0,0-2-1.56C555.63,65.65,555.54,66.21,556,67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M552.57,66.52a3.47,3.47,0,0,0,1.95,1.56c.61.08.7-.49.22-1.27a3.44,3.44,0,0,0-2-1.55C552.19,65.18,552.1,65.74,552.57,66.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.12,66.05a3.38,3.38,0,0,0,1.94,1.56c.61.08.71-.49.23-1.27a3.46,3.46,0,0,0-1.94-1.55C548.75,64.71,548.65,65.28,549.12,66.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.66,65.6a3.35,3.35,0,0,0,1.93,1.55c.61.08.71-.49.24-1.27a3.38,3.38,0,0,0-1.93-1.55C545.3,64.25,545.19,64.82,545.66,65.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M542.18,65.15a3.33,3.33,0,0,0,1.93,1.54c.61.08.72-.49.26-1.26a3.34,3.34,0,0,0-1.93-1.55C541.84,63.81,541.72,64.37,542.18,65.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M538.7,64.7a3.24,3.24,0,0,0,1.92,1.54c.61.08.73-.49.27-1.26A3.28,3.28,0,0,0,539,63.44C538.37,63.36,538.25,63.93,538.7,64.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.21,64.27a3.27,3.27,0,0,0,1.91,1.54c.61.07.74-.5.29-1.27A3.3,3.3,0,0,0,535.5,63C534.89,62.93,534.77,63.49,535.21,64.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531.71,63.84a3.16,3.16,0,0,0,1.91,1.53c.61.08.74-.49.29-1.26a3.11,3.11,0,0,0-1.9-1.53C531.41,62.5,531.27,63.07,531.71,63.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M528.21,63.42A3.1,3.1,0,0,0,530.1,65c.61.07.75-.5.31-1.27a3.16,3.16,0,0,0-1.89-1.53C527.91,62.08,527.77,62.65,528.21,63.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M524.69,63a3.08,3.08,0,0,0,1.89,1.52c.61.07.75-.49.32-1.27A3.06,3.06,0,0,0,525,61.74C524.41,61.67,524.27,62.24,524.69,63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M521.17,62.6a3,3,0,0,0,1.88,1.52c.61.07.76-.5.33-1.27a3,3,0,0,0-1.87-1.52C520.9,61.27,520.75,61.83,521.17,62.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M517.64,62.2a3,3,0,0,0,1.87,1.52c.61.07.77-.5.35-1.27A3,3,0,0,0,518,60.94C517.39,60.87,517.23,61.44,517.64,62.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M514.1,61.81A3,3,0,0,0,516,63.33c.61.07.78-.5.37-1.27a3,3,0,0,0-1.86-1.51C513.86,60.48,513.7,61.05,514.1,61.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510.56,61.43a2.85,2.85,0,0,0,1.84,1.51c.62.07.79-.5.38-1.27a2.91,2.91,0,0,0-1.84-1.51C510.33,60.1,510.16,60.67,510.56,61.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M507,61.06a2.87,2.87,0,0,0,1.84,1.5c.62.07.79-.5.4-1.27a2.87,2.87,0,0,0-1.84-1.5C506.79,59.73,506.61,60.29,507,61.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M503.44,60.69a2.8,2.8,0,0,0,1.83,1.5c.62.07.8-.5.41-1.27a2.87,2.87,0,0,0-1.82-1.5C503.24,59.36,503.06,59.93,503.44,60.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499.88,60.34a2.74,2.74,0,0,0,1.81,1.49c.62.07.81-.51.43-1.27a2.82,2.82,0,0,0-1.81-1.5C499.69,59,499.5,59.57,499.88,60.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M496.3,60a2.72,2.72,0,0,0,1.8,1.49c.62.06.82-.51.45-1.28a2.74,2.74,0,0,0-1.8-1.49C496.13,58.65,495.93,59.22,496.3,60Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M492.72,59.65a2.65,2.65,0,0,0,1.79,1.48c.62.06.83-.51.46-1.27a2.71,2.71,0,0,0-1.79-1.49C492.56,58.31,492.36,58.88,492.72,59.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.13,59.31a2.65,2.65,0,0,0,1.78,1.49c.62.05.84-.52.48-1.28A2.65,2.65,0,0,0,489.61,58C489,58,488.78,58.55,489.13,59.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.54,59a2.56,2.56,0,0,0,1.76,1.48c.63.05.85-.52.5-1.28A2.64,2.64,0,0,0,486,57.71C485.41,57.66,485.19,58.23,485.54,59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.94,58.67a2.55,2.55,0,0,0,1.75,1.48c.63.05.85-.52.51-1.28a2.55,2.55,0,0,0-1.75-1.47C481.82,57.34,481.6,57.91,481.94,58.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.33,58.36a2.52,2.52,0,0,0,1.74,1.48c.63,0,.86-.52.53-1.28a2.51,2.51,0,0,0-1.74-1.47C478.23,57,478,57.6,478.33,58.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.72,58.06a2.43,2.43,0,0,0,1.72,1.47c.63,0,.88-.52.55-1.28a2.47,2.47,0,0,0-1.73-1.46C474.63,56.73,474.39,57.31,474.72,58.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M471.1,57.77a2.4,2.4,0,0,0,1.71,1.47c.63,0,.88-.53.56-1.29a2.44,2.44,0,0,0-1.71-1.46C471,56.44,470.78,57,471.1,57.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M467.47,57.49a2.37,2.37,0,0,0,1.7,1.46c.63,0,.89-.53.58-1.28a2.41,2.41,0,0,0-1.7-1.46C467.42,56.16,467.16,56.73,467.47,57.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M463.84,57.22a2.33,2.33,0,0,0,1.69,1.45c.63,0,.9-.53.59-1.28a2.36,2.36,0,0,0-1.69-1.46C463.81,55.89,463.54,56.46,463.84,57.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M460.2,57a2.26,2.26,0,0,0,1.68,1.45c.63.05.9-.53.61-1.28a2.29,2.29,0,0,0-1.68-1.45C460.19,55.62,459.91,56.2,460.2,57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M456.56,56.69a2.23,2.23,0,0,0,1.66,1.45c.64,0,.92-.53.63-1.29a2.25,2.25,0,0,0-1.66-1.44C456.56,55.37,456.28,55.94,456.56,56.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M452.91,56.45a2.21,2.21,0,0,0,1.65,1.44c.64,0,.92-.54.64-1.29a2.21,2.21,0,0,0-1.64-1.44C452.93,55.12,452.64,55.69,452.91,56.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M449.26,56.21a2.18,2.18,0,0,0,1.63,1.44c.64,0,.94-.54.66-1.29a2.16,2.16,0,0,0-1.63-1.44C449.29,54.88,449,55.45,449.26,56.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M445.6,56a2.13,2.13,0,0,0,1.62,1.43c.64,0,.94-.54.68-1.29a2.14,2.14,0,0,0-1.62-1.43C445.65,54.65,445.35,55.23,445.6,56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M441.94,55.76a2.1,2.1,0,0,0,1.61,1.43c.63,0,.95-.54.69-1.3a2.07,2.07,0,0,0-1.6-1.42C442,54.43,441.69,55,441.94,55.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M438.27,55.54A2,2,0,0,0,439.86,57c.64,0,1-.54.72-1.29A2.07,2.07,0,0,0,439,54.25C438.35,54.21,438,54.79,438.27,55.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.6,55.34a2,2,0,0,0,1.58,1.42c.64,0,1-.54.73-1.29a2,2,0,0,0-1.58-1.42C434.7,54,434.37,54.59,434.6,55.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.93,55.15a2,2,0,0,0,1.56,1.42c.64,0,1-.55.75-1.3a2,2,0,0,0-1.57-1.42C431,53.82,430.7,54.4,430.93,55.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427.25,55a1.93,1.93,0,0,0,1.54,1.42c.65,0,1-.55.77-1.3A2,2,0,0,0,428,53.66C427.38,53.63,427,54.21,427.25,55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.56,54.78a1.92,1.92,0,0,0,1.53,1.42c.65,0,1-.56.79-1.31a1.92,1.92,0,0,0-1.53-1.41C423.71,53.45,423.36,54,423.56,54.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419.87,54.62A1.9,1.9,0,0,0,421.39,56c.64,0,1-.56.8-1.31a1.86,1.86,0,0,0-1.52-1.4C420,53.29,419.68,53.87,419.87,54.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.18,54.46a1.82,1.82,0,0,0,1.5,1.4c.65,0,1-.55.82-1.3a1.82,1.82,0,0,0-1.5-1.4C416.36,53.13,416,53.71,416.18,54.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.49,54.31a1.78,1.78,0,0,0,1.48,1.4c.65,0,1-.56.84-1.31a1.81,1.81,0,0,0-1.49-1.4C412.69,53,412.31,53.56,412.49,54.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.79,54.17a1.77,1.77,0,0,0,1.47,1.4c.64,0,1-.57.85-1.31a1.78,1.78,0,0,0-1.47-1.4C409,52.84,408.62,53.42,408.79,54.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M405.09,54a1.71,1.71,0,0,0,1.45,1.39c.65,0,1-.56.87-1.31A1.72,1.72,0,0,0,406,52.73C405.32,52.71,404.93,53.29,405.09,54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.38,53.92a1.7,1.7,0,0,0,1.44,1.39c.65,0,1-.57.89-1.32a1.71,1.71,0,0,0-1.44-1.38C401.63,52.59,401.23,53.17,401.38,53.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397.67,53.81a1.67,1.67,0,0,0,1.43,1.38c.64,0,1-.57.9-1.32a1.67,1.67,0,0,0-1.42-1.38C397.94,52.47,397.53,53.06,397.67,53.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394,53.7a1.64,1.64,0,0,0,1.41,1.39c.65,0,1.06-.58.92-1.32a1.62,1.62,0,0,0-1.4-1.38C394.25,52.37,393.83,53,394,53.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.25,53.61A1.61,1.61,0,0,0,391.64,55c.65,0,1.07-.58.94-1.32a1.62,1.62,0,0,0-1.39-1.38C390.55,52.27,390.13,52.86,390.25,53.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.53,53.52a1.57,1.57,0,0,0,1.38,1.38,1,1,0,0,0,1-1.32,1.6,1.6,0,0,0-1.38-1.38A1,1,0,0,0,386.53,53.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.81,53.45a1.54,1.54,0,0,0,1.36,1.37,1,1,0,0,0,1-1.32,1.55,1.55,0,0,0-1.36-1.37A1,1,0,0,0,382.81,53.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M379.09,53.38a1.5,1.5,0,0,0,1.34,1.37,1.07,1.07,0,0,0,1-1.33,1.52,1.52,0,0,0-1.34-1.36A1.07,1.07,0,0,0,379.09,53.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M375.37,53.33a1.47,1.47,0,0,0,1.32,1.36,1.09,1.09,0,0,0,1-1.33A1.5,1.5,0,0,0,376.38,52,1.09,1.09,0,0,0,375.37,53.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.64,53.28A1.46,1.46,0,0,0,373,54.64a1.1,1.1,0,0,0,1-1.33A1.46,1.46,0,0,0,372.67,52,1.11,1.11,0,0,0,371.64,53.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.92,53.24a1.41,1.41,0,0,0,1.29,1.36,1.12,1.12,0,0,0,1.05-1.33A1.44,1.44,0,0,0,369,51.91,1.12,1.12,0,0,0,367.92,53.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364.19,53.22a1.39,1.39,0,0,0,1.27,1.35,1.14,1.14,0,0,0,1.07-1.34,1.4,1.4,0,0,0-1.28-1.35A1.14,1.14,0,0,0,364.19,53.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.46,53.2a1.37,1.37,0,0,0,1.26,1.35,1.17,1.17,0,0,0,1.08-1.34,1.37,1.37,0,0,0-1.26-1.35A1.16,1.16,0,0,0,360.46,53.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.73,53.19A1.35,1.35,0,0,0,358,54.54a1.19,1.19,0,0,0,1.1-1.35,1.35,1.35,0,0,0-1.24-1.34A1.18,1.18,0,0,0,356.73,53.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M353,53.19a1.34,1.34,0,0,0,1.23,1.35,1.21,1.21,0,0,0,1.12-1.35,1.33,1.33,0,0,0-1.23-1.35A1.22,1.22,0,0,0,353,53.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.26,53.2a1.31,1.31,0,0,0,1.21,1.34,1.24,1.24,0,0,0,1.14-1.35,1.32,1.32,0,0,0-1.21-1.34A1.23,1.23,0,0,0,349.26,53.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.53,53.22a1.28,1.28,0,0,0,1.19,1.34,1.25,1.25,0,0,0,1.15-1.36,1.27,1.27,0,0,0-1.19-1.33A1.25,1.25,0,0,0,345.53,53.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.79,53.24A1.25,1.25,0,0,0,343,54.58a1.36,1.36,0,1,0-1.17-1.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M323.11,53.53a1.14,1.14,0,0,0,1.09,1.32,1.4,1.4,0,0,0,1.26-1.37,1.15,1.15,0,0,0-1.09-1.32A1.42,1.42,0,0,0,323.11,53.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M319.38,53.61a1.13,1.13,0,0,0,1.07,1.33,1.43,1.43,0,0,0,1.27-1.38,1.13,1.13,0,0,0-1.07-1.32A1.44,1.44,0,0,0,319.38,53.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.21,59.43c-.31.76,0,1.33.6,1.27a2.39,2.39,0,0,0,1.7-1.48c.3-.76,0-1.32-.61-1.27A2.39,2.39,0,0,0,219.21,59.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.59,60.85c-.33.76-.09,1.33.54,1.27a2.57,2.57,0,0,0,1.75-1.5c.33-.76.09-1.33-.54-1.26A2.56,2.56,0,0,0,204.59,60.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201,61.23c-.34.76-.11,1.33.53,1.26a2.6,2.6,0,0,0,1.76-1.5c.33-.76.1-1.33-.53-1.26A2.57,2.57,0,0,0,201,61.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197.32,61.61c-.36.77-.13,1.33.51,1.26a2.63,2.63,0,0,0,1.77-1.5c.34-.76.12-1.33-.51-1.26A2.66,2.66,0,0,0,197.32,61.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.69,62c-.36.77-.14,1.34.49,1.27A2.69,2.69,0,0,0,196,61.76c.36-.77.14-1.33-.49-1.26A2.69,2.69,0,0,0,193.69,62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.84,63.23c-.39.77-.19,1.34.44,1.26A2.78,2.78,0,0,0,185.1,63c.38-.77.18-1.33-.45-1.26A2.87,2.87,0,0,0,182.84,63.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172,64.54c-.4.77-.22,1.33.41,1.25a2.93,2.93,0,0,0,1.85-1.53c.4-.77.22-1.33-.4-1.26A3,3,0,0,0,172,64.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.46,65c-.41.77-.24,1.33.39,1.25a3,3,0,0,0,1.86-1.54c.41-.77.23-1.33-.39-1.25A3,3,0,0,0,168.46,65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M164.88,65.45c-.41.77-.25,1.33.38,1.25a3,3,0,0,0,1.87-1.54c.41-.77.24-1.33-.37-1.26A3.07,3.07,0,0,0,164.88,65.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.32,65.91c-.43.78-.27,1.34.35,1.26a3.13,3.13,0,0,0,1.89-1.55c.41-.77.25-1.33-.36-1.25A3.08,3.08,0,0,0,161.32,65.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M157.76,66.39c-.43.77-.28,1.33.34,1.25A3.12,3.12,0,0,0,160,66.09c.43-.78.27-1.34-.34-1.25A3.08,3.08,0,0,0,157.76,66.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M154.2,66.87c-.43.77-.29,1.33.33,1.25a3.18,3.18,0,0,0,1.9-1.56c.43-.77.29-1.33-.33-1.25A3.24,3.24,0,0,0,154.2,66.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M150.66,67.36c-.45.77-.3,1.33.31,1.25a3.26,3.26,0,0,0,1.91-1.56c.44-.78.3-1.34-.31-1.25A3.19,3.19,0,0,0,150.66,67.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M147.12,67.85c-.45.78-.31,1.34.3,1.25a3.21,3.21,0,0,0,1.92-1.56c.44-.78.31-1.33-.3-1.25A3.34,3.34,0,0,0,147.12,67.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M143.59,68.36c-.45.77-.32,1.33.29,1.25A3.34,3.34,0,0,0,145.81,68c.45-.78.32-1.34-.29-1.25A3.37,3.37,0,0,0,143.59,68.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M140.07,68.87c-.46.78-.34,1.34.27,1.25a3.39,3.39,0,0,0,1.94-1.57c.46-.78.33-1.34-.27-1.25A3.38,3.38,0,0,0,140.07,68.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M136.56,69.39c-.47.78-.35,1.34.26,1.25a3.4,3.4,0,0,0,1.94-1.58c.47-.78.35-1.34-.26-1.25A3.43,3.43,0,0,0,136.56,69.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M133.06,69.91c-.48.78-.37,1.34.24,1.25a3.47,3.47,0,0,0,2-1.58c.46-.78.35-1.33-.25-1.24A3.43,3.43,0,0,0,133.06,69.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M129.56,70.45c-.48.78-.37,1.34.24,1.24a3.5,3.5,0,0,0,2-1.58c.47-.78.36-1.34-.24-1.24A3.53,3.53,0,0,0,129.56,70.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M126.08,71c-.49.78-.39,1.34.22,1.24a3.52,3.52,0,0,0,2-1.58c.47-.78.37-1.34-.23-1.25A3.6,3.6,0,0,0,126.08,71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M122.6,71.54c-.49.78-.39,1.34.21,1.24a3.58,3.58,0,0,0,2-1.59c.49-.78.39-1.34-.21-1.24A3.56,3.56,0,0,0,122.6,71.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M119.14,72.09c-.5.78-.41,1.34.19,1.24a3.59,3.59,0,0,0,2-1.59c.49-.78.4-1.34-.2-1.24A3.6,3.6,0,0,0,119.14,72.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M115.68,72.65c-.5.79-.41,1.34.19,1.24a3.62,3.62,0,0,0,2-1.59c.49-.79.41-1.34-.19-1.25A3.76,3.76,0,0,0,115.68,72.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M112.23,73.22c-.5.78-.42,1.34.18,1.24a3.7,3.7,0,0,0,2-1.6c.5-.78.42-1.34-.18-1.24A3.77,3.77,0,0,0,112.23,73.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M108.8,73.79c-.51.79-.44,1.35.16,1.25a3.77,3.77,0,0,0,2-1.61c.5-.78.43-1.34-.17-1.24A3.82,3.82,0,0,0,108.8,73.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M105.37,74.37c-.51.79-.44,1.35.15,1.25a3.77,3.77,0,0,0,2-1.61c.51-.79.44-1.34-.15-1.24A3.83,3.83,0,0,0,105.37,74.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M629.21,75.11a4.36,4.36,0,0,0,2,1.64c.55.11.54-.45,0-1.25a4.36,4.36,0,0,0-2-1.64C628.63,73.76,628.64,74.32,629.21,75.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M626,74.5a4.36,4.36,0,0,0,2,1.64c.56.1.55-.46,0-1.25a4.36,4.36,0,0,0-2-1.64C625.45,73.15,625.46,73.71,626,74.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M622.83,73.9a4.27,4.27,0,0,0,2,1.63c.56.1.55-.46,0-1.25a4.27,4.27,0,0,0-2-1.63C622.25,72.54,622.26,73.1,622.83,73.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M619.62,73.3a4.27,4.27,0,0,0,2,1.63c.56.1.56-.46,0-1.25a4.18,4.18,0,0,0-2-1.63C619.05,72,619.05,72.51,619.62,73.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.39,72.71a4.25,4.25,0,0,0,2,1.62c.56.11.56-.46,0-1.25a4.21,4.21,0,0,0-2-1.62C615.83,71.36,615.83,71.92,616.39,72.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.16,72.12a4.21,4.21,0,0,0,2,1.62c.56.1.56-.46,0-1.25a4.15,4.15,0,0,0-2-1.62C612.61,70.77,612.61,71.33,613.16,72.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M609.92,71.54a4.17,4.17,0,0,0,2,1.62c.56.1.57-.46,0-1.26a4.14,4.14,0,0,0-2-1.61C609.37,70.19,609.37,70.75,609.92,71.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M606.67,71a4.15,4.15,0,0,0,2,1.62c.57.1.58-.46,0-1.26a4.12,4.12,0,0,0-2-1.61C606.13,69.61,606.12,70.17,606.67,71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603.41,70.39a4,4,0,0,0,2,1.61c.56.1.58-.46,0-1.25a4.14,4.14,0,0,0-2-1.61C602.87,69,602.86,69.6,603.41,70.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M600.13,69.83a4.08,4.08,0,0,0,2,1.61c.57.09.59-.47,0-1.26a4,4,0,0,0-2-1.6C599.6,68.48,599.59,69,600.13,69.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M596.85,69.27a3.94,3.94,0,0,0,2,1.6c.58.1.6-.46,0-1.25a4,4,0,0,0-2-1.6C596.33,67.92,596.31,68.48,596.85,69.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M593.55,68.72a4,4,0,0,0,2,1.6c.57.09.6-.47,0-1.25a3.94,3.94,0,0,0-2-1.6C593,67.37,593,67.93,593.55,68.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M590.25,68.17a4,4,0,0,0,2,1.6c.57.09.6-.47.07-1.25a3.94,3.94,0,0,0-2-1.6C589.75,66.83,589.72,67.39,590.25,68.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M586.94,67.63a3.93,3.93,0,0,0,2,1.6c.58.09.61-.47.08-1.26a3.93,3.93,0,0,0-2-1.59C586.44,66.29,586.41,66.85,586.94,67.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M583.61,67.1a3.89,3.89,0,0,0,2,1.59c.57.09.61-.47.08-1.26a3.84,3.84,0,0,0-2-1.58C583.13,65.75,583.09,66.32,583.61,67.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M580.28,66.57a3.78,3.78,0,0,0,2,1.59c.58.09.62-.47.1-1.26a3.84,3.84,0,0,0-2-1.58C579.8,65.23,579.76,65.79,580.28,66.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M576.94,66.05a3.79,3.79,0,0,0,2,1.58c.58.09.63-.47.11-1.25a3.79,3.79,0,0,0-2-1.58C576.47,64.71,576.42,65.27,576.94,66.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M573.59,65.54a3.74,3.74,0,0,0,2,1.58c.59.08.64-.48.12-1.26a3.66,3.66,0,0,0-2-1.57C573.13,64.2,573.07,64.76,573.59,65.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M570.23,65a3.6,3.6,0,0,0,2,1.57c.58.09.64-.47.13-1.25a3.71,3.71,0,0,0-2-1.57C569.78,63.69,569.72,64.25,570.23,65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M566.86,64.53a3.62,3.62,0,0,0,2,1.57c.59.09.65-.48.14-1.26a3.59,3.59,0,0,0-2-1.56C566.42,63.19,566.35,63.75,566.86,64.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M563.48,64a3.57,3.57,0,0,0,2,1.56c.58.09.65-.48.15-1.25a3.62,3.62,0,0,0-2-1.57C563.05,62.7,563,63.26,563.48,64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M560.09,63.55a3.51,3.51,0,0,0,2,1.56c.59.09.67-.48.17-1.26a3.59,3.59,0,0,0-2-1.55C559.67,62.21,559.59,62.77,560.09,63.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M556.69,63.07a3.55,3.55,0,0,0,2,1.56c.59.08.67-.48.18-1.26a3.52,3.52,0,0,0-2-1.55C556.28,61.73,556.2,62.29,556.69,63.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M553.29,62.6a3.45,3.45,0,0,0,2,1.55c.59.08.67-.48.19-1.26a3.51,3.51,0,0,0-1.95-1.55C552.89,61.26,552.8,61.82,553.29,62.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.87,62.13a3.46,3.46,0,0,0,1.94,1.55c.6.08.69-.48.21-1.26a3.44,3.44,0,0,0-1.94-1.54C549.49,60.8,549.4,61.36,549.87,62.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M546.45,61.67a3.41,3.41,0,0,0,1.93,1.55c.6.08.7-.49.22-1.26a3.39,3.39,0,0,0-1.93-1.54C546.08,60.34,546,60.9,546.45,61.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543,61.22A3.36,3.36,0,0,0,545,62.76c.59.08.7-.48.23-1.26A3.33,3.33,0,0,0,543.25,60C542.66,59.89,542.55,60.45,543,61.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.58,60.78a3.29,3.29,0,0,0,1.92,1.53c.6.08.71-.48.24-1.26a3.31,3.31,0,0,0-1.91-1.53C539.23,59.44,539.12,60,539.58,60.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.14,60.34a3.23,3.23,0,0,0,1.9,1.53c.6.08.72-.49.26-1.26a3.28,3.28,0,0,0-1.91-1.53C535.8,59,535.68,59.57,536.14,60.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M532.68,59.91a3.23,3.23,0,0,0,1.9,1.53c.6.07.72-.49.27-1.26a3.23,3.23,0,0,0-1.9-1.53C532.36,58.58,532.23,59.14,532.68,59.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529.22,59.49A3.17,3.17,0,0,0,531.11,61c.6.07.73-.49.28-1.26a3.13,3.13,0,0,0-1.89-1.52C528.91,58.16,528.78,58.72,529.22,59.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525.75,59.07a3.09,3.09,0,0,0,1.88,1.52c.61.07.74-.49.3-1.26a3.1,3.1,0,0,0-1.88-1.51C525.45,57.74,525.32,58.31,525.75,59.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M522.27,58.67a3.09,3.09,0,0,0,1.87,1.51c.61.07.75-.49.32-1.26a3.05,3.05,0,0,0-1.87-1.51C522,57.34,521.84,57.9,522.27,58.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M518.79,58.27a3,3,0,0,0,1.86,1.51c.61.07.75-.5.33-1.26A3,3,0,0,0,519.12,57C518.51,56.94,518.37,57.5,518.79,58.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M515.3,57.87a3,3,0,0,0,1.85,1.51c.61.07.76-.5.34-1.26a3,3,0,0,0-1.85-1.5C515,56.55,514.88,57.11,515.3,57.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M511.8,57.49a2.89,2.89,0,0,0,1.84,1.5c.61.07.77-.5.36-1.26a3,3,0,0,0-1.84-1.5C511.55,56.17,511.39,56.73,511.8,57.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M508.29,57.11a2.84,2.84,0,0,0,1.83,1.5c.61.07.78-.5.37-1.26a2.86,2.86,0,0,0-1.83-1.49C508.06,55.79,507.89,56.35,508.29,57.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M504.78,56.75a2.82,2.82,0,0,0,1.82,1.49c.61.06.79-.5.39-1.26a2.86,2.86,0,0,0-1.82-1.49C504.56,55.42,504.39,56,504.78,56.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M501.26,56.39a2.81,2.81,0,0,0,1.81,1.48c.61.07.79-.5.4-1.26a2.78,2.78,0,0,0-1.81-1.48C501.06,55.06,500.88,55.63,501.26,56.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M497.73,56a2.76,2.76,0,0,0,1.8,1.49c.62.06.8-.51.42-1.27a2.78,2.78,0,0,0-1.8-1.48C497.54,54.71,497.36,55.28,497.73,56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M494.2,55.69A2.69,2.69,0,0,0,496,57.17c.61.06.81-.51.43-1.26a2.7,2.7,0,0,0-1.78-1.48C494,54.37,493.83,54.93,494.2,55.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M490.66,55.35a2.67,2.67,0,0,0,1.78,1.48c.62.06.82-.51.45-1.27a2.71,2.71,0,0,0-1.78-1.47C490.5,54,490.3,54.6,490.66,55.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.12,55a2.63,2.63,0,0,0,1.76,1.47c.62,0,.83-.51.47-1.27a2.65,2.65,0,0,0-1.77-1.47C487,53.71,486.76,54.27,487.12,55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483.57,54.71a2.56,2.56,0,0,0,1.75,1.46c.62.06.83-.51.48-1.26a2.59,2.59,0,0,0-1.75-1.47C483.44,53.39,483.22,54,483.57,54.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M480,54.4a2.55,2.55,0,0,0,1.74,1.46c.62,0,.84-.52.5-1.27a2.56,2.56,0,0,0-1.74-1.46C479.9,53.08,479.67,53.64,480,54.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.45,54.09a2.47,2.47,0,0,0,1.73,1.46c.62.05.85-.52.51-1.27A2.49,2.49,0,0,0,477,52.83C476.35,52.77,476.12,53.34,476.45,54.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.88,53.8a2.45,2.45,0,0,0,1.71,1.45c.63,0,.86-.52.53-1.27a2.45,2.45,0,0,0-1.71-1.45C472.8,52.48,472.56,53.05,472.88,53.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469.31,53.51A2.38,2.38,0,0,0,471,55c.62,0,.86-.52.54-1.27a2.41,2.41,0,0,0-1.7-1.45C469.24,52.19,469,52.76,469.31,53.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.73,53.23a2.36,2.36,0,0,0,1.69,1.45c.62,0,.87-.52.56-1.27A2.4,2.4,0,0,0,466.29,52C465.67,51.92,465.42,52.48,465.73,53.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M462.14,53a2.33,2.33,0,0,0,1.68,1.45c.62,0,.88-.53.58-1.28a2.35,2.35,0,0,0-1.68-1.44C462.11,51.65,461.85,52.22,462.14,53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.56,52.7a2.25,2.25,0,0,0,1.66,1.44c.62,0,.89-.53.59-1.27a2.31,2.31,0,0,0-1.66-1.44C458.53,51.39,458.26,52,458.56,52.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455,52.45a2.21,2.21,0,0,0,1.65,1.43c.62,0,.9-.52.61-1.27a2.24,2.24,0,0,0-1.65-1.43C455,51.14,454.68,51.7,455,52.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M451.36,52.21A2.24,2.24,0,0,0,453,53.64c.63,0,.91-.53.63-1.28A2.22,2.22,0,0,0,452,50.93C451.37,50.89,451.09,51.46,451.36,52.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M447.76,52a2.14,2.14,0,0,0,1.62,1.43c.62,0,.91-.54.64-1.28a2.14,2.14,0,0,0-1.62-1.42C447.78,50.66,447.49,51.23,447.76,52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M444.15,51.75a2.12,2.12,0,0,0,1.6,1.42c.63,0,.93-.54.67-1.28a2.13,2.13,0,0,0-1.61-1.42C444.19,50.43,443.89,51,444.15,51.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M440.54,51.53A2.08,2.08,0,0,0,442.13,53c.63,0,.93-.54.68-1.29a2.11,2.11,0,0,0-1.59-1.41C440.59,50.21,440.29,50.78,440.54,51.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M436.92,51.32a2,2,0,0,0,1.57,1.41c.64,0,.95-.54.7-1.28A2.05,2.05,0,0,0,437.62,50C437,50,436.68,50.58,436.92,51.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.3,51.12a2,2,0,0,0,1.56,1.41c.63,0,.95-.54.71-1.29a2,2,0,0,0-1.56-1.4C433.39,49.81,433.07,50.38,433.3,51.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.67,50.93a2,2,0,0,0,1.55,1.4c.63,0,1-.54.73-1.28a2,2,0,0,0-1.55-1.4C429.78,49.61,429.45,50.19,429.67,50.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M426,50.75a1.92,1.92,0,0,0,1.53,1.4c.64,0,1-.55.75-1.29a1.93,1.93,0,0,0-1.53-1.4C426.17,49.43,425.83,50,426,50.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422.41,50.57a1.88,1.88,0,0,0,1.52,1.4c.63,0,1-.55.76-1.29a1.9,1.9,0,0,0-1.51-1.39C422.55,49.26,422.21,49.83,422.41,50.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M418.77,50.41a1.86,1.86,0,0,0,1.5,1.39c.64,0,1-.55.79-1.29a1.86,1.86,0,0,0-1.5-1.39C418.93,49.09,418.58,49.67,418.77,50.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.13,50.25a1.81,1.81,0,0,0,1.49,1.39c.63,0,1-.55.8-1.29A1.84,1.84,0,0,0,415.94,49C415.31,48.94,415,49.51,415.13,50.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.49,50.11A1.77,1.77,0,0,0,413,51.49c.63,0,1-.55.82-1.29a1.78,1.78,0,0,0-1.47-1.38C411.68,48.79,411.31,49.37,411.49,50.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.84,50a1.74,1.74,0,0,0,1.46,1.38c.63,0,1-.56.83-1.29a1.74,1.74,0,0,0-1.45-1.38C408.05,48.65,407.68,49.23,407.84,50Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.19,49.84a1.71,1.71,0,0,0,1.44,1.38c.64,0,1-.56.86-1.3a1.72,1.72,0,0,0-1.44-1.37C404.42,48.53,404,49.1,404.19,49.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.54,49.72A1.68,1.68,0,0,0,402,51.1c.64,0,1-.57.87-1.3a1.68,1.68,0,0,0-1.42-1.37C400.78,48.41,400.39,49,400.54,49.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.88,49.61A1.64,1.64,0,0,0,398.29,51c.64,0,1-.56.89-1.3a1.67,1.67,0,0,0-1.41-1.37C397.14,48.3,396.75,48.88,396.88,49.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.23,49.51a1.61,1.61,0,0,0,1.39,1.37c.63,0,1-.57.9-1.31a1.63,1.63,0,0,0-1.39-1.36C393.5,48.19,393.1,48.77,393.23,49.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.56,49.42a1.59,1.59,0,0,0,1.38,1.36c.64,0,1.05-.57.92-1.3a1.58,1.58,0,0,0-1.37-1.36C389.86,48.1,389.44,48.68,389.56,49.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.9,49.34a1.57,1.57,0,0,0,1.36,1.36,1,1,0,0,0,.94-1.31A1.57,1.57,0,0,0,386.85,48,1,1,0,0,0,385.9,49.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.24,49.26a1.52,1.52,0,0,0,1.34,1.36,1,1,0,0,0,1-1.31A1.53,1.53,0,0,0,383.2,48,1,1,0,0,0,382.24,49.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.57,49.2a1.48,1.48,0,0,0,1.32,1.35,1.05,1.05,0,0,0,1-1.31,1.5,1.5,0,0,0-1.32-1.35A1.06,1.06,0,0,0,378.57,49.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M374.9,49.14a1.47,1.47,0,0,0,1.31,1.36,1.08,1.08,0,0,0,1-1.32,1.47,1.47,0,0,0-1.3-1.35A1.08,1.08,0,0,0,374.9,49.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M371.23,49.1a1.45,1.45,0,0,0,1.29,1.35,1.09,1.09,0,0,0,1-1.32,1.45,1.45,0,0,0-1.29-1.35A1.1,1.1,0,0,0,371.23,49.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.55,49.06a1.43,1.43,0,0,0,1.28,1.35,1.12,1.12,0,0,0,1-1.33,1.43,1.43,0,0,0-1.27-1.34A1.13,1.13,0,0,0,367.55,49.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M363.88,49a1.39,1.39,0,0,0,1.26,1.35,1.14,1.14,0,0,0,1.05-1.33,1.41,1.41,0,0,0-1.26-1.34A1.14,1.14,0,0,0,363.88,49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360.2,49a1.36,1.36,0,0,0,1.25,1.33A1.14,1.14,0,0,0,362.51,49a1.37,1.37,0,0,0-1.24-1.34A1.17,1.17,0,0,0,360.2,49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.53,49a1.33,1.33,0,0,0,1.22,1.33A1.17,1.17,0,0,0,358.84,49a1.35,1.35,0,0,0-1.23-1.33A1.17,1.17,0,0,0,356.53,49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M352.85,49a1.3,1.3,0,0,0,1.21,1.33,1.19,1.19,0,0,0,1.1-1.33A1.31,1.31,0,0,0,354,47.68,1.19,1.19,0,0,0,352.85,49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M319.72,49.43a1.11,1.11,0,0,0,1,1.31A1.42,1.42,0,0,0,322,49.38a1.12,1.12,0,0,0-1-1.31A1.42,1.42,0,0,0,319.72,49.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M316,49.52a1.09,1.09,0,0,0,1,1.31,1.46,1.46,0,0,0,1.28-1.37,1.1,1.1,0,0,0-1-1.3A1.46,1.46,0,0,0,316,49.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M250,52.78c-.24.74.09,1.31.72,1.27a2.05,2.05,0,0,0,1.57-1.44c.23-.74-.1-1.31-.73-1.26A2,2,0,0,0,250,52.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M246.35,53c-.24.75.07,1.32.71,1.27a2.08,2.08,0,0,0,1.58-1.43c.24-.75-.08-1.32-.71-1.27A2.11,2.11,0,0,0,246.35,53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.49,56.56c-.34.76-.1,1.32.53,1.25a2.52,2.52,0,0,0,1.73-1.48c.33-.75.1-1.31-.53-1.25A2.54,2.54,0,0,0,206.49,56.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M188.56,58.5c-.37.76-.17,1.32.45,1.25a2.75,2.75,0,0,0,1.79-1.51c.37-.76.17-1.31-.44-1.24A2.77,2.77,0,0,0,188.56,58.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185,58.91c-.38.76-.19,1.32.43,1.25a2.78,2.78,0,0,0,1.81-1.51c.38-.76.18-1.32-.43-1.25A2.85,2.85,0,0,0,185,58.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.43,59.33c-.39.76-.2,1.32.42,1.25a2.86,2.86,0,0,0,1.82-1.51c.38-.77.19-1.32-.42-1.25A2.86,2.86,0,0,0,181.43,59.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M177.87,59.76c-.39.76-.21,1.32.4,1.25a2.92,2.92,0,0,0,1.83-1.52c.4-.76.21-1.32-.4-1.25A3,3,0,0,0,177.87,59.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.32,60.2c-.4.76-.23,1.32.39,1.25a3,3,0,0,0,1.84-1.53c.4-.76.22-1.32-.39-1.24A2.93,2.93,0,0,0,174.32,60.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M170.78,60.64c-.41.77-.25,1.33.37,1.25A3,3,0,0,0,173,60.36c.41-.76.24-1.32-.37-1.24A3,3,0,0,0,170.78,60.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.24,61.09c-.42.77-.26,1.33.36,1.25a3,3,0,0,0,1.86-1.53c.41-.77.25-1.32-.36-1.24A3,3,0,0,0,167.24,61.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M163.71,61.56c-.43.76-.27,1.32.34,1.24a3.06,3.06,0,0,0,1.87-1.53c.42-.77.27-1.33-.34-1.25A3.09,3.09,0,0,0,163.71,61.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M160.18,62c-.43.77-.28,1.33.34,1.25a3.14,3.14,0,0,0,1.88-1.54c.42-.77.27-1.33-.34-1.24A3.18,3.18,0,0,0,160.18,62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M156.67,62.5c-.44.77-.3,1.33.31,1.24a3.17,3.17,0,0,0,1.9-1.54c.43-.77.28-1.32-.32-1.24A3.15,3.15,0,0,0,156.67,62.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M153.16,63c-.45.77-.31,1.33.3,1.25a3.25,3.25,0,0,0,1.9-1.55c.44-.77.3-1.33-.3-1.24A3.2,3.2,0,0,0,153.16,63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M149.66,63.47c-.45.78-.32,1.33.29,1.25a3.25,3.25,0,0,0,1.9-1.55c.45-.78.32-1.33-.29-1.25A3.28,3.28,0,0,0,149.66,63.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M146.16,64c-.45.78-.33,1.33.28,1.25a3.32,3.32,0,0,0,1.91-1.56c.46-.77.33-1.33-.27-1.24A3.35,3.35,0,0,0,146.16,64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M142.67,64.48c-.46.77-.34,1.33.27,1.24a3.37,3.37,0,0,0,1.92-1.56c.46-.77.34-1.33-.26-1.24A3.41,3.41,0,0,0,142.67,64.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M139.2,65c-.47.78-.36,1.34.25,1.25a3.44,3.44,0,0,0,1.93-1.57c.46-.77.35-1.33-.25-1.24A3.41,3.41,0,0,0,139.2,65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M135.72,65.51c-.47.78-.36,1.34.24,1.25a3.46,3.46,0,0,0,1.94-1.57c.47-.78.36-1.33-.23-1.24A3.51,3.51,0,0,0,135.72,65.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M132.26,66c-.48.78-.38,1.34.23,1.24a3.49,3.49,0,0,0,1.95-1.57c.47-.78.37-1.33-.23-1.24A3.53,3.53,0,0,0,132.26,66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M128.81,66.58c-.49.78-.39,1.33.21,1.24a3.57,3.57,0,0,0,2-1.58c.48-.78.38-1.33-.22-1.24A3.55,3.55,0,0,0,128.81,66.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M118.5,68.22c-.5.79-.42,1.34.17,1.24a3.61,3.61,0,0,0,2-1.59c.49-.78.41-1.33-.18-1.23A3.65,3.65,0,0,0,118.5,68.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M115.08,68.79c-.51.78-.43,1.34.16,1.24a3.72,3.72,0,0,0,2-1.6c.5-.78.43-1.33-.16-1.23A3.69,3.69,0,0,0,115.08,68.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M111.67,69.36c-.51.78-.44,1.34.15,1.24a3.82,3.82,0,0,0,2-1.6c.5-.78.43-1.34-.16-1.24A3.75,3.75,0,0,0,111.67,69.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M619.63,69.41a4.25,4.25,0,0,0,2,1.62c.55.11.54-.45,0-1.25a4.24,4.24,0,0,0-2-1.62C619.06,68.05,619.07,68.61,619.63,69.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M616.43,68.81a4.28,4.28,0,0,0,2,1.63c.55.1.55-.46,0-1.25a4.21,4.21,0,0,0-2-1.63C615.86,67.46,615.87,68,616.43,68.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.23,68.23a4.25,4.25,0,0,0,2,1.62c.56.1.55-.46,0-1.26a4.3,4.3,0,0,0-2-1.62C612.66,66.87,612.67,67.43,613.23,68.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M610,67.64a4.13,4.13,0,0,0,2,1.62c.56.1.56-.46,0-1.25a4.19,4.19,0,0,0-2-1.62C609.45,66.29,609.45,66.85,610,67.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M606.78,67.07a4.16,4.16,0,0,0,2,1.61c.57.1.57-.46,0-1.25a4.16,4.16,0,0,0-2-1.61C606.23,65.72,606.23,66.28,606.78,67.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603.55,66.5a4.08,4.08,0,0,0,2,1.61c.56.09.57-.47,0-1.26a4,4,0,0,0-2-1.6C603,65.15,603,65.71,603.55,66.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M600.3,65.93a4.08,4.08,0,0,0,2,1.61c.56.1.57-.46,0-1.25a4.1,4.1,0,0,0-2-1.61C599.76,64.58,599.75,65.14,600.3,65.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M597.05,65.37a3.94,3.94,0,0,0,2,1.6c.57.1.58-.46,0-1.25a4.13,4.13,0,0,0-2-1.6C596.52,64,596.5,64.59,597.05,65.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M593.78,64.82a4,4,0,0,0,2,1.6c.57.09.59-.47,0-1.25a4,4,0,0,0-2-1.6C593.26,63.48,593.24,64,593.78,64.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M590.51,64.28a4,4,0,0,0,2,1.59c.57.09.6-.47.06-1.25a4,4,0,0,0-2-1.59C590,62.93,590,63.49,590.51,64.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.23,63.74a3.88,3.88,0,0,0,2,1.58c.57.1.6-.46.06-1.25a3.92,3.92,0,0,0-2-1.58C586.72,62.39,586.69,63,587.23,63.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M583.93,63.2a4,4,0,0,0,2,1.59c.58.09.61-.47.07-1.25A3.84,3.84,0,0,0,584,62C583.44,61.86,583.4,62.42,583.93,63.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M580.63,62.67a3.87,3.87,0,0,0,2,1.59c.57.09.61-.47.08-1.25a3.81,3.81,0,0,0-2-1.58C580.14,61.33,580.11,61.89,580.63,62.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M577.33,62.15a3.81,3.81,0,0,0,2,1.58c.58.09.62-.47.1-1.25a3.86,3.86,0,0,0-2-1.58C576.84,60.81,576.8,61.37,577.33,62.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574,61.64a3.75,3.75,0,0,0,2,1.57c.58.09.62-.47.1-1.25a3.78,3.78,0,0,0-2-1.57C573.54,60.3,573.49,60.86,574,61.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M570.68,61.13a3.75,3.75,0,0,0,2,1.57c.58.09.63-.47.11-1.25a3.73,3.73,0,0,0-2-1.57C570.22,59.8,570.17,60.35,570.68,61.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M567.35,60.63a3.69,3.69,0,0,0,2,1.57c.58.08.64-.48.12-1.25a3.69,3.69,0,0,0-2-1.57C566.9,59.3,566.84,59.85,567.35,60.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564,60.14a3.66,3.66,0,0,0,2,1.56c.58.08.64-.48.14-1.25a3.62,3.62,0,0,0-2-1.56C563.56,58.8,563.5,59.36,564,60.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M560.66,59.65a3.61,3.61,0,0,0,2,1.56c.59.08.65-.48.15-1.25a3.62,3.62,0,0,0-2-1.56C560.22,58.32,560.16,58.88,560.66,59.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M557.3,59.17a3.54,3.54,0,0,0,2,1.55c.58.08.65-.48.16-1.25a3.58,3.58,0,0,0-1.95-1.55C556.87,57.84,556.8,58.4,557.3,59.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M553.93,58.7a3.49,3.49,0,0,0,2,1.54c.58.09.66-.48.17-1.25a3.55,3.55,0,0,0-1.95-1.54C553.52,57.37,553.44,57.92,553.93,58.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M550.56,58.23a3.45,3.45,0,0,0,1.93,1.54c.59.08.67-.48.19-1.25A3.5,3.5,0,0,0,550.74,57C550.16,56.9,550.07,57.46,550.56,58.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.17,57.77a3.42,3.42,0,0,0,1.93,1.54c.59.08.68-.48.2-1.25a3.45,3.45,0,0,0-1.93-1.54C546.78,56.44,546.7,57,547.17,57.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.78,57.32a3.4,3.4,0,0,0,1.93,1.53c.59.08.68-.48.2-1.25A3.35,3.35,0,0,0,544,56.07C543.41,56,543.31,56.55,543.78,57.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540.39,56.87a3.31,3.31,0,0,0,1.91,1.53c.59.08.69-.48.22-1.25a3.3,3.3,0,0,0-1.91-1.52C540,55.55,539.92,56.11,540.39,56.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537,56.44A3.33,3.33,0,0,0,538.89,58c.59.08.7-.48.23-1.25a3.26,3.26,0,0,0-1.9-1.52C536.63,55.11,536.52,55.67,537,56.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.57,56a3.17,3.17,0,0,0,1.9,1.52c.59.08.7-.48.24-1.25a3.2,3.2,0,0,0-1.89-1.51C533.23,54.68,533.12,55.24,533.57,56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M530.15,55.58A3.23,3.23,0,0,0,532,57.1c.6.07.71-.49.26-1.25a3.22,3.22,0,0,0-1.89-1.52C529.82,54.26,529.7,54.82,530.15,55.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M526.73,55.17a3.18,3.18,0,0,0,1.87,1.51c.6.07.72-.49.28-1.25A3.15,3.15,0,0,0,527,53.92C526.41,53.85,526.28,54.4,526.73,55.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M523.29,54.76a3.1,3.1,0,0,0,1.87,1.5c.6.08.73-.49.29-1.25a3.12,3.12,0,0,0-1.87-1.5C523,53.44,522.86,54,523.29,54.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M519.85,54.36a3.05,3.05,0,0,0,1.86,1.5c.6.07.74-.49.3-1.25a3.06,3.06,0,0,0-1.85-1.5C519.56,53,519.43,53.6,519.85,54.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M516.41,54a3,3,0,0,0,1.85,1.5c.6.07.74-.49.31-1.25a3,3,0,0,0-1.85-1.49C516.13,52.65,516,53.21,516.41,54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M513,53.58a3,3,0,0,0,1.84,1.49c.6.07.75-.49.33-1.25a3,3,0,0,0-1.83-1.49C512.69,52.26,512.54,52.82,513,53.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M509.49,53.2a2.93,2.93,0,0,0,1.83,1.49c.61.07.76-.5.35-1.25A2.93,2.93,0,0,0,509.84,52C509.24,51.89,509.09,52.45,509.49,53.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M506,52.83a2.87,2.87,0,0,0,1.82,1.49c.6.06.76-.5.36-1.26a2.93,2.93,0,0,0-1.82-1.48C505.79,51.52,505.63,52.08,506,52.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M502.56,52.47a2.82,2.82,0,0,0,1.8,1.48c.61.06.78-.5.38-1.25a2.83,2.83,0,0,0-1.81-1.48C502.33,51.16,502.16,51.72,502.56,52.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499.08,52.12a2.77,2.77,0,0,0,1.79,1.47c.61.06.78-.5.39-1.25a2.76,2.76,0,0,0-1.79-1.47C498.87,50.81,498.69,51.36,499.08,52.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.6,51.77a2.71,2.71,0,0,0,1.78,1.47c.61.06.79-.5.4-1.25A2.72,2.72,0,0,0,496,50.52C495.4,50.46,495.22,51,495.6,51.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M492.11,51.43a2.69,2.69,0,0,0,1.77,1.47c.61.06.79-.51.42-1.26a2.72,2.72,0,0,0-1.77-1.46C491.92,50.12,491.74,50.68,492.11,51.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M488.61,51.1a2.64,2.64,0,0,0,1.76,1.46c.61.06.8-.5.44-1.25a2.68,2.68,0,0,0-1.76-1.46C488.44,49.79,488.25,50.35,488.61,51.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.11,50.78a2.6,2.6,0,0,0,1.75,1.46c.61,0,.81-.51.45-1.26a2.58,2.58,0,0,0-1.75-1.45C485,49.47,484.75,50,485.11,50.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.6,50.47a2.6,2.6,0,0,0,1.74,1.45c.61,0,.82-.51.46-1.26a2.56,2.56,0,0,0-1.73-1.45C481.46,49.16,481.25,49.72,481.6,50.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.09,50.16a2.55,2.55,0,0,0,1.72,1.45c.62,0,.83-.51.49-1.26a2.52,2.52,0,0,0-1.73-1.44C478,48.85,477.75,49.41,478.09,50.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.57,49.86a2.49,2.49,0,0,0,1.71,1.45c.62,0,.84-.52.5-1.26a2.47,2.47,0,0,0-1.71-1.44C474.46,48.56,474.24,49.12,474.57,49.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M471.05,49.57a2.4,2.4,0,0,0,1.7,1.44c.61.05.84-.51.51-1.26a2.45,2.45,0,0,0-1.69-1.43C471,48.27,470.73,48.83,471.05,49.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M467.52,49.29a2.41,2.41,0,0,0,1.69,1.44c.61,0,.85-.52.53-1.26A2.36,2.36,0,0,0,468.05,48C467.45,48,467.21,48.55,467.52,49.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M464,49a2.36,2.36,0,0,0,1.67,1.43c.62,0,.86-.52.55-1.26a2.36,2.36,0,0,0-1.67-1.43C463.93,47.72,463.68,48.28,464,49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M460.45,48.75a2.31,2.31,0,0,0,1.66,1.43c.62,0,.87-.52.56-1.26A2.28,2.28,0,0,0,461,47.5C460.41,47.45,460.15,48,460.45,48.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M456.91,48.5a2.25,2.25,0,0,0,1.64,1.42c.62,0,.88-.52.59-1.26a2.28,2.28,0,0,0-1.65-1.42C456.88,47.2,456.62,47.76,456.91,48.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M453.36,48.25A2.24,2.24,0,0,0,455,49.67c.62,0,.89-.52.6-1.26A2.24,2.24,0,0,0,454,47C453.35,47,453.08,47.51,453.36,48.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M449.81,48a2.21,2.21,0,0,0,1.62,1.42c.62,0,.89-.53.61-1.27a2.18,2.18,0,0,0-1.61-1.41C449.81,46.71,449.54,47.27,449.81,48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M446.26,47.78a2.12,2.12,0,0,0,1.6,1.41c.62,0,.9-.53.63-1.26a2.15,2.15,0,0,0-1.6-1.41C446.28,46.48,446,47,446.26,47.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M442.7,47.56a2,2,0,0,0,1.59,1.4c.62,0,.91-.52.64-1.26a2.1,2.1,0,0,0-1.58-1.4C442.73,46.26,442.44,46.82,442.7,47.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M439.13,47.35a2.09,2.09,0,0,0,1.58,1.4c.62,0,.92-.53.66-1.27a2.07,2.07,0,0,0-1.57-1.4C439.18,46.05,438.89,46.61,439.13,47.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435.57,47.14a2,2,0,0,0,1.55,1.4c.63,0,.93-.54.69-1.27a2,2,0,0,0-1.56-1.39C435.63,45.84,435.33,46.41,435.57,47.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M432,47a2,2,0,0,0,1.55,1.39c.62,0,.93-.54.7-1.27a2,2,0,0,0-1.55-1.39C432.08,45.65,431.76,46.21,432,47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.42,46.76A2,2,0,0,0,430,48.15c.62,0,.94-.54.72-1.27a2,2,0,0,0-1.53-1.39C428.52,45.46,428.2,46,428.42,46.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M424.84,46.58A1.93,1.93,0,0,0,426.35,48c.63,0,1-.55.74-1.28a1.93,1.93,0,0,0-1.51-1.38C425,45.28,424.63,45.85,424.84,46.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M421.26,46.41a1.86,1.86,0,0,0,1.5,1.38c.62,0,1-.54.75-1.27a1.87,1.87,0,0,0-1.5-1.38C421.39,45.11,421.06,45.68,421.26,46.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.67,46.25a1.85,1.85,0,0,0,1.49,1.38c.62,0,1-.55.77-1.28A1.85,1.85,0,0,0,418.44,45C417.82,45,417.48,45.52,417.67,46.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.08,46.1a1.82,1.82,0,0,0,1.47,1.37c.63,0,1-.55.79-1.28a1.82,1.82,0,0,0-1.47-1.37C414.25,44.8,413.9,45.37,414.08,46.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M410.49,46a1.77,1.77,0,0,0,1.45,1.37c.63,0,1-.55.81-1.28a1.76,1.76,0,0,0-1.45-1.36C410.68,44.65,410.32,45.22,410.49,46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.9,45.82a1.74,1.74,0,0,0,1.43,1.37c.63,0,1-.56.83-1.29a1.76,1.76,0,0,0-1.44-1.36C407.1,44.52,406.73,45.09,406.9,45.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.3,45.69a1.71,1.71,0,0,0,1.42,1.37c.63,0,1-.56.84-1.29a1.71,1.71,0,0,0-1.42-1.36C403.52,44.39,403.14,45,403.3,45.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.7,45.58a1.67,1.67,0,0,0,1.4,1.36c.63,0,1-.56.86-1.29a1.66,1.66,0,0,0-1.4-1.35C399.93,44.28,399.55,44.85,399.7,45.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M396.1,45.47a1.61,1.61,0,0,0,1.39,1.35c.62,0,1-.55.87-1.28A1.64,1.64,0,0,0,397,44.19C396.35,44.17,396,44.74,396.1,45.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M392.49,45.37a1.59,1.59,0,0,0,1.37,1.35c.63,0,1-.56.9-1.29a1.6,1.6,0,0,0-1.38-1.34C392.76,44.07,392.36,44.64,392.49,45.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.88,45.28a1.58,1.58,0,0,0,1.36,1.35,1,1,0,0,0,.91-1.29A1.6,1.6,0,0,0,389.79,44C389.17,44,388.76,44.55,388.88,45.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M385.27,45.2a1.53,1.53,0,0,0,1.34,1.34c.63,0,1.05-.56.93-1.29a1.55,1.55,0,0,0-1.34-1.34A1,1,0,0,0,385.27,45.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.66,45.13A1.5,1.5,0,0,0,383,46.47a1,1,0,0,0,.95-1.3,1.52,1.52,0,0,0-1.32-1.33C382,43.82,381.56,44.4,381.66,45.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M378.05,45.07a1.47,1.47,0,0,0,1.3,1.33,1,1,0,0,0,1-1.3A1.5,1.5,0,0,0,379,43.77,1,1,0,0,0,378.05,45.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M374.43,45a1.45,1.45,0,0,0,1.29,1.34,1.07,1.07,0,0,0,1-1.31,1.47,1.47,0,0,0-1.29-1.33A1.07,1.07,0,0,0,374.43,45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.81,45a1.44,1.44,0,0,0,1.28,1.33,1.09,1.09,0,0,0,1-1.31,1.43,1.43,0,0,0-1.28-1.32A1.08,1.08,0,0,0,370.81,45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M367.19,44.93a1.41,1.41,0,0,0,1.26,1.33,1.1,1.1,0,0,0,1-1.31,1.41,1.41,0,0,0-1.26-1.32A1.11,1.11,0,0,0,367.19,44.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M363.57,44.9a1.38,1.38,0,0,0,1.24,1.33,1.12,1.12,0,0,0,1-1.31,1.37,1.37,0,0,0-1.24-1.32A1.12,1.12,0,0,0,363.57,44.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M360,44.89a1.35,1.35,0,0,0,1.22,1.32,1.14,1.14,0,0,0,1.06-1.31A1.35,1.35,0,0,0,361,43.58,1.15,1.15,0,0,0,360,44.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.33,44.88a1.31,1.31,0,0,0,1.2,1.32,1.15,1.15,0,0,0,1.07-1.32,1.32,1.32,0,0,0-1.2-1.31A1.15,1.15,0,0,0,356.33,44.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M352.7,44.88a1.3,1.3,0,0,0,1.19,1.32A1.19,1.19,0,0,0,355,44.88a1.3,1.3,0,0,0-1.19-1.31A1.19,1.19,0,0,0,352.7,44.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349.08,44.89a1.27,1.27,0,0,0,1.17,1.31,1.2,1.2,0,0,0,1.11-1.32,1.29,1.29,0,0,0-1.18-1.31A1.21,1.21,0,0,0,349.08,44.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.45,44.91a1.26,1.26,0,0,0,1.16,1.31,1.23,1.23,0,0,0,1.12-1.32,1.25,1.25,0,0,0-1.16-1.31A1.23,1.23,0,0,0,345.45,44.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.83,44.94a1.21,1.21,0,0,0,1.13,1.3,1.33,1.33,0,0,0,0-2.63A1.26,1.26,0,0,0,341.83,44.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M323.69,45.21a1.12,1.12,0,0,0,1.05,1.3A1.39,1.39,0,0,0,326,45.17a1.12,1.12,0,0,0-1.06-1.29A1.37,1.37,0,0,0,323.69,45.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M254.93,48.33c-.23.74.1,1.3.72,1.26a2,2,0,0,0,1.54-1.41c.22-.74-.1-1.3-.72-1.26A2,2,0,0,0,254.93,48.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M251.33,48.59c-.24.73.08,1.3.71,1.25a2,2,0,0,0,1.55-1.41c.23-.74-.09-1.3-.71-1.26A2.06,2.06,0,0,0,251.33,48.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M247.73,48.85c-.24.74.07,1.3.69,1.25A2.05,2.05,0,0,0,250,48.68c.24-.74-.07-1.29-.69-1.25A2.1,2.1,0,0,0,247.73,48.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.07,51.26c-.31.75-.06,1.31.56,1.25a2.39,2.39,0,0,0,1.68-1.46c.31-.74.06-1.3-.56-1.24A2.41,2.41,0,0,0,219.07,51.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M215.51,51.6c-.32.75-.08,1.31.54,1.25a2.48,2.48,0,0,0,1.7-1.46c.31-.75.07-1.31-.55-1.25A2.46,2.46,0,0,0,215.51,51.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212,52c-.33.75-.1,1.31.52,1.24a2.47,2.47,0,0,0,1.71-1.46c.32-.75.09-1.3-.53-1.24A2.47,2.47,0,0,0,212,52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M208.39,52.31c-.34.75-.11,1.3.51,1.24a2.51,2.51,0,0,0,1.72-1.47c.33-.75.1-1.3-.51-1.24A2.51,2.51,0,0,0,208.39,52.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.84,52.67c-.35.75-.13,1.31.49,1.25a2.61,2.61,0,0,0,1.74-1.48c.33-.75.11-1.3-.5-1.24A2.57,2.57,0,0,0,204.84,52.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201.29,53.05c-.35.75-.14,1.3.48,1.24a2.64,2.64,0,0,0,1.75-1.48c.34-.75.13-1.3-.49-1.24A2.62,2.62,0,0,0,201.29,53.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.67,54.22c-.37.75-.17,1.31.44,1.24A2.75,2.75,0,0,0,192.89,54c.37-.76.18-1.31-.43-1.24A2.78,2.78,0,0,0,190.67,54.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187.15,54.62c-.38.76-.2,1.31.42,1.24a2.78,2.78,0,0,0,1.79-1.49c.38-.76.19-1.31-.42-1.24A2.81,2.81,0,0,0,187.15,54.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.63,55c-.39.75-.21,1.31.4,1.24a2.91,2.91,0,0,0,1.81-1.5c.38-.76.2-1.31-.41-1.24A2.81,2.81,0,0,0,183.63,55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M180.11,55.46c-.4.76-.22,1.31.39,1.24a2.88,2.88,0,0,0,1.82-1.51c.39-.75.21-1.31-.4-1.23A2.87,2.87,0,0,0,180.11,55.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176.6,55.89c-.41.76-.24,1.31.37,1.24a2.93,2.93,0,0,0,1.83-1.51c.4-.76.23-1.31-.38-1.24A3,3,0,0,0,176.6,55.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.09,56.33c-.41.76-.25,1.31.36,1.24a3,3,0,0,0,1.84-1.52c.41-.76.25-1.31-.36-1.23A3,3,0,0,0,173.09,56.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M169.59,56.78c-.41.76-.26,1.31.35,1.23a3,3,0,0,0,1.85-1.52c.41-.76.26-1.31-.35-1.23A3,3,0,0,0,169.59,56.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M166.1,57.23c-.42.76-.27,1.32.33,1.24a3.06,3.06,0,0,0,1.86-1.53c.42-.76.27-1.31-.33-1.23A3.1,3.1,0,0,0,166.1,57.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M162.61,57.69c-.43.77-.28,1.32.32,1.24a3.11,3.11,0,0,0,1.87-1.53c.43-.76.28-1.31-.32-1.23A3.14,3.14,0,0,0,162.61,57.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.13,58.16c-.43.77-.3,1.32.31,1.24a3.18,3.18,0,0,0,1.88-1.53c.43-.77.29-1.32-.31-1.24A3.21,3.21,0,0,0,159.13,58.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M155.66,58.64c-.44.77-.31,1.32.29,1.24a3.25,3.25,0,0,0,1.89-1.54c.44-.77.31-1.32-.29-1.24A3.25,3.25,0,0,0,155.66,58.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M152.19,59.12c-.45.77-.32,1.33.28,1.24a3.29,3.29,0,0,0,1.9-1.54c.44-.77.32-1.32-.28-1.24A3.33,3.33,0,0,0,152.19,59.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M148.73,59.62c-.45.77-.33,1.32.27,1.23a3.29,3.29,0,0,0,1.9-1.54c.45-.77.34-1.32-.26-1.24A3.36,3.36,0,0,0,148.73,59.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M145.28,60.12c-.46.77-.35,1.32.25,1.23a3.34,3.34,0,0,0,1.92-1.55c.45-.77.34-1.32-.26-1.23A3.35,3.35,0,0,0,145.28,60.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M141.83,60.62c-.46.77-.36,1.33.24,1.24A3.45,3.45,0,0,0,144,60.3c.46-.77.35-1.32-.25-1.23A3.44,3.44,0,0,0,141.83,60.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M138.39,61.14c-.47.77-.37,1.32.23,1.23a3.4,3.4,0,0,0,1.93-1.56c.47-.77.37-1.32-.23-1.23A3.51,3.51,0,0,0,138.39,61.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M135,61.66c-.48.77-.38,1.33.22,1.24a3.6,3.6,0,0,0,1.94-1.57c.47-.77.37-1.32-.22-1.23A3.53,3.53,0,0,0,135,61.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M124.71,63.27c-.49.78-.41,1.33.18,1.23a3.59,3.59,0,0,0,2-1.57c.49-.78.41-1.33-.18-1.24A3.68,3.68,0,0,0,124.71,63.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M121.31,63.82c-.49.78-.42,1.33.17,1.23a3.65,3.65,0,0,0,2-1.58c.49-.77.42-1.33-.17-1.23A3.65,3.65,0,0,0,121.31,63.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M613.25,64.33a4.28,4.28,0,0,0,2,1.62c.56.1.55-.46,0-1.25a4.19,4.19,0,0,0-2-1.62C612.68,63,612.69,63.54,613.25,64.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M603.65,62.6a4.1,4.1,0,0,0,2,1.61c.56.1.56-.46,0-1.25a4.1,4.1,0,0,0-2-1.61C603.09,61.26,603.09,61.82,603.65,62.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M597.2,61.48a4.07,4.07,0,0,0,2,1.6c.56.1.57-.46,0-1.25a4.13,4.13,0,0,0-2-1.6C596.66,60.14,596.65,60.7,597.2,61.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M594,60.93a4.07,4.07,0,0,0,2,1.6c.56.09.57-.47,0-1.25a4.07,4.07,0,0,0-2-1.6C593.43,59.59,593.42,60.15,594,60.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M590.72,60.39a4,4,0,0,0,2,1.59c.56.09.58-.47,0-1.25a4,4,0,0,0-2-1.59C590.19,59,590.18,59.6,590.72,60.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.46,59.85a4,4,0,0,0,2,1.58c.57.1.59-.46,0-1.25a4,4,0,0,0-2-1.58C587,58.51,586.93,59.06,587.46,59.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M584.2,59.31a4,4,0,0,0,2,1.59c.57.09.59-.47,0-1.25a3.9,3.9,0,0,0-2-1.58C583.69,58,583.67,58.53,584.2,59.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M580.93,58.79a3.88,3.88,0,0,0,2,1.57c.57.1.6-.46.07-1.24a3.9,3.9,0,0,0-2-1.58C580.43,57.45,580.4,58,580.93,58.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M577.66,58.27a3.78,3.78,0,0,0,2,1.57c.57.09.61-.47.08-1.25a3.84,3.84,0,0,0-2-1.57C577.16,56.93,577.13,57.49,577.66,58.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574.37,57.75a3.78,3.78,0,0,0,2,1.57c.57.09.61-.47.09-1.24a3.82,3.82,0,0,0-2-1.57C573.89,56.42,573.85,57,574.37,57.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M571.08,57.25a3.77,3.77,0,0,0,2,1.56c.57.09.61-.47.09-1.25a3.79,3.79,0,0,0-2-1.56C570.61,55.92,570.56,56.47,571.08,57.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M567.78,56.75a3.75,3.75,0,0,0,2,1.56c.57.08.62-.48.1-1.25a3.74,3.74,0,0,0-2-1.56C567.32,55.42,567.27,56,567.78,56.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.47,56.25a3.67,3.67,0,0,0,2,1.56c.57.08.62-.47.11-1.25a3.66,3.66,0,0,0-2-1.55C564,54.93,564,55.48,564.47,56.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M561.16,55.77a3.66,3.66,0,0,0,2,1.55c.58.08.64-.48.13-1.25a3.69,3.69,0,0,0-2-1.55C560.71,54.44,560.66,55,561.16,55.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M557.84,55.29a3.52,3.52,0,0,0,1.95,1.54c.57.09.64-.47.13-1.24A3.64,3.64,0,0,0,558,54C557.4,54,557.34,54.52,557.84,55.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M554.51,54.81a3.57,3.57,0,0,0,1.94,1.55c.58.08.65-.48.15-1.25a3.57,3.57,0,0,0-1.94-1.54C554.08,53.49,554,54.05,554.51,54.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551.17,54.35a3.49,3.49,0,0,0,1.94,1.53c.58.09.65-.47.16-1.24a3.48,3.48,0,0,0-1.94-1.53C550.76,53,550.68,53.58,551.17,54.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M547.83,53.89a3.46,3.46,0,0,0,1.92,1.53c.59.08.66-.48.18-1.24A3.46,3.46,0,0,0,548,52.65C547.42,52.57,547.35,53.12,547.83,53.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M544.48,53.44A3.33,3.33,0,0,0,546.4,55c.58.08.66-.47.18-1.24a3.39,3.39,0,0,0-1.92-1.52C544.08,52.12,544,52.67,544.48,53.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.12,53A3.3,3.3,0,0,0,543,54.51c.59.08.67-.48.2-1.24a3.42,3.42,0,0,0-1.91-1.52C540.74,51.68,540.65,52.23,541.12,53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M537.76,52.56a3.25,3.25,0,0,0,1.9,1.51c.58.08.68-.48.21-1.24a3.31,3.31,0,0,0-1.9-1.51C537.39,51.24,537.29,51.79,537.76,52.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M534.38,52.13a3.33,3.33,0,0,0,1.9,1.51c.59.07.69-.48.22-1.25a3.24,3.24,0,0,0-1.89-1.5C534,50.81,533.93,51.37,534.38,52.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531,51.7a3.21,3.21,0,0,0,1.88,1.51c.59.07.7-.48.24-1.24a3.24,3.24,0,0,0-1.88-1.51C530.66,50.39,530.56,50.94,531,51.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M527.62,51.29a3.2,3.2,0,0,0,1.88,1.5c.59.07.7-.49.25-1.24a3.18,3.18,0,0,0-1.87-1.5C527.29,50,527.18,50.53,527.62,51.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M524.23,50.88a3.16,3.16,0,0,0,1.87,1.5c.59.07.71-.49.26-1.25a3.15,3.15,0,0,0-1.86-1.49C523.91,49.57,523.79,50.12,524.23,50.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520.84,50.48A3.07,3.07,0,0,0,522.7,52c.59.07.71-.49.27-1.24a3.1,3.1,0,0,0-1.85-1.49C520.53,49.17,520.4,49.72,520.84,50.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M517.44,50.08a3,3,0,0,0,1.84,1.49c.6.07.73-.49.29-1.24a3.06,3.06,0,0,0-1.84-1.49C517.14,48.78,517,49.33,517.44,50.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M514,49.7a3,3,0,0,0,1.83,1.48c.6.07.74-.49.31-1.24a3,3,0,0,0-1.84-1.48C513.75,48.39,513.61,49,514,49.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510.61,49.32a3,3,0,0,0,1.83,1.48c.59.06.74-.49.32-1.24a3,3,0,0,0-1.83-1.48C510.34,48,510.2,48.57,510.61,49.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M507.19,49A2.88,2.88,0,0,0,509,50.42c.59.07.74-.49.33-1.24a2.89,2.89,0,0,0-1.81-1.47C506.94,47.65,506.79,48.2,507.19,49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M503.77,48.59a2.92,2.92,0,0,0,1.8,1.47c.6.06.75-.5.35-1.25a2.86,2.86,0,0,0-1.8-1.46C503.53,47.29,503.37,47.84,503.77,48.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M500.34,48.23a2.82,2.82,0,0,0,1.79,1.47c.6.06.76-.5.36-1.25A2.82,2.82,0,0,0,500.7,47C500.11,46.93,500,47.49,500.34,48.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M496.9,47.88a2.73,2.73,0,0,0,1.78,1.46c.6.06.77-.49.38-1.24a2.76,2.76,0,0,0-1.78-1.45C496.69,46.59,496.52,47.14,496.9,47.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M493.46,47.54A2.72,2.72,0,0,0,495.23,49c.6.06.77-.5.39-1.24a2.67,2.67,0,0,0-1.77-1.45C493.26,46.25,493.08,46.8,493.46,47.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M490,47.21a2.64,2.64,0,0,0,1.76,1.45c.6.06.78-.5.41-1.24A2.71,2.71,0,0,0,490.42,46C489.82,45.92,489.64,46.47,490,47.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M486.56,46.89a2.64,2.64,0,0,0,1.74,1.45c.61,0,.79-.51.43-1.25A2.66,2.66,0,0,0,487,45.65C486.39,45.59,486.2,46.15,486.56,46.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483.1,46.57A2.6,2.6,0,0,0,484.83,48c.61,0,.8-.51.44-1.25a2.61,2.61,0,0,0-1.73-1.44C482.94,45.28,482.75,45.83,483.1,46.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M479.64,46.27a2.52,2.52,0,0,0,1.72,1.43c.6.06.81-.5.45-1.24A2.53,2.53,0,0,0,480.09,45C479.5,45,479.29,45.53,479.64,46.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.17,46a2.5,2.5,0,0,0,1.71,1.43c.6.05.81-.51.47-1.25a2.53,2.53,0,0,0-1.71-1.42C476,44.67,475.83,45.23,476.17,46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.7,45.68a2.46,2.46,0,0,0,1.69,1.42c.61,0,.83-.51.49-1.24a2.48,2.48,0,0,0-1.7-1.43C472.58,44.38,472.37,44.94,472.7,45.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469.22,45.39a2.38,2.38,0,0,0,1.68,1.42c.61,0,.83-.51.5-1.24a2.4,2.4,0,0,0-1.68-1.42C469.12,44.1,468.9,44.66,469.22,45.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.74,45.12a2.33,2.33,0,0,0,1.67,1.41c.61,0,.84-.51.52-1.24a2.4,2.4,0,0,0-1.67-1.42C465.66,43.83,465.42,44.38,465.74,45.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M462.25,44.85a2.32,2.32,0,0,0,1.66,1.41c.61.05.85-.51.53-1.24a2.3,2.3,0,0,0-1.65-1.41C462.19,43.56,462,44.12,462.25,44.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.76,44.59A2.31,2.31,0,0,0,460.4,46c.61,0,.86-.51.55-1.25a2.26,2.26,0,0,0-1.64-1.4C458.71,43.3,458.46,43.86,458.76,44.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455.27,44.34a2.27,2.27,0,0,0,1.63,1.41c.61,0,.86-.52.56-1.25a2.21,2.21,0,0,0-1.62-1.4C455.23,43.05,455,43.61,455.27,44.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M451.77,44.1a2.19,2.19,0,0,0,1.61,1.4c.61,0,.87-.52.59-1.25a2.22,2.22,0,0,0-1.62-1.4C451.75,42.81,451.49,43.37,451.77,44.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M448.27,43.86a2.16,2.16,0,0,0,1.6,1.4c.61,0,.88-.52.6-1.25a2.18,2.18,0,0,0-1.6-1.39C448.26,42.58,448,43.13,448.27,43.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M444.76,43.64A2.1,2.1,0,0,0,446.34,45c.62,0,.89-.52.62-1.25a2.13,2.13,0,0,0-1.58-1.39C444.77,42.35,444.49,42.91,444.76,43.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M441.25,43.42a2.07,2.07,0,0,0,1.57,1.39c.61,0,.9-.53.63-1.25a2.09,2.09,0,0,0-1.57-1.39C441.28,42.14,441,42.69,441.25,43.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M437.73,43.21a2.06,2.06,0,0,0,1.56,1.39c.61,0,.9-.53.65-1.26A2.07,2.07,0,0,0,438.38,42C437.78,41.93,437.49,42.49,437.73,43.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.22,43a2,2,0,0,0,1.54,1.38c.61,0,.91-.53.67-1.25a2,2,0,0,0-1.55-1.38C434.28,41.73,434,42.29,434.22,43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.69,42.82a2,2,0,0,0,1.53,1.38c.62,0,.92-.53.69-1.26a2,2,0,0,0-1.53-1.37C430.77,41.54,430.46,42.1,430.69,42.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M427.17,42.64A1.93,1.93,0,0,0,428.68,44c.62,0,.93-.53.7-1.26a1.92,1.92,0,0,0-1.51-1.36C427.26,41.35,427,41.91,427.17,42.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.64,42.46a1.88,1.88,0,0,0,1.5,1.37c.61,0,.93-.53.72-1.26a1.91,1.91,0,0,0-1.5-1.36C423.75,41.18,423.43,41.74,423.64,42.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M420.11,42.3a1.86,1.86,0,0,0,1.48,1.36c.62,0,1-.54.74-1.26A1.87,1.87,0,0,0,420.85,41C420.24,41,419.91,41.57,420.11,42.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.57,42.14A1.84,1.84,0,0,0,418,43.5c.62,0,1-.54.75-1.26a1.82,1.82,0,0,0-1.46-1.36C416.72,40.86,416.38,41.42,416.57,42.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413,42a1.78,1.78,0,0,0,1.45,1.36c.62,0,1-.54.77-1.27a1.8,1.8,0,0,0-1.45-1.35C413.2,40.71,412.85,41.27,413,42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.5,41.85a1.74,1.74,0,0,0,1.43,1.35c.62,0,1-.54.79-1.26a1.76,1.76,0,0,0-1.43-1.35C409.68,40.57,409.32,41.13,409.5,41.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406,41.72a1.73,1.73,0,0,0,1.42,1.35c.62,0,1-.55.81-1.27a1.71,1.71,0,0,0-1.42-1.34C406.15,40.44,405.79,41,406,41.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M402.41,41.6a1.67,1.67,0,0,0,1.4,1.34c.62,0,1-.55.83-1.27a1.7,1.7,0,0,0-1.41-1.34C402.62,40.31,402.25,40.88,402.41,41.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.86,41.48a1.65,1.65,0,0,0,1.39,1.34c.62,0,1-.55.84-1.27a1.66,1.66,0,0,0-1.39-1.33C399.09,40.2,398.71,40.76,398.86,41.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M395.31,41.38a1.62,1.62,0,0,0,1.37,1.34c.62,0,1-.56.86-1.28a1.62,1.62,0,0,0-1.37-1.33C395.56,40.09,395.17,40.66,395.31,41.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391.76,41.28a1.59,1.59,0,0,0,1.35,1.34c.62,0,1-.56.88-1.28A1.6,1.6,0,0,0,392.63,40C392,40,391.63,40.56,391.76,41.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M388.2,41.19a1.54,1.54,0,0,0,1.34,1.33c.62,0,1-.55.9-1.27a1.58,1.58,0,0,0-1.34-1.33A1,1,0,0,0,388.2,41.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384.65,41.12A1.51,1.51,0,0,0,386,42.44c.62,0,1-.56.91-1.28a1.55,1.55,0,0,0-1.32-1.32C384.94,39.83,384.54,40.4,384.65,41.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M381.09,41.05a1.49,1.49,0,0,0,1.3,1.32,1,1,0,0,0,.93-1.28,1.5,1.5,0,0,0-1.3-1.32A1,1,0,0,0,381.09,41.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377.53,41a1.46,1.46,0,0,0,1.29,1.32,1,1,0,0,0,.94-1.28,1.46,1.46,0,0,0-1.29-1.31A1,1,0,0,0,377.53,41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M374,40.93a1.45,1.45,0,0,0,1.28,1.32,1,1,0,0,0,1-1.29,1.45,1.45,0,0,0-1.27-1.31A1,1,0,0,0,374,40.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.4,40.89a1.39,1.39,0,0,0,1.26,1.31,1.06,1.06,0,0,0,1-1.29,1.4,1.4,0,0,0-1.26-1.3A1.06,1.06,0,0,0,370.4,40.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.83,40.85a1.38,1.38,0,0,0,1.24,1.31,1.07,1.07,0,0,0,1-1.29,1.38,1.38,0,0,0-1.24-1.3A1.08,1.08,0,0,0,366.83,40.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M363.27,40.83a1.34,1.34,0,0,0,1.22,1.3,1.1,1.1,0,0,0,1-1.29,1.36,1.36,0,0,0-1.23-1.3A1.1,1.1,0,0,0,363.27,40.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.7,40.81a1.31,1.31,0,0,0,1.21,1.3,1.11,1.11,0,0,0,1-1.29,1.33,1.33,0,0,0-1.21-1.3A1.12,1.12,0,0,0,359.7,40.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M356.13,40.8a1.29,1.29,0,0,0,1.19,1.3,1.13,1.13,0,0,0,1.05-1.29,1.3,1.3,0,0,0-1.19-1.3A1.15,1.15,0,0,0,356.13,40.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M352.56,40.8a1.27,1.27,0,0,0,1.17,1.3,1.16,1.16,0,0,0,1.07-1.3,1.27,1.27,0,0,0-1.17-1.29A1.16,1.16,0,0,0,352.56,40.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M349,40.81a1.25,1.25,0,0,0,1.15,1.3,1.18,1.18,0,0,0,1.09-1.3,1.25,1.25,0,0,0-1.15-1.29A1.18,1.18,0,0,0,349,40.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.42,40.83a1.23,1.23,0,0,0,1.14,1.3,1.21,1.21,0,0,0,1.1-1.31,1.23,1.23,0,0,0-1.14-1.29A1.22,1.22,0,0,0,345.42,40.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M341.84,40.86A1.2,1.2,0,0,0,343,42.15a1.23,1.23,0,0,0,1.12-1.31A1.21,1.21,0,0,0,343,39.56,1.23,1.23,0,0,0,341.84,40.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M338.27,40.9a1.18,1.18,0,0,0,1.11,1.28,1.25,1.25,0,0,0,1.14-1.31,1.19,1.19,0,0,0-1.11-1.28A1.26,1.26,0,0,0,338.27,40.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M295.39,42c-.12.72.28,1.29.9,1.26a1.59,1.59,0,0,0,1.35-1.35c.12-.72-.29-1.28-.9-1.26A1.61,1.61,0,0,0,295.39,42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M291.82,42.18c-.13.72.26,1.29.88,1.26a1.61,1.61,0,0,0,1.37-1.35c.12-.72-.27-1.28-.89-1.26A1.64,1.64,0,0,0,291.82,42.18Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M259.76,44c-.21.73.11,1.29.73,1.25A2,2,0,0,0,262,43.81c.21-.73-.12-1.28-.73-1.24A2,2,0,0,0,259.76,44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M256.21,44.19c-.23.73.09,1.29.71,1.25a2,2,0,0,0,1.52-1.4c.22-.73-.1-1.28-.71-1.24A2,2,0,0,0,256.21,44.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M252.66,44.44c-.24.73.07,1.29.69,1.24a2,2,0,0,0,1.54-1.4c.23-.72-.08-1.28-.69-1.23A2,2,0,0,0,252.66,44.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M249.11,44.7c-.24.73.06,1.29.68,1.24a2.07,2.07,0,0,0,1.55-1.4c.24-.73-.06-1.29-.68-1.24A2.06,2.06,0,0,0,249.11,44.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M245.57,45c-.26.74,0,1.29.66,1.25a2.08,2.08,0,0,0,1.56-1.41c.25-.73,0-1.29-.66-1.24A2.09,2.09,0,0,0,245.57,45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M242,45.24c-.26.73,0,1.29.65,1.24a2.13,2.13,0,0,0,1.58-1.41c.25-.73,0-1.29-.64-1.24A2.16,2.16,0,0,0,242,45.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M220.83,47.07c-.31.74-.06,1.29.55,1.23a2.4,2.4,0,0,0,1.67-1.44c.3-.74.06-1.28-.55-1.23A2.42,2.42,0,0,0,220.83,47.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M217.31,47.4c-.32.74-.08,1.29.54,1.24a2.42,2.42,0,0,0,1.67-1.45c.32-.74.08-1.29-.53-1.23A2.45,2.45,0,0,0,217.31,47.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M213.8,47.74c-.33.74-.1,1.3.51,1.24a2.48,2.48,0,0,0,1.7-1.45c.32-.74.09-1.29-.52-1.23A2.47,2.47,0,0,0,213.8,47.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M210.29,48.09c-.34.75-.12,1.3.5,1.24a2.52,2.52,0,0,0,1.7-1.46c.33-.74.11-1.29-.5-1.23A2.55,2.55,0,0,0,210.29,48.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.78,48.45c-.35.75-.13,1.3.48,1.24A2.59,2.59,0,0,0,209,48.23c.34-.74.12-1.29-.48-1.23A2.55,2.55,0,0,0,206.78,48.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M203.27,48.82c-.35.74-.14,1.3.47,1.23a2.58,2.58,0,0,0,1.73-1.46c.35-.74.14-1.29-.46-1.23A2.64,2.64,0,0,0,203.27,48.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M196.28,49.58c-.37.75-.17,1.3.44,1.23a2.7,2.7,0,0,0,1.75-1.47c.37-.75.17-1.3-.43-1.23A2.7,2.7,0,0,0,196.28,49.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M192.79,50c-.38.75-.19,1.3.42,1.23A2.75,2.75,0,0,0,195,49.73c.37-.75.18-1.3-.42-1.23A2.72,2.72,0,0,0,192.79,50Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.82,50.78c-.39.75-.21,1.3.39,1.23a2.85,2.85,0,0,0,1.8-1.49c.38-.75.2-1.29-.4-1.22A2.84,2.84,0,0,0,185.82,50.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M182.34,51.2c-.39.75-.22,1.3.38,1.23a2.91,2.91,0,0,0,1.81-1.5c.39-.75.22-1.3-.38-1.22A2.88,2.88,0,0,0,182.34,51.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M175.41,52.05c-.41.75-.26,1.31.35,1.23a3,3,0,0,0,1.82-1.5c.41-.75.25-1.3-.35-1.23A3,3,0,0,0,175.41,52.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172,52.49c-.42.76-.27,1.31.33,1.23a3,3,0,0,0,1.84-1.51c.41-.75.26-1.3-.34-1.22A3,3,0,0,0,172,52.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M168.49,52.94c-.42.75-.28,1.31.32,1.23a3.07,3.07,0,0,0,1.85-1.51c.42-.76.27-1.31-.32-1.23A3.06,3.06,0,0,0,168.49,52.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M165,53.39c-.43.76-.29,1.31.31,1.23a3.14,3.14,0,0,0,1.86-1.51c.42-.76.28-1.31-.31-1.23A3.11,3.11,0,0,0,165,53.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M161.6,53.86c-.44.75-.31,1.31.29,1.22a3.14,3.14,0,0,0,1.87-1.52c.43-.75.3-1.3-.3-1.22A3.16,3.16,0,0,0,161.6,53.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M154.73,54.8c-.45.76-.33,1.32.27,1.23a3.24,3.24,0,0,0,1.88-1.53c.45-.76.33-1.31-.27-1.22A3.25,3.25,0,0,0,154.73,54.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M151.3,55.29c-.45.76-.34,1.31.26,1.23A3.34,3.34,0,0,0,153.45,55c.45-.76.34-1.31-.25-1.22A3.3,3.3,0,0,0,151.3,55.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M144.47,56.28c-.47.77-.37,1.32.23,1.23A3.41,3.41,0,0,0,146.61,56c.46-.77.36-1.32-.23-1.23A3.44,3.44,0,0,0,144.47,56.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M141.06,56.79c-.47.77-.37,1.32.22,1.23a3.44,3.44,0,0,0,1.92-1.55c.47-.77.37-1.32-.22-1.23A3.44,3.44,0,0,0,141.06,56.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M130.88,58.36c-.49.77-.41,1.32.18,1.22A3.58,3.58,0,0,0,133,58c.48-.77.4-1.32-.18-1.23A3.6,3.6,0,0,0,130.88,58.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M600.51,58.15a4.16,4.16,0,0,0,2,1.6c.56.1.56-.46,0-1.25a4.16,4.16,0,0,0-2-1.6C600,56.8,600,57.36,600.51,58.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M597.31,57.59a4,4,0,0,0,2,1.6c.56.1.56-.46,0-1.25a4,4,0,0,0-2-1.59C596.76,56.25,596.76,56.81,597.31,57.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M594.1,57a4.06,4.06,0,0,0,2,1.6c.56.09.57-.47,0-1.25a4,4,0,0,0-2-1.59C593.56,55.7,593.55,56.26,594.1,57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M590.88,56.5a4,4,0,0,0,2,1.59c.56.09.57-.46,0-1.25a4,4,0,0,0-2-1.59C590.35,55.16,590.34,55.72,590.88,56.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M587.66,56a4,4,0,0,0,2,1.59c.56.09.57-.47,0-1.25a4,4,0,0,0-2-1.58C587.13,54.63,587.11,55.18,587.66,56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M584.42,55.43a3.94,3.94,0,0,0,2,1.58c.57.09.58-.46,0-1.24a3.94,3.94,0,0,0-2-1.58C583.9,54.1,583.89,54.65,584.42,55.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M581.18,54.91a3.91,3.91,0,0,0,2,1.57c.56.09.58-.46.05-1.24a3.91,3.91,0,0,0-2-1.57C580.67,53.57,580.65,54.13,581.18,54.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M577.94,54.39a3.82,3.82,0,0,0,2,1.57c.56.09.59-.47.06-1.24a3.9,3.9,0,0,0-2-1.57C577.43,53.06,577.41,53.62,577.94,54.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574.68,53.88a3.85,3.85,0,0,0,2,1.56c.57.09.6-.46.07-1.24a3.78,3.78,0,0,0-2-1.56C574.19,52.55,574.16,53.11,574.68,53.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M571.42,53.37a3.73,3.73,0,0,0,2,1.56c.57.09.61-.47.08-1.24a3.79,3.79,0,0,0-2-1.56C570.94,52.05,570.9,52.6,571.42,53.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M568.16,52.88a3.71,3.71,0,0,0,2,1.55c.57.09.61-.47.09-1.24a3.72,3.72,0,0,0-2-1.55C567.68,51.55,567.64,52.11,568.16,52.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M564.88,52.38a3.66,3.66,0,0,0,2,1.55c.57.09.61-.47.1-1.24a3.67,3.67,0,0,0-2-1.54C564.41,51.06,564.37,51.62,564.88,52.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M561.6,51.9a3.62,3.62,0,0,0,1.95,1.54c.58.09.62-.47.11-1.24a3.62,3.62,0,0,0-1.95-1.54C561.14,50.58,561.09,51.13,561.6,51.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M558.31,51.42a3.62,3.62,0,0,0,2,1.54c.57.08.63-.47.12-1.24a3.69,3.69,0,0,0-1.94-1.54C557.87,50.1,557.81,50.66,558.31,51.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555,51A3.55,3.55,0,0,0,557,52.48c.57.09.63-.47.13-1.23a3.57,3.57,0,0,0-1.94-1.54C554.58,49.63,554.52,50.19,555,51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M551.72,50.49A3.59,3.59,0,0,0,553.65,52c.58.08.64-.48.14-1.24a3.5,3.5,0,0,0-1.93-1.53C551.29,49.17,551.23,49.72,551.72,50.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M548.42,50a3.44,3.44,0,0,0,1.92,1.52c.57.08.64-.47.15-1.23a3.48,3.48,0,0,0-1.92-1.53C548,48.71,547.93,49.27,548.42,50Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.1,49.58A3.44,3.44,0,0,0,547,51.1c.58.08.65-.48.16-1.24a3.45,3.45,0,0,0-1.91-1.52C544.7,48.27,544.62,48.82,545.1,49.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M541.78,49.13a3.42,3.42,0,0,0,1.91,1.52c.58.08.66-.48.18-1.24A3.4,3.4,0,0,0,542,47.9C541.39,47.82,541.31,48.38,541.78,49.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M538.46,48.7a3.35,3.35,0,0,0,1.9,1.51c.58.07.66-.48.19-1.24a3.34,3.34,0,0,0-1.9-1.5C538.08,47.39,538,47.94,538.46,48.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.13,48.27a3.28,3.28,0,0,0,1.89,1.5c.58.08.67-.48.2-1.23a3.27,3.27,0,0,0-1.89-1.5C534.76,47,534.66,47.51,535.13,48.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M531.79,47.85a3.3,3.3,0,0,0,1.88,1.5c.58.07.68-.48.22-1.24A3.27,3.27,0,0,0,532,46.62C531.43,46.54,531.33,47.09,531.79,47.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M528.45,47.43a3.26,3.26,0,0,0,1.87,1.5c.58.07.69-.48.23-1.24a3.22,3.22,0,0,0-1.87-1.49C528.1,46.13,528,46.68,528.45,47.43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525.1,47A3.16,3.16,0,0,0,527,48.51c.59.08.69-.48.25-1.23a3.2,3.2,0,0,0-1.87-1.49C524.76,45.72,524.66,46.28,525.1,47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M521.75,46.63a3.15,3.15,0,0,0,1.85,1.48c.59.07.7-.48.26-1.23A3.18,3.18,0,0,0,522,45.39C521.42,45.33,521.31,45.88,521.75,46.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M518.39,46.23a3.07,3.07,0,0,0,1.84,1.48c.59.07.71-.48.27-1.23A3.1,3.1,0,0,0,518.66,45C518.08,44.94,518,45.49,518.39,46.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M515,45.85a3.06,3.06,0,0,0,1.84,1.47c.58.07.71-.48.28-1.23a3,3,0,0,0-1.83-1.47C514.72,44.55,514.6,45.1,515,45.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M511.65,45.47a3,3,0,0,0,1.83,1.47c.58.06.72-.49.29-1.23A3,3,0,0,0,512,44.24C511.37,44.18,511.23,44.72,511.65,45.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M508.28,45.1a2.9,2.9,0,0,0,1.81,1.46c.59.07.73-.49.31-1.23a3,3,0,0,0-1.81-1.46C508,43.81,507.87,44.36,508.28,45.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M504.9,44.74a2.9,2.9,0,0,0,1.8,1.46c.59.06.73-.49.32-1.24a2.87,2.87,0,0,0-1.8-1.45C504.64,43.45,504.49,44,504.9,44.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M501.51,44.38a2.88,2.88,0,0,0,1.79,1.46c.59.06.74-.5.34-1.24a2.83,2.83,0,0,0-1.79-1.45C501.27,43.09,501.11,43.64,501.51,44.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M498.12,44a2.79,2.79,0,0,0,1.78,1.45c.59.06.75-.49.35-1.23a2.77,2.77,0,0,0-1.78-1.44C497.89,42.75,497.73,43.29,498.12,44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M494.73,43.69a2.74,2.74,0,0,0,1.76,1.45c.6.06.76-.5.37-1.23a2.73,2.73,0,0,0-1.77-1.44C494.51,42.41,494.34,43,494.73,43.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.33,43.36a2.68,2.68,0,0,0,1.75,1.44c.59.06.76-.49.38-1.23a2.7,2.7,0,0,0-1.75-1.44C491.12,42.08,491,42.63,491.33,43.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.92,43a2.65,2.65,0,0,0,1.74,1.43c.6.06.77-.49.4-1.23a2.65,2.65,0,0,0-1.74-1.43C487.73,41.75,487.55,42.3,487.92,43Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M484.51,42.72a2.61,2.61,0,0,0,1.73,1.43c.6.06.78-.5.41-1.23a2.61,2.61,0,0,0-1.73-1.43C484.34,41.44,484.15,42,484.51,42.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.1,42.41a2.59,2.59,0,0,0,1.71,1.43c.6,0,.79-.5.43-1.23a2.6,2.6,0,0,0-1.72-1.43C480.94,41.13,480.74,41.68,481.1,42.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M477.68,42.11a2.51,2.51,0,0,0,1.7,1.42c.6,0,.8-.5.45-1.23a2.59,2.59,0,0,0-1.71-1.42C477.53,40.83,477.33,41.38,477.68,42.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.25,41.82a2.47,2.47,0,0,0,1.7,1.41c.59.06.8-.5.45-1.23a2.48,2.48,0,0,0-1.69-1.41C474.12,40.54,473.92,41.09,474.25,41.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M470.83,41.53a2.43,2.43,0,0,0,1.68,1.41c.59.05.81-.5.47-1.23a2.42,2.42,0,0,0-1.68-1.4C470.71,40.26,470.5,40.81,470.83,41.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M467.4,41.26a2.39,2.39,0,0,0,1.66,1.4c.6.05.82-.5.49-1.23a2.4,2.4,0,0,0-1.66-1.4C467.29,40,467.07,40.53,467.4,41.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M464,41a2.35,2.35,0,0,0,1.65,1.4c.6,0,.83-.51.51-1.23a2.37,2.37,0,0,0-1.65-1.4C463.87,39.71,463.65,40.26,464,41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M460.52,40.73a2.3,2.3,0,0,0,1.64,1.39c.6.05.83-.5.52-1.23A2.28,2.28,0,0,0,461,39.5C460.45,39.45,460.21,40,460.52,40.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M457.08,40.47a2.26,2.26,0,0,0,1.62,1.4c.6,0,.84-.51.54-1.24a2.29,2.29,0,0,0-1.63-1.39C457,39.2,456.78,39.75,457.08,40.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M453.63,40.23a2.25,2.25,0,0,0,1.61,1.39c.6,0,.85-.51.55-1.24A2.21,2.21,0,0,0,454.18,39C453.59,39,453.34,39.51,453.63,40.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450.18,40a2.16,2.16,0,0,0,1.6,1.39c.6,0,.85-.52.57-1.24a2.22,2.22,0,0,0-1.6-1.38C450.15,38.72,449.9,39.27,450.18,40Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M446.72,39.77a2.12,2.12,0,0,0,1.59,1.37c.6,0,.86-.51.58-1.23a2.16,2.16,0,0,0-1.58-1.38C446.71,38.49,446.45,39,446.72,39.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.26,39.55a2.13,2.13,0,0,0,1.57,1.37c.61,0,.88-.52.61-1.24a2.13,2.13,0,0,0-1.57-1.37C443.27,38.28,443,38.83,443.26,39.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M439.8,39.33a2,2,0,0,0,1.56,1.37c.6,0,.88-.52.62-1.23a2.08,2.08,0,0,0-1.56-1.37C439.82,38.06,439.55,38.62,439.8,39.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M436.34,39.13a2,2,0,0,0,1.54,1.37c.6,0,.89-.52.63-1.24A2,2,0,0,0,437,37.9C436.38,37.86,436.09,38.41,436.34,39.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M432.87,38.93a2,2,0,0,0,1.52,1.37c.61,0,.9-.53.66-1.24a2,2,0,0,0-1.53-1.36C432.92,37.67,432.63,38.22,432.87,38.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.4,38.75a1.92,1.92,0,0,0,1.51,1.35c.6,0,.9-.52.67-1.24a2,2,0,0,0-1.51-1.35C429.47,37.48,429.17,38,429.4,38.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M425.92,38.57a1.91,1.91,0,0,0,1.5,1.35c.6,0,.91-.52.68-1.24a1.89,1.89,0,0,0-1.49-1.35C426,37.3,425.7,37.85,425.92,38.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M422.44,38.4a1.89,1.89,0,0,0,1.48,1.35c.61,0,.93-.53.71-1.25a1.89,1.89,0,0,0-1.48-1.34C422.55,37.13,422.23,37.69,422.44,38.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419,38.24a1.83,1.83,0,0,0,1.47,1.34c.61,0,.93-.53.72-1.24A1.83,1.83,0,0,0,419.69,37C419.08,37,418.76,37.52,419,38.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.48,38.08a1.77,1.77,0,0,0,1.45,1.34c.61,0,.94-.53.74-1.24a1.82,1.82,0,0,0-1.45-1.34C415.62,36.82,415.29,37.37,415.48,38.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412,37.94a1.79,1.79,0,0,0,1.44,1.34c.61,0,.94-.54.75-1.25a1.76,1.76,0,0,0-1.43-1.33C412.15,36.67,411.81,37.23,412,37.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408.51,37.8a1.74,1.74,0,0,0,1.41,1.34c.61,0,1-.54.78-1.25a1.75,1.75,0,0,0-1.42-1.33C408.68,36.54,408.33,37.09,408.51,37.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M405,37.67A1.7,1.7,0,0,0,406.42,39c.61,0,1-.54.79-1.25a1.7,1.7,0,0,0-1.4-1.32C405.2,36.41,404.85,37,405,37.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.52,37.55a1.66,1.66,0,0,0,1.39,1.33c.61,0,1-.54.81-1.25a1.68,1.68,0,0,0-1.39-1.32C401.73,36.29,401.36,36.84,401.52,37.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398,37.44a1.65,1.65,0,0,0,1.38,1.33c.61,0,1-.55.82-1.26a1.61,1.61,0,0,0-1.37-1.31C398.25,36.18,397.88,36.73,398,37.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M394.53,37.34a1.59,1.59,0,0,0,1.35,1.32c.61,0,1-.55.84-1.26a1.61,1.61,0,0,0-1.35-1.31C394.77,36.07,394.39,36.63,394.53,37.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M391,37.25a1.53,1.53,0,0,0,1.34,1.31c.61,0,1-.55.86-1.25A1.57,1.57,0,0,0,391.89,36C391.28,36,390.9,36.54,391,37.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M387.52,37.16a1.53,1.53,0,0,0,1.33,1.31c.61,0,1-.55.87-1.25a1.52,1.52,0,0,0-1.32-1.31C387.8,35.89,387.41,36.45,387.52,37.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M384,37.09a1.5,1.5,0,0,0,1.31,1.3c.61,0,1-.55.89-1.26a1.5,1.5,0,0,0-1.3-1.3C384.31,35.82,383.91,36.38,384,37.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380.52,37a1.46,1.46,0,0,0,1.28,1.3,1,1,0,0,0,.92-1.26,1.48,1.48,0,0,0-1.29-1.3A1,1,0,0,0,380.52,37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M377,37a1.44,1.44,0,0,0,1.27,1.3,1,1,0,0,0,.93-1.27,1.45,1.45,0,0,0-1.27-1.29A1,1,0,0,0,377,37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373.5,36.91a1.42,1.42,0,0,0,1.25,1.3,1,1,0,0,0,.95-1.27,1.41,1.41,0,0,0-1.25-1.29A1,1,0,0,0,373.5,36.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370,36.87a1.38,1.38,0,0,0,1.24,1.29,1,1,0,0,0,1-1.27A1.39,1.39,0,0,0,371,35.6,1.05,1.05,0,0,0,370,36.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.48,36.83a1.35,1.35,0,0,0,1.22,1.29,1.06,1.06,0,0,0,1-1.27,1.35,1.35,0,0,0-1.22-1.28A1.05,1.05,0,0,0,366.48,36.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M363,36.81a1.36,1.36,0,0,0,1.21,1.29,1.09,1.09,0,0,0,1-1.28A1.34,1.34,0,0,0,364,35.54,1.09,1.09,0,0,0,363,36.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.45,36.79a1.31,1.31,0,0,0,1.19,1.29,1.11,1.11,0,0,0,1-1.28,1.31,1.31,0,0,0-1.19-1.28A1.11,1.11,0,0,0,359.45,36.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.93,36.78a1.29,1.29,0,0,0,1.18,1.29,1.13,1.13,0,0,0,1-1.28A1.28,1.28,0,0,0,357,35.51,1.13,1.13,0,0,0,355.93,36.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M352.42,36.78a1.25,1.25,0,0,0,1.15,1.28,1.14,1.14,0,0,0,1.06-1.28,1.27,1.27,0,0,0-1.16-1.27A1.14,1.14,0,0,0,352.42,36.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M348.9,36.79A1.23,1.23,0,0,0,350,38.07a1.17,1.17,0,0,0,1.07-1.28A1.22,1.22,0,0,0,350,35.52,1.16,1.16,0,0,0,348.9,36.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M345.38,36.81a1.22,1.22,0,0,0,1.12,1.28,1.19,1.19,0,0,0,1.09-1.29,1.21,1.21,0,0,0-1.12-1.27A1.2,1.2,0,0,0,345.38,36.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M306.66,37.59a1,1,0,0,0,.94,1.25,1.47,1.47,0,0,0,1.27-1.32c.09-.71-.32-1.26-.93-1.24A1.48,1.48,0,0,0,306.66,37.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M303.14,37.72a1,1,0,0,0,.92,1.24,1.5,1.5,0,0,0,1.29-1.32c.1-.71-.31-1.27-.91-1.24A1.52,1.52,0,0,0,303.14,37.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M299.63,37.85c-.12.71.28,1.27.89,1.24a1.55,1.55,0,0,0,1.32-1.33c.11-.71-.3-1.26-.9-1.24A1.55,1.55,0,0,0,299.63,37.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M296.11,38c-.13.71.27,1.27.88,1.24a1.57,1.57,0,0,0,1.33-1.33c.12-.71-.28-1.26-.88-1.24A1.57,1.57,0,0,0,296.11,38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M292.59,38.14c-.14.71.25,1.27.86,1.24A1.61,1.61,0,0,0,294.8,38c.13-.71-.26-1.26-.86-1.23A1.61,1.61,0,0,0,292.59,38.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M268,39.42c-.2.72.14,1.27.75,1.24a1.86,1.86,0,0,0,1.46-1.37c.19-.72-.14-1.26-.75-1.23A1.88,1.88,0,0,0,268,39.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M264.5,39.64c-.21.72.12,1.27.73,1.23a1.87,1.87,0,0,0,1.47-1.37c.2-.71-.12-1.26-.73-1.22A1.89,1.89,0,0,0,264.5,39.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M261,39.87c-.21.72.1,1.27.72,1.23a1.91,1.91,0,0,0,1.48-1.38c.22-.71-.1-1.26-.71-1.22A1.94,1.94,0,0,0,261,39.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M257.49,40.1c-.23.72.08,1.27.7,1.23a2,2,0,0,0,1.5-1.38c.22-.71-.09-1.26-.7-1.22A2,2,0,0,0,257.49,40.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M254,40.35c-.24.72.07,1.27.68,1.22a2,2,0,0,0,1.52-1.38c.23-.72-.08-1.26-.68-1.22A2,2,0,0,0,254,40.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M250.49,40.6c-.24.72,0,1.27.66,1.23a2.06,2.06,0,0,0,1.54-1.39c.24-.72-.06-1.27-.66-1.22A2,2,0,0,0,250.49,40.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M247,40.86c-.26.72,0,1.27.64,1.23a2.09,2.09,0,0,0,1.55-1.39c.25-.73,0-1.27-.64-1.23A2.09,2.09,0,0,0,247,40.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M243.5,41.13c-.26.72,0,1.27.63,1.22A2.11,2.11,0,0,0,245.7,41c.25-.72,0-1.27-.63-1.22A2.11,2.11,0,0,0,243.5,41.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.12,43.25c-.32.73-.09,1.28.52,1.22A2.42,2.42,0,0,0,221.3,43c.32-.73.08-1.27-.52-1.22A2.43,2.43,0,0,0,219.12,43.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M215.65,43.58c-.33.73-.1,1.28.5,1.22a2.44,2.44,0,0,0,1.68-1.43c.32-.73.1-1.27-.5-1.22A2.48,2.48,0,0,0,215.65,43.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M212.18,43.93c-.33.73-.12,1.28.49,1.22a2.51,2.51,0,0,0,1.69-1.44c.33-.73.11-1.28-.49-1.22A2.51,2.51,0,0,0,212.18,43.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M208.72,44.28c-.35.73-.13,1.28.47,1.22a2.53,2.53,0,0,0,1.7-1.44c.34-.74.13-1.28-.47-1.22A2.52,2.52,0,0,0,208.72,44.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M205.26,44.64c-.35.73-.15,1.28.45,1.22a2.55,2.55,0,0,0,1.72-1.45c.35-.73.14-1.28-.46-1.21A2.58,2.58,0,0,0,205.26,44.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M198.35,45.38c-.37.74-.18,1.29.42,1.22a2.66,2.66,0,0,0,1.75-1.45c.36-.74.17-1.29-.43-1.22A2.67,2.67,0,0,0,198.35,45.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M194.9,45.77c-.37.74-.19,1.29.41,1.22a2.77,2.77,0,0,0,1.76-1.46c.36-.74.18-1.29-.42-1.22A2.75,2.75,0,0,0,194.9,45.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.46,46.16c-.39.74-.21,1.29.39,1.22a2.8,2.8,0,0,0,1.77-1.47c.37-.74.2-1.28-.4-1.21A2.76,2.76,0,0,0,191.46,46.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M184.58,47c-.4.74-.23,1.29.37,1.22a2.89,2.89,0,0,0,1.79-1.48c.39-.74.22-1.29-.37-1.21A2.82,2.82,0,0,0,184.58,47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M174.3,48.24c-.42.75-.27,1.3.32,1.22A3,3,0,0,0,176.45,48c.41-.75.26-1.29-.33-1.22A3,3,0,0,0,174.3,48.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M167.47,49.13c-.43.75-.3,1.3.3,1.22a3.09,3.09,0,0,0,1.84-1.5c.43-.76.29-1.3-.3-1.22A3.08,3.08,0,0,0,167.47,49.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M153.87,51c-.46.76-.35,1.31.25,1.22A3.25,3.25,0,0,0,156,50.69c.45-.76.34-1.3-.25-1.22A3.28,3.28,0,0,0,153.87,51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M150.48,51.48c-.46.75-.35,1.3.24,1.22a3.35,3.35,0,0,0,1.89-1.53c.46-.76.35-1.3-.24-1.22A3.34,3.34,0,0,0,150.48,51.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M147.1,52c-.46.76-.36,1.31.22,1.22a3.42,3.42,0,0,0,1.91-1.53c.46-.76.36-1.31-.23-1.22A3.39,3.39,0,0,0,147.1,52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M143.73,52.47c-.47.76-.38,1.31.21,1.22a3.47,3.47,0,0,0,1.91-1.54c.46-.76.37-1.3-.21-1.22A3.44,3.44,0,0,0,143.73,52.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M581.39,51a3.91,3.91,0,0,0,2,1.57c.56.09.57-.47,0-1.25a3.87,3.87,0,0,0-2-1.56C580.86,49.71,580.85,50.26,581.39,51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M578.17,50.52a3.86,3.86,0,0,0,2,1.57c.56.09.58-.47,0-1.24a3.9,3.9,0,0,0-2-1.57C577.65,49.19,577.63,49.75,578.17,50.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M574.94,50a3.78,3.78,0,0,0,2,1.56c.56.09.58-.46.05-1.24a3.91,3.91,0,0,0-2-1.56C574.44,48.69,574.42,49.24,574.94,50Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M571.71,49.51a3.87,3.87,0,0,0,2,1.56c.56.08.59-.47.06-1.24a3.76,3.76,0,0,0-2-1.56C571.22,48.19,571.19,48.74,571.71,49.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M568.48,49a3.79,3.79,0,0,0,2,1.55c.56.08.59-.47.07-1.24a3.73,3.73,0,0,0-2-1.55C568,47.69,568,48.25,568.48,49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M565.24,48.53a3.66,3.66,0,0,0,2,1.54c.57.09.6-.47.08-1.24a3.79,3.79,0,0,0-1.95-1.54C564.76,47.21,564.72,47.76,565.24,48.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M562,48.05a3.64,3.64,0,0,0,2,1.53c.56.09.6-.47.09-1.23a3.69,3.69,0,0,0-1.95-1.54C561.52,46.73,561.48,47.28,562,48.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M558.74,47.57a3.59,3.59,0,0,0,1.94,1.53c.56.09.61-.47.1-1.23a3.61,3.61,0,0,0-1.94-1.53C558.28,46.26,558.23,46.81,558.74,47.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555.48,47.1a3.57,3.57,0,0,0,1.93,1.53c.57.08.62-.47.11-1.23a3.56,3.56,0,0,0-1.93-1.53C555,45.79,555,46.34,555.48,47.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M552.21,46.64a3.55,3.55,0,0,0,1.93,1.52c.57.08.62-.47.12-1.23a3.57,3.57,0,0,0-1.92-1.52C551.77,45.33,551.72,45.88,552.21,46.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M548.94,46.19a3.52,3.52,0,0,0,1.92,1.51c.57.08.63-.47.14-1.23A3.5,3.5,0,0,0,549.08,45C548.51,44.88,548.45,45.43,548.94,46.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M545.67,45.74a3.47,3.47,0,0,0,1.91,1.51c.57.08.63-.47.14-1.23a3.46,3.46,0,0,0-1.91-1.51C545.25,44.43,545.18,45,545.67,45.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M542.38,45.3a3.4,3.4,0,0,0,1.91,1.5c.57.08.64-.47.16-1.23a3.44,3.44,0,0,0-1.91-1.5C542,44,541.9,44.54,542.38,45.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.1,44.86a3.3,3.3,0,0,0,1.89,1.5c.58.08.65-.47.17-1.23a3.35,3.35,0,0,0-1.89-1.49C538.7,43.56,538.62,44.11,539.1,44.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M535.8,44.44a3.32,3.32,0,0,0,1.89,1.49c.58.08.66-.47.18-1.23A3.3,3.3,0,0,0,536,43.21C535.42,43.14,535.34,43.69,535.8,44.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M532.51,44a3.28,3.28,0,0,0,1.88,1.49c.57.07.66-.48.19-1.23a3.3,3.3,0,0,0-1.88-1.49C532.13,42.72,532,43.27,532.51,44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529.2,43.6a3.25,3.25,0,0,0,1.87,1.49c.58.07.67-.48.21-1.23a3.23,3.23,0,0,0-1.87-1.48C528.84,42.31,528.75,42.86,529.2,43.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M525.9,43.2a3.2,3.2,0,0,0,1.86,1.48c.57.07.67-.48.22-1.23A3.15,3.15,0,0,0,526.12,42C525.55,41.91,525.45,42.45,525.9,43.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M522.58,42.8a3.15,3.15,0,0,0,1.85,1.47c.58.07.69-.48.24-1.22a3.15,3.15,0,0,0-1.85-1.47C522.24,41.51,522.14,42.06,522.58,42.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M519.27,42.41a3.11,3.11,0,0,0,1.84,1.47c.58.07.68-.48.24-1.23a3.06,3.06,0,0,0-1.84-1.46C518.94,41.12,518.83,41.67,519.27,42.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M515.94,42a3.11,3.11,0,0,0,1.83,1.46c.58.07.7-.48.26-1.22a3,3,0,0,0-1.83-1.47C515.63,40.74,515.51,41.28,515.94,42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M512.62,41.65a3,3,0,0,0,1.82,1.46c.58.06.7-.49.27-1.23a3,3,0,0,0-1.82-1.45C512.32,40.36,512.19,40.91,512.62,41.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M509.29,41.28a2.9,2.9,0,0,0,1.8,1.45c.58.07.71-.48.29-1.22a2.94,2.94,0,0,0-1.81-1.45C509,40,508.87,40.54,509.29,41.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M506,40.92a3,3,0,0,0,1.8,1.45c.58.06.71-.49.3-1.23a2.94,2.94,0,0,0-1.8-1.44C505.67,39.64,505.54,40.18,506,40.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M502.61,40.56A2.84,2.84,0,0,0,504.39,42c.59.06.73-.49.32-1.22a2.86,2.86,0,0,0-1.79-1.44C502.34,39.29,502.2,39.83,502.61,40.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499.26,40.22A2.83,2.83,0,0,0,501,41.66c.58.06.73-.49.32-1.23A2.82,2.82,0,0,0,499.59,39C499,38.94,498.87,39.49,499.26,40.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.91,39.88a2.77,2.77,0,0,0,1.77,1.43c.58.06.73-.49.34-1.22a2.82,2.82,0,0,0-1.77-1.43C495.68,38.6,495.52,39.15,495.91,39.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M492.56,39.55A2.67,2.67,0,0,0,494.31,41c.58.06.74-.48.36-1.22a2.76,2.76,0,0,0-1.76-1.42C492.34,38.27,492.18,38.82,492.56,39.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.2,39.22a2.68,2.68,0,0,0,1.74,1.43c.58.05.75-.5.37-1.22A2.68,2.68,0,0,0,489.57,38C489,38,488.82,38.5,489.2,39.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485.84,38.91a2.61,2.61,0,0,0,1.72,1.41c.59.06.76-.49.39-1.22a2.68,2.68,0,0,0-1.73-1.41C485.64,37.64,485.47,38.18,485.84,38.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M482.47,38.6A2.6,2.6,0,0,0,484.18,40c.59.05.77-.49.4-1.22a2.63,2.63,0,0,0-1.71-1.41C482.29,37.33,482.11,37.87,482.47,38.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M479.1,38.3a2.52,2.52,0,0,0,1.7,1.4c.59.06.77-.49.42-1.21a2.55,2.55,0,0,0-1.71-1.41C478.93,37,478.75,37.57,479.1,38.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M475.72,38a2.52,2.52,0,0,0,1.69,1.41c.59.05.78-.5.43-1.22a2.48,2.48,0,0,0-1.69-1.4C475.57,36.74,475.38,37.28,475.72,38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.34,37.72a2.48,2.48,0,0,0,1.68,1.4c.59.05.79-.5.45-1.22a2.49,2.49,0,0,0-1.68-1.4C472.21,36.46,472,37,472.34,37.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469,37.44a2.4,2.4,0,0,0,1.67,1.39c.59.05.79-.49.46-1.22a2.4,2.4,0,0,0-1.67-1.38C468.84,36.18,468.63,36.72,469,37.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.58,37.17a2.38,2.38,0,0,0,1.65,1.39c.59,0,.8-.5.47-1.22A2.34,2.34,0,0,0,466.05,36C465.47,35.91,465.25,36.45,465.58,37.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M462.19,36.91a2.29,2.29,0,0,0,1.63,1.38c.59.05.81-.5.5-1.22a2.39,2.39,0,0,0-1.64-1.38C462.09,35.65,461.87,36.19,462.19,36.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.79,36.66A2.28,2.28,0,0,0,460.42,38c.59,0,.81-.5.5-1.22a2.29,2.29,0,0,0-1.62-1.37C458.72,35.4,458.49,35.94,458.79,36.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455.4,36.41a2.22,2.22,0,0,0,1.6,1.37c.6,0,.83-.5.53-1.22a2.28,2.28,0,0,0-1.61-1.37C455.33,35.15,455.1,35.69,455.4,36.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M452,36.17a2.22,2.22,0,0,0,1.6,1.37c.59,0,.84-.51.54-1.22A2.21,2.21,0,0,0,452.54,35C452,34.91,451.71,35.46,452,36.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M448.59,35.94a2.18,2.18,0,0,0,1.58,1.37c.6,0,.85-.51.56-1.23a2.18,2.18,0,0,0-1.58-1.36C448.56,34.69,448.31,35.23,448.59,35.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M445.18,35.72a2.15,2.15,0,0,0,1.57,1.36c.6,0,.85-.51.57-1.22a2.12,2.12,0,0,0-1.56-1.36C445.17,34.46,444.91,35,445.18,35.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M441.77,35.5a2.1,2.1,0,0,0,1.56,1.36c.59,0,.85-.51.59-1.22a2.09,2.09,0,0,0-1.56-1.35C441.78,34.25,441.51,34.79,441.77,35.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M438.36,35.3a2,2,0,0,0,1.54,1.35c.59,0,.87-.51.6-1.22A2.05,2.05,0,0,0,439,34.08C438.38,34.05,438.11,34.59,438.36,35.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M434.94,35.1a2,2,0,0,0,1.53,1.35c.59,0,.87-.52.62-1.23a2,2,0,0,0-1.52-1.34C435,33.85,434.7,34.39,434.94,35.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M431.53,34.91a1.94,1.94,0,0,0,1.5,1.34c.6,0,.89-.51.64-1.22a2,2,0,0,0-1.5-1.34C431.57,33.66,431.29,34.2,431.53,34.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M428.1,34.73a2,2,0,0,0,1.5,1.34c.59,0,.89-.52.65-1.23a1.93,1.93,0,0,0-1.49-1.33C428.17,33.48,427.88,34,428.1,34.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M424.68,34.55a1.89,1.89,0,0,0,1.48,1.34c.59,0,.9-.52.67-1.23a1.92,1.92,0,0,0-1.48-1.33C424.76,33.31,424.46,33.85,424.68,34.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M421.25,34.39a1.87,1.87,0,0,0,1.46,1.33c.6,0,.91-.52.69-1.23a1.85,1.85,0,0,0-1.46-1.32C421.35,33.14,421,33.69,421.25,34.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.82,34.23a1.81,1.81,0,0,0,1.45,1.33c.6,0,.91-.53.71-1.23A1.84,1.84,0,0,0,418.53,33C417.94,33,417.62,33.53,417.82,34.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.39,34.08a1.75,1.75,0,0,0,1.43,1.32c.6,0,.92-.52.72-1.22a1.75,1.75,0,0,0-1.43-1.32C414.52,32.83,414.2,33.38,414.39,34.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411,33.94a1.75,1.75,0,0,0,1.42,1.32c.6,0,.93-.53.74-1.23a1.74,1.74,0,0,0-1.41-1.31C411.1,32.69,410.77,33.24,411,33.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M407.52,33.81a1.69,1.69,0,0,0,1.4,1.31c.6,0,.94-.53.76-1.23a1.73,1.73,0,0,0-1.4-1.31C407.68,32.56,407.34,33.11,407.52,33.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.08,33.69A1.67,1.67,0,0,0,405.46,35c.6,0,1-.54.78-1.24a1.67,1.67,0,0,0-1.39-1.3C404.26,32.44,403.91,33,404.08,33.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M400.64,33.57A1.63,1.63,0,0,0,402,34.88c.61,0,1-.54.8-1.24a1.65,1.65,0,0,0-1.37-1.3C400.84,32.32,400.48,32.87,400.64,33.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M397.19,33.46a1.59,1.59,0,0,0,1.36,1.3c.6,0,1-.53.81-1.23a1.63,1.63,0,0,0-1.36-1.3C397.41,32.21,397.05,32.76,397.19,33.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393.75,33.36a1.56,1.56,0,0,0,1.33,1.3c.6,0,1-.54.83-1.24a1.57,1.57,0,0,0-1.34-1.29C394,32.11,393.61,32.66,393.75,33.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M390.3,33.27a1.55,1.55,0,0,0,1.32,1.3c.6,0,1-.54.84-1.24A1.53,1.53,0,0,0,391.14,32C390.55,32,390.17,32.57,390.3,33.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M380,33.05a1.44,1.44,0,0,0,1.27,1.28,1,1,0,0,0,.89-1.24,1.44,1.44,0,0,0-1.27-1.28C380.25,31.8,379.85,32.35,380,33.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.49,33a1.42,1.42,0,0,0,1.26,1.28,1,1,0,0,0,.91-1.24,1.43,1.43,0,0,0-1.25-1.28A1,1,0,0,0,376.49,33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M373,32.94a1.39,1.39,0,0,0,1.23,1.28,1,1,0,0,0,.94-1.25A1.41,1.41,0,0,0,374,31.7,1,1,0,0,0,373,32.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M369.58,32.9a1.37,1.37,0,0,0,1.22,1.28,1,1,0,0,0,.95-1.25,1.37,1.37,0,0,0-1.22-1.27A1,1,0,0,0,369.58,32.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M366.12,32.87a1.33,1.33,0,0,0,1.21,1.27,1,1,0,0,0,1-1.25,1.35,1.35,0,0,0-1.2-1.27A1.05,1.05,0,0,0,366.12,32.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.66,32.84a1.31,1.31,0,0,0,1.19,1.27,1.06,1.06,0,0,0,1-1.25,1.33,1.33,0,0,0-1.18-1.27A1.08,1.08,0,0,0,362.66,32.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359.2,32.83a1.3,1.3,0,0,0,1.17,1.27,1.09,1.09,0,0,0,1-1.26,1.28,1.28,0,0,0-1.17-1.26A1.08,1.08,0,0,0,359.2,32.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M355.74,32.82a1.27,1.27,0,0,0,1.15,1.27,1.11,1.11,0,0,0,1-1.26,1.27,1.27,0,0,0-1.15-1.26A1.1,1.1,0,0,0,355.74,32.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M352.28,32.82a1.23,1.23,0,0,0,1.13,1.26,1.12,1.12,0,0,0,1-1.26,1.24,1.24,0,0,0-1.14-1.25A1.12,1.12,0,0,0,352.28,32.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M283,34.63c-.17.71.19,1.26.79,1.23a1.67,1.67,0,0,0,1.38-1.33c.16-.71-.2-1.25-.8-1.22A1.69,1.69,0,0,0,283,34.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M279.51,34.81c-.18.71.17,1.26.78,1.22a1.72,1.72,0,0,0,1.39-1.33c.17-.7-.18-1.24-.78-1.21A1.71,1.71,0,0,0,279.51,34.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M276.05,35c-.18.71.16,1.25.76,1.22a1.74,1.74,0,0,0,1.41-1.34c.17-.7-.17-1.24-.76-1.21A1.76,1.76,0,0,0,276.05,35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M272.59,35.2c-.19.7.14,1.25.75,1.22a1.79,1.79,0,0,0,1.42-1.35c.18-.7-.15-1.24-.75-1.21A1.8,1.8,0,0,0,272.59,35.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M269.13,35.4c-.2.71.13,1.25.73,1.22a1.83,1.83,0,0,0,1.44-1.35c.19-.7-.13-1.24-.73-1.21A1.86,1.86,0,0,0,269.13,35.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M265.68,35.62c-.21.7.11,1.25.71,1.21a1.88,1.88,0,0,0,1.46-1.35c.2-.71-.12-1.25-.72-1.21A1.87,1.87,0,0,0,265.68,35.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M262.22,35.84c-.22.71.1,1.25.7,1.21a1.91,1.91,0,0,0,1.47-1.35c.21-.71-.1-1.25-.7-1.21A1.92,1.92,0,0,0,262.22,35.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M258.77,36.07c-.23.71.08,1.25.68,1.21a1.94,1.94,0,0,0,1.49-1.36c.22-.71-.09-1.25-.68-1.21A1.94,1.94,0,0,0,258.77,36.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M255.32,36.31c-.24.71.06,1.25.66,1.21a2,2,0,0,0,1.5-1.37c.23-.7-.06-1.24-.66-1.2A2,2,0,0,0,255.32,36.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M251.87,36.55c-.25.71,0,1.26.65,1.21A2,2,0,0,0,254,36.4c.24-.71-.05-1.25-.64-1.21A2,2,0,0,0,251.87,36.55Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M248.42,36.81c-.25.71,0,1.25.63,1.21a2.08,2.08,0,0,0,1.54-1.37c.24-.72,0-1.26-.64-1.21A2,2,0,0,0,248.42,36.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M241.53,37.34c-.27.71,0,1.26.6,1.21a2.14,2.14,0,0,0,1.56-1.38c.27-.71,0-1.25-.6-1.21A2.17,2.17,0,0,0,241.53,37.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M203.82,40.86c-.35.73-.16,1.27.43,1.21A2.6,2.6,0,0,0,206,40.63c.35-.72.16-1.26-.43-1.2A2.63,2.63,0,0,0,203.82,40.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200.41,41.23c-.36.73-.18,1.27.42,1.21A2.69,2.69,0,0,0,202.56,41c.36-.73.17-1.27-.42-1.21A2.71,2.71,0,0,0,200.41,41.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197,41.61c-.37.73-.2,1.27.4,1.2a2.7,2.7,0,0,0,1.74-1.44c.36-.73.19-1.27-.4-1.21A2.71,2.71,0,0,0,197,41.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M193.6,42c-.38.73-.2,1.28.39,1.21a2.76,2.76,0,0,0,1.75-1.45c.38-.74.2-1.27-.38-1.21A2.8,2.8,0,0,0,193.6,42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.21,42.38c-.39.74-.23,1.28.37,1.21a2.79,2.79,0,0,0,1.76-1.46c.38-.73.22-1.27-.37-1.2A2.8,2.8,0,0,0,190.21,42.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M183.42,43.19c-.4.74-.25,1.28.34,1.21a2.9,2.9,0,0,0,1.79-1.47c.39-.74.24-1.27-.35-1.2A2.89,2.89,0,0,0,183.42,43.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M176.65,44c-.42.74-.28,1.28.31,1.21a3,3,0,0,0,1.81-1.48c.41-.74.27-1.28-.32-1.21A3,3,0,0,0,176.65,44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M173.27,44.46c-.43.74-.29,1.28.3,1.21a3.06,3.06,0,0,0,1.82-1.48c.41-.75.28-1.29-.3-1.21A3,3,0,0,0,173.27,44.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M159.79,46.26c-.45.75-.34,1.29.25,1.21A3.18,3.18,0,0,0,161.9,46c.44-.75.33-1.29-.25-1.21A3.24,3.24,0,0,0,159.79,46.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M149.73,47.68c-.46.76-.37,1.3.22,1.22a3.44,3.44,0,0,0,1.89-1.52c.46-.75.36-1.3-.22-1.21A3.37,3.37,0,0,0,149.73,47.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M146.39,48.17c-.47.76-.38,1.31.2,1.22a3.46,3.46,0,0,0,1.9-1.52c.47-.76.38-1.3-.2-1.22A3.46,3.46,0,0,0,146.39,48.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M572,45.66a3.79,3.79,0,0,0,2,1.55c.56.08.58-.47.05-1.24a3.84,3.84,0,0,0-2-1.55C571.45,44.34,571.43,44.89,572,45.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M565.54,44.68a3.69,3.69,0,0,0,2,1.54c.56.08.59-.47.07-1.23a3.77,3.77,0,0,0-1.95-1.54C565.05,43.37,565,43.92,565.54,44.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M562.32,44.2a3.74,3.74,0,0,0,1.95,1.54c.56.08.59-.47.07-1.24A3.7,3.7,0,0,0,562.4,43C561.84,42.89,561.81,43.44,562.32,44.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M559.1,43.73A3.66,3.66,0,0,0,561,45.26c.56.08.6-.47.09-1.23a3.74,3.74,0,0,0-1.94-1.53C558.63,42.42,558.59,43,559.1,43.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M555.88,43.27a3.59,3.59,0,0,0,1.93,1.52c.56.08.6-.47.09-1.23A3.62,3.62,0,0,0,556,42C555.41,42,555.37,42.51,555.88,43.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M552.64,42.81a3.59,3.59,0,0,0,1.93,1.52c.56.08.61-.47.1-1.23a3.57,3.57,0,0,0-1.92-1.52C552.19,41.51,552.14,42.05,552.64,42.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M549.41,42.36a3.54,3.54,0,0,0,1.91,1.51c.57.08.62-.47.12-1.23a3.57,3.57,0,0,0-1.91-1.51C549,41.06,548.91,41.6,549.41,42.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M546.17,41.92a3.48,3.48,0,0,0,1.91,1.5c.56.08.62-.47.12-1.23a3.5,3.5,0,0,0-1.9-1.5C545.74,40.62,545.68,41.16,546.17,41.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M542.92,41.48a3.5,3.5,0,0,0,1.9,1.5c.57.07.63-.48.14-1.23a3.43,3.43,0,0,0-1.9-1.49C542.5,40.18,542.44,40.73,542.92,41.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M539.67,41.05a3.38,3.38,0,0,0,1.89,1.49c.57.08.64-.47.15-1.22a3.38,3.38,0,0,0-1.89-1.49C539.26,39.75,539.19,40.3,539.67,41.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M536.42,40.62a3.3,3.3,0,0,0,1.88,1.49c.57.08.64-.47.16-1.22a3.41,3.41,0,0,0-1.88-1.49C536,39.33,535.94,39.88,536.42,40.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M533.16,40.21A3.27,3.27,0,0,0,535,41.69c.57.07.65-.48.18-1.22A3.34,3.34,0,0,0,533.33,39C532.77,38.92,532.69,39.46,533.16,40.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M529.89,39.8a3.25,3.25,0,0,0,1.87,1.47c.57.08.65-.47.18-1.22a3.26,3.26,0,0,0-1.86-1.47C529.52,38.51,529.43,39.05,529.89,39.8Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M526.63,39.4a3.19,3.19,0,0,0,1.85,1.47c.57.07.66-.48.2-1.22a3.23,3.23,0,0,0-1.86-1.47C526.26,38.11,526.17,38.66,526.63,39.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M523.35,39a3.19,3.19,0,0,0,1.85,1.47c.57.06.66-.48.21-1.22a3.13,3.13,0,0,0-1.85-1.46C523,37.72,522.9,38.26,523.35,39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520.08,38.61a3.11,3.11,0,0,0,1.83,1.46c.57.07.67-.48.22-1.21a3.11,3.11,0,0,0-1.83-1.46C519.73,37.33,519.63,37.88,520.08,38.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M516.79,38.23a3.11,3.11,0,0,0,1.83,1.46c.57.06.68-.48.24-1.22A3.11,3.11,0,0,0,517,37C516.46,37,516.36,37.5,516.79,38.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M513.51,37.86a3.06,3.06,0,0,0,1.81,1.45c.58.06.69-.48.25-1.22a3,3,0,0,0-1.81-1.44C513.19,36.58,513.08,37.12,513.51,37.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M510.22,37.49a3,3,0,0,0,1.8,1.44c.58.07.69-.48.27-1.21a3,3,0,0,0-1.81-1.44C509.91,36.22,509.8,36.76,510.22,37.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M506.92,37.13a2.94,2.94,0,0,0,1.8,1.44c.57.06.7-.48.27-1.21a3,3,0,0,0-1.79-1.44C506.63,35.86,506.51,36.4,506.92,37.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M503.63,36.78a2.88,2.88,0,0,0,1.78,1.43c.57.06.7-.48.29-1.21a2.91,2.91,0,0,0-1.78-1.43C503.35,35.51,503.22,36.05,503.63,36.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M500.33,36.44a2.83,2.83,0,0,0,1.77,1.42c.57.06.71-.48.3-1.21a2.85,2.85,0,0,0-1.77-1.42C500.06,35.17,499.92,35.71,500.33,36.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M497,36.1a2.79,2.79,0,0,0,1.76,1.42c.58.06.72-.49.32-1.21a2.81,2.81,0,0,0-1.76-1.42C496.77,34.84,496.62,35.37,497,36.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M493.71,35.77a2.73,2.73,0,0,0,1.75,1.41c.57.06.72-.48.33-1.21A2.78,2.78,0,0,0,494,34.56C493.47,34.51,493.32,35.05,493.71,35.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M490.4,35.45a2.73,2.73,0,0,0,1.73,1.41c.58,0,.73-.49.35-1.21a2.77,2.77,0,0,0-1.74-1.41C490.17,34.19,490,34.72,490.4,35.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.08,35.13a2.69,2.69,0,0,0,1.72,1.41c.58.05.74-.49.36-1.21a2.66,2.66,0,0,0-1.72-1.4C486.87,33.87,486.71,34.41,487.08,35.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M483.76,34.82a2.6,2.6,0,0,0,1.71,1.4c.58.06.75-.49.38-1.2a2.64,2.64,0,0,0-1.72-1.4C483.56,33.57,483.39,34.11,483.76,34.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M480.44,34.52a2.56,2.56,0,0,0,1.69,1.4c.58,0,.76-.49.39-1.21a2.56,2.56,0,0,0-1.69-1.39C480.25,33.27,480.08,33.81,480.44,34.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M477.11,34.23a2.53,2.53,0,0,0,1.68,1.39c.58.05.76-.49.41-1.21A2.55,2.55,0,0,0,477.51,33C476.94,33,476.76,33.52,477.11,34.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M473.78,34a2.47,2.47,0,0,0,1.67,1.38c.58.05.77-.49.42-1.21a2.52,2.52,0,0,0-1.67-1.38C473.62,32.7,473.43,33.23,473.78,34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M470.44,33.67a2.46,2.46,0,0,0,1.66,1.38c.58.05.78-.5.44-1.21a2.46,2.46,0,0,0-1.66-1.37C470.3,32.42,470.11,33,470.44,33.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M467.1,33.4a2.38,2.38,0,0,0,1.65,1.37c.58,0,.78-.49.45-1.2a2.38,2.38,0,0,0-1.65-1.37C467,32.15,466.78,32.69,467.1,33.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M463.76,33.14a2.36,2.36,0,0,0,1.64,1.37c.58,0,.79-.5.46-1.21a2.32,2.32,0,0,0-1.63-1.36C463.65,31.89,463.44,32.43,463.76,33.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M460.42,32.88A2.33,2.33,0,0,0,462,34.25c.58,0,.8-.5.48-1.21a2.33,2.33,0,0,0-1.62-1.36C460.32,31.64,460.11,32.17,460.42,32.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M457.07,32.64A2.26,2.26,0,0,0,458.68,34c.58,0,.8-.49.49-1.2a2.25,2.25,0,0,0-1.6-1.35C457,31.39,456.77,31.93,457.07,32.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M453.72,32.4a2.24,2.24,0,0,0,1.59,1.35c.59,0,.82-.5.52-1.2a2.25,2.25,0,0,0-1.6-1.35C453.66,31.16,453.43,31.69,453.72,32.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450.37,32.17A2.16,2.16,0,0,0,452,33.51c.58.05.82-.5.52-1.2A2.17,2.17,0,0,0,450.9,31C450.32,30.93,450.08,31.46,450.37,32.17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M447,31.94a2.16,2.16,0,0,0,1.57,1.35c.58,0,.82-.51.54-1.21a2.18,2.18,0,0,0-1.56-1.34C447,30.7,446.73,31.24,447,31.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.65,31.73a2.12,2.12,0,0,0,1.55,1.34c.59,0,.84-.51.56-1.21a2.08,2.08,0,0,0-1.55-1.33C443.63,30.49,443.38,31,443.65,31.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M440.29,31.52a2,2,0,0,0,1.54,1.33c.58,0,.84-.5.57-1.2a2.06,2.06,0,0,0-1.53-1.33C440.29,30.28,440,30.82,440.29,31.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M436.93,31.32a2,2,0,0,0,1.52,1.33c.58,0,.85-.51.59-1.21a2,2,0,0,0-1.52-1.32C436.94,30.08,436.67,30.62,436.93,31.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M433.56,31.13a2,2,0,0,0,1.5,1.32c.59,0,.86-.5.61-1.2a2,2,0,0,0-1.5-1.32C433.59,29.89,433.31,30.43,433.56,31.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M430.19,30.94a1.92,1.92,0,0,0,1.49,1.32c.59,0,.87-.51.62-1.2a1.93,1.93,0,0,0-1.49-1.32C430.23,29.71,430,30.25,430.19,30.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M426.82,30.77a1.88,1.88,0,0,0,1.47,1.31c.59,0,.88-.51.64-1.2a1.91,1.91,0,0,0-1.47-1.32C426.88,29.53,426.59,30.07,426.82,30.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.44,30.6a1.87,1.87,0,0,0,1.46,1.31c.59,0,.89-.51.66-1.21a1.88,1.88,0,0,0-1.46-1.31C423.52,29.37,423.22,29.9,423.44,30.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M420.06,30.44a1.84,1.84,0,0,0,1.45,1.31c.59,0,.89-.52.67-1.21a1.83,1.83,0,0,0-1.44-1.31C420.16,29.21,419.85,29.74,420.06,30.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.68,30.29a1.79,1.79,0,0,0,1.43,1.3c.59,0,.9-.52.7-1.21a1.82,1.82,0,0,0-1.43-1.3C416.79,29.05,416.48,29.59,416.68,30.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M413.3,30.14a1.75,1.75,0,0,0,1.42,1.3c.59,0,.9-.52.71-1.21a1.78,1.78,0,0,0-1.42-1.3C413.43,28.91,413.11,29.45,413.3,30.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M409.92,30a1.71,1.71,0,0,0,1.4,1.3c.59,0,.91-.52.72-1.21a1.71,1.71,0,0,0-1.39-1.29C410.06,28.77,409.74,29.31,409.92,30Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M406.53,29.88a1.7,1.7,0,0,0,1.39,1.29c.59,0,.92-.52.74-1.21a1.68,1.68,0,0,0-1.38-1.29C406.69,28.65,406.36,29.19,406.53,29.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M403.14,29.76A1.65,1.65,0,0,0,404.51,31c.59,0,.93-.52.76-1.21a1.65,1.65,0,0,0-1.36-1.28C403.32,28.53,403,29.07,403.14,29.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M399.75,29.64a1.63,1.63,0,0,0,1.36,1.29c.59,0,.94-.53.77-1.22a1.62,1.62,0,0,0-1.35-1.28C400,28.41,399.6,29,399.75,29.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M393,29.44a1.56,1.56,0,0,0,1.32,1.28c.59,0,.95-.53.81-1.22a1.55,1.55,0,0,0-1.32-1.27C393.2,28.21,392.83,28.76,393,29.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M389.57,29.35a1.54,1.54,0,0,0,1.31,1.28c.59,0,1-.53.83-1.22a1.54,1.54,0,0,0-1.31-1.27C389.82,28.13,389.45,28.67,389.57,29.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M386.18,29.27a1.5,1.5,0,0,0,1.28,1.28c.6,0,1-.54.85-1.23A1.49,1.49,0,0,0,387,28.06C386.44,28.05,386.06,28.59,386.18,29.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M382.78,29.2a1.46,1.46,0,0,0,1.27,1.27.94.94,0,0,0,.86-1.22A1.45,1.45,0,0,0,383.64,28C383.06,28,382.67,28.52,382.78,29.2Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M379.38,29.14a1.42,1.42,0,0,0,1.25,1.26,1,1,0,0,0,.88-1.22,1.42,1.42,0,0,0-1.25-1.26C379.67,27.91,379.28,28.45,379.38,29.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376,29.08a1.4,1.4,0,0,0,1.24,1.27,1,1,0,0,0,.89-1.23,1.4,1.4,0,0,0-1.23-1.26A1,1,0,0,0,376,29.08Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M372.58,29a1.36,1.36,0,0,0,1.22,1.25,1,1,0,0,0,.91-1.22,1.38,1.38,0,0,0-1.22-1.26A1,1,0,0,0,372.58,29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue pulse" d="M369.17,29a1.34,1.34,0,0,0,1.21,1.25,1,1,0,0,0,.93-1.23,1.36,1.36,0,0,0-1.2-1.25A1,1,0,0,0,369.17,29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M365.77,29A1.32,1.32,0,0,0,367,30.22,1,1,0,0,0,367.9,29a1.31,1.31,0,0,0-1.18-1.25A1,1,0,0,0,365.77,29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M362.36,28.94a1.29,1.29,0,0,0,1.17,1.25,1,1,0,0,0,1-1.23,1.3,1.3,0,0,0-1.17-1.25A1.06,1.06,0,0,0,362.36,28.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M359,28.93a1.29,1.29,0,0,0,1.16,1.25,1.07,1.07,0,0,0,1-1.24,1.26,1.26,0,0,0-1.15-1.24A1.07,1.07,0,0,0,359,28.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M290.71,30.37c-.15.69.22,1.23.81,1.2a1.59,1.59,0,0,0,1.33-1.3c.14-.69-.23-1.22-.81-1.2A1.61,1.61,0,0,0,290.71,30.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M287.3,30.52c-.16.7.2,1.24.8,1.21a1.64,1.64,0,0,0,1.34-1.31c.15-.69-.21-1.22-.8-1.19A1.61,1.61,0,0,0,287.3,30.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M283.89,30.69c-.17.69.18,1.23.78,1.2a1.66,1.66,0,0,0,1.36-1.3c.15-.7-.2-1.23-.78-1.2A1.66,1.66,0,0,0,283.89,30.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M280.48,30.87c-.17.69.17,1.23.76,1.2a1.7,1.7,0,0,0,1.38-1.31c.16-.7-.18-1.23-.77-1.2A1.7,1.7,0,0,0,280.48,30.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M277.07,31.05c-.18.69.15,1.23.75,1.2a1.72,1.72,0,0,0,1.39-1.32c.17-.69-.16-1.22-.75-1.19A1.73,1.73,0,0,0,277.07,31.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M273.66,31.24c-.19.7.14,1.23.73,1.2a1.76,1.76,0,0,0,1.41-1.32c.18-.7-.14-1.23-.73-1.2A1.8,1.8,0,0,0,273.66,31.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M270.25,31.44c-.19.7.13,1.23.72,1.2a1.81,1.81,0,0,0,1.42-1.33c.2-.69-.12-1.22-.71-1.19A1.8,1.8,0,0,0,270.25,31.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M266.85,31.65c-.21.69.1,1.23.7,1.2A1.86,1.86,0,0,0,269,31.52c.2-.7-.11-1.23-.7-1.2A1.86,1.86,0,0,0,266.85,31.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M263.44,31.86c-.21.7.09,1.24.69,1.2a1.89,1.89,0,0,0,1.45-1.33c.21-.7-.09-1.23-.68-1.2A1.9,1.9,0,0,0,263.44,31.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M260,32.09c-.22.7.07,1.23.67,1.19A1.93,1.93,0,0,0,262.18,32c.22-.7-.08-1.24-.67-1.2A2,2,0,0,0,260,32.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M256.64,32.32c-.23.7.06,1.24.65,1.19a2,2,0,0,0,1.49-1.34c.22-.7-.07-1.23-.65-1.19A2,2,0,0,0,256.64,32.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M253.24,32.56c-.24.7,0,1.24.63,1.19a2,2,0,0,0,1.51-1.34c.23-.7,0-1.24-.64-1.19A2,2,0,0,0,253.24,32.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M249.84,32.81c-.25.7,0,1.24.62,1.19A2,2,0,0,0,252,32.65c.24-.7,0-1.23-.62-1.19A2,2,0,0,0,249.84,32.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M246.45,33.06c-.27.71,0,1.24.6,1.2a2.11,2.11,0,0,0,1.53-1.36c.25-.7,0-1.23-.6-1.19A2.09,2.09,0,0,0,246.45,33.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M243.05,33.33c-.27.7,0,1.24.59,1.19a2.1,2.1,0,0,0,1.54-1.36c.26-.7,0-1.24-.58-1.19A2.13,2.13,0,0,0,243.05,33.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M239.66,33.6c-.28.71,0,1.24.57,1.19a2.14,2.14,0,0,0,1.56-1.36c.27-.71,0-1.24-.57-1.19A2.12,2.12,0,0,0,239.66,33.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M199.11,37.49c-.37.72-.2,1.26.39,1.19a2.66,2.66,0,0,0,1.72-1.42c.37-.73.2-1.26-.39-1.2A2.7,2.7,0,0,0,199.11,37.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M185.68,39c-.4.74-.25,1.27.33,1.2a2.88,2.88,0,0,0,1.78-1.45c.39-.73.24-1.26-.34-1.19A2.9,2.9,0,0,0,185.68,39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M179,39.85c-.42.74-.28,1.28.3,1.2a2.92,2.92,0,0,0,1.8-1.46c.41-.73.27-1.26-.31-1.19A3,3,0,0,0,179,39.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M172.31,40.7c-.43.74-.31,1.28.28,1.21a3.07,3.07,0,0,0,1.81-1.48c.43-.73.3-1.27-.28-1.2A3.14,3.14,0,0,0,172.31,40.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M162.32,42c-.45.75-.34,1.29.24,1.21a3.22,3.22,0,0,0,1.84-1.49c.45-.74.34-1.28-.24-1.2A3.2,3.2,0,0,0,162.32,42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M543.4,37.68a3.52,3.52,0,0,0,1.9,1.49c.56.07.61-.47.12-1.22a3.43,3.43,0,0,0-1.9-1.49C543,36.38,542.91,36.93,543.4,37.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M540.19,37.25a3.42,3.42,0,0,0,1.88,1.48c.56.08.62-.47.14-1.21A3.46,3.46,0,0,0,540.32,36C539.76,36,539.7,36.51,540.19,37.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M520.82,34.84a3.12,3.12,0,0,0,1.83,1.45c.56.07.65-.47.2-1.21A3.12,3.12,0,0,0,521,33.64C520.46,33.57,520.37,34.11,520.82,34.84Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M517.57,34.46a3.15,3.15,0,0,0,1.83,1.45c.56.06.66-.48.21-1.21a3.11,3.11,0,0,0-1.82-1.44C517.23,33.2,517.13,33.73,517.57,34.46Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M514.33,34.09a3,3,0,0,0,1.81,1.44c.57.07.67-.47.23-1.2a3.1,3.1,0,0,0-1.81-1.44C514,32.83,513.89,33.37,514.33,34.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M511.08,33.73a3,3,0,0,0,1.8,1.43c.57.07.67-.47.24-1.2a3,3,0,0,0-1.8-1.43C510.76,32.47,510.65,33,511.08,33.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M504.57,33a2.93,2.93,0,0,0,1.78,1.42c.57.06.69-.48.27-1.2a2.92,2.92,0,0,0-1.78-1.42C504.28,31.77,504.16,32.31,504.57,33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M501.31,32.69a2.86,2.86,0,0,0,1.77,1.41c.57.06.69-.48.28-1.2a2.86,2.86,0,0,0-1.77-1.41C501,31.43,500.91,32,501.31,32.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M498.05,32.35a2.82,2.82,0,0,0,1.76,1.41c.57.06.7-.48.29-1.2a2.8,2.8,0,0,0-1.75-1.4C497.78,31.1,497.65,31.63,498.05,32.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M494.79,32a2.77,2.77,0,0,0,1.74,1.41c.57,0,.71-.48.31-1.2a2.79,2.79,0,0,0-1.75-1.4C494.53,30.78,494.39,31.31,494.79,32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M491.52,31.7a2.68,2.68,0,0,0,1.73,1.4c.57.06.71-.48.32-1.2a2.77,2.77,0,0,0-1.73-1.39C491.28,30.46,491.13,31,491.52,31.7Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M488.24,31.39A2.68,2.68,0,0,0,490,32.78c.57.06.73-.48.34-1.19a2.65,2.65,0,0,0-1.72-1.39C488,30.15,487.86,30.68,488.24,31.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M485,31.09a2.62,2.62,0,0,0,1.71,1.38c.57.06.72-.48.35-1.19a2.64,2.64,0,0,0-1.71-1.38C484.75,29.84,484.6,30.38,485,31.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M481.69,30.79a2.58,2.58,0,0,0,1.7,1.38c.57,0,.73-.48.36-1.2a2.59,2.59,0,0,0-1.69-1.37C481.49,29.55,481.33,30.08,481.69,30.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.41,30.5a2.54,2.54,0,0,0,1.68,1.37c.57.05.74-.48.38-1.19a2.54,2.54,0,0,0-1.68-1.37C478.22,29.26,478.05,29.79,478.41,30.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M475.12,30.21a2.5,2.5,0,0,0,1.67,1.37c.58.05.75-.48.4-1.19A2.5,2.5,0,0,0,475.52,29C475,29,474.78,29.51,475.12,30.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M471.84,29.94a2.46,2.46,0,0,0,1.65,1.36c.58.05.76-.48.41-1.19a2.5,2.5,0,0,0-1.65-1.36C471.68,28.7,471.5,29.23,471.84,29.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M468.55,29.67A2.42,2.42,0,0,0,470.19,31c.57,0,.76-.49.42-1.19A2.42,2.42,0,0,0,469,28.48C468.4,28.44,468.21,29,468.55,29.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M465.25,29.41a2.37,2.37,0,0,0,1.63,1.35c.58,0,.77-.49.44-1.19a2.39,2.39,0,0,0-1.63-1.35C465.12,28.18,464.93,28.71,465.25,29.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M462,29.16a2.33,2.33,0,0,0,1.61,1.34c.58.05.78-.49.46-1.19A2.34,2.34,0,0,0,462.41,28C461.84,27.93,461.64,28.46,462,29.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.66,28.91a2.27,2.27,0,0,0,1.6,1.34c.58.05.79-.49.47-1.19a2.3,2.3,0,0,0-1.6-1.34C458.56,27.68,458.35,28.21,458.66,28.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455.36,28.67A2.22,2.22,0,0,0,457,30c.57,0,.79-.49.48-1.19a2.24,2.24,0,0,0-1.59-1.33C455.27,27.45,455.06,28,455.36,28.67Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M452.05,28.44a2.22,2.22,0,0,0,1.58,1.33c.58,0,.8-.49.5-1.19a2.18,2.18,0,0,0-1.58-1.32C452,27.22,451.76,27.74,452.05,28.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M448.75,28.22a2.12,2.12,0,0,0,1.56,1.32c.58,0,.81-.49.51-1.18A2.17,2.17,0,0,0,449.26,27C448.69,27,448.46,27.52,448.75,28.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M445.44,28A2.1,2.1,0,0,0,447,29.32c.57,0,.81-.49.53-1.19A2.12,2.12,0,0,0,446,26.82C445.4,26.78,445.16,27.31,445.44,28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M442.13,27.79a2.08,2.08,0,0,0,1.53,1.32c.58,0,.82-.5.55-1.19a2.08,2.08,0,0,0-1.53-1.31C442.1,26.57,441.86,27.1,442.13,27.79Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M438.81,27.59a2.05,2.05,0,0,0,1.52,1.32c.58,0,.83-.5.56-1.19a2,2,0,0,0-1.51-1.31C438.81,26.37,438.55,26.9,438.81,27.59Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M435.5,27.4a2,2,0,0,0,1.5,1.31c.58,0,.84-.5.58-1.19a2,2,0,0,0-1.5-1.31C435.5,26.18,435.24,26.71,435.5,27.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M415.55,26.4A1.76,1.76,0,0,0,417,27.68c.59,0,.89-.51.68-1.19a1.77,1.77,0,0,0-1.41-1.28C415.66,25.19,415.35,25.72,415.55,26.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M412.22,26.26a1.75,1.75,0,0,0,1.4,1.28c.58,0,.89-.51.69-1.19a1.74,1.74,0,0,0-1.39-1.28C412.34,25.05,412,25.58,412.22,26.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1 blue" d="M368.77,25.16A1.32,1.32,0,0,0,370,26.39a1,1,0,0,0,.92-1.21A1.34,1.34,0,0,0,369.68,24,1,1,0,0,0,368.77,25.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M298.24,26.21c-.12.68.25,1.21.83,1.19a1.51,1.51,0,0,0,1.28-1.27c.12-.68-.25-1.21-.83-1.19A1.53,1.53,0,0,0,298.24,26.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M294.88,26.35c-.13.68.23,1.21.82,1.19A1.56,1.56,0,0,0,297,26.26c.13-.68-.24-1.21-.82-1.18A1.54,1.54,0,0,0,294.88,26.35Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M291.52,26.49c-.14.68.21,1.22.8,1.19a1.58,1.58,0,0,0,1.31-1.28c.14-.68-.22-1.2-.8-1.18A1.59,1.59,0,0,0,291.52,26.49Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M288.16,26.65c-.15.68.2,1.21.79,1.18a1.59,1.59,0,0,0,1.32-1.28c.15-.68-.2-1.21-.78-1.18A1.63,1.63,0,0,0,288.16,26.65Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M284.8,26.81c-.16.68.18,1.21.77,1.18a1.62,1.62,0,0,0,1.34-1.28c.16-.68-.19-1.21-.76-1.18A1.67,1.67,0,0,0,284.8,26.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M281.44,27c-.17.68.17,1.21.76,1.18a1.67,1.67,0,0,0,1.35-1.29c.17-.68-.17-1.21-.75-1.18A1.7,1.7,0,0,0,281.44,27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M278.09,27.16c-.18.68.15,1.21.73,1.18A1.71,1.71,0,0,0,280.2,27c.17-.68-.16-1.2-.74-1.17A1.7,1.7,0,0,0,278.09,27.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M274.73,27.34c-.19.69.13,1.22.72,1.18a1.73,1.73,0,0,0,1.39-1.3c.18-.68-.14-1.2-.72-1.17A1.75,1.75,0,0,0,274.73,27.34Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M271.37,27.54c-.19.68.12,1.21.71,1.18a1.79,1.79,0,0,0,1.4-1.31c.19-.68-.12-1.21-.7-1.17A1.78,1.78,0,0,0,271.37,27.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M268,27.74c-.21.68.1,1.21.68,1.18a1.82,1.82,0,0,0,1.43-1.31c.2-.69-.11-1.21-.69-1.18A1.84,1.84,0,0,0,268,27.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M264.66,28c-.21.68.09,1.21.67,1.18a1.86,1.86,0,0,0,1.44-1.32c.21-.68-.09-1.21-.67-1.17A1.88,1.88,0,0,0,264.66,28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M261.31,28.16c-.22.69.07,1.22.65,1.18A1.89,1.89,0,0,0,263.42,28c.22-.69-.08-1.22-.66-1.18A1.94,1.94,0,0,0,261.31,28.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M258,28.39c-.23.69.05,1.22.63,1.18a1.91,1.91,0,0,0,1.47-1.32c.23-.69-.05-1.22-.63-1.18A1.94,1.94,0,0,0,258,28.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M254.61,28.62c-.24.69,0,1.22.62,1.18a2,2,0,0,0,1.48-1.32c.24-.69,0-1.22-.62-1.18A2,2,0,0,0,254.61,28.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M251.26,28.86c-.25.7,0,1.23.6,1.18a2,2,0,0,0,1.5-1.33c.25-.69,0-1.21-.6-1.17A2,2,0,0,0,251.26,28.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M247.91,29.11c-.26.7,0,1.23.59,1.18A2,2,0,0,0,250,29c.26-.7,0-1.22-.59-1.18A2.07,2.07,0,0,0,247.91,29.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M244.56,29.37c-.27.69,0,1.22.57,1.18a2.1,2.1,0,0,0,1.53-1.34c.27-.7,0-1.22-.57-1.18A2.11,2.11,0,0,0,244.56,29.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M241.22,29.63c-.28.7,0,1.23.55,1.18a2.12,2.12,0,0,0,1.55-1.34c.27-.7,0-1.22-.56-1.18A2.14,2.14,0,0,0,241.22,29.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M237.87,29.91c-.28.69,0,1.22.54,1.18A2.18,2.18,0,0,0,240,29.73c.28-.69,0-1.22-.54-1.17A2.2,2.2,0,0,0,237.87,29.91Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M234.53,30.19c-.29.7-.06,1.23.52,1.18A2.22,2.22,0,0,0,236.63,30c.28-.7.05-1.22-.53-1.17A2.21,2.21,0,0,0,234.53,30.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M231.19,30.48c-.3.7-.07,1.23.51,1.17a2.27,2.27,0,0,0,1.59-1.36c.29-.7.06-1.22-.51-1.17A2.25,2.25,0,0,0,231.19,30.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M227.85,30.77c-.31.7-.09,1.23.49,1.18A2.29,2.29,0,0,0,230,30.58c.3-.7.08-1.22-.5-1.17A2.31,2.31,0,0,0,227.85,30.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M224.51,31.07c-.32.71-.1,1.24.48,1.19a2.37,2.37,0,0,0,1.62-1.38c.31-.7.09-1.22-.48-1.17A2.36,2.36,0,0,0,224.51,31.07Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M221.18,31.39c-.33.7-.12,1.23.46,1.18a2.43,2.43,0,0,0,1.63-1.38c.32-.71.11-1.23-.46-1.18A2.4,2.4,0,0,0,221.18,31.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M211.18,32.37c-.35.71-.16,1.24.42,1.18a2.52,2.52,0,0,0,1.67-1.39c.34-.71.16-1.24-.42-1.18A2.56,2.56,0,0,0,211.18,32.37Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M207.85,32.71c-.35.71-.17,1.24.41,1.18a2.57,2.57,0,0,0,1.68-1.4c.35-.71.17-1.23-.4-1.18A2.61,2.61,0,0,0,207.85,32.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M204.53,33.06c-.36.71-.19,1.24.39,1.18a2.62,2.62,0,0,0,1.7-1.4c.35-.71.18-1.24-.4-1.18A2.63,2.63,0,0,0,204.53,33.06Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M201.21,33.42c-.37.71-.21,1.24.37,1.18a2.63,2.63,0,0,0,1.71-1.41c.37-.71.2-1.24-.38-1.18A2.66,2.66,0,0,0,201.21,33.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M197.89,33.78c-.38.72-.22,1.25.36,1.19A2.71,2.71,0,0,0,200,33.55c.37-.71.21-1.24-.36-1.18A2.67,2.67,0,0,0,197.89,33.78Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M194.57,34.16c-.39.71-.23,1.25.35,1.18a2.75,2.75,0,0,0,1.73-1.42c.38-.72.22-1.25-.35-1.18A2.75,2.75,0,0,0,194.57,34.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M191.25,34.54c-.39.72-.24,1.25.34,1.18a2.74,2.74,0,0,0,1.74-1.42c.39-.72.24-1.25-.33-1.19A2.82,2.82,0,0,0,191.25,34.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M187.94,34.92c-.4.73-.26,1.26.32,1.19A2.84,2.84,0,0,0,190,34.68c.39-.72.25-1.25-.32-1.19A2.9,2.9,0,0,0,187.94,34.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M184.63,35.32c-.41.72-.27,1.26.31,1.19a2.93,2.93,0,0,0,1.77-1.44c.4-.72.26-1.25-.31-1.19A2.92,2.92,0,0,0,184.63,35.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M181.32,35.72c-.41.73-.28,1.26.3,1.19a3,3,0,0,0,1.78-1.44c.41-.73.27-1.26-.3-1.19A3,3,0,0,0,181.32,35.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M178,36.13c-.42.73-.29,1.27.28,1.19a3,3,0,0,0,1.79-1.45c.42-.72.29-1.25-.28-1.18A3,3,0,0,0,178,36.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M505.45,29.31a2.93,2.93,0,0,0,1.77,1.4c.56.06.67-.47.25-1.19a2.94,2.94,0,0,0-1.78-1.4C505.14,28.06,505,28.59,505.45,29.31Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M502.23,29a2.89,2.89,0,0,0,1.76,1.4c.57.06.68-.48.26-1.19a2.88,2.88,0,0,0-1.76-1.4C501.93,27.72,501.82,28.25,502.23,29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M499,28.64A2.82,2.82,0,0,0,500.76,30c.56.06.68-.47.27-1.19a2.85,2.85,0,0,0-1.75-1.39C498.73,27.4,498.6,27.93,499,28.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M495.79,28.32a2.79,2.79,0,0,0,1.74,1.38c.56.06.69-.47.28-1.18a2.81,2.81,0,0,0-1.74-1.39C495.52,27.08,495.39,27.61,495.79,28.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M492.56,28a2.72,2.72,0,0,0,1.73,1.38c.56.06.7-.47.3-1.18a2.74,2.74,0,0,0-1.73-1.38C492.3,26.76,492.17,27.29,492.56,28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M489.33,27.69a2.74,2.74,0,0,0,1.72,1.38c.56.05.7-.48.31-1.19a2.73,2.73,0,0,0-1.71-1.37C489.09,26.46,489,27,489.33,27.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M486.1,27.39a2.66,2.66,0,0,0,1.71,1.37c.56,0,.7-.48.32-1.18a2.63,2.63,0,0,0-1.7-1.37C485.87,26.16,485.72,26.68,486.1,27.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M482.87,27.09a2.62,2.62,0,0,0,1.69,1.37c.56.05.72-.48.34-1.18a2.6,2.6,0,0,0-1.69-1.36C482.65,25.87,482.5,26.39,482.87,27.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M479.63,26.81a2.57,2.57,0,0,0,1.68,1.36c.57,0,.72-.48.36-1.18A2.56,2.56,0,0,0,480,25.63C479.43,25.58,479.27,26.11,479.63,26.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M476.39,26.52a2.55,2.55,0,0,0,1.67,1.36c.56.05.73-.48.37-1.18a2.54,2.54,0,0,0-1.67-1.35C476.2,25.3,476,25.83,476.39,26.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M473.15,26.25a2.48,2.48,0,0,0,1.66,1.35c.56,0,.73-.48.38-1.18a2.49,2.49,0,0,0-1.65-1.34C473,25,472.8,25.56,473.15,26.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M469.91,26a2.44,2.44,0,0,0,1.64,1.34c.56.05.74-.48.4-1.18a2.48,2.48,0,0,0-1.64-1.34C469.75,24.77,469.57,25.29,469.91,26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M466.66,25.73a2.4,2.4,0,0,0,1.63,1.34c.56,0,.75-.49.41-1.18a2.4,2.4,0,0,0-1.62-1.34C466.51,24.51,466.33,25,466.66,25.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M463.41,25.47A2.36,2.36,0,0,0,465,26.81c.56,0,.75-.49.42-1.18a2.38,2.38,0,0,0-1.61-1.33C463.28,24.26,463.09,24.78,463.41,25.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M460.16,25.23a2.32,2.32,0,0,0,1.6,1.33c.57,0,.77-.49.44-1.18a2.29,2.29,0,0,0-1.59-1.32C460,24,459.85,24.54,460.16,25.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M456.91,25a2.29,2.29,0,0,0,1.58,1.33c.57,0,.78-.49.46-1.18a2.25,2.25,0,0,0-1.58-1.32C456.81,23.78,456.6,24.3,456.91,25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M443.87,24.12a2.11,2.11,0,0,0,1.53,1.3c.57,0,.8-.49.52-1.17a2.06,2.06,0,0,0-1.53-1.3C443.83,22.91,443.6,23.43,443.87,24.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M440.61,23.92a2,2,0,0,0,1.51,1.29c.57,0,.81-.49.54-1.17a2,2,0,0,0-1.51-1.29C440.58,22.71,440.34,23.24,440.61,23.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M437.34,23.72a2,2,0,0,0,1.5,1.3c.57,0,.82-.5.56-1.18a2,2,0,0,0-1.5-1.28C437.33,22.52,437.08,23,437.34,23.72Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.43,22.58a1.75,1.75,0,0,0,1.39,1.26c.57,0,.87-.51.67-1.18a1.75,1.75,0,0,0-1.4-1.25C414.53,21.38,414.23,21.91,414.43,22.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M411.15,22.44a1.7,1.7,0,0,0,1.38,1.26c.57,0,.87-.51.68-1.17a1.74,1.74,0,0,0-1.38-1.26C411.26,21.25,411,21.77,411.15,22.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M302.26,22.28c-.12.67.25,1.2.83,1.17a1.45,1.45,0,0,0,1.24-1.24c.11-.67-.26-1.18-.83-1.16A1.45,1.45,0,0,0,302.26,22.28Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M295.64,22.54c-.14.67.22,1.2.8,1.17a1.51,1.51,0,0,0,1.28-1.25c.12-.67-.23-1.19-.81-1.16A1.51,1.51,0,0,0,295.64,22.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M292.33,22.68c-.14.67.21,1.2.79,1.17a1.56,1.56,0,0,0,1.29-1.25c.14-.67-.22-1.19-.79-1.17A1.57,1.57,0,0,0,292.33,22.68Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M289,22.83c-.15.67.19,1.2.77,1.17a1.59,1.59,0,0,0,1.31-1.26c.15-.67-.2-1.19-.77-1.16A1.61,1.61,0,0,0,289,22.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M285.72,23c-.17.67.17,1.19.75,1.17a1.62,1.62,0,0,0,1.32-1.27c.16-.67-.18-1.19-.75-1.16A1.62,1.62,0,0,0,285.72,23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M282.41,23.15c-.17.68.16,1.2.73,1.17a1.67,1.67,0,0,0,1.35-1.27c.16-.67-.17-1.19-.74-1.16A1.66,1.66,0,0,0,282.41,23.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M279.1,23.33c-.18.67.14,1.19.72,1.16a1.68,1.68,0,0,0,1.36-1.27c.17-.67-.15-1.19-.72-1.16A1.71,1.71,0,0,0,279.1,23.33Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M275.79,23.51c-.18.67.13,1.19.71,1.16a1.73,1.73,0,0,0,1.37-1.28c.18-.67-.13-1.19-.7-1.15A1.73,1.73,0,0,0,275.79,23.51Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M272.49,23.69c-.2.68.11,1.2.69,1.17a1.8,1.8,0,0,0,1.39-1.28c.19-.68-.12-1.19-.69-1.16A1.77,1.77,0,0,0,272.49,23.69Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M269.18,23.89c-.2.67.1,1.2.67,1.16a1.79,1.79,0,0,0,1.41-1.28c.2-.68-.1-1.19-.67-1.16A1.8,1.8,0,0,0,269.18,23.89Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M265.88,24.09c-.22.68.08,1.2.65,1.16A1.8,1.8,0,0,0,268,24c.21-.67-.08-1.19-.65-1.15A1.83,1.83,0,0,0,265.88,24.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M262.58,24.3c-.23.68.06,1.2.63,1.16a1.85,1.85,0,0,0,1.44-1.29c.22-.68-.07-1.19-.64-1.16A1.87,1.87,0,0,0,262.58,24.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M259.27,24.52c-.23.68.05,1.2.62,1.16a1.92,1.92,0,0,0,1.46-1.3c.22-.68-.06-1.19-.63-1.15A1.89,1.89,0,0,0,259.27,24.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M256,24.75c-.24.68,0,1.2.61,1.16A2,2,0,0,0,258,24.6c.24-.68,0-1.19-.6-1.15A2,2,0,0,0,256,24.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M252.67,25c-.25.68,0,1.2.59,1.16a2,2,0,0,0,1.48-1.31c.24-.68,0-1.19-.59-1.15A2,2,0,0,0,252.67,25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M249.37,25.22c-.26.68,0,1.21.57,1.16a2,2,0,0,0,1.5-1.31c.25-.68,0-1.2-.58-1.16A2.05,2.05,0,0,0,249.37,25.22Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M246.07,25.47c-.27.68,0,1.21.56,1.16a2.05,2.05,0,0,0,1.51-1.32c.26-.68,0-1.2-.56-1.15A2.06,2.06,0,0,0,246.07,25.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M242.77,25.73c-.27.68,0,1.2.54,1.16a2.12,2.12,0,0,0,1.53-1.33c.27-.68,0-1.2-.54-1.15A2.1,2.1,0,0,0,242.77,25.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M239.47,26c-.28.69,0,1.21.53,1.16a2.11,2.11,0,0,0,1.54-1.33c.28-.68,0-1.2-.53-1.15A2.16,2.16,0,0,0,239.47,26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M236.18,26.26c-.29.69-.07,1.21.51,1.17a2.23,2.23,0,0,0,1.56-1.34c.28-.69.05-1.21-.52-1.16A2.2,2.2,0,0,0,236.18,26.26Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M232.88,26.54c-.3.69-.08,1.22.5,1.17A2.25,2.25,0,0,0,235,26.36c.29-.68.07-1.2-.5-1.15A2.22,2.22,0,0,0,232.88,26.54Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M229.59,26.83c-.31.69-.09,1.21.48,1.16a2.31,2.31,0,0,0,1.59-1.34c.3-.69.08-1.21-.49-1.16A2.27,2.27,0,0,0,229.59,26.83Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M223,27.42c-.32.7-.12,1.22.46,1.17a2.36,2.36,0,0,0,1.61-1.36c.32-.69.12-1.21-.45-1.16A2.4,2.4,0,0,0,223,27.42Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M219.72,27.73c-.34.7-.14,1.23.43,1.17a2.37,2.37,0,0,0,1.63-1.36c.33-.7.13-1.22-.44-1.16A2.4,2.4,0,0,0,219.72,27.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M213.14,28.38c-.34.7-.16,1.22.41,1.16a2.51,2.51,0,0,0,1.66-1.37c.34-.7.15-1.22-.41-1.16A2.48,2.48,0,0,0,213.14,28.38Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M209.86,28.71c-.36.7-.18,1.23.39,1.17a2.57,2.57,0,0,0,1.67-1.38c.35-.7.17-1.22-.4-1.17A2.55,2.55,0,0,0,209.86,28.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M206.57,29.05c-.36.7-.19,1.23.38,1.17a2.59,2.59,0,0,0,1.69-1.39c.35-.7.18-1.22-.39-1.16A2.63,2.63,0,0,0,206.57,29.05Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M203.29,29.39c-.37.71-.2,1.24.37,1.18a2.64,2.64,0,0,0,1.69-1.39c.37-.71.2-1.23-.36-1.17A2.67,2.67,0,0,0,203.29,29.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M200,29.75c-.37.71-.21,1.23.36,1.17a2.68,2.68,0,0,0,1.7-1.39c.37-.71.22-1.23-.35-1.17A2.69,2.69,0,0,0,200,29.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M196.74,30.11c-.39.71-.23,1.24.34,1.18a2.78,2.78,0,0,0,1.72-1.41c.37-.71.22-1.23-.34-1.17A2.73,2.73,0,0,0,196.74,30.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M190.19,30.86c-.4.71-.26,1.24.31,1.17a2.84,2.84,0,0,0,1.75-1.41c.39-.71.25-1.24-.32-1.17A2.82,2.82,0,0,0,190.19,30.86Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M490.35,24a2.76,2.76,0,0,0,1.71,1.37c.56.05.69-.48.29-1.18a2.74,2.74,0,0,0-1.71-1.36C490.09,22.8,490,23.33,490.35,24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M487.16,23.73a2.68,2.68,0,0,0,1.7,1.35c.56.06.69-.47.3-1.17a2.63,2.63,0,0,0-1.69-1.35C486.92,22.51,486.78,23,487.16,23.73Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M484,23.44a2.64,2.64,0,0,0,1.69,1.35c.55.05.7-.48.32-1.17a2.66,2.66,0,0,0-1.69-1.35C483.74,22.22,483.6,22.74,484,23.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M480.78,23.15a2.61,2.61,0,0,0,1.67,1.35c.56.05.71-.48.33-1.17A2.59,2.59,0,0,0,481.11,22C480.56,21.94,480.41,22.46,480.78,23.15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M477.58,22.88a2.57,2.57,0,0,0,1.67,1.34c.55,0,.71-.48.34-1.17a2.56,2.56,0,0,0-1.66-1.34C477.38,21.67,477.22,22.19,477.58,22.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M474.39,22.61A2.51,2.51,0,0,0,476,23.94c.55,0,.71-.48.36-1.16a2.49,2.49,0,0,0-1.65-1.33C474.2,21.4,474,21.92,474.39,22.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M468,22.09a2.4,2.4,0,0,0,1.62,1.32c.56.05.73-.48.39-1.16a2.39,2.39,0,0,0-1.62-1.32C467.83,20.89,467.65,21.4,468,22.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M461.58,21.6a2.33,2.33,0,0,0,1.6,1.31c.56,0,.74-.48.42-1.16a2.35,2.35,0,0,0-1.6-1.31C461.45,20.4,461.26,20.92,461.58,21.6Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M458.38,21.36A2.26,2.26,0,0,0,460,22.67c.56,0,.75-.48.43-1.16a2.29,2.29,0,0,0-1.58-1.3C458.26,20.17,458.06,20.68,458.38,21.36Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M455.17,21.14a2.24,2.24,0,0,0,1.57,1.3c.56,0,.76-.48.44-1.16a2.24,2.24,0,0,0-1.56-1.3C455.06,19.94,454.86,20.46,455.17,21.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M432.66,19.74A2,2,0,0,0,434.13,21c.56,0,.81-.48.56-1.15a2,2,0,0,0-1.47-1.26C432.66,18.56,432.41,19.07,432.66,19.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M429.44,19.57a1.92,1.92,0,0,0,1.45,1.26c.57,0,.82-.49.58-1.16A1.93,1.93,0,0,0,430,18.42C429.46,18.39,429.2,18.9,429.44,19.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423,19.25a1.85,1.85,0,0,0,1.43,1.25c.56,0,.83-.49.6-1.16a1.85,1.85,0,0,0-1.42-1.24C423,18.07,422.77,18.58,423,19.25Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M419.77,19.1a1.77,1.77,0,0,0,1.4,1.24c.57,0,.84-.49.62-1.15a1.79,1.79,0,0,0-1.4-1.24C419.83,17.92,419.55,18.44,419.77,19.1Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M416.54,19a1.77,1.77,0,0,0,1.39,1.25c.56,0,.85-.5.64-1.16a1.77,1.77,0,0,0-1.39-1.23C416.62,17.78,416.33,18.29,416.54,19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M296.39,18.81c-.13.65.22,1.17.79,1.14a1.49,1.49,0,0,0,1.26-1.22c.13-.66-.23-1.17-.79-1.15A1.52,1.52,0,0,0,296.39,18.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M293.14,18.94c-.15.66.2,1.17.77,1.15a1.52,1.52,0,0,0,1.27-1.23c.14-.66-.21-1.17-.77-1.14A1.52,1.52,0,0,0,293.14,18.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M289.88,19.09c-.15.65.19,1.17.75,1.14a1.57,1.57,0,0,0,1.3-1.23c.14-.66-.2-1.17-.76-1.14A1.55,1.55,0,0,0,289.88,19.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M286.62,19.24c-.16.66.17,1.17.74,1.14a1.59,1.59,0,0,0,1.31-1.24c.15-.65-.18-1.16-.74-1.14A1.61,1.61,0,0,0,286.62,19.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M283.37,19.4c-.17.66.15,1.17.72,1.14a1.63,1.63,0,0,0,1.32-1.24c.16-.66-.16-1.17-.72-1.14A1.64,1.64,0,0,0,283.37,19.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M280.11,19.56c-.18.66.14,1.18.71,1.15a1.68,1.68,0,0,0,1.34-1.25c.17-.66-.15-1.17-.71-1.14A1.67,1.67,0,0,0,280.11,19.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M276.85,19.74c-.18.66.12,1.17.69,1.14a1.7,1.7,0,0,0,1.36-1.25c.18-.66-.13-1.17-.69-1.14A1.72,1.72,0,0,0,276.85,19.74Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M273.6,19.92c-.2.66.1,1.17.67,1.14a1.73,1.73,0,0,0,1.37-1.26c.19-.66-.11-1.17-.67-1.14A1.75,1.75,0,0,0,273.6,19.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M270.34,20.11c-.2.66.09,1.17.66,1.14A1.77,1.77,0,0,0,272.39,20c.2-.67-.1-1.17-.66-1.14A1.78,1.78,0,0,0,270.34,20.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M267.09,20.3c-.22.67.07,1.18.64,1.15a1.83,1.83,0,0,0,1.4-1.27c.21-.67-.08-1.17-.64-1.14A1.81,1.81,0,0,0,267.09,20.3Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M263.83,20.5c-.22.67.06,1.18.63,1.15a1.86,1.86,0,0,0,1.42-1.27c.21-.67-.07-1.18-.63-1.14A1.85,1.85,0,0,0,263.83,20.5Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M260.58,20.71c-.23.67,0,1.19.61,1.15a1.89,1.89,0,0,0,1.43-1.28c.23-.66-.05-1.17-.61-1.14A1.91,1.91,0,0,0,260.58,20.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M257.32,20.93c-.24.67,0,1.19.6,1.15a1.94,1.94,0,0,0,1.45-1.29c.23-.66,0-1.17-.6-1.13A1.91,1.91,0,0,0,257.32,20.93Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M254.07,21.16c-.25.67,0,1.18.58,1.14A2,2,0,0,0,256.11,21c.25-.67,0-1.18-.57-1.14A2,2,0,0,0,254.07,21.16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M250.82,21.39c-.26.67,0,1.19.56,1.15a2,2,0,0,0,1.48-1.3c.25-.67,0-1.18-.56-1.14A2,2,0,0,0,250.82,21.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M247.57,21.63c-.27.67,0,1.19.54,1.15a2.07,2.07,0,0,0,1.5-1.3c.26-.67,0-1.18-.55-1.14A2,2,0,0,0,247.57,21.63Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M244.32,21.88c-.28.67,0,1.19.53,1.14a2.08,2.08,0,0,0,1.51-1.3c.26-.67,0-1.18-.54-1.14A2.07,2.07,0,0,0,244.32,21.88Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M241.06,22.13c-.28.68-.05,1.19.52,1.15A2.14,2.14,0,0,0,243.11,22c.27-.67,0-1.18-.52-1.14A2.16,2.16,0,0,0,241.06,22.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M237.81,22.4c-.28.67-.06,1.19.51,1.14a2.17,2.17,0,0,0,1.54-1.31c.28-.68,0-1.19-.51-1.14A2.17,2.17,0,0,0,237.81,22.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M234.57,22.66c-.3.69-.08,1.2.48,1.15a2.21,2.21,0,0,0,1.56-1.32c.29-.67.07-1.18-.49-1.14A2.21,2.21,0,0,0,234.57,22.66Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M228.07,23.23c-.31.68-.11,1.2.46,1.15a2.32,2.32,0,0,0,1.58-1.33c.31-.69.1-1.2-.46-1.15A2.29,2.29,0,0,0,228.07,23.23Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M224.82,23.52c-.32.68-.12,1.2.45,1.15a2.32,2.32,0,0,0,1.59-1.34c.32-.68.12-1.19-.44-1.14A2.36,2.36,0,0,0,224.82,23.52Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M221.58,23.82c-.33.68-.14,1.2.43,1.15a2.42,2.42,0,0,0,1.61-1.34c.32-.69.13-1.2-.43-1.15A2.39,2.39,0,0,0,221.58,23.82Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M218.34,24.12c-.34.69-.16,1.21.41,1.16a2.45,2.45,0,0,0,1.62-1.35c.33-.69.15-1.2-.41-1.15A2.41,2.41,0,0,0,218.34,24.12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M215.09,24.44c-.34.69-.16,1.21.4,1.15a2.46,2.46,0,0,0,1.64-1.35c.34-.69.16-1.2-.4-1.15A2.5,2.5,0,0,0,215.09,24.44Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M211.85,24.76c-.35.69-.18,1.21.39,1.15a2.51,2.51,0,0,0,1.65-1.35c.34-.7.17-1.21-.39-1.16A2.53,2.53,0,0,0,211.85,24.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M208.61,25.09c-.36.69-.19,1.21.37,1.15a2.56,2.56,0,0,0,1.67-1.36c.35-.7.18-1.21-.38-1.15A2.56,2.56,0,0,0,208.61,25.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M478.7,19.27a2.57,2.57,0,0,0,1.66,1.32c.55.05.69-.47.32-1.15A2.51,2.51,0,0,0,479,18.12C478.48,18.07,478.34,18.59,478.7,19.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M475.55,19a2.52,2.52,0,0,0,1.64,1.32c.55.05.7-.47.34-1.15a2.49,2.49,0,0,0-1.64-1.31C475.34,17.81,475.19,18.32,475.55,19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M472.4,18.75A2.49,2.49,0,0,0,474,20.06c.55,0,.7-.47.35-1.15a2.48,2.48,0,0,0-1.63-1.31C472.2,17.56,472,18.07,472.4,18.75Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M450.28,17.13a2.16,2.16,0,0,0,1.53,1.27c.55,0,.76-.47.45-1.14A2.17,2.17,0,0,0,450.73,16C450.18,16,450,16.46,450.28,17.13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M361.18,14a1.2,1.2,0,0,0,1.1,1.16,1,1,0,0,0,.91-1.15,1.21,1.21,0,0,0-1.1-1.15A1,1,0,0,0,361.18,14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M354.79,14a1.16,1.16,0,0,0,1.06,1.15,1,1,0,0,0,.94-1.15,1.16,1.16,0,0,0-1.06-1.15A1,1,0,0,0,354.79,14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M351.59,14a1.13,1.13,0,0,0,1.05,1.15,1,1,0,0,0,1-1.15,1.14,1.14,0,0,0-1.06-1.15A1,1,0,0,0,351.59,14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M303.55,14.9c-.11.64.25,1.15.81,1.13a1.42,1.42,0,0,0,1.21-1.2c.1-.64-.26-1.14-.81-1.12A1.43,1.43,0,0,0,303.55,14.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M300.35,15c-.13.64.23,1.15.79,1.12a1.44,1.44,0,0,0,1.22-1.2c.12-.64-.23-1.14-.79-1.12A1.45,1.45,0,0,0,300.35,15Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M297.14,15.14c-.13.64.22,1.15.78,1.13a1.49,1.49,0,0,0,1.24-1.21c.12-.64-.22-1.14-.78-1.12A1.49,1.49,0,0,0,297.14,15.14Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M293.94,15.27c-.15.65.19,1.15.75,1.13A1.51,1.51,0,0,0,296,15.19c.14-.64-.2-1.14-.76-1.12A1.5,1.5,0,0,0,293.94,15.27Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M290.73,15.41c-.15.64.18,1.15.74,1.13a1.54,1.54,0,0,0,1.27-1.22c.15-.64-.18-1.14-.74-1.12A1.56,1.56,0,0,0,290.73,15.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M287.53,15.56c-.16.64.16,1.15.72,1.12a1.57,1.57,0,0,0,1.29-1.22c.15-.64-.17-1.14-.73-1.11A1.54,1.54,0,0,0,287.53,15.56Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M284.32,15.71c-.17.64.15,1.15.71,1.12a1.58,1.58,0,0,0,1.3-1.22c.16-.64-.15-1.14-.71-1.12A1.62,1.62,0,0,0,284.32,15.71Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M281.11,15.87c-.17.64.13,1.15.69,1.12a1.65,1.65,0,0,0,1.33-1.22c.17-.65-.14-1.15-.7-1.12A1.65,1.65,0,0,0,281.11,15.87Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M277.91,16c-.19.65.11,1.16.67,1.13a1.67,1.67,0,0,0,1.34-1.23c.18-.65-.12-1.15-.68-1.12A1.68,1.68,0,0,0,277.91,16Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M274.7,16.21c-.19.65.1,1.15.66,1.12a1.69,1.69,0,0,0,1.35-1.23c.19-.65-.1-1.15-.66-1.12A1.73,1.73,0,0,0,274.7,16.21Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M271.49,16.39c-.2.65.09,1.16.65,1.12a1.75,1.75,0,0,0,1.37-1.24c.19-.65-.09-1.15-.65-1.11A1.76,1.76,0,0,0,271.49,16.39Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M268.29,16.58c-.21.65.07,1.15.63,1.12a1.76,1.76,0,0,0,1.38-1.24c.21-.65-.07-1.15-.63-1.12A1.77,1.77,0,0,0,268.29,16.58Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M265.08,16.77c-.22.66.06,1.16.61,1.13a1.86,1.86,0,0,0,1.41-1.25c.21-.66-.06-1.15-.62-1.12A1.83,1.83,0,0,0,265.08,16.77Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M261.88,17c-.23.66,0,1.17.59,1.13a1.87,1.87,0,0,0,1.42-1.25c.22-.66,0-1.16-.6-1.12A1.86,1.86,0,0,0,261.88,17Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M252.26,17.62c-.25.66,0,1.17.55,1.13a2,2,0,0,0,1.46-1.27c.25-.66,0-1.16-.55-1.12A2,2,0,0,0,252.26,17.62Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M249.06,17.85c-.27.67,0,1.17.53,1.13a2,2,0,0,0,1.48-1.27c.26-.66,0-1.16-.54-1.12A2,2,0,0,0,249.06,17.85Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M245.85,18.09c-.27.67,0,1.17.52,1.13a2,2,0,0,0,1.49-1.28c.27-.66,0-1.16-.52-1.12A2.07,2.07,0,0,0,245.85,18.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M464.19,14.47a2.34,2.34,0,0,0,1.59,1.28c.54,0,.7-.47.37-1.13a2.31,2.31,0,0,0-1.59-1.27C464,13.31,463.86,13.81,464.19,14.47Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M448.6,13.41a2.14,2.14,0,0,0,1.52,1.24c.55,0,.74-.46.44-1.12a2.14,2.14,0,0,0-1.51-1.24C448.51,12.26,448.31,12.75,448.6,13.41Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M442.36,13a2.07,2.07,0,0,0,1.49,1.23c.55,0,.76-.47.47-1.12a2,2,0,0,0-1.48-1.23C442.3,11.88,442.09,12.38,442.36,13Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M426.74,12.19a1.86,1.86,0,0,0,1.42,1.21c.54,0,.79-.47.55-1.12a1.88,1.88,0,0,0-1.42-1.2C426.75,11.05,426.51,11.55,426.74,12.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M423.62,12a1.81,1.81,0,0,0,1.4,1.21c.54,0,.79-.48.56-1.12a1.82,1.82,0,0,0-1.4-1.2C423.64,10.91,423.39,11.4,423.62,12Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M420.49,11.9a1.77,1.77,0,0,0,1.38,1.2c.55,0,.81-.47.58-1.11a1.78,1.78,0,0,0-1.38-1.2C420.53,10.77,420.27,11.26,420.49,11.9Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M417.36,11.76a1.73,1.73,0,0,0,1.37,1.2c.55,0,.81-.48.59-1.11A1.73,1.73,0,0,0,418,10.66C417.42,10.63,417.15,11.13,417.36,11.76Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M414.23,11.64a1.7,1.7,0,0,0,1.35,1.19c.55,0,.82-.48.62-1.11a1.71,1.71,0,0,0-1.36-1.19C414.3,10.51,414,11,414.23,11.64Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M408,11.4a1.63,1.63,0,0,0,1.32,1.18c.55,0,.83-.48.64-1.11a1.64,1.64,0,0,0-1.32-1.18C408.07,10.27,407.78,10.77,408,11.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M404.83,11.29a1.62,1.62,0,0,0,1.31,1.18c.55,0,.84-.48.66-1.11a1.6,1.6,0,0,0-1.31-1.18C405,10.17,404.66,10.66,404.83,11.29Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M401.7,11.19A1.56,1.56,0,0,0,403,12.36c.55,0,.85-.48.68-1.11a1.57,1.57,0,0,0-1.29-1.17C401.84,10.06,401.53,10.56,401.7,11.19Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M398.57,11.09a1.52,1.52,0,0,0,1.27,1.17c.55,0,.86-.48.7-1.11A1.53,1.53,0,0,0,399.26,10C398.72,10,398.41,10.46,398.57,11.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M376.6,10.61a1.33,1.33,0,0,0,1.17,1.15.88.88,0,0,0,.81-1.12,1.32,1.32,0,0,0-1.17-1.14A.87.87,0,0,0,376.6,10.61Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M370.32,10.53a1.28,1.28,0,0,0,1.13,1.14.9.9,0,0,0,.84-1.12,1.26,1.26,0,0,0-1.13-1.13A.9.9,0,0,0,370.32,10.53Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M364,10.48a1.21,1.21,0,0,0,1.11,1.13.93.93,0,0,0,.87-1.12,1.22,1.22,0,0,0-1.1-1.13A1,1,0,0,0,364,10.48Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M354.6,10.45a1.14,1.14,0,0,0,1,1.13,1,1,0,0,0,.93-1.13,1.14,1.14,0,0,0-1.05-1.12A1,1,0,0,0,354.6,10.45Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M313.66,11a.88.88,0,0,0,.84,1.11A1.3,1.3,0,0,0,315.64,11a.88.88,0,0,0-.84-1.1A1.29,1.29,0,0,0,313.66,11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M310.5,11.11a.88.88,0,0,0,.83,1.11,1.32,1.32,0,0,0,1.15-1.16c.09-.63-.27-1.12-.82-1.1A1.33,1.33,0,0,0,310.5,11.11Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M304.2,11.32a.84.84,0,0,0,.79,1.1,1.39,1.39,0,0,0,1.19-1.17c.1-.63-.25-1.12-.79-1.1A1.39,1.39,0,0,0,304.2,11.32Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M291.58,11.81c-.15.63.17,1.12.72,1.1a1.51,1.51,0,0,0,1.26-1.19c.14-.63-.18-1.12-.73-1.1A1.53,1.53,0,0,0,291.58,11.81Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M288.42,11.94c-.16.64.16,1.13.71,1.11a1.55,1.55,0,0,0,1.27-1.19c.16-.64-.16-1.12-.71-1.1A1.55,1.55,0,0,0,288.42,11.94Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M285.27,12.09c-.17.63.14,1.13.69,1.1A1.57,1.57,0,0,0,287.25,12c.16-.63-.15-1.12-.7-1.1A1.59,1.59,0,0,0,285.27,12.09Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M282.11,12.24c-.18.64.13,1.13.68,1.11a1.62,1.62,0,0,0,1.3-1.2c.17-.64-.13-1.13-.68-1.1A1.62,1.62,0,0,0,282.11,12.24Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M279,12.4c-.18.64.11,1.13.66,1.11a1.65,1.65,0,0,0,1.33-1.21c.17-.63-.12-1.12-.67-1.1A1.68,1.68,0,0,0,279,12.4Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M275.8,12.57c-.2.64.09,1.13.64,1.1a1.69,1.69,0,0,0,1.34-1.21c.19-.63-.1-1.12-.65-1.09A1.66,1.66,0,0,0,275.8,12.57Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M269.48,12.92c-.21.64.07,1.14.62,1.11a1.76,1.76,0,0,0,1.36-1.22c.21-.64-.07-1.13-.61-1.1A1.75,1.75,0,0,0,269.48,12.92Z" transform="translate(-9.66 -9.33)" />
    <path class="cls-1" d="M266.32,13.11c-.21.64.05,1.14.6,1.1A1.81,1.81,0,0,0,268.31,13c.21-.64-.06-1.13-.6-1.1A1.81,1.81,0,0,0,266.32,13.11Z" transform="translate(-9.66 -9.33)" />
</svg>
    );
};

export default MapContent;