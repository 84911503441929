import React, { useState } from "react";
import { Helmet } from 'react-helmet';

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="bg-gray-800 rounded-3xl overflow-hidden shadow-md mb-2 max-w-screen-xl mx-auto">
      <h2>
        <button
          type="button"
          className="flex items-center text-white justify-between w-full py-5 px-6 font-bold text-whiterounded-3xl bg-gray-800 hover:bg-gray-600 transition-colors duration-300"
          onClick={toggleOpen}
        >
          <span>{title}</span>
          <svg
            className={`w-4 h-4 transition-transform duration-300 text-teal-400 ${
              isOpen ? "rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        className={`px-6 py-4 bg-gray-800 text-white ${isOpen ? "" : "hidden"}`}
      >
        {content}
      </div>
    </div>
  );
};

const TermsOfService = () => {
  return (
    <div className="container mx-auto py-8 px-4 sm:py-16 sm:px-6 max-w-screen-xl">
      <h1 className="text-3xl font-extrabold text-white mt-10 pt-10">
        Terms of Service
      </h1>
      <Helmet>
      <title>Terms Of Service | Virtue Host</title>
        <meta name="description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="keywords" content="Game Servers, Minecraft Hosting, Bot Hosting" />
        {/* Open Graph meta tags */}
        <meta property="og:image" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:secure_url" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="96" />
        <meta property="og:image:height" content="96" />
        <meta property="og:image:alt" content="Virtue Host Logo" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:src" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta name="twitter:title" content="Terms Of Service - Virtue Host" />
        <meta name="twitter:description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="twitter:image" content="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Link tags */}
        <link rel="canonical" href="https://virtue-host.com/" />
        <link rel="apple-touch-icon" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="manifest" href="" />
        <link rel="mask-icon" href="https://virtue-host.com/assets/images/company/logo.png" color="#55FF55" />
        <link rel="shortcut icon" href="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Microsoft-specific meta tags */}
        <meta name="msapplication-TileColor" content="#55FF55" />
        <meta name="msapplication-config" content="" />
        <meta name="theme-color" content="#55FF55" />
      </Helmet>
      <p className="text-white font-semibold">
        <strong>Last Revision: 08/30/2024</strong>
      </p>
      <div className="accordion mt-8">
        <AccordionItem
          title="Introduction"
          content={
            <>
              <p className="mb-2 font-bold">
                Welcome to Virtue Host! Before using our services, please review
                our Terms of Service ("Agreement") carefully.
              </p>
              <p className="font-bold">
                These terms govern the relationship between you ("You,"
                "Customer") and Virtue Host ("Us," "We," "Company").
              </p>
              <ul className="list-disc list-inside mt-4 font-bold">
                <li>
                  By using our services, you agree to be bound by these Terms of
                  Service.
                </li>
                <li>
                  We may cancel, suspend, or terminate any service at our
                  discretion. We will notify you and provide reasons for such
                  actions. Refunds will be considered on a case-by-case basis.
                </li>
                <li>
                  We may modify these Terms of Service at any time without prior
                  notice.
                </li>
                <li>
                  You are responsible for reviewing the latest Terms of Service
                  periodically.
                </li>
                <li>
                  Failure to comply with the updated Terms of Service may result
                  in termination of your service.
                </li>
              </ul>
            </>
          }
        />
        <AccordionItem
          title="Payment"
          content={
            <>
              <h1 className="text-2xl font-extrabold mt-8 mb-4">Payment</h1>
              <ul className="list-disc list-inside mt-4 space-y-4">
                <li>
                  <strong>Invoice Generation:</strong> Invoices are generated 10
                  days before the due date.
                </li>
                <li>
                  <strong>Payment Attempt:</strong> We will attempt to capture
                  the payment 7 days before the due date. If the capture fails,
                  the service will be suspended 1 day after the due date, but
                  payment will restore access to the service.
                </li>
                <li>
                  <strong>Payment Reminders:</strong>
                  <ul className="list-disc list-inside ml-5">
                    <li>First unpaid reminder: 5 days before the due date.</li>
                    <li>First overdue reminder: 1 day after the due date.</li>
                    <li>Second overdue reminder: 2 days after the due date.</li>
                  </ul>
                </li>
                <li>
                  <strong>Service Suspension:</strong> Services will be
                  suspended if payment is not received 1 day after the due date.
                </li>
                <li>
                  <strong>Service Termination:</strong> Services will be
                  terminated 3 days after the due date, and all associated data
                  will be permanently deleted if payment is not made.
                </li>
              </ul>
            </>
          }
        />
        <AccordionItem
          title="Refunds & Disputes"
          content={
            <>
              <h1 className="text-2xl font-extrabold mt-8 mb-4">
                Refunds & Disputes
              </h1>
              <ul className="list-disc list-inside mt-4 space-y-4">
                <li>
                  <strong>Refund Policy:</strong> We offer refunds for all
                  payments within 48 hours of the original transaction.
                </li>
                <li>
                  <strong>Refund Processing:</strong> Refunds are issued to the
                  original payer and are processed only upon confirmation of an
                  associated invoice ID linked to the payment gateway. The
                  refunded amount will exclude tax fees charged by the payment
                  gateway.
                </li>
                <li>
                  <strong>Non-refundable Items:</strong> Add-ons are
                  non-refundable.
                </li>
                <li>
                  <strong>Chargebacks:</strong> Initiating a chargeback will
                  result in automatic closure of your account and termination of
                  services, with all associated data being permanently deleted.
                  Details of the dispute will be provided to the Gateway
                  provider as evidence against the client's actions.
                </li>
                <li>
                  <strong>Avoiding Chargebacks:</strong> To avoid a chargeback,
                  it is recommended to open a support ticket in advance to
                  discuss your concerns with a Virtue Host staff member and
                  determine if you are eligible for a refund.
                </li>
              </ul>
            </>
          }
        />
        <AccordionItem
          title="Usage Restrictions"
          content={
            <>
              <h1 className="text-2xl font-extrabold mt-8 mb-4">
                Usage Restrictions
              </h1>
              <ul className="list-disc list-inside mt-4 space-y-4">
                <li>
                  <strong>Prohibited Uses:</strong> You may not use our services
                  for any illegal or unauthorized purpose. This includes, but is
                  not limited to, activities that violate any local, state, or
                  federal law.
                </li>
                <li>
                  <strong>Account Sharing:</strong> Accounts must not be shared
                  with unauthorized users. Each account must be accessed by only
                  one user.
                </li>
                <li>
                  <strong>Resource Abuse:</strong> You may not use excessive
                  amounts of server resources, including bandwidth, storage, and
                  processing power, that could affect other users.
                </li>
                <li>
                  <strong>Spam and Malware:</strong> Sending spam or
                  distributing malware is strictly prohibited.
                </li>
                <li>
                  <strong>Service Interruption:</strong> Actions that result in
                  disruption of service for other users are prohibited.
                </li>
              </ul>
            </>
          }
        />
        <AccordionItem
          title="Account Termination"
          content={
            <>
              <h1 className="text-2xl font-extrabold mt-8 mb-4">
                Account Termination
              </h1>
              <ul className="list-disc list-inside mt-4 space-y-4">
                <li>
                  <strong>Voluntary Termination:</strong> You may terminate your
                  account at any time by contacting support. Upon termination,
                  all data associated with the account will be permanently
                  deleted.
                </li>
                <li>
                  <strong>Involuntary Termination:</strong> We may terminate
                  your account if you violate our terms or if your payment is
                  overdue. Upon termination, you will forfeit any remaining
                  service time and all data associated with the account will be
                  permanently deleted.
                </li>
                <li>
                  <strong>Data Retention:</strong> Data may be retained for a
                  short period after termination to comply with legal
                  obligations or for account recovery purposes.
                </li>
              </ul>
            </>
          }
        />
        <AccordionItem
          id="accordion-cancellations"
          title="Cancellations"
          content={
            <div className="py-5 border-b border-gray-700">
              <h1 className="text-2xl font-extrabold text-white mt-8 mb-4">
                Cancellations
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Service Cancellation:</strong> If you do not wish to
                  continue your service, you must submit a cancellation request
                  through the Virtue Host client area.
                </li>
                <li>
                  <strong>Assistance with Cancellations:</strong> If you need
                  assistance with submitting a cancellation request, please
                  contact our support team for help.
                </li>
                <li>
                  <strong>Refund Policy:</strong> Please review our refund
                  policy before submitting a cancellation request, as certain
                  services may not be eligible for refunds.
                </li>
                <li>
                  <strong>Effective Date:</strong> Cancellations will be
                  effective at the end of the current billing cycle unless
                  otherwise specified. No pro-rated refunds will be issued for
                  partial billing periods.
                </li>
                <li>
                  <strong>Data Retention:</strong> Upon cancellation, all data
                  associated with the service will be permanently deleted.
                  Ensure you have backups of any important data before
                  proceeding with the cancellation.
                </li>
                <li>
                  <strong>Confirmation:</strong> Once your cancellation request
                  is processed, you will receive a confirmation email. If you do
                  not receive this email, please contact our support team to
                  verify the cancellation status.
                </li>
              </ul>
            </div>
          }
        />
        <AccordionItem
          id="accordion-security"
          title="Security"
          content={
            <div className="py-5 border-b border-gray-700">
              <h1 className="text-2xl font-extrabold text-white mt-8 mb-4">
                Security
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  At Virtue Host, safeguarding user privacy is our top priority.
                  Every team member is mandated to sign a Non-Disclosure
                  Agreement (NDA), ensuring the confidentiality of user
                  information.
                </li>
                <li>
                  If a user chooses to share their account details, Virtue Host
                  cannot be held accountable for any subsequent incidents. It is
                  the sole responsibility of the client/user to maintain the
                  security of their accounts, regardless of the Virtue Host
                  platform used.
                </li>
                <li>
                  Clients are tasked with enhancing the security of their
                  accounts by implementing either a strong password or enabling
                  two-factor authentication.
                </li>
              </ul>
            </div>
          }
        />
        <AccordionItem
          id="accordion-privacy"
          title="Privacy"
          content={
            <div className="py-5 border-b border-gray-700">
              <h1 className="text-2xl font-extrabold text-white mt-8 mb-4">
                Privacy
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Access Logs:</strong> For security purposes, our
                  website stores access logs with details for every visitor.
                  These logs include information such as:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Browser details</li>
                    <li>IP address</li>
                    <li>Date and time of access</li>
                    <li>Pages visited</li>
                    <li>Referrer URL</li>
                  </ul>
                </li>
                <li>
                  <strong>Cookies:</strong> Our website uses cookies, small
                  pieces of information stored on your computer, for our Client
                  Area (Billing) and Control Panel. These cookies help us:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Maintain your login session</li>
                    <li>Personalize your user experience</li>
                    <li>
                      Track and analyze website usage for performance
                      improvements
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Data Security:</strong> We implement robust security
                  measures to protect your personal data from unauthorized
                  access, alteration, disclosure, or destruction. These measures
                  include:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>
                      Secure Socket Layer (SSL) encryption for data transmission
                    </li>
                    <li>
                      Regular security audits and vulnerability assessments
                    </li>
                    <li>Access controls and authentication protocols</li>
                  </ul>
                </li>
                <li>
                  <strong>Data Retention:</strong> Access logs and other
                  personal data are retained for a period necessary to fulfill
                  the purposes outlined in this policy or as required by law.
                  After this period, the data is securely deleted.
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>
                      Inactive client data will be deleted after one year of
                      inactivity.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Third-Party Services:</strong> We may use third-party
                  services to process your data (e.g., payment processors,
                  analytics providers). These services adhere to strict data
                  protection and privacy standards to ensure the security of
                  your information.
                </li>
                <li>
                  <strong>User Rights:</strong> You have the right to:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Access and obtain a copy of your personal data</li>
                    <li>Request correction or deletion of your data</li>
                    <li>
                      Withdraw consent for data processing where consent was
                      previously given
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          }
        />
        <AccordionItem
          id="accordion-abuse"
          title="Abuse"
          content={
            <>
              <h1 className="text-2xl font-extrabold text-white mb-4">Abuse</h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Reporting Abuse:</strong> To report abuse on the
                  Virtue Host network, please contact us at{" "}
                  <a
                    href="mailto:abuse@virtue-host.com"
                    className="text-blue-500 underline"
                  >
                    abuse@virtue-host.com
                  </a>
                  . Include all relevant details to assist in the investigation.
                </li>
                <li>
                  <strong>Response to Abuse Complaints:</strong> Any abuse
                  complaints regarding your services will be forwarded to you
                  and will require immediate attention. We may suspend your
                  services until abuse reports are resolved. If an abuse report
                  is validated, your services may be terminated.
                </li>
                <li>
                  <strong>Types of Abuse:</strong> The following actions
                  constitute abuse and are strictly prohibited:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Spamming or sending unsolicited emails</li>
                    <li>Distribution of malware or malicious software</li>
                    <li>Unauthorized access to networks or systems</li>
                    <li>Conducting illegal activities</li>
                    <li>Harassment or abuse of our staff or other users</li>
                  </ul>
                </li>
                <li>
                  <strong>Consequences of Abuse:</strong> Engaging in any
                  abusive activities may result in:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Immediate suspension of services</li>
                    <li>Termination of services without refund</li>
                    <li>Reporting to relevant authorities</li>
                  </ul>
                </li>
                <li>
                  <strong>Preventive Measures:</strong> We implement preventive
                  measures to detect and mitigate abuse on our network. These
                  measures include:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Monitoring for suspicious activity</li>
                    <li>Regular security audits</li>
                    <li>Enforcement of strict access controls</li>
                  </ul>
                </li>
                <li>
                  <strong>Cooperation with Authorities:</strong> We cooperate
                  with law enforcement and regulatory authorities to investigate
                  and respond to abuse complaints. Your information may be
                  shared with these authorities as required by law.
                </li>
              </ul>
            </>
          }
        />
        <AccordionItem
          id="accordion-violations"
          title="Violations"
          content={
            <>
              <h1 className="text-2xl font-extrabold text-white mb-4">
                Violations
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Warning Notices:</strong> For certain violations of
                  these Terms of Service, we may provide notices or warnings via
                  email or support ticket at our discretion.
                </li>
                <li>
                  <strong>Consequences:</strong> Any violation of these terms
                  may result in:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Account suspension</li>
                    <li>Account termination</li>
                    <li>Account closure without refund</li>
                  </ul>
                </li>
                <li>
                  <strong>Exploitation Attempts:</strong> Any attempts to
                  exploit any part of our system may result in:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Account suspension</li>
                    <li>Account termination</li>
                    <li>Account closure without refund</li>
                  </ul>
                </li>
                <li>
                  <strong>Prohibited Activities:</strong> Engaging in any of the
                  following activities is strictly prohibited and will result in
                  severe actions including suspension or termination:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Unauthorized access to our systems or networks</li>
                    <li>Distribution of malware or malicious software</li>
                    <li>Using our services to conduct illegal activities</li>
                    <li>Spamming or sending unsolicited emails</li>
                    <li>Abuse or harassment of our staff or other users</li>
                  </ul>
                </li>
                <li>
                  <strong>Reporting Violations:</strong> If you become aware of
                  any violations of these terms, please report them immediately
                  to our support team. We take all reports seriously and will
                  investigate thoroughly.
                </li>
                <li>
                  <strong>Final Discretion:</strong> We reserve the right to
                  determine what constitutes a violation of these terms and to
                  take appropriate action, including modifying or terminating
                  services, at our sole discretion.
                </li>
              </ul>
            </>
          }
        />

        <AccordionItem
          id="accordion-affiliates"
          title="Affiliates"
          content={
            <>
              <h1 className="text-2xl font-extrabold text-white mb-4">
                Affiliates
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Withdrawal Options:</strong> Affiliate withdrawals can
                  be made to your Virtue Host credit balance or via an external
                  withdrawal through PayPal.
                </li>
                <li>
                  <strong>Referral Credits:</strong> Successful referrals will
                  credit 10% of the referred purchase to your affiliate account.
                  This credit will be available for withdrawal 30 days after the
                  successful transaction.
                </li>
                <li>
                  <strong>Program Participation:</strong> By participating in
                  our affiliate program, you agree to adhere to the terms and
                  conditions set forth. Any violation may result in termination
                  of your affiliate status and forfeiture of any accumulated
                  credits.
                </li>
                <li>
                  <strong>Promotional Materials:</strong> Affiliates are
                  provided with a range of promotional materials to help drive
                  referrals. These materials must be used as provided without
                  modification unless approved by Virtue Host.
                </li>
                <li>
                  <strong>Prohibited Activities:</strong> The following
                  activities are prohibited and may result in the termination of
                  your affiliate account:
                  <ul className="list-disc list-inside pl-6 mt-2">
                    <li>Spamming or sending unsolicited emails</li>
                    <li>Misleading or deceptive marketing practices</li>
                    <li>Using unauthorized promotional materials</li>
                    <li>Any form of fraud or abuse</li>
                  </ul>
                </li>
                <li>
                  <strong>Support and Contact:</strong> If you have any
                  questions or need assistance with the affiliate program,
                  please contact our support team at{" "}
                  <a
                    href="mailto:affiliates@virtue-host.com"
                    className="text-blue-500 underline"
                  >
                    affiliates@virtue-host.com
                  </a>
                  .
                </li>
              </ul>
            </>
          }
        />
        <AccordionItem
          id="backup-policy"
          title="Backup Policy"
          content={
            <>
              <h1 className="text-2xl font-extrabold text-white mt-8 mb-4">
                Backup Policy
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Client Responsibility:</strong> Clients must regularly
                  create and manage their backups. Virtue Host is not liable for
                  any data loss.
                </li>
                <li>
                  <strong>Client-Managed Backups:</strong> Clients must back up
                  their website data using the provided tools. Virtue Host is
                  not responsible for lost data due to failure to back up.
                </li>
                <li>
                  <strong>Automated Offsite Backups:</strong> Virtue Host
                  performs automated backups every 15 days per node for game
                  servers. These are offsite backups to storage servers in
                  locations of our nodes, for disaster recovery only.
                </li>
                <li>
                  <strong>Storage Limits:</strong> Clients must not exceed their
                  plan’s storage limits for backups.
                </li>
                <li>
                  <strong>No Guarantee:</strong> Virtue Host does not guarantee
                  backup availability or integrity. Clients should maintain
                  additional backups elsewhere.
                </li>
                <li>
                  <strong>Liability:</strong> Virtue Host is not liable for any
                  data loss.
                </li>
              </ul>
            </>
          }
        />

        <AccordionItem
          id="support-policy"
          title="Support Policy"
          content={
            <>
              <h1 className="text-2xl font-extrabold text-white mt-8 mb-4">
                Support Policy
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Customer Support Channels:</strong> We offer customer
                  support through the following channels:
                </li>
                <li>
                  <strong>Support Hours For Discord:</strong> 24/7. Typical
                  response times are within 1 hour for critical issues, and
                  6 hours for non-critical issues.
                </li>
                <li>
                  <strong>Support Hours For Client Area:</strong> 24/7. Typical
                  response times are within 1 hour for critical issues, and 6
                  hours for non-critical issues.
                </li>
                <li>
                  <strong>Email:</strong>{" "}
                  <a
                    href="mailto:support@virtue-host.com"
                    className="text-blue-500"
                  >
                    support@virtue-host.com
                  </a>
                </li>
                <li>
                  <strong>Ticket Center:</strong> Available on our Client Area.
                </li>
                <li>
                  <strong>Discord Tickets:</strong> Available on Discord.{" "}
                  <a
                    href="https://discord.gg/r8PgA3ReAG"
                    className="text-blue-500"
                  >
                    Click Here
                  </a>
                </li>
              </ul>
              <h2 className="text-xl font-bold text-white mb-2 mt-6">
                Contact Us
              </h2>
              <p className="text-white">
                If you have any questions about these Terms, please{" "}
                <a
                  href="https://billing.virtue-host.com/contact.php/"
                  className="text-blue-500"
                >
                  contact us
                </a>
                .
              </p>
            </>
          }
        />

        <AccordionItem
          id="maintenance"
          title="Server Maintenance and Updates"
          content={
            <>
              <h1 className="text-2xl font-extrabold text-white mt-8 mb-4">
                Server Maintenance and Updates
              </h1>
              <ul className="list-disc list-inside text-white mt-4 space-y-4">
                <li>
                  <strong>Regular Maintenance:</strong> Our servers are
                  restarted every three (3) months to implement necessary
                  updates and improvements.
                </li>
                <li>
                  <strong>Scheduled Maintenance:</strong>
                  <ul className="list-disc list-inside ml-5">
                    <li>
                      Announcements will be made at least seven (7) days in
                      advance via email and our official communication channels.
                    </li>
                    <li>
                      Maintenance for our North America location will be performed at
                      12:00 AM EST.
                    </li>
                    <li>
                      Maintenance for our Finland location will be performed at
                      12:00 AM local Finland time.
                    </li>
                    <li>
                      During these maintenance windows, services may be
                      temporarily unavailable. We strive to minimize downtime
                      and inconvenience to our users.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Unscheduled Maintenance:</strong>
                  <ul className="list-disc list-inside ml-5">
                    <li>
                      In the case of urgent updates or unforeseen technical
                      issues, we may need to perform unscheduled maintenance.
                    </li>
                    <li>
                      We will notify users as soon as possible with details of
                      the issue, expected downtime, and any actions required by
                      the users.
                    </li>
                    <li>
                      We aim to complete unscheduled maintenance quickly to
                      restore services with minimal disruption.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>User Responsibilities:</strong>
                  <ul className="list-disc list-inside ml-5">
                    <li>
                      It is the user’s responsibility to regularly back up their
                      data to avoid potential data loss during maintenance
                      periods.
                    </li>
                    <li>
                      Users should ensure that their applications and services
                      are compatible with the latest software updates.
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          }
        />
      </div>
    </div>
  );
};

export default TermsOfService;
