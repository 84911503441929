import React from 'react';

const Panel = () => {
  return (
    <div className="bg-gray-800 py-24 sm:py-32">
      <div className="mx-auto max-w-screen-xl px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center gap-16">
          <img 
            src="https://cdn.virtue-host.com/company/panel.webp" 
            alt="Pterodactyl Panel screenshot" 
            className="w-full lg:w-1/2 max-w-none rounded-xl shadow-lg" 
          />
          <div className="lg:w-1/2 text-white">
            <h2 className="text-base font-semibold text-green-500">Manage faster</h2>
            <h1 className="text-4xl font-bold tracking-tight text-teal-400 sm:text-4xl">Powerful Control Panel</h1>
            <p className="mt-4 text-lg leading-8 text-white">
              <strong>Introducing the Pterodactyl Panel:</strong> the ultimate companion for your game server. 
              It's user-friendly, secure, and empowers you with effortless control. Say goodbye to complexity and 
              hello to seamless server management with Pterodactyl.
            </p>
            <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="flex items-start">
                <svg className="h-6 w-6 text-teal-300 mr-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 9h4m0 0V5m0 4L4 4m15 5h-4m0 0V5m0 4 5-5M5 15h4m0 0v4m0-4-5 5m15-5h-4m0 0v4m0-4 5 5" />
                </svg>
                <div>
                  <dt className="font-semibold">Split Servers</dt>
                </div>
              </div>

              <div className="flex items-start">
                <svg className="h-6 w-6 text-teal-300 mr-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M3 6a3 3 0 1 1 4 2.83v6.34a3.001 3.001 0 1 1-2 0V8.83A3.001 3.001 0 0 1 3 6Zm11.207-2.707a1 1 0 0 1 0 1.414L13.914 5H15a4 4 0 0 1 4 4v6.17a3.001 3.001 0 1 1-2 0V9a2 2 0 0 0-2-2h-1.086l.293.293a1 1 0 0 1-1.414 1.414l-2-2a1 1 0 0 1 0-1.414l2-2a1 1 0 0 1 1.414 0Z" clipRule="evenodd" />
                </svg>
                <div>
                  <dt className="font-semibold">Pull Files</dt>
                </div>
              </div>

              <div className="flex items-start">
                <svg className="h-6 w-6 text-teal-300 mr-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M10 5a2 2 0 0 0-2 2v3h2.4A7.48 7.48 0 0 0 8 15.5a7.48 7.48 0 0 0 2.4 5.5H5a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1V7a4 4 0 1 1 8 0v1.15a7.446 7.446 0 0 0-1.943.685A.999.999 0 0 1 12 8.5V7a2 2 0 0 0-2-2Z" clipRule="evenodd" />
                  <path fillRule="evenodd" d="M10 15.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Zm6.5-1.5a1 1 0 1 0-2 0v1.5a1 1 0 0 0 .293.707l1 1a1 1 0 0 0 1.414-1.414l-.707-.707V14Z" clipRule="evenodd" />
                </svg>
                <div>
                  <dt className="font-semibold">Automated Tasks</dt>
                </div>
              </div>

              <div className="flex items-start">
                <svg className="h-6 w-6 text-teal-300 mr-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M7 6a1 1 0 0 1 2 0v4l6.4-4.8A1 1 0 0 1 17 6v12a1 1 0 0 1-1.6.8L9 14v4a1 1 0 1 1-2 0V6Z" clipRule="evenodd" />
                </svg>
                <div>
                  <dt className="font-semibold">Automatic Backup & Restore</dt>
                </div>
              </div>

              <div className="flex items-start">
                <svg className="h-6 w-6 text-teal-300 mr-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z" clipRule="evenodd" />
                </svg>
                <div>
                  <dt className="font-semibold">Control Users Permissions</dt>
                </div>
              </div>

              <div className="flex items-start">
                <svg className="h-6 w-6 text-teal-300 mr-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M10.83 5a3.001 3.001 0 0 0-5.66 0H4a1 1 0 1 0 0 2h1.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-9.17ZM4 11h9.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-1.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 1 0 0 2Zm5.66 3a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-9.17a3.001 3.001 0 0 0-5.66 0H4a1 1 0 1 0 0 2h1.66Z" />
                </svg>
                <div>
                  <dt className="font-semibold">Manage Multiple Servers</dt>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
