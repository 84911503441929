import React from 'react';
import { Helmet } from 'react-helmet';

const partners = [
  {
    name: 'EllasRipley',
    title: 'Server Owner - The Project',
    description: 'The project was created by EliasRipley as he wanted to build a multiplayer world which featured some of the best mods available, yet didn\'t introduce too much bloat. The idea was to make a server where building a beautiful community was the top priority. Some of the bigger mods include Create, Ars Nouveau, Alex\'s Mods and Tectonic. It also features a few extra dimensions for those who want to explore other lands.',
    imageUrl: 'https://cdn.virtue-host.com/partners/theproject.webp',
    discordLink: 'https://discord.com/invite/ptrYNsJRBp'
  }
  // Add more partner objects here as needed
];

const PartnersPage = () => (
  <section className="bg-gray-900 py-10 sm:py-16 px-4 sm:px-6 mt-10">
    <div className="container mx-auto max-w-screen-xl">
      <h1 className="text-4xl font-bold text-center text-white mb-6 sm:mb-3">
        Virtue Host <span className="text-teal-400">Partners</span>
      </h1>
      <Helmet>
      <title>Partners | Virtue Host</title>
        <meta name="description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="keywords" content="Game Servers, Minecraft Hosting, Bot Hosting" />
        {/* Open Graph meta tags */}
        <meta property="og:image" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:secure_url" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="96" />
        <meta property="og:image:height" content="96" />
        <meta property="og:image:alt" content="Virtue Host Logo" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:src" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta name="twitter:title" content="Partners - Virtue Host" />
        <meta name="twitter:description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="twitter:image" content="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Link tags */}
        <link rel="canonical" href="https://virtue-host.com/" />
        <link rel="apple-touch-icon" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="manifest" href="" />
        <link rel="mask-icon" href="https://virtue-host.com/assets/images/company/logo.png" color="#55FF55" />
        <link rel="shortcut icon" href="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Microsoft-specific meta tags */}
        <meta name="msapplication-TileColor" content="#55FF55" />
        <meta name="msapplication-config" content="" />
        <meta name="theme-color" content="#55FF55" />
      </Helmet>
      <p className="max-w-3xl mx-auto text-xl text-center text-white mb-8">
        Discover fresh possibilities and expand your community through our Partner Program. Leverage our suite of resources to provide lightning-fast hosting solutions for Minecraft, game servers, and websites.
      </p>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {partners.map((partner, index) => (
          <div key={index} className="bg-gray-800 border-4 border-gray-700 rounded-3xl p-6 transition-transform transform hover:scale-105">
            <div className="flex items-center mb-4">
              <img className="w-24 h-24 rounded-full ring-4 ring-teal-400" src={partner.imageUrl} alt={partner.name} />
              <div className="ml-4">
                <h2 className="text-xl font-semibold text-white">{partner.name}</h2>
                <p className="text-gray-300">{partner.title}</p>
                <div className="flex">
              <a href={partner.discordLink} className="text-gray-600 hover:text-gray-400" aria-label="Discord">
                <svg className="w-10 h-10 text-blue-500 mt-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                </svg>
              </a>
            </div>
              </div>
              
            </div>
            <p className="text-gray-300 font-semibold mb-4">{partner.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default PartnersPage;
