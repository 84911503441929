// HomePage.jsx
import React from 'react';
import HeroSection from './HeroSection';
import FeaturesSection from './FeaturesSection';
import Panel from './Panel';
import GlobalLocations from './GlobalLocations';
import Technologies from './Technologies';
import Comparison from './Comparison';
import Reviews from './Reviews';
import Faq from './Faq';

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <FeaturesSection />
      <Panel />
      <GlobalLocations />
      <Technologies />
      <Comparison />
      <Reviews />
      <Faq />
    </div>
  );
};

export default HomePage;
