import React, { useState } from "react";
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-gray-900 min-h-screen py-10 px-4 sm:py-16 sm:px-6">
      <div className="container mx-auto max-w-screen-xl bg-gray-900">
        <h1 className="text-3xl font-extrabold text-white mt-5 pt-10 mb-5">
          Privacy Policy
        </h1>
        <Helmet>
        <title>Privacy Policy | Virtue Host</title>
        <meta name="description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="keywords" content="Game Servers, Minecraft Hosting, Bot Hosting" />
        {/* Open Graph meta tags */}
        <meta property="og:image" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:secure_url" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="96" />
        <meta property="og:image:height" content="96" />
        <meta property="og:image:alt" content="Virtue Host Logo" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:src" content="https://virtue-host.com/assets/images/company/logo.png" />
        <meta name="twitter:title" content="Privacy Policy - Virtue Host" />
        <meta name="twitter:description" content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting." />
        <meta name="twitter:image" content="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Link tags */}
        <link rel="canonical" href="https://virtue-host.com/" />
        <link rel="apple-touch-icon" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://virtue-host.com/assets/images/company/logo.png" />
        <link rel="manifest" href="" />
        <link rel="mask-icon" href="https://virtue-host.com/assets/images/company/logo.png" color="#55FF55" />
        <link rel="shortcut icon" href="https://virtue-host.com/assets/images/company/logo.png" />

        {/* Microsoft-specific meta tags */}
        <meta name="msapplication-TileColor" content="#55FF55" />
        <meta name="msapplication-config" content="" />
        <meta name="theme-color" content="#55FF55" />
      </Helmet>
        <div id="accordion-flush" data-accordion="collapse">
          {sections.map((section, index) => (
            <div key={index} className="bg-gray-800 rounded-3xl overflow-hidden shadow-md mb-2">
              <h2 id={`accordion-flush-heading-${index}`}>
                <button
                  type="button"
                  className={`flex items-center justify-between w-full py-5 px-6 font-bold text-white rounded-3xl bg-gray-800 hover:bg-gray-600 transition-colors duration-300 ${
                    activeIndex === index
                      ? "bg-gray-800 text-gray-900 dark:text-white"
                      : "text-white"
                  }`}
                  onClick={() => handleAccordionToggle(index)}
                  aria-expanded={activeIndex === index}
                  aria-controls={`accordion-flush-body-${index}`}
                >
                  <span>{section.title}</span>
                  <svg
                    data-accordion-icon
                    className={`w-4 h-4 transition-transform duration-300 text-teal-400 ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id={`accordion-flush-body-${index}`}
                className={`px-6 py-4 bg-gray-800 text-white  ${activeIndex === index ? "" : "hidden"}`}
                aria-labelledby={`accordion-flush-heading-${index}`}
              >
                {section.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const sections = [
  {
    title: "Information We Collect",
    content: (
      <>
        <p className="mb-2 text-white">
          We may collect personal identification information from Users in a
          variety of ways, including, but not limited to, when Users visit our
          website, register on the website, fill out a form, and in connection
          with other activities, services, features, or resources we make
          available on our Services. Users may be asked for, as appropriate,
          name, email address, mailing address, and payment information. We will
          collect personal identification information from Users only if they
          voluntarily submit such information to us. Users can always refuse to
          supply personally identification information, except that it may
          prevent them from engaging in certain Services-related activities.
        </p>
        <p className="text-white">
          We may also collect non-personal identification information about
          Users whenever they interact with our Services. Non-personal
          identification information may include the browser name, the type of
          computer, and technical information about Users' means of connection
          to our Services, such as the operating system and the Internet service
          providers utilized and other similar information.
        </p>
      </>
    ),
  },
  {
    title: "How We Use Collected Information",
    content: (
      <>
        <p className="mb-2 text-white">
          We may collect and use Users' personal information for the following
          purposes:
        </p>
        <ul className="list-disc list-inside text-white mb-8">
          <li className="mb-2">
            To improve customer service: Information you provide helps us
            respond to your customer service requests and support needs more
            efficiently.
          </li>
          <li className="mb-2">
            To personalize user experience: We may use information in the
            aggregate to understand how our Users as a group use the services
            and resources provided on our Services.
          </li>
          <li className="mb-2">
            To process payments: We may use the information Users provide about
            themselves when placing an order only to provide service to that
            order. We do not share this information with outside parties except
            to the extent necessary to provide the service.
          </li>
          <li className="mb-2">
            To send periodic emails: We may use the email address to send User
            information and updates pertaining to their order. It may also be
            used to respond to their inquiries, questions, and/or other
            requests.
          </li>
        </ul>
        <p className="mb-2 text-white">
          We may also use cookies and similar technologies to enhance User
          experience on our website. Necessary cookies are essential for the
          functioning of the website and cannot be switched off in our systems.
          These cookies do not store any personally identifiable information.
        </p>
        <p className="mb-2 text-white">
          We may use Google Analytics to collect information about how Users use
          our website. Google Analytics gathers information about website use
          through cookies. The information collected regarding your use of the
          website is used to create reports about the use of the website.
          Google's privacy policy is available at:{" "}
          <a
            href="https://policies.google.com/privacy"
            className="text-blue-600 hover:underline"
          >
            https://policies.google.com/privacy
          </a>
          .
        </p>
      </>
    ),
  },
  {
    title: "How We Protect Your Information",
    content: (
      <>
        <p className="mb-2 text-white">
          Protecting your information is our top priority. We implement rigorous
          data collection, storage, and processing practices along with robust
          security measures to safeguard against unauthorized access,
          alteration, disclosure, or destruction of your personal information,
          including your username, password, transaction details, and data
          stored on our Services.
        </p>
        <p className="mb-2 text-white">
          Our security measures include, but are not limited to:
        </p>
        <ul className="list-disc list-inside text-white mb-2">
          <li>
            Encryption of sensitive data using secure socket layer technology
            (SSL).
          </li>
          <li>
            Regular vulnerability scanning and penetration testing of our
            systems.
          </li>
          <li>
            Strict access controls to ensure only authorized personnel have
            access to your information.
          </li>
          <li>
            Ongoing monitoring of our systems and networks for any suspicious
            activity.
          </li>
          <li>
            Compliance with industry standards and regulations to continuously
            improve our security practices.
          </li>
        </ul>
        <p className="mb-2 text-white">
          We take your privacy and the security of your personal information
          very seriously. To protect your data, we employ advanced security
          measures, including encryption, secure data storage, and stringent
          access controls. Our dedicated security team continuously monitors our
          systems for potential vulnerabilities and unauthorized access
          attempts.
        </p>
      </>
    ),
  },
  {
    title: "Sharing Your Personal Information",
    content: (
      <p className="mb-2 text-white">
        We do not sell, trade, or rent Users' personal identification
        information to third parties unless necessary to provide the services
        requested, or required by law or legal process. However, we may share
        generic aggregated demographic information not linked to any personal
        identification information about visitors and users with our business
        partners, trusted affiliates, and advertisers for legitimate business
        purposes.
      </p>
    ),
  },
  {
    title: "Changes to This Privacy Policy",
    content: (
      <p className="mb-2 text-white">
        We reserve the right to update this Privacy Policy from time to time as
        necessary. Any changes will be effective immediately upon posting the
        revised policy on this page with a new effective date. We encourage you
        to review this Privacy Policy periodically to stay informed about how we
        collect, use, and protect your personal information. Your continued use
        of our Services after any modifications to this Privacy Policy will
        constitute your acknowledgment of the changes and your consent to abide
        and be bound by the updated policy.
      </p>
    ),
  },
  {
    title: "Your Data Protection Rights",
    content: (
      <>
        <p className="mb-2 text-white">
          Under certain circumstances, you have the following data protection
          rights:
        </p>
        <ul className="list-disc list-inside text-white mb-8">
          <li className="mb-2">
            <strong>The right to access:</strong> You have the right to request
            copies of your personal data. We may charge you a small fee for
            this service.
          </li>
          <li className="mb-2">
            <strong>The right to rectification:</strong> You have the right to
            request that we correct any information you believe is inaccurate or
            complete information you believe is incomplete.
          </li>
          <li className="mb-2">
            <strong>The right to erasure:</strong> You have the right to request
            that we erase your personal data, under certain conditions.
          </li>
          <li className="mb-2">
            <strong>The right to restrict processing:</strong> You have the
            right to request that we restrict the processing of your personal
            data, under certain conditions.
          </li>
          <li className="mb-2">
            <strong>The right to object to processing:</strong> You have the
            right to object to our processing of your personal data, under
            certain conditions.
          </li>
          <li className="mb-2">
            <strong>The right to data portability:</strong> You have the right
            to request that we transfer the data that we have collected to
            another organization, or directly to you, under certain conditions.
          </li>
        </ul>
        <p className="mb-2 text-white">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at our
          provided contact information.
        </p>
      </>
    ),
  },
  {
    title: "Contact Us",
    content: (
      <>
        <p className="mb-2 text-white">
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us at:
        </p>
        <p className="text-white">
          <strong>Email:</strong> support@example.com
          <br />
          <strong>Address:</strong> 1234 Example St, City, Country
        </p>
      </>
    ),
  }
  

];

export default PrivacyPolicy;
