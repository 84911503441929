import React from 'react';
import MapContent from './Map.jsx';

const GlobalLocations = () => {
  return (
    <div className="bg-gray-900 py-32">
      <h2 className="mb-4 text-center text-4xl font-bold text-teal-400 md:mb-6 lg:text-4xl">Global Locations</h2>
      <p className="mx-auto max-w-screen-md text-2xl text-center text-white md:text-2xl font-bold mb-5 px-5">
        Select from our global locations for swift download speeds and minimal ping
      </p>
      <MapContent />;
    </div>
  );

};

export default GlobalLocations;
