import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white">
            <div className="container px-6 py-8 mx-auto max-w-screen-7xl">
                <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4">
                    {/* Services Section */}
                    <div>
                        <div className="text-lg font-bold text-teal-400 uppercase">
                            Services
                        </div>
                        <a href="minecraft" className="block mt-5 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Minecraft Server Hosting
                        </a>
                        <a href="games" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Game Servers
                        </a>
                        <a href="bot-hosting" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Bot Hosting
                        </a>
                    </div>

                    {/* Clients Section */}
                    <div>
                        <div className="text-lg font-bold text-teal-400 uppercase">
                            Clients
                        </div>
                        <a href="https://virtue-host.com/billing/" className="block mt-5 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Client Area
                        </a>
                        <a href="https://panel.virtue-host.com/" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Game Panel
                        </a>
                        <a href="https://virtue-host.com/billing/submitticket.php/" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Submit a Ticket
                        </a>
                        <a href="https://help.virtue-host.com/" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Knowledgebase
                        </a>
                    </div>

                    {/* Company Section */}
                    <div>
                        <div className="text-lg font-bold text-teal-400 uppercase">
                            Company
                        </div>
                        <a href="https://virtue-host.com/billing/contact.php" className="block mt-5 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Contact Us
                        </a>
                        <a href="https://status.virtue-host.com/" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Status Page
                        </a>
                        <a href="partners" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Partners
                        </a>
                        <a href="https://virtue-host.com/billing/affiliates.php" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Affiliates
                        </a>
                    </div>

                    {/* Legal Section */}
                    <div>
                        <div className="text-lg font-bold text-teal-400 uppercase">
                            Legal
                        </div>
                        <a href="terms-of-service" className="block mt-5 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Terms of Service
                        </a>
                        <a href="privacy-policy" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Privacy Policy
                        </a>
                        <a href="aup" className="block mt-3 text-lg font-bold hover:text-gray-400 duration-300 hover:underline">
                            Acceptable Usage Policy
                        </a>
                    </div>
                </div>

                <hr className="my-10 border-gray-700" />

                <div className="sm:flex sm:items-center sm:justify-between">
                    <p className="text-sm font-semibold">© 2024 Virtue Host. All Rights Reserved.</p>
                    <div className="flex mt-3 -mx-2 sm:mt-0">
                        <a href="https://discord.com/invite/UR5BD98Wn6" className="mx-2 text-blue-600 hover:text-blue-400 duration-300" aria-label="Discord">
                            <svg className="w-8 h-8" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
